import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import ChartOptions from './chart-options'
import { formatDiff } from '../../../utils/date'

export default class Chart extends Component {
    diff = (estimated, actual) => moment(estimated).diff(moment(actual), 'seconds')

    generateXAxis() {
        const { stops } = this.props

        return stops.map((stop) => {
            const arrival = stop.actualArrival ? stop.actualArrival : stop.estimatedArrival

            return {
                x: moment(stop.estimatedArrival),
                y: parseInt(this.diff(stop.estimatedArrival, arrival) / 60, 10),
            }
        })
    }

    render() {
        const { stops, timezone } = this.props
        const chartData = this.generateXAxis()
        const labels = chartData.map((stop) => stop.x)

        const lineColor = '#41CDA5'
        const dotColors = stops.map((stop) => (stop.cancelled ? '#BDC3C7' : lineColor))

        const data = () => ({
            labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Stops',
                    borderColor: lineColor,
                    pointBorderColor: dotColors,
                    pointHoverBackgroundColor: lineColor,
                    pointHoverBorderColor: lineColor,
                    pointBorderWidth: 8,
                    pointHoverRadius: 8,
                    pointHoverBorderWidth: 1,
                    pointRadius: 3,
                    fill: false,
                    borderWidth: 4,
                    data: chartData,
                },
            ],
        })

        const options = {
            ...ChartOptions,
            linearGradientLine: true,
            tooltips: {
                ...ChartOptions.tooltips,
                callbacks: {
                    title: (tooltipItems) => `Stop ${tooltipItems[0].index + 1}`,
                    beforeLabel: (tooltipItem) => {
                        const stop = stops[tooltipItem.index]
                        const eta = moment(stop.estimatedArrival)
                        const etd = moment(stop.estimatedDeparture)
                        const serviceTime = this.diff(etd, eta)
                        return `ETA: ${eta.tz(timezone).format('HH:mm')} (${formatDiff(serviceTime)} ST)`
                    },
                    label: (tooltipItem) => {
                        const stop = stops[tooltipItem.index]
                        if (!stop.actualArrival) {
                            return 'ATA: Unknown'
                        }
                        const ata = moment(stop.actualArrival)
                        const atd = moment(stop.actualDeparture)
                        const serviceTime = this.diff(atd, ata)
                        return `ATA: ${ata.tz(timezone).format('HH:mm')} (${formatDiff(serviceTime)} ST)`
                    },
                    afterLabel: (tooltipItem) => {
                        const stop = stops[tooltipItem.index]
                        const edt = moment.duration(stop.estimatedDriveTimeFromPreviousStop, 'seconds')
                        const adt = moment.duration(stop.actualDriveTimeFromPreviousStop, 'seconds')

                        return `${formatDiff(edt)} / ${formatDiff(adt)} DT from previous stop`
                    },
                    footer: (tooltipItems) => {
                        const diff = tooltipItems[0].yLabel
                        return diff > 0 ? `Ahead: ${diff} mins` : `Behind: ${diff} mins`
                    },
                },
            },
            onClick: (_, elements) => {
                if (elements.length > 0) {
                    this.props.onClick(elements[0]._index)
                }
            },
        }

        return (
            <div style={{ width: '100vw', height: '20vh' }}>
                <Line options={options} data={data} />
            </div>
        )
    }
}

Chart.propTypes = {
    stops: PropTypes.array.isRequired,
    timezone: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}
