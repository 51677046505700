const element = (label, color) => {
    const container = document.createElement('div')
    const innerCircle = document.createElement('div')
    const p = document.createElement('p')
    const text = document.createTextNode(label)

    container.style.width = '24px'
    container.style.height = '24px'
    container.style.borderRadius = '50%'
    container.style.backgroundColor = 'white'

    innerCircle.style.width = '20px'
    innerCircle.style.height = '20px'
    innerCircle.style.marginTop = '2px'
    innerCircle.style.marginLeft = '2px'
    innerCircle.style.borderRadius = '50%'
    innerCircle.style.backgroundColor = color

    p.style.color = 'white'
    p.style.textAlign = 'center'

    container.appendChild(innerCircle)
    innerCircle.appendChild(p)
    p.appendChild(text)

    return container
}

export default (Marker, latitude, longitude, label, color) =>
    new Marker({ element: element(label, color) }).setLngLat([longitude, latitude])
