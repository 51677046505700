import React, { Component } from 'react'
import PropTypes from 'prop-types'

import 'react-toggle/style.css'

import moment from 'moment'
import { Alert, Badge, Button, ControlLabel, ListGroup, ListGroupItem, Modal } from 'react-bootstrap'
import { DayPickerSingleDateController } from 'react-dates'
import Toggle from 'react-toggle'
import Loader from 'react-loader'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getSchedule, bookConsignment } from '../../../utils/intervals-webapi'
import { handleError } from '../../../utils/handle-error'

export default class BookConsignment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enableFreeTimeslots: false,
            loading: true,
            showError: false,
            bookError: null,
            showBookError: false,
            timeWindows: [],
            selectedDate: null,
            selectedTimeWindow: null,
        }
    }

    componentWillMount() {
        getSchedule(this.props.orderToken)
            .then(this.onScheduleFetched)
            .catch(handleError)
    }

    onScheduleFetched = (response) => {
        const { countryCode } = this.props
        if (response.status !== 'COMPLETED') {
            this.setState({ loading: false, showError: true })
            return
        }

        const convertStringsToDates = (timeWindow) => ({
            ...timeWindow,
            key: timeWindow.start + timeWindow.stop,
            start: moment(timeWindow.start).tz(mapTimeZoneToCountry(countryCode)),
            stop: moment(timeWindow.stop).tz(mapTimeZoneToCountry(countryCode)),
        })

        const timeWindows = response.payload.timeWindows.map(convertStringsToDates)

        const selectedTimeWindow =
            timeWindows.filter((window) => window.timeslotSpec === null).sort((a, b) => a.start - b.start)[0] || null

        const selectedDate = selectedTimeWindow ? selectedTimeWindow.start : null

        this.setState({
            loading: false,
            timeWindows,
            selectedTimeWindow,
            selectedDate,
        })
    }

    onFreeTimeslotsToggled = (e) => {
        const enabled = e.target.checked
        const { selectedTimeWindow } = this.state
        const updatedSelected =
            !enabled && selectedTimeWindow && selectedTimeWindow.timeslotSpec ? null : selectedTimeWindow
        this.setState({ enableFreeTimeslots: enabled, selectedTimeWindow: updatedSelected })
    }

    onDayChanged = (selectedDate) => {
        this.setState({ selectedDate: selectedDate.toDate(), selectedTimeWindow: null })
    }

    onBookConsignmentResponse = (response) => {
        const { onSuccess } = this.props
        if (response.status !== 'COMPLETED') {
            this.setState({
                showBookError: true,
                bookError: `${response.errorCode}\n${response.errorMsg || ''}`,
            })
            return
        }
        onSuccess()
    }

    onBookConsignment = () => {
        const { selectedTimeWindow } = this.state
        const { orderToken, deliveryAttempt } = this.props
        this.setState({ showBookError: false })
        bookConsignment(orderToken, deliveryAttempt, selectedTimeWindow)
            .then(this.onBookConsignmentResponse)
            .catch(handleError)
    }

    applicableSortedTimeWindows = (timeWindows, enableFreeTimeslots) =>
        timeWindows
            .filter((timeWindow) => timeWindow.timeslotSpec === null || enableFreeTimeslots)
            .sort((a, b) => a.start - b.start)

    sortTimeWindow = (a, b) => {
        if (a.timeslotSpec && !b.timeslotSpec) {
            return 1
        }
        if (!a.timeslotSpec && b.timeslotSpec) {
            return -1
        }
        return 0
    }

    selectedDayTimeWindows = (allowedTimeWindows, selectedDate) => {
        const isSameDay = (a, b) => a.isSame(b, 'day')
        return allowedTimeWindows
            .filter((timeWindow) => isSameDay(timeWindow.start, selectedDate))
            .sort(this.sortTimeWindow)
    }

    renderTimeWindows = (timeWindows) => {
        const { selectedTimeWindow } = this.state

        const renderTimeWindow = (timeWindow) => {
            const start = timeWindow.start.format('HH:mm')
            const stop = timeWindow.stop.format('HH:mm')
            const isSelected = selectedTimeWindow ? timeWindow.key === selectedTimeWindow.key : false
            return (
                <ListGroupItem
                    key={timeWindow.key}
                    href=""
                    onClick={() => this.setState({ selectedTimeWindow: timeWindow })}
                    active={isSelected}
                >
                    <span className="book-consignment--time">{start}</span> -
                    <span className="book-consignment--time">{stop}</span>
                    {timeWindow.timeslotSpec ? <Badge>Timeslot</Badge> : null}
                </ListGroupItem>
            )
        }

        return <ListGroup>{timeWindows.map(renderTimeWindow)}</ListGroup>
    }

    renderBookError = () => {
        const { bookError } = this.state
        return (
            <div className="book-consignment--book-error">
                <Alert onDoubleClick={() => alert(bookError)} bsStyle="danger">
                    Could not book consignment!
                </Alert>
            </div>
        )
    }

    renderCalendar = (windows) => {
        const { selectedDate } = this.state
        const isOutsideDay = (day) => !windows.some((wdw) => wdw.start.isSame(day, 'day'))
        const isDayHighlighted = (day) => selectedDate && day.isSame(selectedDate, 'day')
        return (
            <DayPickerSingleDateController
                numberOfMonths={1}
                onDateChange={this.onDayChanged}
                isOutsideRange={isOutsideDay}
                isDayHighlighted={isDayHighlighted}
            />
        )
    }

    renderBodyWithSchedule = () => {
        const { enableFreeTimeslots, selectedDate, timeWindows, showBookError } = this.state

        const windows = this.applicableSortedTimeWindows(timeWindows, enableFreeTimeslots)
        const renderedTimeWindows = selectedDate
            ? this.renderTimeWindows(this.selectedDayTimeWindows(windows, selectedDate))
            : null

        return (
            <Modal.Body>
                <ControlLabel>Select Delivery Window</ControlLabel>
                <div className="book-consignment--body">
                    <div>
                        <div className="book-consignment--toggle">
                            {this.renderCalendar(windows)}
                            <Toggle defaultChecked={false} onChange={this.onFreeTimeslotsToggled} />
                            <span>
                                <strong>Show Free Timeslots</strong>
                            </span>
                        </div>
                    </div>
                    <div className="book-consignment--time-windows">{renderedTimeWindows}</div>
                </div>
                {showBookError ? this.renderBookError() : null}
            </Modal.Body>
        )
    }

    renderBodyWhenLoading = () => (
        <Modal.Body className="book-consignment--loading">
            <Loader loaded={false} />
        </Modal.Body>
    )

    renderBodyWhenFailed = () => (
        <Modal.Body>
            <Alert bsStyle="danger">Could not load schedule for order!</Alert>
        </Modal.Body>
    )

    renderBodyWhenNoTimeWindows = () => (
        <Modal.Body>
            <Alert bsStyle="info">There are no time windows to book for a consignment!</Alert>
        </Modal.Body>
    )

    renderBody = () => {
        const { loading, showError, timeWindows } = this.state
        if (loading) {
            return this.renderBodyWhenLoading()
        }
        if (showError) {
            return this.renderBodyWhenFailed()
        }
        if (timeWindows.length === 0) {
            return this.renderBodyWhenNoTimeWindows()
        }
        return this.renderBodyWithSchedule()
    }

    renderFooter = () => {
        const { loading, selectedTimeWindow, showError, timeWindows } = this.state

        const renderBook = !showError && !loading && timeWindows.length > 1
        const bookButton = (
            <Button bsStyle="primary" disabled={!selectedTimeWindow} onClick={this.onBookConsignment}>
                Book
            </Button>
        )
        return (
            <Modal.Footer>
                <Button onClick={this.props.onClose}>Cancel</Button>
                {renderBook ? bookButton : null}
            </Modal.Footer>
        )
    }

    render() {
        const { onClose } = this.props
        return (
            <Modal show onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Book Consignment</Modal.Title>
                </Modal.Header>
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}

BookConsignment.propTypes = {
    orderToken: PropTypes.string.isRequired,
    countryCode: PropTypes.string.isRequired,
    deliveryAttempt: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}
