import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getOrderNotificationHistory } from '../../../utils/order-webapi'
import { handleError } from '../../../utils/handle-error'

const cellStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

/**
 * Render SMS Log
 */
export default class NotificationsLog extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            notificationLog: [],
        }
    }

    componentDidMount() {
        if (this.props.order) {
            this.updateNotificationLog()
        }
    }

    updateNotificationLog = () => {
        // Retrieve a list of notifications sent to the consumer
        // regarding this order
        getOrderNotificationHistory(this.props.order.token)
            .then((notificationLog) => {
                this.setState({ notificationLog })
            })
            .catch(handleError)
    }

    renderRow = (notification) => {
        const { order } = this.props

        const timestamp =
            notification.timestamp == null
                ? 'No timestamp'
                : moment(notification.timestamp)
                      .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                      .format('YYYY-MM-DD HH:mm')

        const isPhoneNumber = notification.type.toLowerCase().startsWith('sms:')

        return (
            <tr key={notification.id + notification.timestamp + notification.event}>
                <td>{notification.type}</td>
                <td>{notification.event}</td>
                <td style={cellStyle}>{timestamp}</td>
                {isPhoneNumber ? (
                    <td>
                        <a href={`tel:${notification.to}`}>{notification.to}</a>
                    </td>
                ) : (
                    <td>{notification.to}</td>
                )}
                <td>{notification.message}</td>
                <td>{notification.extra != null ? notification.extra.reason : ''}</td>
            </tr>
        )
    }

    render() {
        const { notificationLog } = this.state

        if (notificationLog == null || notificationLog.length === 0) {
            return null
        }

        return (
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Timestamp</th>
                        <th>To</th>
                        <th>Message</th>
                        <th>Info</th>
                    </tr>
                </thead>
                <tbody>{notificationLog.map(this.renderRow)}</tbody>
            </Table>
        )
    }
}

NotificationsLog.propTypes = {
    order: PropTypes.object.isRequired,
}
