import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import moment from 'moment'

import mapTimeZoneToCountry from '../../../utils/timezone'
import { mapSort, sortNumbers, reverseSorter } from '../../../utils/sorting'
import { pluck } from '../../../utils/pluck'
import { getOrderEndCustomerHistory } from '../../../utils/order-webapi'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm'

/**
 * Render changes made to Consumer information, Addresses and Order Settings
 */
export default class EndcustomerChangeLog extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            changes: [],
        }

        this.prettyChangeValue = this.prettyChangeValue.bind(this)
        this.renderChange = this.renderChange.bind(this)
    }

    componentDidMount() {
        if (this.props.order) {
            this._updateEndCustomerChangeLog()
        }
    }

    equals(current, previous, key) {
        const isEmpty = (thing) => thing === undefined || thing === null || thing === ''
        if (isEmpty(current) && isEmpty(previous)) {
            return true
        }
        if (isEmpty(current) || isEmpty(previous)) {
            return false
        }

        if (key.toLowerCase().endsWith('coordinate')) {
            return current.latitude === previous.latitude && current.longitude === previous.longitude
        }

        return current === previous
    }

    diff(changes, currentValue) {
        if (!changes || changes.length === 0) {
            return []
        }

        const sorted = changes
            .concat({
                date: Date.now(),
                ...currentValue,
            })
            .sort(reverseSorter(mapSort(sortNumbers, pluck('date'))))

        const diff = []

        for (let i = 0; i < sorted.length; i++) {
            if (i + 1 === sorted.length) {
                break
            }

            const current = sorted[i]
            const prev = sorted[i + 1]

            for (const key in current) {
                if (['id', 'date', 'user'].includes(key)) {
                    continue
                }
                if (
                    current.hasOwnProperty(key) &&
                    prev.hasOwnProperty(key) &&
                    !this.equals(current[key], prev[key], key)
                ) {
                    diff.push({
                        date: prev.date,
                        user: prev.user,
                        key,
                        from: prev[key],
                        to: current[key],
                    })
                }
            }
        }

        return diff
    }

    prettyChangeValue(key, value) {
        if (value === null || typeof value === 'undefined' || value === '') {
            return <span className="text-muted">N/A</span>
        }
        if (typeof value === 'boolean') {
            return value ? (
                <span className="label label-success">Yes</span>
            ) : (
                <span className="label label-danger">No</span>
            )
        }
        if (key.toLowerCase().endsWith('coordinate')) {
            return `${value.latitude}, ${value.longitude}`
        }
        return value
    }

    prettyUser(user) {
        if (!user) {
            return `${this.props.order.endCustomer.name} (Consumer)`
        }

        return `${user.firstName} ${user.lastName}`
    }

    _updateEndCustomerChangeLog = () => {
        // Retrieve a list of changes made to the order regarding end customer and
        // delivery address settings
        getOrderEndCustomerHistory(this.props.order.token)
            .then((res) => {
                const changes = []
                    .concat(
                        this.diff(res.orderSettingsHistory, this.props.order.settings),
                        this.diff(res.endCustomerHistory, this.props.order.endCustomer),
                        this.diff(res.addressHistory, this.props.order.deliveryAddress),
                        this.diff(res.addressSettingsHistory, this.props.order.deliveryAddress.settings),
                    )
                    .sort(reverseSorter(mapSort(sortNumbers, pluck('date'))))

                this.setState({ changes })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    renderChange(change, i) {
        const { order } = this.props
        return (
            <tr key={i}>
                <td>
                    <strong>
                        {moment(change.date)
                            .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                            .format(DATE_FORMAT)}
                    </strong>
                </td>
                <td>{this.prettyUser(change.user)}</td>
                <td className="text-muted">changed</td>
                <td>{change.key}</td>
                <td className="text-muted">from</td>
                <td>{this.prettyChangeValue(change.key, change.from)}</td>
                <td className="text-muted">to</td>
                <td>{this.prettyChangeValue(change.key, change.to)}</td>
            </tr>
        )
    }

    render() {
        if (!this.props.order) {
            return null
        }

        return (
            <Table striped responsive condensed>
                <thead>
                    <tr />
                </thead>
                <tbody>{this.state.changes.map(this.renderChange)}</tbody>
            </Table>
        )
    }
}

EndcustomerChangeLog.PropTypes = {
    order: PropTypes.object.isRequired,
}
