import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import Select from 'react-select'
import { Grid, Row, Col, Table, ControlLabel, FormGroup } from 'react-bootstrap'
import HtmlTitle from '../html-title'
import auth from '../../auth'
import * as actions from '../../actions/user'
import { fetchOwnersWebapi } from '../../actions/owner'
import { mapSort, sortStrings } from '../../utils/sorting'
import { displayName } from '../../utils/display-name'
import TerminalSelect from '../common/terminal-select'
import {
    canFetchCouriers,
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    COURIER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TRAFFIC_CONTROLLER,
    USER_MANAGER,
} from '../../utils/role'

const toOption = ({ id: value, name: label }) => ({ value, label })

const RenderTableRow = ({ id, firstName, lastName, email, phoneNumber }) => (
    <tr key={id}>
        <td>{id}</td>
        <td>{displayName(' ', firstName, lastName)}</td>
        <td>{email}</td>
        <td>{phoneNumber}</td>
        <td>
            <Link to={`/admin/users/${id}`}>View</Link>
        </td>
    </tr>
)

RenderTableRow.propTypes = {
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
}

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            terminal: null,
            courier: null,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            COURIER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
            USER_MANAGER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin/unauthorized')
            return
        }

        this.props.actions.fetchUsers()
        this.props.actions.fetchCurrentUser()
        if (canFetchCouriers()) {
            this.props.courierActions.fetchOwners()
        }
    }

    onCourierChange = (courier) => {
        if (courier !== null) {
            this.props.actions.getCourierUsers(courier.value)
        } else {
            this.props.actions.fetchUsers()
        }
        this.setState({
            courier,
        })
    }

    onTerminalChange = (terminal) => {
        this.setState({ terminal })
    }

    getCourierSelectBox() {
        const { courier } = this.state
        return (
            <FormGroup controlId="courier">
                <ControlLabel>Courier</ControlLabel>
                <Select
                    componentClass="select"
                    placeholder="Select courier..."
                    value={courier}
                    onChange={this.onCourierChange}
                    options={this.props.couriers.map(toOption)}
                    clearable
                    searchable
                />
            </FormGroup>
        )
    }

    canViewImages = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    filterByTerminal = (user) => {
        const { terminal } = this.state
        return terminal == null
            ? true
            : user.userSettings.warehouse !== null && user.userSettings.warehouse.id === terminal.id
    }

    renderHeader() {
        const offset = canFetchCouriers() ? 0 : 3
        return (
            <Row>
                {canFetchCouriers() ? this.renderSelectCourier() : null}
                <Col md={3} mdOffset={offset}>
                    <FormGroup controlId="terminal">
                        <ControlLabel>Terminal</ControlLabel>
                        <TerminalSelect onSelect={this.onTerminalChange} />
                    </FormGroup>
                </Col>
                <Col sm={2} md={4} mdOffset={offset} className="text-right">
                    <div className="mt-sm">
                        <Link to="/admin/users/create" className="btn btn-primary">
                            Create
                        </Link>
                        {this.canViewImages ? (
                            <Link to="/admin/users/images/recent" className="btn btn-default">
                                View Images
                            </Link>
                        ) : null}
                    </div>
                </Col>
            </Row>
        )
    }

    renderSelectCourier() {
        return (
            <Col md={3} mdOffset={1}>
                {this.getCourierSelectBox()}
            </Col>
        )
    }

    render() {
        return (
            <div>
                <HtmlTitle title="Users" />
                <Loader color="#BFBFBF" loaded={!this.props.loading}>
                    <Grid fluid>
                        {this.renderHeader()}
                        <Row>
                            <Col md={10} mdOffset={1}>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone number</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.users
                                            .filter(this.filterByTerminal)
                                            .sort(
                                                mapSort(sortStrings, ({ firstName, lastName }) =>
                                                    displayName(' ', firstName, lastName),
                                                ),
                                            )
                                            .map(RenderTableRow)}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Grid>
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = ({ users: { loading, data: users, current: me }, owners: { data: couriers } }) => ({
    loading,
    users,
    me,
    couriers,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    courierActions: {
        fetchOwners: () => fetchOwnersWebapi()(dispatch),
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(List)

List.propTypes = {
    actions: PropTypes.object.isRequired,
    courierActions: PropTypes.object.isRequired,
    router: PropTypes.object,
    loading: PropTypes.bool,
    users: PropTypes.array,
    couriers: PropTypes.array,
}
