import Api from './api'
import auth from '../auth'
import dev from './dev'

import { PriceTierType } from './types'

const baseUrl = '/api/buyers'
const contentType = dev('application/json', 'application/vnd.budbee.buyers-v1+json')
const readOnlyContentType = 'application/vnd.budbee.read-only.buyers-v1+json'

function pathForPriceTierType(type) {
    switch (type) {
        case PriceTierType.DistributionVolume:
            return 'distribution-volumes'
        case PriceTierType.MerchantParcels:
            return 'merchant-parcels'
        case PriceTierType.MerchantPallets:
            return 'merchant-pallets'
        default:
            return null
    }
}

export default {
    get(id) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(id)
            .get()
    },

    getReadOnly() {
        return new Api(baseUrl)
            .accept(readOnlyContentType)
            .type(readOnlyContentType)
            .header('Authorization', auth.get())
            .get()
    },

    create(request) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .post(request)
    },

    edit(id, request) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(id)
            .put(request)
    },

    delete(id) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(id)
            .delete()
    },

    find(first, limit) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .queryParam('first', first)
            .queryParam('limit', limit)
            .get()
    },

    upload(id, data) {
        const fd = new FormData()
        fd.append('file', data)
        return new Api(baseUrl)
            .accept('multipart/form-data')
            .header('Authorization', auth.get())
            .path(id)
            .path('image')
            .put(fd, false)
    },

    getPricePointList(buyerId, collectionPointId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-points')
            .queryParam('collectionPointId', collectionPointId)
            .get()
    },

    getPriceTiers(type, buyerId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-tiers')
            .path(pathForPriceTierType(type))
            .get()
    },

    savePriceTier(type, buyerId, data) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-tiers')
            .path(pathForPriceTierType(type))
            .post(data)
    },

    deletePriceTier(buyerId, tierId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-tiers')
            .path(tierId)
            .delete()
    },

    createPricePoints(buyerId, request) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-points')
            .post(request)
    },

    getZones(buyerId) {
        return new Api(baseUrl, buyerId)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('postal-code-zones')
            .get()
    },

    removePricePoints(buyerId, pricePointIds) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('price-points')
            .delete(pricePointIds)
    },

    getSms(buyerId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('sms')
            .get()
    },

    createPreview(buyerId, template) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('sms-preview')
            .post({ template })
    },

    createSms(buyerId, type, message) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('sms')
            .post({ type, message })
    },

    findCpForBuyer(buyerId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('collection-points')
            .get()
    },

    findScheduleForCp(buyerId, cpId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('collection-points')
            .path(cpId)
            .path('schedules')
            .get()
    },

    addCpSchedule(buyerId, collectionPointSchedule) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('collection-points')
            .path('schedules')
            .post(collectionPointSchedule)
    },

    deleteScheduleById(scheduleId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path('collection-points')
            .path('schedules')
            .path(scheduleId)
            .delete()
    },

    createBuyerCollectionPoint(buyerId, collectionPoint) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('collection-points')
            .post(collectionPoint)
    },

    listSelectedTags(buyerId) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(buyerId)
            .path('tags')
            .get()
    },
}
