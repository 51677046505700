import Webapi from './webapi'
import auth from '../auth'

const merchantsV1 = 'application/vnd.budbee.merchants-v1+json'

const webapi = (accept, contentType = accept) => {
    let w = new Webapi()
        .authorization(auth.get())
        .path('shopping')
        .path('merchants')

    if (accept != null) {
        w.accept(accept)
    }
    if (contentType != null) {
        w = w.contentType(contentType)
    }

    return w
}

export const getIndexingSettings = (countryCode) => {
    return webapi(merchantsV1, merchantsV1)
        .path('indexing-settings')
        .path(countryCode)
        .get()
}

export const updateGlobalIndexingSettings = (request) => {
    return webapi(merchantsV1, merchantsV1)
        .path('indexing-settings')
        .put(request)
}

export const updateMerchantIndexingSettings = (merchantId, countryCode, request) => {
    return webapi(merchantsV1, merchantsV1)
        .path(merchantId)
        .path('indexing-settings')
        .path(countryCode)
        .put(request)
}

export const postScrapeMerchant = (merchantId, countryCode, request) => {
    return webapi(merchantsV1, merchantsV1)
        .path(merchantId)
        .path('indexing-settings')
        .path(countryCode)
        .post(request)
}
