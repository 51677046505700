import React, { Component } from 'react'
import { Modal, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import { DAYS_OF_WEEK } from '../../utils/days-of-week'
import CurrencySelect from '../common/currency-select'
import * as actions from '../../actions/regional-settings'

const RECURRING_INTERVALS = [
    { value: 'WEEKLY', label: 'Weekly' },
    { value: 'MONTHLY', label: 'Monthly' },
]

const toOption = ({ id: value, englishExonym: label }) => ({ value, label })
const toCents = (amount) => amount * 100

class CreateModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            regionalSettings: {},
            description: '',
            customInvoiceDescription: '',
            invoiceAccountType: this.props.invoiceTypes[0],
            invoiceArticle: this.props.invoiceArticles[0],
            amountInCents: 0,
            daysOfWeek: [],
            recurringInterval: RECURRING_INTERVALS[0],
            currency: '',
            country: '',
            city: null,
            validFrom: moment
                .utc()
                .add(1, 'days')
                .format('YYYY-MM-DD'),
            validTo: '',
            copies: 1,
        }
        this.closeModal = this.closeModal.bind(this)
        this.clear = this.clear.bind(this)
        this.validate = this.validate.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    closeModal() {
        this.props.close()
    }

    clear() {
        this.setState({
            regionalSettings: {},
            description: '',
            customInvoiceDescription: '',
            daysOfWeek: [],
            invoiceAccountType: this.props.invoiceTypes[0],
            invoiceArticle: this.props.invoiceArticles[0],
            amountInCents: 0,
            currency: '',
            country: '',
            city: null,
            validFrom: moment
                .utc()
                .add(1, 'days')
                .format('YYYY-MM-DD'),
            validTo: '',
            copies: 1,
        })
    }

    componentDidMount() {
        this.props.actions.fetchRegionalSettings()
    }

    componentWillReceiveProps({ regionalSettings }) {
        this.setState({ regionalSettings })
    }

    onSubmit(e) {
        e.preventDefault()
        for (let i = 0; i < this.state.copies; i += 1) {
            if (this.state.recurringInterval.value === 'WEEKLY') {
                this.state.daysOfWeek.forEach((dayOfWeek) => {
                    this.props
                        .createRecurringInvoiceItem(this.props.invoiceRecipientId, {
                            dayOfWeek: dayOfWeek.value,
                            recurringInterval: this.state.recurringInterval.value,
                            description: this.state.description,
                            customInvoiceDescription: this.state.customInvoiceDescription,
                            invoiceAccountType: this.state.invoiceAccountType.value,
                            invoiceArticle: this.state.invoiceArticle.value,
                            amountInCents: toCents(this.state.amountInCents),
                            currency: { code: this.state.currency },
                            country: this.state.country,
                            city: this.state.city,
                            validFrom: this.state.validFrom,
                            validTo: this.state.validTo,
                        })
                        .then(() => {
                            this.props.loadRecurringInvoiceItems()
                        })
                })
            } else {
                this.props
                    .createRecurringInvoiceItem(this.props.invoiceRecipientId, {
                        dayOfWeek: DAYS_OF_WEEK[0].value,
                        recurringInterval: this.state.recurringInterval.value,
                        description: this.state.description,
                        customInvoiceDescription: this.state.customInvoiceDescription,
                        invoiceAccountType: this.state.invoiceAccountType.value,
                        invoiceArticle: this.state.invoiceArticle.value,
                        amountInCents: toCents(this.state.amountInCents),
                        currency: { code: this.state.currency },
                        country: this.state.country,
                        city: this.state.city,
                        validFrom: this.state.validFrom,
                        validTo: this.state.validTo,
                    })
                    .then(() => {
                        this.props.loadRecurringInvoiceItems()
                    })
            }
        }
        this.closeModal()
    }

    onChange = (prop, name) => (e) => this.setState({ [name]: e.target[prop] })

    validate() {
        return (
            this.state.amountInCents > 0 &&
            (this.state.daysOfWeek.length > 0 || this.state.recurringInterval.value === 'MONTHLY') &&
            this.state.customInvoiceDescription.length < 30 &&
            this.state.country.length > 0 &&
            this.state.currency.length > 0
        )
    }

    render() {
        const dayOfWeekInput = (
            <Select
                multi
                autoBlur
                allowCreate={false}
                backspaceRemoves={false}
                clearable={false}
                ignoreCase
                searchable={false}
                isLoading={false}
                options={DAYS_OF_WEEK}
                value={this.state.daysOfWeek}
                onChange={(item) => this.setState({ daysOfWeek: item })}
                placeholder="Chose reccurrence interval"
                noResultsText="None"
            />
        )
        return (
            <Modal show={this.props.show} animation>
                <div className="panel-heading">
                    <h3 className="panel-title">
                        <i className="glyphicon glyphicon-plus"> </i> Create Invoice items
                    </h3>
                </div>

                <div className="panel-body" style={{ padding: '20px 35px', boxSizing: 'border-box' }}>
                    <form method="post" className="form-horizontal" onSubmit={() => this.onSubmit()}>
                        <Select
                            autoBlur
                            allowCreate={false}
                            backspaceRemoves={false}
                            clearable={false}
                            ignoreCase
                            searchable={false}
                            isLoading={false}
                            options={RECURRING_INTERVALS}
                            value={this.state.recurringInterval}
                            onChange={(item) => this.setState({ recurringInterval: item })}
                            placeholder="Chose recurrence interval"
                        />
                        {this.state.recurringInterval.value === 'WEEKLY' ? dayOfWeekInput : ''}
                        <FormGroup>
                            <ControlLabel>Description for internal reference</ControlLabel>
                            <FormControl
                                type="text"
                                required
                                id="description"
                                value={this.state.description}
                                onChange={this.onChange('value', 'description')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Optional description on invoice (overrides the default)</ControlLabel>
                            <FormControl
                                type="text"
                                required
                                id="customInvoiceDescription"
                                value={this.state.customInvoiceDescription}
                                onChange={this.onChange('value', 'customInvoiceDescription')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Invoice article (shown on invoice)</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={this.props.invoiceArticles}
                                value={this.state.invoiceArticle}
                                onChange={(item) => this.setState({ invoiceArticle: item })}
                                placeholder="Chose invoice type"
                                noResultsText="None"
                                id="article"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Country</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={
                                    this.state.regionalSettings.countries
                                        ? this.state.regionalSettings.countries.map(toOption)
                                        : []
                                }
                                value={this.state.country}
                                onChange={(country) => this.setState({ country: country ? country.value : '' })}
                                placeholder="Select country..."
                                noResultsText="None"
                                id="country"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>City</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={
                                    this.state.regionalSettings.cities
                                        ? this.state.regionalSettings.cities
                                              .filter(({ countryCode }) => countryCode === this.state.country)
                                              .map(toOption)
                                        : []
                                }
                                value={this.state.city}
                                onChange={(city) => this.setState({ city: city ? city.value : null })}
                                placeholder="Optionaly select city..."
                                noResultsText="None"
                                id="city"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Currency</ControlLabel>
                            <CurrencySelect
                                onSelect={(currency) => this.setState({ currency: currency ? currency.value : '' })}
                                id="currency"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Amount in {this.state.currency}</ControlLabel>
                            <FormControl
                                type="number"
                                min="0"
                                required
                                id="amountInCents"
                                value={this.state.amountInCents}
                                onChange={this.onChange('value', 'amountInCents')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Type (Positive or negative) </ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={this.props.invoiceTypes}
                                value={this.state.invoiceAccountType}
                                onChange={(item) => this.setState({ invoiceAccountType: item })}
                                placeholder="Chose invoice type"
                                noResultsText="None"
                                id="type"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Custom start date (eg back in time)</ControlLabel>
                            <FormControl
                                type="text"
                                id="validFrom"
                                value={this.state.validFrom}
                                onChange={this.onChange('value', 'validFrom')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Optional end date</ControlLabel>
                            <FormControl
                                type="text"
                                id="validTo"
                                value={this.state.validTo}
                                onChange={this.onChange('value', 'validTo')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel># of invoices to create (max 10 at the time)</ControlLabel>
                            <FormControl
                                type="number"
                                min="1"
                                max="10"
                                required
                                id="amount"
                                value={this.state.copies}
                                onChange={this.onChange('value', 'copies')}
                            />
                        </FormGroup>
                        <div>
                            <button
                                type="submit"
                                className="pull-left btn btn-primary"
                                disabled={!this.validate()}
                                onClick={this.onSubmit}
                            >
                                Save
                            </button>
                            <button type="button" className="btn btn-default" onClick={() => this.clear()}>
                                Clear
                            </button>
                            <button
                                type="button"
                                className="btn btn-success pull-right"
                                onClick={() => this.closeModal()}
                            >
                                Close
                                <i className="glyphicon glyphicon-remove" />
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        )
    }
}

CreateModal.propTypes = {
    show: PropTypes.bool,
    invoiceRecipientId: PropTypes.number,
    createRecurringInvoiceItem: PropTypes.func,
    loadRecurringInvoiceItems: PropTypes.func,
    close: PropTypes.func,
    invoiceArticles: PropTypes.array,
    invoiceTypes: PropTypes.array,
    regionalSettings: PropTypes.object,
    actions: PropTypes.object,
}

const mapStateToProps = (state) => ({
    regionalSettings: state.regionalSettings,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal)
