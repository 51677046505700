import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const ConsolidationSupport = {
    ACTIVE: 'ACTIVE',
    ACTIVE_ANONYMOUS: 'ACTIVE_ANONYMOUS',
    DISABLED: 'DISABLED',
}

const consolidationSupportOptions = Object.values(ConsolidationSupport).map((value) => {
    return { label: value, value }
})

export default function ConsolidationSupportSelect({ id, onChange, value }) {
    function handleChange(option) {
        onChange(option.value)
    }

    return (
        <Select
            id={id}
            value={value}
            onChange={handleChange}
            options={consolidationSupportOptions}
            placeholder="Select Consolidation Support"
            clearable={false}
        />
    )
}

ConsolidationSupportSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    onChange: PropTypes.func,
}
