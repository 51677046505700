export default () => [
    { value: 'CAR', label: 'Car' },
    { value: 'ELECTRIC_CAR', label: 'Electric Car' },
    { value: 'ELECTRIC_CAR_6_PALLETS', label: 'Electric Car 6 Pallets' },
    { value: 'ELECTRIC_CAR_11_PALLETS', label: 'Electric Car 11 Pallets' },
    { value: 'ENVIRONMENTAL_CAR', label: 'Environmental Car' },
    { value: 'ENVIRONMENTAL_CAR_6_PALLETS', label: 'Environmental Car 6 Pallets' },
    { value: 'ENVIRONMENTAL_CAR_11_PALLETS', label: 'Environmental Car 11 Pallets' },
    { value: 'BICYCLE', label: 'Bicycle' },
    { value: 'TRUCK_8_PALLETS', label: 'Truck 8 Pallets' },
    { value: 'TRUCK_16_PALLETS', label: 'Truck 16 Pallets' },
    { value: 'TRUCK_33_PALLETS', label: 'Truck 33 Pallets' },
    { value: 'TRUCK_51_PALLETS', label: 'Truck 51 Pallets' },
    { value: 'ENVIRONMENTAL_TRUCK_8_PALLETS', label: 'Environmental Truck 8 Pallets' },
    { value: 'ENVIRONMENTAL_TRUCK_16_PALLETS', label: 'Environmental Truck 16 Pallets' },
    { value: 'ENVIRONMENTAL_TRUCK_33_PALLETS', label: 'Environmental Truck 33 Pallets' },
    { value: 'ENVIRONMENTAL_TRUCK_51_PALLETS', label: 'Environmental Truck 51 Pallets' },
]
