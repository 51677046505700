import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl, Glyphicon } from 'react-bootstrap'
import '../../../../styles/less/time.less'
import cx from 'classnames'

const INPUT_LENGTH = 2

const padValue = (rawValue) => {
    if (rawValue === '') {
        return '00'
    }

    return rawValue.padStart(INPUT_LENGTH, '0')
}

class TimeControl extends Component {
    handleUpdate = (unit, value, id) => {
        const updatedTime = {
            ...this.props.time,
            [unit]: value || null,
        }
        if (id && unit === 'hours' && value.length === INPUT_LENGTH) {
            document.getElementById(id).focus()
        }
        if (id && unit === 'minutes' && value.length === INPUT_LENGTH) {
            document.getElementById(id).focus()
        }
        this.props.onChange(updatedTime)
    }

    handleChange = (unit, id) => (e) => {
        this.handleUpdate(unit, e.target.value, id)
    }

    handleBlur = (unit) => (e) => {
        this.handleUpdate(unit, padValue(e.target.value, unit))
    }

    render() {
        const {
            time,
            placeholder,
            disabled,
            controlId,
            classNameHours,
            classNameMinutes,
            focusNextElementById,
        } = this.props

        return (
            <InputGroup className="time-control">
                <InputGroup.Addon>
                    <Glyphicon glyph="time" />
                </InputGroup.Addon>
                <FormControl
                    className={cx(classNameHours, 'time-control-hours')}
                    id={`${controlId}Hours`}
                    type="text"
                    placeholder={placeholder.hours}
                    onChange={this.handleChange('hours', `${controlId}Minutes`)}
                    onBlur={this.handleBlur('hours')}
                    value={time.hours || ''}
                    maxLength={INPUT_LENGTH}
                    disabled={disabled}
                    onFocus={(e) => e.target.select()}
                />
                <InputGroup.Button style={{ width: 0 }}></InputGroup.Button>
                <InputGroup.Addon className={disabled ? 'time-divider disabled' : 'time-divider'}>:</InputGroup.Addon>
                <FormControl
                    className={classNameMinutes ? `${classNameMinutes} time-control-minutes` : 'time-control-minutes'}
                    id={`${controlId}Minutes`}
                    type="text"
                    placeholder={placeholder.minutes}
                    onChange={this.handleChange('minutes', focusNextElementById)}
                    onBlur={this.handleBlur('minutes')}
                    value={time.minutes || ''}
                    maxLength={INPUT_LENGTH}
                    disabled={disabled}
                    onFocus={(e) => e.target.select()}
                />
            </InputGroup>
        )
    }
}

export default TimeControl

TimeControl.propTypes = {
    controlId: PropTypes.string.isRequired,
    time: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    classNameHours: PropTypes.string,
    classNameMinutes: PropTypes.string,
    focusNextElementById: PropTypes.string,
}
