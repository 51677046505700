import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import { Col, Row, Panel } from 'react-bootstrap'
import { connect } from 'react-redux'
import RejectedInvoiceItemList from './rejected-invoice-item-list'
import { INVOICE_TYPES_COURIER } from '../couriers/manage-invoice-items'
import {
    getRejectedInvoiceItems,
    createRejectedInvoiceItem,
    deleteRejectedInvoiceItem,
    getCourierRejectedInvoice,
} from '../../utils/rejection-invoice-webapi'
import { fetchInvoiceSettings } from '../../actions/regional-settings'
import { handleError } from '../../utils/handle-error'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'
import auth from '../../auth'

const toOption = ({ id: value, label }) => ({ value, label })

class RejectedInvoiceItemView extends React.Component {
    constructor(...args) {
        super(...args)
        this.state = {
            rejectedInvoiceId: this.props.params.rejectedInvoiceId,
            courierId: this.props.params.id,
            fetchingRejectedInvoice: true,
            rejectedInvoice: null,
        }
    }

    loadRejectedInvoice = () => {
        this.setState({ fetchingRejectedInvoice: true })
        getCourierRejectedInvoice(this.state.rejectedInvoiceId, this.state.courierId)
            .then((resp) => {
                this.setState({
                    rejectedInvoice: resp,
                    fetchingRejectedInvoice: false,
                })
            })
            .catch((err) => {
                this.setState({ fetchingRejectedInvoice: false })
                handleError(err)
            })
    }

    transitionToCourierPage = () => {
        this.props.router.push('/admin/couriers')
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.transitionToCourierPage()
            return
        }
        const { dispatch } = this.props
        dispatch(fetchInvoiceSettings())
        this.loadRejectedInvoice()
    }

    render() {
        const invoiceHeader = (invoiceId, invoiceNo) => <h4>Manage Invoice: {invoiceNo}</h4>
        if (this.props.loading || this.state.fetchingRejectedInvoice) {
            return <Loader color="#BFBFBF" />
        }
        return (
            <Col md={10} mdOffset={1}>
                <Col md={10} mdOffset={1}>
                    <Row>
                        <h3> Manage rejected invoice</h3>
                        <h5>
                            {' '}
                            Generate invoice: Generated rows will be added automatically to invoice, insert correction
                            rows bellow.
                        </h5>
                        <h5>
                            {' '}
                            Manual invoice: Rows from manual invoices are not added automatically, insert all rows for
                            invoice bellow .
                        </h5>
                    </Row>
                    <Row>
                        <Panel
                            header={invoiceHeader(
                                this.state.rejectedInvoice.invoiceId,
                                this.state.rejectedInvoice.invoiceNo,
                            )}
                        >
                            <RejectedInvoiceItemList
                                rejectedInvoiceId={parseInt(this.state.rejectedInvoiceId, 10)}
                                invoiceCurrency={this.state.rejectedInvoice.currency.code}
                                deleteInvoiceItem={deleteRejectedInvoiceItem}
                                createInvoiceItem={createRejectedInvoiceItem}
                                loadAllInvoiceItems={getRejectedInvoiceItems}
                                invoiceTypes={INVOICE_TYPES_COURIER}
                                invoiceArticles={this.props.invoiceArticles.map(toOption)}
                            />
                        </Panel>
                    </Row>
                </Col>
            </Col>
        )
    }
}

RejectedInvoiceItemView.propTypes = {
    invoiceArticles: PropTypes.array,
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    params: {
        rejectedInvoiceId: PropTypes.number,
        id: PropTypes.number,
    },
    router: PropTypes.object,
}

const mapStateToProps = ({
    invoiceSettings: {
        articles: { courier: invoiceArticles },
    },
}) => ({
    invoiceArticles,
})

export default connect(mapStateToProps)(RejectedInvoiceItemView)
