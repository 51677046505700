import React from 'react'
import { Label } from 'react-bootstrap'
import { DELIVERY, RETURN, RETURN_BULK_SPLIT } from './parcel-types'
import { TOO_LARGE_TO_DELIVER } from './sorting-deviation-types'
import { Delivered, ReturnedToMerchant } from './status'

const REQUESTED_BY_MERCHANT = 'REQUESTED_BY_MERCHANT'
const REQUESTED_BY_BUDBEE = 'REQUESTED_BY_BUDBEE'

export const AVAILABLE_STATUSES = [
    {
        value: 'NotStarted',
        label: 'Not Started',
        applicableToType: ['DELIVERY', 'RETURN', 'RETURN_BULK_SPLIT'],
    },
    {
        value: 'Collected',
        label: 'Collected',
        applicableToType: ['DELIVERY', 'RETURN', 'RETURN_BULK_SPLIT'],
    },
    {
        value: 'CrossDocked',
        label: 'Cross-Docked',
        applicableToType: ['DELIVERY', 'RETURN'],
    },
    {
        value: 'OnRouteDelivery',
        label: 'En Route Delivery',
        applicableToType: ['DELIVERY'],
    },
    {
        value: 'Delivered',
        label: 'Delivered',
        applicableToType: ['DELIVERY'],
    },
    {
        value: 'Backordered',
        label: 'Backordered (Delayed from Merchant)',
        applicableToType: ['DELIVERY'],
    },
    {
        value: 'CollectedShippingLabel',
        label: 'Collected Shipping Label',
        applicableToType: ['RETURN'],
    },
    {
        value: 'ReturnedToTerminal',
        label: 'Returned To Terminal',
        applicableToType: ['DELIVERY', 'RETURN'],
    },
    {
        value: 'ReturnedToMerchant',
        label: 'Returned To Merchant',
        applicableToType: ['DELIVERY', 'RETURN', 'RETURN_BULK_SPLIT'],
    },
]

const recallRequestedBy = (parcel) => {
    switch (parcel.recallReason) {
        case REQUESTED_BY_BUDBEE:
            return 'Budbee'
        case REQUESTED_BY_MERCHANT:
            return 'Merchant'
        default:
            return null
    }
}

export const descriptiveLabel = (parcel) => {
    const requestedRecall = parcel.recallRequestedAt != null
    switch (parcel.type) {
        case DELIVERY:
            return (
                <Label bsStyle="info">
                    Delivery Parcel
                    {requestedRecall && ` (Requested in Return by ${recallRequestedBy(parcel)})`}
                </Label>
            )
        case RETURN:
            return <Label bsStyle="warning">Consumer Return</Label>
        case RETURN_BULK_SPLIT:
            return <Label bsStyle="danger">Return Bulk Split</Label>
        default:
            return null
    }
}

export const sortingDeviationLabel = (parcel) => {
    if (parcel.machineDimensions != null && parcel.machineDimensions.deviation != null) {
        switch (parcel.machineDimensions.deviation) {
            case TOO_LARGE_TO_DELIVER:
                return <Label bsStyle="danger">Large parcel</Label>
            default:
                return null
        }
    } else return null
}

export const currentStatus = (parcel) => {
    const psu = parcel.parcelStatusUpdates
    const last = psu[psu.length - 1]

    if (parcel.type !== RETURN) {
        return last
    }

    if (psu.length >= 2) {
        const secondLast = psu[psu.length - 2]
        if (secondLast && secondLast.date === last.date) {
            return secondLast
        }
    }

    return last
}

export const isReturnable = (parcel) => {
    if (parcel.type !== DELIVERY) {
        return false
    }

    if ([Delivered, ReturnedToMerchant].includes(currentStatus(parcel).status)) {
        return false
    }

    return parcel.recallRequestedAt === null
}

export const isBulkSplit = (parcel) => {
    return parcel.type === RETURN_BULK_SPLIT
}

export const hasPallets = (parcels) => parcels.some((parcel) => isBulkSplit(parcel))

export const isParcelStatus = (status) => {
    return AVAILABLE_STATUSES.some((el) => {
        return el.value === status
    })
}

export const getDeliveredStatusVariant = (status) => {
    if (status.status === Delivered) {
        if (status.outsideDoor) {
            return 'Delivered outside door'
        }

        if (status.withNeighbour) {
            return (
                <span>
                    Delivered to neighbour
                    <br />
                    <small>
                        {status.neighbourName} / {status.neighbourAddress}
                    </small>
                </span>
            )
        }
    }

    return status.status
}
