import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import {
    Modal,
    Button,
    Glyphicon,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    Checkbox,
    Table,
    Label,
    Radio,
} from 'react-bootstrap'
import DatePicker from 'react-bootstrap-date-picker'
import Select from 'react-select'
import moment from 'moment'

import TerminalSelect from '../../../../common/terminal-select'
import { toggleLaneEnabled } from '../../../../../actions/terminal-rules'
import { temporalOptions } from '../../../../../utils/sorting'
import {
    DATE,
    DISTRIBUTION_ROUTE,
    LOCKER,
    LOCKER_LINEHAUL,
    LOCKER_STOP,
    MERCHANT_PRIORITY,
    OVERSIZE_LOCKER_PARCEL,
    READ_FAIL,
    RETURN_LINEHAUL,
    TEMPORAL,
    LINEHAUL,
    RETURN_TO_MERCHANT,
    LINEHAUL_HUB_OR_REGION,
    ON_DEMAND_ROUTE,
} from '../../../../../utils/terminal-sorting-rule-types'
import { getHubAndRegionOptions } from './get-hub-and-region-options'
import MerchantGroupSelect from './merchant-group-select'
import MerchantSearchSelect from './merchant-search-select'
import OnDemandRouteSelect from './on-demand-route-select-form-group'
import { getOnDemandRoutes } from '../../../../../utils/route-webapi'

const ruleTypes = [
    { value: DISTRIBUTION_ROUTE, label: 'Distribution Route' },
    { value: DATE, label: 'Date' },
    { value: TEMPORAL, label: 'Date (v2)' },
    { value: LINEHAUL, label: 'Linehaul to another City' },
    { value: RETURN_LINEHAUL, label: 'Linehaul for Returns' },
    { value: LOCKER_LINEHAUL, label: 'Linehaul to another City (LOCKER DELIVERY)' },
    { value: LINEHAUL_HUB_OR_REGION, label: 'Linehaul to Hub or Region' },
    { value: LOCKER, label: 'Box Locker' },
    { value: MERCHANT_PRIORITY, label: 'Merchant Priority' },
    { value: READ_FAIL, label: 'Reject (if no other rule matches)' },
    { value: OVERSIZE_LOCKER_PARCEL, label: "Oversize locker parcel (won't fit in a box)" },
    { value: LOCKER_STOP, label: 'Locker Route' },
    { value: RETURN_TO_MERCHANT, label: 'Merchant Return' },
    { value: ON_DEMAND_ROUTE, label: 'On Demand Route' },
]

const EXCLUSIVE_MERCHANT_PRIORITY = 'EXCLUSIVE'
const NON_EXCLUSIVE_MERCHANT_PRIORITY = 'NON_EXCLUSIVE'

class RouteSortingConfigurationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            laneRoute: null,
            dateRuleDate: null,
            recurToday: false,
            greaterThan: false,
            lesserThan: false,
            terminalId: null,
            lockerId: null,
            temporal: null,
            laneEnabled: null,
            lockerStop: null,
            selectAllStops: true,
            hubOrRegionOption: null,
            exclusiveMerchantPriority: false,
        }
    }

    enabledRuleTypes = this.setUpEnabledRuleTypes()

    componentDidUpdate(prevProps) {
        this.updateLaneEnabled(prevProps)
    }

    updateLaneEnabled = (prevProps) => {
        if (prevProps !== this.props && this.props.editingLane) {
            this.setState({ laneEnabled: this.props.editingLane.enabled })
        }
    }

    distributionRouteOptions = () => {
        const { routes, routeRules } = this.props
        return routes
            .filter((route) => routeRules.every((rule) => rule.route.id !== route.id))
            .map((route) => ({
                label: `#${route.id} (${route.city}) Loading: ${moment(route.eta).format('HH:mm')}`,
                value: route.id,
            }))
    }

    getOnDemandRouteOptions = (merchantId) => {
        const { onDemandRouteRules, terminalId } = this.props
        return getOnDemandRoutes(terminalId, merchantId).then((onDemandRoutes) => {
            return onDemandRoutes
                .filter((route) => onDemandRouteRules.every((rule) => rule.route.id !== route.id))
                .map((route) => ({
                    label: `ODR#${route.id} Loading: ${moment(route.eta).format('HH:mm')}`,
                    value: route.id,
                }))
        })
    }

    onSelectLockerRoute = (lockerRoute) => {
        this.setState({ lockerRoute }, () => this.props.getAllLockerStops(lockerRoute.value))
    }

    onSelectAllStops = (selectAllStops) => {
        this.setState({ selectAllStops, lockerStop: null })
    }

    onSelectLockerStop = (lockerStop) => {
        this.setState({ lockerStop, selectAllStops: false })
    }

    onSaveLockerStopRule = () => {
        const { selectAllStops, lockerStop } = this.state
        const { lockerStops } = this.props

        if (selectAllStops) {
            const ids = lockerStops.map((x) => x.id)
            this.props.onSaveMultipleLockerStopRules(ids)

            return
        }

        this.props.onSaveLockerStopRule(lockerStop.value)
    }

    lockerRouteOptions = () => {
        const { lockerRoutes } = this.props
        return lockerRoutes.map((route) => ({
            label: `#${route.id} (${route.name})`,
            value: route.id,
        }))
    }

    lockerStopOptions = () => {
        const { lockerStops } = this.props
        return lockerStops.map((stop) => ({
            label: stop.lockerName,
            value: stop.id,
        }))
    }

    lockerOptions = () => {
        const { lockers, lockerRules } = this.props
        return lockers
            .filter((locker) => lockerRules.every((rule) => rule.locker.id !== locker.id))
            .map((locker) => ({
                label: `${locker.identifier} (${locker.name})`,
                value: locker.id,
            }))
    }

    getSelectedHubOrRegionRules = () => {
        const { rules, editingLane } = this.props
        return rules.filter(
            ({ type, terminalSortingLaneId }) =>
                type === LINEHAUL_HUB_OR_REGION && terminalSortingLaneId === editingLane.id,
        )
    }

    linehaulHubOrRegionOptions = () => {
        const { hubPallets, terminalId } = this.props
        const options = getHubAndRegionOptions(hubPallets, terminalId)
        const selectedHubOrRegionRules = this.getSelectedHubOrRegionRules()
        return options.filter(
            ({ groupWarehouseId, groupWarehouseType }) =>
                !selectedHubOrRegionRules.some(
                    ({ attributes }) =>
                        Number(attributes.groupWarehouseId) === groupWarehouseId &&
                        attributes.groupWarehouseType === groupWarehouseType,
                ),
        )
    }

    onMerchantRouteGroupSelect = (option) => {
        this.setState({
            merchantRouteSelectedGroup: option,
            merchantRouteSelectedMerchant: null,
        })
    }

    onMerchantRouteMerchantSelect = (option) => {
        this.setState({
            merchantRouteSelectedMerchant: option,
            merchantRouteSelectedGroup: null,
        })
    }

    onMerchantPriorityGroupSelect = (option) => {
        this.setState({
            merchantPrioritySelectedGroup: option,
            merchantPrioritySelectedMerchant: null,
        })
    }

    onMerchantPriorityMerchantSelect = (option) => {
        this.setState({
            merchantPrioritySelectedMerchant: option,
            merchantPrioritySelectedGroup: null,
        })
    }

    onSaveReturnToMerchantRule = () => {
        this.props.onSaveReturnToMerchantRule(
            this.state.merchantRouteSelectedMerchant && this.state.merchantRouteSelectedMerchant.value,
            this.state.merchantRouteSelectedGroup && this.state.merchantRouteSelectedGroup.value,
        )
        this.setState({
            merchantRouteSelectedMerchant: null,
            merchantRouteSelectedGroup: null,
        })
    }

    onSaveMerchantPriorityRule = () => {
        this.props.onSaveMerchantPriorityRule(
            this.state.merchantPrioritySelectedMerchant && this.state.merchantPrioritySelectedMerchant.value,
            this.state.merchantPrioritySelectedGroup && this.state.merchantPrioritySelectedGroup.value,
            this.state.exclusiveMerchantPriority ? EXCLUSIVE_MERCHANT_PRIORITY : NON_EXCLUSIVE_MERCHANT_PRIORITY,
        )
        this.setState({
            merchantPrioritySelectedMerchant: null,
            merchantPrioritySelectedGroup: null,
        })
    }

    onSaveLinehaulHubOrRegionRule = () => {
        const { groupWarehouseId, groupWarehouseType } = this.state.hubOrRegionOption
        this.props.onSaveLinehaulHubOrRegionRule(groupWarehouseId, groupWarehouseType)
        this.setState({ hubOrRegionOption: null })
    }

    getSelectedMerchantIds = (sortingType) => {
        const { rules, editingLane } = this.props

        const merchantRules = rules.filter(
            ({ type, terminalSortingLaneId }) => type === sortingType && terminalSortingLaneId === editingLane.id,
        )

        const merchantIds = merchantRules.map(({ attributes }) => Number(attributes.merchantId))
        return new Set(merchantIds)
    }

    getSelectedMerchantGroupIds = (sortingType) => {
        const { rules, editingLane } = this.props

        const merchantRules = rules.filter(
            ({ type, terminalSortingLaneId }) => type === sortingType && terminalSortingLaneId === editingLane.id,
        )

        const merchantGroupIds = merchantRules.map(({ attributes }) => Number(attributes.merchantGroupId))
        return new Set(merchantGroupIds)
    }

    getMerchantPrioRulesOnLane = (laneId) => {
        const { rules } = this.props
        return rules.filter(
            ({ type, terminalSortingLaneId }) => type === MERCHANT_PRIORITY && terminalSortingLaneId === laneId,
        )
    }

    shouldAllowInclusiveRuleForLane = () => {
        const { editingLane } = this.props
        const prioRulesOnLane = this.getMerchantPrioRulesOnLane(editingLane.id)
        const isInclusive = (rule) =>
            rule.attributes.exclusivePriority == null ||
            rule.attributes.exclusivePriority === NON_EXCLUSIVE_MERCHANT_PRIORITY
        return prioRulesOnLane.length === 0 || prioRulesOnLane.every(isInclusive)
    }

    shouldAllowExclusiveRuleForLane = () => {
        const { editingLane } = this.props
        const prioRulesOnLane = this.getMerchantPrioRulesOnLane(editingLane.id)
        return prioRulesOnLane.length === 0 || !this.shouldAllowInclusiveRuleForLane()
    }

    canSaveReturnToMerchantRule = () => {
        const { merchantRouteSelectedGroup, merchantRouteSelectedMerchant } = this.state
        if (merchantRouteSelectedGroup) {
            return !this.getSelectedMerchantGroupIds(RETURN_TO_MERCHANT).has(merchantRouteSelectedGroup.value)
        }
        if (merchantRouteSelectedMerchant) {
            return !this.getSelectedMerchantIds(RETURN_TO_MERCHANT).has(merchantRouteSelectedMerchant.value)
        }
        return false
    }

    canSaveMerchantPriorityRule = () => {
        const { merchantPrioritySelectedGroup, merchantPrioritySelectedMerchant } = this.state
        if (merchantPrioritySelectedGroup) {
            return !this.getSelectedMerchantGroupIds(MERCHANT_PRIORITY).has(merchantPrioritySelectedGroup.value)
        }
        if (merchantPrioritySelectedMerchant) {
            return !this.getSelectedMerchantIds(MERCHANT_PRIORITY).has(merchantPrioritySelectedMerchant.value)
        }
        return false
    }

    renderRuleAdd(type) {
        if (type == null) {
            return null
        }
        switch (type.value) {
            case DISTRIBUTION_ROUTE:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Distribution Route</ControlLabel>
                            <Select
                                style={{ zIndex: 3 }}
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable
                                options={this.distributionRouteOptions()}
                                value={this.state.laneRoute}
                                onChange={(laneRoute) => this.setState({ laneRoute })}
                                placeholder="Select route"
                                noResultsText="None available..."
                            />
                        </FormGroup>
                        <Button
                            disabled={!this.state.laneRoute}
                            bsStyle="warning"
                            bsSize="small"
                            onClick={() => this.props.onSaveRouteRule(this.state.laneRoute)}
                        >
                            Save
                        </Button>
                    </div>
                )
            case DATE:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Date</ControlLabel>
                            <DatePicker
                                id="dateRule"
                                dateFormat="YYYY-MM-DD"
                                value={this.state.dateRuleDate}
                                placeholder="Select date"
                                onChange={(dateRuleDate) =>
                                    this.setState({ dateRuleDate: moment(dateRuleDate).format('YYYY-MM-DD') })
                                }
                            />
                            <Checkbox
                                value={this.state.recurToday}
                                onChange={(e) => this.setState({ recurToday: e.target.checked })}
                            >
                                Todays date
                            </Checkbox>
                            <Checkbox
                                value={this.state.lesserThan}
                                onChange={(e) => this.setState({ lesserThan: e.target.checked })}
                            >
                                Before
                            </Checkbox>
                            <Checkbox
                                value={this.state.greaterThan}
                                onChange={(e) => this.setState({ greaterThan: e.target.checked })}
                            >
                                After
                            </Checkbox>
                            <Button
                                disabled={!this.state.dateRuleDate}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() =>
                                    this.props.onSaveDateRule(
                                        this.state.dateRuleDate,
                                        this.state.recurToday,
                                        this.state.lesserThan,
                                        this.state.greaterThan,
                                    )
                                }
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case TEMPORAL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Period</ControlLabel>
                            <Select
                                style={{ zIndex: 3 }}
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable
                                options={temporalOptions}
                                value={this.state.temporal}
                                onChange={(temporal) => this.setState({ temporal: temporal.value })}
                                placeholder="Select period"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                disabled={!this.state.temporal}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveTemporalRule(this.state.temporal)}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case LINEHAUL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Distribution Terminal</ControlLabel>
                            <TerminalSelect onSelect={(terminal) => this.setState({ terminalId: terminal.id })} />
                            <br />
                            <Button
                                disabled={!this.state.terminalId}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveTerminalRule(this.state.terminalId)}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case RETURN_LINEHAUL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Destination Terminal</ControlLabel>
                            <TerminalSelect onSelect={(terminal) => this.setState({ terminalId: terminal.id })} />
                            <br />
                            <Button
                                disabled={!this.state.terminalId}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveReturnLinehaulRule(this.state.terminalId)}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case LOCKER_LINEHAUL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Distribution Terminal</ControlLabel>
                            <TerminalSelect onSelect={(terminal) => this.setState({ terminalId: terminal.id })} />
                            <br />
                            <Button
                                disabled={!this.state.terminalId}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveLockerLinehaulRule(this.state.terminalId)}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case LOCKER:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Select Locker</ControlLabel>
                            <Select
                                style={{ zIndex: 3 }}
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable
                                options={this.lockerOptions()}
                                value={this.state.lockerId}
                                onChange={(locker) => this.setState({ lockerId: locker.value })}
                                placeholder="Select Locker"
                                noResultsText="None available..."
                            />
                            <br />
                            <Button
                                disabled={!this.state.lockerId}
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveLockerRule(this.state.lockerId)}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case READ_FAIL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Reject</ControlLabel>
                            <br />
                            <Button bsStyle="warning" bsSize="small" onClick={() => this.props.onSaveReadFailRule()}>
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case OVERSIZE_LOCKER_PARCEL:
                return (
                    <div>
                        <FormGroup>
                            <ControlLabel>Oversize Locker Parcel</ControlLabel>
                            <br />
                            <Button
                                bsStyle="warning"
                                bsSize="small"
                                onClick={() => this.props.onSaveOversizeLockerParcelRule()}
                            >
                                Save
                            </Button>
                        </FormGroup>
                    </div>
                )
            case LOCKER_STOP:
                return (
                    <div>
                        <FormGroup>
                            <div>
                                <ControlLabel>Select Route</ControlLabel>
                                <Select
                                    style={{ zIndex: 3 }}
                                    autoBlur
                                    allowCreate={false}
                                    backspaceRemoves={false}
                                    clearable={false}
                                    ignoreCase
                                    searchable
                                    options={this.lockerRouteOptions()}
                                    value={this.state.lockerRoute}
                                    onChange={this.onSelectLockerRoute}
                                    placeholder="Select Locker Route"
                                    noResultsText="None available..."
                                />
                            </div>
                            {this.state.lockerRoute && (
                                <div>
                                    <br />
                                    <ControlLabel>Select Stop</ControlLabel>
                                    {this.renderLockerStopSelect()}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <div>
                                {this.state.lockerRoute != null ? (
                                    <Checkbox
                                        checked={this.state.selectAllStops}
                                        value={this.state.selectAllStops}
                                        onChange={(e) => this.onSelectAllStops(e.target.checked)}
                                    >
                                        Select all Stops
                                    </Checkbox>
                                ) : null}
                            </div>
                            <div>
                                <br />
                                <Button
                                    disabled={
                                        (!this.state.lockerStop && !this.state.selectAllStops) ||
                                        this.state.lockerRoute == null
                                    }
                                    bsStyle="warning"
                                    bsSize="small"
                                    onClick={this.onSaveLockerStopRule}
                                >
                                    Save
                                </Button>
                            </div>
                        </FormGroup>
                    </div>
                )
            case MERCHANT_PRIORITY:
                return (
                    <div>
                        <ControlLabel>Select merchant</ControlLabel>
                        <MerchantSearchSelect
                            selectedMerchantId={
                                this.state.merchantPrioritySelectedMerchant &&
                                this.state.merchantPrioritySelectedMerchant.value
                            }
                            onChange={this.onMerchantPriorityMerchantSelect}
                            excludeMerchantsInGroups
                        />
                        Or
                        <br />
                        <ControlLabel>Select merchant group</ControlLabel>
                        <MerchantGroupSelect
                            selected={
                                this.state.merchantPrioritySelectedGroup && this.state.merchantPrioritySelectedGroup
                            }
                            onChange={this.onMerchantPriorityGroupSelect}
                            multi={false}
                        />
                        <br />
                        <FormGroup>
                            <Radio
                                className="radio-inline"
                                checked={!this.state.exclusiveMerchantPriority}
                                disabled={!this.shouldAllowInclusiveRuleForLane()}
                                title={
                                    !this.shouldAllowInclusiveRuleForLane()
                                        ? 'Can not add non-exclusive rule to lane with exclusive rule'
                                        : 'Non-exclusive'
                                }
                                onChange={() => this.setState({ exclusiveMerchantPriority: false })}
                            />
                            <ControlLabel>Non-exclusive</ControlLabel>
                        </FormGroup>
                        <FormGroup>
                            <Radio
                                className="radio-inline"
                                checked={this.state.exclusiveMerchantPriority}
                                disabled={!this.shouldAllowExclusiveRuleForLane()}
                                title="Exclusive"
                                onChange={() => this.setState({ exclusiveMerchantPriority: true })}
                            />
                            <ControlLabel>Exclusive (only allow the chosen merchant/group)</ControlLabel>
                        </FormGroup>
                        <Button
                            disabled={!this.canSaveMerchantPriorityRule()}
                            bsStyle="warning"
                            bsSize="small"
                            onClick={this.onSaveMerchantPriorityRule}
                        >
                            Save
                        </Button>
                    </div>
                )
            case RETURN_TO_MERCHANT:
                return (
                    <div>
                        <ControlLabel>Select merchant</ControlLabel>
                        <MerchantSearchSelect
                            selectedMerchantId={
                                this.state.merchantRouteSelectedMerchant &&
                                this.state.merchantRouteSelectedMerchant.value
                            }
                            onChange={this.onMerchantRouteMerchantSelect}
                            excludeMerchantsInGroups
                        />
                        Or
                        <br />
                        <ControlLabel>Select merchant group</ControlLabel>
                        <MerchantGroupSelect
                            selected={this.state.merchantRouteSelectedGroup && this.state.merchantRouteSelectedGroup}
                            onChange={this.onMerchantRouteGroupSelect}
                            multi={false}
                        />
                        <br />
                        <Button
                            disabled={!this.canSaveReturnToMerchantRule()}
                            bsStyle="warning"
                            bsSize="small"
                            onClick={this.onSaveReturnToMerchantRule}
                        >
                            Save
                        </Button>
                    </div>
                )
            case LINEHAUL_HUB_OR_REGION:
                return (
                    <FormGroup>
                        <ControlLabel>Select Hub or Region</ControlLabel>
                        <Select
                            style={{ zIndex: 3 }}
                            autoBlur
                            allowCreate={false}
                            backspaceRemoves={false}
                            clearable={false}
                            ignoreCase
                            searchable
                            options={this.linehaulHubOrRegionOptions()}
                            value={this.state.hubOrRegionOption}
                            onChange={(hubOrRegionOption) => this.setState({ hubOrRegionOption })}
                            placeholder="Select Hub or Region"
                            noResultsText="None available..."
                        />
                        <br />
                        <Button
                            disabled={!this.state.hubOrRegionOption}
                            bsStyle="warning"
                            bsSize="small"
                            onClick={this.onSaveLinehaulHubOrRegionRule}
                        >
                            Save
                        </Button>
                    </FormGroup>
                )
            case ON_DEMAND_ROUTE:
                return (
                    <div>
                        <OnDemandRouteSelect
                            merchantGetter={this.getSelectedMerchantIds}
                            onDemandRouteOptionsGetter={this.getOnDemandRouteOptions}
                            onSave={(selected) => {
                                this.props.onSaveOnDemandRouteRule(selected)
                            }}
                        />
                    </div>
                )
            default:
                return null
        }
    }

    renderLockerStopSelect() {
        if (!this.props.lockerStops.length) {
            return null
        }

        return (
            <Select
                style={{ zIndex: 3 }}
                autoBlur
                allowCreate={false}
                backspaceRemoves={false}
                clearable={false}
                ignoreCase
                searchable
                options={this.lockerStopOptions()}
                onChange={this.onSelectLockerStop}
                value={this.state.lockerStop}
                placeholder="Select Locker Stop"
                noResultsText="None available..."
            />
        )
    }

    onToggleEnabled(enabled) {
        const { terminalId, editingLane, dispatch } = this.props
        this.setState({ laneEnabled: enabled })
        dispatch(toggleLaneEnabled(terminalId, editingLane.id, enabled))
    }

    renderEnabledCheckbox() {
        const { laneEnabled } = this.state

        const label = laneEnabled ? (
            <Label bsStyle="success">Lane is ENABLED</Label>
        ) : (
            <Label bsStyle="danger">Lane is DISABLED</Label>
        )

        return (
            <Checkbox checked={Boolean(laneEnabled)} onChange={(e) => this.onToggleEnabled(e.target.checked)}>
                <h4 style={{ display: 'inline' }}>{label}</h4>
            </Checkbox>
        )
    }

    setDefaultExclusivityForLane = () => {
        const shouldAllowExclusive = this.shouldAllowExclusiveRuleForLane()
        const shouldAllowInclusive = this.shouldAllowInclusiveRuleForLane()
        const checkInclusive = (shouldAllowExclusive && shouldAllowInclusive) || shouldAllowInclusive
        this.setState({ exclusiveMerchantPriority: !checkInclusive })
    }

    onModalOpen = () => {
        this.setDefaultExclusivityForLane()
    }

    render() {
        const { editingLane, onDeleteRule } = this.props

        const { rules, routeRules, lockerRules } = this.props

        if (rules == null || editingLane == null || routeRules == null || lockerRules == null) {
            return null
        }

        const laneRules = rules.filter((rule) => rule.terminalSortingLaneId === editingLane.id)

        return (
            <Modal
                className="sorting-modal"
                show={this.props.show}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg"
                onEntered={this.onModalOpen}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">Configuration of lane {editingLane.name}</Modal.Title>
                    {this.props.canEditLaneEnablement && this.renderEnabledCheckbox()}
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You can assign as many rules as needed. If a Parcel matches any of the rules, it will be sorted
                        onto this lane.
                    </p>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Rule Type</strong>
                                </th>
                                <th>
                                    <strong>Value</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {laneRules.map((rule) =>
                                rule.type === 'DISTRIBUTION_ROUTE' ? (
                                    <tr key={rule.id + rule.type}>
                                        <td>{rule.type}</td>
                                        <td>
                                            <Link to={`/admin/terminal-sorting/${rule.route}`}>{rule.route}</Link>
                                        </td>
                                        <td>
                                            <Button
                                                type="button"
                                                className=""
                                                bsStyle="default"
                                                bsSize="sm"
                                                onClick={() => onDeleteRule(rule.type, rule)}
                                            >
                                                <Glyphicon glyph="trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr key={rule.id + rule.type}>
                                        <td>{rule.type}</td>
                                        <td>{rule.name}</td>
                                        <td>
                                            <Button
                                                type="button"
                                                className=""
                                                bsStyle="default"
                                                bsSize="sm"
                                                onClick={() => onDeleteRule(rule.type, rule)}
                                            >
                                                <Glyphicon glyph="trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </Table>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Add new rule</ControlLabel>
                                <Select
                                    autoBlur
                                    allowCreate={false}
                                    backspaceRemoves={false}
                                    clearable={false}
                                    ignoreCase
                                    searchable={false}
                                    options={this.enabledRuleTypes}
                                    value={this.state.ruleType}
                                    onChange={(ruleType) => this.setState({ ruleType })}
                                    placeholder="Select type"
                                    noResultsText="None available..."
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>{this.renderRuleAdd(this.state.ruleType)}</Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }

    setUpEnabledRuleTypes() {
        let types = this.props.onSaveRouteRule
            ? ruleTypes
            : ruleTypes.filter(({ value }) => value !== DISTRIBUTION_ROUTE)

        types = this.props.onSaveOnDemandRouteRule ? types : types.filter(({ value }) => value !== ON_DEMAND_ROUTE)

        return types
    }
}

RouteSortingConfigurationModal.propTypes = {
    dispatch: PropTypes.func.isRequired,

    routes: PropTypes.array.isRequired,
    lockers: PropTypes.array.isRequired,

    terminalId: PropTypes.number.isRequired,
    editingLane: PropTypes.object,
    onDemandRouteRules: PropTypes.array.isRequired,
    routeRules: PropTypes.array.isRequired,
    lockerRules: PropTypes.array.isRequired,
    lockerStops: PropTypes.array,
    lockerRoutes: PropTypes.array,
    rules: PropTypes.array.isRequired,

    onDeleteRule: PropTypes.func.isRequired,
    onSaveRouteRule: PropTypes.func,
    onSaveOnDemandRouteRule: PropTypes.func,
    onSaveDateRule: PropTypes.func,
    onSaveTemporalRule: PropTypes.func,
    onSaveTerminalRule: PropTypes.func,
    onSaveReturnLinehaulRule: PropTypes.func,
    onSaveOversizeLockerParcelRule: PropTypes.func,
    onSaveLockerLinehaulRule: PropTypes.func,
    onSaveLockerStopRule: PropTypes.func,
    onSaveLockerRule: PropTypes.func,
    onSaveMerchantPriorityRule: PropTypes.func,
    onSaveReadFailRule: PropTypes.func,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    getAllLockerStops: PropTypes.func,
    onSaveMultipleLockerStopRules: PropTypes.func,
    onSaveReturnToMerchantRule: PropTypes.func,
    onSaveLinehaulHubOrRegionRule: PropTypes.func.isRequired,
    hubPallets: PropTypes.array.isRequired,
    canEditLaneEnablement: PropTypes.bool,
}

export default connect()(RouteSortingConfigurationModal)
