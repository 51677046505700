import React from 'react'
import { Label } from 'react-bootstrap'
import {
    PARCEL_RECALL,
    ORDER_CANCELLED,
    INVALID_ORDER_TYPE,
    WRONG_LOCKER,
    NO_DELIVERY_MODE,
    HAS_INCOMPLETE_ASSIGNMENTS,
    NO_AVAILABLE_BOX,
    PARCEL_TOO_LARGE,
    BOX_WONT_OPEN,
} from './box-assignment-failed-reason-types'

const styles = {
    padding: '0px 5px 0px 15px',
    border: 'none',
    display: 'inline-flex',
}

export const BoxAssignmentFailedReason = (scan) => {
    if (scan.event.reason != null) {
        switch (scan.event.reason) {
            case PARCEL_RECALL:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Parcel Recall</Label>
                    </td>
                )
            case ORDER_CANCELLED:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Order Cancelled</Label>
                    </td>
                )
            case INVALID_ORDER_TYPE:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Invalid Order Type</Label>
                    </td>
                )
            case WRONG_LOCKER:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Wrong Locker</Label>
                    </td>
                )
            case NO_DELIVERY_MODE:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">No Delivery Mode</Label>
                    </td>
                )
            case PARCEL_TOO_LARGE:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Parcel Too Large</Label>
                    </td>
                )
            case NO_AVAILABLE_BOX:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">No Available Box</Label>
                    </td>
                )
            case HAS_INCOMPLETE_ASSIGNMENTS:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Has Incomplete Assignments</Label>
                    </td>
                )
            case BOX_WONT_OPEN:
                return (
                    <td style={styles}>
                        <Label bsStyle="danger">Box Won&apos;t Open</Label>
                    </td>
                )
            default:
                return null
        }
    } else return null
}
