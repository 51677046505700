import Webapi from './webapi'
import auth from '../auth'

const V2 = 'application/vnd.budbee.collection-points-v2+json'
const V3 = 'application/vnd.budbee.collection-points-v3+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(V2)
        .contentType(V2)
        .path('collection-points')

export const getCollectionPointPostalCodeZones = (id) =>
    webapi()
        .path(id)
        .path('postal-code-zones')
        .get()

export const getCollectionPointPostalCodeZoneTransits = (id) =>
    webapi()
        .path(id)
        .path('postal-code-zones/transits')
        .get()

export const editCollectionPointPostalCodeZoneTransits = (id, transits) =>
    webapi()
        .path(id)
        .path('postal-code-zones/transits')
        .put(transits)

export const getCollectionPointIntervals = (id) =>
    webapi()
        .path(id)
        .path('intervals')
        .get()

export const createCollectionPointIntervals = (collectionPointId, interval) =>
    webapi()
        .path(collectionPointId)
        .path('intervals')
        .post(interval)

export const deleteCollectionPointIntervals = (intervalIds) =>
    webapi()
        .path('intervals')
        .delete(intervalIds)

export const saveInjectionTerminal = (collectionPointId, injectionTerminal) =>
    webapi()
        .path(collectionPointId)
        .path('injection-terminal')
        .put({ injectionTerminal })

export const saveOrderFulfillmentTime = (collectionPointId, orderFulfillmentTime) =>
    webapi()
        .path(collectionPointId)
        .path('order-fulfillment-time')
        .put({ orderFulfillmentTime })

export const saveInjectionTerminalAndFulfillmentTime = (collectionPointId, injectionTerminal, orderFulfillmentTime) =>
    webapi()
        .accept(V3)
        .contentType(V3)
        .path(collectionPointId)
        .path('injection-details')
        .put({ orderFulfillmentTime, injectionTerminal })

export const deleteCollectionPoint = (id) =>
    webapi()
        .path(id)
        .delete()
