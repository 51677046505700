import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Button, ControlLabel, Form, FormGroup, Col, Row, Grid, Table, Glyphicon } from 'react-bootstrap'
import sortBy from 'lodash/sortBy'

import CountrySelect from '../../../common/country-select'
import { getShoppingSettingsForMerchant } from '../../../../utils/shopping-webapi'
import { getCollectionGroup, deleteCollectionGroup } from '../../../../utils/shopping-collection-api'
import { handleError } from '../../../../utils/handle-error'
import CollectionGroupForm from './collection-group-form'
import CollectionsForm from './collections-form'
import CollectionList from './collection-list'

const tableHeadingKeys = ['Group Title', 'Order', 'Collections', 'Actions']

class ShoppingCollectionForm extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            country: { value: 'SE', label: 'Sweden' },
            showForm: false,
            collectionGroup: [],
            showCollections: false,
            createCollection: false,
            createGroup: {
                create: false,
                edit: false,
            },
        }
    }

    componentDidMount() {
        this.getShoppingSettings()
        this.getCollectionGroup()
    }

    getCollectionGroup = () => {
        const { country } = this.state
        getCollectionGroup(country.value).then((res) => {
            this.setState({ collectionGroup: res })
        })
    }

    getShoppingSettings = () => {
        getShoppingSettingsForMerchant(this.props.merchant.id)
            .then((merchantSettingsResponse) => {
                if (merchantSettingsResponse) {
                    this.setState({ checkShoppingSettings: true })
                }
            })
            .catch((e) => {
                handleError(e)
            })
    }

    handleInputChange = (evt) => {
        const { name, value } = evt.target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    renderCollectionGroup = () => {
        const groups = sortBy(this.state.collectionGroup, (o) => o.order)
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {tableHeadingKeys.map((heading) => {
                            return <th key={heading}>{heading}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {groups.map((group) => {
                        return (
                            <tr key={group.id}>
                                <td>{group.title}</td>
                                <td>{group.order}</td>
                                <td>{group.collections.length}</td>
                                <td>
                                    <Button
                                        bsSize="xsmall"
                                        onClick={() => {
                                            this.setState({
                                                createGroup: {
                                                    edit: true,
                                                    title: group.title,
                                                    order: group.order,
                                                    id: group.id,
                                                },
                                            })
                                        }}
                                    >
                                        EDIT
                                    </Button>
                                    <Button
                                        bsStyle="primary"
                                        bsSize="xsmall"
                                        onClick={() => {
                                            this.setState(() => ({
                                                showCollections: group,
                                            }))
                                        }}
                                    >
                                        SHOW COLLECTIONS
                                    </Button>
                                    <Button
                                        bsStyle="success"
                                        bsSize="xsmall"
                                        onClick={() => {
                                            this.setState(() => ({
                                                createCollection: group,
                                                showCollections: false,
                                            }))
                                        }}
                                    >
                                        CREATE NEW COLLECTION
                                    </Button>
                                    <Button
                                        bsStyle="danger"
                                        bsSize="xsmall"
                                        onClick={() => {
                                            deleteCollectionGroup(group.id)
                                                .then(this.getCollectionGroup)
                                                .catch((err) => {
                                                    handleError(err)
                                                })
                                        }}
                                    >
                                        <Glyphicon glyph="trash" />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    renderCountrySelect = () => {
        const { country } = this.state
        return (
            <Form horizontal>
                <FormGroup validationState={country ? 'success' : 'error'}>
                    <Col componentClass={ControlLabel} lg={4}>
                        Select Market
                    </Col>
                    <Col lg={8}>
                        <CountrySelect
                            value={country}
                            onSelect={(selectedCountry) => {
                                if (selectedCountry) {
                                    this.setState({ country: selectedCountry }, () => {
                                        this.getCollectionGroup()
                                    })
                                }
                            }}
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }

    render() {
        const { merchant } = this.props
        const { checkShoppingSettings, createCollection, showCollections, createGroup, country } = this.state

        const createOrEdit = createGroup.create || createGroup.edit

        if (!checkShoppingSettings) {
            return (
                <div>
                    <h5>No shopping settings avaliable. Add shopping settings before creating collections.</h5>
                    <Link to={`/admin/merchants/${merchant.id}/shopping`}>Shopping Settings</Link>
                </div>
            )
        }

        return (
            <Grid className="show-grid">
                <Row className="g-2">
                    <Col md={5}>
                        <Button
                            bsStyle="primary"
                            onClick={() => {
                                this.setState({
                                    createGroup: { create: true },
                                })
                            }}
                        >
                            Create Collection Group
                        </Button>
                    </Col>
                    <Col md={5}>{this.renderCountrySelect()}</Col>
                </Row>
                <Row>
                    <Col md={8}>
                        {createOrEdit && (
                            <CollectionGroupForm
                                country={country.value}
                                createGroup={createGroup}
                                getCollectionGroup={this.getCollectionGroup}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>{this.renderCollectionGroup()}</Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <div>
                            {showCollections && (
                                <CollectionList
                                    group={showCollections}
                                    getCollectionGroup={this.getCollectionGroup}
                                    merchantId={merchant.id}
                                />
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div>
                            {createCollection && !showCollections && (
                                <CollectionsForm group={createCollection} merchantId={merchant.id} />
                            )}
                        </div>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

ShoppingCollectionForm.propTypes = {
    merchant: PropTypes.shape({
        id: PropTypes.number,
    }),
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
})

export default connect(mapStateToProps, null)(ShoppingCollectionForm)
