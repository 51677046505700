import React, { Component } from 'react'
import {
    Row,
    Col,
    Panel,
    Button,
    Table,
    FormGroup,
    InputGroup,
    FormControl,
    DropdownButton,
    MenuItem,
    ControlLabel,
} from 'react-bootstrap'
import Loader from 'react-loader'
import { Link } from 'react-router'
import uniqBy from 'lodash/uniqBy'
import auth from '../../auth'
import CountrySelect from '../common/country-select'
import CitySelect from '../common/city-select'
import Alert from '../alert'
import { orderSearchV2 } from '../../utils/order-webapi'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'
import getSearchFieldPlaceholder from '../../utils/searchFieldPlaceholders'
import { getMerchant } from '../../utils/merchant-webapi'
import { formatDateBasedOnCountry } from '../../utils/date'

const RequestStatus = {
    NA: 'NA',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
}

const orderOptions = [
    { value: 'packageId', label: 'Order package id' },
    { value: 'token', label: 'Order token' },
    { value: 'merchantReference', label: 'Order cart id' },
]

const consumerOptions = [
    { value: 'consumerPhoneNumber', label: 'Consumer phone number' },
    { value: 'consumerEmail', label: 'Consumer email' },
    { value: 'consumerName', label: 'Consumer name' },
]

export default class Search extends Component {
    constructor(props) {
        super(props)

        this.state = {
            requestStatus: RequestStatus.NA,
            isSearching: false,
            consumerOption: consumerOptions[0],
            orderOption: orderOptions[0],
            orderQ: '',
            consumerQ: '',
            country: '',
            city: '',
            results: [],
            error: null,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
        )
        if (!hasAuth) {
            // eslint-disable-next-line react/prop-types
            this.props.router.push('/admin')
        }
    }

    isValidForm = () => {
        const { consumerQ, orderQ } = this.state
        return (consumerQ || orderQ) !== ''
    }

    search = (e) => {
        e.preventDefault()
        const { orderOption, consumerOption, consumerQ, orderQ, country, city } = this.state

        this.setState(
            {
                results: [],
                isSearching: true,
                requestStatus: RequestStatus.NA,
            },
            () => {
                const queryParams = {
                    [consumerOption.value]: consumerQ.trim(),
                    [orderOption.value]: orderQ.trim(),
                    consumerCountry: country || null,
                    distributionCity: city || null,
                    sortDirection: '0',
                }
                orderSearchV2(queryParams)
                    .then((res) => {
                        const mapResults = res.map((r) => {
                            return {
                                incrementalId: r.incrementalId,
                                diamondId: r.diamondId,
                                tmsId: r.tmsId,
                                merchantReference: r.merchantReference,
                                token: r.token,
                                parcels: r.parcels,
                                expireAt: r.expireAt,
                                merchantId: r.merchantId,
                                orderProductCode: r.orderProductCode,
                                consumerPhoneNumber: r.consumerPhoneNumber,
                                consumerE164PhoneNumber: r.consumerE164PhoneNumber,
                                consumerName: r.consumerName,
                                consumerNameInCaps: r.consumerNameInCaps,
                                referencePerson: r.referencePerson,
                                consumerEmail: r.consumerEmail,
                                consumerEmailInCaps: r.consumerEmailInCaps,
                                consumerStreet: r.consumerStreet,
                                consumerPostalCode: r.consumerPostalCode,
                                consumerCountry: r.consumerCountry,
                                consumerLocality: r.consumerLocality,
                                distributionCity: r.distributionCity,
                                consumerCreatedAt: r.createdAt,
                            }
                        })
                        const uniqueRes = uniqBy(mapResults, 'merchantId')

                        Promise.all(uniqueRes.map((order) => getMerchant(order.merchantId)))
                            .then((results) => {
                                const finalMerchant = mapResults.map((itm) => {
                                    return { ...itm, ...results.find((item) => item.id === itm.merchantId) }
                                })
                                this.setState({
                                    results: finalMerchant,
                                    requestStatus: RequestStatus.SUCCESS,
                                    error: null,
                                    isSearching: false,
                                })
                            })
                            .catch((err) => {
                                this.setState({
                                    results: [],
                                    requestStatus: RequestStatus.FAILURE,
                                    isSearching: false,
                                    error: err,
                                })
                            })
                    })
                    .catch((error) => {
                        this.setState({
                            results: [],
                            requestStatus: RequestStatus.FAILURE,
                            isSearching: false,
                            error,
                        })
                    })
            },
        )
    }

    renderForm() {
        const { country, consumerOption, orderOption, isSearching } = this.state

        return (
            <Panel>
                <form>
                    <Row>
                        <FormGroup>
                            <Col md={6}>
                                <ControlLabel>Select Country</ControlLabel>
                                <CountrySelect
                                    onSelect={(option) => this.setState({ country: option ? option.value : '' })}
                                />
                            </Col>
                            <Col md={6}>
                                <ControlLabel>Select City</ControlLabel>
                                <CitySelect
                                    disabled={!country}
                                    multi={false}
                                    countryFilter={country}
                                    onSelect={(option) => this.setState({ city: option ? option.value : '' })}
                                />
                            </Col>
                        </FormGroup>
                    </Row>
                    <br />
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <ControlLabel>Order information</ControlLabel>
                                <InputGroup>
                                    <DropdownButton
                                        componentClass={InputGroup.Button}
                                        id="search-option-dropdown"
                                        title={orderOption.label}
                                    >
                                        {orderOptions.map((s) => (
                                            <MenuItem key={s.value} onClick={() => this.setState({ orderOption: s })}>
                                                {s.label}
                                            </MenuItem>
                                        ))}
                                    </DropdownButton>
                                    <InputGroup.Addon>=</InputGroup.Addon>
                                    <FormControl
                                        type="text"
                                        placeholder={getSearchFieldPlaceholder(orderOption.value)}
                                        onChange={(e) => this.setState({ orderQ: e.target.value })}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <ControlLabel>Consumer information</ControlLabel>
                                <InputGroup>
                                    <DropdownButton
                                        componentClass={InputGroup.Button}
                                        id="search-option-dropdown"
                                        title={consumerOption.label}
                                    >
                                        {consumerOptions.map((s) => (
                                            <MenuItem
                                                key={s.value}
                                                onClick={() => this.setState({ consumerOption: s })}
                                            >
                                                {s.label}
                                            </MenuItem>
                                        ))}
                                    </DropdownButton>
                                    <InputGroup.Addon>=</InputGroup.Addon>
                                    <FormControl
                                        type="text"
                                        placeholder={getSearchFieldPlaceholder(consumerOption.value)}
                                        onChange={(e) => this.setState({ consumerQ: e.target.value })}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={12}>
                                <Button
                                    className="right"
                                    bsStyle="primary"
                                    type="submit"
                                    disabled={!this.isValidForm() || isSearching}
                                    onClick={this.search}
                                >
                                    Search
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </form>
            </Panel>
        )
    }

    renderNoResults = () => {
        return (
            <div>
                <span className="text-muted">No results</span>
            </div>
        )
    }

    renderRow = (order) => {
        return (
            <tr key={order.token}>
                <td>
                    {order && order.consumerCreatedAt ? formatDateBasedOnCountry(order.consumerCreatedAt) : 'No date'}
                </td>
                <td>
                    <Link to={`/admin/orders/${order.token}`}>{order.token}</Link>
                </td>
                <td>{order.parcels[0] ? order.parcels[0].packageId : '-'}</td>
                <td>{order.name}</td>
                <td>{order.consumerName}</td>
                <td>{order.consumerPhoneNumber}</td>
                <td>{order.consumerEmail}</td>
                <td>{`${order.consumerStreet}, ${order.consumerPostalCode} ${order.distributionCity}`}</td>
            </tr>
        )
    }

    renderResults() {
        const { results, isSearching, requestStatus, error } = this.state

        if (isSearching && results.length === 0) {
            return <Loader />
        }

        if (error) {
            return <Alert type="danger" message={`Could not search: ${error.status} ${error.statusText}`} />
        }

        return (
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Created At</th>
                        <th>Token</th>
                        <th>Package ID</th>
                        <th>Merchant</th>
                        <th>Consumer</th>
                        <th>Consumer Nr</th>
                        <th>Consumer Email</th>
                        <th>Delivery Address</th>
                    </tr>
                </thead>
                <tbody>
                    {results.length === 0 && requestStatus === RequestStatus.SUCCESS && this.renderNoResults()}
                    {results.map(this.renderRow)}
                </tbody>
            </Table>
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderForm()}
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderResults()}
                        <span className="text-muted">Max 100 results, 6 months back</span>
                    </Col>
                </Row>
            </div>
        )
    }
}
