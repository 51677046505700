import React, { Component } from 'react'
import hash from 'object-hash'
import { Table, Button, Navbar, Nav } from 'react-bootstrap/lib'
import DatePicker from 'react-datepicker'
import { Alert, FormControl, FormGroup, ControlLabel, Panel, Checkbox, Modal } from 'react-bootstrap'
import { Link } from 'react-router'
import moment from 'moment'
import Loader from 'react-loader'
import Select from 'react-select'
import { getOrdersToGeocodeByTerminal, cancelDeliveryAttempt } from '../../utils/order-webapi'
import { saveGeocode } from '../../utils/geocoding-webapi'
import { handleError } from '../../utils/handle-error'
import CountrySelect from '../common/country-select'
import TerminalSelect from '../common/terminal-select'

const Status = {
    EDITED: 'EDITED',
    SAVED: 'SAVED',
    GEOCODING: 'GEOCODING',
    FAILED: 'FAILED',
    NA: 'NA',
    CANCELLING_CONSIGNMENT: 'CANCELLING_CONSIGNMENT',
    CONSIGNMENT_CANCELLED: 'CONSIGNMENT_CANCELLED',
    CONSIGNMENT_CANCELLATION_FAILED: 'CONSIGNMENT_CANCELLATION_FAILED',
}

const orderSearchLimit = [
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '1000', label: '1000' },
]

const toRowData = (responseOrder) => ({
    id: responseOrder.id,
    consignments: responseOrder.consignments,
    street: responseOrder.deliveryAddress.street,
    street2: responseOrder.deliveryAddress.street2,
    name: responseOrder.endCustomer.name,
    postalCode: responseOrder.deliveryAddress.postalCode,
    locality: responseOrder.deliveryAddress.city,
    country: responseOrder.deliveryAddress.countryCode,
    status: Status.NA,
    specialInstructions: responseOrder.deliveryAddress.settings.specialInstructions,
    floor: responseOrder.deliveryAddress.settings.floor,
    coordinate: {
        latitude: '',
        longitude: '',
    },
    navigationCoordinate: {
        latitude: '',
        longitude: '',
    },
})

const hashPlc = (data) =>
    hash({
        postalCode: data.postalCode,
        locality: data.locality,
        country: data.country,
    })

const toRow = (responseOrder) => {
    const rowData = toRowData(responseOrder)
    return {
        id: responseOrder.id,
        token: responseOrder.token,
        consignments: responseOrder.consignments,
        address: responseOrder.deliveryAddress.id,
        hash: hash(rowData),
        plcHash: hashPlc(rowData),
        allowVariation: true,
        isVariation: false,
        originalStreet: responseOrder.deliveryAddress.street,
        data: rowData,
    }
}

const resetGeocodeData = () => ({
    streetName: '',
    co: '',
    houseNumber: '',
    locality: '',
    postalCode: '',
    latitude: '',
    longitude: '',
    specialInstructions: '',
    floor: '',
})

const orderGeocodeData = (order) => ({
    streetName: order.data.street,
    co: order.data.street2,
    name: order.data.name,
    country: order.data.country,
    houseNumber: '',
    locality: order.data.locality,
    postalCode: order.data.postalCode,
    latitude: order.data.coordinate.latitude,
    longitude: order.data.coordinate.longitude,
    specialInstructions: order.data.specialInstructions,
    floor: order.data.floor,
})

export default class MissingCoordinatesBeta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 'N/A',
            searching: false,
            startDate: moment(),
            endDate: moment(),
            orders: [],
            orderToResolve: null,
            geocodeData: resetGeocodeData(),
            statuses: {},
            findNAOrders: false,
            excludeCancelledConsignments: false,
            countryFilter: {
                value: localStorage.getItem('countryFilter.value'),
                label: localStorage.getItem('countryFilter.label'),
            },
            terminals: null,
            noOfOrders: orderSearchLimit[0],
            showConfirmation: false,
            cancellationCategory: 'WRONG_OR_INCOMPLETE_ADDRESS',
            cancellationComment: 'Cancelled during geocoding',
        }
        this.renderRows = this.renderRows.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.renderStartPicker = this.renderStartPicker.bind(this)
        this.renderEndPicker = this.renderEndPicker.bind(this)
        this.search = this.search.bind(this)
        this.geocodeMissing = this.geocodeMissing.bind(this)
        this.processGeocodeResponse = this.processGeocodeResponse.bind(this)
        this.reattemptAll = this.reattemptAll.bind(this)
        this.updateGeocodingStatus = this.updateGeocodingStatus.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.cancelConsignment = this.cancelConsignment.bind(this)
        this.cancelConsignmentButton = this.cancelConsignmentButton.bind(this)
        this.processConsignmentCancelResponse = this.processConsignmentCancelResponse.bind(this)
        this.handleCancelError = this.handleCancelError.bind(this)
    }

    payloadVerifiedLocation = (latitude, longitude) => {
        if (latitude.trim() === '' || longitude.trim() === '') {
            return null
        }
        const coordinate = { latitude, longitude }
        return { coordinate, navigationCoordinate: coordinate }
    }

    search = () => {
        this.setState({ searching: true }, () => {
            const distinctOrders = (orders) => {
                const newList = []
                orders
                    .filter(
                        (o) =>
                            this.state.countryFilter === null ||
                            o.data.country.trim().toUpperCase() === this.state.countryFilter.value,
                    )
                    .forEach((order) => {
                        if (newList.filter((no) => no.id === order.id).length === 0) {
                            newList.push(order)
                        }
                    })
                return newList
            }
            const resetStatuses = (orders) => orders.map((order) => ({ [order.id]: Status.NA }))
            const start = this.state.startDate == null ? null : this.state.startDate.startOf('day').valueOf()
            const end = this.state.endDate == null ? null : this.state.endDate.endOf('day').valueOf()

            getOrdersToGeocodeByTerminal(
                start,
                end,
                this.state.findNAOrders,
                // exclusion can only be done if findNAOrders is checked.
                this.state.findNAOrders ? this.state.excludeCancelledConsignments : false,
                this.state.countryFilter === null ? null : this.state.countryFilter.value,
                this.state.terminals,
                this.state.noOfOrders.value,
            )
                .then(({ count, orders }) => {
                    this.setState({
                        searching: false,
                        count,
                        orders: distinctOrders(orders.map(toRow)),
                        statuses: resetStatuses(orders),
                    })
                })
                .catch((err) => {
                    this.setState({ searching: false })
                    handleError(err)
                })
        })
    }

    displayAddress = (order) =>
        [`${order.data.street},`, order.data.postalCode, `${order.data.locality},`, order.data.country].join(' ')

    updateGeocodingStatus(orderId, status) {
        return { ...this.state.statuses, [orderId]: status }
    }

    processGeocodeResponse(response) {
        const { statuses } = this.state
        const newStatuses = Object.keys(response).reduce(
            (acc, id) => ({
                ...acc,
                [parseInt(id, 10)]: response[id] ? Status.SAVED : Status.FAILED,
            }),
            statuses,
        )
        this.setState({ statuses: newStatuses })
    }

    reattemptAll() {
        const { orders, statuses } = this.state

        // When done the statuses object looks like
        // {"0":{"4111007":"NA"},"1":{"4111008":"NA"},"4111007":"SAVED","4111008":"SAVED"}

        const requests = orders.map((order) => ({
            orderId: order.id,
            request: {
                street: order.data.street,
                postalCode: order.data.postalCode,
                locality: order.data.locality,
                country: order.data.country,
            },
        }))
        requests.forEach((request) => {
            // console.log("status of order: " + request.orderId  +": "+ statuses[request.orderId])
            const orderStatus = statuses[request.orderId]
            if (
                !orderStatus ||
                orderStatus === Status.CONSIGNMENT_CANCELLATION_FAILED ||
                orderStatus === Status.FAILED
            ) {
                this.setState({ statuses: { ...statuses, [request.orderId]: Status.GEOCODING } })
                saveGeocode([request])
                    .then(this.processGeocodeResponse)
                    .catch(handleError)
            } else {
                console.log(
                    `Geocoding would not be attempted for orderId ${request.orderId} as status is ${
                        statuses[request.orderId]
                    }`,
                )
            }
        })
    }

    geocodeMissing() {
        const { orderToResolve, geocodeData, statuses } = this.state
        const payload = {
            orderId: orderToResolve.id,
            request: {
                street: `${geocodeData.streetName} ${geocodeData.houseNumber}`,
                postalCode: geocodeData.postalCode,
                street2: geocodeData.co,
                locality: geocodeData.locality,
                country: orderToResolve.data.country,
                verifiedStreetAddress: {
                    streetName: geocodeData.streetName,
                    houseNumber: geocodeData.houseNumber,
                    originalAsVariation: false,
                },
                settings: {
                    specialInstructions: geocodeData.specialInstructions,
                    floor: geocodeData.floor,
                },
                verifiedLocation: this.payloadVerifiedLocation(geocodeData.latitude, geocodeData.longitude),
            },
        }
        this.setState({ statuses: { ...statuses, [orderToResolve.id]: Status.GEOCODING } })
        saveGeocode([payload])
            .then(this.processGeocodeResponse)
            .catch(handleError)
    }

    missingGeocodingData() {
        const { geocodeData } = this.state
        const missing = []
        if (!geocodeData.streetName) {
            missing.push('street name')
        }

        if (!geocodeData.houseNumber) {
            missing.push('house number')
        }

        if (!geocodeData.locality) {
            missing.push('locality')
        }

        if (!geocodeData.postalCode) {
            missing.push('postal code')
        }

        return missing
    }

    renderStartPicker() {
        return (
            <DatePicker
                // className="form-control"
                dateFormat="YYYY-MM-DD"
                minDate={moment().subtract(7, 'days')}
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.endDate}
                placeholder="Select Start Date"
                onChange={(startDate) => this.setState({ startDate: moment(startDate) })}
            />
        )
    }

    renderEndPicker() {
        return (
            <DatePicker
                // className="form-control"
                dateFormat="YYYY-MM-DD"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                placeholder="Select Start Date"
                onChange={(endDate) => this.setState({ endDate: moment(endDate) })}
            />
        )
    }

    renderCountryPicker() {
        return (
            <CountrySelect
                value={this.state.countryFilter}
                onSelect={(country) => {
                    this.setState({ countryFilter: country })
                    localStorage.setItem('countryFilter.label', country.label)
                    localStorage.setItem('countryFilter.value', country.value)
                }}
            />
        )
    }

    renderTerminalPicker() {
        const { countryFilter, terminals } = this.state
        return (
            <TerminalSelect
                onSelect={(terminal) => this.setState({ terminals: { ...terminals, terminal } })}
                multi
                countryFilter={countryFilter !== null ? countryFilter.value : null}
            />
        )
    }

    renderForm() {
        return (
            <div>
                <Navbar className="geocoder-navbar">
                    <Nav>
                        <Navbar.Form>
                            <FormGroup>
                                <ControlLabel>Country</ControlLabel>
                                {this.renderCountryPicker()}
                                <br />
                                <ControlLabel>Start &nbsp;&nbsp;</ControlLabel> {this.renderStartPicker()}
                            </FormGroup>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormGroup>
                                <ControlLabel>Terminals</ControlLabel>
                                {this.renderTerminalPicker()}
                                <br />
                                <ControlLabel>Stop &nbsp;&nbsp;</ControlLabel> {this.renderEndPicker()}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button onClick={this.search} disabled={this.state.searching}>
                                    Search
                                </Button>
                            </FormGroup>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormGroup>
                                <ControlLabel title="Orders that have not yet been booked for specific delivery date.">
                                    <Checkbox
                                        checked={this.state.findNAOrders}
                                        onChange={(e) => this.setState({ findNAOrders: e.target.checked })}
                                    />
                                    &nbsp;&nbsp; Include N/A orders
                                </ControlLabel>
                                <br />
                                <ControlLabel title="Exclude orders whose consignments have been cancelled.">
                                    <Checkbox
                                        checked={this.state.excludeCancelledConsignments}
                                        disabled={!this.state.findNAOrders}
                                        onChange={(e) =>
                                            this.setState({ excludeCancelledConsignments: e.target.checked })
                                        }
                                    />
                                    &nbsp;&nbsp; Exclude cancelled consignments
                                </ControlLabel>
                                <br />
                                <ControlLabel>Number of Orders &nbsp;&nbsp;</ControlLabel>
                                <Select
                                    value={this.state.noOfOrders}
                                    onChange={(e) => this.setState({ noOfOrders: e })}
                                    options={orderSearchLimit}
                                />
                            </FormGroup>
                        </Navbar.Form>
                    </Nav>
                </Navbar>
                <Loader color="#BFBFBF" loaded={!this.state.searching} />
            </div>
        )
    }

    renderOrderLink = (token) => (
        <Link rel="noopener noreferrer" target="_blank" to={`/admin/orders/${token}`} onlyActiveOnIndex>
            {token}
        </Link>
    )

    renderRows() {
        const { statuses } = this.state
        const onResolve = (order) => {
            this.setState({
                orderToResolve: order,
                geocodeData: orderGeocodeData(order),
                statuses: this.updateGeocodingStatus(order.id, Status.NA),
            })
        }

        const statusCss = (status) => {
            if (status === Status.NA) {
                return ''
            }
            if (status === Status.SAVED) {
                return 'geocoder-resolved_row'
            }
            if (status === Status.GEOCODING) {
                return 'geocoder-geocoding_row'
            }
            if (status === Status.FAILED) {
                return 'geocoder-failed_row'
            }
            if (status === Status.CANCELLING_CONSIGNMENT) {
                return 'geocoder-geocoding_row'
            }
            if (status === Status.CONSIGNMENT_CANCELLED) {
                return 'geocoder-success_cancel_consignment_row'
            }
            if (status === Status.CONSIGNMENT_CANCELLATION_FAILED) {
                return 'geocoder-failed_cancel_consignment_row'
            }
            return undefined
        }
        const renderRow = (order) => {
            const status = statuses[order.id]
            return (
                <tr key={order.token}>
                    <td>{this.renderOrderLink(order.token)}</td>
                    <td>{order.id}</td>
                    <td className={`geocoder-missing_row ${statusCss(status)}`}>
                        <span>{this.displayAddress(order)}</span>
                        {status !== Status.SAVED ? <Button onClick={() => onResolve(order)}>resolve</Button> : null}
                    </td>
                </tr>
            )
        }
        return this.state.orders.map(renderRow)
    }

    cancelConsignment() {
        const { orderToResolve, statuses, cancellationCategory, cancellationComment } = this.state

        this.handleClose()
        const { consignments } = orderToResolve
        if (consignments != null && consignments.length > 0) {
            const consignmentId = consignments[0].id
            this.setState({ statuses: { ...statuses, [orderToResolve.id]: Status.CANCELLING_CONSIGNMENT } })
            // console.log(`order_${orderToResolve.id}:${consignmentId}:${cancellationComment}:${cancellationCategory}`)
            cancelDeliveryAttempt(orderToResolve.id, consignmentId, cancellationComment, cancellationCategory)
                .then(this.processConsignmentCancelResponse)
                .catch(this.handleCancelError)
        } else {
            console.error(`No consignments found for order: ${orderToResolve.token}`)
        }
    }

    handleCancelError(e) {
        console.error(e)
        const { orderToResolve, statuses } = this.state
        this.setState({ statuses: { ...statuses, [orderToResolve.id]: Status.CONSIGNMENT_CANCELLATION_FAILED } })
    }

    processConsignmentCancelResponse() {
        const { orderToResolve, statuses } = this.state
        this.setState({ statuses: { ...statuses, [orderToResolve.id]: Status.CONSIGNMENT_CANCELLED } })
    }

    cancelConsignmentButton(consignments) {
        // console.log("cancel consignment button:" + JSON.stringify(consignments))
        const hasNoConsignment = consignments == null || consignments.length === 0
        // console.log("hasNoConsignment:" + hasNoConsignment + ", nullCheck " + (consignments == null))

        return (
            <Button disabled={hasNoConsignment} onClick={this.handleShow}>
                Cancel Consignment
            </Button>
        )
    }

    renderCountrySpecificContent = (geocodeData) => {
        // eslint-disable-next-line class-methods-use-this
        // renderCountrySpecificContent(geocodeData) {
        const { name } = geocodeData
        const { postalCode } = geocodeData
        const { country } = geocodeData

        if (country === 'SE') {
            // merinfo url: https://www.merinfo.se/search?who=Mayank+Arora&where=13135
            // hitta url: https://www.hitta.se/s%C3%B6k?vad=Mayank+Arora
            const merinfoUrl = `https://www.merinfo.se/search?who=${name}&where=${postalCode}`
            const hittaUrl = `https://www.hitta.se/s%C3%B6k?vad=${name}`
            return (
                <div>
                    <a href={encodeURI(merinfoUrl)} target="_blank" rel="noopener noreferrer">
                        merinfo
                    </a>
                    <a href={hittaUrl} target="_blank" rel="noopener noreferrer">
                        hitta
                    </a>
                </div>
            )
        }
        return null
    }

    renderGeocodingStatus() {
        const { statuses } = this.state
        const status = statuses[this.state.orderToResolve.id]
        const alert = (level, text) => (
            <div style={{ marginTop: '10px' }}>
                <Alert bsStyle={level}>{text}</Alert>
            </div>
        )
        if (status === Status.GEOCODING) {
            return alert('info', 'Attempting to Geocode...')
        }
        if (status === Status.SAVED) {
            return alert('success', 'Successfully Geocoded!')
        }
        if (status === Status.FAILED) {
            return alert('danger', 'Failed to Geocode')
        }
        if (status === Status.CANCELLING_CONSIGNMENT) {
            return alert('info', 'Attempting to cancel Consignment...')
        }
        if (status === Status.CONSIGNMENT_CANCELLED) {
            return alert('success', 'Successfully cancelled Consignment!')
        }
        if (status === Status.CONSIGNMENT_CANCELLATION_FAILED) {
            return alert('danger', 'Failed to cancel Consignment')
        }
        return null
    }

    handleShow() {
        this.setState({ showConfirmation: true })
    }

    handleClose() {
        this.setState({ showConfirmation: false })
    }

    renderEditor() {
        const { orderToResolve, geocodeData } = this.state
        if (!orderToResolve) {
            return <div className="geocoder-editor" />
        }
        const updateGeocodeData = (field, value) => {
            this.setState({ geocodeData: { ...geocodeData, [field]: value } })
        }
        const missingData = this.missingGeocodingData()
        return (
            <div className="geocoder-editor">
                <Panel header="Geocode">
                    <div style={{ marginBottom: '15px' }}>
                        Resolve order {this.renderOrderLink(orderToResolve.token)} : {geocodeData.name} :{' '}
                        {this.displayAddress(orderToResolve)}
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 3, marginRight: '20px' }}>
                            <span>
                                Street <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.streetName}
                                onChange={(e) => updateGeocodeData('streetName', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>
                                House Number <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.houseNumber}
                                onChange={(e) => updateGeocodeData('houseNumber', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 2.5, marginRight: '20px' }}>
                            <span>C/O</span>
                            <FormControl
                                type="text"
                                value={geocodeData.co != null ? geocodeData.co : ''}
                                onChange={(e) => updateGeocodeData('co', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1.5, marginRight: '20px' }}>
                            <span>
                                Locality <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.locality}
                                onChange={(e) => updateGeocodeData('locality', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>
                                Postal Code <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.postalCode}
                                onChange={(e) => updateGeocodeData('postalCode', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 3.5, marginRight: '20px' }}>
                            <span>Special Instructions</span>
                            <FormControl
                                type="text"
                                value={geocodeData.specialInstructions}
                                onChange={(e) => updateGeocodeData('specialInstructions', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 0.5 }}>
                            <span>Floor</span>
                            <FormControl
                                type="number"
                                value={geocodeData.floor}
                                onChange={(e) => updateGeocodeData('floor', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 1, marginRight: '20px' }}>
                            <span>Latitude</span>
                            <FormControl
                                type="text"
                                value={geocodeData.latitude}
                                onChange={(e) => updateGeocodeData('latitude', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>Longitude</span>
                            <FormControl
                                type="text"
                                value={geocodeData.longitude}
                                onChange={(e) => updateGeocodeData('longitude', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 1, marginRight: '20px' }}>
                            <Button disabled={missingData.length !== 0} onClick={this.geocodeMissing}>
                                Geocode
                            </Button>
                        </div>
                        <div style={{ flex: 1, marginRight: '20px' }}>
                            {this.cancelConsignmentButton(orderToResolve.consignments)}
                            <Modal show={this.state.showConfirmation} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Cancel Consignment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    This will cancel consignment{' '}
                                    {orderToResolve.consignments && orderToResolve.consignments.length > 0
                                        ? orderToResolve.consignments[0].id
                                        : 'N/A'}{' '}
                                    with reason WRONG_OR_INCOMPLETE_ADDRESS
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={this.handleClose}>Close</Button>
                                    <Button onClick={this.cancelConsignment}>Cancel Consignment</Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div style={{ flex: 1, marginTop: '10px' }}>
                            {this.renderCountrySpecificContent(geocodeData)}
                        </div>
                        <div style={{ flex: 5 }}> </div>
                    </div>
                    <div className="geocoder-geocode">{this.renderGeocodingStatus()}</div>
                </Panel>
            </div>
        )
    }

    renderTable() {
        const { count: numOfOrders } = this.state
        if (this.state.orders.length === 0) {
            return null
        }

        const header = (
            <div className="geocoder-table-btn-header">
                <span>{`Search (${numOfOrders} results), scroll to see all.`}</span>
                <Button onClick={this.reattemptAll}>Re-attempt All</Button>
            </div>
        )
        return (
            <div className="geocoder-table">
                <Panel header={header}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Token</th>
                                <th>Order Id</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderRows()}</tbody>
                    </Table>
                </Panel>
            </div>
        )
    }

    renderWorksace() {
        return (
            <div className="geocoder-workspace">
                {this.renderTable()}
                {this.renderEditor()}
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderWorksace()}
            </div>
        )
    }
}
