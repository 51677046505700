import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'react-bootstrap'
import Alert from '../alert'
import Form from './form'
import auth from '../../auth'
import * as roleActions from '../../actions/roles'
import * as merchantActions from '../../actions/merchants'
import * as courierActions from '../../actions/owner'
import * as userActions from '../../actions/user'
import * as terminalActions from '../../actions/terminals'
import empty from '../../utils/empty'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    COURIER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

class Create extends Component {
    static contextTypes = {
        router() {},
    }

    static propTypes = {
        courierActions: PropTypes.object,
        merchantActions: PropTypes.object,
        roleActions: PropTypes.object,
        terminalActions: PropTypes.object,
        userActions: PropTypes.object,
        router: PropTypes.object,
        couriers: PropTypes.array,
        merchants: PropTypes.array,
        roles: PropTypes.array,
        terminals: PropTypes.array,
        error: PropTypes.node,
    }

    canSetCourier = auth.hasAnyRole(
        COURIER,
        OPERATIONS_ADMIN,
        OPERATIONS_COORDINATOR,
        TRAFFIC_CONTROLLER,
        TERMINAL_ADMIN,
    )

    canSetMerchant = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    componentDidMount() {
        this.props.roleActions.fetchRoles()

        if (this.canSetCourier) {
            this.props.courierActions.fetchOwnersWebapi()
        }
        if (this.canSetMerchant) {
            this.props.merchantActions.fetchMerchants()
        }

        if (this.canSetCourier || this.canSetMerchant) {
            this.props.terminalActions.fetchTerminalsWebapi()
        }
    }

    validate = ({
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        connectedOwnerOperatorIds: couriers,
        connectedBuyerIds: merchants,
    }) => {
        const hasDetails = [firstName, lastName, email, password, phoneNumber].every((v) => !empty(v))
        const hasEmployer = !this.canSetCourier || couriers.length > 0 || merchants.length > 0

        return hasDetails && hasEmployer
    }

    create = (e) => {
        e.preventDefault()

        const user = this.refs.form.getUser()
        if (!this.validate(user)) {
            return
        }

        this.props.userActions.createUser(user, this.props.router)
    }

    cancel = () => {
        this.context.router.push('/admin/users')
    }

    render() {
        return (
            <div>
                <Form
                    ref="form"
                    passwordRequired
                    onSubmit={this.create}
                    onCancel={this.cancel}
                    validate={this.validate}
                    canSetCourier={this.canSetCourier}
                    canSetMerchant={this.canSetMerchant}
                    ownerOperatorOptions={this.props.couriers}
                    buyerOptions={this.props.merchants}
                    roleOptions={this.props.roles}
                    terminalOptions={this.props.terminals}
                />

                <Row style={{ marginTop: 20 }}>
                    <Col md={4} mdOffset={4}>
                        <Alert type="danger" message={this.props.error} />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles.data,
    couriers: state.owners.options,
    merchants: state.buyers.options,
    error: state.users.error,
    terminals: state.terminals.options,
})

const mapDispatchToProps = (dispatch) => ({
    roleActions: bindActionCreators(roleActions, dispatch),
    merchantActions: bindActionCreators(merchantActions, dispatch),
    courierActions: bindActionCreators(courierActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Create)
