import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Loader from 'react-loader'
import { Table, Row, Col, Grid, Glyphicon, Button, Label } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { SingleDatePicker } from 'react-dates'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { handleError } from '../../utils/handle-error'
import '../../../styles/less/zoneManagement.less'
import getNameFromCountryCode from '../../utils/country-codes'
import { OPERATIONS_COORDINATOR } from '../../utils/role'
import ZoneDraftChangesModal from './modals/zone-draft-changes'
import { splitStringAndReturnValueByIndex } from '../../utils/extract-from-string'
import Confirm from '../confirm'

import * as actions from '../../actions/zoneManagement'
import * as terminalActions from '../../actions/terminals'

import auth from '../../auth'
import { getRegionalSettings } from '../../utils/settings-api'
import zoneManagementClient from '../../utils/zone-management-client'

class ZoneManagementGroupHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            liveGroup: null,
            draftGroup: null,
            livePostalCodesZones: [],
            draftPostalCodesZones: [],
            allChanges: [],
            showZoneDraftChangesModal: false,
            selectedZone: '',
            changesInSelectedZone: {},
            totalAdded: null,
            totalRemoved: null,
            terminalCodeToDelete: null,
            terminalNameToDelete: null,
            showConfirmDeleteZoneModal: false,
            showConfirmDeleteDraftModal: false,
            showConfirmCreateDraftModal: false,
            showConfirmPublishDraftModal: false,
            showConfirmUnpublishGroupModal: false,
            diffsLoaded: false,
            planPublishDate: moment(),
            focus: false,
            showConfirmCreatePlanModal: false,
        }
    }

    componentDidMount() {
        const { groupId } = this.props.params

        if (!auth.hasAnyRole(OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin')
            return
        }

        this.props.actions.fetchGroup(groupId).then(() => {
            const { group } = this.props.zoneManagement
            if (group && group.validFrom) {
                this.props.actions
                    .fetchNextGroup(groupId)
                    .then((response) => {
                        this.setState({ liveGroup: group, draftGroup: response.response || null })
                    })
                    .then(() => {
                        this.props.actions
                            .fetchPostalCodeZones(this.state.liveGroup.id)
                            .then((fetchLivePostalCodeZoneRsesponse) => {
                                this.setState({
                                    livePostalCodesZones: fetchLivePostalCodeZoneRsesponse.response.postalCodeZones,
                                })
                                if (this.state.draftGroup) {
                                    this.props.actions
                                        .fetchPostalCodeZones(this.state.draftGroup.id)
                                        .then((fetchDraftPostalCodeZonesResponse) => {
                                            this.setState({
                                                draftPostalCodesZones:
                                                    fetchDraftPostalCodeZonesResponse.response.postalCodeZones,
                                            })
                                        })
                                        .then(() => {
                                            this.props.actions
                                                .fetchDiffBetweenGroups(
                                                    this.state.liveGroup.id,
                                                    this.state.draftGroup.id,
                                                )
                                                .then((fetchDiffBetweenGroupsResponse) => {
                                                    this.sortDiffBetweenGroups(fetchDiffBetweenGroupsResponse.response)
                                                })
                                        })
                                        .finally(() => this.setState({ loading: false }))
                                } else {
                                    this.setState({ loading: false })
                                }
                            })
                    })
            } else {
                this.props.actions
                    .fetchPreviousGroup(groupId)
                    .then((fetchPreviousGroupResponse) => {
                        this.setState({ liveGroup: fetchPreviousGroupResponse.response || null, draftGroup: group })
                    })
                    .then(() => {
                        if (this.state.liveGroup) {
                            this.props.actions
                                .fetchPostalCodeZones(this.state.liveGroup.id)
                                .then((fetchLivePostalCodeZonesResponse) => {
                                    this.setState({
                                        livePostalCodesZones: fetchLivePostalCodeZonesResponse.response.postalCodeZones,
                                    })
                                    this.props.actions
                                        .fetchPostalCodeZones(this.state.draftGroup.id)
                                        .then((fetchPostalCodeZonesResponse) => {
                                            this.setState({
                                                draftPostalCodesZones:
                                                    fetchPostalCodeZonesResponse.response.postalCodeZones,
                                            })
                                        })
                                        .then(() => {
                                            this.props.actions
                                                .fetchDiffBetweenGroups(
                                                    this.state.liveGroup.id,
                                                    this.state.draftGroup.id,
                                                )
                                                .then((response) => {
                                                    this.sortDiffBetweenGroups(response.response)
                                                })
                                        })
                                        .finally(() => this.setState({ loading: false }))
                                })
                        } else {
                            this.props.actions
                                .fetchPostalCodeZones(this.state.draftGroup.id)
                                .then((response) => {
                                    this.setState({ draftPostalCodesZones: response.response.postalCodeZones })
                                })
                                .finally(() => this.setState({ loading: false }))
                        }
                    })
            }
        })
    }

    sortDiffBetweenGroups = (response) => {
        const diffs = []
        this.state.draftPostalCodesZones.forEach((zone) => {
            diffs.push({ terminalCode: zone.terminalCode, postalCodesAdded: [], postalCodesRemoved: [] })
        })
        const terminalCodeIndex = 4
        const postalCodeIndex = 6
        const dividers = '/|-'
        let added = 0
        let removed = 0

        response.forEach(({ op, path, from, value }) => {
            switch (op) {
                case 'add':
                    if (value && value.postalCodes) {
                        Object.keys(value.postalCodes).forEach((postalCode) => {
                            added += 1
                            diffs
                                .find(
                                    (x) =>
                                        x.terminalCode ===
                                        splitStringAndReturnValueByIndex(path, dividers, terminalCodeIndex),
                                )
                                .postalCodesAdded.push(postalCode.substring(0, postalCode.length - 3))
                        })
                    } else {
                        added += 1
                        diffs
                            .find(
                                (x) =>
                                    x.terminalCode ===
                                    splitStringAndReturnValueByIndex(path, dividers, terminalCodeIndex),
                            )
                            .postalCodesAdded.push(splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex))
                    }
                    break
                case 'remove':
                    splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex) !== undefined &&
                        diffs
                            .find(
                                (x) =>
                                    x.terminalCode ===
                                    splitStringAndReturnValueByIndex(path, dividers, terminalCodeIndex),
                            )
                            .postalCodesRemoved.push(splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex))
                    removed += 1
                    break
                case 'move':
                    splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex) !== undefined &&
                        diffs
                            .find(
                                (x) =>
                                    x.terminalCode ===
                                    splitStringAndReturnValueByIndex(path, dividers, terminalCodeIndex),
                            )
                            .postalCodesAdded.push(splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex))
                    added += 1
                    splitStringAndReturnValueByIndex(path, dividers, postalCodeIndex) !== undefined &&
                        diffs
                            .find(
                                (x) =>
                                    x.terminalCode ===
                                    splitStringAndReturnValueByIndex(from, dividers, terminalCodeIndex),
                            )
                            .postalCodesRemoved.push(splitStringAndReturnValueByIndex(from, dividers, postalCodeIndex))
                    removed += 1
                    break
                default:
                    break
            }
        })

        this.setState({ allChanges: diffs, totalAdded: added, totalRemoved: removed, diffsLoaded: true })
    }

    getPostalCodesForCountry = (country) => {
        const query = `query ($country: CountryCode) {
            fetchPostalCodeAreas(country: $country) {
              id
              country
              postalCode
            }
          }`

        const variables = {
            country,
        }

        return zoneManagementClient.send(query, variables).then((response) => response.data.fetchPostalCodeAreas)
    }

    onCreateZoneChangePlan = () => {
        const { liveGroup, draftGroup, planPublishDate } = this.state
        const countryOfLiveGroup = liveGroup.country

        return Promise.all([getRegionalSettings(), this.getPostalCodesForCountry(countryOfLiveGroup)])
            .then(([regionalSettings, postalCodes]) => {
                if (
                    regionalSettings.terminals &&
                    regionalSettings.terminals.length > 0 &&
                    postalCodes &&
                    postalCodes.length > 0
                ) {
                    const FindZoneManagementPostalCodeData = (country, postalCode) =>
                        postalCodes.find((x) => x.country === country && x.postalCode === postalCode)
                    const formattedLiveGroupData = liveGroup.postalCodeZones.flatMap((postalCodeZone) => {
                        const terminal = regionalSettings.terminals.find((x) => x.code === postalCodeZone.terminalCode)
                        if (terminal) {
                            return postalCodeZone.postalCodes.flatMap((postalCodeData) => {
                                const zoneManagementPostalCodeData = FindZoneManagementPostalCodeData(
                                    postalCodeData.country,
                                    postalCodeData.postalCode,
                                )
                                return {
                                    terminalId: terminal.id,
                                    postalCodeId: zoneManagementPostalCodeData.id,
                                    fossilFree: postalCodeData.fossilFree,
                                }
                            })
                        }
                        throw new Error(
                            '[LIVE DATA] Unable to find matching terminal for ',
                            postalCodeZone.terminalCode,
                        )
                    })

                    const formattedDraftGroupData = draftGroup.postalCodeZones.flatMap((postalCodeZone) => {
                        const terminal = regionalSettings.terminals.find((x) => x.code === postalCodeZone.terminalCode)
                        if (terminal) {
                            return postalCodeZone.postalCodes.flatMap((postalCodeData) => {
                                const zoneManagementPostalCodeData = FindZoneManagementPostalCodeData(
                                    postalCodeData.country,
                                    postalCodeData.postalCode,
                                )
                                return {
                                    terminalId: terminal.id,
                                    postalCodeId: zoneManagementPostalCodeData.id,
                                    fossilFree: postalCodeData.fossilFree,
                                }
                            })
                        }
                        throw new Error(
                            '[DRAFT DATA] Unable to find matching terminal for ',
                            postalCodeZone.terminalCode,
                        )
                    })

                    const removedPostalCodes = formattedLiveGroupData
                        .filter(
                            (x) =>
                                !formattedDraftGroupData.find(
                                    (object) =>
                                        // No need to match terminal or possile free as postal code moving across zones is allowed
                                        object.postalCodeId === x.postalCodeId,
                                ),
                        )
                        .map((plannedChange) => ({ ...plannedChange, status: 'TO_INACTIVE' }))

                    const newlyAddedPostalCodes = formattedDraftGroupData
                        .filter(
                            (x) =>
                                !formattedLiveGroupData.find(
                                    (object) =>
                                        object.terminalCode === x.terminalCode &&
                                        object.postalCodeId === x.postalCodeId &&
                                        object.fossilFree === x.fossilFree,
                                ),
                        )
                        .map((plannedChange) => ({ ...plannedChange, status: 'TO_ACTIVE' }))

                    // Calling Zone Management API to submit zone changes
                    const query = `mutation ($publishDate:DateTime!, $postalCodeAreas:[PostalCodeAreaInput!]!){
                    upsertZonePlannedChange(
                      publishDate: $publishDate
                      postalCodeAreas: $postalCodeAreas
                    ) {
                      id
                    }
                  }`

                    const variables = {
                        publishDate: planPublishDate.toISOString(),
                        postalCodeAreas: [...removedPostalCodes, ...newlyAddedPostalCodes],
                    }

                    if (variables.postalCodeAreas.length <= 0) {
                        throw new Error('No postal code changes found to create a schedule')
                    }
                    return zoneManagementClient.send(query, variables)
                }
                throw new Error('Unable to fetch regional settings/postal codes')
            })
            .then((response) => {
                const { errors, data } = response
                if (errors) {
                    alert('Failed to publish plan')
                    return handleError(errors)
                }
                return alert(`Succes! Plan Id: ${data.upsertZonePlannedChange.id}`)
            })
            .catch(handleError)
    }

    onDeleteZone = () => {
        const { groupId } = this.props.params
        const { group } = this.props.zoneManagement
        const { terminalCodeToDelete } = this.state

        this.setState({ loading: true })
        const payload = cloneDeep(group)
        const index = payload.postalCodeZones.findIndex((x) => x.terminalCode === terminalCodeToDelete)
        payload.postalCodeZones.splice(index, 1)

        this.props.actions
            .updateGroup(groupId, payload)
            .then((response) => {
                if (response.status === 200) {
                    const indexOfDraftZone = this.state.draftPostalCodesZones.findIndex(
                        (x) => x.terminalCode === terminalCodeToDelete,
                    )
                    this.state.draftPostalCodesZones.splice(indexOfDraftZone, 1)
                    this.setState({ loading: false })
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onDeleteDraft = () => {
        const { draftGroup } = this.state
        this.setState({ loading: true })

        this.props.actions
            .deleteGroup(draftGroup.id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ loading: false })
                    this.props.router.push('/admin/zone-management')
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onPublishDraft = () => {
        const { draftGroup } = this.state
        this.setState({ loading: true })

        const requestBody = { validFrom: new Date() }

        this.props.actions
            .publishGroup(draftGroup.id, requestBody)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ loading: false })
                    this.props.router.push('/admin/zone-management')
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onUnpublishDraft = () => {
        const { liveGroup } = this.state
        this.setState({ loading: true })

        this.props.actions
            .unpublishGroup(liveGroup.id)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ loading: false })
                    this.props.router.push('/admin/zone-management')
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onCreateDraft = () => {
        const { type, groupName } = this.props.params
        const { group } = this.props.zoneManagement

        this.setState({ loading: true })
        const payload = cloneDeep(group)
        delete payload.validFrom

        this.props.actions
            .createGroup(payload)
            .then((response) => {
                if (response.status === 200) {
                    const { link } = response.response
                    const draftId = link.substr(link.length - 5)
                    this.props.router.push(`/admin/zone-management/${type}/${groupName}/${draftId}/edit`)
                    window.location.reload()
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onChange = (type, value) => this.setState({ [type]: value })

    onShow = (terminalCode, selectedZone) =>
        this.setState((prevState) => ({
            showZoneDraftChangesModal: true,
            selectedZone,
            changesInSelectedZone: prevState.allChanges.find((x) => x.terminalCode === terminalCode),
        }))

    onConfirmDeleteZone = (terminalCodeToDelete, terminalNameToDelete) => {
        this.setState({ showConfirmDeleteZoneModal: true, terminalCodeToDelete, terminalNameToDelete })
    }

    onConfirmDeleteDraft = () => {
        this.setState({ showConfirmDeleteDraftModal: true })
    }

    onConfirmCreateDraft = () => {
        this.setState({ showConfirmCreateDraftModal: true })
    }

    onConfirmPublishDraft = () => {
        this.setState({ showConfirmPublishDraftModal: true })
    }

    onConfirmUnpublishGroup = () => {
        this.setState({ showConfirmUnpublishGroupModal: true })
    }

    onConfirmCreatePlan = () => {
        this.setState({ showConfirmCreatePlanModal: true })
    }

    renderZone = (zone) => {
        const { allChanges } = this.state
        const { type, groupName, action, groupId } = this.props.params
        let postalCodesAdded = ''
        let postalCodesRemoved = ''

        if (allChanges.length > 0) {
            postalCodesAdded = allChanges.find((x) => x.terminalCode === zone.terminalCode).postalCodesAdded
            postalCodesRemoved = allChanges.find((x) => x.terminalCode === zone.terminalCode).postalCodesRemoved
        }

        return (
            <tr key={zone.terminalCode} className="zone-table-row">
                <td>{zone.name}</td>
                <td>
                    <div>
                        {action === 'edit' && postalCodesAdded.length > 0
                            ? `${postalCodesAdded.length} postal codes added`
                            : null}
                    </div>
                    <div>
                        {action === 'edit' && postalCodesRemoved.length > 0
                            ? `${postalCodesRemoved.length} postal codes removed`
                            : null}
                    </div>
                </td>
                <td style={{ width: 50 }}>
                    {action === 'edit' && (
                        <Button
                            className="pull-right"
                            type="button"
                            bsStyle="success"
                            onClick={() => this.onShow(zone.terminalCode, zone.name)}
                        >
                            View changes
                        </Button>
                    )}
                </td>
                <td style={{ width: 100 }}>
                    {action === 'view' && (
                        <Link
                            to={`/admin/zone-management/${type}/${groupName}/${groupId}/view/${zone.terminalCode}`}
                            className="btn btn-default pull-right edit-draft"
                        >
                            View
                        </Link>
                    )}
                    {action === 'edit' && (
                        <Link
                            to={`/admin/zone-management/${type}/${groupName}/${groupId}/edit/${zone.terminalCode}`}
                            className="btn btn-default pull-right edit-draft"
                        >
                            <Glyphicon glyph="pencil" /> Edit
                        </Link>
                    )}
                </td>
                <td style={{ width: 50 }}>
                    {action === 'edit' && (
                        <Button
                            className="pull-right"
                            type="button"
                            bsStyle="danger"
                            onClick={() => this.onConfirmDeleteZone(zone.terminalCode, zone.name)}
                        >
                            <Glyphicon glyph="trash" />
                        </Button>
                    )}
                </td>
            </tr>
        )
    }

    renderTable() {
        const { group } = this.props.zoneManagement
        const { type, groupName, action, groupId } = this.props.params
        const { totalAdded, totalRemoved, diffsLoaded } = this.state
        let postalCodeZones = []
        postalCodeZones = action === 'edit' ? this.state.draftPostalCodesZones : this.state.livePostalCodesZones
        const stylingClass = action === 'edit' ? 'edit-mode' : 'view-mode'
        return (
            <div>
                <Row>
                    <Col md={4} mdOffset={2}>
                        {this.state.liveGroup && action === 'view' && (
                            <Link className="btn btn-default group-tabs live-tab">
                                <h3>Live</h3>
                            </Link>
                        )}
                        {this.state.liveGroup && action === 'edit' && (
                            <Link
                                to={`/admin/zone-management/${type}/${groupName}/${this.state.liveGroup.id}/view`}
                                className="btn btn-default group-tabs live-tab-clickable"
                            >
                                <h3>Live</h3>
                            </Link>
                        )}
                        {!this.state.liveGroup && action === 'edit' && (
                            <Link disabled className="btn btn-default group-tabs live-tab-disabled">
                                <h3>Live</h3>
                            </Link>
                        )}

                        {this.state.draftGroup && action === 'view' && (
                            <Link
                                to={`/admin/zone-management/${type}/${groupName}/${this.state.draftGroup.id}/edit`}
                                className="btn btn-default group-tabs draft-tab-clickable"
                            >
                                <h3>Edit draft</h3>
                            </Link>
                        )}
                        {this.state.draftGroup && action === 'edit' && (
                            <Link className="btn btn-default group-tabs draft-tab">
                                <h3>Edit draft</h3>
                            </Link>
                        )}
                        {!this.state.draftGroup && action === 'view' && (
                            <Link
                                onClick={() => this.onConfirmCreateDraft()}
                                className="btn btn-default group-tabs draft-tab-clickable-new"
                            >
                                <h3>New draft</h3>
                            </Link>
                        )}
                    </Col>
                </Row>
                <Row className={stylingClass}>
                    <Col md={8} mdOffset={2}>
                        <h3>{`${getNameFromCountryCode(group.country)} ${type} Zone Mapping `}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <Row>
                            <Col md={4}>
                                <Link to="/admin/zone-management" className="btn btn-default pull-left top-buttons">
                                    Go back
                                </Link>
                                {action === 'view' && (
                                    <Button
                                        className="pull-left top-buttons"
                                        type="button"
                                        bsStyle="warning"
                                        onClick={() => this.onConfirmUnpublishGroup()}
                                    >
                                        Apply previous settings
                                    </Button>
                                )}
                                {action === 'edit' && (
                                    <span>
                                        <Link
                                            to={`/admin/zone-management/${type}/${groupName}/${groupId}/edit/create`}
                                            className="btn btn-success pull-left top-buttons"
                                        >
                                            Create zone
                                        </Link>
                                        <Button
                                            className="pull-left top-buttons"
                                            type="button"
                                            bsStyle="danger"
                                            onClick={() => this.onConfirmDeleteDraft()}
                                        >
                                            Delete draft
                                        </Button>
                                        <Button
                                            className="pull-left top-buttons"
                                            type="button"
                                            bsStyle="primary"
                                            onClick={() => this.onConfirmPublishDraft()}
                                        >
                                            Publish draft
                                        </Button>
                                    </span>
                                )}
                                {action === 'edit' && (
                                    <span>
                                        <Button
                                            className="pull-left top-buttons"
                                            type="button"
                                            bsStyle="info"
                                            onClick={() => this.onConfirmCreatePlan()}
                                        >
                                            Create Schedule
                                        </Button>
                                    </span>
                                )}
                            </Col>
                            <Col md={6} className="total-changed">
                                {action === 'edit' && (
                                    <Loader color="#bfbfbf" scale={0.5} loaded={diffsLoaded}>
                                        {diffsLoaded && (
                                            <Col md={4}>
                                                <Row>
                                                    <Label bsStyle="success">
                                                        Total postal codes added: {totalAdded}
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label bsStyle="danger">
                                                        Total postal codes removed: {totalRemoved}
                                                    </Label>
                                                </Row>
                                            </Col>
                                        )}
                                    </Loader>
                                )}
                            </Col>
                            <Col md={2}>
                                {action === 'edit' && (
                                    <span>
                                        <h5>
                                            <strong>Draft Publish Date:</strong>
                                        </h5>
                                        <SingleDatePicker
                                            date={this.state.planPublishDate}
                                            onDateChange={(date) =>
                                                this.setState({
                                                    planPublishDate: date,
                                                })
                                            }
                                            focused={this.state.focus}
                                            onFocusChange={({ focused }) =>
                                                this.setState({
                                                    focus: focused,
                                                })
                                            }
                                            numberOfMonths={1}
                                            displayFormat="DD-MMM-YYYY"
                                        />
                                    </span>
                                )}
                            </Col>
                        </Row>
                        <Row />
                        <Table striped>
                            <tbody>{postalCodeZones.map(this.renderZone)}</tbody>
                        </Table>
                    </Col>
                </Row>
                <ZoneDraftChangesModal
                    show={this.state.showZoneDraftChangesModal}
                    data={this.state.changesInSelectedZone}
                    onClose={() => this.setState({ showZoneDraftChangesModal: false })}
                    selectedZone={this.state.selectedZone}
                />
            </div>
        )
    }

    render() {
        return (
            <Grid fluid className="group-table">
                <Loader color="#bfbfbf" loaded={!this.state.loading}>
                    {this.renderTable()}
                </Loader>
                {this.renderConfirmDeleteZone()}
                {this.renderConfirmDeleteDraft()}
                {this.renderConfirmCreateDraft()}
                {this.renderConfirmPublishDraft()}
                {this.renderConfirmUnpublishGroup()}
                {this.renderConfirmCreatePlan()}
            </Grid>
        )
    }

    renderConfirmDeleteZone() {
        const { showConfirmDeleteZoneModal, terminalNameToDelete } = this.state
        return (
            <Confirm
                show={showConfirmDeleteZoneModal}
                title="Delete zone"
                bsStyle="danger"
                onClose={() => this.setState({ showConfirmDeleteZoneModal: false })}
                onConfirm={() => {
                    this.onDeleteZone()
                    this.setState({ showConfirmDeleteZoneModal: false })
                }}
            >
                {`Are you sure you want to delete ${terminalNameToDelete} zone for home deliveries?`}
            </Confirm>
        )
    }

    renderConfirmDeleteDraft() {
        const { showConfirmDeleteDraftModal } = this.state
        return (
            <Confirm
                show={showConfirmDeleteDraftModal}
                title="Delete draft"
                bsStyle="danger"
                onClose={() => this.setState({ showConfirmDeleteDraftModal: false })}
                onConfirm={() => {
                    this.onDeleteDraft()
                    this.setState({ showConfirmDeleteDraftModal: false })
                }}
            >
                Are you sure you want to delete this draft? Saved changes will be lost
            </Confirm>
        )
    }

    renderConfirmCreateDraft() {
        const { showConfirmCreateDraftModal } = this.state
        const { groupName } = this.props.params

        return (
            <Confirm
                show={showConfirmCreateDraftModal}
                title="Create draft"
                bsStyle="success"
                onClose={() => this.setState({ showConfirmCreateDraftModal: false })}
                onConfirm={() => {
                    this.onCreateDraft()
                    this.setState({ showConfirmCreateDraftModal: false })
                }}
            >
                {`You are about to create a new draft for ${groupName}. Do you want to proceed?`}
            </Confirm>
        )
    }

    renderConfirmPublishDraft() {
        const { showConfirmPublishDraftModal } = this.state
        return (
            <Confirm
                show={showConfirmPublishDraftModal}
                title="Publish draft"
                bsStyle="success"
                onClose={() => this.setState({ showConfirmPublishDraftModal: false })}
                onConfirm={() => {
                    this.onPublishDraft()
                    this.setState({ showConfirmPublishDraftModal: false })
                }}
            >
                Are you sure you want to apply these changes?
            </Confirm>
        )
    }

    renderConfirmUnpublishGroup() {
        const { showConfirmUnpublishGroupModal } = this.state
        return (
            <Confirm
                show={showConfirmUnpublishGroupModal}
                title="Unpublish group"
                bsStyle="warning"
                onClose={() => this.setState({ showConfirmUnpublishGroupModal: false })}
                onConfirm={() => {
                    this.onUnpublishDraft()
                    this.setState({ showConfirmUnpublishGroupModal: false })
                }}
            >
                Are you sure you want to go back to the previous settings for this group?
            </Confirm>
        )
    }

    renderConfirmCreatePlan() {
        const { showConfirmCreatePlanModal } = this.state
        return (
            <Confirm
                show={showConfirmCreatePlanModal}
                title="Create Schedule"
                bsStyle="warning"
                onClose={() => this.setState({ showConfirmCreatePlanModal: false })}
                onConfirm={() => {
                    this.onCreateZoneChangePlan()
                    this.setState({ showConfirmCreatePlanModal: false })
                }}
            >
                Are you sure you have reloaded the page and need proceed?
            </Confirm>
        )
    }
}

const mapStateToProps = ({ zoneManagement, terminals }) => ({
    zoneManagement,
    terminals: terminals.terminals,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagementGroupHandler)

ZoneManagementGroupHandler.propTypes = {
    router: PropTypes.object,
    actions: PropTypes.object,
    zoneManagement: PropTypes.object,
    params: PropTypes.object,
}
