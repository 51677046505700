import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.roles-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('roles')

export const getRoles = () => webapi().get()

export const getRole = (id) =>
    webapi()
        .path(id)
        .get()
