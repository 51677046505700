import Api from './api'
import dev from './dev'
import auth from '../auth'

const baseUrl = '/api/orders'
const contentType = dev('application/json', 'application/vnd.budbee.orders-v1+json')

export default {
    findByToken(token) {
        return new Api(baseUrl)
            .path(token)
            .header('Accept', contentType)
            .get()
    },

    findStopsByToken(token) {
        return new Api(baseUrl)
            .path(token)
            .path('stops')
            .header('Accept', contentType)
            .get()
    },

    findEtaByToken(token) {
        return new Api(baseUrl)
            .path(token)
            .path('eta')
            .header('Accept', contentType)
            .get()
    },

    findEtaIntervalByToken(token) {
        return new Api(baseUrl)
            .path(token)
            .path('interval')
            .header('Accept', contentType)
            .get()
    },

    findUserByToken(token) {
        return new Api(baseUrl)
            .path(token)
            .path('user')
            .header('Accept', contentType)
            .get()
    },

    setRating(token, rating) {
        return new Api(baseUrl)
            .path(token)
            .path('rating')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .post(rating)
    },

    getCallHistory(token) {
        return new Api(baseUrl)
            .path(token)
            .path('call-history')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getCallHistoryAsEndcustomer(orderToken, authToken) {
        return new Api(baseUrl)
            .path(orderToken)
            .path('call-history-ec')
            .queryParam('auth', authToken)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .get()
    },

    getNotificationHistory(token) {
        return new Api(baseUrl)
            .path(token)
            .path('notification-history')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getComments(token) {
        return new Api(baseUrl)
            .path(token)
            .path('comments')
            .path('admin')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    commentOrder(token, message, permissionIds) {
        const request = {
            message,
            permissionIds,
        }

        return new Api(baseUrl)
            .path(token)
            .path('comments')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(request)
    },

    findWaitingForApproval(token) {
        return new Api(baseUrl)
            .path(token)
            .path('booking')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .get()
    },

    validateEndCustomerAuthToken(token, authToken) {
        return new Api(baseUrl)
            .path(token)
            .path('edit')
            .path('authenticate')
            .header('Content-Type', contentType)
            .post({ authToken })
    },

    updateEndCustomer(token, request) {
        return new Api(baseUrl)
            .path(token)
            .path('edit')
            .path('end-customer')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .put(request)
    },

    updateEndCustomerAdmin(token, endCustomer) {
        return new Api(baseUrl)
            .path(token)
            .path('end-customer')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .put(endCustomer)
    },

    updateDeliveryAddress(token, request) {
        return new Api(baseUrl)
            .path(token)
            .path('delivery-address')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .put(request)
    },

    postEndCustomerComment(token, request, authToken) {
        return new Api(baseUrl)
            .path(token)
            .path('end-customer')
            .path('comment')
            .queryParam('auth', authToken)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .post(request)
    },

    getEndCustomerComments(token, authToken) {
        return new Api(baseUrl)
            .path(token)
            .path('comments')
            .queryParam('auth', authToken)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .get()
    },

    getSignatures(token) {
        return new Api(baseUrl)
            .path(token)
            .path('signatures')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    numberOfDeliveryAttemptsBetween(from, to, tag) {
        return new Api(baseUrl)
            .path('delivery-attempts')
            .path('count')
            .path(from)
            .path(to)
            .queryParam('tag', tag)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    cancelOrder(orderId, comment, category) {
        const json = {
            category,
            reason: comment,
        }
        return new Api(baseUrl)
            .path(orderId)
            .path('cancel')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(json)
    },

    getDeliveryAttempts(orderId) {
        return new Api(baseUrl)
            .path(orderId)
            .path('delivery-attempts')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getConsignments(token) {
        return new Api(baseUrl)
            .path(token)
            .path('consignments')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    bookDeliveryAttempt(orderId, deliveryAttempt) {
        return new Api(baseUrl)
            .path(orderId)
            .path('delivery-attempts')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(deliveryAttempt)
    },

    bookDeliveryAttemptAsEndCustomer(orderId, deliveryAttempt, authToken) {
        return new Api(baseUrl)
            .path(orderId)
            .path('delivery-attempts')
            .path('end-customer')
            .queryParam('auth', authToken)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .post(deliveryAttempt)
    },

    bookReturn(orderToken, payload) {
        return new Api(baseUrl)
            .path(orderToken)
            .path('return-attempts')
            .path('user')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(payload)
    },

    cancelDeliveryAttempt(orderId, deliveryAttemptId, comment, category) {
        const json = {
            category,
            reason: comment,
        }
        return new Api(baseUrl)
            .path(orderId)
            .path('delivery-attempts')
            .path(deliveryAttemptId)
            .path('cancel')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(json)
    },

    missDeliveryAttempt(orderId, deliveryAttemptId, comment, categoryId) {
        const json = {
            categoryId,
            comment,
        }
        return new Api(baseUrl)
            .path(orderId)
            .path('delivery-attempts')
            .path(deliveryAttemptId)
            .path('miss')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(json)
    },

    getEndCustomerHistoryForOrder(token) {
        return new Api(baseUrl)
            .path(token)
            .path('end-customer-history')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    findBySearch(buyerId, cpId, query, start, end, offset, limit) {
        return new Api(baseUrl)
            .queryParam('buyerId', buyerId)
            .queryParam('collectionPointId', cpId)
            .queryParam('query', query)
            .queryParam('from', start)
            .queryParam('to', end)
            .queryParam('offset', offset)
            .queryParam('limit', limit)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getDeliveryPhotos(token) {
        return new Api(baseUrl)
            .path(token)
            .path('images')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    updateOrderSettings(orderToken, orderSettings) {
        return new Api(baseUrl)
            .path(orderToken)
            .path('order-settings')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .put(orderSettings)
    },

    getOrderDeliveryInfo(orderToken) {
        return new Api(baseUrl)
            .path(orderToken)
            .path('delivery-info')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    addReturnBulkSplitForPickup(orderToken, pickupConsignmentId) {
        return new Api(baseUrl)
            .path(orderToken)
            .path('consignments')
            .path(pickupConsignmentId)
            .path('return-bulk-split')
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },
}
