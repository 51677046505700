import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, ControlLabel } from 'react-bootstrap'

import MerchantSearchSelect from './merchant-search-select'

class MerchantSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            merchantId: null,
        }
    }

    onChange = (merchant) => {
        this.setState({
            merchantId: merchant ? merchant.value : null,
        })
    }

    onSave = () => {
        this.props.onSave(this.state.merchantId)
        this.setState({ merchantId: null })
    }

    onInputChange = () => this.setState({ merchantId: null })

    render = () => {
        const { selectedMerchantIds, buttonText } = this.props
        const { merchantId } = this.state
        const isSaveDisabled = merchantId === null || selectedMerchantIds.has(merchantId)
        return (
            <FormGroup>
                <ControlLabel>Select Merchant</ControlLabel>
                <MerchantSearchSelect
                    onChange={this.onChange}
                    onInputChange={this.onInputChange}
                    selectedMerchantId={merchantId}
                />
                <br />
                <Button disabled={isSaveDisabled} bsStyle="warning" bsSize="small" onClick={this.onSave}>
                    {buttonText || 'Save'}
                </Button>
            </FormGroup>
        )
    }
}

MerchantSelect.propTypes = {
    selectedMerchantIds: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
}

export default MerchantSelect
