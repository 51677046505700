import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'
import classNames from 'classnames'

import {
    DATE,
    LINEHAUL,
    LOCKER,
    LOCKER_LINEHAUL,
    MERCHANT_PRIORITY,
    OVERSIZE_LOCKER_PARCEL,
    READ_FAIL,
    LOCKER_STOP,
    RETURN_LINEHAUL,
    TEMPORAL,
    RETURN_TO_MERCHANT,
    LINEHAUL_HUB_OR_REGION,
    ON_DEMAND_ROUTE,
} from '../../../../../utils/terminal-sorting-rule-types'

const parseRules = (list) =>
    list.map((rule) => {
        switch (rule.type) {
            case RETURN_LINEHAUL:
            case LINEHAUL:
            case LOCKER_STOP:
            case RETURN_TO_MERCHANT:
            case LINEHAUL_HUB_OR_REGION:
                return (
                    <Label key={`${rule.type}-${rule.id}`} className="label-route">
                        {rule.name}
                    </Label>
                )

            case MERCHANT_PRIORITY:
                return (
                    <Label key={`${rule.type}-${rule.id}`} bsStyle="info" className="label-route">
                        {rule.name}
                    </Label>
                )

            case TEMPORAL:
            case DATE:
            case LOCKER:
            case ON_DEMAND_ROUTE:
                return (
                    <Label key={`${rule.type}-${rule.id}`} bsStyle="success" className="label-route">
                        {rule.name}
                    </Label>
                )

            case LOCKER_LINEHAUL:
                const lockerLinehaulName = `${rule.name} (LOCKER DELIVERY)`
                return (
                    <Label key={`${rule.type}-${rule.id}`} className="label-route">
                        {lockerLinehaulName}
                    </Label>
                )

            default:
                return null
        }
    })

const getRouteStatus = (list, rss) =>
    list.map(({ route: { id, title } }) => {
        // Add percentage if lane exists in status list (rss)
        const entry = rss.find((abc) => abc.routeId === id)
        const percentage = entry ? Math.round((entry.sorted / entry.parcels) * 100) : 0
        return {
            id,
            title,
            percentage: percentage > 100 ? 100 : percentage,
        }
    })

const parseRouteRules = (list) =>
    list.map((rule) => {
        const title = rule.title ? `#${rule.id} (${rule.title})` : `#${rule.id}`
        return <Label key={rule.id} bsStyle="success" className="label-route">{`${title} - ${rule.percentage}%`}</Label>
    })

const parseWarning = (count, text) => {
    if (count > 0) {
        return (
            <Label bsStyle="warning" className="label-route">
                <i className="glyphicon glyphicon-warning-sign" /> {text}
            </Label>
        )
    }
    return null
}

const SortingMachineLanePanel = ({ lane, idx, routeSortingStatuses, rules, routeRules, onClick }) => {
    const routeStatus = getRouteStatus(routeRules, routeSortingStatuses)
    const readFailRules = rules.filter((x) => x.type === READ_FAIL)
    const oversizeLockerParcelRules = rules.filter((x) => x.type === OVERSIZE_LOCKER_PARCEL)
    const onClickHandler = onClick ? () => onClick(lane) : undefined

    const classes = classNames('sorting-machine-lane-panel', {
        editable: Boolean(onClick),
        inactive: rules.length === 0,
        ready: routeStatus.filter((rule) => rule.percentage === 100).length > 0,
        'row-left': idx % 2 === 1,
        'row-right': idx % 2 === 0,
    })

    return (
        <div tabIndex={0} role="button" onClick={onClickHandler} className={classes}>
            <div className="lane-title">
                <div>LANE: {lane.name}</div>
                {lane.terminalGate != null ? <div>GATE: {lane.terminalGate.name}</div> : null}
            </div>
            <div className="lane-rules">
                <span>{parseRules(rules, routeStatus)}</span>
                <span>{parseRouteRules(routeStatus)}</span>
                <span>{parseWarning(readFailRules.length, 'REJECT')}</span>
                <span>{parseWarning(oversizeLockerParcelRules.length, 'OVERSIZE LOCKER PARCEL')}</span>
            </div>
        </div>
    )
}

SortingMachineLanePanel.propTypes = {
    lane: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    rules: PropTypes.array,
    routeSortingStatuses: PropTypes.array,
    routeRules: PropTypes.array,
    onClick: PropTypes.func,
}

export default SortingMachineLanePanel
