export const LOADING_START = 'LOADING_START'
export const LOADING_STOP = 'LOADING_STOP'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const LOADING_USERS = 'LOADING_USERS'
export const USERS_LOADED = 'USERS_LOADED'
export const USER_LOADED = 'USER_LOADED'
export const USER_CREATED = 'USER_CREATED'
export const USER_CREATE_FAILED = 'USER_CREATE_FAILED'
export const USER_DELETED = 'USER_DELETED'
export const CURRENT_USER_LOADED = 'CURRENT_USER_LOADED'
export const CURRENT_USER_REQUESTED = 'CURRENT_USER_REQUESTED'
export const CURRENT_USER_LOGGED_OUT = 'CURRENT_USER_LOGGED_OUT'
export const USER_SELECTED = 'USER_SELECTED'
export const USER_PASSWORD_CHANGE_SUCCESS = 'USER_PASSWORD_CHANGE_SUCCESS'
export const USER_PASSWORD_CHANGE_FAIL = 'USER_PASSWORD_CHANGE_FAIL'
export const UPDATING_USER = 'UPDATING_USER'
export const USER_UPDATED = 'USER_UPDATED'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const UPDATE_USER_CANCELLED = 'UPDATE_USER_CANCELLED'

export const ROLES_LOADED = 'ROLES_LOADED'
export const ROLES_LOADING = 'ROLES_LOADING'
export const FETCHING_GROUPS = 'FETCHING_GROUPS'
export const FETCHING_GROUP = 'FETCHING_GROUP'
export const FETCHING_LATEST_GROUPS = 'FETCHING_LATEST_GROUPS'
export const FETCHING_DIFF_BETWEEN_GROUPS = 'FETCHING_DIFF_BETWEEN_GROUPS'
export const FETCHING_PREVIOUS_GROUP = 'FETCHING_PREVIOUS_GROUP'
export const FETCHING_NEXT_GROUP = 'FETCHING_NEXT_GROUP'
export const GROUPS_LOADED = 'GROUPS_LOADED'
export const LATEST_GROUPS_LOADED = 'LATEST_GROUPS_LOADED'
export const DIFF_BETWEEN_GROUPS_LOADED = 'DIFF_BETWEEN_GROUPS_LOADED'
export const GROUP_LOADED = 'GROUP_LOADED'
export const PREVIOUS_GROUP_LOADED = 'PREVIOUS_GROUP_LOADED'
export const NEXT_GROUP_LOADED = 'NEXT_GROUP_LOADED'
export const GROUP_POSTAL_CODE_ZONES_LOADED = 'GROUP_POSTAL_CODE_ZONES_LOADED'
export const GROUP_CREATED = 'GROUP_CREATED'
export const GROUP_UPDATED = 'GROUP_UPDATED'
export const GROUP_DELETED = 'GROUP_DELETED'
export const GROUP_PUBLISHED = 'GROUP_PUBLISHED'
export const GROUP_UNPUBLISHED = 'GROUP_UNPUBLISHED'
export const PUBLISHING_GROUP = 'PUBLISHING_GROUP'
export const UNPUBLISHING_GROUP = 'UNPUBLISHING_GROUP'
export const OWNER_LOADED = 'OWNER_LOADED'
export const OWNERS_LOADED = 'OWNERS_LOADED'
export const OWNERS_LOADING = 'OWNERS_LOADING'
export const OWNER_PRICE_POINTS_LOADED = 'OWNER_PRICE_POINTS_LOADED'
export const OWNER_PRICE_POINTS_UPDATE = 'OWNER_PRICE_POINTS_UPDATE'

export const FETCHING_BUYER = 'FETCHING_BUYER'
export const FETCHING_BUYERS = 'FETCHING_BUYERS'
export const BUYERS_LOADED = 'BUYERS_LOADED'
export const BUYER_LOADED = 'BUYER_LOADED'
export const BUYERS_FAILED_TO_LOAD = 'BUYERS_FAILED_TO_LOAD'
export const BUYER_FAILED_TO_LOAD = 'BUYER_FAILED_TO_LOAD'
export const BUYERS_LOADING = 'BUYERS_LOADING'
export const BUYER_SELECTED = 'BUYER_SELECTED'
export const BUYERS_ZONES_LOADED = 'BUYERS_ZONES_LOADED'
export const TIER_ENUMS_LOADED = 'TIER_ENUMS_LOADED'

export const COLLECTION_POINT_INJECTION_TERMINAL_UPDATED = 'COLLECTION_POINT_INJECTION_TERMINAL_UPDATED'
export const COLLECTION_POINT_ORDER_FULFILLMENT_TIME_UPDATED = 'COLLECTION_POINT_ORDER_FULFILLMENT_TIME_UPDATED'

export const FETCHING_TAGS = 'FETCHING_TAGS'
export const TAGS_LOADED = 'TAGS_LOADED'
export const TAGS_FAILED_TO_LOAD = 'TAGS_FAILED_TO_LOAD'

export const UPDATING_MERCHANT = 'UPDATING_MERCHANT'
export const MERCHANT_UPDATED = 'MERCHANT_UPDATED'
export const MERCHANT_FAILED_TO_UPDATE = 'MERCHANT_FAILED_TO_UPDATE'

export const FETCHING_COURIER = 'FETCHING_COURIER'
export const FETCHING_COURIERS = 'FETCHING_COURIERS'
export const COURIERS_LOADED = 'COURIERS_LOADED'
export const COURIER_LOADED = 'COURIER_LOADED'
export const COURIERS_FAILED_TO_LOAD = 'COURIERS_FAILED_TO_LOAD'
export const COURIER_FAILED_TO_LOAD = 'COURIER_FAILED_TO_LOAD'
export const COURIERS_LOADING = 'COURIERS_LOADING'
export const COURIER_SELECTED = 'COURIER_SELECTED'
export const UPDATING_COURIER = 'UPDATING_COURIER'
export const COURIER_UPDATED = 'COURIER_UPDATED'
export const COURIER_FAILED_TO_UPDATE = 'COURIER_FAILED_TO_UPDATE'

export const FETCHING_COMPENSATION_TIERS = 'FETCHING_COMPENSATION_TIERS'
export const COMPENSATION_TIERS_LOADED = 'COMPENSATION_TIERS_LOADED'
export const COMPENSATION_TIERS_FAILED_TO_LOAD = 'COMPENSATION_TIERS_FAILED_TO_LOAD'
export const UPDATING_COMPENSATION_TIER = 'UPDATING_COMPENSATION_TIER'
export const COMPENSATION_TIER_UPDATED = 'COMPENSATION_TIER_UPDATED'
export const COMPENSATION_TIER_FAILED_TO_UPDATE = 'COMPENSATION_TIER_FAILED_TO_UPDATE'
export const DELETING_COMPENSATION_TIERS = 'DELETING_COMPENSATION_TIERS'
export const COMPENSATION_TIERS_DELETED = 'COMPENSATION_TIERS_DELETED'
export const COMPENSATION_TIERS_FAILED_TO_DELETE = 'COMPENSATION_TIERS_FAILED_TO_DELETE'

export const LOADING_PRICE_POINTS = 'LOADING_PRICE_POINTS'
export const PRICE_POINTS_LOADED = 'PRICE_POINTS_LOADED'
export const PRICE_POINT_DELETE = 'PRICE_POINT_DELETE'
export const PRICE_POINTS_UPDATE = 'PRICE_POINTS_UPDATE'

export const PRICE_TIERS_LOADED = 'PRICE_TIERS_LOADED'
export const PRICE_TIERS_DELETE = 'PRICE_TIERS_DELETE'

export const PARCEL_DISCOUNTS_LOADED = 'PARCEL_DISCOUNTS_LOADED'
export const PARCEL_DISCOUNTS_DELETE = 'PARCEL_DISCOUNTS_DELETE'

export const CP_ZONES_LOADED = 'CP_ZONES_LOADED'
export const CP_PRICE_POINTS_LOADED = 'CP_PRICE_POINTS_LOADED'

export const FETCHING_POSTAL_CODE_ZONES = 'FETCHING_POSTAL_CODE_ZONES'
export const POSTAL_CODE_ZONES_LOADED = 'POSTAL_CODE_ZONES_LOADED'
export const POSTAL_CODE_ZONES_FAILED_TO_LOAD = 'POSTAL_CODE_ZONES_FAILED_TO_LOAD'

export const TERMINALS_LOADING = 'TERMINALS_LOADING'
export const TERMINALS_LOADED = 'TERMINALS_LOADED'

export const TRANSIT_ERROR = 'TRANSIT_ERROR'
export const TRANSIT_RESET = 'TRANSIT_RESET'
export const TRANSIT_RESET_DATA = 'TRANSIT_RESET_DATA'
export const TRANSIT_LIST_LOADED = 'TRANSIT_LIST_LOADED'
export const TRANSIT_ADD_DATA = 'TRANSIT_ADD_DATA'
export const TRANSIT_DATA_STORE = 'TRANSIT_DATA_STORE'
export const LIST_DATA_LOADED = 'LIST_DATA_LOADED'
export const TRANSIT_REMOVE_DATA = 'TRANSIT_REMOVE_DATA'
export const TRANSIT_REMOVE_WAREHOUSE = 'TRANSIT_REMOVE_WAREHOUSE'
export const TRANSIT_REMOVE_UNDO = 'TRANSIT_REMOVE_UNDO'
export const TRANSIT_SAVE_DATA = 'TRANSIT_SAVE_DATA'
export const TRANSIT_DATA_SAVED = 'TRANSIT_DATA_SAVED'

export const INTERVALS_LOADED = 'INTERVALS_LOADED'

export const RULE_CREATED = 'RULE_CREATED'
export const RULE_CREATE_FAIL = 'RULE_CREATE_FAIL'
export const RULE_DELETE_FAIL = 'RULE_DELETE_FAIL'
export const RULES_LOADED = 'RULES_LOADED'
export const RULES_LOADING = 'RULES_LOADING'
export const RULES_RESET = 'RULES_RESET'

export const DELETING_ALL_RULES_FROM_LANES = 'DELETING_ALL_RULES_FROM_LANES'
export const DELETED_ALL_RULES_FROM_LANES = 'DELETED_ALL_RULES_FROM_LANES'
export const DELETE_ALL_RULES_FROM_LANES_FAILED = 'DELETE_ALL_RULES_FROM_LANES_FAILED'

export const DELETING_ALL_RULES_FROM_PROFILE = 'DELETING_ALL_RULES_FROM_PROFILE'
export const DELETED_ALL_RULES_FROM_PROFILE = 'DELETED_ALL_RULES_FROM_PROFILE'
export const DELETE_ALL_RULES_FROM_PROFILE_FAILED = 'DELETE_ALL_RULES_FROM_PROFILE_FAILED'

export const ALL_RULES_LOADED = 'ALL_RULES_LOADED'
export const DATE_RULES_LOADED = 'DATE_RULES_LOADED'
export const TEMPORAL_RULES_LOADED = 'TEMPORAL_RULES_LOADED'
export const ROUTE_RULES_LOADED = 'ROUTE_RULES_LOADED'
export const PROFILE_RULES_LOADING = 'PROFILE_RULES_LOADING'
export const PROFILE_RULES_LOADED = 'PROFILE_RULES_LOADED'
export const TERMINAL_RULES_LOADED = 'TERMINAL_RULES_LOADED'
export const RETURN_LINEHAUL_RULES_LOADED = 'RETURN_LINEHAUL_RULES_LOADED'
export const LOCKER_RULES_LOADED = 'LOCKER_RULES_LOADED'
export const LOCKER_LINEHAUL_RULES_LOADED = 'LOCKER_LINEHAUL_RULES_LOADED'
export const LOCKER_STOPS_LOADED = 'LOCKER_STOPS_LOADED'
export const LOCKER_ROUTES_LOADED = 'LOCKER_ROUTES_LOADED'
export const OVERSIZE_LOCKER_PARCEL_RULES_LOADED = 'OVERSIZE_LOCKER_PARCEL_RULES_LOADED'
export const READ_FAIL_RULES_LOADED = 'READ_FAIL_RULES_LOADED'
export const SORTING_PROFILES_LOADED = 'SORTING_PROFILES_LOADED'
export const EDITING_PROFILE = 'EDITING_PROFILE'
export const EDITED_PROFILE = 'EDITED_PROFILE'
export const ROUTE_RULES_LENGTH = 'ROUTE_RULES_LENGTH'
export const ASSIGNING_ON_DEMAND_ROUTES_TO_LANES = 'ASSIGNING_ON_DEMAND_ROUTES_TO_LANES'
export const ASSIGNED_ON_DEMAND_ROUTES_TO_LANES = 'ASSIGNED_ON_DEMAND_ROUTES_TO_LANES'
export const ASSIGN_ON_DEMAND_ROUTES_TO_LANES_FAILED = 'ASSIGN_ON_DEMAND_ROUTES_TO_LANES_FAILED'
export const ASSIGNING_ROUTES_TO_LANES = 'ASSIGNING_ROUTES_TO_LANES'
export const ASSIGNED_ROUTES_TO_LANES = 'ASSIGNED_ROUTES_TO_LANES'
export const ASSIGN_ROUTES_TO_LANES_FAILED = 'ASSIGN_ROUTES_TO_LANES_FAILED'
export const BOOK_DELIVERY_LOADED = 'BOOK_DELIVERY_LOADED'
export const BOOK_DELIVERY_FAILED = 'BOOK_DELIVERY_FAILED'

export const DOCKING_DATA_LOADED = 'DOCKING_DATA_LOADED'

export const SMS_REQUEST_SENT = 'SMS_REQUEST_SENT'
export const SMS_REQUEST_RESPONSE = 'SMS_REQUEST_RESPONSE'
export const SMS_REQUEST_ERROR = 'SMS_REQUEST_ERROR'

export const PICKUP_SCHEDULE_LOADED = 'PICKUP_SCHEDULE_LOADED'

export const HOLIDAY_CREATED = 'HOLIDAY_CREATED'
export const FETCHING_HOLIDAYS = 'FETCHING_HOLIDAYS'
export const HOLIDAYS_LOADED = 'HOLIDAYS_LOADED'
export const HOLIDAYS_FAILED_TO_LOAD = 'HOLIDAYS_FAILED_TO_LOAD'
export const HOLIDAY_REMOVED = 'HOLIDAY_REMOVED'
export const HOLIDAY_CITIES_LOADED = 'HOLIDAY_CITIES_LOADED'
export const HOLIDAY_CITIES_FAILED_TO_LOAD = 'HOLIDAY_CITIES_FAILED_TO_LOAD'
export const HOLIDAY_UNLOCKED_FOR_MERCHANT = 'HOLIDAY_UNLOCKED_FOR_MERCHANT'
export const HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT = 'HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT'
export const HOLIDAY_LOCKER_CITY_UNBLOCK_CHANGED = 'HOLIDAY_LOCKER_UNBLOCK_CHANGED'

export const FETCHING_TERMINAL_LIMITS = 'FETCHING_TERMINAL_LIMITS'
export const TERMINAL_LIMITS_LOADED = 'TERMINAL_LIMITS_LOADED'
export const TERMINAL_LIMITS_FAILED_TO_LOAD = 'TERMINAL_LIMITS_FAILED_TO_LOAD'
export const TERMINAL_LIMIT_LOADED = 'TERMINAL_LIMIT_LOADED'
export const TERMINAL_LIMIT_REMOVED = 'TERMINAL_LIMIT_REMOVED'
export const TERMINAL_LIMIT_FAILED_TO_CREATE = 'TERMINAL_LIMIT_FAILED_TO_CREATE'
export const TERMINAL_LIMIT_FAILED_TO_REMOVE = 'TERMINAL_LIMIT_FAILED_TO_REMOVE'

export const MERCHANT_LIMIT_REMOVED = 'MERCHANT_LIMIT_REMOVED'

export const CONSIGNMENT_COUNTS_LOADED = 'CONSIGNMENT_COUNTS_LOADED'
export const CONSIGNMENT_COUNTS_FAILED = 'CONSIGNMENT_COUNTS_FAILED'

export const LOADING_SLOTS_LOADING = 'LOADING_SLOTS_LOADING'
export const LOADING_SLOTS_LOADED = 'LOADING_SLOTS_LOADED'
export const LOADING_SLOTS_FAILED_TO_LOAD = 'LOADING_SLOTS_FAILED_TO_LOAD'

export const FETCHING_RECURRING_PICKUPS = 'FETCHING_RECURRING_PICKUPS'
export const RECURRING_PICKUP_LOADED = 'RECURRING_PICKUP_LOADED'
export const RECURRING_PICKUPS_LOADED = 'RECURRING_PICKUPS_LOADED'
export const RECURRING_PICKUP_CREATED = 'RECURRING_PICKUP_CREATED'
export const RECURRING_PICKUP_LOCATIONS_LOADED = 'RECURRING_PICKUP_LOCATIONS_LOADED'
export const RECURRING_PICKUP_LOCATIONS_MULTIPLE_LOADED = 'MULTIPLE_RECURRING_PICKUP_LOCATIONS_MULTIPLE_LOADED'
export const RECURRING_PICKUP_COLLECTION_POINT_SCHEDULES_LOADED = 'RECURRING_PICKUP_COLLECTION_POINT_SCHEDULES_LOADED'
export const RECURRING_PICKUP_DELETED = 'RECURRING_PICKUP_DELETED'
export const RECURRING_PICKUP_HISTORY_FETCHED = 'RECURRING_PICKUP_HISTORY_FETCHED'

export const ROUTE_SEARCH_INITIATED = 'ROUTE_SEARCH_INITIATED'
export const ROUTE_SEARCH_LOADED = 'ROUTE_SEARCH_LOADED'

export const FETCHING_LINEHAULS = 'FETCHING_LINEHAULS'
export const LINEHAUL_LOADED = 'LINEHAUL_LOADED'

export const REGIONAL_SETTINGS_LOADED = 'REGIONAL_SETTINGS_LOADED'
export const REGIONAL_SETTINGS_FAILED_TO_LOAD = 'REGIONAL_SETTINGS_FAILED_TO_LOAD'
export const UI_SETTINGS_LOADED = 'UI_SETTINGS_LOADED'

export const INVOICE_SETTINGS_LOADED = 'INVOICE_SETTINGS_LOADED'

export const FETCHING_MERCHANT_HOLIDAYS = 'FETCHING_MERCHANT_HOLIDAYS'
export const MERCHANT_HOLIDAYS_LOADED = 'MERCHANT_HOLIDAYS_LOADED'

export const DRIVER_FEEDBACK_LOADED = 'DRIVER_FEEDBACK_LOADED'

export const LOADING_ROUTING_DEADLINES = 'LOADING_ROUTING_DEADLINES'
export const ROUTING_DEADLINES_LOADED = 'ROUTING_DEADLINES_LOADED'
export const LOADING_ROUTING_DEADLINES_FAILED = 'LOADING_ROUTING_DEADLINES_FAILED'

export const LOADED_RETURN_PALLET_GROUPS = 'LOADED_RETURN_PALLET_GROUPS'
export const CREATED_RETURN_PALLET_GROUP = 'CREATED_RETURN_PALLET_GROUP'
export const REMOVED_RETURN_PALLET_GROUP = 'REMOVED_RETURN_PALLET_GROUP'
export const REMOVED_MERCHANT_FROM_RETURN_PALLET_GROUP = 'REMOVED_MERCHANT_FROM_RETURN_PALLET_GROUP'
export const UPDATED_MERCHANT_GROUP_MASTER_MERCHANT = 'UPDATED_MERCHANT_GROUP_MASTER_MERCHANT'
export const ADDED_MERCHANT_TO_RETURN_PALLET_GROUP = 'ADDED_MERCHANT_TO_RETURN_PALLET_GROUP'
