import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import ListRow from './courier-row'

const List = ({ couriers, permissions }) => {
    if (!couriers) {
        return null
    }

    return (
        <div className="carriers-table">
            <Table striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        {permissions.edit ? <th> </th> : null}
                    </tr>
                </thead>
                <tbody>
                    {couriers.map((c) => (
                        <ListRow key={c.id} courier={c} permissions={permissions} />
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default List

List.propTypes = {
    couriers: PropTypes.array.isRequired,
    permissions: PropTypes.object.isRequired,
}
