import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { BoxAssignmentFailedReason } from '../../../utils/scanning-log-util'

import mapTimeZoneToCountry from '../../../utils/timezone'
import { humanTimeDiff } from '../../../utils/times'

/**
 * Render Scanning Log
 */
export default class ScanningLog extends Component {
    renderTerminal = (terminal) => {
        if (terminal == null) {
            return <p className="text-muted">N/A</p>
        }

        return <p>{terminal.name}</p>
    }

    timeDiff = (time) => {
        return moment(time).diff(moment())
    }

    renderScan(scan) {
        const { deliveryAddress } = this.props

        return (
            <tr key={scan.id}>
                <td>
                    <a href={`/admin/users/${scan.user.id}`}>{`${scan.user.firstName} ${scan.user.lastName}`}</a>
                </td>
                <td>{this.renderTerminal(scan.user.settings.warehouse)}</td>
                <td>
                    {moment(scan.date)
                        .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                        .format('YYYY-MM-DD HH:mm:ss')}{' '}
                    ({humanTimeDiff(scan.date)})
                </td>
            </tr>
        )
    }

    renderBarcodeScannedEvents = () => {
        const { deliveryAddress, barcodeScanEvents, parcelId } = this.props

        if (barcodeScanEvents == null || barcodeScanEvents.length === 0) {
            return null
        }

        return (
            <Table responsive condensed hover>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Terminal</th>
                        <th>Scan Mode</th>
                        <th>Pallet</th>
                        <th>Date &#709;</th>
                    </tr>
                </thead>
                <tbody>
                    {barcodeScanEvents
                        .filter((scan) => scan.event.originalParcelId === parcelId)
                        .map((scan) => {
                            const isScan = scan.event.event === 'BARCODE_SCANNED'
                            return scan.event.event === 'BOX_ASSIGNMENT_FAILED' ? (
                                <tr key={scan.id}>{BoxAssignmentFailedReason(scan)}</tr>
                            ) : (
                                <tr key={scan.id}>
                                    <td>
                                        {isScan ? (
                                            <a href={`/admin/users/${scan.event.userId}`}>
                                                {`${scan.event.userFirstName} ${scan.event.userLastName}`}
                                            </a>
                                        ) : null}
                                    </td>
                                    <td>{scan.event.terminalName ? scan.event.terminalName : 'N/A'}</td>
                                    <td>{scan.event.intent || scan.event.reason}</td>
                                    <td>{scan.event.palletIdentifier}</td>
                                    <td>
                                        {moment(scan.timestamp)
                                            .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                                            .format('YYYY-MM-DD HH:mm:ss')}{' '}
                                        ({humanTimeDiff(scan.timestamp)})
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
        )
    }

    render() {
        const { scanningLog, barcodeScanEvents, isBeforeDiamondScanEvent, showOldScanLog } = this.props

        if (barcodeScanEvents && !isBeforeDiamondScanEvent && !showOldScanLog) {
            return this.renderBarcodeScannedEvents()
        }

        if (scanningLog == null || scanningLog.length === 0) {
            return null
        }

        return (
            <Table responsive condensed hover>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Terminal</th>
                        <th>Date &#709;</th>
                    </tr>
                </thead>
                <tbody>{scanningLog.map(this.renderScan.bind(this))}</tbody>
            </Table>
        )
    }
}

ScanningLog.propTypes = {
    scanningLog: PropTypes.array,
    deliveryAddress: PropTypes.object,
    barcodeScanEvents: PropTypes.array,
    isBeforeDiamondScanEvent: PropTypes.bool,
    parcelId: PropTypes.number,
    showOldScanLog: PropTypes.bool,
}
