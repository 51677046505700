import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import Form from './form'
import { createMerchant } from '../../utils/merchant-webapi'
import isEmpty from '../../utils/isEmptyValue'
import { getAllTags } from '../../utils/tag-webapi'
import {
    DEFAULT_BILLING_PERIOD,
    DEFAULT_INVOICE_DUE_DAYS,
    DEFAULT_CURRENCY_CONVERSION_FEE_IN_PERCENT,
    DEFAULT_RETENTION_PERIOD,
    DEFAULT_INVOICE_LANGUAGE,
    DEFAULT_INVOICE_DELIVERY_TYPE,
} from '../../utils/merchant'
import { handleError } from '../../utils/handle-error'

const toOption = ({ id: value, name: label }) => ({ value, label })
const validateMandatoryFields = ({ name, externalName, buyerSettings }) => {
    const supportContact = buyerSettings.supportPhone || buyerSettings.supportEmail
    const mandatoryFields = [
        name,
        externalName,
        supportContact,
        buyerSettings.billingPeriod,
        buyerSettings.companyRegistrationNumber,
        buyerSettings.taxRegistrationNumber,
        buyerSettings.dueDaysInvoice,
        buyerSettings.invoiceEmail,
        buyerSettings.invoiceDeliveryType,
        buyerSettings.invoiceCountry,
        buyerSettings.invoiceCity,
        buyerSettings.invoicePostalCode,
        buyerSettings.invoiceAddr1,
    ]
    return mandatoryFields.every((v) => !isEmpty(v))
}

export default class CreateBuyer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            buyer: {
                name: null,
                externalName: null,
                buyerSettings: {
                    signatureRequired: false,
                    identificationCheckRequired: false,
                    smsSendCollected: false,
                    smsSendEta: true,
                    smsSendDelivered: true,
                    smsSendMiss: true,
                    smsSendAllParcelsMissingAtCollection: true,
                    smsSendOrderCreated: false,
                    selectIntervalInCheckout: false,
                    allowedToCreateDeliveryAttemptsAfterCutoff: false,
                    offerReturns: false,
                    offerReturnFromBuyers: false,
                    defaultReturnTypeToDeliveryMethod: false,
                    defaultReturnTypeToBox: false,
                    onDemandPickup: false,
                    allowConsumerToChangeDate: true,
                    allowConsumerToEditOutsideDoor: true,
                    billingPeriod: DEFAULT_BILLING_PERIOD,
                    dueDaysInvoice: DEFAULT_INVOICE_DUE_DAYS,
                    invoicesPreapproved: true,
                    invoiceSplitByCountry: true,
                    invoiceGroupSubmerchant: false,
                    invoiceEmail: null,
                    invoiceEmail2: null,
                    invoicePhone: null,
                    invoicePhone2: null,
                    companyRegistrationNumber: null,
                    requiresTerminalEndAlways: false,
                    customInvoiceCurrency: null,
                    currencyConversionFeeInPercent: DEFAULT_CURRENCY_CONVERSION_FEE_IN_PERCENT,
                    retentionPeriod: DEFAULT_RETENTION_PERIOD,
                    priceRunnerId: null,
                    defaultMissRetries: null,
                    invoiceLanguage: DEFAULT_INVOICE_LANGUAGE,
                    invoiceRecipientOverride: null,
                    invoiceDeliveryType: DEFAULT_INVOICE_DELIVERY_TYPE,
                },
            },
            tags: [],
            availableTags: [],
            shippingCosts: [],
        }
    }

    componentDidMount() {
        getAllTags()
            .then((res) => {
                const availableTags = res.map(toOption)
                this.setState({ availableTags })
            })
            .catch(handleError)
    }

    onChange = (buyer) => {
        this.setState({ buyer })
    }

    onCreate = () => {
        const { buyer } = this.state
        const { buyerSettings } = buyer
        const nullIfEmpty = (propertyFunc) => (propertyFunc(buyerSettings) === '' ? null : propertyFunc(buyerSettings))

        const updatedBuyerSettings = {
            ...buyer.buyerSettings,
            emailServer: nullIfEmpty((x) => x.emailServer),
            emailSender: nullIfEmpty((x) => x.emailSender),
            emailPassword: nullIfEmpty((x) => x.emailPassword),
            smsSender: nullIfEmpty((x) => x.smsSender),
            trackingHost: nullIfEmpty((x) => x.trackingHost),
            invoiceEmail: nullIfEmpty((x) => x.invoiceEmail),
            companyRegistrationNumber: nullIfEmpty((x) => x.companyRegistrationNumber),
        }

        const tagIds = this.state.tags.map((tag) => tag.value)

        const shippingCosts = this.state.shippingCosts.map((shippingCost) =>
            Object.keys(shippingCost).reduce((object, key) => {
                const newObject = object
                if (key !== 'check') {
                    newObject[key] = shippingCost[key]
                }
                return newObject
            }, {}),
        )

        const request = {
            name: buyer.name,
            externalName: buyer.externalName,
            buyerSettings: updatedBuyerSettings,
            buyerTagIds: tagIds,
            shippingCosts,
        }

        createMerchant(request)
            .then((response) => this.transition(response.id))
            .catch(handleError)
    }

    onCancel = () => this.transition()

    transition = (merchantId = null) => {
        this.props.router.push(`/admin/merchants/${merchantId || ''}`)
    }

    updateTags = (tags) => {
        this.setState({ tags })
    }

    updateShippingCosts = (shippingCosts) => {
        this.setState({ shippingCosts })
    }

    render() {
        return (
            <Grid>
                <Row>
                    <Col lg={8} lgOffset={2} md={10} mdOffset={1} className="padd-up">
                        <Form
                            buyer={this.state.buyer}
                            availableTags={this.state.availableTags}
                            selectedTags={this.state.tags}
                            onUpdateTags={this.updateTags}
                            shippingCosts={this.state.shippingCosts}
                            onUpdateShippingCosts={this.updateShippingCosts}
                            onChange={this.onChange}
                            onSubmit={this.onCreate}
                            onCancel={this.onCancel}
                            validate={validateMandatoryFields}
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

CreateBuyer.propTypes = {
    router: PropTypes.object,
}
