import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, ButtonGroup, Col, Dropdown, Glyphicon, Image, MenuItem, Row, Table } from 'react-bootstrap'

import moment from 'moment'
import Statuses from './statuses'
import ChildParcels from './childParcels'
import {
    currentStatus,
    descriptiveLabel,
    getDeliveredStatusVariant,
    isReturnable,
    sortingDeviationLabel,
} from '../../../utils/parcel-util'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getParcelScanningLog, printLabel, recallParcel } from '../../../utils/parcel-webapi'
import { handleError } from '../../../utils/handle-error'
import parcelPlaceholder from '../../../../img/parcel.png'
import ScanningLog from './scanning-log'

/**
 * Render a Parcel (Scannable Parcel) with it's status updates
 */
export default class Parcel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scanningLog: [],
            printing: false,
            error: null,
            isBeforeDiamondScanEvent: false,
            showOldScanLog: false,
            fetchingOldScanLog: false,
        }
    }

    componentDidMount() {
        const { parcel } = this.props

        if (parcel) {
            const date = moment.utc(parcel.createdAt)
            const startedRecordingScanEventInDiamondDate = '2020-11-01'
            const isBeforeDiamondScanEvent = date.isBefore(startedRecordingScanEventInDiamondDate)

            this.setState({ isBeforeDiamondScanEvent })

            if (isBeforeDiamondScanEvent) {
                this.updateScanningLog()
            }
        }
    }

    updateScanningLog() {
        const { parcel } = this.props
        this.setState({ fetchingOldScanLog: true })

        getParcelScanningLog(parcel.id)
            .then((scanningLog) => {
                this.setState({ scanningLog })
            })
            .catch((e) => {
                handleError(e)
                this.setState({ error: 'Could not get Scanning Log' })
            })
            .finally(() => this.setState({ fetchingOldScanLog: false }))
    }

    handleToggleScanLog = () => {
        const { showOldScanLog } = this.state

        const toggleShowScanLog = !showOldScanLog

        this.setState({ showOldScanLog: toggleShowScanLog })

        if (!showOldScanLog) {
            this.updateScanningLog()
        }
    }

    recallParcel = () => {
        if (!window.confirm('Are you sure this Parcel should be returned to Merchant?')) {
            return
        }

        const { merchant, parcel } = this.props
        recallParcel(merchant.id, parcel.id)
            .then(() => {
                this.props.recalledParcel(parcel)
            })
            .catch(() => this.setState({ error: 'Could not Return to Merchant' }))
    }

    print = () => {
        const { parcel } = this.props
        this.setState({ printing: true })
        printLabel(parcel.id)
        setTimeout(() => this.setState({ printing: false }), 1000)
    }

    renderPhoto() {
        const { parcel } = this.props

        const photo = parcel.photo || parcelPlaceholder

        return (
            <a href={photo} target="_blank" rel="noopener noreferrer">
                <Image src={photo} rounded responsive />
            </a>
        )
    }

    renderDetails() {
        const { parcel, deliveryAddress } = this.props

        return (
            <Table responsive condensed hover>
                <tbody>
                    <tr>
                        <td>Type</td>
                        <td>
                            {descriptiveLabel(parcel)}
                            {` `}
                            {sortingDeviationLabel(parcel)}
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{this.renderStatus()}</td>
                    </tr>
                    <tr>
                        <td>Shipment Number</td>
                        <td>{parcel.shipmentId}</td>
                    </tr>
                    <tr>
                        <td>Package Number</td>
                        <td>{parcel.packageId}</td>
                    </tr>
                    <tr>
                        <td>Created</td>
                        <td>
                            {moment(parcel.createdAt)
                                .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                                .format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                    </tr>
                    <tr>
                        <td>Dimensions supplied by Merchant</td>
                        <td>{this.renderDimensions(parcel.dimensions)}</td>
                    </tr>
                    <tr>
                        <td>Dimensions measured</td>
                        <td>{this.renderDimensions(parcel.machineDimensions)}</td>
                    </tr>
                    {parcel.expiresAt ? (
                        <tr>
                            <td>Expires At</td>
                            <td>
                                {moment(parcel.expiresAt)
                                    .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                                    .format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                        </tr>
                    ) : null}
                </tbody>
            </Table>
        )
    }

    renderStatus = () => {
        const { parcel } = this.props
        if (!parcel.parcelStatusUpdates || parcel.parcelStatusUpdates.length === 0) {
            return '-'
        }

        const latestStatus = currentStatus(parcel)
        return getDeliveredStatusVariant(latestStatus)
    }

    renderDimensions = (dimensions) => {
        if (!dimensions) {
            return <span className="text-muted">-</span>
        }

        const width = dimensions.width != null ? dimensions.width : 0
        const height = dimensions.height != null ? dimensions.height : 0
        const length = dimensions.length != null ? dimensions.length : 0
        const weight = dimensions.weight != null ? dimensions.weight : 0
        const volume = dimensions.volume != null ? dimensions.volume : 0

        return (
            <span>
                {width} &times; {height} &times; {length} cm ({volume / 1000000} m³) - {weight / 1000} kg
            </span>
        )
    }

    renderActions() {
        const { parcel } = this.props
        const { printing } = this.state

        const printButtonText = printing ? <span>Printing...</span> : <span>Print Shipping Label</span>

        return (
            <ButtonGroup>
                <Button bsStyle="danger" disabled={!isReturnable(parcel)} onClick={this.recallParcel}>
                    <Glyphicon glyph="repeat" /> Recall Parcel to Merchant
                </Button>
                <Button onClick={this.print} disabled={printing}>
                    <Glyphicon glyph="print" /> {printButtonText}
                </Button>
                <Dropdown id="download-shipping-label-dropdown">
                    <Dropdown.Toggle>
                        <Glyphicon glyph="download" /> Download Shipping Label
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <MenuItem href={parcel.label} target="_blank" rel="noopener noreferrer">
                            PDF (10x15cm)
                        </MenuItem>
                        <MenuItem disabled>ZPL</MenuItem>
                    </Dropdown.Menu>
                </Dropdown>
            </ButtonGroup>
        )
    }

    renderStatuses() {
        const { parcel, deliveryAddress } = this.props

        return (
            <div>
                <h4>Status Updates</h4>
                <Statuses statuses={parcel.parcelStatusUpdates} deliveryAddress={deliveryAddress} />
            </div>
        )
    }

    renderChildParcels() {
        const { parcel } = this.props

        return (
            <div>
                <h4>Child Parcels</h4>
                <ChildParcels childParcels={parcel.childParcels} />
            </div>
        )
    }

    renderScanLogToggleButton = () => {
        const { showOldScanLog, fetchingOldScanLog } = this.state

        let buttonContent = showOldScanLog ? 'Show new scan-log' : 'Show old scan-log'

        if (fetchingOldScanLog) {
            buttonContent = <Glyphicon glyph="refresh refreshing" />
        }

        return (
            <Button
                disabled={fetchingOldScanLog}
                type="button"
                className="pull-right"
                onClick={this.handleToggleScanLog}
            >
                {buttonContent}
            </Button>
        )
    }

    renderScans() {
        const {
            deliveryAddress,
            loadingScanEvents,
            loadingScanEventsFailed,
            barcodeScanEvents,
            fetchScanEvents,
            parcel,
        } = this.props
        const { scanningLog, isBeforeDiamondScanEvent, showOldScanLog } = this.state

        return (
            <div>
                <Row style={{ margin: '0' }}>
                    <Button disabled={loadingScanEvents} className="pull-right" type="button" onClick={fetchScanEvents}>
                        <Glyphicon glyph={`refresh ${loadingScanEvents ? 'refreshing' : ''}`} />
                    </Button>
                    {this.renderScanLogToggleButton()}
                    <h4>Scanning</h4>
                </Row>

                {loadingScanEventsFailed ? (
                    <Alert bsStyle="danger">
                        <span>Failed to load events</span>
                        <Button
                            bsStyle="primary"
                            style={{ marginLeft: '10px' }}
                            disabled={loadingScanEvents}
                            type="button"
                            onClick={fetchScanEvents}
                        >
                            <span>Try again</span>
                            <Glyphicon
                                style={{ marginLeft: '10px' }}
                                glyph={`refresh ${loadingScanEvents ? 'refreshing' : ''}`}
                            />
                        </Button>
                    </Alert>
                ) : (
                    <ScanningLog
                        deliveryAddress={deliveryAddress}
                        scanningLog={scanningLog}
                        parcelId={parcel.id}
                        showOldScanLog={showOldScanLog}
                        barcodeScanEvents={barcodeScanEvents}
                        isBeforeDiamondScanEvent={isBeforeDiamondScanEvent}
                    />
                )}
            </div>
        )
    }

    render() {
        let childParcels
        if (this.props.parcel.childParcels && this.props.parcel.childParcels.length > 0) {
            childParcels = (
                <Row className="bump-down">
                    <Col md={12}>{this.renderChildParcels()}</Col>
                </Row>
            )
        } else {
            childParcels = ''
        }

        return (
            <div>
                {this.state.error && <Alert type="danger" message={this.state.error} />}
                <Row>
                    <Col md={2}>{this.renderPhoto()}</Col>
                    <Col md={10}>{this.renderDetails()}</Col>
                </Row>
                <Row className="bump-down">
                    <Col md={12}>{this.renderActions()}</Col>
                </Row>
                <Row className="bump-down">
                    <Col md={12}>{this.renderStatuses()}</Col>
                </Row>
                <Row className="bump-down">
                    <Col md={12}>{this.renderScans()}</Col>
                </Row>
                {childParcels}
            </div>
        )
    }
}

Parcel.propTypes = {
    parcel: PropTypes.object.isRequired,
    merchant: PropTypes.object.isRequired,
    deliveryAddress: PropTypes.object.isRequired,
    recalledParcel: PropTypes.func.isRequired,
    fetchScanEvents: PropTypes.func.isRequired,
    loadingScanEvents: PropTypes.bool.isRequired,
    loadingScanEventsFailed: PropTypes.bool.isRequired,
    barcodeScanEvents: PropTypes.array.isRequired,
}
