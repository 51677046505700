import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Col, Form, FormControl, FormGroup, Modal } from 'react-bootstrap'
import {
    setMaximumNumberOfRoutesPerLane,
    setMinimumNumberOfRoutesPerLane,
    setSortingMachineAssignDirection,
} from '../../../../../actions/terminal-rules'

const assignDirectionRules = [
    { key: 'EVEN_TO_ODD_ASCENDING', value: 'Even numbers, then Odd (Ascending)' },
    { key: 'EVEN_TO_ODD_DESCENDING', value: 'Even numbers, then Odd (Descending)' },
    { key: 'ODD_TO_EVEN_ASCENDING', value: 'Odd numbers, then Even (Ascending)' },
    { key: 'ODD_TO_EVEN_DESCENDING', value: 'Odd numbers, then Even (Descending)' },
]

class TerminalSettingsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    onSetAssignDirection = (direction) => {
        const { dispatch, terminal } = this.props
        dispatch(setSortingMachineAssignDirection(terminal.id, direction))
    }

    onSetMinimumNumberOfRoutesPerLane = (value) => {
        const { dispatch, terminal } = this.props
        dispatch(setMinimumNumberOfRoutesPerLane(terminal.id, value))
    }

    onSetMaximumNumberOfRoutesPerLane = (value) => {
        const { dispatch, terminal } = this.props
        dispatch(setMaximumNumberOfRoutesPerLane(terminal.id, value))
    }

    render() {
        const { terminal, show, onHide } = this.props

        return (
            <Modal
                className="terminal-settings-modal"
                show={show}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg"
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-lg">Sorting Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form horizontal>
                        <FormGroup controlId="settings-assign-direction">
                            <Col sm={5}>
                                <strong>Assign Rules in direction</strong>
                                <br />
                                <small>Decides in what direction rules should be assigned.</small>
                                <br />
                                <small>Select which side to start on (Even numbers or Odd numbers).</small>
                                <br />
                                <small>Then select if rules should start on lowest number or highest number.</small>
                            </Col>
                            <Col sm={7}>
                                <FormControl
                                    componentClass="select"
                                    placeholder="Select"
                                    defaultValue={terminal.sortingMachineAssignDirection}
                                    onChange={(e) => this.onSetAssignDirection(e.target.value)}
                                >
                                    {assignDirectionRules.map((d) => (
                                        <option key={d.key} value={d.key}>
                                            {d.value}
                                        </option>
                                    ))}
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="settings-min-routes-per-lane">
                            <Col sm={5}>
                                <strong>Minimum number of Routes per Lane</strong>
                                <br />
                                <small>Automatic assigning of Routes will not yield a result below this number</small>
                                <br />
                            </Col>
                            <Col sm={7}>
                                <FormControl
                                    type="number"
                                    min={1}
                                    defaultValue={terminal.sortingMachineMinimumRoutesPerLane}
                                    onChange={(e) => this.onSetMinimumNumberOfRoutesPerLane(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup controlId="settings-max-routes-per-lane">
                            <Col sm={5}>
                                <strong>Maximum number of Routes per Lane</strong>
                                <br />
                                <small>
                                    Automatic assigning of Routes will not yield a result above this number. Any surplus
                                    will not be assigned to lanes
                                </small>
                                <br />
                            </Col>
                            <Col sm={7}>
                                <FormControl
                                    type="number"
                                    min={1}
                                    defaultValue={terminal.sortingMachineMaximumRoutesPerLane}
                                    onChange={(e) => this.onSetMaximumNumberOfRoutesPerLane(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                    <Button onClick={onHide} bsStyle="primary">
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

TerminalSettingsModal.propTypes = {
    terminal: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

export default connect()(TerminalSettingsModal)
