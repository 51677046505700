import { sortBy } from 'lodash'

import {
    LOADED_RETURN_PALLET_GROUPS,
    CREATED_RETURN_PALLET_GROUP,
    REMOVED_RETURN_PALLET_GROUP,
    REMOVED_MERCHANT_FROM_RETURN_PALLET_GROUP,
    ADDED_MERCHANT_TO_RETURN_PALLET_GROUP,
    UPDATED_MERCHANT_GROUP_MASTER_MERCHANT,
} from '../constants/actionTypes'
import initialState from './initialState/return-pallet-groups'

export default function ReturnPalletGroupsReducer(state = initialState, action) {
    switch (action.type) {
        case LOADED_RETURN_PALLET_GROUPS:
            return {
                ...state,
                groups: action.response,
            }
        case REMOVED_RETURN_PALLET_GROUP:
            return {
                ...state,
                groups: state.groups.filter(({ id }) => id !== action.id),
            }
        case CREATED_RETURN_PALLET_GROUP:
            return {
                ...state,
                groups: sortBy([...state.groups, action.group], ({ name }) => name),
            }
        case REMOVED_MERCHANT_FROM_RETURN_PALLET_GROUP:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    if (group.id === action.groupId) {
                        return {
                            ...group,
                            merchants: group.merchants.filter(({ id }) => id !== action.merchantId),
                        }
                    }
                    return group
                }),
            }
        case UPDATED_MERCHANT_GROUP_MASTER_MERCHANT:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    if (group.id === action.groupId) {
                        return {
                            ...group,
                            groupMaster: group.merchants.find((merchant) => merchant.id === action.merchantId),
                        }
                    }
                    return group
                }),
            }
        case ADDED_MERCHANT_TO_RETURN_PALLET_GROUP:
            return {
                ...state,
                groups: state.groups.map((group) => {
                    if (group.id === action.group.id) {
                        return action.group
                    }
                    return group
                }),
            }
        default:
            return state
    }
}
