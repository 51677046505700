import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/regional-settings'

const toOption = ({ value, displayName: label }) => ({ value, label })

class CancelReasonSelect extends Component {
    static propTypes = {
        onSelect: PropTypes.func.isRequired,
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        actions: PropTypes.object,
        reasons: PropTypes.array.isRequired,
    }

    state = {
        selected: null,
    }

    componentDidMount() {
        this.props.actions.fetchUISettings()
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            if (value.label != null) {
                this.setState({ selected: value })
            } else {
                this.setState({ selected: this.props.reasons.map(toOption).find((reason) => reason.value === value) })
            }
        }
    }

    onChange = (selected) => {
        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    render() {
        const { reasons } = this.props
        const { selected } = this.state

        return (
            <Select
                placeholder="Select Reason"
                value={selected}
                options={reasons.map(toOption)}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable
            />
        )
    }
}

const mapStateToProps = ({ regionalSettings }) => ({
    reasons: regionalSettings.cancelReasons,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CancelReasonSelect)
