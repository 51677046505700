// const WEBAPI_LOCALHOST_URL = 'https://webapi.testing.budbee.com'
const WEBAPI_LOCALHOST_URL = 'http://localhost:9000'

const GROUND_OPS_ADMIN_LOCALHOST_URL = 'http://localhost:3000'

const SHOPPING_BACKOFFICE_LOCALHOST_URL = 'http://localhost:3000'

const searchEnvironmentNameInUrl = () => {
    const hostname = window && window.location && window.location.hostname
    return /\w+\.(\w+\.)?budbee\.com/.exec(hostname)
}

// Returns the environment part of the url, e.g: "testing.". Returns an empty string for production or null for localhost.
const getUrlEnvironmentSegment = () => {
    const searchResult = searchEnvironmentNameInUrl()
    if (searchResult === null) {
        return null
    }

    return searchResult[1] || ''
}

const getBudbeeUrl = (serviceName, environmentSegment) => `https://${serviceName}.${environmentSegment}budbee.com`

export const findWebapiUrlFromHostname = () => {
    const environmentSegment = getUrlEnvironmentSegment()
    return environmentSegment === null ? WEBAPI_LOCALHOST_URL : getBudbeeUrl('webapi', environmentSegment)
}

export const findGroundOpsAdminUrlFromHostname = () => {
    const environmentSegment = getUrlEnvironmentSegment()
    return environmentSegment === null
        ? GROUND_OPS_ADMIN_LOCALHOST_URL
        : getBudbeeUrl('admin.ground-ops', environmentSegment)
}

export const findShoppingBackofficeUrlFromHostname = () => {
    const environmentSegment = getUrlEnvironmentSegment()
    return environmentSegment === null
        ? SHOPPING_BACKOFFICE_LOCALHOST_URL
        : getBudbeeUrl('backoffice.shopping', environmentSegment)
}
