import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Statuses from './statuses'
import ScanningLog from './scanning-log'
import ParcelApi from '../../../utils/parcel-api'

import mapTimeZoneToCountry from '../../../utils/timezone'

/**
 * Render an Article (Non-Unique Parcel) with it's status updates
 */
export default class Article extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            scanningLog: [],
        }
    }

    componentDidMount() {
        if (this.props.article) {
            this._updateScanningLog()
        }
    }

    _updateScanningLog = () => {
        ParcelApi.getScanningLog(this.props.article.id)
            .then((scanningLog) => {
                this.setState({ scanningLog })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    render() {
        const { article, deliveryAddress } = this.props

        if (article === null) {
            return null
        }

        return (
            <div>
                <label className="strong-label">Title</label>
                {article.title}
                <label className="strong-label">Reference</label>
                {article.packageId}
                <label className="strong-label">Price</label>
                {article.unitPrice}
                <label className="strong-label">Created</label>
                {moment(article.createdAt)
                    .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                    .format('YYYY-MM-DD HH:mm:ss')}
                <Statuses statuses={article.parcelStatusUpdates} deliveryAddress={deliveryAddress} />
                <ScanningLog parcel={article} deliveryAddress={deliveryAddress} scanningLog={this.state.scanningLog} />
            </div>
        )
    }
}

Article.PropTypes = {
    article: PropTypes.object.isRequired,
}
