import React from 'react'
import { Alert } from 'react-bootstrap'

const ReturnPalletConfigurationHeader = () => (
    <div>
        <h1>Return Pallet Configuration</h1>
        <Alert style={{ marginTop: '20px' }} bsStyle="info">
            Create return pallet groups and add merchants to them. This allows returns for the merchants in the group to
            be placed on the same pallet.
        </Alert>
    </div>
)

export default ReturnPalletConfigurationHeader
