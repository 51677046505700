import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import {
    convertCentsToWhole,
    MIN_HOURS_PER_ROUTE,
    OPERATOR_GROUPS,
    VEHICLE_INSPECTION_TIME_PER_ROUTE,
} from '../../../utils/couriers'
import { formatDate } from '../../../utils/date'

export default class FixedTierTable extends Component {
    renderMultiOrDefault = (values, defaultValue) => (isEmpty(values) ? defaultValue : values.join(', '))

    renderValidToFrom = (validFrom, validTo) => {
        if (validFrom === null && validTo === null) {
            return 'Always'
        }
        let from = 'Always'
        if (validFrom) {
            from = formatDate(validFrom)
        }
        let to = 'forever'
        if (validTo) {
            to = formatDate(validTo)
        }
        return `${from} to ${to}`
    }

    renderAmount = (tier, type) => {
        if (type.id === MIN_HOURS_PER_ROUTE || type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE) {
            return <td> {tier.lowerBound} </td>
        }
        let price = 0
        if (tier.incrementalPriceInCents !== 0) {
            price = tier.incrementalPriceInCents
        } else if (tier.fixedPriceInCents !== 0) {
            price = tier.fixedPriceInCents
        }

        if (type.operator === OPERATOR_GROUPS.ADDITIVE) {
            return (
                <td>
                    {convertCentsToWhole(price)} {tier.currency}
                </td>
            )
        }
        if (type.operator === OPERATOR_GROUPS.MULTIPLICATIVE) {
            return (
                <td>
                    {convertCentsToWhole(price)} % per unit {tier.currency}
                </td>
            )
        }
        return <td> Undefined </td>
    }

    renderRows = () => {
        const { compensationTiers, onSelect, onEdit, type } = this.props

        return compensationTiers.map((tier) => (
            <tr key={tier.id} onClick={onEdit(tier)} style={{ cursor: 'pointer' }}>
                <td>
                    <input
                        type="checkbox"
                        name={tier.type}
                        value={tier.id}
                        onClick={onSelect}
                        checked={tier.selected}
                    />
                </td>
                <td>{this.renderMultiOrDefault(tier.cities, 'All cities')}</td>
                {this.renderAmount(tier, type)}
                <td>{this.renderValidToFrom(tier.validFrom, tier.validTo)}</td>
            </tr>
        ))
    }

    render() {
        const { onSelectAll, type } = this.props

        return (
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" name={type.id} onChange={onSelectAll} />
                        </th>
                        <th>City</th>
                        <th>{type.label}</th>
                        <th>Valid Interval</th>
                    </tr>
                </thead>
                <tbody>{this.renderRows(type)}</tbody>
            </Table>
        )
    }
}

FixedTierTable.propTypes = {
    type: PropTypes.object.isRequired,
    compensationTiers: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
}
