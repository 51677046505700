import React, { Component } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import auth from '../../../auth'
import Settings from '../../../utils/settings'
import Confirm from '../../confirm'
import { COMMERCIAL_MANAGER, TRAFFIC_CONTROLLER } from '../../../utils/role'

const settingTranslations = {
    enabledNotifications: 'Push notifications enabled',
}

export default class ConsumerSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editing: false,
            showConfirmModal: false,
        }

        this.cosnumerSettings = new Settings(this.props, settingTranslations)
        this.actionButtonCallback = this.actionButtonCallback.bind(this)
    }

    canEditSettings = auth.hasAnyRole(TRAFFIC_CONTROLLER, COMMERCIAL_MANAGER)

    handleToggle(value, setting) {
        const { onUpdateSetting } = this.props
        onUpdateSetting(value, setting)
    }

    actionButtonCallback() {
        const { editing } = this.state
        const { onSaveSettings } = this.props
        if (editing) {
            this.setState(
                (prevState) => ({ editing: !prevState.editing }),
                () => onSaveSettings(),
            )
        } else {
            this.setState({ editing: true })
        }
    }

    renderConfirmDisableNotifications() {
        const { showConfirmModal } = this.state

        if (showConfirmModal) {
            this.handleToggle(true, 'enabledNotifications')
        }
        return (
            <Confirm
                show={showConfirmModal}
                title="Save consumer settings?"
                bsStyle="danger"
                onClose={() => {
                    this.setState({ showConfirmModal: false })
                }}
                onConfirm={() => this.handleToggle(false, 'enabledNotifications')}
            >
                Are you sure you want to disable push notifications for this consumer? The consumer needs to open up the
                app to enable them again.
            </Confirm>
        )
    }

    onNotificationSettingsChange = (event) => {
        this.setState({ showConfirmModal: !event.target.checked })
    }

    render() {
        const { settings } = this.props
        const editBtn = this.canEditSettings ? (
            <Button className="pull-right" bsSize="small" onClick={this.actionButtonCallback}>
                {this.state.editing ? 'Done' : 'Edit'}
            </Button>
        ) : null

        return (
            <div>
                <Row>
                    <Col md={12}>
                        {this.props.consumer.pushNotificationToken && editBtn}
                        <Table style={{ marginBottom: 0 }} responsive>
                            <tbody>
                                {Object.keys(settings).map((setting, i) =>
                                    this.cosnumerSettings.renderSetting(
                                        setting,
                                        i,
                                        this.state.editing,
                                        this.handleToggle,
                                        setting === 'enabledNotifications' && this.onNotificationSettingsChange,
                                    ),
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {this.renderConfirmDisableNotifications()}
            </div>
        )
    }
}

ConsumerSettings.propTypes = {
    onUpdateSetting: PropTypes.func,
    onSaveSettings: PropTypes.func,
    settings: PropTypes.object,
    consumer: PropTypes.object,
}
