export const handleError = (e) => {
    console.error(e)
    return e
}

export const handleErrorWithJson = (e) => {
    e.json()
    console.error(e)
    return e
}
