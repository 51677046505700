import {
    CP_PRICE_POINTS_LOADED,
    CP_ZONES_LOADED,
    INTERVALS_LOADED,
    LIST_DATA_LOADED,
    LOADING_START,
    LOADING_STOP,
    PICKUP_SCHEDULE_LOADED,
    TRANSIT_ADD_DATA,
    TRANSIT_DATA_SAVED,
    TRANSIT_DATA_STORE,
    TRANSIT_ERROR,
    TRANSIT_LIST_LOADED,
    TRANSIT_REMOVE_DATA,
    TRANSIT_REMOVE_UNDO,
    TRANSIT_RESET,
    TRANSIT_RESET_DATA,
    TRANSIT_SAVE_DATA,
} from '../constants/actionTypes'
import dataState from './initialState/dataState'
import { mapSort, sortNumbers } from '../utils/sorting'
import { prop } from '../utils/prop'

const toOption = (data) => ({ value: data.id, label: data.name })

const addRoute = (route, pcodes, warehouses) => {
    const postCodes = route.postalCodeZoneIds.map((id) => ({
        class: 'left label label-info',
        label: pcodes.filter((zone) => zone.id === id)[0].title,
    }))
    const transPoints = route.transits.sort(mapSort(sortNumbers, prop('sequence'))).map((wh) => ({
        class: 'left label label-warning',
        label: String(wh.sequence).concat('. ', warehouses.filter((house) => house.id === wh.warehouseId)[0].name),
    }))
    return { postCodes, transPoints }
}

export default function CollectionReducer(state = dataState, action) {
    let newState
    let transitsData
    let transits
    let priceList
    let intervalList
    let index
    let deleteRoute

    switch (action.type) {
        case LOADING_START:
            return { ...state, saving: true }

        case LOADING_STOP:
            return { ...state, saving: false }

        case CP_ZONES_LOADED:
            newState = state
            index = state.cpzones.findIndex((item) => item.collectionId === action.cid)
            if (index >= 0) {
                const newZones = [...newState.cpzones]
                newZones.splice(index, 1)
                newZones.push({ data: action.cpzones, collectionId: action.cid })

                return {
                    ...state,
                    loading: false,
                    cpzones: newZones,
                }
            }

            return {
                ...state,
                loading: false,
                cpzones: [...state.cpzones, { data: action.cpzones, collectionId: action.cid }],
            }

        case PICKUP_SCHEDULE_LOADED: {
            const pickups = [
                ...state.pickups.filter(({ collectionId }) => collectionId !== action.cid),
                { data: action.pickups, collectionId: action.cid },
            ]
            return {
                ...state,
                loading: false,
                pickups,
            }
        }

        case INTERVALS_LOADED:
            newState = state
            index = state.intervals.findIndex((item) => item.collectionId === action.cid)
            if (index >= 0) {
                newState = {
                    ...state,
                    intervals: [...newState.intervals.slice(0, index), ...newState.intervals.slice(index + 1)],
                }
            }
            intervalList = { data: action.intervals, collectionId: action.cid }
            return {
                ...state,
                loading: false,
                intervals: [...newState.intervals, intervalList],
            }

        case CP_PRICE_POINTS_LOADED:
            newState = state
            index = state.priceList.findIndex((item) => item.collectionId === action.collectionId)
            if (index >= 0) {
                newState = {
                    ...state,
                    priceList: [...state.priceList.slice(0, index), ...state.priceList.slice(index + 1)],
                }
            }
            priceList = { data: action.response, collectionId: action.collectionId }
            return {
                ...state,
                loading: false,
                priceList: [...newState.priceList, priceList],
            }

        case TRANSIT_LIST_LOADED:
            newState = state
            index = state.transits.findIndex((item) => item.cid === action.cid)
            if (index >= 0) {
                newState = {
                    ...state,
                    transitsData: [...state.transitsData.slice(0, index), ...state.transitsData.slice(index + 1)],
                    transits: [...state.transits.slice(0, index), ...state.transits.slice(index + 1)],
                }
            }
            transitsData = {
                cid: action.cid,
                transits: action.transits,
            }
            transits = {
                cid: action.cid,
                transits: action.transits.map((route) => addRoute(route, newState.postcodes, newState.warehousesraw)),
            }
            return {
                ...state,
                transitsData: [...newState.transitsData, transitsData],
                transits: [...newState.transits, transits],
                loading: false,
                saving: false,
            }

        case LIST_DATA_LOADED:
            return {
                ...state,
                postcodes: action.pcodes,
                warehouses: action.warehouses.map(toOption),
                warehousesraw: action.warehouses,
            }

        case TRANSIT_ADD_DATA:
            return {
                ...state,
                currentRoute: action.route,
            }

        case TRANSIT_SAVE_DATA:
            transitsData = state.transitsData.map((col) => {
                if (col.cid === action.cid) {
                    return {
                        ...col,
                        transits: [...col.transits, state.currentRoute],
                    }
                }
                return col
            })
            return { ...state, transitsData, added: true }

        case TRANSIT_DATA_STORE:
            return { ...state, saving: true }

        case TRANSIT_ERROR:
            return {
                ...state,
                saving: false,
                success: 'error',
                msg: 'There was an error saving your request',
            }

        case TRANSIT_DATA_SAVED:
            return {
                ...state,
                saving: false,
                success: 'success',
                msg: 'Transit Data has been sucessfully Stored',
            }

        case TRANSIT_RESET:
            return {
                ...state,
                currentRoute: {},
                saving: false,
                msg: false,
                success: false,
            }

        case TRANSIT_RESET_DATA:
            newState = state
            if (Object.keys(state.currentRoute).length > 0) {
                newState.transitsData = state.transitsData.map((col) => {
                    if (col.cid === action.cid) {
                        return {
                            ...col,
                            transits: [
                                ...col.transits.slice(0, col.transits.length - 1),
                                ...col.transits.slice(col.transits.length - 1 + 1),
                            ],
                        }
                    }
                    return col
                })
            }
            return {
                ...state,
                transitsData: newState.transitsData,
                currentRoute: {},
                saving: false,
                msg: false,
                added: false,
                success: false,
            }

        case TRANSIT_REMOVE_DATA:
            transitsData = state.transitsData.map((col) => {
                if (col.cid === action.cid) {
                    if (action.index === undefined) {
                        return {
                            ...col,
                            transits: [],
                        }
                    }
                    ;[deleteRoute] = col.transits.filter((_, i) => i === action.index)
                    return {
                        ...col,
                        transits: [...col.transits.slice(0, action.index), ...col.transits.slice(action.index + 1)],
                    }
                }
                return col
            })
            return {
                ...state,
                transitsData,
                deleteRoute,
            }

        case TRANSIT_REMOVE_UNDO:
            newState = state
            if (Object.keys(state.deleteRoute).length > 0) {
                newState.transitsData = state.transitsData.map((col) => {
                    if (col.cid === action.cid) {
                        return {
                            ...col,
                            transits: [...col.transits, state.deleteRoute],
                        }
                    }
                    return col
                })
            }
            return {
                ...state,
                transitsData: newState.transitsData,
                deleteRoute: {},
            }

        default:
            return state
    }
}
