import {
    FETCHING_BUYERS,
    BUYERS_LOADED,
    BUYERS_FAILED_TO_LOAD,
    BUYER_FAILED_TO_LOAD,
    BUYER_SELECTED,
    PRICE_POINTS_LOADED,
    PRICE_POINTS_UPDATE,
    BUYER_LOADED,
    PRICE_TIERS_LOADED,
    BUYERS_ZONES_LOADED,
    TIER_ENUMS_LOADED,
    UPDATING_MERCHANT,
    MERCHANT_UPDATED,
    MERCHANT_FAILED_TO_UPDATE,
    COLLECTION_POINT_INJECTION_TERMINAL_UPDATED,
    COLLECTION_POINT_ORDER_FULFILLMENT_TIME_UPDATED,
} from '../constants/actionTypes'
import buyersState from './initialState/buyers-state'

const toOption = ({ id: value, externalName, name }) => ({
    value,
    label: externalName === name ? externalName : `${externalName} (${name})`,
})

export default function BuyersReducer(state = buyersState, action) {
    switch (action.type) {
        case FETCHING_BUYERS:
            return {
                ...state,
                loading: true,
                buyers: [],
            }

        case BUYERS_LOADED:
            const newOptions = action.buyers.map(toOption)
            return {
                ...state,
                loading: false,
                error: null,
                buyers: action.buyers,
                options: newOptions,
            }

        case BUYERS_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                buyers: [],
            }

        case BUYER_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                buyer: null,
            }

        case BUYER_LOADED:
            return {
                ...state,
                loading: false,
                error: null,
                buyer: action.buyer,
            }

        case BUYER_SELECTED:
            const selected = state.data.find((b) => b.id === action.id)
            return { ...state, selected }

        case PRICE_TIERS_LOADED:
            const groups = []

            /* Group Tiers by parittion */
            action.response.forEach((tier) => {
                const key = tier.paritions.map((parition) => parition.id)
                let group = groups.find((grp) => grp.key.toString() === key.toString())
                if (!group) {
                    group = { key, tiers: [], zones: [] }
                    group.tiers.push(tier)
                    group.zones = tier.paritions
                    groups.push(group)
                } else {
                    group.tiers.push(tier)
                }
            })

            /* Sort Tiers by Quantity */
            groups.forEach((group) => group.tiers.sort((a, b) => a.quantity - b.quantity))

            return {
                ...state,
                priceTiers: { ...state.priceTiers, [action.priceTierType]: groups },
            }

        case PRICE_POINTS_LOADED: {
            const priceList = [...state.priceList]
            const index = priceList.push({ data: action.response, collectionId: action.collectionId })
            return {
                ...state,
                loading: true,
                priceList,
                index,
            }
        }

        case PRICE_POINTS_UPDATE: {
            const priceList = [...state.priceList]
            const index = priceList.findIndex((item) => item.collectionId === action.collectionId)
            priceList.splice(index, 0, { response: action.response, collectionId: action.collectionId })
            return {
                ...state,
                loading: true,
                priceList,
                index,
            }
        }

        case BUYERS_ZONES_LOADED:
            return { ...state, postcodes: action.response }

        case TIER_ENUMS_LOADED:
            return { ...state, tierEnums: action.response }

        case UPDATING_MERCHANT:
            return { ...state, loading: true, error: null }

        case MERCHANT_UPDATED:
            return { ...state, loading: false, updated: true, error: null, buyer: { ...action.merchant } }

        case MERCHANT_FAILED_TO_UPDATE:
            return { ...state, loading: false, updated: false, error: action.status }

        case COLLECTION_POINT_INJECTION_TERMINAL_UPDATED: {
            const updatedCollectionPoints = [...state.buyer.collectionPoints]
            const index = updatedCollectionPoints.findIndex((x) => x.id === action.collectionPointId)
            updatedCollectionPoints[index] = {
                ...updatedCollectionPoints[index],
                injectionTerminal: action.injectionTerminal,
            }
            return {
                ...state,
                buyer: {
                    ...state.buyer,
                    collectionPoints: updatedCollectionPoints,
                },
            }
        }

        case COLLECTION_POINT_ORDER_FULFILLMENT_TIME_UPDATED: {
            const updatedCollectionPoints = [...state.buyer.collectionPoints]
            const index = updatedCollectionPoints.findIndex((x) => x.id === action.collectionPointId)
            updatedCollectionPoints[index] = {
                ...updatedCollectionPoints[index],
                orderFulfillmentTime: action.orderFulfillmentTime,
            }
            return {
                ...state,
                buyer: {
                    ...state.buyer,
                    collectionPoints: updatedCollectionPoints,
                },
            }
        }

        default:
            return state
    }
}
