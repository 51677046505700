export const regexForSwedishPostalCodes = /\b\d{5}\b/g
export const regexForFinishPostalCodes = /\b\d{5}\b/g
export const regexForDanishPostalCodes = /\b\d{4}\b/g
export const regexForDutchPostalCodes = /\b\d{4}\b/g
export const regexForBelgianPostalCodes = /\b\d{4}\b/g
export const regexForGermanPostalCodes = /\b\d{5}\b/g
export const regexForNorwegianPostalCodes = /\b\d{4}\b/g

export const getRegexForPostalCodeValidation = (countryCode) => {
    let regex
    switch (countryCode) {
        case 'SE':
            regex = regexForSwedishPostalCodes
            break
        case 'FI':
            regex = regexForFinishPostalCodes
            break
        case 'DK':
            regex = regexForDanishPostalCodes
            break
        case 'NL':
            regex = regexForDutchPostalCodes
            break
        case 'BE':
            regex = regexForBelgianPostalCodes
            break
        case 'DE':
            regex = regexForGermanPostalCodes
            break
        case 'NO':
            regex = regexForNorwegianPostalCodes
            break
        default:
            return new Error('Country not supported')
    }
    return regex
}

export const convertArrayOfPostalCodesToString = (postalCodes) => {
    return postalCodes
        .map((e) => e.postalCode)
        .join('\n')
        .trim()
}

export const convertStringOfPostalCodesToArray = (postalCodes, isFossilFree, country) => {
    return postalCodes
        .split(/\r?\n/)
        .filter((x) => x !== '')
        .map((code) => ({
            postalCode: code.replace(/\s/g, ''),
            country,
            fossilFree: isFossilFree,
        }))
}
