import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.users-v2+json'
const userV3 = 'application/vnd.budbee.users-v3+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('users')

export const whoami = () =>
    webapi()
        .path('me')
        .get()

export const blacklistToken = () =>
    new Webapi()
        .contentType('application/json')
        .path('_tokens')
        .path('blacklist')
        .post({ bearerToken: auth.get().replace('Bearer ', '') })

export const getUsers = () => webapi().get()

export const getUser = (id) =>
    webapi()
        .accept(userV3)
        .contentType(userV3)
        .path(id)
        .get()

export const getForCourier = (courierId) =>
    webapi()
        .queryParam('courierId', courierId)
        .get()

export const createUser = (user) => webapi().post(user)

export const editUser = (id, user) =>
    webapi()
        .path(id)
        .put(user)

export const deleteUser = (id) =>
    webapi()
        .path(id)
        .delete()

export const denyUserImage = (id) =>
    webapi()
        .path(id)
        .path('image/deny')
        .put()

export const userExists = (email) =>
    webapi()
        .path('exists')
        .path(email)
        .get()

export const changePassword = (id, currentPassword, newPassword) =>
    webapi()
        .path(id)
        .path('password')
        .put({ currentPassword, newPassword })

export const findRecentImages = () =>
    webapi()
        .path('images/recent')
        .get()

export const acceptImage = (id) =>
    webapi()
        .path(id)
        .path('image/accept')
        .put()

export const denyImage = (id) =>
    webapi()
        .path(id)
        .path('image/deny')
        .put()
