import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import TierRow from './tier-row'

const GroupRow = ({ i, group, deletePriceTier, unit, onTierCheck, enumTier, quantityEnumMap }) => {
    return (
        <tr>
            <td>{i}</td>
            <td>
                {group.zones.map((z) => (
                    <div key={z.title}>{z.title}</div>
                ))}
            </td>
            <td>
                <Table className="price-list-table">
                    <caption>Active</caption>
                    <tbody>
                        {group.tiers
                            .filter((t) => !(t.validFrom >= Date.now()))
                            .map((t, ti) => (
                                <TierRow
                                    key={t.id}
                                    i={ti + 1}
                                    tier={t}
                                    unit={unit}
                                    prevWeight={group.tiers[ti - 1] ? group.tiers[ti - 1].weight : -1}
                                    deletePriceTier={deletePriceTier}
                                    onTierCheck={onTierCheck}
                                    enumTier={enumTier}
                                    quantityEnumMap={quantityEnumMap}
                                />
                            ))}
                    </tbody>
                </Table>
                <Table className="price-list-table">
                    <caption>Future</caption>
                    <tbody>
                        {group.tiers
                            .filter((t) => t.validFrom >= Date.now())
                            .map((t, ti) => (
                                <TierRow
                                    key={t.id}
                                    i={ti + 1}
                                    tier={t}
                                    unit={unit}
                                    prevWeight={group.tiers[ti - 1] ? group.tiers[ti - 1].weight : -1}
                                    deletePriceTier={deletePriceTier}
                                    onTierCheck={onTierCheck}
                                    enumTier={enumTier}
                                    quantityEnumMap={quantityEnumMap}
                                />
                            ))}
                    </tbody>
                </Table>
            </td>
        </tr>
    )
}

GroupRow.propTypes = {
    i: PropTypes.number,
    group: PropTypes.object,
    deletePriceTier: PropTypes.func,
    unit: PropTypes.object.isRequired,
    onTierCheck: PropTypes.func,
    enumTier: PropTypes.bool.isRequired,
    quantityEnumMap: PropTypes.object,
}

export default GroupRow
