import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.postal-code-zones-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('postalcodes')

export const getPostalcodeZones = () =>
    webapi()
        .path('zones')
        .get()

export const getCityLimits = () =>
    webapi()
        .path('zones/limits')
        .get()

export const newCityLimit = (request) =>
    webapi()
        .path('zones/limits')
        .post(request)

export const deleteCityLimit = (id) =>
    webapi()
        .path('zones/limits')
        .path(id)
        .delete()
