import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Row, Col, Alert, FormGroup, ControlLabel, FormControl, Button, Checkbox } from 'react-bootstrap'
import { connect } from 'react-redux'
import MerchantSelect from '../common/merchant-select'
import { upsertMerchantConsignmentLimit, upsertTerminalLimit } from '../../actions/terminal-limits'

const getInitState = () => ({
    merchantMaxNumConsignments: '',
    selectedMerchant: null,
    blockDirectCheckout: false,
})

class AddMerchantLimit extends Component {
    constructor(...args) {
        super(...args)
        this.state = getInitState()
    }

    resetState() {
        this.setState(getInitState())
    }

    cancelModal = () => {
        this.resetState()
        this.props.closeModal()
    }

    validate = () => {
        const { merchantMaxNumConsignments, selectedMerchant, blockDirectCheckout } = this.state
        const limit = parseInt(merchantMaxNumConsignments, 10)
        if (blockDirectCheckout) {
            return true
        }

        return (
            selectedMerchant != null &&
            !Number.isNaN(limit) &&
            merchantMaxNumConsignments >= 0 &&
            merchantMaxNumConsignments <= this.props.limit.maximumNumberOfConsignments
        )
    }

    onCreate() {
        const p = (() => {
            if (this.state.blockDirectCheckout) {
                return this.props.dispatch(
                    upsertTerminalLimit({
                        ...this.props.limit,
                        blockDirectCheckoutMerchants: true,
                    }),
                )
            }

            return this.props.dispatch(
                upsertMerchantConsignmentLimit({
                    maximumNumberOfConsignments: this.state.merchantMaxNumConsignments,
                    consignmentLimitId: this.props.limit.id,
                    merchantId: this.state.selectedMerchant.value,
                }),
            )
        })()

        p.then(this.cancelModal)
    }

    render() {
        const { merchantMaxNumConsignments, selectedMerchant } = this.state
        const { limit } = this.props

        return (
            <Modal show animation onHide={this.cancelModal}>
                <Modal.Header closeButton>Merchant consignment limit</Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <Checkbox
                                checked={this.state.blockDirectCheckout}
                                onChange={(e) => this.setState({ blockDirectCheckout: e.target.checked })}
                            >
                                Block direct checkout for all merchants
                            </Checkbox>
                        </FormGroup>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Merchant</ControlLabel>
                                    <MerchantSelect
                                        value={selectedMerchant}
                                        multi={false}
                                        onSelect={(merchant) => this.setState({ selectedMerchant: merchant })}
                                        disabled={this.state.blockDirectCheckout}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Max consignments</ControlLabel>
                                    <FormControl
                                        type="number"
                                        placeholder="Merchant Max Consignments"
                                        value={merchantMaxNumConsignments}
                                        disabled={this.state.blockDirectCheckout}
                                        onChange={(e) =>
                                            this.setState({
                                                merchantMaxNumConsignments: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>{limit.terminal}</Col>
                            <Col md={6}>{limit.date}</Col>
                            <Col md={12}>
                                {merchantMaxNumConsignments > limit.maximumNumberOfConsignments ? (
                                    <Alert bsStyle="danger">
                                        Cannot set limit {merchantMaxNumConsignments}, max is{' '}
                                        {limit.maximumNumberOfConsignments}
                                    </Alert>
                                ) : null}
                                <FormGroup>
                                    <Button onClick={this.props.closeModal}>Cancel</Button>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        disabled={!this.validate()}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.onCreate()
                                        }}
                                    >
                                        Save
                                    </Button>
                                </FormGroup>
                                <p>
                                    Set an upper limit of the number of Consignments that can be booked in a terminal on
                                    a date for a merchant.
                                </p>
                                <p>
                                    You can block a merchant from being able to do direct checkout by setting their
                                    limit to 0.
                                </p>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

AddMerchantLimit.propTypes = {
    dispatch: PropTypes.func.isRequired,
    limit: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default connect()(AddMerchantLimit)
