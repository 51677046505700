import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col, Panel, Button, Glyphicon } from 'react-bootstrap'

import Alert from '../alert'
import CreateFreeTimeslot from '../orders/modals/create-free-timeslot'
import Consumer from './panels/admin-consumer'
import FreeTimeslots from '../orders/panels/free-timeslots'

import HtmlTitle from '../html-title'

import auth from '../../auth'
import { createFreeTimeslot, getFreeTimeslots, consumerOrder } from '../../utils/order-webapi'
import { handleError } from '../../utils/handle-error'
import {
    consumerSearch,
    updateConsumer,
    deleteNotificationToken,
    consumerResidences,
    consumerOrders,
    consumerSubscriptions,
} from '../../utils/consumer-webapi'
import { recurringPaymentTransactions } from '../../utils/financial-webapi'
import ConsumerSettings from './panels/admin-consumer-settings'
import AdminResidences from './panels/admin-residences'
import AdminConsumerSubscriptions from './panels/admin-consumer-subscriptions'
import OrdersTable from '../orders/table'
import { COMMERCIAL_MANAGER, TRAFFIC_CONTROLLER } from '../../utils/role'
import AdminRecurringPaymentTransactions from './panels/admin-recurring-payment-transactions'

class View extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            consumer: null,
            settings: null,
            error: null,
            showCreateFreeTimeslotModal: false,
            showFreeTimeslots: false,
            freeTimeslots: [],
            residences: [],
            orders: [],
            subscriptions: [],
            supportedCities: [],
            transactions: [],
            transactionsError: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(TRAFFIC_CONTROLLER, COMMERCIAL_MANAGER)
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }
        this.getConsumerData()
    }

    onConsumerChange = (name, value) => {
        const consumer = {
            ...this.state.consumer,
            [name]: value,
        }
        this.setState((prevState) => ({ consumer: { ...prevState.consumer, ...consumer } }))
    }

    onConsumerSave = () => {
        const { consumer } = this.state
        updateConsumer(consumer.consumerId, consumer).catch(handleError)
    }

    getOrdersData = (orderIDs) => {
        // Displaying only the 20 most recent orders
        const requests = orderIDs
            .sort((a, b) => b - a)
            .slice(0, 20)
            .map(consumerOrder)

        return Promise.all(requests).then((body) =>
            body.map((res) => ({
                date: res.createdAt,
                token: res.token,
                buyerName: res.buyer.name,
                name: res.endCustomer.name,
                street: res.deliveryAddress.street,
                postalCode: res.deliveryAddress.postalCode,
                city: res.deliveryAddress.city,
            })),
        )
    }

    getConsumerData = () => {
        const { phoneNumber } = this.props.params

        consumerSearch('phoneNumber', phoneNumber)
            .then((consumer) => {
                this.setState({
                    consumer,
                    settings: { enabledNotifications: consumer.pushNotificationToken !== null },
                })
                this.syncTimeslots(phoneNumber).catch(handleError)
                consumerResidences(consumer.consumerId)
                    .then((resp) => this.setState({ residences: resp.residences }))
                    .catch(handleError)

                consumerOrders(consumer.consumerId)
                    .then((resp) => this.getOrdersData(resp.orders))
                    .then((orders) => this.setState({ orders }))
                    .catch(handleError)

                consumerSubscriptions(consumer.phoneNumber).then((subscriptionsResponse) => {
                    const subscriptions = subscriptionsResponse.timeslotSubscriptions
                    const { supportedCities } = subscriptionsResponse
                    this.setState({ subscriptions, supportedCities })

                    const onRecurringPaymentTransactionFailed = (e) => {
                        this.setState({ transactionsError: true })
                        handleError(e)
                    }
                    const recurringPaymentIds = subscriptions.map((subscription) => subscription.recurringPaymentId)
                    recurringPaymentTransactions(recurringPaymentIds)
                        .then((response) => this.setState({ transactions: response.payload.transactions }))
                        .catch(onRecurringPaymentTransactionFailed)
                })
            })
            .catch((e) => {
                handleError(e)
                this.setState({ error: e.statusText })
            })
    }

    syncTimeslots = (e164PhoneNumber) =>
        getFreeTimeslots(e164PhoneNumber).then((timeslots) =>
            this.setState({ freeTimeslots: timeslots.payload, showFreeTimeslots: true }),
        )

    createFreeTimeslot = (reason) => {
        const { consumer } = this.state
        const onResponse = (response) => {
            if (response.status !== 'COMPLETED') {
                alert('Could not create free timeslot')
            } else {
                this.syncTimeslots(consumer.phoneNumber)
            }
            this.setState({ showCreateFreeTimeslotModal: false })
        }
        createFreeTimeslot(consumer.phoneNumber, reason).then(onResponse)
    }

    onUpdateSetting = (val, setting) => {
        const { settings } = this.state
        settings[setting] = val
    }

    onSaveSettings = () => {
        const { consumer, settings } = this.state
        if (consumer.pushNotificationToken && !settings.enabledNotifications) {
            deleteNotificationToken(this.state.consumer.consumerId)
                .then(() => {
                    this.setState((prevState) => ({
                        consumer: { ...prevState.consumer, pushNotificationToken: null },
                        settings: { ...prevState.settings, enabledNotifications: false },
                    }))
                })
                .catch((e) => {
                    handleError(e)
                    this.setState({ error: e.statusText })
                })
        }
    }

    renderFreeTimeslots() {
        const { showCreateFreeTimeslotModal, freeTimeslots, showFreeTimeslots } = this.state
        if (!showFreeTimeslots) {
            return null
        }

        const header = <h3>Free Timeslots (Consumer)</h3>

        return (
            <Panel header={header}>
                <CreateFreeTimeslot
                    show={showCreateFreeTimeslotModal}
                    onCreate={this.createFreeTimeslot}
                    onClose={() => this.setState({ showCreateFreeTimeslotModal: false })}
                />
                <Button onClick={() => this.setState({ showCreateFreeTimeslotModal: true })}>
                    <Glyphicon glyph="plus" /> Add Free Timeslot
                </Button>
                <FreeTimeslots timeslots={freeTimeslots} />
            </Panel>
        )
    }

    renderResidences() {
        const { residences } = this.state

        const header = <h3>Residences</h3>

        return (
            <Panel header={header}>
                <AdminResidences residences={residences} />
            </Panel>
        )
    }

    renderOrders() {
        const { orders } = this.state

        const header = (
            <div className="orders-panel-consumers-view">
                <h3>Orders</h3>
                <small>20 most recent orders for this customer</small>
            </div>
        )

        return (
            <Panel header={header}>
                <OrdersTable orders={orders} />
            </Panel>
        )
    }

    cancelSubscription = () => {
        this.getConsumerData()
    }

    renderSubscriptions() {
        const { consumer, subscriptions, supportedCities } = this.state

        const header = <h3>Time slot subscription</h3>
        return (
            <Panel header={header}>
                <AdminConsumerSubscriptions
                    consumer={consumer}
                    supportedCities={supportedCities}
                    subscriptions={subscriptions}
                    onCancelSubscription={this.cancelSubscription}
                />
            </Panel>
        )
    }

    renderRecurringPaymentTransactions() {
        const { transactions, transactionsError } = this.state
        const header = <h3>Recurring Payment Transactions</h3>
        return (
            <Panel header={header}>
                {transactionsError ? 'Failed to load recurring payment transactions ' : null}
                <AdminRecurringPaymentTransactions transactions={transactions} />
            </Panel>
        )
    }

    render() {
        const { consumer, settings, error } = this.state
        if (error !== null) {
            return (
                <Grid fluid>
                    <Col md={8} mdOffset={2}>
                        <Alert type="danger" message={error} />
                    </Col>
                </Grid>
            )
        }

        if (consumer == null) {
            return null
        }

        const consumerHerder = <h3>Consumer</h3>
        const consumerSettings = <h3>Communication</h3>

        return (
            <div>
                <HtmlTitle title={consumer.name} />

                <Grid fluid>
                    <Row>
                        <Col md={4} mdOffset={2}>
                            <Panel header={consumerHerder}>
                                <Consumer
                                    consumer={consumer}
                                    onFormChange={this.onConsumerChange}
                                    onConsumerSave={this.onConsumerSave}
                                />
                            </Panel>
                        </Col>
                        <Col md={4}>
                            <Panel header={consumerSettings}>
                                <ConsumerSettings
                                    settings={settings}
                                    consumer={consumer}
                                    onUpdateSetting={this.onUpdateSetting}
                                    onSaveSettings={this.onSaveSettings}
                                />
                            </Panel>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderSubscriptions()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderRecurringPaymentTransactions()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderFreeTimeslots()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderResidences()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderOrders()}
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

View.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
}

export default View
