import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { convertCentsToWhole } from '../../../utils/couriers'
import { formatDate, formatTime } from '../../../utils/date'

export default class BaseTierTable extends Component {
    renderMultiOrDefault = (values, defaultValue) => (isEmpty(values) ? defaultValue : values.join(', '))

    renderTimesIfPopulated = (from, to) => (from && to ? `${formatTime(from)} to ${formatTime(to)}` : null)

    renderDays = ({ daysOfWeek, specialDay, validFrom, validTo }) => {
        if (specialDay) {
            return `Overrides day of week  between ${formatDate(validFrom)} and ${formatDate(validTo)}`
        }

        return this.renderMultiOrDefault(daysOfWeek, 'All days of week')
    }

    renderValidToFrom = (validFrom, validTo) => {
        if (validFrom === null && validTo === null) {
            return 'Always'
        }
        let from = 'Always'
        if (validFrom) {
            from = moment(validFrom, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        let to = 'forever'
        if (validTo) {
            to = moment(validTo, 'YYYY-MM-DD').format('YYYY-MM-DD')
        }
        return `${from} to ${to}`
    }

    renderTime = ({ timeOfDayStart, timeOfDayStop }) =>
        this.renderTimesIfPopulated(timeOfDayStart, timeOfDayStop) || 'All times of day'

    renderRows = () => {
        const { compensationTiers, onSelect, onEdit } = this.props

        return compensationTiers.map((tier) => (
            <tr key={tier.id} onClick={onEdit(tier)} style={{ cursor: 'pointer' }}>
                <td>
                    <input
                        type="checkbox"
                        name={tier.type}
                        value={tier.id}
                        onClick={onSelect}
                        checked={tier.selected}
                    />
                </td>
                <td>{this.renderMultiOrDefault(tier.cities, 'All cities')}</td>
                <td>{this.renderDays(tier)}</td>
                <td>{this.renderTime(tier)}</td>
                <td> {tier.lowerBound === null ? 0 : tier.lowerBound} </td>
                <td> {tier.upperBound === null ? 'infinity' : tier.upperBound} </td>
                <td>{this.renderMultiOrDefault(tier.driverLevels, 'All levels')}</td>
                <td>
                    {convertCentsToWhole(tier.incrementalPriceInCents)} {tier.currency}
                </td>
                <td>{this.renderValidToFrom(tier.validFrom, tier.validTo)}</td>
                <td>{tier.routeType}</td>
                <td>{tier.vehicleType}</td>
            </tr>
        ))
    }

    render() {
        const { onSelectAll, type } = this.props

        return (
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" name={type.id} onChange={onSelectAll} />
                        </th>
                        <th>City</th>
                        <th>Days</th>
                        <th>Time of day</th>
                        <th>Start range (inclusive)</th>
                        <th>End range (exclusive)</th>
                        <th>Driver level</th>
                        <th>{type.label}</th>
                        <th>Valid Interval</th>
                        <th>Route Type</th>
                        <th>Vehicle Type</th>
                    </tr>
                </thead>
                <tbody>{this.renderRows()}</tbody>
            </Table>
        )
    }
}

BaseTierTable.propTypes = {
    type: PropTypes.object.isRequired,
    compensationTiers: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
}
