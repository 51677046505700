import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import acc from 'accounting'
import mapType from '../../../../utils/map-price-point-type'

class PricePointItem extends React.Component {
    formatAmount = (item) =>
        acc.formatMoney(item.price.amount / 100, {
            symbol: item.price.currency.code,
            decimal: ',',
            thousand: ' ',
            format: '%v %s',
        })

    formatDate = (date) => {
        if (date == null) {
            return null
        }

        return moment(date).format('YYYY-MM-DD')
    }

    render() {
        const { item, onSelected } = this.props
        return (
            <tr>
                <td>
                    <input type="checkbox" name="pricePoint" checked={item.selected} onChange={onSelected(item)} />
                </td>
                <td>{mapType.toLabel(item.type)}</td>
                <td>{this.formatAmount(item)}</td>
                <td>{this.formatDate(item.validFrom)}</td>
                <td>{this.formatDate(item.validTo)}</td>
            </tr>
        )
    }
}

export default PricePointItem

PricePointItem.propTypes = {
    item: PropTypes.object.isRequired,
    onSelected: PropTypes.func.isRequired,
}
