import Api from './api'
import auth from '../auth'
import dev from './dev'

const baseUrl = '/api/parcel-discounts'
const contentType = dev('application/json', 'application/vnd.budbee.parcel-discounts-v1+json')

export default {
    get(id) {
        return new Api(baseUrl)
            .queryParam('buyerId', id)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .get()
    },

    create(request) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .post(request)
    },

    delete(id) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(id)
            .delete()
    },
}
