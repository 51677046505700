import {
    FETCHING_TERMINAL_LIMITS,
    TERMINAL_LIMITS_LOADED,
    TERMINAL_LIMITS_FAILED_TO_LOAD,
    TERMINAL_LIMIT_LOADED,
    TERMINAL_LIMIT_REMOVED,
    TERMINAL_LIMIT_FAILED_TO_CREATE,
    TERMINAL_LIMIT_FAILED_TO_REMOVE,
    MERCHANT_LIMIT_REMOVED,
    CONSIGNMENT_COUNTS_LOADED,
    CONSIGNMENT_COUNTS_FAILED,
    ROUTING_DEADLINES_LOADED,
    LOADING_ROUTING_DEADLINES_FAILED,
    LOADING_ROUTING_DEADLINES,
} from '../constants/actionTypes'
import {
    getTerminalLimits,
    setTerminalLimit,
    setMerchantConsignmentLimit,
    removeMerchantConsignmentLimit,
    getActiveConsignmentsCount,
} from '../utils/limits-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import { getRoutingDeadlines, overrideRoutingDeadline } from '../utils/deadlines-webapi'

export const fetchingTerminalLimits = () => ({
    type: FETCHING_TERMINAL_LIMITS,
})

export const terminalLimitsLoaded = (limits) => ({
    type: TERMINAL_LIMITS_LOADED,
    status: 200,
    error: null,
    payload: limits.payload,
})

export const terminalLimitsFailedToLoad = (e) => ({
    type: TERMINAL_LIMITS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

export const terminalLimitLoaded = (limit) => ({
    type: TERMINAL_LIMIT_LOADED,
    payload: limit.payload,
})

export const terminalLimitRemoved = (limitId, merchantId) => () => ({
    type: MERCHANT_LIMIT_REMOVED,
    payload: {
        limitId,
        merchantId,
    },
})

export const terminalLimitFailedToCreate = (e) => ({
    type: TERMINAL_LIMIT_FAILED_TO_CREATE,
    status: e.status,
    error: e.statusText,
})

export const terminalLimitFailedToRemove = (e) => ({
    type: TERMINAL_LIMIT_FAILED_TO_REMOVE,
    status: e.status,
    error: e.statusText,
})

export const removedTerminalLimit = (cityLimit) => ({
    type: TERMINAL_LIMIT_REMOVED,
    cityLimit,
})

export const consignmentCountsLoaded = (activeConsignments) => ({
    type: CONSIGNMENT_COUNTS_LOADED,
    counts: activeConsignments,
})

export const consignmentCountsFailed = (e) => ({
    type: CONSIGNMENT_COUNTS_FAILED,
    status: e.status,
    error: e.statusText,
})

export const routingDeadlinesLoading = () => ({
    type: LOADING_ROUTING_DEADLINES,
})

export const routingDeadlinesLoaded = (response) => ({
    type: ROUTING_DEADLINES_LOADED,
    payload: response.payload,
})

export const routingDeadlinesLoadingFailed = (response) => ({
    type: LOADING_ROUTING_DEADLINES_FAILED,
    response,
})

/**
    Fetch terminal limits from backend
*/
export const fetchTerminalLimits = () => (dispatch) => {
    dispatch(fetchingTerminalLimits())
    getTerminalLimits()
        .then(compose(dispatch, terminalLimitsLoaded))
        .catch(compose(dispatch, terminalLimitsFailedToLoad, handleError))
}

/**
    Create a new city Limit
*/
export const upsertTerminalLimit = (limit) => (dispatch) =>
    setTerminalLimit(limit)
        .then(compose(dispatch, terminalLimitLoaded))
        .catch(compose(dispatch, terminalLimitFailedToCreate, handleError))

/**
    Create a new Merchant Consignment Limit
*/
export const upsertMerchantConsignmentLimit = (merchantLimit) => (dispatch) =>
    setMerchantConsignmentLimit(
        merchantLimit.consignmentLimitId,
        merchantLimit.merchantId,
        merchantLimit.maximumNumberOfConsignments,
    )
        .then(compose(dispatch, terminalLimitLoaded))
        .catch(compose(dispatch, terminalLimitFailedToCreate, handleError))

export const deleteMerchantConsignmentLimit = (limitId, merchantId) => (dispatch) =>
    removeMerchantConsignmentLimit(limitId, merchantId)
        .then(compose(dispatch, terminalLimitRemoved(limitId, merchantId)))
        .catch(compose(dispatch, terminalLimitFailedToRemove, handleError))

export const countActiveConsignments = () => (dispatch) =>
    getActiveConsignmentsCount()
        .then(compose(dispatch, consignmentCountsLoaded))
        .catch(compose(dispatch, consignmentCountsFailed, handleError))

export const fetchRoutingDeadlines = () => (dispatch) => {
    dispatch(routingDeadlinesLoading())
    return getRoutingDeadlines()
        .then(compose(dispatch, routingDeadlinesLoaded))
        .catch(compose(dispatch, routingDeadlinesLoadingFailed, handleError))
}

/**
    Override terminal deadline
 */
export const updateRoutingDeadline = (deadline) => () => overrideRoutingDeadline(deadline)
