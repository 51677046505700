/**
 *
 * @param {*} element     The element to upsert into the array
 * @param {*} collection  The array to upsert the element to
 */
export const upsert = (element, collection) => {
    const shouldUpdate = collection.some((x) => x.id === element.id)

    return shouldUpdate ? collection.map((x) => (x.id === element.id ? element : x)) : [...collection, element]
}
