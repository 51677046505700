import {
    FETCHING_COURIER,
    FETCHING_COURIERS,
    COURIERS_LOADED,
    COURIER_LOADED,
    COURIER_SELECTED,
    UPDATING_COURIER,
    COURIER_UPDATED,
    COURIER_FAILED_TO_UPDATE,
    COURIERS_FAILED_TO_LOAD,
    COMPENSATION_TIERS_FAILED_TO_LOAD,
    COMPENSATION_TIER_FAILED_TO_UPDATE,
    COMPENSATION_TIERS_LOADED,
    COMPENSATION_TIER_UPDATED,
    FETCHING_COMPENSATION_TIERS,
    UPDATING_COMPENSATION_TIER,
    DELETING_COMPENSATION_TIERS,
    COMPENSATION_TIERS_DELETED,
    COMPENSATION_TIERS_FAILED_TO_DELETE,
} from '../constants/actionTypes'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import {
    getCourier,
    getCouriers,
    editCourier,
    getCompensationTiers,
    postCompensationTier,
    postCompensationTiers,
    putCompensationTier,
    dropCompensationTiers,
    putEndCourierCompensationTiers,
} from '../utils/courier-webapi'
import { constant } from '../utils/constant'

const fetchingCouriers = () => ({
    type: FETCHING_COURIERS,
})

const fetchingCourier = () => ({
    type: FETCHING_COURIER,
})

const courierLoaded = (courier) => ({
    type: COURIER_LOADED,
    status: 200,
    courier,
})

const courierFailedToLoad = (e) => ({
    type: COURIERS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

const couriersLoaded = (couriers) => ({
    type: COURIERS_LOADED,
    status: 200,
    couriers,
})

const courierSelected = (id) => ({
    type: COURIER_SELECTED,
    status: 200,
    id,
})

const updatingCourier = () => ({
    type: UPDATING_COURIER,
})

const courierUpdated = (courier) => ({
    type: COURIER_UPDATED,
    courier,
})

const courierFailedToUpdate = (e) => ({
    type: COURIER_FAILED_TO_UPDATE,
    status: e.status,
    error: e.statusText,
})

const fetchingCompensationTiers = () => ({
    type: FETCHING_COMPENSATION_TIERS,
})

const compensationTiersLoaded = (compensationTiers) => ({
    type: COMPENSATION_TIERS_LOADED,
    compensationTiers,
})

const compensationTiersFailedToLoad = (e) => ({
    type: COMPENSATION_TIERS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

const updatingCompensationTier = () => ({
    type: UPDATING_COMPENSATION_TIER,
})

const compensationTierUpdated = () => ({
    type: COMPENSATION_TIER_UPDATED,
})

const compensationTierFailedToUpdate = (e) => ({
    type: COMPENSATION_TIER_FAILED_TO_UPDATE,
    status: e.status,
    error: e.statusText,
})

const deletingCompensationTiers = () => ({
    type: DELETING_COMPENSATION_TIERS,
})

const compensationTiersDeleted = () => ({
    type: COMPENSATION_TIERS_DELETED,
})

const compensationTiersFailedToDelete = (e) => ({
    type: COMPENSATION_TIERS_FAILED_TO_DELETE,
    status: e.status,
    error: e.statusText,
})

export const fetchCourier = (id) => (dispatch) => {
    dispatch(fetchingCourier())
    return getCourier(id)
        .then(compose(dispatch, courierLoaded))
        .catch(compose(dispatch, courierFailedToLoad, handleError))
}

export const fetchCouriers = () => (dispatch) => {
    dispatch(fetchingCouriers())
    return getCouriers()
        .then(compose(dispatch, couriersLoaded))
        .catch(handleError)
}

export const updateCourier = (id, courier) => (dispatch) => {
    dispatch(updatingCourier())
    return editCourier(id, courier)
        .then(compose(dispatch, courierUpdated, constant(courier)))
        .catch((error) => {
            compose(dispatch, courierFailedToUpdate, handleError)(error)
            throw error
        })
}

export const selectCourier = (id) => (dispatch) => dispatch(courierSelected(id))

export const fetchCompensationTiers = (courierId, validTo) => (dispatch) => {
    dispatch(fetchingCompensationTiers())
    return getCompensationTiers(courierId, validTo)
        .then(compose(dispatch, compensationTiersLoaded))
        .catch(compose(dispatch, compensationTiersFailedToLoad, handleError))
}

export const createCompensationTier = (courierId, compensationTier) => (dispatch) => {
    dispatch(updatingCompensationTier())
    return postCompensationTier(courierId, compensationTier)
        .then(compose(dispatch, compensationTierUpdated))
        .catch(compose(dispatch, compensationTierFailedToUpdate, handleError))
}

export const createCompensationTiers = (courierId, compensationTiers) => (dispatch) => {
    dispatch(updatingCompensationTier())
    return postCompensationTiers(courierId, compensationTiers)
        .then(compose(dispatch, compensationTierUpdated))
        .catch(compose(dispatch, compensationTierFailedToUpdate, handleError))
}

export const updateEndCourierCompensationTiers = (courierId, validTo, routeTypeToEnd) => (dispatch) => {
    dispatch(updatingCompensationTier())
    return putEndCourierCompensationTiers(courierId, validTo, routeTypeToEnd)
        .then(compose(dispatch, compensationTierUpdated))
        .catch(compose(dispatch, compensationTierFailedToUpdate, handleError))
}

export const updateCompensationTier = (courierId, compensationTier) => (dispatch) => {
    dispatch(updatingCompensationTier())
    return putCompensationTier(courierId, compensationTier)
        .then(compose(dispatch, compensationTierUpdated))
        .catch(compose(dispatch, compensationTierFailedToUpdate, handleError))
}

export const deleteCompensationTiers = (courierId, compensationTierIds) => (dispatch) => {
    dispatch(deletingCompensationTiers())
    return dropCompensationTiers(courierId, compensationTierIds)
        .then(compose(dispatch, compensationTiersDeleted))
        .catch(compose(dispatch, compensationTiersFailedToDelete, handleError))
}
