import Webapi from './webapi'
import auth from '../auth'

const v2 = 'application/vnd.budbee.postal-code-zones-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .contentType(v2)
        .accept(v2)
        .path('postal-code-zone-groups')

export const createPostalCodeZoneGroup = (data) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .post(data)

export const getAllPostalCodeZoneGroups = () =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .get()

export const getLatestPostalCodeZoneGroups = () =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .queryParam('latest-only', true)
        .queryParam('fetch-zones', 'false')
        .get()

export const updatePostalCodeZoneGroup = (groupId, data) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .put(data)

export const removePostalCodeZoneGroup = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .delete()

export const getPostalCodeZonesByGroupID = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .get()

export const getPostalCodeZoneGroupByID = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .get()

export const getPreviousPostalCodeZoneGroupByID = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .path('previous')
        .get()

export const getNextPostalCodeZoneGroupByID = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .path('next')
        .get()

export const publishPostalCodeZoneGroup = (groupId, requestBody) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path(groupId)
        .path('publish')
        .post(requestBody)

export const unpublishPostalCodeZoneGroup = (groupId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path('unpublish')
        .path(groupId)
        .get()

export const getDiffBetweenGroups = (liveId, draftId) =>
    webapi()
        .contentType(v2)
        .accept(v2)
        .path('diff')
        .path(`${liveId}..${draftId}`)
        .get()
