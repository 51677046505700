import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import ReturnPalletGroupCreateForm from './return-pallet-group-create-form'
import ReturnPalletGroupTable from './return-pallet-group-table'
import ReturnPalletConfigurationHeader from './return-pallet-configuration-header'

const ReturnPalletConfiguration = () => (
    <Grid fluid>
        <Row>
            <Col md={8} mdOffset={2}>
                <ReturnPalletConfigurationHeader />
                <ReturnPalletGroupCreateForm />
            </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
            <Col md={8} mdOffset={2}>
                <ReturnPalletGroupTable />
            </Col>
        </Row>
    </Grid>
)

export default ReturnPalletConfiguration
