import React from 'react'
import PropTypes from 'prop-types'

import SortingMachineLanePanel from './sorting-machine-lane-panel'
import { sortLanes } from './sort-lanes'

const SortingMachine = ({ terminal, terminalLanes, rules, routeSortingStatuses, routeRules, onClick }) => (
    <div className="machine">
        <div className="arrow-down" />
        <div className="arrow-down" />
        <div className="middle-section">
            {sortLanes(terminal, terminalLanes).map((lane, idx) => (
                <SortingMachineLanePanel
                    key={lane.id}
                    lane={lane}
                    idx={idx}
                    rules={rules.filter((rule) => rule.terminalSortingLaneId === lane.id)}
                    routeSortingStatuses={routeSortingStatuses.filter((rule) => rule.lane === lane.name)}
                    routeRules={routeRules.filter((rule) => rule.terminalSortingLane.id === lane.id)}
                    onClick={onClick}
                />
            ))}
        </div>
    </div>
)

SortingMachine.propTypes = {
    terminal: PropTypes.object.isRequired,
    routeRules: PropTypes.array.isRequired,
    rules: PropTypes.array.isRequired,
    terminalLanes: PropTypes.array.isRequired,
    routeSortingStatuses: PropTypes.array.isRequired,
    onClick: PropTypes.func,
}

export default SortingMachine
