import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'

class CreateFreeTimeslot extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reason: '',
        }
    }

    render() {
        const { show, onCreate, onClose } = this.props
        const { reason } = this.state
        const close = () => {
            this.setState({ reason: '' })
            onClose()
        }
        const valid = reason && reason.trim().length !== 0
        return (
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Free Timeslot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <ControlLabel>Reason</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                rows="3"
                                placeholder="Specify reason"
                                value={reason}
                                autoFocus
                                onChange={(e) => this.setState({ reason: e.target.value })}
                            />
                        </FormGroup>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close}>Close</Button>
                    <Button disabled={!valid} onClick={() => onCreate(reason)}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

CreateFreeTimeslot.propTypes = {
    show: PropTypes.bool,
    onCreate: PropTypes.func,
    onClose: PropTypes.func,
}

export default CreateFreeTimeslot
