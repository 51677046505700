import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .accept(contentType)
        .contentType(contentType)
        .authorization(auth.get())

export const getRoutingDeadlines = () =>
    webapi()
        .path('routing-deadlines')
        .accept('application/vnd.budbee.booking-deadlines-v2+json')
        .contentType('application/vnd.budbee.booking-deadlines-v2+json')
        .get()

export const overrideRoutingDeadline = (request) =>
    webapi()
        .path('override-terminal-booking-deadline')
        .accept('application/vnd.budbee.booking-deadlines-v2+json')
        .contentType('application/vnd.budbee.booking-deadlines-v2+json')
        .post(request)
