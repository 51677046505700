import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

export default class Confirm extends Component {
    constructor(props) {
        super(props)

        this.onClickConfirm = this.onClickConfirm.bind(this)
    }

    onClickConfirm() {
        const { onConfirm, onClose } = this.props
        if (onConfirm) {
            onConfirm()
        }
        onClose()
    }

    render() {
        const { onClose, show, cancelText, title, children, bsStyle, confirmText } = this.props

        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {children && <Modal.Body>{children}</Modal.Body>}
                <Modal.Footer>
                    <Button onClick={onClose}>{cancelText}</Button>
                    <Button bsStyle={bsStyle} onClick={this.onClickConfirm}>
                        {confirmText}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

Confirm.defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    bsStyle: 'danger',
}

Confirm.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.any,
    bsStyle: PropTypes.string,
}
