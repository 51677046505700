import React from 'react'
import cx from 'classnames'

const labelTranslation = {
    PRICE_PER_STOP: 'default',
    PRICE_PER_MISS: 'danger',
    PRICE_PER_HOUR: 'warning',
    PRICE_PER_CANCELLATION_PRE_CUTOFF: 'warning',
    PRICE_PER_CANCELLATION_POST_CUTOFF: 'danger',
    START_DEBIT_PER_DAY: 'success',
    START_DEBIT_PER_ROUTE: 'success',
    MINIMUM_DEBIT_PER_DAY: 'info',
    MINIMUM_DEBIT_PER_ROUTE: 'info',
    PICK_UP_ROUTE: 'info',
    RATING_INCENTIVE: 'default',
    PRICE_PER_RECALL: 'danger',
    PRICE_PER_MANUAL_SORTING_LARGE_PARCEL: 'danger',
    PRICE_PER_MANUAL_SORTING_EXTRA_LARGE_PARCEL: 'danger',
    PRICE_FOR_TOO_LARGE_TO_DELIVER: 'danger',
    PRICE_FOR_SIGNATURE_REQUIRED_AT_DOOR: 'success',
    PRICE_FOR_FRAUD_PROTECTION_BOX_DELIVERY: 'success',
    PRICE_FOR_BOX_TO_HOME_TOO_LARGE_TO_DELIVER: 'success',
}

const textTranslation = {
    PRICE_PER_STOP: 'Price per stop',
    PRICE_PER_MISS: 'Price per miss',
    PRICE_PER_HOUR: 'Price per hour',
    PRICE_PER_CANCELLATION_PRE_CUTOFF: 'Price per Cancellation Pre Cutoff',
    PRICE_PER_CANCELLATION_POST_CUTOFF: 'Price per Cancellation Post Cutoff',
    START_DEBIT_PER_DAY: 'Start debit per day',
    START_DEBIT_PER_ROUTE: 'Start debit per route',
    MINIMUM_DEBIT_PER_DAY: 'Minimum debit per day',
    MINIMUM_DEBIT_PER_ROUTE: 'Minimum debit per route',
    PICK_UP_ROUTE: 'One Time Pick Up',
    RATING_INCENTIVE: 'Rating incentive',
    PRICE_PER_RECALL: 'Price per recall',
    PRICE_PER_MANUAL_SORTING_LARGE_PARCEL: 'Manual sorting Large parcel',
    PRICE_PER_MANUAL_SORTING_EXTRA_LARGE_PARCEL: 'Manual sorting Extra large parcel',
    PRICE_FOR_TOO_LARGE_TO_DELIVER: 'Large parcel recall',
    PRICE_FOR_SIGNATURE_REQUIRED_AT_DOOR: 'Signature required on Home Delivery',
    PRICE_FOR_FRAUD_PROTECTION_BOX_DELIVERY: 'Fraud protection on Box Delivery',
    PRICE_FOR_BOX_TO_HOME_TOO_LARGE_TO_DELIVER: 'Box to Home conversion due to size',
}

const mapType = {
    toLabel(type) {
        const classes = {
            label: true,
            [`label-${labelTranslation[type]}`]: true,
        }

        return <span className={cx(classes)}>{mapType.toText(type)}</span>
    },

    toText(type) {
        if (textTranslation[type] != null) {
            return textTranslation[type]
        }

        return type
    },
}

export default mapType
