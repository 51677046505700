import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import UserReducer from './users'
import RolesReducer from './roles'
import ZoneManagementReducer from './zoneManagement'
import BuyersReducer from './buyers'
import OwnerReducer from './owners'
import PostalCodeZonesReducer from './postal-code-zones'
import CollectionReducer from './collection-points'
import DiscountsReducer from './discounts'
import TerminalReducer from './terminals'
import TerminalRuleReducer from './terminal-rules'
import DockingReducer from './docking'
import SmsReducer from './sms'
import HolidaysReducer from './holidays'
import TerminalLimitsReducer from './terminal-limits'
import LoadingSchedulerReducer from './loading-scheduler'
import recurringPickupsReducer from './recurring-pickups'
import routeSearchReducer from './route-search'
import { linehaulReducer } from './linehauls'
import RegionalSettingsReducer from './regional-settings'
import InvoiceSettingsReducer from './invoice-settings'
import CouriersReducer from './couriers'
import TagsReducer from './tags'
import TerminalProfileRulesReducer from './terminal-profile-rules'
import ReturnPalletGroupsReducer from './return-pallet-groups'

const rootReducer = combineReducers({
    users: UserReducer,
    roles: RolesReducer,
    zoneManagement: ZoneManagementReducer,
    couriers: CouriersReducer,
    buyers: BuyersReducer,
    owners: OwnerReducer,
    routing: routerReducer,
    postalCodeZones: PostalCodeZonesReducer,
    collection: CollectionReducer,
    discounts: DiscountsReducer,
    terminals: TerminalReducer,
    terminalRules: TerminalRuleReducer,
    docking: DockingReducer,
    sms: SmsReducer,
    holidays: HolidaysReducer,
    limits: TerminalLimitsReducer,
    loadingScheduler: LoadingSchedulerReducer,
    recurringPickups: recurringPickupsReducer,
    routeSearch: routeSearchReducer,
    linehaul: linehaulReducer,
    regionalSettings: RegionalSettingsReducer,
    invoiceSettings: InvoiceSettingsReducer,
    tags: TagsReducer,
    terminalProfileRules: TerminalProfileRulesReducer,
    returnPalletGroups: ReturnPalletGroupsReducer,
})

export default rootReducer
