import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

const SortableItem = SortableElement(({ value, position }) => (
    <span>
        <li className="SortableItem">
            {position}. {value}
        </li>
    </span>
))

const SortableList = SortableContainer(({ items }) => (
    <ul className="SortableList">
        {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} position={index + 1} value={value} />
        ))}
    </ul>
))

const RemoveItem = ({ position, removeItem }) => (
    <li className="RemoveItem" onClick={() => removeItem(position)}>
        <i className="glyphicon glyphicon-remove inline" />
    </li>
)

const RemoveList = ({ items, removeItem }) => (
    <ul className="RemoveList">
        {items.map((value, index) => (
            <RemoveItem key={`ŕm-item-${index}`} index={index} position={index} removeItem={removeItem} />
        ))}
    </ul>
)

export default class SortList extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="media">
                <div className="media-body">
                    <SortableList
                        items={this.props.items}
                        onSortEnd={this.props.onSortEnd.bind(this)}
                        helperClass="SortableHelper"
                    />
                </div>
                <div className="media-right">
                    <RemoveList items={this.props.items} removeItem={this.props.removeItem} />
                </div>
            </div>
        )
    }
}
