import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Loader from 'react-loader'
import { connect } from 'react-redux'
import { Grid, Table, Button, Glyphicon, FormControl, InputGroup } from 'react-bootstrap'
import moment from 'moment'
import AddMerchantLimit from './add-merchant-limit-modal'
import {
    fetchTerminalLimits,
    upsertTerminalLimit,
    deleteMerchantConsignmentLimit,
    countActiveConsignments,
    fetchRoutingDeadlines,
    updateRoutingDeadline as overrideRoutingDeadline,
} from '../../actions/terminal-limits'
import { fetchMerchants } from '../../actions/merchants'
import { fetchTerminalsWebapi } from '../../actions/terminals'
import HtmlTitle from '../html-title'
import Alert from '../alert'
import auth from '../../auth'
import { mapSort, sortNumbers, sortStrings } from '../../utils/sorting'
import {
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TRAFFIC_CONTROLLER,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    LEGACY_BUDBEE_TERMINAL_WORKER,
    LEGACY_TERMINAL_WORKER,
} from '../../utils/role'

const now = moment()
const limitsSorter = mapSort(sortNumbers, ({ date }) => moment(date, 'YYYY-MM-DD').unix())
const merchantLimitsSorter = mapSort(sortStrings, ({ merchant }) => merchant)
const unique = (value, index, self) => self.indexOf(value) === index

/**
    Renders all future limit on number of consigmnents for a terminal
    Allows User to edit limits
*/
class TerminalLimits extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            showAddMerchantLimit: false,
            limit: null,
            editLimit: false,
            terminal: null,
            date: null,
            newLimit: null,
            editRoutingDeadline: false,
            currentRoutingDeadline: null,
            newRoutingDeadline: null,
            newRoutingDeadlineError: false,
            isSaving: false,
        }

        this.canEdit = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

        const urlSearchParams = new URLSearchParams(window.location.search)
        this.urlFilter = {
            countryCodes: urlSearchParams.getAll('country').map((country) => country.toUpperCase()),
            terminalCodes: urlSearchParams.getAll('terminal').map((terminal) => terminal.toUpperCase()),
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
            LEGACY_BUDBEE_TERMINAL_WORKER,
            LEGACY_TERMINAL_WORKER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        const { dispatch } = this.props
        dispatch(fetchTerminalLimits())
        dispatch(fetchMerchants())
        dispatch(fetchTerminalsWebapi())
        dispatch(countActiveConsignments())
        dispatch(fetchRoutingDeadlines())
        this.intervalId = setInterval(() => dispatch(countActiveConsignments()), 30000)
    }

    componentDidUpdate(prevProps, prevState) {
        const { dispatch } = this.props

        if (this.state.isSaving) {
            dispatch(fetchRoutingDeadlines()).then(() => {
                if (prevState.isSaving) {
                    this.setState({ isSaving: false })
                }
            })
        }
    }

    componentWillUnmount() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId)
        }
    }

    filterTerminals = (terminals) => {
        if (this.urlFilter.countryCodes.length || this.urlFilter.terminalCodes.length) {
            return terminals.filter(
                (terminal) =>
                    this.urlFilter.terminalCodes.some((code) => terminal.code === code) ||
                    this.urlFilter.countryCodes.some((countryCode) => terminal.address.countryCode === countryCode),
            )
        }
        return terminals
    }

    toggleAddMerchantLimit = (limit) => () => {
        this.setState({ showAddMerchantLimit: true, limit })
    }

    toggleEditLimit = (terminal, date) => () => {
        this.setState({ editLimit: true, terminal, date, newLimit: null })
    }

    toggleEditRoutingDeadline = (terminal, date, currentRoutingDeadline) => () => {
        this.setState({ editRoutingDeadline: true, terminal, date, currentRoutingDeadline })
    }

    upsertNewLimit = (e) => {
        e.preventDefault()
        const request = {
            terminal: this.state.terminal,
            date: this.state.date,
            maximumNumberOfConsignments: this.state.newLimit,
            blockDirectCheckoutMerchants: false,
        }
        this.props.dispatch(upsertTerminalLimit(request)).then(this.resetNewLimit)
    }

    isValidRoutingDeadline = (deadline) => /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(deadline)

    getDeadlineDate = (date, deadline) => {
        const deadlineDate = new Date(date)
        const timeParts = deadline.split(':')
        const hours = timeParts[0]
        const minutes = timeParts[1]
        deadlineDate.setHours(hours)
        deadlineDate.setMinutes(minutes)

        return deadlineDate
    }

    updateRoutingDeadline = (e) => {
        e.preventDefault()

        const { terminal, date, currentRoutingDeadline, newRoutingDeadline } = this.state

        if (!this.isValidRoutingDeadline(newRoutingDeadline)) {
            this.setState({ newRoutingDeadlineError: true })

            return
        }

        // The new deadline time can not be earlier than the current deadline
        const currDeadlline = this.getDeadlineDate(date, currentRoutingDeadline)
        const newDeadline = this.getDeadlineDate(date, newRoutingDeadline)

        if (newDeadline.getTime() <= currDeadlline.getTime()) {
            this.setState({ newRoutingDeadlineError: true })

            return
        }

        this.setState({ newRoutingDeadlineError: false })

        const request = {
            terminal,
            date,
            deadline: newRoutingDeadline,
        }
        this.props.dispatch(overrideRoutingDeadline(request)).then(() => {
            this.resetNewRoutingDeadline()
            this.setState({
                isSaving: true,
            })
        })
    }

    resetNewLimit = () => {
        this.setState({
            editLimit: false,
            terminal: null,
            date: null,
            newLimit: null,
        })
    }

    resetNewRoutingDeadline = () => {
        this.setState({
            editRoutingDeadline: false,
            terminal: null,
            date: null,
            currentRoutingDeadline: null,
            newRoutingDeadline: null,
            newRoutingDeadlineError: false,
        })
    }

    removeMerchantLimit = (limitId, merchantId, merchant) => () => {
        if (!window.confirm(`Are you sure you want to remove the limit for ${merchant}?`)) {
            return
        }

        this.props.dispatch(deleteMerchantConsignmentLimit(limitId, merchantId))
    }

    removeCheckoutBlock = (limit) => () => {
        if (!window.confirm('Are you sure you want to remove direct checkout block?')) {
            return
        }

        this.props.dispatch(
            upsertTerminalLimit({
                ...limit,
                blockDirectCheckoutMerchants: false,
            }),
        )
    }

    renderColumn = (date) => {
        const m = moment(date, 'YYYY-MM-DD')
        const isToday = now.format('YYYY-MM-DD') === m.format('YYYY-MM-DD')
        const header = isToday ? 'Today' : m.format('DD/MM')

        return (
            <th key={date} className="text-center">
                <div>{header}</div>
                <small>{isToday ? m.format('DD/MM - dddd') : m.format('dddd')}</small>
            </th>
        )
    }

    renderTableHeader(dates) {
        return (
            <thead>
                <tr>
                    <th>Terminal</th>
                    {dates.map(this.renderColumn)}
                </tr>
            </thead>
        )
    }

    renderAllBlocked(limit) {
        return (
            <div className="clearfix">
                {this.canEdit ? (
                    <Button
                        type="button"
                        bsStyle="link"
                        style={{ padding: '0 12px' }}
                        onClick={this.removeCheckoutBlock(limit)}
                        className="remove pull-right"
                        title="Remove direct checkout block"
                    >
                        <Glyphicon glyph="remove" />
                    </Button>
                ) : null}
                <span className="text-danger">Block direct checkout for all merchants</span>
            </div>
        )
    }

    renderMerchantLimit = (terminalLimit) => (merchantLimit) => {
        return (
            <div key={merchantLimit.merchant} className="clearfix">
                {this.canEdit && terminalLimit != null ? (
                    <Button
                        type="button"
                        bsStyle="link"
                        style={{ padding: '0 12px' }}
                        onClick={this.removeMerchantLimit(
                            terminalLimit.id,
                            merchantLimit.merchantId,
                            merchantLimit.merchant,
                        )}
                        className="remove pull-right"
                        title={`Remove limit for ${merchantLimit.merchant}`}
                    >
                        <Glyphicon glyph="remove" />
                    </Button>
                ) : null}
                <span>
                    {merchantLimit.merchant}: <strong>{merchantLimit.maximumNumberOfConsignments}</strong>
                </span>
            </div>
        )
    }

    renderMaxNumber(terminal, date, limit) {
        if (this.state.editLimit && terminal === this.state.terminal && date === this.state.date) {
            const currentValue = (() => {
                if (this.state.newLimit != null) {
                    return this.state.newLimit
                }
                if (limit != null) {
                    return limit.maximumNumberOfConsignments
                }
                return ''
            })()
            return (
                <div>
                    <form onSubmit={this.upsertNewLimit}>
                        <InputGroup>
                            <FormControl
                                autoFocus
                                value={currentValue}
                                onChange={(e) => this.setState({ newLimit: e.target.value })}
                            />
                            <InputGroup.Button>
                                <Button type="submit">
                                    <Glyphicon glyph="ok" />
                                </Button>
                            </InputGroup.Button>
                            <InputGroup.Button>
                                <Button onClick={this.resetNewLimit}>
                                    <Glyphicon glyph="remove" />
                                </Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </form>
                </div>
            )
        }

        return (
            <div className={cx('clearfix', { 'bg-danger': limit == null })}>
                {this.canEdit ? (
                    <Button
                        type="button"
                        bsStyle="link"
                        style={{ padding: '0 12px' }}
                        onClick={this.toggleEditLimit(terminal, date)}
                        className="pull-right"
                        title={`Edit limit in ${terminal} for ${date}`}
                    >
                        <Glyphicon glyph="edit" />
                    </Button>
                ) : null}
                <span>{limit != null ? <strong>{limit.maximumNumberOfConsignments}</strong> : '----'}</span>
            </div>
        )
    }

    renderRoutingDeadline = (terminal, routingDeadline, date) => {
        if (this.state.editRoutingDeadline && terminal === this.state.terminal && date === this.state.date) {
            const currentValue = (() => {
                if (this.state.newRoutingDeadline != null) {
                    return this.state.newRoutingDeadline
                }
                if (routingDeadline != null) {
                    return routingDeadline.deadline
                }
                return ''
            })()

            return (
                <div>
                    <form onSubmit={this.updateRoutingDeadline}>
                        <InputGroup>
                            <FormControl
                                autoFocus
                                value={currentValue}
                                onChange={(e) => this.setState({ newRoutingDeadline: e.target.value })}
                                style={{ borderColor: this.state.newRoutingDeadlineError ? 'red' : null }}
                            />
                            <InputGroup.Button>
                                <Button type="submit">
                                    <Glyphicon glyph="ok" />
                                </Button>
                            </InputGroup.Button>
                            <InputGroup.Button>
                                <Button onClick={this.resetNewRoutingDeadline}>
                                    <Glyphicon glyph="remove" />
                                </Button>
                            </InputGroup.Button>
                        </InputGroup>
                    </form>
                </div>
            )
        }

        const deadline = (routingDeadline && routingDeadline.deadline) || null

        if (!deadline) {
            return <div>n/a</div>
        }

        return (
            <div>
                {this.canEdit && (
                    <Button
                        type="button"
                        bsStyle="link"
                        style={{ padding: '0 12px' }}
                        onClick={this.toggleEditRoutingDeadline(terminal, date, deadline)}
                        className="pull-right"
                        title={`Change deadline for ${terminal}`}
                    >
                        <Glyphicon glyph="time" />
                    </Button>
                )}
                <span>
                    <strong>{deadline}</strong>
                </span>
            </div>
        )
    }

    renderTerminalLimit = (terminal, limits, routingDeadlines) => (date) => {
        const dateLimit = limits.find((limit) => limit.date === date)
        const merchantLimits =
            dateLimit != null && this.props.merchants.length > 0
                ? dateLimit.merchantConsignmentLimits
                      .map((ml) => {
                          const merchant = this.props.merchants.find((m) => m.id === ml.merchantId)
                          return {
                              merchantId: ml.merchantId,
                              merchant: merchant.externalName,
                              maximumNumberOfConsignments: ml.maximumNumberOfConsignments,
                          }
                      })
                      .sort(merchantLimitsSorter)
                : []
        const renderMerchantLimits = dateLimit != null && !dateLimit.blockDirectCheckoutMerchants
        const allBlocked = dateLimit != null && dateLimit.blockDirectCheckoutMerchants

        const activeConsignments = this.props.consignmentCounts.find(
            (cc) => cc.terminal === terminal && cc.date === date,
        )
        const currentCount = activeConsignments != null ? <strong>{activeConsignments.consignments}</strong> : '----'

        const routingDeadline =
            routingDeadlines && routingDeadlines.find((rd) => rd.terminal === terminal && rd.date === date)

        return (
            <td key={dateLimit != null ? dateLimit.id : date}>
                <div className="key">
                    <small>Booked consignments</small>
                </div>
                <div>{currentCount}</div>
                <div className="key">
                    <small>Terminal limit</small>
                </div>
                {this.renderMaxNumber(terminal, date, dateLimit)}
                <div className="key">
                    <small>Remaining until terminal limit</small>
                </div>
                {dateLimit && activeConsignments ? (
                    <strong>
                        {Math.max(0, dateLimit.maximumNumberOfConsignments - activeConsignments.consignments)}
                    </strong>
                ) : (
                    '----'
                )}
                <div className="key">
                    <small>Merchant limit</small>
                </div>
                <div>
                    {allBlocked ? this.renderAllBlocked(dateLimit) : null}
                    {renderMerchantLimits ? merchantLimits.map(this.renderMerchantLimit(dateLimit)) : null}
                    {this.canEdit && renderMerchantLimits ? (
                        <div>
                            <Button
                                type="button"
                                bsStyle="link"
                                bsSize="sm"
                                onClick={this.toggleAddMerchantLimit(dateLimit)}
                            >
                                <Glyphicon glyph="plus" /> Add
                            </Button>
                        </div>
                    ) : null}
                </div>
                <div className="key">
                    <small>Routing deadline</small>
                </div>
                {this.renderRoutingDeadline(terminal, routingDeadline, date)}
            </td>
        )
    }

    renderRow = (dates, limits, routingDeadlines) => (terminal) => {
        const terminalLimits = limits.filter((limit) => limit.terminal === terminal.code)
        return (
            <tr key={terminal.id}>
                <th>{terminal.name}</th>
                {dates.map(this.renderTerminalLimit(terminal.code, terminalLimits, routingDeadlines))}
            </tr>
        )
    }

    renderBody(terminals, uniqueDates, limits, routingDeadlines) {
        return (
            <tbody>{this.filterTerminals(terminals).map(this.renderRow(uniqueDates, limits, routingDeadlines))}</tbody>
        )
    }

    render() {
        const { loading, error, limits, terminals, routingDeadlines } = this.props
        const { showAddMerchantLimit, limit } = this.state

        if (error != null) {
            return <Alert type="danger" message={error} />
        }

        const sortedLimits = limits.sort(limitsSorter)
        const uniqueDates = sortedLimits.map(({ date }) => date).filter(unique)

        return (
            <div>
                <HtmlTitle title="Terminal Limits" />
                <Loader color="#bfbfbf" loaded={!loading}>
                    <Grid fluid>
                        <Table striped className="limits-table">
                            {this.renderTableHeader(uniqueDates)}
                            {this.renderBody(terminals, uniqueDates, limits, routingDeadlines)}
                        </Table>
                        {showAddMerchantLimit ? (
                            <AddMerchantLimit
                                limit={limit}
                                closeModal={() => this.setState({ showAddMerchantLimit: false, limit: null })}
                            />
                        ) : null}
                    </Grid>
                </Loader>
            </div>
        )
    }
}

TerminalLimits.propTypes = {
    router: PropTypes.object,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.string,
    limits: PropTypes.array.isRequired,
    consignmentCounts: PropTypes.array.isRequired,
    merchants: PropTypes.array.isRequired,
    terminals: PropTypes.array.isRequired,
    routingDeadlines: PropTypes.array,
}

const mapStateToProps = (state) => {
    const {
        limits: {
            loading: limitsLoading,
            error: limitsError,
            limits,
            consignmentCounts,
            routingDeadlines,
            loadingRoutingDeadlines,
            loadingRoutingDeadlinesError,
        },
        buyers: { loading: merchantsLoading, error: merchantsError, buyers: merchants },
        terminals: { loading: terminalsLoading, error: terminalsError, terminals },
    } = state
    return {
        loading: limitsLoading || merchantsLoading || terminalsLoading,
        error: limitsError || merchantsError || terminalsError,
        limits,
        consignmentCounts,
        merchants,
        terminals,
        routingDeadlines,
        loadingRoutingDeadlines,
        loadingRoutingDeadlinesError,
    }
}

export default connect(mapStateToProps)(TerminalLimits)
