import Api from './api'
import Webapi from './webapi'
import auth from '../auth'
import dev from './dev'

const baseUrl = '/api/routes'
const contentType = dev('application/json', 'application/vnd.budbee.routes-v1+json')
const V2 = 'application/vnd.budbee.routes-v2+json'

export default {
    get(routeId) {
        return new Api(baseUrl)
            .path(routeId)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .get()
    },

    getSplitReasons() {
        return new Api(baseUrl)
            .path('/split-reasons')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .get()
    },

    getIncompleteToSchedule(date) {
        return new Api(baseUrl)
            .path('incomplete')
            .path(date)
            .header('Accept', V2)
            .header('Authorization', auth.get())
            .get()
    },

    getTodaysIncompleteEcommerceRoutes(terminalId) {
        return new Api(baseUrl)
            .path('sorting')
            .path('e-commerce')
            .queryParam('terminal', terminalId)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getIncompleteDates() {
        return new Api(baseUrl)
            .path('incomplete')
            .path('dates')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getForMail() {
        return new Api(baseUrl)
            .path('for-mail')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    createUserRoute(userRoutes) {
        return new Api(baseUrl)
            .path('assign')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .post(userRoutes)
    },

    getSingleTraffix(id) {
        return new Api(baseUrl)
            .path(`traffix/${id}`)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    completeRoute(id) {
        return new Api(baseUrl)
            .path('done')
            .path(id)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .put()
    },

    getPositionsForRoute(routeId) {
        return new Api(baseUrl)
            .path(routeId)
            .path('positions')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    moveStops(routeId, originalRouteMeetupSequence, when, address, stopids, splitReason, responsibleParty) {
        const data = {
            fromRoute: routeId,
            originalRouteMeetupSequence,
            when,
            address,
            stopIdsToMove: stopids,
            splitReason,
            responsibleParty,
        }

        return new Api(baseUrl)
            .path('move-stops')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .post(data)
    },

    doSearch(queryData) {
        return new Api(baseUrl)
            .path('search')
            .header('Accept', V2)
            .header('Authorization', auth.get())
            .header('Content-type', V2)
            .post(queryData)
    },

    gameCalculateDriveTimes(nodes) {
        return new Api(baseUrl)
            .path('game')
            .path('calculate-drive-times')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .post(nodes)
    },

    gameUploadResults(results) {
        return new Api(baseUrl)
            .path('game')
            .path('enter-results')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .post(results)
    },

    gameGetToplist() {
        return new Api(baseUrl)
            .path('game')
            .path('toplist')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .get()
    },

    getRouteAnalysis(routeId) {
        return new Webapi()
            .authorization(auth.get())
            .path(`/routes/${routeId}/analysis`)
            .get()
    },
}
