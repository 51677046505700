export const DISTRIBUTION = 'DISTRIBUTION'
export const MERCHANT = 'MERCHANT'
export const LINEHAUL = 'LINEHAUL'
export const ON_DEMAND = 'ON_DEMAND_PICKUP'
export const LOCKER = 'LOCKER'

export default () => [
    { value: DISTRIBUTION, label: 'Distribution' },
    { value: MERCHANT, label: 'Merchant' },
    { value: LINEHAUL, label: 'Linehaul' },
    { value: ON_DEMAND, label: 'On demand' },
    { value: LOCKER, label: 'Locker' },
]
