import {
    HOLIDAY_CREATED,
    FETCHING_HOLIDAYS,
    HOLIDAYS_LOADED,
    HOLIDAYS_FAILED_TO_LOAD,
    HOLIDAY_REMOVED,
    HOLIDAY_CITIES_LOADED,
    HOLIDAY_CITIES_FAILED_TO_LOAD,
    HOLIDAY_UNLOCKED_FOR_MERCHANT,
    HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT,
    HOLIDAY_LOCKER_CITY_UNBLOCK_CHANGED,
} from '../constants/actionTypes'
import {
    getHolidays,
    createHoliday as newHoliday,
    editCities,
    deleteHoliday,
    unlockHolidayForMerchant,
    resetHolidayLockForMerchant,
    updateUnblockedLockerCities,
} from '../utils/holiday-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import { constant } from '../utils/constant'

export const fetchingHolidays = () => ({
    type: FETCHING_HOLIDAYS,
})

export const holidaysLoaded = (response) => ({
    type: HOLIDAYS_LOADED,
    status: 200,
    error: null,
    payload: response.payload,
})

export const holidayCitiesLoaded = (holiday, cities) => () => ({
    type: HOLIDAY_CITIES_LOADED,
    status: 200,
    error: null,
    holidayId: holiday.id,
    payload: cities,
})

export const holidayUnlockedForMerchant = (response) => ({
    type: HOLIDAY_UNLOCKED_FOR_MERCHANT,
    status: 200,
    error: null,
    payload: response.payload,
})

export const holidayLockerUnblockChanged = (holidayId) => (response) => ({
    status: 200,
    error: null,
    payload: response.payload,
    type: HOLIDAY_LOCKER_CITY_UNBLOCK_CHANGED,
    holidayId,
})

export const holidayUnlockedRemovedForMerchant = (response) => ({
    type: HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT,
    status: 200,
    error: null,
    payload: response.payload,
})

export const holidayCitiesFailedToLoad = (e) => ({
    type: HOLIDAY_CITIES_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

export const holidaysFailedToLoad = (e) => ({
    type: HOLIDAYS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

export const holidayCreated = (response, holiday) => ({
    type: HOLIDAY_CREATED,
    payload: { id: response.payload, ...holiday },
})

export const removedHoliday = (holiday) => ({
    type: HOLIDAY_REMOVED,
    holiday,
})

/**
    Fetch Holidays from backend
*/
export const fetchHolidays = () => (dispatch) => {
    dispatch(fetchingHolidays())
    getHolidays()
        .then(compose(dispatch, holidaysLoaded))
        .catch(compose(dispatch, holidaysFailedToLoad, handleError))
}

/**
    Create a new Holiday
*/
export const createHoliday = (holiday) => (dispatch) =>
    newHoliday(holiday)
        .then(compose(dispatch, holidayCreated))
        .then(compose(dispatch, fetchHolidays))
        .catch(handleError)

/**
    Set cities a Holiday is active in.
*/
export const setCities = (holiday, cities) => (dispatch) =>
    editCities(holiday.id, cities)
        .then(compose(dispatch, holidayCitiesLoaded(holiday, cities)))
        .catch(compose(dispatch, holidayCitiesFailedToLoad, handleError))

/**
    Unlock a Holiday for a Certain Merchant in a certain city.
*/
export const unlockHoliday = (holiday, merchantId, cityId) => (dispatch) =>
    unlockHolidayForMerchant(holiday.id, merchantId, cityId)
        .then(compose(dispatch, holidayUnlockedForMerchant))
        .catch(handleError)

/**
    Remove a Holiday Unlock for a Certain Merchant in a certain city.
*/
export const removeUnlock = (holiday, merchantId, cityId) => (dispatch) =>
    resetHolidayLockForMerchant(holiday.id, merchantId, cityId)
        .then(compose(dispatch, holidayUnlockedRemovedForMerchant))
        .catch(handleError)

/**
    Remove a Holiday
*/
export const removeHoliday = (holiday) => (dispatch) =>
    deleteHoliday(holiday.id)
        .then(compose(dispatch, removedHoliday, constant(holiday)))
        .catch(handleError)

export const changeLockerHolidayUnblockCities = (holidayId, addedCities, removedCities) => (dispatch) => {
    updateUnblockedLockerCities(holidayId, addedCities, removedCities)
        .then(compose(dispatch, holidayLockerUnblockChanged(holidayId)))
        .catch(handleError)
}
