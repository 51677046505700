import moment from 'moment'
import 'moment-duration-format'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const SE = 'SE'
export const DK = 'DK'
export const FI = 'FI'
export const NL = 'NL'
export const BE = 'BE'
export const DE = 'DE'
export const NO = 'NO'

export const SWEDEN_TIME_ZONE = 'Europe/Stockholm'
export const DENMARK_TIME_ZONE = 'Europe/Copenhagen'
export const FINLAND_TIME_ZONE = 'Europe/Helsinki'
export const NETHERLANDS_TIME_ZONE = 'Europe/Amsterdam'
export const BELGIUM_TIME_ZONE = 'Europe/Brussels'
export const GERMANY_TIME_ZONE = 'Europe/Berlin'
export const NORWAY_TIME_ZONE = 'Europe/Oslo'

// Maps the country code to the right time zone
export const mapCountryCodeToTimeZone = (countryCode) => {
    let timezone
    switch (countryCode) {
        case SE:
            timezone = SWEDEN_TIME_ZONE
            break
        case FI:
            timezone = FINLAND_TIME_ZONE
            break
        case DK:
            timezone = DENMARK_TIME_ZONE
            break
        case NL:
            timezone = NETHERLANDS_TIME_ZONE
            break
        case BE:
            timezone = BELGIUM_TIME_ZONE
            break
        case DE:
            timezone = GERMANY_TIME_ZONE
            break
        case NO:
            timezone = NORWAY_TIME_ZONE
            break
        default:
            return new Error('Country is not supported')
    }

    return timezone
}
export const prettyTime = (date) => {
    if (moment(date).isSame(moment(), 'day')) {
        return moment(date).format('HH:mm')
    }
    return moment(date).format('YYYY-MM-DD HH:mm')
}

export const formatDiff = (diff) => {
    let format = 's [sec]'
    if (diff >= 60) format = 'd [d] h [h] m [min]'

    return moment.duration(diff, 'seconds').format(format)
}

export const formatDate = (str) =>
    moment(str)
        .subtract(1, 'months')
        .format(DATE_FORMAT)
export const formatTime = (str) => moment(str, 'HH:mm:ss').format('HH:mm')

// Returns current weekday if today, or next week's weekday if dayOfWeek already occurred this week
export const getClosestInstanceOfWeekDay = (dayOfWeek) => {
    if (!dayOfWeek) {
        return null
    }
    const todayWeekday = moment().weekday()
    const nextInstanceOfWeekday = moment()
        .isoWeekday(dayOfWeek)
        .weekday()

    return todayWeekday <= nextInstanceOfWeekday
        ? moment().isoWeekday(dayOfWeek)
        : moment()
              .add(1, 'weeks')
              .isoWeekday(dayOfWeek)
}

export const formatDateBasedOnCountry = (date, countryCode = 'SE') =>
    moment(date)
        .tz(mapCountryCodeToTimeZone(countryCode))
        .format(DATE_FORMAT)
