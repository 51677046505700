import { DOCKING_DATA_LOADED } from '../constants/actionTypes'
import dataState from './initialState/dataState'

export default function DockingReducer(state = dataState, action) {
    switch (action.type) {
        case DOCKING_DATA_LOADED:
            return {
                ...state,
                docks: action.docks,
                routes: action.routes,
            }

        default:
            return state
    }
}
