import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { Alert, Grid, Row, Col, Table, DropdownButton, MenuItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import HolidayModal from './holiday-modal'
import { fetchOwnersWebapi } from '../../actions/owner'
import * as actions from '../../actions/recurring-pickups'
import {
    canFetchCouriers,
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    MERCHANT_ADMIN,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'
import { isAfter } from '../../utils/times'
import { getRegionalSettings } from '../../utils/settings-api'
import { handleError } from '../../utils/handle-error'
import FilterHeader from './components/filter-header'
import HtmlTitle from '../html-title'
import { formatTime } from './format-time'
import auth from '../../auth'
import { STOP_TYPE } from '../../utils/recurring-pickups'
import '../../../styles/less/list-handler.less'

class RecurringPickupsListHandler extends Component {
    constructor(...args) {
        super(...args)
        this.canEditSchedules = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        this.state = {
            modalOpen: false,
            selectedCourier: null,
            selectedMerchants: [],
            sortedRecurringPickups: [],
            regionalSettings: {},
            selectedCountries: [],
            selectedRouteType: null,
            regionalSettingsLoaded: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
            MERCHANT_ADMIN,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        const { routeType } = this.props.location.query
        this.setState({ selectedRouteType: routeType })

        if (canFetchCouriers()) {
            this.props.courierActions.fetchOwners()
        }

        getRegionalSettings()
            .then((res) => {
                this.setState({
                    regionalSettings: res,
                    regionalSettingsLoaded: true,
                })
            })
            .catch(handleError)
    }

    componentWillReceiveProps(nextProps) {
        const filteredPickups = this.applyFilters(nextProps.recurringPickups)
        const groupedPickups = this.groupSimilarPickups(filteredPickups)
        const sortedRecurringPickups = this.sortPickups(groupedPickups)

        this.setState({ sortedRecurringPickups })
    }

    fetchRecurringPickupsByRouteType() {
        const routeType = this.state.selectedRouteType

        this.props.router.replace({
            pathname: '/admin/recurring-routes',
            search: routeType ? `?routeType=${routeType}` : '',
        })
        this.props.actions.fetchRecurringPickups(routeType)
    }

    onDelete = ({ id, name }) => () => {
        if (!window.confirm(`Are you sure you want to delete the '${name}' route?`)) {
            return
        }

        this.props.actions.removeRoute(id)
    }

    onCourierChange = (selectedCourier) => this.setState({ selectedCourier })

    onTerminalChange = (selectedTerminal) => this.setState({ selectedTerminal })

    onMerchantChange = (selectedMerchants) => this.setState({ selectedMerchants })

    onCountryChange = (selectedCountries) => this.setState({ selectedCountries })

    search = () => this.fetchRecurringPickupsByRouteType()

    onRouteTypeChange = (selectedRouteType) =>
        this.setState({ selectedRouteType: selectedRouteType !== null ? selectedRouteType.value : selectedRouteType })

    filterByCourier = (recurringPickup) =>
        this.state.selectedCourier ? recurringPickup.courierName === this.state.selectedCourier.label : true

    filterByTerminal = (recurringPickup) =>
        this.state.selectedTerminal
            ? recurringPickup.stops.some(
                  (stop) => stop.type === STOP_TYPE.TERMINAL && stop.terminalId === this.state.selectedTerminal.id,
              )
            : true

    filterByMerchant(recurringPickup) {
        return (
            this.state.selectedMerchants.length === 0 ||
            this.state.selectedMerchants.find((selectedMerchant) =>
                recurringPickup.stops.find(
                    (stop) => stop.type === STOP_TYPE.MERCHANT && stop.merchantName === selectedMerchant.label,
                ),
            )
        )
    }

    filterByCountry(recurringPickup) {
        if (this.state.selectedCountries.length > 0) {
            const stopCountries = new Set(recurringPickup.stops.map((stop) => stop.country))
            return this.state.selectedCountries.some((country) => stopCountries.has(country.value))
        }
        return true
    }

    includeCouriersWithRecurringPickups = (recurringPickups, courier) =>
        recurringPickups.find((recurringPickup) => recurringPickup.courierName === courier.name)

    addSimilarPickups = (recurringPickup, recurringPickups) => {
        const similarPickups = recurringPickups.filter(
            (x) =>
                x.startTime === recurringPickup.startTime &&
                x.endTime === recurringPickup.endTime &&
                x.name === recurringPickup.name &&
                x.courierName === recurringPickup.courierName &&
                x.requiredPallets === recurringPickup.requiredPallets &&
                x.remuneration === recurringPickup.remuneration &&
                x.maxPallets === recurringPickup.maxPallets &&
                x.stops.length === recurringPickup.stops.length &&
                x.stops.every((a, i) => {
                    const b = recurringPickup.stops[i]
                    return (
                        (a.startTime === b.startTime && a.endTime === b.endTime && a.terminalId === b.terminalId) ||
                        a.collectionPointMerchantScheduleId === b.collectionPointMerchantScheduleId
                    )
                }),
        )
        const daysOfWeek = similarPickups.map((y) => y.dayOfWeek)
        return similarPickups.map((x) => ({ ...x, daysOfWeek, key: recurringPickup.key }))
    }

    groupSimilarPickups = (recurringPickups) =>
        recurringPickups.reduce((acc, curr) => {
            const pickup = { ...curr }
            const existingPickup = acc.find(
                (x) =>
                    x.startTime === pickup.startTime &&
                    x.endTime === pickup.endTime &&
                    x.name === pickup.name &&
                    x.courierName === pickup.courierName &&
                    x.requiredPallets === pickup.requiredPallets &&
                    x.maxPallets === pickup.maxPallets &&
                    x.remuneration === pickup.remuneration &&
                    x.stops.length === pickup.stops.length &&
                    x.stops.every((a, i) => {
                        const b = pickup.stops[i]
                        return (
                            (a.startTime === b.startTime && a.endTime === b.endTime && a.terminalId === b.terminalId) ||
                            a.collectionPointMerchantScheduleId === b.collectionPointMerchantScheduleId
                        )
                    }) &&
                    Boolean(x.instaboxId) === Boolean(pickup.instaboxId),
            )

            if (existingPickup) {
                existingPickup.daysOfWeek.push(pickup.dayOfWeek)
                existingPickup.key.push([pickup.id, pickup.dayOfWeek])
                existingPickup.actionable = false
                existingPickup.clickable = true
            } else {
                pickup.daysOfWeek = [pickup.dayOfWeek]
                pickup.key = [[pickup.id, pickup.dayOfWeek]]
                pickup.actionable = true
                acc.push(pickup)
            }
            return acc
        }, [])

    sortPickups = (recurringPickups) => {
        const todayDayOfWeek = moment().weekday()

        const daysBeforeTodayWeekday = recurringPickups
            .filter((recurringPickup) =>
                recurringPickup.daysOfWeek.every(
                    (dayOfWeek) =>
                        moment()
                            .isoWeekday(dayOfWeek)
                            .weekday() < todayDayOfWeek,
                ),
            )
            .sort((a, b) => {
                const pickupDayOfWeek1 = moment()
                    .isoWeekday(a.dayOfWeek)
                    .weekday()
                const pickupDayOfWeek2 = moment()
                    .isoWeekday(b.dayOfWeek)
                    .weekday()

                if (pickupDayOfWeek1 === pickupDayOfWeek2) {
                    return isAfter(a.stops[0].startTime, b.stops[0].startTime) ? 1 : -1
                }

                return pickupDayOfWeek1 < pickupDayOfWeek2 ? -1 : 1
            })
        const daysAfterAndIncludingTodayWeekday = recurringPickups
            .filter((recurringPickup) =>
                recurringPickup.daysOfWeek.some(
                    (dayOfWeek) =>
                        moment()
                            .isoWeekday(dayOfWeek)
                            .weekday() >= todayDayOfWeek,
                ),
            )
            .sort((a, b) => {
                const pickupDayOfWeek1 = moment()
                    .isoWeekday(a.dayOfWeek)
                    .weekday()
                const pickupDayOfWeek2 = moment()
                    .isoWeekday(b.dayOfWeek)
                    .weekday()

                if (pickupDayOfWeek1 === pickupDayOfWeek2) {
                    return isAfter(a.stops[0].startTime, b.stops[0].startTime) ? 1 : -1
                }

                return pickupDayOfWeek1 < pickupDayOfWeek2 ? -1 : 1
            })

        return daysAfterAndIncludingTodayWeekday.concat(daysBeforeTodayWeekday)
    }

    toggleUnfurl(pickup) {
        const { sortedRecurringPickups } = this.state

        const similarPickups = this.addSimilarPickups(pickup, this.props.recurringPickups).map((x) => ({
            ...x,
            clickable: true,
            ungrouped: true,
        }))

        const pickupIndex = sortedRecurringPickups.findIndex((x) => x.id === similarPickups[0].id)
        const shouldUnfurl =
            similarPickups.length > 1 && !similarPickups.every((x) => sortedRecurringPickups.find((y) => y.id === x.id))
        const shouldFurl =
            similarPickups.length > 1 && similarPickups.every((x) => sortedRecurringPickups.find((y) => y.id === x.id))

        if (shouldUnfurl && pickupIndex >= 0) {
            sortedRecurringPickups.splice(pickupIndex, 1, ...similarPickups.map((x) => ({ ...x, actionable: true })))
        } else if (shouldFurl && pickupIndex >= 0) {
            sortedRecurringPickups.splice(pickupIndex, similarPickups.length, {
                ...similarPickups[0],
                actionable: false,
                clickable: true,
                ungrouped: false,
            })
        }
        this.setState({ sortedRecurringPickups })
    }

    renderRow(pickup) {
        const firstStop = pickup.stops[0]
        const lastStop = pickup.stops[pickup.stops.length - 1]
        const time = `${formatTime(firstStop.startTime)}-${formatTime(lastStop.endTime)}`
        const stopNames = pickup.stops.map((stop) => {
            switch (stop.type) {
                case STOP_TYPE.MERCHANT:
                    return stop.merchantName.startsWith('Budbee Box') ? stop.collectionPointName : stop.merchantName
                case STOP_TYPE.TERMINAL:
                    return stop.terminalName
                default:
                    return stop.type
            }
        })

        return (
            <tr
                key={pickup.id}
                className={`${pickup.ungrouped ? 'ungroupedStyle' : null} ${pickup.clickable ? 'clickable' : null}`}
                onClick={() => this.toggleUnfurl(pickup)}
            >
                {pickup.actionable && <td>{pickup.dayOfWeek.substring(0, 2)}</td>}
                {!pickup.actionable && (
                    <td>
                        {pickup.daysOfWeek.reduce(
                            (acc, curr) => `${acc !== '' ? `${acc}, ${curr.substring(0, 2)}` : curr.substring(0, 2)}`,
                            '',
                        )}{' '}
                    </td>
                )}
                <td>{time}</td>
                <td>{pickup.name}</td>
                <td>{stopNames.join(' → ')}</td>
                <td>
                    {pickup.courierName} {pickup.driverId == null ? null : `(${pickup.driverName})`}
                </td>
                <td>
                    {pickup.requiredPallets} / {pickup.maxPallets}
                </td>
                <td>
                    {pickup.remuneration} {pickup.currency}
                </td>
                {this.renderDropdown(pickup)}
            </tr>
        )
    }

    renderDropdown(pickup) {
        const isReadOnly = Boolean(pickup.instaboxId)
        return (
            <td>
                {!pickup.actionable && pickup.daysOfWeek.length > 1 ? (
                    <DropdownButton
                        pullRight
                        title="Actions"
                        id="merchant-routes-dropdown"
                        onClick={(e) => e.stopPropagation()}
                        disabled={isReadOnly}
                    >
                        <LinkContainer
                            to={`/admin/recurring-routes/${pickup.id}/edit`}
                            query={{ cps: [pickup.key] }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MenuItem>Edit Group</MenuItem>
                        </LinkContainer>
                        <LinkContainer
                            to={`/admin/recurring-routes/${pickup.id}/copy`}
                            query={{ cps: [pickup.key] }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <MenuItem>Copy Group</MenuItem>
                        </LinkContainer>
                    </DropdownButton>
                ) : null}
                {pickup.actionable && (
                    <DropdownButton
                        pullRight
                        title="Actions"
                        id="merchant-routes-dropdown"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <LinkContainer to={`/admin/recurring-routes/${pickup.id}`} onClick={(e) => e.stopPropagation()}>
                            <MenuItem>View</MenuItem>
                        </LinkContainer>
                        {this.canEditSchedules ? (
                            <LinkContainer
                                to={`/admin/recurring-routes/${pickup.id}/edit`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <MenuItem>Edit</MenuItem>
                            </LinkContainer>
                        ) : null}
                        {this.canEditSchedules ? (
                            <LinkContainer
                                disabled={isReadOnly}
                                to={`/admin/recurring-routes/${pickup.id}/one-time-route`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (isReadOnly) {
                                        e.preventDefault()
                                    }
                                }}
                            >
                                <MenuItem disabled={isReadOnly}>One-time route</MenuItem>
                            </LinkContainer>
                        ) : null}
                        {this.canEditSchedules ? (
                            <LinkContainer
                                disabled={isReadOnly}
                                to={`/admin/recurring-routes/${pickup.id}/copy`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (isReadOnly) {
                                        e.preventDefault()
                                    }
                                }}
                            >
                                <MenuItem disabled={isReadOnly}>Copy route</MenuItem>
                            </LinkContainer>
                        ) : null}
                    </DropdownButton>
                )}
            </td>
        )
    }

    applyFilters(recurringPickups) {
        const recurringPickupsWithStops = recurringPickups.filter((x) => x.stops.length > 0)
        return recurringPickupsWithStops.filter(
            (recurringPickup) =>
                this.filterByCourier(recurringPickup) &&
                this.filterByTerminal(recurringPickup) &&
                this.filterByCountry(recurringPickup) &&
                this.filterByMerchant(recurringPickup),
        )
    }

    render() {
        const couriers = this.props.couriers.filter((courier) =>
            this.includeCouriersWithRecurringPickups(this.props.recurringPickups, courier),
        )

        const merchants = this.props.recurringPickups
            .flatMap((recurringPickup) => recurringPickup.stops)
            .filter((stop) => stop.type === STOP_TYPE.MERCHANT)
            .map((stop) => stop.merchantName)
            .filter((merchant, i, entries) => entries.indexOf(merchant) === i)

        const { countries } = this.state.regionalSettings

        const filteredPickups = this.applyFilters(this.state.sortedRecurringPickups)
        const isListingOrderadminRoutes = filteredPickups.some((pickup) => pickup.instaboxId)

        if (!this.state.regionalSettingsLoaded) {
            return null
        }

        return (
            <div>
                <HtmlTitle title="Recurring pickups" />
                <Loader color="#bfbfbf" loaded={this.props.loaded}>
                    <Grid fluid>
                        <Row>
                            <Col md={8} mdOffset={1}>
                                <FilterHeader
                                    couriers={couriers}
                                    merchants={merchants}
                                    countries={countries}
                                    onCourierChange={this.onCourierChange}
                                    onTerminalChange={this.onTerminalChange}
                                    onMerchantChange={this.onMerchantChange}
                                    onCountryChange={this.onCountryChange}
                                    onRouteTypeChange={this.onRouteTypeChange}
                                    selectedTerminal={this.state.selectedTerminal}
                                    selectedMerchants={this.state.selectedMerchants}
                                    selectedCountries={this.state.selectedCountries}
                                    selectedRouteType={this.state.selectedRouteType}
                                    selected={this.state.selectedCourier}
                                    loaded={this.props.loaded}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: 25, paddingRight: 45 }}>
                                <Button onClick={this.search} bsStyle="success">
                                    Search
                                </Button>
                                {this.canEditSchedules ? (
                                    <Col className="pull-right">
                                        <Link to="/admin/recurring-routes/create" className="btn btn-primary">
                                            Create
                                        </Link>
                                    </Col>
                                ) : null}
                                <Col className="pull-right" style={{ paddingRight: 15 }}>
                                    <Button bsStyle="info" onClick={() => this.setState({ modalOpen: true })}>
                                        Holidays
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Col md={12}>
                                    <Col md={10} mdOffset={1}>
                                        {isListingOrderadminRoutes && (
                                            <Alert className="list-handler-alert" bsStyle="info">
                                                <span>
                                                    Some of the listed recurring routes are managed through Orderadmin
                                                    and can only be viewed or deleted.
                                                </span>
                                            </Alert>
                                        )}
                                        <Table striped className="recurring-pickups-table">
                                            <thead>
                                                <tr>
                                                    <th>Day</th>
                                                    <th>Time</th>
                                                    <th>Name</th>
                                                    <th>Terminals/merchants</th>
                                                    <th>Courier</th>
                                                    <th>Capacity</th>
                                                    <th>Remuneration</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>{filteredPickups.map(this.renderRow, this)}</tbody>
                                        </Table>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Grid>

                    <HolidayModal show={this.state.modalOpen} onHide={() => this.setState({ modalOpen: false })} />
                </Loader>
            </div>
        )
    }
}
RecurringPickupsListHandler.propTypes = {
    router: PropTypes.object,
    courierActions: PropTypes.object.isRequired,
    actions: PropTypes.object,
    loaded: PropTypes.bool,
    recurringPickups: PropTypes.array,
    couriers: PropTypes.array,
    location: PropTypes.object,
    query: PropTypes.array,
    routeType: PropTypes.string,
}

const mapStateToProps = (state) => ({
    loaded: state.recurringPickups.loaded,
    recurringPickups: state.recurringPickups.data,
    couriers: state.owners.data,
})

const mapDispatchToProps = (dispatch) => ({
    courierActions: {
        fetchOwners: () => fetchOwnersWebapi()(dispatch),
    },
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPickupsListHandler)

RecurringPickupsListHandler.propTypes = {
    router: PropTypes.object,
    courierActions: PropTypes.object.isRequired,
    actions: PropTypes.object,
    loaded: PropTypes.bool,
    recurringPickups: PropTypes.array,
}
