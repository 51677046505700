import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Navbar, Nav, NavItem } from 'react-bootstrap'
import auth from '../../auth'
import MissingCoordinates from './missing-coordinates'
import MissingCoordinatesBeta from './missing-coordinates-beta'
import BrowseGeocoder, { BrowseGeocoderDefaultState } from './browse-geocoder'
import GeocodeNewAddress from './geocode-new-address'
import HtmlTitle from '../html-title'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../utils/role'

const Views = {
    MissingCoordinates: 'MissingCoordinates',
    BrowseGeocoder: 'BrowseGeocoder',
    GeocodeNewAddress: 'GeocodeNewAddress',
    Beta: 'MissingCoordinatesBeta',
}
export default class Frame extends Component {
    constructor(props) {
        super(props)

        this.state = {
            view: Views.Beta,
            browseGeocoderState: BrowseGeocoderDefaultState,
        }
    }

    componentWillMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)
        if (!hasAuth) {
            this.props.router.push('/admin')
        }
    }

    onUpdateBrowserGeocoderState(state) {
        this.setState({ browseGeocoderState: state })
    }

    itemCss(view) {
        if (this.state.view === view) {
            return 'active'
        }
        return ''
    }

    changeView(view) {
        this.setState({ view })
    }

    renderNavItem(text, view, eventKey) {
        return (
            <NavItem eventKey={eventKey} href="#" className={this.itemCss(view)} onClick={() => this.changeView(view)}>
                {text}
            </NavItem>
        )
    }

    renderNav() {
        return (
            <Navbar fluid className="geocoder-navbar">
                <Nav>
                    {this.renderNavItem('Missing Coordinates', Views.MissingCoordinates, 1)}
                    {this.renderNavItem('Browse Geocoder', Views.BrowseGeocoder, 2)}
                    {this.renderNavItem('Geocode New Address', Views.GeocodeNewAddress, 3)}
                    {this.renderNavItem('Missing Coordinates (Beta)', Views.Beta, 4)}
                </Nav>
            </Navbar>
        )
    }

    renderContent(view) {
        if (this.state.view === Views.MissingCoordinates) {
            return <MissingCoordinates />
        }

        if (this.state.view === Views.Beta) {
            return <MissingCoordinatesBeta />
        }

        if (view === Views.GeocodeNewAddress) {
            return <GeocodeNewAddress />
        }

        return (
            <BrowseGeocoder
                setState={(state) => this.onUpdateBrowserGeocoderState(state)}
                state={this.state.browseGeocoderState}
            />
        )
    }

    render() {
        const { view } = this.state
        return (
            <div>
                <HtmlTitle title="Geocoding" />
                {this.renderNav()}
                {this.renderContent(view)}
            </div>
        )
    }
}

Frame.propTypes = {
    router: PropTypes.object,
}
