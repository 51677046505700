import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import ResidenceInline from './admin-residence-inline'

const AdminResidences = (props) => {
    const { residences } = props
    return (
        <Table striped>
            <thead>
                <tr>
                    <th>Street</th>
                    <th>Postal Code</th>
                    <th>City</th>
                    <th>Country</th>
                    <th>House Type</th>
                    <th>Door Code</th>
                    <th>Floor</th>
                    <th>Special Instructions</th>
                    <th>C/O</th>
                    <th>Delivery Preferences</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {residences
                    ? residences.map((residence) => <ResidenceInline key={residence.id} residence={residence} />)
                    : null}
            </tbody>
        </Table>
    )
}

AdminResidences.propTypes = {
    residences: PropTypes.array,
}

export default AdminResidences
