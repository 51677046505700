import React, { Component } from 'react'
import { Row, Button, Modal, Col, Alert } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { defaultCompensationModels } from '../../../utils/couriers'
import * as actions from '../../../actions/couriers'
import { DATE_FORMAT } from '../../../utils/date'

class DefaultsList extends Component {
    createCompensationModelValidFrom = () => {
        const tomorrow = moment.utc().add(1, 'days')
        return tomorrow
    }

    componentDidMount() {
        const validFrom = this.createCompensationModelValidFrom()
        this.setState(() => ({
            validFrom,
        }))
    }

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            validFrom: null,
            validTo: null,
        }
    }

    setShow = (show) => {
        this.setState({ show })
    }

    fetchCompensationTiersWithDelay = () =>
        setTimeout(() => {
            const today = moment
                .utc()
                .subtract(60, 'days')
                .format(DATE_FORMAT)
            this.props.actions.fetchCompensationTiers(this.props.courierId, today).then(this.setShow(false))
        }, 1000)

    handleSubmitDefaults = (tiers) => {
        const endDateForTiers = this.state.validFrom
            .clone()
            .subtract(1, 'days')
            .format(DATE_FORMAT)
        const tiersWithDates = tiers.map((tier) => {
            const tierCopy = cloneDeep(tier)
            tierCopy.validFrom = this.state.validFrom.format(DATE_FORMAT)
            tierCopy.validTo = this.state.validTo ? this.state.validTo.format(DATE_FORMAT) : undefined
            return tierCopy
        })
        const routeTypeToEnd = 'DISTRIBUTION'
        this.props.actions
            .updateEndCourierCompensationTiers(this.props.courierId, endDateForTiers, routeTypeToEnd)
            .then(() => {
                this.props.actions.createCompensationTiers(this.props.courierId, tiersWithDates).then(() => {
                    this.fetchCompensationTiersWithDelay()
                })
            })
    }

    renderDefaults = (key) => (
        <Row key={key} style={{ marginTop: '15px' }}>
            <Col md={10} key={key}>
                <p style={{ fontWeight: 700, margin: 0 }}>{defaultCompensationModels[key].title}</p>
                <p style={{ margin: 0 }}>{defaultCompensationModels[key].description}</p>
                <Button
                    bsStyle="warning"
                    style={{ marginTop: '5px' }}
                    onClick={() => this.handleSubmitDefaults(defaultCompensationModels[key].tiers)}
                >
                    Apply
                </Button>
                <br />
            </Col>
        </Row>
    )

    renderStartPicker = () => (
        <DatePicker
            id="validFrom"
            dateFormat={DATE_FORMAT}
            selected={this.state.validFrom}
            value={this.state.validFrom}
            placeholder="Select Start Date"
            onChange={(date) => {
                this.setState({ validFrom: date })
            }}
        />
    )

    renderEndPicker = () => (
        <DatePicker
            id="validTo"
            dateFormat={DATE_FORMAT}
            selected={this.state.validTo}
            value={this.state.validTo}
            placeholder="Select End Date"
            onChange={(date) => {
                this.setState({ validTo: date })
            }}
        />
    )

    render() {
        return (
            <Row>
                <Button onClick={() => this.setShow(!this.state.show)} bsStyle="warning">
                    Apply Default Compensation Model
                </Button>
                <Modal bsSize="lg" show={this.state.show} onHide={() => this.setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-capitalize">
                            <h3 style={{ margin: 0 }}>Default Compensation models</h3>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Valid from and valid to are both inclusive, tiers will be valid on both start- and enddate.
                        <Row>
                            <Col xs={12} md={12}>
                                <p style={{ marginTop: '10px', fontWeight: 'bold' }}>Valid from:</p>
                            </Col>
                            <Col xs={12} md={12}>
                                {this.renderStartPicker()}
                            </Col>
                            <Col xs={12} md={12}>
                                <p style={{ marginTop: '10px', fontWeight: 'bold' }}>Valid to:</p>
                            </Col>
                            <Col xs={12} md={12}>
                                {this.renderEndPicker()}
                            </Col>
                        </Row>
                        {Object.keys(defaultCompensationModels).map((key) => this.renderDefaults(key))}
                        <br />
                        <Col>
                            <Alert bsStyle="warning">Applying default will change all current tiers enddate.</Alert>
                        </Col>
                    </Modal.Body>
                    <br />
                </Modal>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    courier: state.couriers.courier,
    courierId: state.couriers.courierId,
    error: state.couriers.error,
    updating: state.couriers.updatingCompensationTier,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultsList)

DefaultsList.propTypes = {
    courierId: PropTypes.any.isRequired,
    actions: PropTypes.object,
}
