import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Label } from 'react-bootstrap'
import { getDeliveredStatusVariant, isParcelStatus } from '../../../utils/parcel-util'

/**
 * Render Child Parcels in a Table
 */
export default class ChildParcels extends Component {
    constructor(props) {
        super(props)
        this.state = {
            childParcels: [],
        }
    }

    componentDidMount() {
        if (this.props.childParcels) {
            this.setState({ childParcels: this.props.childParcels })
        }
    }

    renderParcel = (parcel, i) => {
        const title = getDeliveredStatusVariant(parcel)
        return (
            <tr key={i}>
                <td>{parcel.packageNumber}</td>
                <td>
                    <a href={`/admin/orders/${parcel.orderToken}`}>{parcel.orderToken}</a>
                </td>
                <td>
                    {isParcelStatus(parcel.status) ? (
                        <Label bsStyle="primary">{title}</Label>
                    ) : (
                        <Label bsStyle="warning">{title}</Label>
                    )}
                </td>
            </tr>
        )
    }

    render() {
        const { childParcels } = this.state

        if (childParcels == null) {
            return null
        }

        return (
            <Table responsive condensed hover>
                <thead>
                    <tr>
                        <th>Package Number</th>
                        <th>Order</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>{childParcels.map(this.renderParcel)}</tbody>
            </Table>
        )
    }
}

ChildParcels.propTypes = {
    childParcels: PropTypes.array,
}
