export const DEFAULT_BILLING_PERIOD = 'WEEKLY'
export const DEFAULT_RETENTION_PERIOD = 'FOREVER'
export const DEFAULT_INVOICE_DUE_DAYS = 10
export const MIN_INVOICE_DUE_DAYS = 0
export const MAX_INVOICE_DUE_DAYS = 60
export const DEFAULT_CURRENCY_CONVERSION_FEE_IN_PERCENT = 0
export const MIN_CURRENCY_CONVERSION_FEE_IN_PERCENT = 0
export const MAX_CURRENCY_CONVERSION_FEE_IN_PERCENT = 10
export const MIN_DEFAULT_MISS_RETRIES = 0
export const DEFAULT_INVOICE_LANGUAGE = 'EN'
export const DEFAULT_INVOICE_DELIVERY_TYPE = 'E-MAIL'

// https://developer.fortnox.se/documentation/resources/customers/
export const CUSTOM_INVOICE_ID_MAX_LENGTH = 1024
export const CUSTOM_INVOICE_ID_FORMAT = /^[a-zA-Z0-9 \-+_/]*$/
