import { HUB_PALLETS_LOAD_STATUS, HUB_PALLETS_SAVE_STATUS } from './constants'

const getSettingsFromResponse = (hubPalletsResponse) =>
    hubPalletsResponse.reduce((settings, { terminals }) => {
        const hubSettings = terminals.map(({ id, enablementType, enabled }) => ({ id, enablementType, enabled }))
        return [...settings, ...hubSettings]
    }, [])

export const updateSettings = (settings, id, enablementType) =>
    settings.map((setting) =>
        setting.id === id && setting.enablementType === enablementType
            ? { ...setting, enabled: !setting.enabled }
            : setting,
    )

export const createPayload = (settings) => settings.map(({ id: terminalId, ...rest }) => ({ terminalId, ...rest }))

export const getDefaultState = () => ({
    hubPalletsLoadStatus: HUB_PALLETS_LOAD_STATUS.NOT_LOADED,
    hubPalletsSaveStatus: HUB_PALLETS_SAVE_STATUS.UNCHANGED,
    hubPalletsResponse: [],
    selectedTerminal: null,
    settings: [],
})

export const getStateUpdatesFromResponse = (hubPalletsResponse) => ({
    hubPalletsLoadStatus: HUB_PALLETS_LOAD_STATUS.LOADED,
    hubPalletsResponse,
    settings: getSettingsFromResponse(hubPalletsResponse),
})

export const isEnabled = (settings, id, enablementType) =>
    settings.some((setting) => setting.id === id && setting.enablementType === enablementType && setting.enabled)

export const getSelectionDetails = (terminals) => terminals.map(({ name }) => name).join(', ')
