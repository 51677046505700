import React, { Component } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import Loader from 'react-loader'
import PropTypes from 'prop-types'
import PostalCodeZones from '../../../postal-code-zones/postal-code-zones'
import mapType from '../../../../utils/map-price-point-type'
import currencies from '../../../../utils/currencies'
import CountrySelect from '../../../common/country-select'

const currencyOptions = currencies()

const pricePointTypes = [
    {
        label: mapType.toText('PRICE_PER_STOP'),
        value: 'PRICE_PER_STOP',
    },
    {
        label: mapType.toText('PRICE_PER_MISS'),
        value: 'PRICE_PER_MISS',
    },
    {
        label: mapType.toText('PRICE_PER_HOUR'),
        value: 'PRICE_PER_HOUR',
    },
    {
        label: mapType.toText('PRICE_PER_CANCELLATION_PRE_CUTOFF'),
        value: 'PRICE_PER_CANCELLATION_PRE_CUTOFF',
    },
    {
        label: mapType.toText('PRICE_PER_CANCELLATION_POST_CUTOFF'),
        value: 'PRICE_PER_CANCELLATION_POST_CUTOFF',
    },
    {
        label: mapType.toText('START_DEBIT_PER_DAY'),
        value: 'START_DEBIT_PER_DAY',
    },
    {
        label: mapType.toText('START_DEBIT_PER_ROUTE'),
        value: 'START_DEBIT_PER_ROUTE',
    },
    {
        label: mapType.toText('MINIMUM_DEBIT_PER_DAY'),
        value: 'MINIMUM_DEBIT_PER_DAY',
    },
    {
        label: mapType.toText('MINIMUM_DEBIT_PER_ROUTE'),
        value: 'MINIMUM_DEBIT_PER_ROUTE',
    },
    {
        label: mapType.toText('PICK_UP_ROUTE'),
        value: 'PICK_UP_ROUTE',
    },
    {
        label: mapType.toText('RATING_INCENTIVE'),
        value: 'RATING_INCENTIVE',
    },
    {
        label: mapType.toText('PRICE_PER_RECALL'),
        value: 'PRICE_PER_RECALL',
    },
    {
        label: mapType.toText('PRICE_PER_MANUAL_SORTING_LARGE_PARCEL'),
        value: 'PRICE_PER_MANUAL_SORTING_LARGE_PARCEL',
    },
    {
        label: mapType.toText('PRICE_PER_MANUAL_SORTING_EXTRA_LARGE_PARCEL'),
        value: 'PRICE_PER_MANUAL_SORTING_EXTRA_LARGE_PARCEL',
    },
    {
        label: mapType.toText('PRICE_FOR_TOO_LARGE_TO_DELIVER'),
        value: 'PRICE_FOR_TOO_LARGE_TO_DELIVER',
    },
    {
        label: mapType.toText('PRICE_FOR_SIGNATURE_REQUIRED_AT_DOOR'),
        value: 'PRICE_FOR_SIGNATURE_REQUIRED_AT_DOOR',
    },
    {
        label: mapType.toText('PRICE_FOR_FRAUD_PROTECTION_BOX_DELIVERY'),
        value: 'PRICE_FOR_FRAUD_PROTECTION_BOX_DELIVERY',
    },
    {
        label: mapType.toText('PRICE_FOR_BOX_TO_HOME_TOO_LARGE_TO_DELIVER'),
        value: 'PRICE_FOR_BOX_TO_HOME_TOO_LARGE_TO_DELIVER',
    },
]

export default class CreateModal extends Component {
    constructor(...props) {
        super(...props)
        this.state = this.getInitState()
        this.onPostalCodeZoneChange = this.onPostalCodeZoneChange.bind(this)
        this.onPricePointChange = this.onPricePointChange.bind(this)
        this.addAmount = this.addAmount.bind(this)
        this.handleDateFromChange = this.handleDateFromChange.bind(this)
        this.handleDateToChange = this.handleDateToChange.bind(this)
        this.cancelModal = this.cancelModal.bind(this)
        this.createPricePoints = this.createPricePoints.bind(this)
    }

    getInitState = () => ({
        postalcodeZoneIds: [],
        types: [],
        newZones: [],
        amount: null,
        currency: 'SEK',
        errorMsg: null,
        isSaving: false,
        toggleStyle: { display: 'none' },
        validate: { amount: true, postalcodeZoneIds: true, types: true },
    })

    validate() {
        const { postalcodeZoneIds, types, amount } = this.state
        this.setState({
            validate: {
                postalcodeZoneIds: postalcodeZoneIds.length > 0,
                types: types.length > 0,
                amount: !!amount,
            },
        })
        return (
            this.state.postalcodeZoneIds.length > 0 &&
            this.state.types.length > 0 &&
            this.state.amount !== null &&
            this.state.currency !== null
        )
    }

    resetState() {
        this.setState(this.getInitState())
    }

    cancelModal() {
        this.resetState()
        this.props.toggle()
    }

    epochMilli = (m) => {
        return m.valueOf() + m.utcOffset() * 60 * 1000
    }

    createPricePoints() {
        const { amount } = this.state
        const { currency } = this.state
        const validFrom = this.state.validFrom != null ? this.epochMilli(this.state.validFrom) : null
        const validTo = this.state.validTo != null ? this.epochMilli(this.state.validTo) : null

        const request = {
            collectionPointIds: [this.props.cid],
            postalcodeZoneIds: this.state.postalcodeZoneIds,
            types: this.state.types,
            validFrom,
            validTo,
            amount: amount * 100,
            currency,
        }
        if (this.validate()) {
            this.props.onCreate(request)
            this.setState({ isSaving: true })
        }
    }

    onPostalCodeZoneChange(value) {
        this.state = {
            ...this.state,
            postalcodeZoneIds: value.map((v) => v.value),
            validate: { ...this.state.validate, postalcodeZoneIds: true },
        }
        this.forceUpdate()
    }

    onPricePointChange(item) {
        this.state = {
            ...this.state,
            types: item.map((i) => i.value),
            validate: { ...this.state.validate, types: true },
        }
        this.forceUpdate()
    }

    onCountryChange = (countries) => {
        if (!countries.length) {
            return
        }
        const newCountriesValues = countries.map((c) => c.value)
        const newZones = this.props.postalcodes.filter((p) => newCountriesValues.includes(p.countryCode))

        this.setState({
            changed: true,
            newZones,
        })
    }

    addAmount(e) {
        this.state = {
            ...this.state,
            amount: e.target.value,
            validate: { ...this.state.validate, amount: true },
        }
        this.forceUpdate()
    }

    changeCurrency(value) {
        this.setState({ currency: value })
        this.toggleCurrency()
    }

    toggleCurrency() {
        const { toggleStyle } = this.state
        this.setState({ toggleStyle: { display: toggleStyle.display === 'none' ? 'block' : 'none' } })
    }

    handleDateFromChange(m) {
        this.setState({ validFrom: m })
    }

    handleDateToChange(m) {
        this.setState({ validTo: m })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isSaving && !nextProps.saving) {
            this.resetState()
            this.props.toggle()
        }
    }

    render() {
        const { newZones } = this.state
        return (
            <Modal show={this.props.show} animation>
                <Loader color="#449d44" loaded={!this.props.saving} />
                <div className="panel panel-default" style={{ margin: 0 }}>
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            {' '}
                            <i className="glyphicon glyphicon-usd" /> Manage Price Points
                        </h3>
                    </div>
                    <div className="panel-body">
                        <div className="price-points">
                            <div>
                                <div>
                                    <div className="fielditem">
                                        <div className="label"> Choose a country to pre-fill all postal zones</div>
                                        <CountrySelect
                                            multi
                                            onSelect={(countries) => this.onCountryChange(countries)}
                                        />
                                    </div>

                                    <div className="fielditem">
                                        <div className="label"> Postal Zones: </div>
                                        <div className={!this.state.validate.postalcodeZoneIds ? 'modal-error' : ''}>
                                            <PostalCodeZones
                                                zones={this.props.postalcodes}
                                                onChange={this.onPostalCodeZoneChange}
                                                newZones={newZones}
                                            />
                                        </div>
                                    </div>
                                    <div className="fielditem">
                                        <div className="label"> Price Types: </div>
                                        <Select
                                            className={!this.state.validate.types ? 'modal-error' : ''}
                                            multi
                                            value={this.state.types}
                                            options={pricePointTypes}
                                            onChange={this.onPricePointChange}
                                        />
                                    </div>
                                </div>
                                <div className="fielditem">
                                    <div className="label"> Currency: </div>
                                    <Select
                                        className={!this.state.validate.types ? 'modal-error' : ''}
                                        allowCreate={false}
                                        backspaceRemoves={false}
                                        clearable={false}
                                        searchable={false}
                                        isLoading={false}
                                        options={currencyOptions}
                                        value={this.state.currency}
                                        autosize={false}
                                        onChange={(item) => this.setState({ currency: item.value })}
                                    />
                                </div>
                                <div className="fielditem">
                                    <div className="label"> Price Amount: </div>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className={`form-control ${
                                                !this.state.validate.amount ? 'modal-error' : ''
                                            }`}
                                            placeholder={0}
                                            onChange={this.addAmount}
                                        />
                                        <span className="input-group-addon"> {this.state.currency} </span>
                                    </div>
                                </div>
                            </div>

                            <div className="fielditem">
                                <div className="label"> Date Range: </div>
                                <div className="date-group">
                                    <DatePicker
                                        selected={this.state.validFrom}
                                        className="form-control"
                                        onChange={this.handleDateFromChange}
                                        placeholderText="Valid from"
                                    />
                                    <DatePicker
                                        selected={this.state.validTo}
                                        className="form-control"
                                        onChange={this.handleDateToChange}
                                        placeholderText="Valid to"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-footer">
                        <button type="button" className="btn" onClick={this.cancelModal}>
                            <i className="glyphicon glyphicon-remove" /> Cancel
                        </button>
                        <div className="right">
                            <button type="button" className="btn btn-success" onClick={this.createPricePoints}>
                                <i className="glyphicon glyphicon-floppy-save" />
                                Store
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
CreateModal.propTypes = {
    postalcodes: PropTypes.array,
    onCreate: PropTypes.func,
    toggle: PropTypes.func,
    saving: PropTypes.bool,
    show: PropTypes.bool,
    cid: PropTypes.number,
}
