import type from './type'

export default (node) => {
    const s = type(node)
    let v = null
    if (s === 'object') {
        if (type(node.getDOMNode) === 'function') {
            v = node.getDOMNode().value
        } else {
            v = node.value
        }
    } else {
        v = node
    }

    return v === '' ? null : v
}
