/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/regional-settings'

const toOption = ({ id: value, englishExonym: label }) => ({ value, label })

class CountrySelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: null,
        }
    }

    componentDidMount() {
        this.props.actions.fetchRegionalSettings()
    }

    componentDidUpdate(prevProps) {
        if (this.props.countries !== prevProps.countries) {
            if (this.props.value) {
                const { value } = this.props
                if (value.label) {
                    this.setState({ selected: value })
                } else {
                    const selected = this.props.countries.find((country) => country.id === value) || null
                    this.setState({ selected })
                }
            }
        }
    }

    onChange = (selected) => {
        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    render() {
        const { multi, countries } = this.props
        const { selected } = this.state

        const placeholder = multi ? 'Select countries...' : 'Select country...'

        return (
            <Select
                placeholder={placeholder}
                value={selected}
                options={countries.map(toOption)}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable
                multi={multi}
            />
        )
    }
}

CountrySelect.propTypes = {
    multi: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    actions: PropTypes.object,
    countries: PropTypes.array.isRequired,
}

CountrySelect.defaultProps = {
    multi: false,
}

const mapStateToProps = (state) => ({
    countries: state.regionalSettings.countries,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect)
