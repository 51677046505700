import { debounce } from 'lodash'
import { curry } from './curry'
import { findMerchants } from './merchant-webapi'
import { handleError } from './handle-error'

export const reverseSorter = curry((sorter, a, b) => sorter(b, a))

export const mapSort = curry((sorter, mapper, a, b) => {
    const x = mapper(a)
    const y = mapper(b)
    return sorter(x, y)
})

export const composeSort = (...sorters) => (a, b) =>
    sorters.reduce((cmp, sorter) => (cmp !== 0 ? cmp : sorter(a, b)), 0)

export const sortStrings = curry((a, b) => {
    if (a == null && b == null) {
        return 0
    }
    if (a == null) {
        return -1
    }
    if (b == null) {
        return 1
    }

    return a.localeCompare(b, ['sv', 'dk', 'fi', 'en'], { sensitivity: 'case' })
})

export const sortNumbers = curry((a, b) => {
    if (a == null && b == null) {
        return 0
    }
    if (a == null) {
        return -1
    }
    if (b == null) {
        return 1
    }

    if (a < b) {
        return -1
    }
    if (a > b) {
        return 1
    }

    return 0
})

const dayMappings = {
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
    SU: 7,
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
}
export const sortDays = mapSort(sortNumbers, (day) => dayMappings[day.toUpperCase()])

export const temporalOptions = [
    { label: 'Today', value: 'TODAY' },
    { label: 'Tomorrow', value: 'TOMORROW' },
    { label: 'Future (after tomorrow)', value: 'FUTURE' },
]

export const loadMerchantOptions = debounce((searchTerm, callback) => {
    const convertResponseToSelectOptions = (merchants) => ({
        options: merchants.map(({ id: value, name, externalName }) => ({
            value,
            label: `${name} (${externalName})`,
        })),
    })

    findMerchants(searchTerm)
        .then((response) => callback(null, convertResponseToSelectOptions(response)))
        .catch((error) => {
            handleError(error)
            callback(null, convertResponseToSelectOptions([]))
        })
}, 250)
