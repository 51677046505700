import React, { Component } from 'react'

export default class SaveVariationHelp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{ paddingTop: '20px' }}>
                <h1>Save variation</h1>
                <hr />
                <div stye={{ position: 'relative' }}>
                    <div
                        style={{
                            width: '50vw',
                            float: 'left',
                            textAlign: 'justify',
                            padding: '10px 20px',
                        }}
                    >
                        <p>
                            In attempt to learn from previous failed attempts, the geocoder will remember variations of
                            an address for future uses. An example might be when a consumer enters their address as
                            "Storgatan c/o Sven 1" with the real address as "Storgatan 1". The geocoder will most likely
                            not geocode this correctly, although the consumer may repeatadly enter this as their street
                            address. So we can save "Storgatan c/o Sven 1" as a variation of "Storgatan 1" so that the
                            geocoder will know how to geocode this address in later requests.
                        </p>

                        <p>
                            However if the same user entered "Sven 1" (when they live at "Storgatan 1") then this
                            variation should not be saved. This is because the address "Sven 1" could legitiamley be
                            used for another street and will thus potentially result in false positive matches. Likewise
                            if the consumer was to enter "portkod: 3454 5tr"; it should not be saved as a variation as
                            it has no relevance the the actuall street address.
                        </p>
                        <p>
                            Do note that if the postal code, locality, or country is incorrect then the street can not
                            be saved as a variation as this may increase the change of producing a false positive.
                            Whoever is updating addresses must determine whether the original street should be used as a
                            variation and lean to no if unsure.
                        </p>
                    </div>
                    <div style={{ width: '50vw', float: 'left', padding: '10px 20px' }}>
                        Examples that can be deemed saveable or not:
                        <table style={{ width: '100%', padding: '5px' }}>
                            <tr>
                                <th>Saveable</th>
                                <th>Non Saveable</th>
                            </tr>
                            <tr>
                                <td>Åbyallé 39</td>
                                <td>c/o c/o Olovzon</td>
                            </tr>
                            <tr>
                                <td>Lindalsvägen 35 B Lindalsvägen 35b</td>
                                <td>Donsögatan</td>
                            </tr>
                            <tr>
                                <td>C/o rosenberg. Sankt anskars väg 4</td>
                                <td>Portkodan 3775 annas ring 0707884866</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
