/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { findShoppingBackofficeUrlFromHostname } from '../../../../utils/service-url'

// eslint-disable-next-line react/prefer-stateless-function
class ShoppingSettingsForm extends Component {
    render() {
        return (
            <div>
                <div className="country-based-settings-header form-group">
                    <a
                        _target="blank"
                        href={`${findShoppingBackofficeUrlFromHostname()}/storesettings/${
                            this.props.merchant.externalId
                        }?name=${this.props.merchant && this.props.merchant.name}`}
                    >
                        <label style={{ textDecoration: 'underline', fontSize: 24 }}>Open Shopping Backoffice</label>
                    </a>
                </div>
            </div>
        )
    }
}

ShoppingSettingsForm.propTypes = {
    merchant: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
    loading: state.buyers.loading,
})

export default connect(mapStateToProps)(ShoppingSettingsForm)
