import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import mapTimeZoneToCountry from '../../utils/timezone'
import { missDeliveryAttempt } from '../../utils/order-webapi'
import { handleError } from '../../utils/handle-error'

/**
    Modal to Miss a Delivery Attempt

* */
export default class MissModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        order: PropTypes.object.isRequired,
        deliveryAttempt: PropTypes.object.isRequired,
        onClose: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
    }

    state = {
        loading: false,
        comment: '',
        category: 1, // TODO: These may be populated with categories, or refactored into how we will use miss categories
    }

    validate() {
        return this.state.category !== 0
    }

    submit = () => {
        this.setState({ loading: true })
        const {
            order: { id: orderId },
            deliveryAttempt: { id: deliveryAttemptId },
        } = this.props
        missDeliveryAttempt(orderId, deliveryAttemptId, this.state.comment, this.state.category)
            .then(() => {
                this.setState({ loading: false })
                this.props.onSuccess()
            })
            .catch((e) => {
                this.setState({ loading: false })
                handleError(e)
            })
    }

    title() {
        const { deliveryAddress } = this.props.order
        const deliveryStart = moment(this.props.deliveryAttempt.interval.startTimestamp.date)
        const deliveryStop = moment(this.props.deliveryAttempt.interval.stopTimestamp.date)
        const timezone = mapTimeZoneToCountry(deliveryAddress.countryCode)

        return [
            'Miss Delivery attempt:',
            deliveryStart.tz(timezone).format('YYYY-MM-DD HH:mm'),
            '-',
            deliveryStop.tz(timezone).format('HH:mm'),
        ].join(' ')
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.title()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <ControlLabel>Comment</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                rows="3"
                                placeholder="Add a comment"
                                value={this.state.comment}
                                autoFocus
                                onChange={(e) => this.setState({ comment: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Category</ControlLabel>
                            <FormControl
                                componentClass="select"
                                value={this.state.category}
                                onChange={(e) => this.setState({ category: e.target.value })}
                            >
                                <option value={1}>Other</option>
                            </FormControl>
                        </FormGroup>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button bsStyle="danger" disabled={!this.validate() || this.state.loading} onClick={this.submit}>
                        Miss Delivery Attempt
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
