import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

export default class Create extends Component {
    constructor(props) {
        super(props)

        this.state = {
            focused: true,
            date: moment(),
            name: '',
        }
    }

    render() {
        const { focused, date, name } = this.state

        return (
            <div>
                <form>
                    <FormGroup>
                        <ControlLabel>Date</ControlLabel>
                        <SingleDatePicker
                            date={date}
                            onDateChange={(date) => this.setState({ date })}
                            focused={focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            firstDayOfWeek={1}
                            displayFormat="YYYY-MM-DD"
                            id="create_holiday_datepicker"
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Holiday name"
                            value={name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={this.props.onCancel}>Cancel</Button>
                        <Button
                            type="submit"
                            bsStyle="primary"
                            disabled={!date || !name}
                            onClick={(e) => {
                                e.preventDefault()
                                this.props.onCreate({
                                    date: date.format('YYYY-MM-DD'),
                                    name,
                                })
                            }}
                        >
                            Create
                        </Button>
                    </FormGroup>
                </form>
            </div>
        )
    }
}

Create.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
}
