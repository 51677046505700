import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.parcels-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('parcels')

export const setParcelStatus = (parcels, status, code) =>
    webapi()
        .path('status')
        .put({ parcels, status, code })

export const getParcelScanningLog = (parcelId) =>
    webapi()
        .path(parcelId)
        .path('scanning-log')
        .get()

export const recallParcel = (merchantId, parcelId) =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('merchants')
        .path(merchantId)
        .path('parcels')
        .path(parcelId)
        .path('return-to-merchant')
        .post()

export const printLabel = (parcelId) =>
    webapi()
        .path(parcelId)
        .path('shipping-label')
        .path('print')
        .get()

export const sealBox = (parcelId) =>
    webapi()
        .path('seal-box')
        .post({ parcelId })

export const getIngridDedupParcels = () =>
    new Webapi()
        .authorization(auth.get())
        .path('ingrid')
        .path('dedup')
        .queryParam('terminal-code', 'OSLO')
        .get()

export const recallIngridDedupParcels = () =>
    new Webapi()
        .authorization(auth.get())
        .path('ingrid')
        .path('dedup-recall')
        .queryParam('terminal-code', 'OSLO')
        .post()
