import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { connect } from 'react-redux'

const toOption = (lane) => {
    return { value: lane.id, label: lane.name }
}

class LaneSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: [],
        }
    }

    onChange = (lanePicks) => {
        this.setState({ selected: lanePicks.slice() })
        this.props.onChange(lanePicks)
    }

    render() {
        const { lanes } = this.props
        const { selected } = this.state
        return <Select multi value={selected} options={lanes.map(toOption)} onChange={this.onChange} />
    }
}

LaneSelect.propTypes = {
    lanes: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({ lanes: state.terminalRules.terminalLanes })

export default connect(mapStateToProps)(LaneSelect)
