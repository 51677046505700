import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import AdjustTiersModal from './adjust-tiers-modal'

class AdjustTierButton extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            showModal: false,
        }
    }

    toggleModal = () => this.setState((previousState) => ({ showModal: !previousState.showModal }))

    render() {
        const { partitionName, priceTiers, unit, modifyPriceTiers, enumTier, quantityEnumMap } = this.props
        const { showModal } = this.state
        return (
            <div>
                <Button bsStyle="primary" className="pull-right" onClick={this.toggleModal}>
                    Adjust prices
                </Button>
                <AdjustTiersModal
                    show={showModal}
                    partitionName={partitionName}
                    priceTiers={priceTiers}
                    onClose={this.toggleModal}
                    unit={unit}
                    modifyPriceTiers={modifyPriceTiers}
                    enumTier={enumTier}
                    quantityEnumMap={quantityEnumMap}
                />
            </div>
        )
    }
}

export default AdjustTierButton

AdjustTierButton.propTypes = {
    priceTiers: PropTypes.array.isRequired,
    partitionName: PropTypes.string.isRequired,
    unit: PropTypes.object.isRequired,
    modifyPriceTiers: PropTypes.func.isRequired,
    enumTier: PropTypes.bool.isRequired,
    quantityEnumMap: PropTypes.object,
}
