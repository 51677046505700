import { INVOICE_SETTINGS_LOADED } from '../constants/actionTypes'
import defaultState from './initialState/invoice-settings-state'

export default function InvoiceSettingsReducer(state = defaultState, action) {
    switch (action.type) {
        case INVOICE_SETTINGS_LOADED:
            return {
                ...state,
                articles: action.response.articles,
            }

        default:
            return state
    }
}
