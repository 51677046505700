import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import JournalEventsModal from './journal-events-modal'
import JournalEventsList from './journal-events-list'
import { eventsToShowInList } from '../../../utils/journal-events'

export default class JournalEvents extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
        }
    }

    toggleShow = () => this.setState((state) => ({ show: !state.show }))

    render() {
        const { loadingScanEvents, loadingScanEventsFailed, allScanEvents, timeZone } = this.props
        const { show } = this.state

        const listEvents = allScanEvents.filter((event) => eventsToShowInList.includes(event.event.event))

        return (
            <div>
                <JournalEventsList
                    show={listEvents.length > 0 && !loadingScanEventsFailed}
                    events={listEvents}
                    timeZone={timeZone}
                />
                <Button onClick={this.toggleShow}>Show all journal events</Button>
                <i className="pull-right">
                    * Above are some events of special importance. To see all events, press &apos;Show all journal
                    events&apos;
                </i>
                {show && (
                    <JournalEventsModal
                        show={show}
                        onClose={this.toggleShow}
                        loadingScanEvents={loadingScanEvents}
                        loadingScanEventsFailed={loadingScanEventsFailed}
                        allScanEvents={allScanEvents}
                    />
                )}
            </div>
        )
    }
}

JournalEvents.propTypes = {
    timeZone: PropTypes.string.isRequired,
    loadingScanEvents: PropTypes.bool.isRequired,
    loadingScanEventsFailed: PropTypes.bool.isRequired,
    allScanEvents: PropTypes.array.isRequired,
}
