import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/regional-settings'

const toOption = ({ id: value, englishExonym: label }) => ({ value, label })

class CitySelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.selected,
        }
    }

    componentDidMount() {
        this.props.actions.fetchRegionalSettings()
    }

    componentWillReceiveProps({ value, countryFilter }) {
        if (value != null) {
            if (value.label != null || value.length != null) {
                this.setState({ selected: value })
            } else {
                this.setState({ selected: this.props.cities.map(toOption).find((city) => city.value === value) })
            }
        }

        // If applying a Country filter, trigger an onChange if selected value is no longer selectable
        if (countryFilter != this.props.countryFilter && this.state.selected) {
            const selectedCityExistsInCountry = this.props.cities
                .filter((city) => city.countryCode === countryFilter)
                .some((city) => city === this.state.selected.value)

            if (!selectedCityExistsInCountry) {
                this.onChange(null)
            }
        }
    }

    onChange = (selected) => {
        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    render() {
        const { multi, cities, countryFilter, clearable } = this.props
        const { selected } = this.state

        const applicableCities = countryFilter ? cities.filter((city) => city.countryCode === countryFilter) : cities

        const placeholder = multi ? 'Select cities...' : 'Select city...'
        return (
            <Select
                disabled={this.props.disabled}
                placeholder={placeholder}
                value={selected}
                options={applicableCities.map(toOption)}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable={clearable}
                multi={multi}
            />
        )
    }
}

CitySelect.propTypes = {
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    selected: PropTypes.object,
    multi: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    actions: PropTypes.object,
    cities: PropTypes.array.isRequired,
    countryFilter: PropTypes.string,
}

CitySelect.defaultProps = {
    multi: true,
}

const mapStateToProps = ({ regionalSettings }) => ({
    cities: regionalSettings.cities,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CitySelect)
