import Api from './api'
import auth from '../auth'
import dev from './dev'

const baseUrl = '/api/inventory'
const contentType = dev('application/json', 'application/vnd.budbee.inventory-v1+json')

export default {
    getWarehouses() {
        return new Api(baseUrl)
            .path('warehouses')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getWarehouse(id) {
        return new Api(baseUrl)
            .path('warehouses')
            .path(id)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },
}
