import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, ControlLabel } from 'react-bootstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import { loadMerchantOptions } from '../../../../../utils/sorting'

class OnDemandRouteSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            routeOption: null,
            merchant: null,
            routes: [],
        }
    }

    onChange = (routeOption) => this.setState({ routeOption })

    onMerchantSelected = (merchant) => {
        this.setState({
            merchant,
        })
        if (merchant != null) {
            this.props.onDemandRouteOptionsGetter(merchant.value).then((routes) => {
                this.setState({
                    routes,
                })
            })
        } else {
            this.setState({
                routeOption: null,
                routes: [],
            })
        }
    }

    render = () => {
        const { merchant, routes } = this.state
        return (
            <div>
                <FormGroup>
                    <ControlLabel>Merchant</ControlLabel>
                    <AsyncSelect
                        autoload={false}
                        autosize={false}
                        loadOptions={loadMerchantOptions}
                        placeholder="Merchant name"
                        noResultsText="Merchant not found..."
                        value={merchant != null ? merchant.value : null}
                        onSelectResetsInput={false}
                        onBlurResetsInput={false}
                        onCloseResetsInput={false}
                        onChange={this.onMerchantSelected}
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>
                        Select On Demand Route{merchant != null ? ` for ${merchant.label}` : ''}
                    </ControlLabel>
                    <Select
                        disabled={!this.state.merchant}
                        autoBlur
                        allowCreate={false}
                        backspaceRemoves={false}
                        clearable={false}
                        ignoreCase
                        searchable
                        options={routes}
                        value={this.state.routeOption}
                        onChange={this.onChange}
                        placeholder="Select route"
                        noResultsText="None available..."
                    />
                    <br />
                    <Button
                        disabled={!this.state.routeOption}
                        bsStyle="warning"
                        bsSize="small"
                        onClick={() => this.props.onSave(this.state.routeOption)}
                    >
                        Save
                    </Button>
                </FormGroup>
            </div>
        )
    }
}

OnDemandRouteSelect.propTypes = {
    onDemandRouteOptionsGetter: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
}

export default OnDemandRouteSelect
