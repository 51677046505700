import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import moment from 'moment'
import CreateRecurringModal from './recurring-invoice-modal'
import { handleError } from '../../utils/handle-error'

export default class RecurringInvoiceView extends React.Component {
    static propTypes = {
        invoiceRecipientId: PropTypes.number,
        loadRecurringInvoiceItems: PropTypes.func,
        createRecurringInvoiceItem: PropTypes.func,
        deleteRecurringInvoiceItem: PropTypes.func,
        invoiceTypes: PropTypes.array,
        invoiceArticles: PropTypes.array,
    }

    state = {
        recurringInvoices: [],
        recurringChecked: [],
        openCreateRecurringModal: false,
    }

    componentDidMount() {
        this.loadAllRecurringInvoices()
    }

    loadAllRecurringInvoices = () => {
        this.props
            .loadRecurringInvoiceItems(this.props.invoiceRecipientId)
            .then((resp) => {
                this.setState({
                    recurringInvoices: resp,
                    recurringChecked: Object.values(resp.map(() => false)),
                })
            })
            .catch(handleError)
    }

    toggleCheckRecurring = (index) => {
        this.setState({
            recurringChecked: Object.values({
                ...this.state.recurringChecked,
                [index]: !this.state.recurringChecked[index],
            }),
        })
    }

    deleteMarkedRecurringInvoices = () => {
        for (let i = 0; i < this.state.recurringChecked.length; i += 1) {
            if (this.state.recurringChecked[i]) {
                this.props
                    .deleteRecurringInvoiceItem(this.state.recurringInvoices[i].id)
                    .then(this.loadAllRecurringInvoices)
            }
        }
    }

    render() {
        return (
            <Col md={10} mdOffset={1}>
                <Row>
                    <h3> Recurring invoices </h3>
                    <h5>
                        Recurring invoices are generated one day in advance.
                        <br />
                        Generated Invoices can be removed and edited until they are booked.
                        <br />
                        The morning of every week start, all unbooked invoices are booked and hence no longer editable.
                    </h5>
                    <CreateRecurringModal
                        invoiceRecipientId={this.props.invoiceRecipientId}
                        show={this.state.openCreateRecurringModal}
                        loadRecurringInvoiceItems={this.loadAllRecurringInvoices}
                        createRecurringInvoiceItem={this.props.createRecurringInvoiceItem}
                        close={() => this.setState({ openCreateRecurringModal: false })}
                        invoiceTypes={this.props.invoiceTypes}
                        invoiceArticles={this.props.invoiceArticles}
                    />
                    <Button
                        className="pull-right"
                        bsStyle="success"
                        onClick={() => this.setState({ openCreateRecurringModal: true })}
                    >
                        <i className="glyphicon glyphicon-plus" /> Create new recurring
                    </Button>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <Button
                                        bsStyle="danger"
                                        bsSize="xsmall"
                                        onClick={this.deleteMarkedRecurringInvoices}
                                    >
                                        <i className="glyphicon glyphicon-minus small" />
                                    </Button>
                                </th>
                                <th>#</th>
                                <th>Day</th>
                                <th>Recurring Interval</th>
                                <th>Description</th>
                                <th>Custom Description</th>
                                <th>Invoice Type</th>
                                <th>Invoice article</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Created at</th>
                                <th>Valid from</th>
                                <th>Valid to</th>
                                <th>Created by</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.recurringInvoices.map((ri, i) => (
                                <tr key={ri.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={this.state.recurringChecked[i]}
                                            onChange={() => this.toggleCheckRecurring(i)}
                                        />
                                    </td>
                                    <td>{ri.id}</td>
                                    <td>{ri.dayOfWeek}</td>
                                    <td>{ri.recurringInterval}</td>
                                    <td>{ri.description}</td>
                                    <td>{ri.customInvoiceDescription}</td>
                                    <td>{ri.invoiceAccountType}</td>
                                    <td>{ri.invoiceArticle}</td>
                                    <td>{ri.amountInCents / 100}</td>
                                    <td>{ri.currency.code}</td>
                                    <td>{moment.unix(ri.createdAt / 10e2).format('YYYY-MM-DD')}</td>
                                    <td>
                                        {ri.validFrom === null
                                            ? 'None'
                                            : moment(ri.validFrom.join('-'), 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                    </td>
                                    <td>
                                        {ri.validTo === null
                                            ? 'None'
                                            : moment.unix(ri.validFrom.join('-'), 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                    </td>
                                    <td>{`${ri.createdByUser.firstName} ${ri.createdByUser.lastName}`} </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            </Col>
        )
    }
}
