import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    ControlLabel,
    Form,
    FormGroup,
    Col,
    DropdownButton,
    MenuItem,
    InputGroup,
    Image,
} from 'react-bootstrap'
import DatePicker from 'react-bootstrap-date-picker'
import Loader from 'react-loader'

import ProductsList from './products-list'
import ProductForm from './product-form'
import MerchantInput from '../merchant-input'
import { createCollection, updateCollection, addCollectionImage } from '../../../../utils/shopping-collection-api'
import Uploader from '../../../uploader'
import { handleError } from '../../../../utils/handle-error'

const displaySize = ['NONE', 'SMALL', 'MEDIUM', 'LARGE']
export default class CollectionsForm extends Component {
    constructor(...props) {
        super(...props)
        const { id, title, imageUrl, validFrom, validTo, created, updated, order, products, size } = {
            ...this.props.collection,
        }
        this.state = {
            id: id || null,
            title: title || null,
            imageUrl: imageUrl || null,
            validFrom: validFrom || null,
            validTo: validTo || null,
            created: created || null,
            updated: updated || null,
            order: order || null,
            products: products || [],
            size: size || displaySize[0],
            showProducts: false,
            showCollectionForm: false,
            preView: null,
            fileObject: null,
            addProduct: false,
            isSubmitting: false,
            submitError: null,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.collection) {
            const {
                id,
                title,
                imageUrl,
                validFrom,
                validTo,
                created,
                updated,
                order,
                size,
                products,
            } = this.props.collection

            if (prevProps.collection.id !== id)
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    id,
                    title,
                    imageUrl,
                    validFrom,
                    validTo,
                    created,
                    updated,
                    order,
                    size,
                    products,
                })
        }
    }

    saveProductData = (newData) => {
        this.setState(({ products }) => ({
            products: [...products, newData],
        }))
    }

    handleInputChange = (evt) => {
        const { name, value } = evt.target
        this.setState((state) => ({
            ...state,
            [name]: name === 'order' ? Number(value) : value,
        }))
    }

    onSubmit = (e) => {
        this.setState({ isSubmitting: true })
        e.preventDefault()
        e.stopPropagation()

        const { title, order, validFrom, validTo, size, products, id, imageUrl } = this.state

        const data = {
            title,
            order,
            validFrom,
            validTo,
            size,
            products,
            imageUrl,
        }

        if (id) {
            updateCollection(this.props.group.id, id, data)
                .then(() => {
                    this.setState({ isSubmitting: false })
                })
                .catch((err) => {
                    this.setState({ submitError: true })
                    handleError(err)
                })
        } else {
            createCollection(this.props.group.id, data)
                .then(() => {
                    this.setState({ isSubmitting: false })
                })
                .catch((err) => {
                    this.setState({ submitError: true })
                    handleError(err)
                })
        }
    }

    onRemoveProduct = (productId) => {
        this.setState(({ products }) => ({
            products: products.filter((product) => product.id !== productId),
        }))
    }

    preView = ([file]) => {
        if (file == null) {
            return
        }

        this.setState({
            preView: URL.createObjectURL(file),
            fileObject: file,
        })
    }

    renderCollectinForm = () => {
        return (
            <Form horizontal>
                <MerchantInput
                    type="text"
                    name="title"
                    required
                    controlId="title"
                    label="Collection Title"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="number"
                    name="order"
                    required
                    controlId="order"
                    label="Collection order"
                    value={this.state.order}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="text"
                    name="imageUrl"
                    required
                    controlId="imageUrl"
                    label="Image Url"
                    value={this.state.imageUrl}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel htmlFor="validFrom">Valid From</ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <DatePicker
                            selected={this.state.validFrom}
                            value={this.state.validFrom}
                            dateFormat="YYYY-MM-DD"
                            onChange={(e) => {
                                this.setState({ validFrom: e })
                            }}
                            id="validFrom"
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel htmlFor="validTO">Valid To</ControlLabel>
                    </Col>
                    <Col sm={9}>
                        <DatePicker
                            selected={this.state.validTo}
                            value={this.state.validTo}
                            dateFormat="YYYY-MM-DD"
                            onChange={(e) => {
                                this.setState({ validTo: e })
                            }}
                            id="validTo"
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <DropdownButton componentClass={InputGroup.Button} id="size" title={this.state.size}>
                            {displaySize.map((size) => (
                                <MenuItem
                                    key={size}
                                    onClick={() =>
                                        this.setState({
                                            size,
                                        })
                                    }
                                >
                                    {size}
                                </MenuItem>
                            ))}
                        </DropdownButton>
                    </Col>
                    <Col sm={9}>
                        <Uploader onUpload={this.preView} />
                        {this.state.preView && (
                            <div>
                                <p>Preview</p>
                                <Image src={this.state.preView} responsive />
                            </div>
                        )}
                        <br />
                        <Button
                            className="pull-right"
                            onClick={() => {
                                addCollectionImage(this.props.group.id, this.state.id, this.state.fileObject)
                                    .then((res) => {
                                        this.setState({ imageUrl: res.url })
                                    })
                                    .catch((err) => {
                                        handleError(err)
                                    })
                            }}
                        >
                            Upload
                        </Button>
                    </Col>
                </FormGroup>
                <ProductForm
                    saveProductData={this.saveProductData}
                    groupId={this.props.group.id}
                    collectionId={this.state.id}
                    merchantId={this.props.merchantId}
                />
                <FormGroup>
                    <Button type="submit" bsStyle="primary" className="pull-right" onClick={this.onSubmit}>
                        Submit
                    </Button>
                </FormGroup>
            </Form>
        )
    }

    render() {
        return (
            <div>
                {this.state.submitError && 'An Error Occured'}
                {this.state.isSubmitting ? (
                    <Loader color="#bfbfbf" />
                ) : (
                    <div>
                        {<ProductsList onRemoveProduct={this.onRemoveProduct} products={this.state.products} />}
                        {this.props.group && (
                            <p>
                                Create collection in: <b>{this.props.group.title}</b>
                            </p>
                        )}
                        {this.renderCollectinForm()}
                    </div>
                )}
            </div>
        )
    }
}

CollectionsForm.propTypes = {
    group: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.string,
    }),
    merchantId: PropTypes.number,
    collection: PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        validFrom: PropTypes.string,
        validTo: PropTypes.string,
        created: PropTypes.string,
        updated: PropTypes.string,
        order: PropTypes.number,
        size: PropTypes.string,
        products: PropTypes.array,
    }),
}
