import React, { Component } from 'react'
import PropTypes from 'prop-types'
import differenceBy from 'lodash/differenceBy'
import { Alert } from 'react-bootstrap'
import { PriceTierType } from '../../../../../utils/types'
import auth from '../../../../../auth'
import AddTier from '../add'
import Tiers from './index'
import PostalCodeZones from '../../../../postal-code-zones/postal-code-zones'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
} from '../../../../../utils/role'

export const priceTierData = (priceTiers, type) => {
    if (!priceTiers) {
        return []
    }
    return priceTiers[type]
}

export default class DistributionTiers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addPriceToZones: [],
        }
    }

    postalCodeZoneSelector = (postalCodes) => (onChange, newZones) => {
        return (
            <PostalCodeZones
                disable
                newZones={newZones}
                zones={postalCodes.map((zones) => zones)}
                onChange={onChange}
            />
        )
    }

    getDefaultZonesWithoutPrice = (postalZones, data) => {
        const zonesWithPrice = []
        if (data == null) {
            return null
        }

        data.forEach((d) => {
            d.zones.forEach((z) => {
                const found = zonesWithPrice.some((el) => el.id === z.id)
                if (!found) {
                    zonesWithPrice.push(z)
                }
            })
        })
        return differenceBy(postalZones, zonesWithPrice, 'id').map((d) => d.title)
    }

    allZonesWithoutPrice = (defZonesNotTieredToPrice, addPriceToZones) => {
        const allZonesWithoutPrice = []
        if (defZonesNotTieredToPrice && defZonesNotTieredToPrice.length > 0) {
            defZonesNotTieredToPrice.forEach((z) => {
                const found = allZonesWithoutPrice.some((el) => el === z)
                if (!found) {
                    allZonesWithoutPrice.push(z)
                }
            })
        }
        if (addPriceToZones && addPriceToZones.length > 0) {
            addPriceToZones.forEach((z) => {
                allZonesWithoutPrice.forEach((e) => {
                    if (z === e) {
                        const removeItemWithIndex = allZonesWithoutPrice.indexOf(e)
                        allZonesWithoutPrice.splice(removeItemWithIndex, 1)
                    }
                })
            })
        }
        return allZonesWithoutPrice.join(', ')
    }

    zonesChange = (zones) => {
        const zoneToPrice = zones.map((d) => d.label)
        this.setState({ addPriceToZones: zoneToPrice })
    }

    render() {
        const { postalZones, priceTiers, addPriceTier, modifyPriceTiers, deletePriceTier } = this.props
        const { addPriceToZones } = this.state
        const data = priceTierData(priceTiers, PriceTierType.DistributionVolume)
        const defZonesWithoutPrice = this.getDefaultZonesWithoutPrice(postalZones, data)
        const allZonesWithoutPriceToString = this.allZonesWithoutPrice(defZonesWithoutPrice, addPriceToZones)
        const unit = { name: 'Weight', code: 'kg' }
        const canEdit = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

        return (
            <div>
                <div style={{ padding: 10 }}>
                    <ul>
                        <li>Here you can manage Price Tiers associated with a group of Zones for this Merchant.</li>
                        <li>These Zones can have multiple price Tiers</li>
                        <li>Prices apply up to and including the tier weight</li>
                        <li>A Zone can only appear once in a group per Merchant</li>
                    </ul>
                </div>
                {canEdit && postalZones ? (
                    <AddTier
                        unit={unit}
                        partitionRenderer={this.postalCodeZoneSelector(postalZones)}
                        partitionName="Zones"
                        postalZones={postalZones}
                        priceTiers={data}
                        priceTierType={PriceTierType.DistributionVolume}
                        addPriceTier={addPriceTier}
                        enumTier={false}
                        emitZonesChange={this.zonesChange}
                        emitValueAfterChange
                        showSelectCountry
                    />
                ) : null}
                {allZonesWithoutPriceToString ? (
                    <Alert bsStyle="info" style={{ marginTop: 18, marginBottom: 5 }}>
                        <p>
                            Active Delivery Zones that have Price Tiers missing: <b>{allZonesWithoutPriceToString}</b>
                        </p>
                    </Alert>
                ) : (
                    ''
                )}
                <Tiers
                    partitionName="Zones"
                    unit={unit}
                    priceTiers={data}
                    deletePriceTier={canEdit ? deletePriceTier : null}
                    modifyPriceTiers={(form) => modifyPriceTiers(PriceTierType.DistributionVolume, form)}
                    enumTier={false}
                />
            </div>
        )
    }
}

DistributionTiers.propTypes = {
    priceTiers: PropTypes.object,
    postalZones: PropTypes.array,
    addPriceTier: PropTypes.func,
    modifyPriceTiers: PropTypes.func.isRequired,
    deletePriceTier: PropTypes.func,
}
