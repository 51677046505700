import 'rc-slider/assets/index.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import cn from 'classnames'
import TimeControl from '../../../couriers/components/time-control'
import TerminalSelect from '../../../common/terminal-select'

const getInitState = () => ({
    intervalStart: { hours: '16', minutes: '00' },
    intervalEnd: { hours: '22', minutes: '00' },
    arrival: { hours: '22', minutes: '00' },
    terminalId: null,
    minPallets: '',
    maxPallets: '',
    validate: {
        terminalId: true,
        day: true,
        minPallets: true,
        maxPallets: true,
        intervalStartHours: true,
        intervalStartMinutes: true,
        intervalEndHours: true,
        intervalEndMinutes: true,
        arrivalHours: true,
        arrivalMinutes: true,
    },
    days: [
        { name: 'Mon', enum: 'MONDAY', selected: false },
        { name: 'Tue', enum: 'TUESDAY', selected: false },
        { name: 'Wed', enum: 'WEDNESDAY', selected: false },
        { name: 'Thu', enum: 'THURSDAY', selected: false },
        { name: 'Fri', enum: 'FRIDAY', selected: false },
        { name: 'Sat', enum: 'SATURDAY', selected: false },
        { name: 'Sun', enum: 'SUNDAY', selected: false },
    ],
})

export default class CreateModal extends Component {
    constructor(...args) {
        super(...args)
        this.state = getInitState()
    }

    onTerminalSelect = ({ id: terminalId }) => {
        this.setState({ terminalId }, this.validate)
    }

    onChangePallets = (type) => (e) => {
        this.setState({ [type]: e.target.value }, this.validate)
    }

    validate() {
        const newState = {
            validate: {
                day: this.state.days.some(({ selected }) => selected),
                terminalId: this.state.terminalId != null,
                minPallets: this.state.minPallets != null && this.state.minPallets !== '',
                maxPallets: this.state.maxPallets != null && this.state.maxPallets !== '',
                intervalStartHours:
                    this.state.intervalStart.hours != null && Number(this.state.intervalStart.hours) < 24,
                intervalStartMinutes:
                    this.state.intervalStart.minutes != null && Number(this.state.intervalStart.minutes) < 60,
                intervalEndHours: this.state.intervalEnd.hours != null && Number(this.state.intervalEnd.hours) < 24,
                intervalEndMinutes:
                    this.state.intervalEnd.minutes != null && Number(this.state.intervalEnd.minutes) < 60,
                arrivalHours: this.state.arrival.hours != null && Number(this.state.arrival.hours) < 24,
                arrivalMinutes: this.state.arrival.minutes != null && Number(this.state.arrival.minutes) < 60,
            },
        }
        this.setState(newState)

        if (
            newState.validate.day &&
            newState.validate.terminalId &&
            newState.validate.minPallets &&
            newState.validate.maxPallets &&
            newState.validate.intervalStartHours &&
            newState.validate.intervalStartMinutes &&
            newState.validate.intervalEndHours &&
            newState.validate.intervalEndMinutes &&
            newState.validate.arrivalHours &&
            newState.validate.arrivalMinutes
        ) {
            return true
        }
        return false
    }

    resetState() {
        this.setState(getInitState())
    }

    selectDay = (enumId) => () => {
        this.setState(
            (state) => ({
                days: state.days.map((d) => ({ ...d, selected: d.enum === enumId ? !d.selected : d.selected })),
            }),
            this.validate,
        )
    }

    cancelModal = () => {
        this.resetState()
        this.props.toggle()
    }

    handleTimeChange = (name) => (time) => {
        this.setState({ [name]: time }, this.validate)
    }

    savePickupSchedule = () => {
        if (this.validate()) {
            const saveData = {
                terminalId: this.state.terminalId,
                collectionPointId: this.props.cid,
                days: this.state.days.filter(({ selected }) => selected).map(({ enum: e }) => e),
                intervalEnd: `${this.state.intervalEnd.hours}:${this.state.intervalEnd.minutes}`,
                intervalStart: `${this.state.intervalStart.hours}:${this.state.intervalStart.minutes}`,
                arrival: `${this.state.arrival.hours}:${this.state.arrival.minutes}`,
                timezone: 'Europe/Stockholm',
                minPallets: this.state.minPallets,
                maxPallets: this.state.maxPallets,
            }
            this.props.savePickupSchedule(saveData)
            this.resetState()
        }
    }

    render() {
        const { intervalStart, intervalEnd, arrival } = this.state

        return (
            <Modal show={this.props.show} animation>
                <div className="panel panel-default" style={{ margin: 0 }}>
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            <i className="glyphicon glyphicon-time" /> Manage Pickup Schedules
                        </h3>
                    </div>
                    <div className="panel-body">
                        <div className="pickup-schedules">
                            <div className="col-md-12">
                                <div>
                                    <div className="fielditem">
                                        <div className="label">Day:</div>
                                        <div>
                                            <div
                                                className={cn('btn-group dow', {
                                                    'modal-error': !this.state.validate.day,
                                                })}
                                                role="group"
                                                aria-label="Days"
                                            >
                                                {this.state.days.map((item) => (
                                                    <button
                                                        key={item.name}
                                                        type="button"
                                                        className={cn('btn', {
                                                            'btn-primary': item.selected,
                                                            'btn-default': !item.selected,
                                                        })}
                                                        onClick={this.selectDay(item.enum)}
                                                    >
                                                        {item.name}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fielditem">
                                        <div className="label">Agreed time slot for pickup:</div>
                                        <div className="fieldWrapper">
                                            <TimeControl
                                                classNameHours={cn({
                                                    'modal-error': !this.state.validate.intervalStartHours,
                                                })}
                                                classNameMinutes={cn({
                                                    'modal-error': !this.state.validate.intervalStartMinutes,
                                                })}
                                                controlId="intervalStart"
                                                time={intervalStart}
                                                onChange={this.handleTimeChange('intervalStart')}
                                                placeholder={{ hours: '16', minutes: '00' }}
                                                focusNextElementById="intervalEndHours"
                                            />
                                            <TimeControl
                                                classNameHours={cn({
                                                    'modal-error': !this.state.validate.intervalEndHours,
                                                })}
                                                classNameMinutes={cn({
                                                    'modal-error': !this.state.validate.intervalEndMinutes,
                                                })}
                                                controlId="intervalEnd"
                                                time={intervalEnd}
                                                onChange={this.handleTimeChange('intervalEnd')}
                                                placeholder={{ hours: '22', minutes: '00' }}
                                                focusNextElementById="arrivalHours"
                                            />
                                        </div>
                                    </div>

                                    <div className="fielditem">
                                        <div className="label">Arrival at terminal:</div>
                                        <div className="fieldWrapper">
                                            <TimeControl
                                                classNameHours={cn({
                                                    'modal-error': !this.state.validate.arrivalHours,
                                                })}
                                                classNameMinutes={cn({
                                                    'modal-error': !this.state.validate.arrivalMinutes,
                                                })}
                                                controlId="arrival"
                                                time={arrival}
                                                onChange={this.handleTimeChange('arrival')}
                                                placeholder={{ hours: '22', minutes: '00' }}
                                            />
                                        </div>
                                    </div>

                                    <div className="fielditem">
                                        <div className="label">Dropoff terminal:</div>
                                        <div className={cn({ 'modal-error': !this.state.validate.terminalId })}>
                                            <TerminalSelect onSelect={this.onTerminalSelect} />
                                        </div>
                                    </div>
                                </div>

                                <div className="fielditem">
                                    <div className="label">Agreed minimum of pallets:</div>
                                    <input
                                        className={cn('form-control', {
                                            'modal-error': !this.state.validate.minPallets,
                                        })}
                                        type="number"
                                        required
                                        value={this.state.minPallets}
                                        onChange={this.onChangePallets('minPallets')}
                                    />
                                </div>

                                <div className="fielditem">
                                    <div className="label">Agreed maximum of pallets:</div>
                                    <input
                                        className={cn('form-control', {
                                            'modal-error': !this.state.validate.maxPallets,
                                        })}
                                        type="number"
                                        required
                                        value={this.state.maxPallets}
                                        onChange={this.onChangePallets('maxPallets')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel-footer">
                        <button type="button" className="btn" onClick={this.cancelModal}>
                            <i className="glyphicon glyphicon-remove" /> Cancel
                        </button>
                        <div className="right">
                            <button type="button" className="btn btn-success" onClick={this.savePickupSchedule}>
                                <i className="glyphicon glyphicon-floppy-save" /> Store
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

CreateModal.propTypes = {
    toggle: PropTypes.func,
    cid: PropTypes.number,
    savePickupSchedule: PropTypes.func,
    show: PropTypes.bool,
}
