const __toString = Function.prototype.call.bind(Object.prototype.toString)
const classMap = {}
const names = 'Boolean|Number|String|Function|Array|Date|RegExp|Undefined|Null'.split('|')
let n = null

while ((n = names.pop())) {
    classMap[`[object ${n}]`] = n.toLowerCase()
}

export default function type(obj) {
    const strType = __toString(obj)
    return classMap[strType] || 'object'
}
