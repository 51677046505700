import { mapSort, sortStrings } from '../../../../../utils/sorting'
import { partition } from '../../../../../utils/partition'
import { weave } from '../../../../../utils/weave'

export const sortLanes = (terminal, lanes) => {
    const sortedLanes = lanes.sort(mapSort(sortStrings, ({ name }) => name))
    if (terminal.sortingVersion !== 'SPREAD') {
        return sortedLanes
    }

    const half = sortedLanes.length / 2
    const [right, left] = partition(sortedLanes, ({ name }) => name <= half)

    return weave(right, left.reverse())
}
