import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hash from 'object-hash'
import { Row, Table, Button, Checkbox, Navbar, Nav, FormGroup, ControlLabel } from 'react-bootstrap'
import GeocodedAddress from './geocoded-address'
import { overwriteGeocode, searchGeocode } from '../../utils/geocoding-webapi'
import { handleError } from '../../utils/handle-error'

const Status = {
    NA: 'NA',
    EDITED: 'EDITED',
    SAVED: 'SAVED',
    FAILED: 'FAILED',
}

export const BrowseGeocoderDefaultState = {
    query: {
        street: '',
        postalCode: '',
        locality: '',
        country: '',
        matchExactStreet: true,
    },
    results: [],
}

export default class BrowseGeocoder extends Component {
    static propTypes = {
        setState: PropTypes.func,
        state: PropTypes.object,
    }

    constructor(props) {
        super(props)
        this.renderRows = this.renderRows.bind(this)

        this.renderForm = this.renderForm.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.search = this.search.bind(this)
        this.renderQueryInput = this.renderQueryInput.bind(this)
        this.renderExactMatch = this.renderExactMatch.bind(this)
        this.overwrite = this.overwrite.bind(this)
        this.syncResponse = this.syncResponse.bind(this)
        this.updateResultValue = this.updateResultValue.bind(this)
        this.updateResultCoordinateValue = this.updateResultCoordinateValue.bind(this)
        this.setQueryState = this.setQueryState.bind(this)
        this.setResultsState = this.setResultsState.bind(this)
    }

    setQueryState(newState) {
        const newQuery = {
            ...this.props.state.query,
            ...newState,
        }
        const newAllState = {
            ...this.props.state,
            query: newQuery,
        }
        this.props.setState(newAllState)
    }

    setResultsState(newState) {
        const newAllState = {
            ...this.props.state,
            results: newState,
        }
        this.props.setState(newAllState)
    }

    search() {
        searchGeocode(this.props.state.query)
            .then((resp) => {
                this.setResultsState(resp.map(this.addMeta))
            })
            .catch(handleError)
    }

    addMeta = (result) => ({
        hash: hash(result),
        status: Status.NA,
        data: result,
    })

    overwrite() {
        const resultsToUpdate = this.props.state.results
            .filter((result) => hash(result.data) !== result.hash)
            .map((result) => result.data)
        if (resultsToUpdate.length > 0) {
            overwriteGeocode(resultsToUpdate)
                .then(this.syncResponse)
                .catch(handleError)
        }
    }

    syncResponse(resp) {
        const newResults = this.props.state.results.map((result) => {
            const responseItem = resp.find((r) => r.buildingId === result.data.id)
            if (!responseItem) {
                return result
            }
            if (responseItem.overwritten) {
                return {
                    ...result,
                    status: Status.SAVED,
                }
            }
            return {
                ...result,
                status: Status.FAILED,
            }
        })
        this.setResultsState(newResults)
    }

    updateResultValue(resultId, field, value) {
        const updated = this.props.state.results.map((result) => {
            if (result.data.id !== resultId) {
                return result
            }

            const newData = {
                ...result.data,
                [field]: value,
            }
            const newStatus = hash(newData) === result.hash ? result.NA : Status.EDITED

            return {
                ...result,
                data: newData,
                status: newStatus,
            }
        })
        this.setResultsState(updated)
    }

    updateResultCoordinateValue(resultId, coordinate, axis, value) {
        const updated = this.props.state.results.map((result) => {
            if (result.data.id !== resultId) {
                return result
            }
            const newData = {
                ...result.data,

                [coordinate]: {
                    ...result.data[coordinate],
                    [axis]: value,
                },
            }
            const newStatus = hash(newData) === result.hash ? result.NA : Status.EDITED
            return {
                ...result,
                data: newData,
                status: newStatus,
            }
        })
        this.setResultsState(updated)
    }

    renderForm() {
        return (
            <div>
                <Navbar fluid className="geocoder-navbar">
                    <Nav>
                        <Navbar.Form>
                            <FormGroup>
                                <ControlLabel>Street</ControlLabel>
                                {this.renderQueryInput('street', 'geocoder-input')}
                                <ControlLabel>Postal Code</ControlLabel>
                                {this.renderQueryInput('postalCode', 'geocoder-short-input')}
                                <ControlLabel>Locality</ControlLabel>
                                {this.renderQueryInput('locality', 'geocoder-input')}
                                <ControlLabel>Country Code</ControlLabel>
                                {this.renderQueryInput('country', 'geocoder-short-input')}
                                <ControlLabel>Exact Match</ControlLabel>
                                {this.renderExactMatch()}
                            </FormGroup>{' '}
                            <Button type="submit" onClick={() => this.search()}>
                                Search
                            </Button>
                        </Navbar.Form>
                    </Nav>
                    <Nav pullRight>
                        <Button type="submit" onClick={() => this.overwrite()}>
                            Update
                        </Button>
                    </Nav>
                </Navbar>
            </div>
        )
    }

    renderQueryInput(item, className) {
        const update = (value) => {
            this.setQueryState({ [item]: value })
        }
        return (
            <input
                className={className}
                type="text"
                value={this.props.state.query[item]}
                onChange={(e) => update(e.target.value)}
            />
        )
    }

    renderExactMatch() {
        const update = (value) => {
            this.setQueryState({ matchExactStreet: value })
        }
        return (
            <Checkbox
                defaultChecked={this.props.state.query.matchExactStreet}
                onChange={(e) => update(e.target.checked)}
            />
        )
    }

    renderRows() {
        return this.props.state.results.map((result) => (
            <GeocodedAddress
                key={result.data.id}
                onUpdate={this.updateResultValue}
                onCoordinateUpdate={this.updateResultCoordinateValue}
                result={result}
            />
        ))
    }

    renderTable() {
        return (
            <Row className="geocoder-table">
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th />
                            <th>Street</th>
                            <th>Postal Code</th>
                            <th>Locality</th>
                            <th>Country</th>
                            <th>Coordinate</th>
                            <th>Navigation Coordinate</th>
                        </tr>
                    </thead>
                    <tbody>{this.renderRows()}</tbody>
                </Table>
            </Row>
        )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderTable()}
            </div>
        )
    }
}
