import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Grid, Col, Modal, Button } from 'react-bootstrap'
import { getRoute, listCurrentRouteSortingStatus } from '../../utils/route-webapi'
import { handleError } from '../../utils/handle-error'
import { setParcelStatus } from '../../utils/parcel-webapi'
import { prettyTime } from '../../utils/date'

const renderTableRow = (entry) => {
    let color = entry.scannedAt ? '#EBC944' : '#C02F1D'
    color = entry.sortedAt ? '#A3B86C' : color
    const recent = entry.scannedAt ? `${entry.scannedBy} @ ${prettyTime(entry.scannedAt)}` : 'N/A'

    return (
        <tr key={entry.packageId} style={{ backgroundColor: color }}>
            <td>{entry.buyer != null ? entry.buyer : 'N/A'}</td>
            <td>
                <Link to={`/admin/orders/${entry.token}`}>{entry.packageId}</Link>
            </td>
            <td>{entry.status}</td>
            <td>{entry.customer != null ? entry.customer : 'N/A'}</td>
            <td>{recent}</td>
        </tr>
    )
}

const getSortingPredicate = (a, b) =>
    (b.scannedBy == null) - (a.scannedBy == null) || (b.sortedAt == null) - (a.sortedAt == null)

export default class RouteSortingView extends Component {
    state = {
        entries: [],
        route: null,
        showBackorderModal: false,
    }

    componentDidMount() {
        this.fetchData()
        this.timer = setInterval(this.fetchData.bind(this), 15000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    getSorted() {
        return this.state.entries.filter((entry) => entry.sortedAt).length
    }

    getScanned() {
        return this.state.entries.filter((entry) => entry.scannedAt).length
    }

    getLane() {
        return this.state.entries.length > 0 ? this.state.entries[0].lane : 'N/A'
    }

    getMissing() {
        return this.state.entries.filter((entry) => !entry.scannedAt && !entry.sortedAt).length
    }

    fetchData() {
        const { routeId } = this.props.params
        const promises = [getRoute(routeId), listCurrentRouteSortingStatus(routeId)]

        Promise.all(promises)
            .then(([route, entries]) => this.setState({ route, entries }))
            .catch(handleError)
    }

    renderBackorderModal() {
        const close = () => this.setState({ showBackorderModal: false })
        const backorder = () => {
            if (window.confirm('WARNING THIS CAN NOT BE UNDONE, ARE YOU SURE?')) {
                const backorders = this.state.entries.filter((entry) => !entry.scannedAt).map((entry) => entry.parcelId)
                setParcelStatus(backorders, 'Backordered', null, false)
                    .then(() => this.setState({ showBackorderModal: false }, this.fetchData))
                    .catch(handleError)
            } else {
                this.setState({ showBackorderModal: false })
            }
        }
        const notScanned = this.state.entries.filter((entry) => !entry.scannedAt)
        if (notScanned.length === 0) {
            return null
        }
        return (
            <Modal
                bsSize="large"
                show={this.state.showBackorderModal}
                onHide={close}
                container={this}
                aria-labelledby="contained-modal-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title">Backorder ({notScanned.length} parcels)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <strong>Merchant</strong>
                                </th>
                                <th>
                                    <strong>Parcel</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Consumer</strong>
                                </th>
                                <th>
                                    <strong>Latest Scan</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ color: 'white' }}>{notScanned.map(renderTableRow)}</tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="danger" disabled={this.getMissing() === 0} onClick={backorder}>
                        Backorder
                    </Button>
                    <Button onClick={close}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { entries, route } = this.state

        if (route === null) {
            return null
        }

        const show = () => this.setState({ showBackorderModal: true })
        let percentage = Math.round((this.getSorted() / entries.length) * 100)
        percentage = percentage > 100 ? 100 : percentage
        return (
            <Grid fluid>
                {this.renderBackorderModal()}
                <Col md={10}>
                    <p>
                        <b>
                            Route: {route.title} (#{route.id}) - Lane: {this.getLane()}
                        </b>
                    </p>
                    <p>Number of Parcels: {entries.length}</p>
                    <p>
                        <span style={{ color: 'white', backgroundColor: '#EBC944' }}>Scanned: </span>
                        {this.getScanned()}
                    </p>
                    <p>
                        <span style={{ color: 'white', backgroundColor: '#A3B86C' }}>Sorted onto cage: </span>
                        {this.getSorted()}
                    </p>
                    <p>
                        <span style={{ color: 'white', backgroundColor: '#C02F1D' }}>Missing: </span>
                        {this.getMissing()}
                    </p>
                    <p>
                        <b>Completed: {percentage}%</b>
                    </p>
                    <Button bsStyle="danger" disabled={this.getMissing() === 0} onClick={show}>
                        Backorder {this.getMissing()} missing parcels in this Route
                    </Button>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <strong>Merchant</strong>
                                </th>
                                <th>
                                    <strong>Parcel</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Consumer</strong>
                                </th>
                                <th>
                                    <strong>Latest Scan</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody style={{ color: 'white' }}>
                            {entries.sort(getSortingPredicate).map(renderTableRow)}
                        </tbody>
                    </table>
                </Col>
            </Grid>
        )
    }
}

RouteSortingView.propTypes = {
    params: PropTypes.object,
}
