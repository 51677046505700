import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('return-pallet-groups')

export const getReturnPalletGroups = () => webapi().get()

export const createReturnPalletGroup = (name) => webapi().post({ name })

export const removeReturnPalletGroup = (groupId) =>
    webapi()
        .path('groups')
        .path(groupId)
        .delete()

export const addMerchantToReturnPalletGroup = (groupId, merchantId) =>
    webapi()
        .path('groups')
        .path(groupId)
        .path('merchant')
        .path(merchantId)
        .put()

export const removeMerchantFromReturnPalletGroup = (groupId, merchantId) =>
    webapi()
        .path('groups')
        .path(groupId)
        .path('merchant')
        .path(merchantId)
        .delete()

export const setMerchantGroupMasterMerchant = (groupId, merchantId) =>
    webapi()
        .path('groups')
        .path(groupId)
        .path('master')
        .put({ masterMerchantId: merchantId })
