import { LOADING_SLOTS_LOADING, LOADING_SLOTS_LOADED, LOADING_SLOTS_FAILED_TO_LOAD } from '../constants/actionTypes'

import loadingSchedulerState from './initialState/loading-scheduler-state'

export default function loadingSchedulerReducer(state = loadingSchedulerState, action) {
    switch (action.type) {
        case LOADING_SLOTS_LOADING:
            return {
                ...state,
                isFetching: true,
            }
        case LOADING_SLOTS_LOADED:
            return {
                ...state,
                isFetching: false,
                slots: [...action.response.data],
                error: {
                    status: '',
                    statusText: '',
                },
            }
        case LOADING_SLOTS_FAILED_TO_LOAD:
            return {
                ...state,
                isFetching: false,
                error: {
                    status: action.status,
                    error: action.statusText,
                },
            }
        default:
            return state
    }
}
