import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Map from './map/map'
import { getStopPositionsForRoute } from '../utils/route-webapi'
import { handleError } from '../utils/handle-error'

export default class AdminMapHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayEstimatedRoute: true,
            markers: [],
            estimatedRouteCoordinates: [],
            height: window.innerHeight,
            width: window.innerWidth,
            color: '#f8f',
        }
    }

    updateMarkers = (stops) => {
        if (stops == null) {
            return
        }

        const markers = this.getMarkers(stops)
        this.setState({ markers })

        const birdRoute = markers.map(({ coordinate: { latitude, longitude } }) => [longitude, latitude])

        this.setState({ markers, estimatedRouteCoordinates: birdRoute })
    }

    getMarkers = (stops) => {
        return stops
            .map((stop) => {
                return this.stopMarker(stop)
            })
            .filter((x) => x != null)
    }

    stopMarker = (stop) => {
        const { color } = this.state
        if (stop && stop.address) {
            return {
                sortOrder: stop.sequence,
                coordinate: stop.address.coordinate,
                color,
                label: stop.sequence,
            }
        }
        return null
    }

    mapLoaded = () => {
        if (this.props.params && this.props.params.routeId) {
            getStopPositionsForRoute(this.props.params.routeId)
                .then((res) => this.updateMarkers(res.stops))
                .catch((e) => handleError(e))
        }
    }

    render() {
        const { markers, displayEstimatedRoute, estimatedRouteCoordinates } = this.state

        return (
            <div style={{ width: this.state.width, height: this.state.height }}>
                <Map
                    onLoad={this.mapLoaded}
                    markers={markers}
                    displayPolyline={displayEstimatedRoute}
                    displayHeatmap={false}
                    polylineCoordinates={estimatedRouteCoordinates}
                    heatmapCoordinates={[]}
                    zoomToLocation={null}
                />
            </div>
        )
    }
}

AdminMapHandler.propTypes = {
    params: PropTypes.object,
}
