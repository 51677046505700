import React, { Component } from 'react'
import { ControlLabel, FormGroup, Col, FormControl, Button, Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { updateMerchantCollectionPoint } from '../../../utils/merchant-webapi'
import { handleError } from '../../../utils/handle-error'

const Message = ({ type, children }) => <p className={`${type} edit-collection-point-message`}>{children}</p>

Message.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
}

export default class EditCollectionPoint extends Component {
    constructor(props) {
        super(props)
        const { collectionPoint } = this.props
        const { name, referencePerson, phoneNumber } = collectionPoint

        this.state = {
            name,
            referencePerson,
            phoneNumber,
            error: false,
            saving: false,
            saved: false,
        }
    }

    onInputChange = (event) => this.setState({ [event.target.name]: event.target.value })

    onSuccess = () => this.setState({ saving: false, saved: true })

    onError = (response) => {
        this.setState({ error: true, saving: false })
        handleError(response)
    }

    onSubmit = (event) => {
        event.preventDefault()
        this.setState({ error: false, saving: true, saved: false })
        const { merchantId, collectionPoint } = this.props
        const { name, referencePerson, phoneNumber } = this.state
        const payload = { name, referencePerson, phoneNumber }
        updateMerchantCollectionPoint(merchantId, collectionPoint.id, payload)
            .then(this.onSuccess)
            .catch(this.onError)
    }

    render() {
        const { name, referencePerson, phoneNumber, error, saving, saved } = this.state
        return (
            <div className="edit-collection-point">
                <Form horizontal onSubmit={this.onSubmit}>
                    <FormGroup controlId="name" validationState={name.length > 0 ? null : 'error'}>
                        <Col componentClass={ControlLabel} sm={2}>
                            Name
                        </Col>
                        <Col sm={8}>
                            <FormControl name="name" placeholder="Name" value={name} onChange={this.onInputChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        controlId="referencePerson"
                        validationState={referencePerson.length > 0 ? null : 'error'}
                    >
                        <Col componentClass={ControlLabel} sm={2}>
                            Reference Person
                        </Col>
                        <Col sm={8}>
                            <FormControl
                                name="referencePerson"
                                placeholder="Reference person"
                                value={referencePerson}
                                onChange={this.onInputChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="phoneNumber" validationState={phoneNumber.length > 0 ? null : 'error'}>
                        <Col componentClass={ControlLabel} sm={2}>
                            Telephone Number
                        </Col>
                        <Col sm={8}>
                            <FormControl
                                name="phoneNumber"
                                placeholder="Phone number"
                                value={phoneNumber}
                                onChange={this.onInputChange}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col smOffset={2} sm={10}>
                            <Button bsStyle="primary" bsSize="small" type="submit" disabled={saving}>
                                {saving ? 'Saving...' : 'Save'}
                            </Button>
                            {error && <Message type="text-danger">Unable to save</Message>}
                            {saved && <Message type="text-success">Saved</Message>}
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

EditCollectionPoint.propTypes = {
    collectionPoint: PropTypes.object.isRequired,
    merchantId: PropTypes.number.isRequired,
}
