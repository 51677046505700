import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Table, Checkbox } from 'react-bootstrap'
import moment from 'moment'
import cx from 'classnames'
import { OnRouteDelivery, Delivered, Miss } from '../../utils/status'

const TERMINAL = 'terminal'
const CONSUMER = 'consumer'
const CROSSDOCK = 'crossdock'
const GARAGE = 'garage'
const REST = 'rest'

export default class RouteTable extends Component {
    static propTypes = {
        route: PropTypes.object.isRequired,
        onSelect: PropTypes.func.isRequired,
    }

    state = {
        selected: [],
    }

    onSelect(stop, e) {
        const { checked } = e.target
        const selected = checked ? [...this.state.selected, stop] : this.state.selected.filter((s) => s.id !== stop.id)

        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    date = (date) => {
        const d = moment(date)
        return d.isSame(moment(), 'day') ? d.format('YYYY-MM-DD HH:mm') : d.format('HH:mm')
    }

    eta = (eta) => {
        const start = moment(eta.startTimestamp.date)
        const stop = moment(eta.stopTimestamp.date)

        if (start.isSame(moment(), 'day')) {
            return `${start.format('HH:mm')} - ${stop.format('HH:mm')}`
        }

        return `${start.format('YYYY-MM-DD HH:mm')} - ${stop.format('HH:mm')}`
    }

    address = (address) => `${address.street}, ${address.postalCode} ${address.city}`

    renderTerminalStopTableRow = (stop) => (
        <tr key={stop.id}>
            <td>
                <Checkbox disabled />
            </td>
            <td>{stop.sequence}</td>
            <td>Warehouse</td>
            <td>{stop.consignments.length} deliveries</td>
            <td />
            <td>{this.eta(stop.estimatedInterval)}</td>
            <td />
            <td />
            <td />
            <td>{this.address(stop.address)}</td>
        </tr>
    )

    renderConsumerStopTableRow = (stop, consignment) => {
        const { order } = consignment

        const statusUpdate = consignment.parcelStatusUpdates
            ? consignment.parcelStatusUpdates[consignment.parcelStatusUpdates.length - 1]
            : null

        const delivered = statusUpdate && statusUpdate.status === Delivered

        const classNames = {
            info: statusUpdate && statusUpdate.status === OnRouteDelivery,
            success: delivered,
            danger: statusUpdate && statusUpdate.status === Miss,
        }

        classNames['text-muted'] = consignment.cancellation

        return (
            <tr key={consignment.id} className={cx(classNames)}>
                <td>
                    <Checkbox
                        checked={this.state.selected.some((s) => s.id === stop.id)}
                        onChange={this.onSelect.bind(this, stop)}
                    />
                </td>
                <td>{stop.sequence}</td>
                <td>{order.buyer.name}</td>
                <td>
                    <Link to={`/admin/orders/${order.token}`}>{order.token}</Link>
                </td>
                <td>{statusUpdate && statusUpdate.status}</td>
                <td>{this.eta(stop.estimatedInterval)}</td>
                <td>{delivered ? this.date(statusUpdate.date) : null}</td>
                <td>{order.endCustomer.name}</td>
                <td>{order.endCustomer.phoneNumber}</td>
                <td>{this.address(stop.address)}</td>
            </tr>
        )
    }

    renderCrossdockStopTableRow = (stop) => (
        <tr key={stop.id}>
            <td>
                <Checkbox disabled />
            </td>
            <td>{stop.sequence}</td>
            <td>Meetup</td>
            <td>{stop.consignments.length} deliveries</td>
            <td />
            <td>{this.eta(stop.estimatedInterval)}</td>
            <td />
            <td />
            <td />
            <td>{this.address(stop.address)}</td>
        </tr>
    )

    renderRestStopTableRow = (stop) => (
        <tr key={stop.id}>
            <td>
                <Checkbox disabled />
            </td>
            <td>{stop.sequence}</td>
            <td>Rest</td>
            <td />
            <td />
            <td>{this.eta(stop.estimatedInterval)}</td>
            <td />
            <td />
            <td />
            <td>{this.address(stop.address)}</td>
        </tr>
    )

    renderGarageStopTableRow = (stop) => (
        <tr key={stop.id}>
            <td>
                <Checkbox disabled />
            </td>
            <td>{stop.sequence}</td>
            <td>Garage</td>
            <td />
            <td />
            <td>{this.eta(stop.estimatedInterval)}</td>
            <td />
            <td />
            <td />
            <td>{this.address(stop.address)}</td>
        </tr>
    )

    renderTableRow = (stop, consignment) => {
        switch (stop.type) {
            case TERMINAL:
                return this.renderTerminalStopTableRow(stop)
            case CONSUMER:
                return this.renderConsumerStopTableRow(stop, consignment)
            case CROSSDOCK:
                return this.renderCrossdockStopTableRow(stop)
            case GARAGE:
                return this.renderGarageStopTableRow(stop)
            case REST:
                return this.renderRestStopTableRow(stop)
            default:
                return null
        }
    }

    renderTableRows = (terminalStops, consumerStops, crossdockStops, garageStops, restStops) => {
        terminalStops.forEach((ts) => (ts.type = TERMINAL))
        consumerStops.forEach((cs) => (cs.type = CONSUMER))
        crossdockStops.forEach((cds) => (cds.type = CROSSDOCK))
        garageStops.forEach((gs) => (gs.type = GARAGE))
        restStops.forEach((gs) => (gs.type = REST))

        const stops = terminalStops.concat(consumerStops, crossdockStops, garageStops, restStops)
        stops.sort((a, b) => a.sequence - b.sequence)

        return stops.map((s) => {
            if (s.type === CONSUMER) {
                return s.consignments.map((sc) => this.renderTableRow(s, sc.consignment))
            }
            return this.renderTableRow(s)
        })
    }

    render() {
        const { terminalStops, consumerStops, crossdockStops, garageStop, restStops } = this.props.route
        const garageStops = []
        if (garageStop) {
            garageStops.push(garageStop)
        }

        return (
            <Table striped responsive>
                <thead>
                    <tr>
                        <th />
                        <th>#</th>
                        <th>Merchant</th>
                        <th>Order</th>
                        <th>Status</th>
                        <th>ETA</th>
                        <th>Delivered</th>
                        <th>Consumer</th>
                        <th>Telephone Number</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableRows(terminalStops, consumerStops, crossdockStops, garageStops, restStops)}
                </tbody>
            </Table>
        )
    }
}
