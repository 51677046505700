import React, { Component } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { FormControl, FormGroup, ControlLabel, Button, InputGroup } from 'react-bootstrap'
import empty from '../../../utils/empty'
import CountrySelect from '../../common/country-select'
import Alert from '../../alert'
import GeocodingApi from '../../../utils/geocoding-api'
import TerminalSelect from '../../common/terminal-select'

const typeOptions = [
    { value: 'SENDER_ADDRESS', label: 'Sender address' },
    { value: 'RETURN_ADDRESS', label: 'Return address' },
]

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: typeOptions[0].value,
            name: '',
            contactPerson: '',
            contactPhoneNumber: '',
            street: '',
            postalCode: '',
            city: '',
            countryCode: '',
            latitude: '',
            longitude: '',
            geocoding: false,
            geocodingError: null,
            creating: false,
            injectionTerminal: null,
            orderFulfillmentTimeHours: '',
            orderFulfillmentTimeMinutes: '',
        }
    }

    onChange = (type, value) => this.setState({ [type]: value })

    getCollectionPoint() {
        const {
            type,
            name,
            contactPerson,
            contactPhoneNumber,
            street,
            postalCode,
            city,
            countryCode,
            latitude,
            longitude,
            injectionTerminal,
            orderFulfillmentTimeHours,
            orderFulfillmentTimeMinutes,
        } = this.state

        return {
            type,
            name,
            referencePerson: contactPerson,
            phoneNumber: contactPhoneNumber,
            address: {
                street,
                postalCode,
                city,
                countryCode,
                coordinate: {
                    latitude: parseFloat(latitude),
                    longitude: parseFloat(longitude),
                },
            },
            injectionTerminal,
            orderFulfillmentTime:
                parseInt(orderFulfillmentTimeHours, 10) * 3600 + parseInt(orderFulfillmentTimeMinutes, 10) * 60,
        }
    }

    validateAddress = () => {
        const { street, postalCode, city, countryCode } = this.state
        return [street, postalCode, city, countryCode].every((v) => !empty(v))
    }

    geocode = () => {
        const collectionPoint = this.getCollectionPoint()
        const { address } = collectionPoint

        this.setState({ geocoding: true })

        GeocodingApi.geocode(address.street, address.postalCode, address.city, address.countryCode)
            .then((result) => {
                const { building } = result
                this.setState({
                    latitude: building.coordinate.latitude,
                    longitude: building.coordinate.longitude,
                    geocoding: false,
                    geocodingError: null,
                })
            })
            .catch(() => {
                this.setState({
                    geocoding: false,
                    geocodingError: 'Geocoding failed, please supply coordinates manually',
                })
            })
    }

    create = () => {
        this.setState({ creating: true })
        this.props
            .onSubmit(this.getCollectionPoint())
            .then((response) => {
                if (!response.ok) {
                    this.setState({ creating: false })
                }
            })
            .catch(() => {
                this.setState({ creating: false })
            })
    }

    render() {
        return (
            <form>
                <fieldset>
                    <legend>Create Merchant Warehouse address</legend>
                    <FormGroup>
                        <ControlLabel>Warehouse Type</ControlLabel>
                        <Select
                            value={this.state.type}
                            options={typeOptions}
                            clearable={false}
                            onChange={(e) => this.onChange('type', e.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Merchant Warehouse name</ControlLabel>
                        <FormControl type="text" autoFocus onChange={(e) => this.onChange('name', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Contact Person</ControlLabel>
                        <FormControl type="text" onChange={(e) => this.onChange('contactPerson', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Contact Telephone number</ControlLabel>
                        <FormControl type="tel" onChange={(e) => this.onChange('contactPhoneNumber', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Street</ControlLabel>
                        <FormControl type="text" onChange={(e) => this.onChange('street', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Postal Code</ControlLabel>
                        <FormControl type="text" onChange={(e) => this.onChange('postalCode', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>City</ControlLabel>
                        <FormControl type="text" onChange={(e) => this.onChange('city', e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Country</ControlLabel>
                        <CountrySelect onSelect={(country) => this.onChange('countryCode', country.value)} />
                    </FormGroup>

                    <Button onClick={this.geocode} disabled={!this.validateAddress()} style={{ marginBottom: 10 }}>
                        Geocode address
                    </Button>

                    {this.state.geocodingError && <Alert type="danger" message={this.state.geocodingError} />}

                    <FormGroup>
                        <ControlLabel>Latitude</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.latitude}
                            disabled={this.state.geocoding}
                            onChange={(e) => this.onChange('latitude', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Longitude</ControlLabel>
                        <FormControl
                            type="text"
                            value={this.state.longitude}
                            disabled={this.state.geocoding}
                            onChange={(e) => this.onChange('longitude', e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Injection terminal</ControlLabel>
                        <TerminalSelect
                            value={this.state.injectionTerminal}
                            onSelect={(selectedTerminal) =>
                                this.onChange(
                                    'injectionTerminal',
                                    selectedTerminal == null ? null : selectedTerminal.code,
                                )
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Order fulfillment time</ControlLabel>
                        <div>
                            <InputGroup style={{ width: 150, display: 'inline-table', marginRight: 10 }}>
                                <FormControl
                                    type="number"
                                    value={this.state.orderFulfillmentTimeHours}
                                    onChange={(e) => this.onChange('orderFulfillmentTimeHours', e.target.value)}
                                />
                                <InputGroup.Addon>h</InputGroup.Addon>
                            </InputGroup>
                            <InputGroup style={{ width: 150, display: 'inline-table' }}>
                                <FormControl
                                    type="number"
                                    value={this.state.orderFulfillmentTimeMinutes}
                                    onChange={(e) => this.onChange('orderFulfillmentTimeMinutes', e.target.value)}
                                />
                                <InputGroup.Addon>m</InputGroup.Addon>
                            </InputGroup>
                        </div>
                    </FormGroup>
                </fieldset>

                <FormGroup>
                    <Button
                        bsStyle="primary"
                        disabled={!this.props.validate(this.getCollectionPoint()) || this.state.creating}
                        onClick={this.create}
                    >
                        Create
                    </Button>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                </FormGroup>
            </form>
        )
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
}
