export default {
    maintainAspectRatio: false,
    legend: { position: 'top' },
    layout: {
        padding: { top: 40 },
    },
    scales: {
        xAxes: [
            {
                type: 'time',
                distribution: 'linear',
                time: {
                    unit: 'hour',
                    displayFormats: { hour: 'HH:mm' },
                },
                ticks: {
                    padding: 20,
                    fontColor: '#E6E6E6',
                },
                gridLines: {
                    display: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    padding: 20,
                    fontColor: '#E6E6E6',
                },
                gridLines: {
                    display: true,
                    drawBorder: false,
                    drawTicks: false,
                    zeroLineWidth: 1,
                    zeroLineColor: '#E6E6E6',
                    color: 'transparent',
                },
            },
        ],
    },
    tooltips: {
        backgroundColor: '#FFF',
        borderColor: '#448AFF',
        borderWidth: 2,
        titleFontSize: 16,
        titleFontColor: '#448AFF',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        footerFontColor: '#000',
        displayColors: false,
        intersect: false,
        xPadding: 15,
        yPadding: 15,
    },
}
