import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    FormControl,
    FormGroup,
    MenuItem,
    DropdownButton,
    ControlLabel,
    Alert,
    Label,
    Button,
    Checkbox,
} from 'react-bootstrap'

const optionsArray = () => {
    const arr = []

    for (let i = -10; i <= 30; i += 1) {
        arr.push({ label: i, value: i })
    }

    const option = arr.map((o) => (
        <option key={o.value} value={o.value}>
            {o.label}
        </option>
    ))

    return option
}

const canNotSubmit = (address) => address.street === '' || address.city === '' || address.postalCode === ''

const validate = (value) => {
    const trimmedValue = value.trim()
    if (trimmedValue.length === 0) {
        return 'error'
    }
    return 'success'
}

/**
 * Render an Address with it's Settings
 */
export default class Address extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            isEditingAddress: false,
            isEditingCoordinates: false,
        }
    }

    onFormChange = (key, e) => this.props.onDeliveryAddressChange(key, e)

    onFormToggle = (name, state) => this.setState({ [name]: !state })

    onFormSave = (name, state) => {
        this.props.onEditAddressSave()
        this.onFormToggle(name, state)
    }

    renderErrorMsg() {
        if (!this.props.showError) {
            return null
        }

        return (
            <div style={{ margin: '1em 0' }}>
                <Alert bsStyle="danger">
                    <h4>Error! Something went wrong</h4>
                    <p>Please try again. If the problem persists, contact tech.</p>
                </Alert>
            </div>
        )
    }

    renderEditForm(address) {
        if (!this.state.isEditingAddress) return null

        return (
            <div>
                <fieldset>
                    <ControlLabel>Street *</ControlLabel>
                    <FormGroup validationState={validate(address.street)}>
                        <FormControl
                            type="text"
                            required
                            label="Street"
                            placeholder="Street"
                            value={address.street}
                            onChange={(e) => this.onFormChange('street', e)}
                        />
                    </FormGroup>
                    <ControlLabel>C/O</ControlLabel>
                    <FormGroup>
                        <FormControl
                            type="text"
                            required
                            label="C/O"
                            placeholder="C/O"
                            value={address.street2}
                            onChange={(e) => this.onFormChange('street2', e)}
                        />
                    </FormGroup>
                    <ControlLabel>Postal Code *</ControlLabel>
                    <FormGroup validationState={validate(address.postalCode)}>
                        <FormControl
                            type="text"
                            required
                            label="Postal Code"
                            placeholder="Postal Code"
                            value={address.postalCode}
                            onChange={(e) => this.onFormChange('postalCode', e)}
                        />
                    </FormGroup>
                    <ControlLabel>City *</ControlLabel>
                    <FormGroup validationState={validate(address.city)}>
                        <FormControl
                            type="text"
                            required
                            label="City"
                            placeholder="City"
                            value={address.city}
                            onChange={(e) => this.onFormChange('city', e)}
                        />
                    </FormGroup>
                    <ControlLabel>Door Code</ControlLabel>
                    <FormGroup>
                        <FormControl
                            type="text"
                            label="Door code"
                            placeholder="Door code"
                            value={address.settings.doorCode || ''}
                            onChange={(e) => this.onFormChange('doorCode', e)}
                        />
                    </FormGroup>
                    <ControlLabel>Floor</ControlLabel>
                    <FormControl
                        componentClass="select"
                        defaultValue={address.settings.floor}
                        onChange={(e) => this.onFormChange('floor', e)}
                    >
                        {optionsArray()}
                    </FormControl>
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            className="pull-right"
                            bsSize="small"
                            disabled={canNotSubmit(address)}
                            bsStyle="primary"
                            onClick={() => this.onFormSave('isEditingAddress', this.state.isEditingAddress)}
                        >
                            {this.props.submitting ? 'Saving...' : 'Save'}
                        </Button>
                        <Button
                            className="pull-right"
                            bsSize="small"
                            onClick={() => this.onFormToggle('isEditingAddress', this.state.isEditingAddress)}
                        >
                            Cancel
                        </Button>
                    </div>
                </fieldset>
            </div>
        )
    }

    renderCoordinatesForm(address) {
        if (!this.state.isEditingCoordinates) return null

        let latitude = address && address.coordinate ? address.coordinate.latitude : null
        let longitude = address && address.coordinate ? address.coordinate.longitude : null
        let navigationLatitude = address && address.navigationCoordinate ? address.navigationCoordinate.latitude : null
        let navigationLongitude =
            address && address.navigationCoordinate ? address.navigationCoordinate.longitude : null

        if (Number.isNaN(latitude)) {
            latitude = null
        }

        if (Number.isNaN(longitude)) {
            longitude = null
        }

        if (Number.isNaN(navigationLatitude)) {
            navigationLatitude = null
        }

        if (Number.isNaN(navigationLongitude)) {
            navigationLongitude = null
        }

        return (
            <div>
                <fieldset>
                    <ControlLabel>Delivery Coordinate</ControlLabel>
                    <FormGroup>
                        <FormControl
                            type="number"
                            required
                            label="Latitude"
                            placeholder="Latitude"
                            value={latitude}
                            onChange={(e) => this.onFormChange('latitude', e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            type="number"
                            required
                            label="Longitude"
                            placeholder="Longitude"
                            value={longitude}
                            onChange={(e) => this.onFormChange('longitude', e)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Checkbox
                            defaultChecked={false}
                            onChange={(e) => this.onFormChange('saveCoordinatesForThisAddress', e)}
                            inline
                        >
                            {' '}
                            Apply coordinates to all future deliveries on this address
                        </Checkbox>

                        {this.props.showWillSaveCoordinatesWarning && (
                            <Alert bsStyle="warning">
                                <strong>Warning!</strong> Editing coordinates will effect all future occurrences of this
                                address. <strong>Be careful</strong>
                            </Alert>
                        )}
                    </FormGroup>
                    <Button
                        className="pull-right"
                        bsSize="small"
                        disabled={canNotSubmit(address)}
                        bsStyle="primary"
                        onClick={() => this.onFormSave('isEditingCoordinates', this.state.isEditingCoordinates)}
                    >
                        {this.props.submitting ? 'Saving...' : 'Save'}
                    </Button>
                    <Button
                        className="pull-right"
                        bsSize="small"
                        onClick={() => this.onFormToggle('isEditingCoordinates', this.state.isEditingCoordinates)}
                    >
                        Cancel
                    </Button>
                </fieldset>
            </div>
        )
    }

    renderDoorCode = (settings) => {
        if (settings.hasDoorCode === false) {
            return <Label bsStyle="danger">No Doorcode needed</Label>
        }

        return settings.doorCode ? settings.doorCode : 'N/A'
    }

    renderFloor = (floor) => {
        if (floor === null) {
            return 'N/A'
        }

        if (floor === 0) {
            return 'Bottom floor'
        }

        return floor
    }

    renderSpecialInstructions = (specialInstructions) => {
        if (specialInstructions === null) {
            return 'N/A'
        }

        if (!specialInstructions || specialInstructions.trim().length === 0) {
            return '(Empty)'
        }

        return <pre style={{ marginTop: 0, fontFamily: 'sans-serif' }}>{specialInstructions}</pre>
    }

    renderAddress(address) {
        if (this.state.isEditingAddress || this.state.isEditingCoordinates) {
            return null
        }

        return (
            <div>
                <strong>Street:</strong> {address.street} <br />
                <strong>C/O:</strong> {address.street2 ? address.street2 : 'N/A'} <br />
                <strong>Postal code:</strong> {address.postalCode} <br />
                <strong>City:</strong> {address.city} <br />
                <strong>Country:</strong> {address.countryCode} <br />
                {address.settings != null ? (
                    <div>
                        <br />
                        {!this.props.isLockerAddress ? (
                            <div>
                                <strong>Door code:</strong> {this.renderDoorCode(address.settings)} <br />
                                <strong>Floor:</strong> {this.renderFloor(address.settings.floor)} <br />
                            </div>
                        ) : null}
                        {!this.props.isMerchantAddress ? (
                            <div>
                                <strong>Special Instructions:</strong>&nbsp;
                                {this.renderSpecialInstructions(address.settings.specialInstructions)} <br />
                            </div>
                        ) : null}
                        <br />
                    </div>
                ) : null}
                {this.props.editable && (
                    <DropdownButton id="edit-address" className="dropdown-menu-right" title="Edit">
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    isEditingAddress: true,
                                    isEditingCoordinates: false,
                                })
                            }
                            eventKey="1"
                        >
                            Change Address
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                this.setState({
                                    isEditingAddress: false,
                                    isEditingCoordinates: true,
                                })
                            }
                            eventKey="2"
                        >
                            Edit Coordinates
                        </MenuItem>
                    </DropdownButton>
                )}
            </div>
        )
    }

    render() {
        const { address } = this.props

        let renderedContent = this.renderAddress(address)
        if (!address) {
            return null
        }

        if (this.state.isEditingAddress) {
            renderedContent = this.renderEditForm(address)
        }

        if (this.state.isEditingCoordinates) {
            renderedContent = this.renderCoordinatesForm(address)
        }

        return (
            <div>
                {this.props.header && <h4 style={this.props.headerStyle}>{this.props.header}</h4>}
                {renderedContent}
                {this.renderErrorMsg()}
            </div>
        )
    }
}

Address.propTypes = {
    isMerchantAddress: PropTypes.bool,
    isLockerAddress: PropTypes.bool,
    address: PropTypes.object,
    header: PropTypes.string,
    editable: PropTypes.bool,
    submitting: PropTypes.bool,
    headerStyle: PropTypes.object,
    onDeliveryAddressChange: PropTypes.func,
    onEditAddressSave: PropTypes.func,
    showError: PropTypes.bool,
    showWillSaveCoordinatesWarning: PropTypes.bool,
}
