import React, { Component } from 'react'
import {
    Modal,
    Button,
    Form as BsForm,
    FormGroup,
    Col,
    ControlLabel,
    ToggleButtonGroup,
    ToggleButton,
    Row,
    Checkbox,
    FormControl,
    HelpBlock,
    Alert,
    Glyphicon,
    InputGroup,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import * as actions from '../../../actions/couriers'
import CitySelect from '../../common/city-select'
import TimeControl from './time-control'
import DriverLevelSelect from '../../common/driver-level-select'
import {
    COMPENSATION_GROUPS,
    DISTANCE,
    getTimeWithSeconds,
    MIN_HOURS_PER_ROUTE,
    PRICE_TYPES,
    VEHICLE_INSPECTION_TIME_PER_ROUTE,
    PER_CONSUMER_STOP,
} from '../../../utils/couriers'
import TierPreview from './tier-preview'
import { DATE_FORMAT } from '../../../utils/date'
import routeTypes, { DISTRIBUTION, LOCKER, ON_DEMAND } from '../../../utils/route-types'
import VehicleTypes from '../../../utils/vehicle-types'

const routeTypeOptions = routeTypes()
const vehicleTypes = VehicleTypes()
const EVERY_DAY = 'All days'
const ALL_TIMES = {
    hours: null,
    minutes: null,
}
const DAYS_OF_WEEK = [EVERY_DAY, 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
const CURRENCIES = ['DKK', 'SEK', 'EUR', 'NOK']
const parseLocalTime = (localTime) => {
    if (localTime == null) {
        return {
            ...ALL_TIMES,
        }
    }

    return {
        hours: localTime[0] || '00',
        minutes: localTime[1] || '00',
    }
}
const checkboxStyles = { marginTop: '7px' }
const setDaysOfWeek = (tier) => {
    if (tier.specialDay) {
        return []
    }

    if (tier.daysOfWeek == null) {
        return [EVERY_DAY]
    }

    return tier.daysOfWeek
}

class CompensationTierModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cities: [],
            allCitiesApply: true,
            daysOfWeek: [EVERY_DAY],
            specialDay: false,
            validFrom: null,
            validTo: null,
            focusedInput: null,
            from: {
                ...ALL_TIMES,
            },
            to: {
                ...ALL_TIMES,
            },
            lowerBound: null,
            upperBound: null,
            allTimesApply: true,
            driverLevels: [],
            allDriverLevelsApply: true,
            rate: '',
            currency: '',
            routeType: DISTRIBUTION,
            vehicleType: null,
        }
    }

    componentDidMount = () => {
        if (this.props.existingTier == null) {
            return
        }
        const existingTier = {
            ...this.props.existingTier,
        }

        const existingRate =
            existingTier.incrementalPriceInCents === 0
                ? existingTier.fixedPriceInCents / 100
                : existingTier.incrementalPriceInCents / 100

        this.setState({
            ...existingTier,
            rate: existingRate,
            daysOfWeek: setDaysOfWeek(existingTier),
            from: parseLocalTime(existingTier.timeOfDayStart),
            to: parseLocalTime(existingTier.timeOfDayStop),
            routeType: existingTier.routeType,
            vehicleType: existingTier.vehicleType,
            allCitiesApply: existingTier.cities == null || !existingTier.cities.length,
            allDriverLevelsApply: existingTier.driverLevels == null || !existingTier.driverLevels.length,
            allTimesApply: existingTier.timeOfDayStart == null && existingTier.timeOfDayStop == null,
            validFrom: existingTier.validFrom == null ? null : moment(existingTier.validFrom, 'YYYY-MM-DD'),
            validTo: existingTier.validTo == null ? null : moment(existingTier.validTo, 'YYYY-MM-DD'),
            cities: existingTier.cities == null ? null : existingTier.cities.map((x) => ({ value: x, label: x })),
            driverLevels:
                existingTier.driverLevels == null
                    ? null
                    : existingTier.driverLevels.map((x) => ({ value: x, label: `Level ${x}` })),
        })
    }

    getValidationState = () => (!this.isValid() && this.state.dirty ? 'error' : null)

    isValid = () => {
        const specialDayValid = this.state.validFrom !== null && this.state.validTo !== null
        return (
            this.state.rate !== '' &&
            this.state.currency &&
            (this.state.specialDay === false || (this.state.specialDay && specialDayValid))
        )
    }

    handleCitiesChange = (cities) => this.setState({ cities })

    toggleAllCities = () => {
        this.setState((state) => ({
            cities: [],
            allCitiesApply: !state.allCitiesApply,
        }))
    }

    handleDaysChange = (days) => {
        let updatedDaysOfWeek = days

        if (days.includes(EVERY_DAY)) {
            if (this.state.daysOfWeek.includes(EVERY_DAY)) {
                updatedDaysOfWeek = days.filter((day) => day !== EVERY_DAY)
            } else {
                updatedDaysOfWeek = [EVERY_DAY]
            }
        }

        this.setState({ daysOfWeek: updatedDaysOfWeek })
    }

    toggleSpecialDay = () => {
        this.setState((state) => ({
            daysOfWeek: [EVERY_DAY],
            specialDay: !state.specialDay,
        }))
    }

    handleValidDateChange = ({ startDate, endDate }) => {
        this.setState({ validFrom: startDate, validTo: endDate })
    }

    handleFocusedValidDateChange = (focusedInput) => this.setState({ focusedInput })

    handleTimeChange = (name) => (time) => this.setState({ [name]: time })

    toggleAllTimes = () => {
        this.setState((state) => ({
            from: {
                ...ALL_TIMES,
            },
            to: {
                ...ALL_TIMES,
            },
            allTimesApply: !state.allTimesApply,
        }))
    }

    handleDriverLevelChange = (driverLevels) => this.setState({ driverLevels })

    toggleAllDriverLevels = () => {
        this.setState((state) => ({
            driverLevels: [],
            allDriverLevelsApply: !state.allDriverLevelsApply,
        }))
    }

    handleRateChange = (e) => this.setState({ rate: e.target.value })

    handleLowerBoundChangeMinHours = (e) => this.setState({ lowerBound: e.target.value, rate: 0, currency: 'SEK' })

    handleUpperBoundChange = (e) => this.setState({ upperBound: e.target.value })

    handleLowerBoundChange = (e) => this.setState({ lowerBound: e.target.value })

    handleCurrencyChange = (e) => this.setState({ currency: e.target.value })

    handleRouteTypeChange = (e) => this.setState({ routeType: e.target.value })

    handleVehicleTypeChange = (e) => {
        if (e.target.value !== '') {
            this.setState({ vehicleType: e.target.value })
        } else {
            this.setState({ vehicleType: null })
        }
    }

    handleError = (error) => this.setState({ error: error.status })

    dispatchAction = () =>
        this.props.existingTier == null
            ? this.props.actions.createCompensationTier
            : this.props.actions.updateCompensationTier

    handleSubmit = (e) => {
        e.preventDefault()

        if (!this.isValid()) {
            this.setState({ dirty: true })
            return
        }

        const { type, existingTier, courierId, onClose } = this.props
        const {
            validFrom,
            validTo,
            allCitiesApply,
            cities,
            allDriverLevelsApply,
            driverLevels,
            from,
            to,
            rate,
            currency,
            daysOfWeek,
            specialDay,
            lowerBound,
            upperBound,
            routeType,
            vehicleType,
        } = this.state

        const compensationTier = {
            validFrom: validFrom == null ? null : validFrom.format('YYYY-MM-DD'),
            validTo: validTo == null ? null : validTo.format('YYYY-MM-DD'),
            group: type.group,
            type: type.id,
            cities: allCitiesApply || !cities.length ? null : cities.map((x) => x.value),
            driverLevels: allDriverLevelsApply || !driverLevels.length ? null : driverLevels.map((x) => x.value),
            timeOfDayStart: getTimeWithSeconds(from),
            timeOfDayStop: getTimeWithSeconds(to),
            fixedPriceInCents: type.price_type === PRICE_TYPES.FIXED ? Number.parseFloat(rate).toFixed(2) * 100 : 0,
            incrementalPriceInCents:
                type.price_type === PRICE_TYPES.INCREMENTAL ? Number.parseFloat(rate).toFixed(2) * 100 : 0,
            currency,
            daysOfWeek: !daysOfWeek.length || daysOfWeek.includes(EVERY_DAY) ? null : daysOfWeek,
            specialDay,
            lowerBound: lowerBound ? Number.parseInt(lowerBound, 10) : null,
            upperBound: upperBound ? Number.parseInt(upperBound, 10) : null,
            routeType: routeType || DISTRIBUTION,
            vehicleType,
        }

        if (existingTier != null) {
            compensationTier.id = existingTier.id
        }

        this.dispatchAction()(courierId, compensationTier)
            .then(onClose(true))
            .catch(this.handleError)
    }

    renderDistanceRange = () => {
        const { type } = this.props
        const { lowerBound, upperBound } = this.state

        return (
            <FormGroup>
                <ControlLabel htmlFor="lowerBound">Choose the range of {type.price_variable}</ControlLabel>
                <Row>
                    <Col sm={2}>
                        <InputGroup>
                            <FormControl
                                id="lowerBound"
                                type="number"
                                min="0"
                                placeholder="0"
                                value={lowerBound === null ? '' : lowerBound}
                                onChange={this.handleLowerBoundChange}
                            />
                        </InputGroup>
                    </Col>
                    <div>
                        <Col className="text-center" sm={1}>
                            <b> up to </b>
                        </Col>
                        <Col sm={2}>
                            <InputGroup>
                                <FormControl
                                    id="upperBound"
                                    type="number"
                                    min="0"
                                    placeholder="Infinity"
                                    value={upperBound === null ? '' : upperBound}
                                    onChange={this.handleUpperBoundChange}
                                />
                            </InputGroup>
                        </Col>
                    </div>
                </Row>
            </FormGroup>
        )
    }

    renderRange = () => {
        const { type } = this.props
        const { from, to, allTimesApply, lowerBound, upperBound } = this.state

        if (type.group === COMPENSATION_GROUPS.BASE) {
            return (
                <div>
                    <FormGroup>
                        <ControlLabel htmlFor="fromHours">Choose the hours</ControlLabel>
                        <Row>
                            <Col sm={2}>
                                <TimeControl
                                    controlId="from"
                                    time={from}
                                    onChange={this.handleTimeChange('from')}
                                    placeholder={{ hours: '09', minutes: '00' }}
                                    disabled={allTimesApply}
                                    focusNextElementById="toHours"
                                />
                            </Col>
                            <Col className="text-center" sm={1} style={{ width: '5%', lineHeight: '34px' }}>
                                until
                            </Col>
                            <Col sm={2}>
                                <TimeControl
                                    controlId="to"
                                    time={to}
                                    onChange={this.handleTimeChange('to')}
                                    placeholder={{ hours: '24', minutes: '00' }}
                                    disabled={allTimesApply}
                                />
                            </Col>
                            <Col sm={7}>
                                <Checkbox
                                    defaultChecked={allTimesApply}
                                    onChange={this.toggleAllTimes}
                                    style={checkboxStyles}
                                >
                                    Apply this rate to all hours
                                </Checkbox>
                            </Col>
                        </Row>
                    </FormGroup>
                    {type.id === DISTANCE ? this.renderDistanceRange() : null}
                </div>
            )
        }
        if (
            type.group === COMPENSATION_GROUPS.BONUS ||
            type.id === MIN_HOURS_PER_ROUTE ||
            type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE
        ) {
            return (
                <FormGroup>
                    <ControlLabel htmlFor="lowerBound">Choose the range of {type.price_variable}</ControlLabel>
                    <Row>
                        <Col sm={2}>
                            <InputGroup>
                                <FormControl
                                    id="lowerBound"
                                    type="number"
                                    min="0"
                                    placeholder="0"
                                    value={lowerBound === null ? '' : lowerBound}
                                    onChange={
                                        type.id === MIN_HOURS_PER_ROUTE || type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE
                                            ? this.handleLowerBoundChangeMinHours
                                            : this.handleLowerBoundChange
                                    }
                                />
                            </InputGroup>
                        </Col>
                        {type.id === MIN_HOURS_PER_ROUTE || type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE ? null : (
                            <div>
                                <Col className="text-center" sm={1}>
                                    <b> up to </b>
                                </Col>
                                <Col sm={2}>
                                    <InputGroup>
                                        <FormControl
                                            id="upperBound"
                                            type="number"
                                            min="0"
                                            placeholder="Infinity"
                                            value={upperBound === null ? '' : upperBound}
                                            onChange={this.handleUpperBoundChange}
                                        />
                                    </InputGroup>
                                </Col>
                            </div>
                        )}
                    </Row>
                </FormGroup>
            )
        }
        if (type.group === COMPENSATION_GROUPS.FIXED) {
            return null
        }
        return null
    }

    render() {
        const { type, updating, onClose } = this.props
        const {
            focusedInput,
            error,
            allCitiesApply,
            cities,
            daysOfWeek,
            allTimesApply,
            from,
            to,
            allDriverLevelsApply,
            driverLevels,
            rate,
            currency,
            specialDay,
            validFrom,
            validTo,
            lowerBound,
            upperBound,
            routeType,
            vehicleType,
        } = this.state

        return (
            <Modal bsSize="lg" show onHide={onClose()}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-capitalize">{type.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BsForm>
                        <FormGroup controlId="cities">
                            <ControlLabel>Select the cities</ControlLabel>
                            <CitySelect
                                multi
                                value={cities}
                                onSelect={this.handleCitiesChange}
                                disabled={allCitiesApply}
                            />
                            <Checkbox defaultChecked={allCitiesApply} onChange={this.toggleAllCities}>
                                Apply this rate to all cities
                            </Checkbox>
                        </FormGroup>
                        <FormGroup controlId="days">
                            <Row>
                                <Col sm={12}>
                                    <ControlLabel>Choose the days</ControlLabel>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={9}>
                                    <ToggleButtonGroup
                                        type="checkbox"
                                        value={daysOfWeek}
                                        onChange={this.handleDaysChange}
                                    >
                                        {DAYS_OF_WEEK.map((day) => (
                                            <ToggleButton
                                                key={day}
                                                value={day}
                                                disabled={specialDay}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                {day.toLowerCase()}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                </Col>
                                <Col md={3}>
                                    <Checkbox
                                        checked={specialDay}
                                        onChange={this.toggleSpecialDay}
                                        style={checkboxStyles}
                                    >
                                        Special dates only
                                    </Checkbox>
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup controlId="validFromTo" validationState={this.getValidationState()}>
                            <ControlLabel>Valid interval</ControlLabel>
                            <Row>
                                <DateRangePicker
                                    startDate={validFrom}
                                    startDateId="validFrom"
                                    endDate={validTo}
                                    endDateId="validTo"
                                    onDatesChange={this.handleValidDateChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={this.handleFocusedValidDateChange}
                                    displayFormat={DATE_FORMAT}
                                    minimumNights={0}
                                    isOutsideRange={() => false}
                                />
                            </Row>
                            <HelpBlock>
                                A valid to and from is optional unless special dates only are specified
                            </HelpBlock>
                        </FormGroup>
                        {this.renderRange()}
                        <FormGroup controlId="driverLevel">
                            <ControlLabel>Select the driver levels</ControlLabel>
                            <Row>
                                <Col sm={6} md={4}>
                                    <DriverLevelSelect
                                        multi
                                        value={driverLevels}
                                        onSelect={this.handleDriverLevelChange}
                                        disabled={allDriverLevelsApply}
                                    />
                                </Col>
                                <Col sm={6} md={8}>
                                    <Checkbox
                                        defaultChecked={allDriverLevelsApply}
                                        onChange={this.toggleAllDriverLevels}
                                        style={checkboxStyles}
                                    >
                                        Apply this rate to all driver levels
                                    </Checkbox>
                                </Col>
                            </Row>
                            <FormGroup controlId="type">
                                <ControlLabel>Route Type</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={routeType}
                                    onChange={this.handleRouteTypeChange}
                                >
                                    {routeTypeOptions
                                        .filter((item) => {
                                            if (type.group === COMPENSATION_GROUPS.BASE) {
                                                if (type.id === PER_CONSUMER_STOP) {
                                                    return [DISTRIBUTION, ON_DEMAND].includes(item.value)
                                                }
                                                return [DISTRIBUTION, LOCKER, ON_DEMAND].includes(item.value)
                                            }
                                            return item.value === DISTRIBUTION
                                        })
                                        .map(({ value, label }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                </FormControl>
                            </FormGroup>
                            <FormGroup controlId="type">
                                <ControlLabel>Vehicle Type</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={vehicleType}
                                    onChange={this.handleVehicleTypeChange}
                                >
                                    <option key="ALL" value="">
                                        All vehicles
                                    </option>
                                    {vehicleTypes.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </FormControl>
                            </FormGroup>
                        </FormGroup>
                        {type.id === MIN_HOURS_PER_ROUTE || type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE ? null : (
                            <FormGroup validationState={this.getValidationState()}>
                                <ControlLabel htmlFor="rate">{type.label}</ControlLabel>
                                <Row>
                                    <Col sm={6} md={4}>
                                        <InputGroup>
                                            <FormControl
                                                id="rate"
                                                type="number"
                                                min="0"
                                                value={rate}
                                                onChange={this.handleRateChange}
                                                placeholder="0"
                                            />
                                            <InputGroup.Button style={{ width: 0 }}></InputGroup.Button>
                                            <FormControl
                                                id="currency"
                                                componentClass="select"
                                                value={currency}
                                                onChange={this.handleCurrencyChange}
                                            >
                                                <option value="">--</option>
                                                {CURRENCIES.map((x) => (
                                                    <option key={x} value={x}>
                                                        {x}
                                                    </option>
                                                ))}
                                            </FormControl>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <HelpBlock>A rate should be specified in currency local to the courier</HelpBlock>
                            </FormGroup>
                        )}
                        <Row>
                            <Col sm={12}>
                                <TierPreview
                                    allCitiesApply={allCitiesApply}
                                    cities={cities}
                                    daysOfWeek={daysOfWeek}
                                    allTimesApply={allTimesApply}
                                    from={from}
                                    to={to}
                                    allDriverLevelsApply={allDriverLevelsApply}
                                    driverLevels={driverLevels}
                                    rate={rate}
                                    currency={currency}
                                    specialDay={specialDay}
                                    validFrom={validFrom}
                                    validTo={validTo}
                                    lowerBound={lowerBound}
                                    upperBound={upperBound}
                                    type={type}
                                />
                                {error === 400 && (
                                    <Alert bsStyle="warning">
                                        <Glyphicon glyph="thumbs-down" /> Check your data and try again
                                    </Alert>
                                )}
                                {error === 500 && (
                                    <Alert bsStyle="danger">
                                        <Glyphicon glyph="thumbs-down" /> Oops, something went wrong!
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    </BsForm>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose()}>Cancel</Button>
                    <Button onClick={this.handleSubmit} bsStyle="primary" disabled={updating}>
                        {updating ? 'Saving...' : 'Save'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    courier: state.couriers.courier,
    courierId: state.couriers.courierId,
    error: state.couriers.error,
    updating: state.couriers.updatingCompensationTier,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompensationTierModal)

CompensationTierModal.propTypes = {
    actions: PropTypes.object,
    courierId: PropTypes.number.isRequired,
    type: PropTypes.object.isRequired,
    existingTier: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
}
