import React, { Component } from 'react'
import { Link } from 'react-router'
import { Col, Row, Panel, Glyphicon, Form, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import auth from '../auth'
import RouteTable from './routes/route-table'
import InvoiceItemView from './invoices/invoice-item-view'
import Alert from './alert'
import { sortNumbers } from '../utils/sorting'
import { getRoute, getSplitReasons, extractStops } from '../utils/route-webapi'
import { handleError } from '../utils/handle-error'
import { getAllInvoicesForRoute, createInvoiceForRoute, deleteInvoice } from '../utils/invoice-webapi'
import { INVOICE_TYPES_COURIER } from './couriers/manage-invoice-items'
import {
    OPERATIONS_ADMIN,
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_COORDINATOR,
    TRAFFIC_CONTROLLER,
} from '../utils/role'

const INVOICE_ARTICLES_COURIER = [
    { value: 'PICKUP', label: 'Pickup' },
    { value: 'CANCELLATION_FEE', label: 'Cancellation Fee' },
    { value: 'COURIER_FEE', label: 'Courier fee' },
    { value: 'EXTRA_TIME_DRIVER', label: 'Extra time driver' },
    { value: 'OTHER', label: 'Other' },
    { value: 'LATE_TO_LOADING', label: 'Late to loading' },
    { value: 'DRIVER_UNIFORM', label: 'Driver uniform' },
]

export default class EditRouteHandler extends Component {
    constructor(...args) {
        super(...args)
        this.canSplit = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)
        this.state = {
            route: null,
            selectedStops: [],
            selectedSequence: 1,
            serviceTime: 5,
            splitReason: '',
            responsibleParty: '',
            splitReasons: [],
            isSaving: false,
            errorMsg: null,
            canEditInvoices: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin/users')
            return
        }
        this.setState({ canEditInvoices: auth.hasPermission('edit_invoices') })
        this.fetchData()
    }

    onSelect = (stops) => {
        this.setState({ selectedStops: stops })
    }

    onServiceTimeChange = ({ target: { value: serviceTime } }) => this.setState({ serviceTime })

    onSplitReason = ({ target: { value: splitReason } }) => this.setState({ splitReason })

    onResponsibleParty = ({ target: { value: responsibleParty } }) => this.setState({ responsibleParty })

    onSubmit = (e) => {
        e.preventDefault()
        this.setState({ isSaving: true })

        const { route } = this.state

        const routeId = route.id
        const stopIds = this.state.selectedStops.map((s) => s.id).sort(sortNumbers)
        const crossdockSequence = parseInt(this.state.selectedSequence, 10)

        const crossdockStop = [...route.terminalStops, ...route.consumerStops, ...route.crossdockStops].find(
            (s) => s.sequence === crossdockSequence,
        )
        const etd = crossdockStop.estimatedInterval.stopTimestamp.date

        const serviceTimeMs = this.state.serviceTime * 60 * 1000
        const when = {
            startTimestamp: {
                date: moment(etd),
            },
            stopTimestamp: {
                date: moment(etd + serviceTimeMs),
            },
        }

        const request = [
            routeId,
            crossdockSequence,
            when,
            crossdockStop.address,
            stopIds,
            this.state.splitReason,
            this.state.responsibleParty,
        ]
        extractStops(...request)
            .then(() => {
                this.setState({ isSaving: false, selectedStops: [], errorMsg: null })
                this.fetchData()
            })
            .catch((error) => {
                this.setState({ errorMsg: 'Error moving stops to new Route', isSaving: false })
                handleError(error)
            })
    }

    fetchData() {
        this.setState({
            route: null,
            selectedStops: [],
            selectedSequence: 1,
        })
        getRoute(this.props.params.routeId)
            .then((route) => this.setState({ route }))
            .catch((e) => {
                this.setState({ errorMsg: 'Failed to fetch route data' })
                handleError(e)
            })

        if (this.canSplit) {
            getSplitReasons()
                .then((reasons) => {
                    const reasonLabels = Object.entries(reasons).map(([value, label]) => ({ value, label }))
                    this.setState({ splitReasons: reasonLabels })
                })
                .catch((e) => {
                    this.setState({ errorMsg: 'Failed to fetch split reasons' })
                    handleError(e)
                })
        }
    }

    handleCrossdockStopSequenceChange = (e) => {
        const lowestSelectedSeq = Math.min(...this.state.selectedStops.map((stop) => stop.sequence))
        let sequence = e.target.value
        if (sequence <= 0) {
            sequence = 1
        } else if (sequence >= lowestSelectedSeq) {
            sequence = lowestSelectedSeq - 1
        }
        this.setState({ selectedSequence: sequence })
    }

    invalidCrossdockStopParams() {
        return (
            this.state.selectedSequence == null ||
            this.state.serviceTime == null ||
            this.state.splitReason === '' ||
            this.state.responsibleParty === ''
        )
    }

    renderCrossdockStopInput() {
        if (!this.canSplit || this.state.selectedStops.length < 1) {
            return null
        }
        const lowestSelectedSeq = Math.min(...this.state.selectedStops.map((stop) => stop.sequence))

        return (
            <Form>
                <FormGroup className="col-md-3">
                    <ControlLabel>Select crossdock sequence (1 - {lowestSelectedSeq - 1})</ControlLabel>
                    <FormControl
                        type="number"
                        value={this.state.selectedSequence}
                        placeholder="Original Route Crossdock Stop Sequence"
                        onChange={this.handleCrossdockStopSequenceChange}
                    />
                    <ControlLabel>Select Crossdock Stop Service Time</ControlLabel>
                    <FormControl
                        componentClass="select"
                        value={this.state.serviceTime}
                        onChange={this.onServiceTimeChange}
                    >
                        <option value={5}>5 minutes</option>
                        <option value={10}>10 minutes</option>
                        <option value={15}>15 minutes</option>
                    </FormControl>
                    <ControlLabel>Select reason for split</ControlLabel>
                    <FormControl componentClass="select" value={this.state.splitReason} onChange={this.onSplitReason}>
                        <option value="">Select</option>
                        {this.state.splitReasons.map((reason) => (
                            <option key={reason.value} value={reason.value}>
                                {reason.label}
                            </option>
                        ))}
                    </FormControl>
                    <ControlLabel htmlFor="responsibleParty">Select responsible party</ControlLabel>
                    <br />
                    <small>If you are unsure please check the split guidelines</small>
                    <FormControl
                        componentClass="select"
                        value={this.state.responsibleParty}
                        onChange={this.onResponsibleParty}
                        id="responsibleParty"
                    >
                        <option value="">Select</option>
                        <option value="BUDBEE">Budbee</option>
                        <option value="COURIER">Driver / Courier </option>
                    </FormControl>
                </FormGroup>
                <Button
                    bsStyle="primary"
                    disabled={this.invalidCrossdockStopParams() || this.state.isSaving}
                    onClick={this.onSubmit}
                >
                    Move {this.state.selectedStops.length} stop(s)
                </Button>
            </Form>
        )
    }

    render() {
        if (this.state.route == null) {
            return null
        }

        const header = (
            <h4>
                <Glyphicon glyph="picture" /> Route #{this.state.route.id}
            </h4>
        )

        const invoiceHeader = (
            <h4>
                <Glyphicon glyph="euro" /> Manage Invoices
            </h4>
        )

        return (
            <Col>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <Panel header={header}>
                            <Link to={`/admin/routes/${this.state.route.id}/analysis`} className="btn btn-primary">
                                Heat map
                            </Link>
                            <Link to={`/admin/map/${this.state.route.id}?track`} className="btn btn-primary">
                                Route overview
                            </Link>
                            <RouteTable route={this.state.route} onSelect={this.onSelect} />
                        </Panel>
                        {this.renderCrossdockStopInput()}
                    </Col>
                </Row>
                <Row>
                    {this.state.canEditInvoices ? (
                        <Col md={10} mdOffset={1}>
                            <Panel header={invoiceHeader}>
                                <Col md={12}>
                                    <Row>
                                        <h3> Manage invoices</h3>
                                        <h5> Which have not yet been booked</h5>
                                    </Row>
                                </Col>
                                <InvoiceItemView
                                    invoiceRecipientId={this.state.route.id}
                                    invoiceRecipientName={`Route : ${this.state.route.id}`}
                                    loadAllInvoiceItems={getAllInvoicesForRoute}
                                    createInvoiceItem={createInvoiceForRoute}
                                    deleteInvoiceItem={deleteInvoice}
                                    invoiceTypes={INVOICE_TYPES_COURIER}
                                    invoiceArticles={INVOICE_ARTICLES_COURIER}
                                />
                            </Panel>
                        </Col>
                    ) : null}
                </Row>
                <Alert type="danger" message={this.state.errorMsg} />
            </Col>
        )
    }
}
EditRouteHandler.propTypes = {
    router: PropTypes.object,
    params: PropTypes.object,
}
