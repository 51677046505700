import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import BillingPeriods from '../../utils/billing-periods'

const billingPeriods = BillingPeriods()
const getBillingPeriod = (value) => billingPeriods.find((x) => x.value === value)

export default class BillingPeriodSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: getBillingPeriod(this.props.value),
        }
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            this.setState({ selected: getBillingPeriod(value) })
        }
    }

    render() {
        const { onChange } = this.props
        const { selected } = this.state
        return <Select value={selected} options={billingPeriods} onChange={onChange} />
    }
}

BillingPeriodSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
}
