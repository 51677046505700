import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Col, Grid, Row } from 'react-bootstrap'
import * as recurringPickupsActions from '../../actions/recurring-pickups'
import * as ownerActions from '../../actions/owner'
import * as driverActions from '../../actions/user'
import * as terminalActions from '../../actions/terminals'
import HtmlTitle from '../html-title'
import Form from './form'
import auth from '../../auth'
import { matchPickups, STOP_TYPE, validate, checkIfMerchantRoute } from '../../utils/recurring-pickups'
import { DAYS_OF_WEEK } from '../../utils/days-of-week'
import { OPERATIONS_ADMIN } from '../../utils/role'

const initialPickups = () => []

class RecurringPickupsCreateHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialModel: {
                name: '',
                pickups: initialPickups(),
                dayOfWeek: '-1',
                isOneTimeRoute: false,
                courierId: '-1',
                driverId: '-1',
                remuneration: '',
                currency: 'SEK',
                maxPallets: '',
                vehicleType: '',
                vehicleUtilisationFactor: 100,
                linehaulCost: null,
                requiredPallets: null,
            },
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN)
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        this.props.ownerActions.fetchOwnersWebapi()
        this.props.terminalActions.fetchTerminalsWebapi()
        this.props.recurringPickupsActions.fetchRecurringPickupCollectionMultiplePoints(
            DAYS_OF_WEEK.map((day) => day.label),
        )
    }

    onCourierChange = (courierId) => {
        this.props.driverActions.getCourierUsers(courierId)
    }

    submitRoute(day, form) {
        const newPickups = matchPickups(day, form, form.collectionPoints)

        if (day.selected) {
            const stops = form.pickups.map((pickup, sortOrder) => {
                switch (pickup.type) {
                    case STOP_TYPE.MERCHANT:
                        return {
                            type: pickup.type,
                            merchantScheduleId: newPickups.find(
                                (p) =>
                                    pickup.merchantName === p.buyerName &&
                                    pickup.collectionPointName === p.collectionPointName &&
                                    pickup.startTime === p.startTime &&
                                    pickup.endTime === p.endTime,
                            ).id,
                            returns: pickup.returns,
                            pickup: pickup.pickup,
                            sortOrder,
                        }
                    case STOP_TYPE.TERMINAL:
                        return {
                            type: pickup.type,
                            terminalId: pickup.terminalId,
                            startTime: pickup.startTime,
                            endTime: pickup.endTime,
                            sortOrder,
                        }
                    default:
                        return { ...pickup, sortOrder }
                }
            })
            checkIfMerchantRoute(form.pickups) ? null : form.linehaulCost = null
            const model = {
                name: form.name,
                dayOfWeek: day.enum,
                courierId: form.courierId,
                driverId: form.driverId === '-1' ? null : form.driverId,
                remuneration: form.remuneration,
                currency: form.currency,
                pallets: form.maxPallets,
                vehicleType: form.vehicleType,
                vehicleUtilisationFactor: form.vehicleUtilisationFactor / 100,
                linehaulCost: form.linehaulCost,
                stops,
            }

            this.props.recurringPickupsActions.createRecurringPickup(model, this.props.router)
        }
    }

    onSubmit = (form, e) => {
        e.preventDefault()

        form.days.map((day) => this.submitRoute(day, form))
    }

    render() {
        const { fetchRecurringPickupCollectionPoints, fetchRecurringPickupCollectionPointSchedules } = this.props.recurringPickupsActions
        return (
            <div>
                <HtmlTitle title="Recurring pickups" />
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <h1>Create recurring route</h1>
                        </Col>
                    </Row>

                    <Form
                        onSubmit={this.onSubmit}
                        onCourierChange={this.onCourierChange}
                        initialModel={this.state.initialModel}
                        owners={this.props.owners}
                        drivers={this.props.drivers}
                        terminals={this.props.terminals}
                        locations={this.props.locations}
                        multipleLocations={this.props.multipleLocations}
                        multipleLocationsOptions={this.props.multipleLocationsOptions}
                        validate={validate}
                        checkIfMerchantRoute={checkIfMerchantRoute}
                        fetchRecurringPickupCollectionPoints={fetchRecurringPickupCollectionPoints}
                        fetchRecurringPickupCollectionPointSchedules={fetchRecurringPickupCollectionPointSchedules}
                        initialPickups={initialPickups}
                        showSubmitButtonCreate
                    />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({ owners, terminals, users, recurringPickups: { locations, multipleLocations, multipleLocationsOptions } }) => ({
    owners: owners.data,
    terminals: terminals.terminals,
    drivers: users.data,
    locations,
    multipleLocations,
    multipleLocationsOptions,
})

const mapDispatchToProps = (dispatch) => ({
    recurringPickupsActions: bindActionCreators(recurringPickupsActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    ownerActions: bindActionCreators(ownerActions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPickupsCreateHandler)

RecurringPickupsCreateHandler.propTypes = {
    router: PropTypes.object,
    ownerActions: PropTypes.object,
    driverActions: PropTypes.object,
    terminalActions: PropTypes.object,
    recurringPickupsActions: PropTypes.object,
    owners: PropTypes.array,
    drivers: PropTypes.array,
    terminals: PropTypes.array,
    locations: PropTypes.array,
    multipleLocations: PropTypes.object,
    multipleLocationsOptions: PropTypes.array,
}
