import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    FormGroup,
    Col,
    FormControl,
    ControlLabel,
    InputGroup,
    Glyphicon,
    OverlayTrigger,
    Button,
    Popover,
} from 'react-bootstrap'
import Select from 'react-select'
import Uploader from '../../uploader'
import editMerchant, { supportTypes } from './edit-merchant-hoc'
import MerchantInput from './merchant-input'
import TagSelect from '../../common/tag-select'
import ThumbnailLogoPreview from './thumbnail-logo-preview'
import { isValidHexColor } from '../../../utils/color'

const brandColorInstructionsOverlay = (
    <Popover id="primary-logo-instructions-popover" title="Primary logo">
        Specify the merchants primary brand color, it is used when presenting the merchant along with their logo.
        Supported format: <strong>6 character HEX</strong>
        <br />
        To get the HEX value of a color, use a tool like <br />
        <a
            href="https://www.howtogeek.com/705435/how-to-get-a-system-wide-color-picker-on-windows-10/"
            target="_blank"
            rel="noopener noreferrer"
        >
            Windows Color Picker (Win)
        </a>
        <br />
        <a href="https://apps.apple.com/us/app/colorslurp/id1287239339?mt=12" target="_blank" rel="noopener noreferrer">
            ColorSlurp (MacOS)
        </a>
    </Popover>
)

const primaryLogoInstructionsOverlay = (
    <Popover id="primary-logo-instructions-popover" title="Primary logo">
        The primary logo is used in the Budbee app rendered on a light background. Upload a file with transparent
        background and dark text, or a non-transparent background with light text. Ensure that your uploaded file does
        not have any whitespace around the logo. Supported file formats: <strong>PNG</strong>
    </Popover>
)

const thumbnailLogoInstructionsOverlay = (
    <Popover id="thumbnail-logo-instructions-popover" title="Small thumbnail logo">
        The smaller thumbnail logo is used in the Budbee app inside a circle with the brand color as a background.
        Upload a file with a <strong>transparent</strong> background. The image will be automatically resized to
        208x208px and compressed. You can preview the logo to the right. As a guideline, if this was the Google logo, it
        would be an image of the &quot;G&quot; icon, not the full &quot;Google&quot; wordmark. Ensure that your uploaded
        file does not have any whitespace around the logo. Supported file formats: <strong>PNG</strong>
    </Popover>
)

class GeneralSettingsForm extends Component {
    onTagsChange = (buyerTagsIds) => {
        const event = { target: { value: buyerTagsIds } }
        this.props.onChange('value', 'buyerTagIds')(event)
    }

    renderConfigurableSettingsBasedOnCountry = (countrySettings, globalSettings) => {
        const {
            onChangeSettingsBasedOnCountry,
            addedSupportTypesCountrySpecific,
            allCountries,
            renderSettingsBasedOnCountriesHeader,
            onUpload,
            onUploadThumbnailLogo,
        } = this.props

        const addedSupportTypes = addedSupportTypesCountrySpecific[countrySettings.countryCode]
        const country = allCountries.find((c) => c.id === countrySettings.countryCode)

        if (!country) {
            return null
        }

        return (
            <div key={countrySettings.countryCode} className="country-based-settings">
                {renderSettingsBasedOnCountriesHeader(country, countrySettings)}
                <MerchantInput
                    controlId="externalName"
                    label="Display name (SMS, Tracking)"
                    value={countrySettings.externalName}
                    onChange={onChangeSettingsBasedOnCountry('value', 'externalName', countrySettings.countryCode)}
                    required
                />
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                        Primary Logo
                        <OverlayTrigger trigger="click" placement="top" overlay={primaryLogoInstructionsOverlay}>
                            <Button bsStyle="link">
                                <Glyphicon glyph="question-sign" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                    <Col sm={9}>
                        <div style={{ backgroundColor: 'lightgray', padding: 10 }}>
                            {(countrySettings.logoPreview || countrySettings.logo) && (
                                <img
                                    className="img-responsive centered"
                                    src={countrySettings.logoPreview || countrySettings.logo.url}
                                    alt="merchant logo"
                                />
                            )}
                            <Uploader
                                onUpload={(file) => onUpload(file, countrySettings.countryCode)}
                                accept={['image/png']}
                            />
                        </div>
                    </Col>
                    <Col componentClass={ControlLabel} sm={3}>
                        Thumbnail Logo
                        <OverlayTrigger trigger="click" placement="top" overlay={thumbnailLogoInstructionsOverlay}>
                            <Button bsStyle="link">
                                <Glyphicon glyph="question-sign" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                    <Col sm={9} style={{ marginTop: 10 }}>
                        <div style={{ backgroundColor: 'lightgray', padding: 10 }}>
                            <div style={{ margin: 'auto', width: 72 }}>
                                <ThumbnailLogoPreview
                                    backgroundColor={globalSettings.brandColor}
                                    image={countrySettings.thumbnailLogo}
                                />
                            </div>
                            <Uploader
                                onUpload={(file) => onUploadThumbnailLogo(file, countrySettings.countryCode)}
                                accept={['image/png']}
                            />
                        </div>
                    </Col>
                </FormGroup>
                <div>
                    {this.renderSupportTypesInputs(
                        countrySettings,
                        onChangeSettingsBasedOnCountry,
                        addedSupportTypes,
                        countrySettings.countryCode,
                    )}
                </div>
                {this.renderSupportTypeSelect(addedSupportTypes, countrySettings.countryCode)}
            </div>
        )
    }

    renderSupportTypesInputs = (settings, onSettingsChange, addedSupportTypes, countryCode = null) => {
        const { onRemoveSupportType } = this.props
        return addedSupportTypes.map((supportType) => {
            const supportTypeInfo = supportTypes[supportType]
            return (
                <MerchantInput
                    key={supportTypeInfo.type}
                    type={supportTypeInfo.type}
                    controlId={supportTypeInfo.controlId}
                    label={supportTypeInfo.label}
                    value={settings[supportTypeInfo.controlId] || ''}
                    onChange={onSettingsChange('value', supportTypeInfo.controlId, countryCode)}
                    action={{
                        callback: () => onRemoveSupportType(supportType, countryCode),
                        icon: 'trash',
                        style: 'danger',
                    }}
                />
            )
        })
    }

    renderSupportTypeSelect = (addedSupportTypes, countryCode = null) => {
        const { onAddSupportType } = this.props
        // Remove the support types that are already added
        const addedSupportTypesSet = new Set(addedSupportTypes)
        const supportTypesKeys = Object.keys(supportTypes).filter((key) => !addedSupportTypesSet.has(key))

        // Don't display the support types select if all the options are added
        if (Object.keys(supportTypes).length === addedSupportTypes.length) {
            return null
        }

        return (
            <FormGroup controlId="supportTypes">
                <Col componentClass={ControlLabel} sm={3}>
                    Add support type
                </Col>
                <Col sm={5}>
                    <InputGroup>
                        <InputGroup.Addon>
                            <Glyphicon glyph="plus" />
                        </InputGroup.Addon>
                        <Select
                            placeholder="Add support type..."
                            options={supportTypesKeys.map((key) => ({
                                value: key,
                                label: supportTypes[key].name,
                            }))}
                            onChange={(option) => onAddSupportType(option, countryCode)}
                            allowCreate={false}
                            searchable={false}
                            multi={false}
                        />
                    </InputGroup>
                </Col>
            </FormGroup>
        )
    }

    render() {
        const { merchant, onChange, onSettingsChange, onUpload, addedSupportTypes } = this.props
        const { logo } = merchant
        const { brandColor } = merchant.buyerSettings

        return (
            <div>
                <MerchantInput
                    controlId="name"
                    label="Company name"
                    value={merchant.name}
                    onChange={onChange('value', 'name')}
                    required
                />
                <MerchantInput
                    controlId="externalName"
                    label="Display name (SMS, Tracking)"
                    value={merchant.externalName}
                    onChange={onChange('value', 'externalName')}
                    required
                />
                <FormGroup validationState={isValidHexColor(brandColor) ? null : 'error'}>
                    <Col componentClass={ControlLabel} sm={3}>
                        Brand primary color (Hex)
                        <OverlayTrigger trigger="click" placement="top" overlay={brandColorInstructionsOverlay}>
                            <Button bsStyle="link">
                                <Glyphicon glyph="question-sign" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                    <Col sm={9}>
                        <InputGroup>
                            <FormControl
                                type="text"
                                value={brandColor || ''}
                                placeholder="#FFFFFF"
                                onChange={onSettingsChange('value', 'brandColor')}
                                required
                            />
                            <InputGroup.Addon
                                style={{ backgroundColor: brandColor && brandColor.length === 7 ? brandColor : '' }}
                            >
                                &nbsp;
                            </InputGroup.Addon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                        Primary Logo
                        <OverlayTrigger trigger="click" placement="top" overlay={primaryLogoInstructionsOverlay}>
                            <Button bsStyle="link">
                                <Glyphicon glyph="question-sign" />
                            </Button>
                        </OverlayTrigger>
                    </Col>
                    <Col sm={9}>
                        <div style={{ backgroundColor: 'lightgray', padding: 10 }}>
                            {(merchant.logoPreview || (logo && logo.url)) && (
                                <img
                                    className="img-responsive centered"
                                    src={merchant.logoPreview || (logo && logo.url)}
                                    alt="merchant logo"
                                />
                            )}
                            <Uploader onUpload={onUpload} accept={['image/png']} />
                        </div>
                    </Col>
                </FormGroup>
                <div>{this.renderSupportTypesInputs(merchant.buyerSettings, onSettingsChange, addedSupportTypes)}</div>
                {this.renderSupportTypeSelect(addedSupportTypes)}
                <FormGroup controlId="tags">
                    <Col componentClass={ControlLabel} sm={3}>
                        Tags
                    </Col>
                    <Col sm={9}>
                        <TagSelect value={merchant.buyerTagIds} onChange={this.onTagsChange} />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                        API Key
                    </Col>
                    <Col sm={9}>
                        <FormControl.Static>{merchant.apiKey}</FormControl.Static>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={3}>
                        API Secret
                    </Col>
                    <Col sm={9}>
                        <FormControl.Static>{merchant.apiSecret}</FormControl.Static>
                    </Col>
                </FormGroup>
                {merchant.merchantSettings.map((countrySettings) =>
                    this.renderConfigurableSettingsBasedOnCountry(countrySettings, merchant.buyerSettings),
                )}
            </div>
        )
    }
}

GeneralSettingsForm.propTypes = {
    merchant: PropTypes.object.isRequired,
    allCountries: PropTypes.array,
    onAddSupportType: PropTypes.func.isRequired,
    addedSupportTypes: PropTypes.array,
    addedSupportTypesCountrySpecific: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onUploadThumbnailLogo: PropTypes.func.isRequired,
    onChangeSettingsBasedOnCountry: PropTypes.func.isRequired,
    onRemoveSupportType: PropTypes.func.isRequired,
    renderSettingsBasedOnCountriesHeader: PropTypes.func.isRequired,
}

export default editMerchant(GeneralSettingsForm)
