import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { generateTimes } from '../../../utils/times'
import { formatTime } from '../format-time'
import { Alert, Col, ControlLabel, FormGroup, Glyphicon, Row } from 'react-bootstrap'
import moment from 'moment'

const RestStop = ({ isReadOnly, sortOrder, startTime, endTime, onTimeChange }) => (
    <div>
        <FormGroup>
            <ControlLabel>
                <Glyphicon glyph="heart" /> Rest stop {sortOrder + 1}
            </ControlLabel>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col sm={6}>
                    <ControlLabel>Arrive at</ControlLabel>
                    <Select
                        disabled={isReadOnly}
                        componentClass="select"
                        value={{ value: startTime, label: formatTime(startTime) }}
                        onChange={onTimeChange('startTime')}
                        options={generateTimes(5)}
                        clearable={false}
                        searchable
                    />
                </Col>
                <Col sm={6}>
                    <ControlLabel>Depart at</ControlLabel>
                    <Select
                        disabled={isReadOnly}
                        componentClass="select"
                        value={{ value: endTime, label: formatTime(endTime) }}
                        onChange={onTimeChange('endTime')}
                        options={generateTimes(5)}
                        clearable={false}
                        searchable
                    />
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            {moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm')) && (
                <Alert bsStyle="warning">
                    <Glyphicon glyph="time" /> Schedule continues into next day
                </Alert>
            )}
        </FormGroup>
    </div>
)

export default RestStop
