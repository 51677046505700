import jwt from 'jsonwebtoken'

class Auth {
    constructor(storageKey = 'budbee:carriers') {
        this.authKey = `${storageKey}:jwt:auth`
        this.permissionKey = `${storageKey}:permissions`
        this.requestedPageKey = `${storageKey}:requestedPage`

        this.transition = null
        this.auth = localStorage.getItem(this.authKey)

        const permissions = localStorage.getItem(this.permissionKey)
        if (permissions != null) {
            this.permissions = JSON.parse(permissions)
        } else {
            this.permissions = []
        }

        // delete the user data or else PII will just stay in local storage forever
        localStorage.removeItem(`${storageKey}:user`)
    }

    isLoggedIn() {
        return this.auth != null
    }

    get() {
        return this.auth
    }

    hasAuthExpired() {
        if (this.auth == null) {
            return true
        }

        const token = this.auth.substring(7)
        const data = jwt.decode(token, { json: true })
        if (data === null) return true
        if (data.exp * 1000 < Date.now()) {
            return true
        }

        return false
    }

    setToken(token) {
        this.auth = `Bearer ${token}`
        localStorage.setItem(this.authKey, this.auth)
    }

    setRequestedPage(page) {
        localStorage.setItem(this.requestedPageKey, page)
    }

    popRequestedPage() {
        const page = localStorage.getItem(this.requestedPageKey)
        localStorage.setItem(this.requestedPageKey, null)
        return page
    }

    setPermissions(permissions) {
        this.permissions = (permissions || []).map((permission) => permission.toLowerCase())
        localStorage.setItem(this.permissionKey, JSON.stringify(this.permissions))
    }

    hasPermission(permissionName) {
        if (!this.isLoggedIn()) {
            return false
        }

        return this.permissions.includes(permissionName.toLowerCase())
    }

    hasAnyPermission(permissionNames) {
        if (!this.isLoggedIn()) {
            return false
        }

        return permissionNames.some((permission) => this.permissions.includes(permission.toLowerCase()))
    }

    hasAnyRole(...roles) {
        if (this.auth == null) {
            return false
        }

        const token = this.auth.substring(7)
        const data = jwt.decode(token, { json: true })
        const { 'https://webapi.budbee.com/roles': myRoles } = data

        if (myRoles && myRoles.some((role) => role.toLowerCase() === 'admin')) {
            return true
        }
        return myRoles && roles.some((role) => myRoles.includes(role.toLowerCase()))
    }

    clear() {
        localStorage.removeItem(this.authKey)
        localStorage.removeItem(this.permissionKey)

        this.auth = null
        this.permissions = null
    }

    getTransition() {
        return this.transition
    }

    setTransition(t) {
        this.transition = t
    }
}

export default new Auth()
