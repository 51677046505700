import Webapi from './webapi'
import auth from '../auth'
import { PriceTierType } from './types'

const merchantsV1 = 'application/vnd.budbee.merchants-v1+json'
const merchantsV4 = 'application/vnd.budbee.merchants-v4+json'
const discountsV2 = 'application/vnd.budbee.parcel-discounts-v2+json'
const buyerReadOnlyV2 = 'application/vnd.budbee.read-only.buyers-v2+json'

function pathForPriceTierType(type) {
    switch (type) {
        case PriceTierType.DistributionVolume:
            return 'distribution-volumes'
        case PriceTierType.MerchantParcels:
            return 'merchant-parcels'
        case PriceTierType.MerchantPallets:
            return 'merchant-pallets'
        case PriceTierType.BoxDistribution:
            return 'box-distribution'
        default:
            return null
    }
}

const webapi = (accept, contentType = accept) => {
    let w = new Webapi().authorization(auth.get()).path('merchants')

    if (accept != null) {
        w.accept(accept)
    }
    if (contentType != null) {
        w = w.contentType(contentType)
    }

    return w
}

export const getMerchants = () => webapi(merchantsV1).get()

export const getMinimalMerchants = () => webapi(buyerReadOnlyV2).get()

export const getMerchant = (id) =>
    webapi(merchantsV4)
        .path(id)
        .get()

export const createMerchant = (request) => webapi(merchantsV4).post(request)

export const editMerchant = (id, request) =>
    webapi(merchantsV4)
        .path(id)
        .put(request)

export const deleteMerchant = (id) =>
    webapi(merchantsV1)
        .path(id)
        .delete()

export const getMerchantTags = (id) =>
    webapi(merchantsV1)
        .path(id)
        .path('tags')
        .get()

export const getMerchantSmsTemplates = (id) =>
    webapi(merchantsV1)
        .path(id)
        .path('sms-templates')
        .get()

export const createMerchantSmsTemplate = (id, type, message) =>
    webapi(merchantsV1)
        .path(id)
        .path('sms-templates')
        .post({ type, message })

export const previewSmsTemplate = (id, template) =>
    webapi(merchantsV1)
        .path(id)
        .path('sms-preview')
        .post({ template })

export const getMerchantPriceTiers = (type, id) =>
    webapi(merchantsV1)
        .path(id)
        .path('price-tiers')
        .path(pathForPriceTierType(type))
        .get()

export const createMerchantPriceTier = (type, id, data) =>
    webapi(merchantsV1)
        .path(id)
        .path('price-tiers')
        .path(pathForPriceTierType(type))
        .post(data)

export const deleteMerchantPriceTier = (type, id, tierId) =>
    webapi(merchantsV1)
        .path(id)
        .path('price-tiers')
        .path(pathForPriceTierType(type))
        .path(tierId)
        .delete()

export const getMerchantPostalCodeZones = (id) =>
    webapi(merchantsV1)
        .path(id)
        .path('postal-code-zones')
        .get()

export const getMerchantCollectionPoints = (id) =>
    webapi(merchantsV1)
        .path(id)
        .path('collection-points')
        .get()

export const getMerchantDiscounts = (id) =>
    webapi(discountsV2)
        .path(id)
        .path('parcel-discounts')
        .get()

export const createMerchantDiscount = (id, request) =>
    webapi(discountsV2)
        .path(id)
        .path('parcel-discounts')
        .post(request)

export const deleteMerchantDiscount = (merchantId, id) =>
    webapi(discountsV2)
        .path(merchantId)
        .path('parcel-discounts')
        .path(id)
        .delete()

export const getMerchantSchedules = (merchantId, collectionPointId) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('collection-points')
        .path(collectionPointId)
        .path('schedules')
        .get()

export const createMerchantSchedule = (merchantId, collectionPointSchedule) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('collection-points')
        .path('schedules')
        .post(collectionPointSchedule)

export const deleteMerchantSchedule = (merchantId, scheduleId) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('collection-points')
        .path('schedules')
        .path(scheduleId)
        .delete()

export const getMerchantPricePoints = (merchantId, collectionPointId) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('price-points')
        .queryParam('collectionPointId', collectionPointId)
        .get()

export const createMerchantPricePoint = (merchantId, pricePoint) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('price-points')
        .post(pricePoint)

export const deleteMerchantPricePoints = (merchantId, pricePointIds) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('price-points')
        .delete(pricePointIds)

export const createMerchantCollectionPoint = (merchantId, collectionPoint) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('collection-points')
        .post(collectionPoint)

export const updateMerchantCollectionPoint = (merchantId, collectionPointId, collectionPoint) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('collection-points')
        .path(collectionPointId)
        .put(collectionPoint)

export const setLogo = (file) => {
    const fd = new FormData()
    fd.append('file', file)
    return webapi(null, null)
        .path('image')
        .path('make-merchant-logo')
        .put(fd, false)
}

export const modifyPriceTiers = (merchantId, modifications) =>
    webapi(merchantsV1)
        .path(merchantId)
        .path('price-tiers/modify')
        .post(modifications)

export const findMerchants = (searchTerm, excludeMerchantsInGroups) =>
    webapi()
        .path('search')
        .queryParam('name', searchTerm)
        .queryParam('excludeMerchantsInGroups', excludeMerchantsInGroups || false)
        .get()

export const uploadThumbnailLogo = (merchantId, file) => {
    const fd = new FormData()
    fd.append('file', file)
    return webapi(null, null)
        .path(merchantId)
        .path('upload-thumbnail-logo')
        .post(fd, false)
}
