import * as types from '../constants/actionTypes'
import SmsApi from '../utils/sms-api'

export function onSMSRequestResponse(response) {
    return { type: types.SMS_REQUEST_RESPONSE, response }
}

export function onSMSRequestError(error) {
    return { type: types.SMS_REQUEST_ERROR, error }
}

export function requestSent() {
    return { type: types.SMS_REQUEST_SENT }
}

export function sendSMS(from, to, msg) {
    return function(dispatch) {
        dispatch(requestSent())
        SmsApi.sendText(from, to, msg)
            .then((res) => dispatch(onSMSRequestResponse(res)))
            .catch((error) => dispatch(onSMSRequestError(error)))
    }
}
