import * as types from '../constants/actionTypes'
import InventoryApi from '../utils/inventory-api'
import { getTerminals } from '../utils/terminal-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

export const terminalsLoading = () => ({
    type: types.TERMINALS_LOADING,
})

export const terminalsLoaded = (response) => ({
    type: types.TERMINALS_LOADED,
    status: 200,
    response,
})

export const fetchTerminals = () => (dispatch) => {
    dispatch(terminalsLoading())
    return InventoryApi.getWarehouses()
        .then(compose(dispatch, terminalsLoaded))
        .catch(handleError)
}

export const fetchTerminalsWebapi = () => (dispatch) => {
    dispatch(terminalsLoading())
    return getTerminals()
        .then(compose(dispatch, terminalsLoaded))
        .catch(handleError)
}
