import Select from 'react-select'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getReturnPalletGroups } from '../../../../../actions/return-pallet-groups'

class MerchantGroupSelect extends Component {
    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(getReturnPalletGroups)
    }

    mapGroupsToOptions = (groups) => {
        return groups.map((group) => {
            return { value: group.id, label: group.name }
        })
    }

    groupSearchFilter = (option, searchText) => {
        const { groups } = this.props
        const lowerCaseSearchText = searchText.toLowerCase()
        const groupName = option.label
        const group = groups.find((curGroup) => curGroup.id === option.value)
        const merchantsInGroup = group ? group.merchants : []

        const matches = (name) => name.toLowerCase().includes(lowerCaseSearchText)
        return (
            matches(groupName) ||
            merchantsInGroup.some((merchant) => matches(merchant.name) || matches(merchant.externalName))
        )
    }

    render() {
        const { groups, disabled, selected, placeholder, onChange, multi } = this.props

        return (
            <div>
                <Select
                    disabled={disabled}
                    placeholder={placeholder || 'Merchant group'}
                    value={selected}
                    filterOption={this.groupSearchFilter}
                    options={this.mapGroupsToOptions(groups)}
                    onChange={onChange}
                    allowCreate={false}
                    ignoreCase
                    searchable
                    clearable
                    multi={multi}
                />
            </div>
        )
    }
}

MerchantGroupSelect.propTypes = {
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    multi: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.object,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
}

const mapStateToProps = ({ returnPalletGroups: { groups } }) => ({
    groups,
})
export default connect(mapStateToProps)(MerchantGroupSelect)
