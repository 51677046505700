import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import Loader from 'react-loader'
import { Table, Row, Col, Grid, Glyphicon, Button } from 'react-bootstrap'
import { handleError } from '../../utils/handle-error'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import '../../../styles/less/zoneManagement.less'
import getNameFromCountryCode from '../../utils/country-codes'
import { mapSort, sortStrings } from '../../utils/sorting'
import { prop } from '../../utils/prop'
import cloneDeep from 'lodash/cloneDeep'
import { OPERATIONS_COORDINATOR } from '../../utils/role'
import Confirm from '../confirm'
import moment from 'moment'

import * as actions from '../../actions/zoneManagement'

import auth from '../../auth'

class ZoneManagementListHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            groups: [],
            homeGroups: [],
            boxGroups: [],
            showConfirmCreateDraftModal: false,
            groupToBaseDraftOn: {}
        }
    }

    componentDidMount() {
        if (!auth.hasAnyRole(OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin')
            return
        }

        this.props.actions.fetchLatestGroups()
            .then(() =>
                this.setState({
                    homeGroups: this.props.zoneManagement.groups.filter(x => x.type === 'HOME')
                        .sort(mapSort(sortStrings, prop('country'))),
                    boxGroups: this.props.zoneManagement.groups.filter(x => x.type === 'BOX')
                        .sort(mapSort(sortStrings, prop('country')))
                }),
            )
            .finally(() => this.setState({ loading: false }))
    }

    onCreateDraft = () => {
        const { groupToBaseDraftOn } = this.state
        this.setState({ loading: true })

        this.props.actions.fetchGroup(groupToBaseDraftOn.id)
            .then((response) => {
                var payload = cloneDeep(response.response)
                delete payload.validFrom
                this.props.actions.createGroup(payload)
                    .then((response) => {
                        if (response.status === 200) {
                            var link = response.response.link
                            var draftId = link.substr(link.length - 5)
                            this.props.router.push(`/admin/zone-management/${groupToBaseDraftOn.type}/${groupToBaseDraftOn.name}/${draftId}/edit`)
                            window.location.reload()
                        }
                    })
                    .catch((e) => {
                        handleError(e),
                            this.setState({ loading: false })
                    })
            })
    }

    onConfirmCreateDraft = (groupToBaseDraftOn) => {
        this.setState({ showConfirmCreateDraftModal: true, groupToBaseDraftOn })
    }

    groupHasDraft(group) {
        return this.state.drafts.some(x => x.country === group.country && x.type === group.type)
    }

    getDraftId(group) {
        let draft = this.state.drafts.find(x => x.country === group.country)
        return draft.id
    }

    getPreviousGroupId = (id) => {
        this.setState({ loading: true })
        this.props.actions.fetchPreviousGroup(id)
            .then((response) => {
                return response.id
            })
    }

    goToPreviousGroup = (id) => {
        this.props.actions.fetchPreviousGroup(id)
            .then((r) => {
                if (r.status === 200) {
                    this.props.router.push(`/admin/zone-management/${r.response.type}/${r.response.name}/${r.response.id}/view`)
                }
                if (r.status === 404) {
                    alert('This group does only have a draft. Once the first draft is published it is viewable.')
                }
            })

    }

    renderTable() {
        const { homeGroups, boxGroups } = this.state
        return (
            <Row>
                <Col md={10} mdOffset={1}>
                    <Col md={6}>
                        <h3>Home</h3>
                        <Table striped condensed>
                            <thead>
                                <tr className="zone-table-row">
                                    <th>Name</th>
                                    <th>Country</th>
                                    <th>Live since</th>
                                    <th>Upcoming change</th>
                                    <th> </th>
                                    <th> </th>
                                </tr>
                            </thead>

                            <tbody>
                                {homeGroups.map((group) => (
                                    < tr key={group.id} className="zone-table-row" >
                                        <td>{group.name}</td>
                                        <td>{getNameFromCountryCode(group.country)}</td>
                                        <td>{group.hasOwnProperty('validFrom') ? moment(group.validFrom).format('YYYY-MM-DD') : null}</td>
                                        <td>{group.hasOwnProperty('validFrom') ? null : 'Draft in progress'}</td>
                                        <td>
                                            {group.hasOwnProperty('validFrom') ?
                                                <Link to={`/admin/zone-management/Home/${group.name}/${group.id}/view`} className="pull-right">
                                                    View
                                                </Link>
                                                :
                                                <Link className="pull-right goto-previous-link" onClick={() => this.goToPreviousGroup(group.id)}>
                                                    View
                                                </Link>
                                            }
                                        </td>
                                        <td>
                                            {!group.hasOwnProperty('validFrom') ?
                                                <Link to={`/admin/zone-management/Home/${group.name}/${group.id}/edit`} className="btn btn-default edit-draft">
                                                    <Glyphicon glyph='pencil' /> Edit
                                                </Link>
                                                :
                                                <Button className="btn btn-default new-draft" onClick={() => this.onConfirmCreateDraft(group)}>
                                                    <Glyphicon glyph='plus' /> New
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col md={6}>
                        <h3>Box</h3>
                        <Table striped condensed>
                            <thead>
                                <tr className="zone-table-row">
                                    <th>Name</th>
                                    <th>Country</th>
                                    <th>Live since</th>
                                    <th>Upcoming change</th>
                                    <th> </th>
                                    <th> </th>
                                </tr>
                            </thead>

                            <tbody>
                                {boxGroups.map((group) => (

                                    < tr key={group.id} className="zone-table-row" >
                                        <td>{group.name}</td>
                                        <td>{getNameFromCountryCode(group.country)}</td>
                                        <td>{group.hasOwnProperty('validFrom') ? moment(group.validFrom).format('YYYY-MM-DD') : null}</td>
                                        <td>{group.hasOwnProperty('validFrom') ? null : 'Draft in progress'}</td>
                                        <td>
                                            {group.hasOwnProperty('validFrom') ?
                                                <Link to={`/admin/zone-management/Box/${group.name}/${group.id}/view`} className="pull-right">
                                                    View
                                                </Link>
                                                :
                                                <Link className="pull-right goto-previous-link" onClick={() => this.goToPreviousGroup(group.id)}>
                                                    View
                                                </Link>
                                            }
                                        </td>
                                        <td>
                                            {!group.hasOwnProperty('validFrom') ?
                                                <Link to={`/admin/zone-management/Box/${group.name}/${group.id}/edit`} className="btn btn-default edit-draft">
                                                    <Glyphicon glyph='pencil' /> Edit
                                                </Link>
                                                :
                                                <Button className="btn btn-default new-draft" onClick={() => this.onConfirmCreateDraft(group)}>
                                                    <Glyphicon glyph='plus' /> New
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <Grid fluid>
                <Loader color="#bfbfbf" loaded={!this.state.loading}>
                    {this.renderTable()}
                </Loader>
                {this.renderConfirmCreateDraft()}
            </Grid>
        )
    }

    renderConfirmCreateDraft() {
        const { showConfirmCreateDraftModal, groupToBaseDraftOn } = this.state

        return (
            <Confirm
                show={showConfirmCreateDraftModal}
                title="Create draft"
                bsStyle="success"
                onClose={() => this.setState({ showConfirmCreateDraftModal: false })}
                onConfirm={() => {
                    this.onCreateDraft()
                    this.setState({ showConfirmCreateDraftModal: false })
                }}
            >
                {`Are you sure you want to create a new draft for ${groupToBaseDraftOn.name}?`}
            </Confirm>
        )
    }
}

const mapStateToProps = ({
    zoneManagement,
}) => ({
    zoneManagement,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagementListHandler)

ZoneManagementListHandler.propTypes = {
    router: PropTypes.object,
    actions: PropTypes.object,
    zoneManagement: PropTypes.object,
}
