import React from 'react'
import { Col } from 'react-bootstrap'
import AddCouponsForm from './components/add-coupons-form'

const Coupons = () => (
    <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
        <AddCouponsForm />
    </Col>
)

export default Coupons
