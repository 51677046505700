import { ROLES_LOADED } from '../constants/actionTypes'
import dataState from './initialState/dataState'

export default function UserReducer(state = dataState, action) {
    switch (action.type) {
        case ROLES_LOADED:
            return { ...state, data: action.response }

        default:
            return state
    }
}
