import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col, Button } from 'react-bootstrap'
import Loader from 'react-loader'
import { connect } from 'react-redux'
import HtmlTitle from '../html-title'
import Alert from '../alert'
import Holiday from './holiday'
import CreateHoliday from './create'
import {
    createHoliday,
    fetchHolidays,
    unlockHoliday,
    removeUnlock,
    removeHoliday,
    setCities,
    changeLockerHolidayUnblockCities,
} from '../../actions/holidays'
import { fetchRegionalSettings } from '../../actions/regional-settings'
import { fetchMerchants } from '../../actions/merchants'
import auth from '../../auth'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER } from '../../utils/role'

/**
    Renders all future Public Holidays.
    Allows User to add new Holidays.
*/
class Holidays extends Component {
    static contextTypes = {
        router() {},
    }

    static propTypes = {
        dispatch: PropTypes.func,
        loading: PropTypes.bool,
        error: PropTypes.string,
        holidays: PropTypes.array,
        cities: PropTypes.array,
        buyers: PropTypes.array,
    }

    state = {
        creating: false,
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER)
        if (!hasAuth) {
            this.router.push('/admin')
            return
        }

        const { dispatch } = this.props
        dispatch(fetchHolidays())
        dispatch(fetchRegionalSettings())
        dispatch(fetchMerchants())
    }

    onCreate = (holiday) => {
        const { dispatch } = this.props
        dispatch(createHoliday(holiday))
        this.setState({ creating: false })
    }

    onCitiesChange = (holiday, cities) => {
        const { dispatch } = this.props
        dispatch(setCities(holiday, cities))
    }

    onAddUnlock = (holiday, unlock) => {
        const { dispatch } = this.props
        dispatch(unlockHoliday(holiday, unlock.merchant.value, unlock.city.value))
    }

    onRemoveUnlock = (holiday, unlock) => {
        const { dispatch } = this.props
        dispatch(removeUnlock(holiday, unlock.merchantId, unlock.city))
    }

    onRemoveHoliday = (holiday) => {
        if (window.confirm('Are you sure you want to remove this Holiday?')) {
            const { dispatch } = this.props
            dispatch(removeHoliday(holiday))
        }
    }

    onAllowLockerCitiesChange = (holidayId, cities) => {
        const { dispatch } = this.props
        const selectedCityIds = cities.map((city) => city.value)
        const currentUnblockedCities = this.props.holidays
            .find((holiday) => holiday.holiday.id === holidayId)
            .unblockedLockerCities.map((unblock) => unblock.city)
        const addedCities = selectedCityIds.filter((city) => !currentUnblockedCities.includes(city))
        const removedCities = currentUnblockedCities.filter((city) => !selectedCityIds.includes(city))
        dispatch(changeLockerHolidayUnblockCities(holidayId, addedCities, removedCities))
    }

    renderHoliday = (holiday) => {
        const { buyers, cities } = this.props

        return (
            <Holiday
                key={holiday.holiday.id}
                holiday={holiday.holiday}
                unlocked={holiday.unlocked}
                allowedLockerCities={holiday.unblockedLockerCities}
                cities={cities}
                buyers={buyers}
                onCitiesChange={this.onCitiesChange}
                onAddUnlock={this.onAddUnlock}
                onAllowLockerCitiesChange={this.onAllowLockerCitiesChange}
                onRemoveUnlock={this.onRemoveUnlock}
                onRemoveHoliday={this.onRemoveHoliday}
            />
        )
    }

    renderCreateHoliday() {
        const { creating } = this.state

        if (!creating) {
            return (
                <Button bsStyle="primary" className="pull-right" onClick={() => this.setState({ creating: true })}>
                    Create new Holiday
                </Button>
            )
        }

        return <CreateHoliday onCreate={this.onCreate} onCancel={() => this.setState({ creating: false })} />
    }

    render() {
        return (
            <div>
                <HtmlTitle title="Public Holidays" />
                <Loader color="#BFBFBF" loaded={!this.props.loading}>
                    <Grid fluid>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                {this.renderCreateHoliday()}
                            </Col>
                        </Row>
                        <Row className="bump-down">
                            <Col md={8} mdOffset={2}>
                                {this.props.error && <Alert type="danger" message={this.props.error} />}
                                {this.props.holidays.map(this.renderHoliday)}
                            </Col>
                        </Row>
                    </Grid>
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = ({ holidays: { loading, error, holidays }, regionalSettings, buyers: { buyers } }) => ({
    loading,
    error,
    holidays,
    cities: regionalSettings.cities,
    buyers,
})

export default connect(mapStateToProps)(Holidays)
