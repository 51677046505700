import React, { Component } from 'react'
import Loader from 'react-loader'
import { Col, Row } from 'react-bootstrap'
import PropTypes from 'react/lib/ReactPropTypes'
import connect from 'react-redux/es/connect/connect'
import FixedCosts from '../invoices/index'
import auth from '../../auth'
import { fetchOwnerWebapi } from '../../actions/owner'
import { handleError } from '../../utils/handle-error'
import {
    getAllRecurringInvoicesForCourier,
    getAllInvoicesForCourier,
    createInvoiceForCourier,
    deleteInvoice,
    createRecurringInvoiceForCourier,
    deleteRecurringInvoice,
    uploadCourierInvoicesFromFile,
} from '../../utils/invoice-webapi'

import { fetchInvoiceSettings } from '../../actions/regional-settings'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

export const INVOICE_TYPES_COURIER = [
    { value: 'DEBIT', label: 'Debit + (Courier will be payed)' },
    { value: 'CREDIT', label: 'Credit - (We will deduct this from Couriers next payment)' },
]

const toOption = ({ id: value, label }) => ({ value, label })

class CourierInvoiceItems extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            file: null,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.props.router.push('/admin/couriers')
            return
        }
        const { dispatch } = this.props
        dispatch(fetchOwnerWebapi(this.props.params.id))
        dispatch(fetchInvoiceSettings())
    }

    onFormSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', this.state.file)
        uploadCourierInvoicesFromFile(formData, this.props.courier.id)
            .then(() => {
                alert('Success')
            })
            .catch((error) => {
                handleError(error)
                alert('Failure, check logs for error')
            })
    }

    onChange = ({
        target: {
            files: [file],
        },
    }) => {
        this.setState({ file })
    }

    render() {
        if (this.props.loading) {
            return <Loader color="#BFBFBF" />
        }

        return (
            <Col>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <h2> Manage invoices for courier: {this.props.courier.name}</h2>
                    </Col>
                </Row>
                <FixedCosts
                    invoiceRecipientId={this.props.courier.id}
                    loadRecurringInvoiceItems={getAllRecurringInvoicesForCourier}
                    loadAllInvoiceItems={getAllInvoicesForCourier}
                    createInvoiceItem={createInvoiceForCourier}
                    deleteInvoiceItem={deleteInvoice}
                    createRecurringInvoiceItem={createRecurringInvoiceForCourier}
                    deleteRecurringInvoiceItem={deleteRecurringInvoice}
                    invoiceTypes={INVOICE_TYPES_COURIER}
                    invoiceArticles={this.props.invoiceArticles.map(toOption)}
                />
                <Row>
                    <Col md={10} mdOffset={1}>
                        <form onSubmit={this.onFormSubmit}>
                            <h4>Upload from file</h4>
                            <input type="file" onChange={this.onChange} />
                            <button type="submit">Upload a valid csv file</button>
                        </form>
                    </Col>
                </Row>
            </Col>
        )
    }
}
CourierInvoiceItems.propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
    courier: PropTypes.object,
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    invoiceArticles: PropTypes.array,
}

const mapStateToProps = ({
    owners: { loading, error, single },
    invoiceSettings: {
        articles: { courier: invoiceArticles },
    },
}) => ({
    loading,
    error,
    courier: single,
    invoiceArticles,
})

export default connect(mapStateToProps)(CourierInvoiceItems)
