import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.tags-v2+json'

export const getAllTags = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('tags')
        .get()
