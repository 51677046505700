import Webapi from './webapi'
import auth from '../auth'

const merchantsV1 = 'application/vnd.budbee.merchants-v1+json'

const webapi = (accept, contentType = accept) => {
    let w = new Webapi()
        .authorization(auth.get())
        .path('shopping')
        .path('collection-group')

    if (accept != null) {
        w.accept(accept)
    }
    if (contentType != null) {
        w = w.contentType(contentType)
    }

    return w
}

export const getCollectionGroup = (countryCode) => {
    return webapi(merchantsV1, merchantsV1)
        .queryParam('country-code', countryCode)
        .get()
}

export const createCollectionGroup = (request) => {
    return webapi(merchantsV1, merchantsV1).post(request)
}

export const updateCollectionGroup = (groupId, request) => {
    return webapi(merchantsV1, merchantsV1)
        .path(groupId)
        .put(request)
}

export const deleteCollectionGroup = (groupId) => {
    return webapi(merchantsV1, merchantsV1)
        .path(groupId)
        .delete()
}

export const createCollection = (groupId, request) => {
    return webapi(merchantsV1, merchantsV1)
        .path(groupId)
        .path('collection')
        .post(request)
}

export const updateCollection = (groupId, collectionId, request) => {
    return webapi(merchantsV1, merchantsV1)
        .path(groupId)
        .path('collection')
        .path(collectionId)
        .put(request)
}

export const deleteCollection = (groupId, collectionId) => {
    return webapi(merchantsV1, merchantsV1)
        .path(groupId)
        .path('collection')
        .path(collectionId)
        .delete()
}

export const addCollectionImage = (groupId, collectionId, file) => {
    const fd = new FormData()
    fd.append('file', file)
    return webapi(null, null)
        .path(groupId)
        .path('collection')
        .path(collectionId)
        .path('image')
        .post(fd, false)
}
