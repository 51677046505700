import React, { Component } from 'react'
import hash from 'object-hash'
import { Table, Button, Navbar, Nav } from 'react-bootstrap/lib'
import DatePicker from 'react-datepicker'
import { Alert, FormControl, FormGroup, ControlLabel, Panel, Checkbox } from 'react-bootstrap'
import { Link } from 'react-router'
import moment from 'moment'
import Loader from 'react-loader'
import Select from 'react-select'
import { getOrdersToGeocodeByTerminal } from '../../utils/order-webapi'
import { saveGeocode } from '../../utils/geocoding-webapi'
import { handleError } from '../../utils/handle-error'
import CountrySelect from '../common/country-select'
import TerminalSelect from '../common/terminal-select'

const Status = {
    EDITED: 'EDITED',
    SAVED: 'SAVED',
    GEOCODING: 'GEOCODING',
    FAILED: 'FAILED',
    NA: 'NA',
}

const orderSearchLimit = [
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '1000', label: '1000' },
]

const toRowData = (responseOrder) => ({
    id: responseOrder.id,
    street: responseOrder.deliveryAddress.street,
    postalCode: responseOrder.deliveryAddress.postalCode,
    locality: responseOrder.deliveryAddress.city,
    country: responseOrder.deliveryAddress.countryCode,
    status: Status.NA,
    coordinate: {
        latitude: '',
        longitude: '',
    },
    navigationCoordinate: {
        latitude: '',
        longitude: '',
    },
})

const hashPlc = (data) =>
    hash({
        postalCode: data.postalCode,
        locality: data.locality,
        country: data.country,
    })

const toRow = (responseOrder) => {
    const rowData = toRowData(responseOrder)
    return {
        id: responseOrder.id,
        token: responseOrder.token,
        address: responseOrder.deliveryAddress.id,
        hash: hash(rowData),
        plcHash: hashPlc(rowData),
        allowVariation: true,
        isVariation: false,
        originalStreet: responseOrder.deliveryAddress.street,
        data: rowData,
    }
}

const resetGeocodeData = () => ({
    streetName: '',
    houseNumber: '',
    locality: '',
    postalCode: '',
    latitude: '',
    longitude: '',
})

const orderGeocodeData = (order) => ({
    streetName: order.data.street,
    houseNumber: '',
    locality: order.data.locality,
    postalCode: order.data.postalCode,
    latitude: order.data.coordinate.latitude,
    longitude: order.data.coordinate.longitude,
})

export default class MissingCoordinates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 'N/A',
            searching: false,
            startDate: moment(),
            endDate: moment(),
            orders: [],
            orderToResolve: null,
            geocodeData: resetGeocodeData(),
            statuses: {},
            findNAOrders: false,
            excludeCancelledConsignments: false,
            countryFilter: {
                value: localStorage.getItem('countryFilter.value'),
                label: localStorage.getItem('countryFilter.label'),
            },
            terminals: null,
            noOfOrders: orderSearchLimit[0],
        }
        this.renderRows = this.renderRows.bind(this)
        this.renderForm = this.renderForm.bind(this)
        this.renderTable = this.renderTable.bind(this)
        this.renderStartPicker = this.renderStartPicker.bind(this)
        this.renderEndPicker = this.renderEndPicker.bind(this)
        this.search = this.search.bind(this)
        this.geocodeMissing = this.geocodeMissing.bind(this)
        this.processGeocodeResponse = this.processGeocodeResponse.bind(this)
        this.reattemptAll = this.reattemptAll.bind(this)
        this.updateGeocodingStatus = this.updateGeocodingStatus.bind(this)
    }

    payloadVerifiedLocation = (latitude, longitude) => {
        if (latitude.trim() === '' || longitude.trim() === '') {
            return null
        }
        const coordinate = { latitude, longitude }
        return { coordinate, navigationCoordinate: coordinate }
    }

    search = () => {
        this.setState({ searching: true }, () => {
            const distinctOrders = (orders) => {
                const newList = []
                orders
                    .filter(
                        (o) =>
                            this.state.countryFilter === null ||
                            o.data.country.trim().toUpperCase() === this.state.countryFilter.value,
                    )
                    .forEach((order) => {
                        if (newList.filter((no) => no.id === order.id).length === 0) {
                            newList.push(order)
                        }
                    })
                return newList
            }
            const resetStatuses = (orders) => orders.map((order) => ({ [order.id]: Status.NA }))
            const start = this.state.startDate == null ? null : this.state.startDate.startOf('day').valueOf()
            const end = this.state.endDate == null ? null : this.state.endDate.endOf('day').valueOf()

            getOrdersToGeocodeByTerminal(
                start,
                end,
                this.state.findNAOrders,
                // exclusion can only be done if findNAOrders is checked.
                this.state.findNAOrders ? this.state.excludeCancelledConsignments : false,
                this.state.countryFilter === null ? null : this.state.countryFilter.value,
                this.state.terminals,
                this.state.noOfOrders.value,
            )
                .then(({ count, orders }) => {
                    this.setState({
                        searching: false,
                        count,
                        orders: distinctOrders(orders.map(toRow)),
                        statuses: resetStatuses(orders),
                    })
                })
                .catch((err) => {
                    this.setState({ searching: false })
                    handleError(err)
                })
        })
    }

    displayAddress = (order) =>
        [`${order.data.street},`, order.data.postalCode, `${order.data.locality},`, order.data.country].join(' ')

    updateGeocodingStatus(orderId, status) {
        return { ...this.state.statuses, [orderId]: status }
    }

    processGeocodeResponse(response) {
        const { statuses } = this.state
        const newStatuses = Object.keys(response).reduce(
            (acc, id) => ({
                ...acc,
                [parseInt(id, 10)]: response[id] ? Status.SAVED : Status.FAILED,
            }),
            statuses,
        )
        this.setState({ statuses: newStatuses })
    }

    reattemptAll() {
        const { orders, statuses } = this.state
        const requests = orders.map((order) => ({
            orderId: order.id,
            request: {
                street: order.data.street,
                postalCode: order.data.postalCode,
                locality: order.data.locality,
                country: order.data.country,
            },
        }))
        requests.forEach((request) => {
            this.setState({ statuses: { ...statuses, [request.orderId]: Status.GEOCODING } })
            saveGeocode([request])
                .then(this.processGeocodeResponse)
                .catch(handleError)
        })
    }

    geocodeMissing() {
        const { orderToResolve, geocodeData, statuses } = this.state
        const payload = {
            orderId: orderToResolve.id,
            request: {
                street: `${geocodeData.streetName} ${geocodeData.houseNumber}`,
                postalCode: geocodeData.postalCode,
                locality: geocodeData.locality,
                country: orderToResolve.data.country,
                verifiedStreetAddress: {
                    streetName: geocodeData.streetName,
                    houseNumber: geocodeData.houseNumber,
                    originalAsVariation: false,
                },
                verifiedLocation: this.payloadVerifiedLocation(geocodeData.latitude, geocodeData.longitude),
            },
        }
        this.setState({ statuses: { ...statuses, [orderToResolve.id]: Status.GEOCODING } })
        saveGeocode([payload])
            .then(this.processGeocodeResponse)
            .catch(handleError)
    }

    missingGeocodingData() {
        const { geocodeData } = this.state
        const missing = []
        if (!geocodeData.streetName) {
            missing.push('street name')
        }

        if (!geocodeData.houseNumber) {
            missing.push('house number')
        }

        if (!geocodeData.locality) {
            missing.push('locality')
        }

        if (!geocodeData.postalCode) {
            missing.push('postal code')
        }

        return missing
    }

    renderStartPicker() {
        return (
            <DatePicker
                // className="form-control"
                dateFormat="YYYY-MM-DD"
                minDate={moment().subtract(7, 'days')}
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.endDate}
                placeholder="Select Start Date"
                onChange={(startDate) => this.setState({ startDate: moment(startDate) })}
            />
        )
    }

    renderEndPicker() {
        return (
            <DatePicker
                // className="form-control"
                dateFormat="YYYY-MM-DD"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                placeholder="Select Start Date"
                onChange={(endDate) => this.setState({ endDate: moment(endDate) })}
            />
        )
    }

    renderCountryPicker() {
        return (
            <CountrySelect
                value={this.state.countryFilter}
                onSelect={(country) => {
                    this.setState({ countryFilter: country })
                    localStorage.setItem('countryFilter.label', country.label)
                    localStorage.setItem('countryFilter.value', country.value)
                }}
            />
        )
    }

    renderTerminalPicker() {
        const { countryFilter, terminals } = this.state
        return (
            <TerminalSelect
                onSelect={(terminal) => this.setState({ terminals: { ...terminals, terminal } })}
                multi
                countryFilter={countryFilter !== null ? countryFilter.value : null}
            />
        )
    }

    renderForm() {
        return (
            <div>
                <Navbar className="geocoder-navbar">
                    <Nav>
                        <Navbar.Form>
                            <FormGroup>
                                <ControlLabel>Country</ControlLabel>
                                {this.renderCountryPicker()}
                                <br />
                                <ControlLabel>Start &nbsp;&nbsp;</ControlLabel> {this.renderStartPicker()}
                            </FormGroup>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormGroup>
                                <ControlLabel>Terminals</ControlLabel>
                                {this.renderTerminalPicker()}
                                <br />
                                <ControlLabel>Stop &nbsp;&nbsp;</ControlLabel> {this.renderEndPicker()}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button onClick={this.search} disabled={this.state.searching}>
                                    Search
                                </Button>
                            </FormGroup>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <FormGroup>
                                <ControlLabel title="Orders that have not yet been booked for specific delivery date.">
                                    <Checkbox
                                        checked={this.state.findNAOrders}
                                        onChange={(e) => this.setState({ findNAOrders: e.target.checked })}
                                    />
                                    &nbsp;&nbsp; Include N/A orders
                                </ControlLabel>
                                <br />
                                <ControlLabel title="Exclude orders whose consignments have been cancelled.">
                                    <Checkbox
                                        checked={this.state.excludeCancelledConsignments}
                                        disabled={!this.state.findNAOrders}
                                        onChange={(e) =>
                                            this.setState({ excludeCancelledConsignments: e.target.checked })
                                        }
                                    />
                                    &nbsp;&nbsp; Exclude cancelled consignments
                                </ControlLabel>
                                <br />
                                <ControlLabel>Number of Orders &nbsp;&nbsp;</ControlLabel>
                                <Select
                                    value={this.state.noOfOrders}
                                    onChange={(e) => this.setState({ noOfOrders: e })}
                                    options={orderSearchLimit}
                                />
                            </FormGroup>
                        </Navbar.Form>
                    </Nav>
                </Navbar>
                <Loader color="#BFBFBF" loaded={!this.state.searching} />
            </div>
        )
    }

    renderOrderLink = (token) => (
        <Link rel="noopener noreferrer" target="_blank" to={`/admin/orders/${token}`}>
            {token}
        </Link>
    )

    renderRows() {
        const { statuses } = this.state
        const onResolve = (order) => {
            this.setState({
                orderToResolve: order,
                geocodeData: orderGeocodeData(order),
                statuses: this.updateGeocodingStatus(order.id, Status.NA),
            })
        }

        const statusCss = (status) => {
            if (status === Status.NA) {
                return ''
            }
            if (status === Status.SAVED) {
                return 'geocoder-resolved_row'
            }
            if (status === Status.GEOCODING) {
                return 'geocoder-geocoding_row'
            }
            if (status === Status.FAILED) {
                return 'geocoder-failed_row'
            }

            return undefined
        }
        const renderRow = (order) => {
            const status = statuses[order.id]
            return (
                <tr key={order.token}>
                    <td>{this.renderOrderLink(order.token)}</td>
                    <td className={`geocoder-missing_row ${statusCss(status)}`}>
                        <span>{this.displayAddress(order)}</span>
                        {status !== Status.SAVED ? <Button onClick={() => onResolve(order)}>resolve</Button> : null}
                    </td>
                </tr>
            )
        }
        return this.state.orders.map(renderRow)
    }

    renderGeocodingStatus() {
        const { statuses } = this.state
        const status = statuses[this.state.orderToResolve.id]
        const alert = (level, text) => (
            <div style={{ marginTop: '10px' }}>
                <Alert bsStyle={level}>{text}</Alert>
            </div>
        )
        if (status === Status.GEOCODING) {
            return alert('info', 'Attempting to Geocode...')
        }
        if (status === Status.SAVED) {
            return alert('success', 'Successfully Geocoded!')
        }
        if (status === Status.FAILED) {
            return alert('danger', 'Failed to Geocode')
        }
        return null
    }

    renderEditor() {
        const { orderToResolve, geocodeData } = this.state
        if (!orderToResolve) {
            return <div className="geocoder-editor" />
        }
        const updateGeocodeData = (field, value) => {
            this.setState({ geocodeData: { ...geocodeData, [field]: value } })
        }
        const missingData = this.missingGeocodingData()
        return (
            <div className="geocoder-editor">
                <Panel header="Geocode">
                    <div style={{ marginBottom: '15px' }}>
                        Resolve order {this.renderOrderLink(orderToResolve.token)}:{this.displayAddress(orderToResolve)}
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 3, marginRight: '30px' }}>
                            <span>
                                Street Name <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.streetName}
                                onChange={(e) => updateGeocodeData('streetName', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>
                                House Number <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.houseNumber}
                                onChange={(e) => updateGeocodeData('houseNumber', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 3, marginRight: '30px' }}>
                            <span>
                                Locality <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.locality}
                                onChange={(e) => updateGeocodeData('locality', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>
                                Postal Code <span className="geocoder-required">required</span>
                            </span>
                            <FormControl
                                type="text"
                                value={geocodeData.postalCode}
                                onChange={(e) => updateGeocodeData('postalCode', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-input_row">
                        <div style={{ flex: 1, marginRight: '30px' }}>
                            <span>Latitude</span>
                            <FormControl
                                type="text"
                                value={geocodeData.latitude}
                                onChange={(e) => updateGeocodeData('latitude', e.target.value)}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span>Longitude</span>
                            <FormControl
                                type="text"
                                value={geocodeData.longitude}
                                onChange={(e) => updateGeocodeData('longitude', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="geocoder-geocode">
                        <Button disabled={missingData.length !== 0} onClick={this.geocodeMissing}>
                            Geocode
                        </Button>
                        {this.renderGeocodingStatus()}
                    </div>
                </Panel>
            </div>
        )
    }

    renderTable() {
        const { count: numOfOrders } = this.state
        if (this.state.orders.length === 0) {
            return null
        }

        const header = (
            <div className="geocoder-table-btn-header">
                <span>{`Search (${numOfOrders} results)`}</span>
                <Button onClick={this.reattemptAll}>Re-attempt All</Button>
            </div>
        )
        return (
            <div className="geocoder-table">
                <Panel header={header}>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Order</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderRows()}</tbody>
                    </Table>
                </Panel>
            </div>
        )
    }

    renderWorksace() {
        return (
            <div className="geocoder-workspace">
                {this.renderTable()}
                {this.renderEditor()}
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderWorksace()}
            </div>
        )
    }
}
