import React from 'react'
import PropTypes from 'prop-types'
import Address from './address'

/**
 * Render a Collection Point
 */
const CollectionPoint = ({ collectionPoint }) => {
    if (collectionPoint == null) {
        return null
    }

    const { address } = collectionPoint

    return (
        <div>
            <strong>Collection Point Name:</strong>{' '}
            <span title={`id:${collectionPoint.id}`}>{collectionPoint.name}</span> <br />
            <Address address={address} editable={false} isMerchantAddress />
        </div>
    )
}

CollectionPoint.propTypes = {
    collectionPoint: PropTypes.object,
}

export default CollectionPoint
