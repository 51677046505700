import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Alert from 'react-bootstrap/lib/Alert'

export default class UserProfilePictureWrapper extends React.Component {
    constructor(props) {
        super(props)
        this.state = { alertVisible: false, imageDeleted: false }
    }

    _renderUserImage() {
        const { imageUrl } = this.props
        if (imageUrl) {
            return <img src={imageUrl} className="img-circle" style={{ maxHeight: 140 }} />
        }
    }

    handleAlertDismiss() {
        this.setState({ alertVisible: false })
    }

    handleAlertShow() {
        this.setState({ alertVisible: true })
    }

    _renderDeleteImageButton() {
        if (this.state.imageDeleted || !this.props.showDeleteImgButton) {
            return null
        }

        if (this.state.alertVisible) {
            return (
                <Alert bsStyle="danger" onDismiss={() => this.handleAlertDismiss()}>
                    <h4>Do you really want to remove the profile picture?</h4>
                    <p>
                        <Button onClick={this.props.deleteUserImage} bsStyle="danger">
                            Yes
                        </Button>
                        <Button onClick={() => this.handleAlertDismiss()}>Cancel</Button>
                    </p>
                </Alert>
            )
        }
        return (
            <Button bsStyle="danger" onClick={() => this.handleAlertShow()}>
                Delete Photo
            </Button>
        )
    }

    render() {
        return (
            <div>
                {this._renderUserImage()}
                <br />
                <br />
                {this._renderDeleteImageButton()}
                <br />
                <br />
            </div>
        )
    }
}
