import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

export default class ShoppingList extends Component {
    renderList = (arr) => {
        return arr.map((item, index) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                    <td>
                        <p style={{ display: 'inline-block', paddingLeft: '8px' }}>{item}</p>
                    </td>
                </tr>
            )
        })
    }

    brands = (brands) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Brands: {brands.length}</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(brands)}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    keywords = (keywords) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Keywords: {keywords.length}</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(keywords)}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render() {
        const { scarpingResult } = this.props
        if (this.props.error) {
            return <p>scraping failed</p>
        }

        return (
            <div>
                <h4>Scraped Result</h4>
                <p>
                    Scraped time: <b>{scarpingResult.timestamp}</b>
                </p>
                {this.brands(scarpingResult.brands)}
                {this.keywords(scarpingResult.keywords)}
            </div>
        )
    }
}

ShoppingList.propTypes = {
    error: PropTypes.shape({}),
    scarpingResult: PropTypes.shape({
        brands: PropTypes.arrayOf(PropTypes.string),
        keywords: PropTypes.arrayOf(PropTypes.string),
        timestamp: PropTypes.string,
    }),
}
