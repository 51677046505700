import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Grid,
    Row,
    Col,
    Glyphicon,
    Button,
    ButtonToolbar,
    Panel,
    Image,
    Modal,
    FormGroup,
    ControlLabel,
    FormControl,
    Table,
} from 'react-bootstrap'
import moment from 'moment'
import * as actions from '../../actions/user'
import Alert from '../alert'
import HtmlTitle from '../html-title'
import Password from '../login/password'
import auth from '../../auth'
import { DATE_FORMAT } from '../../utils/date'
import Confirm from '../confirm'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../utils/role'

class View extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPasswordModal: false,
            currentPassword: '',
            newPassword: '',
            validNewPassword: false,
            showDeleteDriverFeedbackConfirm: false,
            showDeleteUserConfirm: false,
            feedbackIdToRemove: -1,
        }
    }

    canEdit = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    canEditDriverFeedback = auth.hasAnyRole(TRAFFIC_CONTROLLER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    renderDeleteDriverFeedbackConfirmation() {
        const { showDeleteDriverFeedbackConfirm, feedbackIdToRemove } = this.state
        const { user } = this.props

        return (
            <Confirm
                show={showDeleteDriverFeedbackConfirm}
                title="Delete driver feedback"
                bsStyle="danger"
                onClose={this.closeDeleteUserFeedbackConfirm}
                onConfirm={() => {
                    this.props.actions.deleteDriverFeedback(user.id, feedbackIdToRemove)
                    this.closeDeleteUserFeedbackConfirm()
                }}
            >
                Are you sure you want to delete this feedback? It cannot be reverted.
            </Confirm>
        )
    }

    renderDeleteUserConfirmation() {
        const { showDeleteUserConfirm } = this.state
        const { user } = this.props

        return (
            <Confirm
                show={showDeleteUserConfirm}
                title="Delete user"
                bsStyle="danger"
                onClose={this.closeDeleteUserConfirm}
                onConfirm={() => {
                    this.props.actions.deleteUser(user.id)
                    setTimeout(() => {
                        this.props.router.push('/admin/users')
                    }, 1000)
                }}
            >
                Are you sure you want to suspend this user? It cannot be reverted.
            </Confirm>
        )
    }

    componentDidMount() {
        this.props.actions.fetchCurrentUser()
        this.props.actions.fetchUser(this.props.params.id)
        this.props.actions.fetchDriverFeedbackById(this.props.params.id)
    }

    changePassword = () => {
        this.props.actions.changePassword(this.props.params.id, this.state.currentPassword, this.state.newPassword)
    }

    onCurrentPasswordChange = (e) => {
        this.setState({ currentPassword: e.target.value })
    }

    onNewPasswordChange = (result) => {
        this.setState({ newPassword: result.password, validNewPassword: result.valid })
    }

    openPasswordModal = () => {
        this.setState({ showPasswordModal: true })
    }

    closePasswordModal = () => {
        this.setState({ showPasswordModal: false })
    }

    openDeleteUserConfirm = () => {
        this.setState({ showDeleteUserConfirm: true })
    }

    closeDeleteUserConfirm = () => {
        this.setState({ showDeleteUserConfirm: false })
    }

    openDeleteUserFeedbackConfirm = (id) => {
        this.setState({ showDeleteDriverFeedbackConfirm: true, feedbackIdToRemove: id })
    }

    closeDeleteUserFeedbackConfirm = () => {
        this.setState({ showDeleteDriverFeedbackConfirm: false })
    }

    renderDeleteUser() {
        if (this.props.user.editable == null || !this.props.user.editable) {
            return null
        }

        return (
            <Button bsStyle="danger" onClick={this.openDeleteUserConfirm}>
                <Glyphicon glyph="trash" /> Suspend Account
            </Button>
        )
    }

    renderEditPassword() {
        if (this.props.user.editable == null || !this.props.user.editable) {
            return null
        }

        return (
            <Button onClick={this.openPasswordModal}>
                <Glyphicon glyph="lock" /> Change Password
            </Button>
        )
    }

    renderEditUser() {
        if (this.props.user.editable == null || !this.props.user.editable) {
            return null
        }

        return (
            <Link className="btn btn-primary" to={`/admin/users/${this.props.user.id}/edit`}>
                <Glyphicon glyph="pencil" /> Edit User
            </Link>
        )
    }

    renderTableRows = (item) => {
        const date = moment.utc(item.createdAt)

        return (
            <tr key={item.id}>
                <td>{date.format(DATE_FORMAT)}</td>
                <td>
                    <Link to={`/admin/orders/${item.orderToken}`}>{item.orderToken}</Link>
                </td>
                <td>{item.category}</td>
                <td>{item.issueType}</td>
                <td>{item.comment}</td>
                <td>{item.createdBy}</td>
                <td>
                    <Glyphicon
                        style={{ color: '#d9534f', cursor: 'pointer' }}
                        onClick={() => this.openDeleteUserFeedbackConfirm(item.id)}
                        glyph="remove"
                    />
                </td>
            </tr>
        )
    }

    renderPasswordModal() {
        const { firstName, lastName, email, phoneNumber } = this.props.user

        let alert
        if (this.props.changePasswordStatus === 200) {
            alert = <Alert type="success" message="Password changed" />
        } else if (this.props.changePasswordStatus === 403) {
            alert = <Alert type="danger" message="Wrong password / permission denied" />
        }

        return (
            <Modal show={this.state.showPasswordModal} onHide={this.closePasswordModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        <ControlLabel>Current password</ControlLabel>
                        <FormControl
                            type="password"
                            placeholder="Current password"
                            value={this.state.currentPassword}
                            onChange={this.onCurrentPasswordChange}
                        />
                    </FormGroup>

                    <Password
                        label="New password"
                        placeholder="New password"
                        userInputs={[firstName, lastName, email, phoneNumber]}
                        onChange={this.onNewPasswordChange}
                    />

                    <Button
                        bsStyle="primary"
                        disabled={this.state.currentPassword.length <= 0 || !this.state.validNewPassword}
                        onClick={this.changePassword}
                    >
                        Save
                    </Button>
                    <Button onClick={this.closePasswordModal}>Cancel</Button>

                    <div className="bump-down">{alert}</div>
                </Modal.Body>
                <Modal.Footer>
                    <small>
                        Drivers can reset their own password using the Forgot password functionality in the Driver
                        Application
                    </small>
                </Modal.Footer>
            </Modal>
        )
    }

    renderPersonalInfo() {
        return (
            <Panel className="bump-down">
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={6}>
                                <dl>
                                    <dt>First Name</dt>
                                    <dd>{this.props.user.firstName}</dd>
                                </dl>
                            </Col>
                            <Col md={6}>
                                <dl>
                                    <dt>Last Name</dt>
                                    <dd>{this.props.user.lastName}</dd>
                                </dl>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <dl>
                                    <dt>Telephone Number</dt>
                                    <dd>
                                        <a href={`tel://${this.props.user.phoneNumber}`}>
                                            {this.props.user.phoneNumber}
                                        </a>
                                        <Link to={`/admin/sms?recipient=${this.props.user.phoneNumber}`}>(SMS)</Link>
                                    </dd>
                                </dl>
                            </Col>

                            <Col md={6}>
                                <dl>
                                    <dt>Email</dt>
                                    <dd>
                                        <a href={`mailto:${this.props.user.email}`}>{this.props.user.email}</a>
                                    </dd>
                                </dl>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={2} mdOffset={1}>
                        {this.props.user.userSettings.image ? (
                            <Image src={this.props.user.userSettings.image.url} circle responsive />
                        ) : null}
                    </Col>
                </Row>
            </Panel>
        )
    }

    renderSettings() {
        const roles = this.props.user.userRoles.map((r) => r.role.name).join(', ')

        return (
            <Panel>
                <Row>
                    <Col md={4}>
                        <dl>
                            <dt>Language</dt>
                            <dd>{this.props.user.userSettings.language}</dd>
                        </dl>
                    </Col>
                    <Col md={4}>
                        <dl>
                            <dt>Role(s)</dt>
                            <dd>{roles}</dd>
                        </dl>
                    </Col>
                    <Col md={4}>
                        <dl>
                            <dt>Works at Terminal</dt>
                            <dd>{this.props.user.warehouse ? this.props.user.warehouse.name : 'N/A'}</dd>
                        </dl>
                    </Col>
                </Row>
            </Panel>
        )
    }

    renderDriverFeedback() {
        const { driverFeedback } = this.props

        if (!driverFeedback || driverFeedback.length <= 0) {
            return (
                <div>
                    <p className="text-muted text-center">No driver feedback</p>
                </div>
            )
        }

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th width="10%">Created</th>
                        <th>Token</th>
                        <th>Category</th>
                        <th>Issue type</th>
                        <th width="20%">Comment</th>
                        <th width="12%">Created by</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody>{driverFeedback.map(this.renderTableRows)}</tbody>
            </Table>
        )
    }

    renderBuyers() {
        const buyers = this.props.user.connectedMerchants

        if (buyers.length === 0) {
            return (
                <div>
                    <p className="text-muted text-center">Not employed by any Merchant</p>
                </div>
            )
        }

        return (
            <div>
                {buyers.map((b) => (
                    <Link key={b.id} to={`/admin/merchants/${b.id}`}>
                        {b.name === b.externalName ? b.externalName : `${b.externalName} (${b.name})`}
                    </Link>
                ))}
            </div>
        )
    }

    renderOwnerOperators() {
        const ownerOperators = this.props.user.connectedOwnerOperators

        if (ownerOperators.length === 0) {
            return (
                <div>
                    <p className="text-muted text-center">Not employed by any Owner Operator</p>
                </div>
            )
        }
        return (
            <div>
                {ownerOperators.map((oo) => (
                    <Link key={oo.key} to={`/admin/couriers/${oo.key}`}>
                        {oo.value}
                    </Link>
                ))}
            </div>
        )
    }

    render() {
        if (undefined === this.props.user) {
            return null
        }

        return (
            <div>
                <HtmlTitle title={`${this.props.user.firstName} ${this.props.user.lastName}`} />
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <ButtonToolbar>
                                {this.renderEditUser()}
                                {this.renderEditPassword()}
                                {this.renderDeleteUser()}
                            </ButtonToolbar>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderPersonalInfo()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderSettings()}
                        </Col>
                    </Row>

                    {this.canEditDriverFeedback ? (
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <Panel header="Driver Feedback">{this.renderDriverFeedback()}</Panel>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col md={4} mdOffset={2}>
                            <Panel header="Employee of Merchant(s)">{this.renderBuyers()}</Panel>
                        </Col>

                        <Col md={4}>
                            <Panel header="Employee of Owner Operator(s)">{this.renderOwnerOperators()}</Panel>
                        </Col>
                    </Row>
                    {this.renderPasswordModal()}
                    {this.renderDeleteDriverFeedbackConfirmation()}
                    {this.renderDeleteUserConfirmation()}
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    me: state.users.current,
    driverFeedback: state.users.driverFeedback,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

View.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object,
    actions: PropTypes.object.isRequired,
    user: PropTypes.object,
    changePasswordStatus: PropTypes.number,
    driverFeedback: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
