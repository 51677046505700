import sortBy from 'lodash/sortBy'

const GROUP_TYPES = {
    HUB: 'HUB',
    REGION: 'REGION',
}

const isRegion = ({ enablementType }) => enablementType === GROUP_TYPES.REGION

const getRegions = (hubPallets) => hubPallets.map(({ terminals }) => terminals.filter(isRegion)).flat()

const getName = ({ label }) => label

const isPartOfRegion = ({ enablementType, terminals }, terminalId) =>
    enablementType === GROUP_TYPES.REGION && terminals.some(({ id }) => id === terminalId)

const isPartOfHub = ({ terminals }, terminalId) =>
    terminals.some((majorTerminal) => majorTerminal.id === terminalId || isPartOfRegion(majorTerminal, terminalId))

const excludeCurrentHub = (hubPallets, terminalId) =>
    hubPallets.filter((hubPallet) => !isPartOfHub(hubPallet, terminalId))

export const getHubAndRegionOptions = (hubPallets, terminalId) => {
    const filteredHubPallets = excludeCurrentHub(hubPallets, terminalId)

    const hubOptions = filteredHubPallets.map(({ name: label, groupTerminalId: groupWarehouseId }) => ({
        label,
        groupWarehouseId,
        groupWarehouseType: GROUP_TYPES.HUB,
    }))
    const regionOptions = getRegions(hubPallets).map(({ name: label, id: groupWarehouseId }) => ({
        label,
        groupWarehouseId,
        groupWarehouseType: GROUP_TYPES.REGION,
    }))

    return [...sortBy(hubOptions, getName), ...sortBy(regionOptions, getName)]
}
