import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/merchants'

const toOption = ({ id: value, externalName: label }) => {
    const idLabel = `${label} (ID:${value})`
    return { value, label: idLabel }
}

class MerchantSelect extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            selected: null,
        }
    }

    componentDidMount() {
        if (this.props.merchants.length === 0) {
            this.props.actions.fetchMerchants().then(() => {
                const { value: selectedId } = this.props
                this.setState({
                    selected: this.props.merchants.map(toOption).find((merchant) => merchant.value === selectedId),
                })
            })
        }
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            if (value.label != null || value.length != null) {
                this.setState({ selected: value })
            } else {
                this.setState({
                    selected: this.props.merchants.map(toOption).find((merchant) => merchant.value === value),
                })
            }
        }
    }

    onChange = (selected) => this.setState({ selected }, () => this.props.onSelect(this.state.selected))

    selectAll = () => this.onChange(this.props.merchants.map(toOption))

    clear = () => this.onChange(null)

    render() {
        const { multi, merchants } = this.props
        const { selected } = this.state

        const placeholder = multi ? 'Select merchants...' : 'Select merchant...'

        return (
            <Select
                disabled={this.props.disabled}
                placeholder={placeholder}
                value={selected}
                options={merchants.map(toOption)}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable
                multi={multi}
                isLoading={this.props.merchants.length === 0}
            />
        )
    }
}

MerchantSelect.propTypes = {
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    actions: PropTypes.object,
    merchants: PropTypes.array.isRequired,
}
MerchantSelect.defaultProps = {
    multi: true,
}

const mapStateToProps = ({ buyers: { buyers: merchants } }) => ({
    merchants,
})
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantSelect)
