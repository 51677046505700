import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AdjustPriceItem from './adjust-price-item'

const timeSpan = ({ validFrom, validTo }) => {
    const from = validFrom == null ? 'Always' : moment(validFrom).format('YYYY-MM-DD HH:mm')
    const to = validTo == null ? 'forever' : moment(validTo).format('YYYY-MM-DD HH:mm')
    return [from, ' -> ', to]
}

const renderTierSpan = (enumTier, prevWeight, tier, unit, quantityEnumMap) => {
    if (enumTier && quantityEnumMap) {
        return `${quantityEnumMap.get(tier.weight)}`
    }
    return (
        <div>
            {prevWeight + 1} - {tier.weight} {unit.code}
        </div>
    )
}

class TierRow extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            delete: false,
        }
        this.delete = this.delete.bind(this)
        this.trash = this.trash.bind(this)
        this.undo = this.undo.bind(this)
        this.undo = this.undo.bind(this)
    }

    trash() {
        this.setState({ delete: true })
    }

    undo() {
        this.setState({ delete: false })
    }

    delete() {
        const { tier, deletePriceTier } = this.props
        deletePriceTier(tier.type, tier.id)
        this.setState({ delete: false })
    }

    render() {
        const { i, tier, prevWeight, deletePriceTier, unit, onTierCheck, enumTier, quantityEnumMap } = this.props
        const renderCheckbox = onTierCheck != null
        return (
            <tr>
                <td>
                    {renderCheckbox ? (
                        <input
                            type="checkbox"
                            defaultChecked
                            onChange={({ target: { checked } }) => onTierCheck(tier.id, checked)}
                        />
                    ) : (
                        i
                    )}
                </td>
                <td>
                    <AdjustPriceItem
                        originalPrice={tier.fixedRate}
                        updatedPrice={tier.newFixedRate}
                        priceExpression={(price) => `${Number(price / 100).toFixed(2)} ${tier.currency}`}
                    />
                </td>
                {tier.incrementalRate ? (
                    <td>
                        <AdjustPriceItem
                            originalPrice={tier.incrementalRate}
                            updatedPrice={tier.newIncrementalRate}
                            priceExpression={(price) =>
                                `${Number(price / 100).toFixed(2)} ${tier.currency} per ${unit.code}`
                            }
                        />
                    </td>
                ) : (
                    <td> - </td>
                )}
                <td>{renderTierSpan(enumTier, prevWeight, tier, unit, quantityEnumMap)}</td>
                <td>{timeSpan(tier)}</td>
                <td style={{ width: 70 }} className={!deletePriceTier ? 'hide' : null}>
                    {this.state.delete ? (
                        <span>
                            <button type="button" className="btn btn-xs btn-primary" onClick={this.delete}>
                                <span className="glyphicon glyphicon-ok" />
                            </button>
                            <button type="button" className="btn btn-xs" onClick={this.undo}>
                                <span className="glyphicon glyphicon-remove" />
                            </button>
                        </span>
                    ) : (
                        <button type="button" className="btn btn-xs btn-danger" onClick={this.trash}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    )}
                </td>
            </tr>
        )
    }
}

TierRow.propTypes = {
    tier: PropTypes.object,
    i: PropTypes.number,
    prevWeight: PropTypes.number,
    deletePriceTier: PropTypes.func,
    unit: PropTypes.object.isRequired,
    onTierCheck: PropTypes.func,
    enumTier: PropTypes.bool.isRequired,
    quantityEnumMap: PropTypes.object,
}

export default TierRow
