import auth from '../auth'
import Webapi from './webapi'

const contentType = 'application/json'

export const getSocialMediaText = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('settings')
        .path('social-media')
        .get()

export const saveSocialMediaText = (edits) =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('settings')
        .path('social-media')
        .put(edits)
