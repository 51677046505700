import {
    FETCHING_HOLIDAYS,
    HOLIDAYS_LOADED,
    HOLIDAYS_FAILED_TO_LOAD,
    HOLIDAY_CREATED,
    HOLIDAY_REMOVED,
    HOLIDAY_CITIES_LOADED,
    HOLIDAY_CITIES_FAILED_TO_LOAD,
    HOLIDAY_UNLOCKED_FOR_MERCHANT,
    HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT,
    HOLIDAY_LOCKER_CITY_UNBLOCK_CHANGED,
} from '../constants/actionTypes'
import holidaysState from './initialState/holidays-state'

export default function HolidaysReducer(state = holidaysState, action) {
    switch (action.type) {
        case FETCHING_HOLIDAYS:
            return {
                ...state,
                loading: true,
                holidays: [],
            }

        case HOLIDAYS_LOADED:
            return {
                ...state,
                loading: false,
                error: null,
                holidays: action.payload,
            }

        case HOLIDAY_CITIES_LOADED:
            return {
                ...state,
                holidays: state.holidays.map((holiday) => {
                    if (holiday.holiday.id === action.holidayId) {
                        return {
                            ...holiday,
                            holiday: { ...holiday.holiday, cities: action.payload },
                        }
                    }
                    return holiday
                }),
                loading: false,
                error: null,
            }

        case HOLIDAY_UNLOCKED_FOR_MERCHANT:
            return {
                ...state,
                holidays: state.holidays.map((holiday) => {
                    const holidayCopy = { ...holiday }

                    holidayCopy.unlocked = upsertHolidayUnlock(holidayCopy.unlocked, action.payload)
                    return holidayCopy
                }),
            }

        case HOLIDAY_UNLOCK_REMOVED_FOR_MERCHANT:
            return {
                ...state,
                holidays: state.holidays.map((holiday) => {
                    const holidayCopy = { ...holiday }
                    const unlock = action.payload

                    holidayCopy.unlocked = holidayCopy.unlocked.filter(
                        (unlocked) => unlock.merchantId !== unlocked.merchantId || unlock.city !== unlocked.city,
                    )

                    return holidayCopy
                }),
            }
        case HOLIDAY_LOCKER_CITY_UNBLOCK_CHANGED:
            const holidayUnlocks = action.payload
            const updatedHolidayId = action.holidayId
            return {
                ...state,
                holidays: state.holidays.map((holiday) => {
                    if (holiday.holiday.id === updatedHolidayId) {
                        return { ...holiday, unblockedLockerCities: holidayUnlocks }
                    }
                    return holiday
                }),
            }
        case HOLIDAYS_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                holidays: [],
            }

        case HOLIDAY_CITIES_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case HOLIDAY_CREATED:
            return {
                ...state,
            }

        case HOLIDAY_REMOVED:
            return {
                ...state,
                holidays: state.holidays.filter((h) => h.holiday.id !== action.holiday.id),
            }

        default:
            return state
    }
}

const upsertHolidayUnlock = (holidayUnlocks, unlockToUpsert) => {
    const existingUnlock = holidayUnlocks.find(
        (unlock) =>
            unlock.id === unlockToUpsert.id &&
            unlock.merchantId === unlockToUpsert.merchantId &&
            unlock.city === unlockToUpsert.city,
    )

    if (!existingUnlock) {
        return holidayUnlocks.concat([unlockToUpsert])
    }
    return holidayUnlocks
}
