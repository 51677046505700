const SEARCH_FIELD_PLACEHOLDERS = {
    packageId: 'Search by package ID, ex: 373401099201380083',
    token: 'Search by order token, ex: xn3lf5m3o',
    merchantReference: 'Search by Cart ID, ex: 32801c74-d989-4v0a-96f2-89de11edb72c',
    consumerPhoneNumber: 'Search phone number with country code, ex: +xxyyyyyyyyyy',
    consumerName: 'Search by consumer name',
    consumerEmail: 'Search by consumer email, ex: john.doe@mail.com',
}

export default function getSearchFieldPlaceholder(searchOption) {
    return SEARCH_FIELD_PLACEHOLDERS[searchOption]
}
