import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import moment from 'moment'
import { Button, ButtonGroup, Col, Glyphicon, Grid, Label, Panel, Row } from 'react-bootstrap'

import Address from './panels/address'
import Alert from '../alert'
import BookConsignmentModal from './modals/book-consignment'
import BookReturnModal from './book-return-modal'
import CallLog from './panels/call-log'
import Cancellation from './panels/cancellation'
import CancelModal from './cancel-modal'
import CollectionPoint from './panels/collection-point'
import Comments from './panels/comments'
import Consignments from './panels/consignments'
import ConvertToHomeModal from './modals/covert-to-home-modal'
import CreateDriverFeedbackModal from './modals/create-driver-feedback'
import CreateFreeTimeslot from './modals/create-free-timeslot'
import EndCustomer from './panels/admin-end-customer'
import EndcustomerChangeLog from './panels/end-customer-change-log'
import FreeTimeslots from './panels/free-timeslots'
import General from './panels/general'
import MissModal from './miss-modal'
import DeliveryMap from './delivery-map'
import OrderSettings from './panels/admin-order-settings'
import Parcels from './panels/parcels'
import ParcelStatusModal from './parcel-status-modal'
import StandaloneReturnModal from './standalone-return-modal'
import Photos from './panels/photos'
import Purchases from './panels/purchases'
import Rating from './panels/rating'
import Signature from './panels/signature'
import NotificationsLog from './panels/notifications-log'
import HtmlTitle from '../html-title'
import { dayAbbr } from '../../utils/day-abbr'
import { INSTABOX, BUDBEE, PORTERBUDDY } from '../../utils/brand-enum'

import auth from '../../auth'
import mapTimeZoneToCountry from '../../utils/timezone'
import { overwriteGeocode, searchGeocode } from '../../utils/geocoding-webapi'
import {
    createBulkSplit,
    createFreeTimeslot,
    getFreeTimeslots,
    getOrderByToken,
    getOrderConsignments,
    getOrderDeliveryInfo,
    getOrderDriverFeedback,
    getOrderJournal,
    getOrderPurchases,
    getVerifyConvertToHome,
    updateConsumer,
    updateDeliveryAddress,
    updateOrderSettings,
    convertToHomeDelivery,
} from '../../utils/order-webapi'
import { lockerBoxRating } from '../../utils/rating-webapi'
import { fetchPalletParcels } from '../../utils/pallet-webapi'
import { handleError } from '../../utils/handle-error'
import { isBulkSplit } from '../../utils/parcel-util'
import JournalEvents from './panels/journal-events'
import TerminalInformation from './panels/terminal-information'
import { getLockerConsignments } from '../../utils/locker-consignments'
import LockerConsignments from './panels/locker-consignments'
import { getDriverFeedbackOptions } from '../../utils/trafiki-webapi'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'
import { getZoneManagementEnabledStatus } from '../../utils/config'

const parseNumber = (key) => (key != null ? parseFloat(key) : null)

const normalizeCoordinates = (coordinate) => ({
    latitude: parseNumber(coordinate.latitude),
    longitude: parseNumber(coordinate.longitude),
})

const validCoordinate = (coordinate) =>
    coordinate != null && coordinate.latitude != null && coordinate.longitude != null

export default class View extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            submitting: false,
            order: null,
            showError: false,
            consignments: [],
            lockerConsignments: [],
            showParcelStatusModal: false,
            showStandaloneReturnModal: false,
            error: null,
            selectedDeliveryAttempt: null,
            showCancelDeliveryAttemptModal: false,
            showCancelOrderModal: false,
            showCreateFreeTimeslotModal: false,
            showMissDeliveryAttemptModal: false,
            showBookDeliveryAttemptModal: false,
            showBookReturnModal: false,
            showCreateDriverFeedbackModal: false,
            showConvertToHomeModal: false,
            deliveryInformation: [],
            purchases: [],
            showPurchases: false,
            freeTimeslots: [],
            showFreeTimeslots: false,
            saveCoordinatesForThisAddress: false,
            palletsWithParcels: [],
            lockerRating: null,
            loadingScanEvents: false,
            loadingScanEventsFailed: false,
            allScanEvents: [],
            barcodeScanEvents: [],
            crossReturnPostedEvent: null,
            driverFeedbackOptions: [],
            orderDriverFeedback: [],
            canConvertToHome: {
                allowed: false,
                notAllowedReason: null,
            },
            canCreateStandaloneReturn: false,
            isZoneManagementEnabled: false,
            orderRegisteredEvent: null,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }
        this.getOrderData()
        this.getLockerConsignmentsData()
        this.fetchScanEvents()
        this.fetchZoneManagementEnabledStatus()
    }

    onDeliveryAddressChange = (key, e) => {
        const { order } = this.state
        const { deliveryAddress: address } = order

        if (address == null) {
            return null
        }

        if (key === 'latitude' || key === 'longitude') {
            if (address.coordinate == null) {
                address.coordinate = {
                    latitude: null,
                    longitude: null,
                }
            }
            if (address.navigationCoordinate == null) {
                address.navigationCoordinate = {
                    latitude: null,
                    longitude: null,
                }
            }
            address.navigationCoordinate[key] = e.target.value
            address.coordinate[key] = e.target.value
        } else if (key === 'saveCoordinatesForThisAddress') {
            this.setState((state) => ({ saveCoordinatesForThisAddress: !state.saveCoordinatesForThisAddress }))
        } else if (key === 'doorCode') {
            address.settings[key] = e.target.value
        } else if (key === 'floor') {
            address.settings[key] = e.target.value
        } else {
            address[key] = e.target.value
        }
        return this.setState({ order })
    }

    onEndCustomerChange = (name, value) => {
        const {
            order: { endCustomer },
        } = this.state
        endCustomer[name] = value
        // the following line is probably a bug, it should be updated on the order in state, to do in another PR
        // eslint-disable-next-line react/no-unused-state
        this.setState({ endCustomer })
    }

    onEndCustomerSave = () => {
        const {
            order: {
                endCustomer: { comment, email, id, name, phoneNumber, referencePerson },
            },
        } = this.state
        const consumer = {
            id,
            name,
            referencePerson,
            phoneNumber,
            email,
            comment,
        }
        updateConsumer(this.state.order.token, consumer).catch(handleError)
    }

    onMissDeliveryAttempt = (deliveryAttempt) => {
        this.setState({ selectedDeliveryAttempt: deliveryAttempt, showMissDeliveryAttemptModal: true })
    }

    onCancelDeliveryAttempt = (deliveryAttempt) => {
        this.setState({ selectedDeliveryAttempt: deliveryAttempt, showCancelDeliveryAttemptModal: true })
    }

    onBookDeliveryAttempt = (deliveryAttempt) => {
        this.setState({ selectedDeliveryAttempt: deliveryAttempt, showBookDeliveryAttemptModal: true })
    }

    onBookReturn = (deliveryAttempt) => {
        this.setState({ selectedDeliveryAttempt: deliveryAttempt, showBookReturnModal: true })
    }

    onCreateDriverFeedback = (deliveryAttempt) => {
        getDriverFeedbackOptions()
            .then((res) => {
                this.setState({
                    driverFeedbackOptions: res.payload,
                    selectedDeliveryAttempt: deliveryAttempt,
                    showCreateDriverFeedbackModal: true,
                })
            })
            .catch(handleError)
    }

    onUpdateSetting = (val, setting) => {
        const { order } = this.state
        order.settings[setting] = val
        this.setState({ order })
    }

    onSaveSettings = () => {
        updateOrderSettings(this.state.order.token, this.state.order.settings).catch(handleError)
    }

    isLockerOrder = () => this.state.order.locker != null

    canCreateStandaloneReturn = () => {
        const crossReturnPostedEvent = this.state.allScanEvents.find(
            (event) => event.event.event === 'CROSS_RETURN_POSTED',
        )
        // TODO: Should be disabled if a return is already created/active
        const partnerOffersReturns = (this.state.order.buyer.settings || {}).offerReturns

        // If every parcel in order.parcels, there can be multiple, are in a delivered state
        const parcelsPickedup = this.state.order.parcels.every((p) => {
            if (p.parcelStatusUpdates !== null && p.parcelStatusUpdates.length > 0) {
                const latestStatus = p.parcelStatusUpdates[p.parcelStatusUpdates.length - 1].status
                return latestStatus === 'PickedUp' || latestStatus === 'Delivered'
            }
            return false
        })
        return parcelsPickedup && partnerOffersReturns && !crossReturnPostedEvent
    }

    fetchOrder = () => {
        const fetchPurchases = (orderId) =>
            getOrderPurchases(orderId)
                .then((purchases) => this.setState({ purchases: purchases.payload, showPurchases: true }))
                .catch(handleError)

        const fetchPallets = (parcels) => {
            parcels
                .filter((parcel) => isBulkSplit(parcel))
                .forEach((parcel) =>
                    fetchPalletParcels(parcel.packageId).then((parcelsToAdd) => {
                        const { palletsWithParcels } = this.state
                        const pallet = {
                            palletId: parcel.id,
                            parcels: parcelsToAdd,
                        }
                        this.setState({ palletsWithParcels: [...palletsWithParcels, pallet] })
                    }),
                )
        }
        const fetchFreeTimeslots = (e164PhoneNumber) => this.syncTimeslots(e164PhoneNumber).catch(handleError)

        const fetchLockerRating = (orderId) =>
            lockerBoxRating(orderId)
                .then((rating) => {
                    this.setState({ lockerRating: rating })
                })
                .catch((e) => console.error(e))

        const fetchDriverFeedbackById = (orderId) =>
            getOrderDriverFeedback(orderId)
                .then((feedback) => this.setState({ orderDriverFeedback: feedback.payload }))
                .catch(handleError)

        // Retrieve the Order
        const { token } = this.props.params
        getOrderByToken(token)
            .then((order) => {
                this.setState({ order })
                fetchPurchases(order.id)
                fetchPallets(order.parcels)
                fetchFreeTimeslots(order.endCustomer.phoneNumber)
                fetchDriverFeedbackById(order.id)
                if (this.isLockerOrder()) {
                    fetchLockerRating(order.id)
                }
                if (this.canCreateStandaloneReturn()) {
                    this.setState({ canCreateStandaloneReturn: true })
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ error: e.statusText })
            })
    }

    getLockerConsignmentsData = () => {
        const { token } = this.props.params
        getLockerConsignments(token)
            .then((lockerConsignments) => {
                this.setState({ lockerConsignments })
            })
            .catch((e) => {
                handleError(e)
            })
    }

    getOrderData = () => {
        const { token } = this.props.params
        this.fetchOrder()
        getOrderConsignments(token)
            .then((consignments) => this.setState({ consignments }))
            .catch(handleError)
        getOrderDeliveryInfo(token)
            .then((deliveryInformation) => this.setState({ deliveryInformation }))
            .catch(handleError)
        getVerifyConvertToHome(token)
            .then((canConvertToHome) => this.setState({ canConvertToHome }))
            .catch(handleError)
    }

    syncTimeslots = (e164PhoneNumber) =>
        getFreeTimeslots(e164PhoneNumber).then((timeslots) =>
            this.setState({ freeTimeslots: timeslots.payload, showFreeTimeslots: true }),
        )

    saveCoordinatesForThisAddress = (address) => {
        searchGeocode(address).then((geocodeAddresses) => {
            if (geocodeAddresses.length === 1) {
                const updatedAddress = geocodeAddresses.map((geocodeAddress) => ({
                    ...geocodeAddress,
                    coordinate: address.coordinate,
                    navigationCoordinate: address.navigationCoordinate,
                }))
                overwriteGeocode(updatedAddress).catch((e) => {
                    handleError(e)
                    this.setState({ error: e.statusText })
                })
            } else if (geocodeAddresses.length > 1) {
                this.setState({ error: `Ambiguous address ${address.street}, will not update coordinates` })
            }
        })
    }

    // todo: rename more generic (FetchScanEvents)
    fetchScanEvents = () => {
        const { token } = this.props.params
        this.setState({ loadingScanEvents: true })
        getOrderJournal(token)
            .then(this.barcodeScanEventsSuccess)
            .catch(this.barcodeScanEventsError)
    }

    fetchZoneManagementEnabledStatus = () => {
        getZoneManagementEnabledStatus()
            .then((isZoneManagementEnabled) => {
                this.setState({ isZoneManagementEnabled })
            })
            .catch((e) => {
                handleError(e)
            })
    }

    isZoneManagementEnabled = () => this.state.isZoneManagementEnabled === true

    barcodeScanEventsSuccess = (events) => {
        const barcodeScanEvents = events.filter((obj) => {
            return obj.event.event === 'BARCODE_SCANNED' || obj.event.event === 'BOX_ASSIGNMENT_FAILED'
        })
        const orderRegisteredEvent = events.find((obj) => obj.event.event === 'ORDER_REGISTERED').event
        const crossReturnPostedEvent = events.find((event) => event.event.event === 'CROSS_RETURN_POSTED')

        this.setState({
            loadingScanEvents: false,
            loadingScanEventsFailed: false,
            allScanEvents: events,
            barcodeScanEvents,
            orderRegisteredEvent,
            crossReturnPostedEvent,
        })
    }

    barcodeScanEventsError = (error) => {
        this.setState({ loadingScanEvents: false, loadingScanEventsFailed: true })
        handleError(error)
    }

    updateAddress = () => {
        const address = this.state.order.deliveryAddress
        if (address == null) {
            return
        }

        // Force latitude and longitude values into Floats
        // since the HTML Input field leaves us with a String
        if (address.coordinate) {
            address.coordinate = normalizeCoordinates(address.coordinate)
        }

        if (address.navigationCoordinate) {
            address.navigationCoordinate = normalizeCoordinates(address.navigationCoordinate)
        }

        if (!validCoordinate(address.coordinate)) {
            address.coordinate = null
        }

        if (!validCoordinate(address.navigationCoordinate)) {
            address.navigationCoordinate = null
        }

        this.setState({ submitting: true })
        updateDeliveryAddress(this.state.order.token, address)
            .then(() => {
                if (this.state.saveCoordinatesForThisAddress) {
                    this.saveCoordinatesForThisAddress(address)
                }
            })
            .then(() => this.setState({ submitting: false, showError: false }))
            .catch(() => {
                this.setState({ submitting: false, showError: true })
            })
    }

    toggleParcelStatusModal = (e) => {
        if (e) {
            e.preventDefault()
        }

        this.setState(
            (state) => ({ showParcelStatusModal: !state.showParcelStatusModal }),
            () => {
                if (!this.state.showParcelStatusModal) {
                    this.getOrderData()
                }
            },
        )
    }

    toggleStandaloneReturnModal = (e) => {
        if (e) {
            e.preventDefault()
        }

        this.setState(
            (state) => ({ showStandaloneReturnModal: !state.showStandaloneReturnModal }),
            () => {
                if (!this.state.showStandaloneReturnModal) {
                    this.getOrderData()
                }
            },
        )
    }

    addBulkSplit = () => {
        const add = window.confirm('Are you sure you want to add a Return Bulk Split Parcel to this pickup?')

        if (add) {
            const { order, consignments } = this.state
            const consignment = consignments[consignments.length - 1]

            createBulkSplit(order.token, consignment.id)
                .then(() => this.getOrderData())
                .catch((e) => {
                    handleError(e)
                    this.setState({ error: e.statusText })
                })
        }
    }

    recalledParcel = () => {
        this.getOrderData()
    }

    createFreeTimeslot = (reason) => {
        const { order } = this.state
        const onResponse = (response) => {
            if (response.status !== 'COMPLETED') {
                alert('Could not create free timeslot')
            } else {
                this.syncTimeslots(order.endCustomer.phoneNumber)
            }
            this.setState({ showCreateFreeTimeslotModal: false })
        }
        createFreeTimeslot(order.endCustomer.phoneNumber, reason).then(onResponse)
    }

    getTrackingLink = (brand, orderRegisteredEvent) => {
        const brandUrls = {
            [BUDBEE]: 'https://tracking.budbee.com/',
            [PORTERBUDDY]: 'https://track.porterbuddy.com/',
            [INSTABOX]: 'https://tracking.instabee.com/',
        }

        const baseUrl = brandUrls[brand] || brandUrls.BUDBEE
        const { authenticationToken, token } = orderRegisteredEvent
        const authParam = btoa(authenticationToken)

        return `${baseUrl}${token}?auth=${authParam}`
    }

    renderOrderChanges() {
        const header = <h3>History</h3>

        return (
            <Panel header={header}>
                <EndcustomerChangeLog order={this.state.order} />
            </Panel>
        )
    }

    renderPurchases() {
        const { purchases, showPurchases } = this.state
        if (!showPurchases) {
            return null
        }

        const header = <h3>Purchases</h3>

        return (
            <Panel header={header}>
                <Purchases purchases={purchases} />
            </Panel>
        )
    }

    renderFreeTimeslots() {
        const { showCreateFreeTimeslotModal, freeTimeslots, showFreeTimeslots } = this.state
        if (!showFreeTimeslots) {
            return null
        }
        const onClose = () => this.setState({ showCreateFreeTimeslotModal: false })
        const onShow = () => this.setState({ showCreateFreeTimeslotModal: true })
        const header = <h3>Free Timeslots (Consumer)</h3>

        return (
            <Panel header={header}>
                <CreateFreeTimeslot
                    show={showCreateFreeTimeslotModal}
                    onCreate={this.createFreeTimeslot}
                    onClose={onClose}
                />
                <Button onClick={onShow}>
                    <Glyphicon glyph="plus" /> Add Free Timeslot
                </Button>
                <FreeTimeslots timeslots={freeTimeslots} />
            </Panel>
        )
    }

    renderCancellation() {
        if (this.state.order.cancellation == null) {
            return null
        }

        const header = <h3>Order Cancelled</h3>

        return (
            <Panel header={header} bsStyle="info">
                <Cancellation
                    cancellation={this.state.order.cancellation}
                    deliveryAddress={this.state.order.deliveryAddress}
                />
            </Panel>
        )
    }

    renderCrossReturnBooked() {
        const { crossReturnPostedEvent } = this.state
        if (crossReturnPostedEvent == null) {
            return null
        }

        const header = <h3>Return booked</h3>

        return (
            <Panel header={header} bsStyle="info">
                <div>
                    <p className="strong-label">Date</p>
                    {moment(crossReturnPostedEvent.timestamp)
                        .tz(mapTimeZoneToCountry(this.state.order.deliveryAddress.countryCode))
                        .format('YYYY-MM-DD HH:mm:ss')}

                    <p className="strong-label">Links</p>
                    <a href={`https://orderadmin.instadmin.net/order/${crossReturnPostedEvent.event.instaboxOrderId}`}>
                        OrderAdmin
                    </a>
                </div>
            </Panel>
        )
    }

    renderRatings() {
        const { order, lockerRating } = this.state
        const consignmentsWithRating = this.state.consignments.filter((c) => c.rating != null)

        if (lockerRating != null) {
            return (
                <Panel header="Rating for locker delivery">
                    <Rating rating={lockerRating} deliveryAddress={order.deliveryAddress} />
                </Panel>
            )
        }
        if (consignmentsWithRating.length === 0) {
            return null
        }

        return (
            <div>
                {consignmentsWithRating.map((c) => {
                    const ts = moment(c.interval.startTimestamp.date)
                        .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                        .format('YYYY-MM-DD')
                    return (
                        <Panel key={c.id} header={`Rating for ${c.type}: ${ts}`}>
                            <Rating rating={c.rating} deliveryAddress={order.deliveryAddress} />
                        </Panel>
                    )
                })}
            </div>
        )
    }

    renderDeliveryInfo() {
        if (this.state.deliveryInformation == null) {
            return null
        }

        const header = <h3>Consignments</h3>
        return (
            <Panel header={header}>
                <Consignments
                    order={this.state.order}
                    consignments={this.state.consignments}
                    deliveryInformation={this.state.deliveryInformation}
                    crossReturnBooked={this.state.crossReturnPostedEvent != null}
                    onMissDeliveryAttempt={this.onMissDeliveryAttempt}
                    onCancelDeliveryAttempt={this.onCancelDeliveryAttempt}
                    onBookDeliveryAttempt={this.onBookDeliveryAttempt}
                    onBookReturn={this.onBookReturn}
                    onCreateDriverFeedback={this.onCreateDriverFeedback}
                    orderDriverFeedback={this.state.orderDriverFeedback}
                    isLockerOrder={this.isLockerOrder()}
                />
            </Panel>
        )
    }

    renderLockerConsignments() {
        const { lockerConsignments, order } = this.state
        if (!lockerConsignments) {
            return null
        }

        const header = <h3>Locker Consignments</h3>

        return (
            <Panel header={header}>
                <LockerConsignments
                    lockerConsignments={lockerConsignments}
                    countryCode={order.deliveryAddress.countryCode}
                />
            </Panel>
        )
    }

    renderAddBulkSplitButton() {
        return (
            <Button onClick={this.addBulkSplit}>
                <Glyphicon glyph="plus" /> Add Bulk Split
            </Button>
        )
    }

    renderTrackingLinkButton() {
        if (!this.state.orderRegisteredEvent) {
            return null
        }

        return (
            <Button
                type="button"
                bsSize="small"
                href={this.getTrackingLink(this.state.order.brand, this.state.orderRegisteredEvent)}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Glyphicon glyph="new-window" /> Open tracking link
            </Button>
        )
    }

    renderParcelPanelHeader() {
        const showAddBulkSplitButton =
            this.state.consignments.length > 0 && this.state.order.cartId.startsWith('Return-{')

        return (
            <div>
                <h4>Parcels</h4>
                <ParcelStatusModal
                    onUpdate={this.getOrderData}
                    show={this.state.showParcelStatusModal}
                    onHide={this.toggleParcelStatusModal}
                    parcels={this.state.order.parcels}
                    palletsWithParcels={this.state.palletsWithParcels}
                    deliveryAddress={this.state.order.deliveryAddress}
                    codeRequired={this.state.order.requireDeliveryCode}
                />

                <StandaloneReturnModal
                    show={this.state.showStandaloneReturnModal}
                    onHide={this.toggleStandaloneReturnModal}
                    order={this.state.order}
                />

                <ButtonGroup className="pull-right" bsSize="small" style={{ marginTop: -35 }}>
                    {this.renderTrackingLinkButton()}
                    {showAddBulkSplitButton && this.renderAddBulkSplitButton()}
                    <Button disabled={this.state.crossReturnPostedEvent != null} onClick={this.toggleParcelStatusModal}>
                        <Glyphicon glyph="pencil" /> Update Status
                    </Button>
                </ButtonGroup>
            </div>
        )
    }

    renderCancelOrderModal = () => {
        if (!this.state.order) {
            return null
        }

        return (
            <CancelModal
                show={this.state.showCancelOrderModal}
                order={this.state.order}
                onClose={() => this.setState({ showCancelOrderModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showCancelOrderModal: false })
                }}
            />
        )
    }

    renderCancelDeliveryAttemptModal = () => {
        if (!this.state.order || !this.state.selectedDeliveryAttempt) {
            return null
        }

        return (
            <CancelModal
                show={this.state.showCancelDeliveryAttemptModal}
                order={this.state.order}
                deliveryAttempt={this.state.selectedDeliveryAttempt}
                onClose={() => this.setState({ showCancelDeliveryAttemptModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showCancelDeliveryAttemptModal: false })
                }}
            />
        )
    }

    renderMissDeliveryAttemptModal = () => {
        if (!this.state.order || !this.state.selectedDeliveryAttempt) {
            return null
        }

        return (
            <MissModal
                show={this.state.showMissDeliveryAttemptModal}
                order={this.state.order}
                deliveryAttempt={this.state.selectedDeliveryAttempt}
                onClose={() => this.setState({ showMissDeliveryAttemptModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showMissDeliveryAttemptModal: false })
                }}
            />
        )
    }

    renderBookDeliveryAttemptModal = () => {
        const { order, showBookDeliveryAttemptModal } = this.state

        if (!order) {
            return null
        }

        if (!showBookDeliveryAttemptModal) {
            return null
        }

        return (
            <BookConsignmentModal
                orderToken={order.token}
                countryCode={order.deliveryAddress.countryCode}
                deliveryAttempt={this.state.selectedDeliveryAttempt}
                onClose={() => this.setState({ showBookDeliveryAttemptModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showBookDeliveryAttemptModal: false })
                }}
            />
        )
    }

    renderBookReturnModal = () => {
        if (!this.state.order) {
            return null
        }

        return (
            <BookReturnModal
                show={this.state.showBookReturnModal}
                order={this.state.order}
                deliveryAttempt={this.state.selectedDeliveryAttempt}
                onClose={() => this.setState({ showBookReturnModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showBookReturnModal: false })
                }}
            />
        )
    }

    renderCreateDriverFeedbackModal = () => {
        if (this.state.deliveryInformation == null || !this.state.order) {
            return null
        }

        return (
            <CreateDriverFeedbackModal
                show={this.state.showCreateDriverFeedbackModal}
                deliveryInformation={this.state.deliveryInformation}
                order={this.state.order}
                deliveryAttempt={this.state.selectedDeliveryAttempt}
                driverFeedbackOptions={this.state.driverFeedbackOptions}
                onClose={() => this.setState({ showCreateDriverFeedbackModal: false })}
                onSuccess={() => {
                    this.getOrderData()
                    this.setState({ showCreateDriverFeedbackModal: false })
                }}
            />
        )
    }

    renderConvertToHomeModal = () => {
        return (
            <ConvertToHomeModal
                show={this.state.showConvertToHomeModal}
                onClose={() => this.setState({ showConvertToHomeModal: false })}
                onSuccess={(reason) => {
                    const data = {
                        party: 'Budbee',
                        productCode: 'DLVEVN',
                        reason,
                    }
                    convertToHomeDelivery(this.state.order.token, data).then(() => {
                        this.setState({ showConvertToHomeModal: false })
                        this.getOrderData()
                    })
                }}
            />
        )
    }

    getBrandLogoImgProps = (brand) => {
        switch (brand) {
            case INSTABOX:
                return { src: '../../../img/instabox-logo.png', alt: 'Instabox logo' }
            case PORTERBUDDY:
                return { src: '../../../img/porterbuddy-icon.svg', alt: 'Porterbuddy logo' }
            case BUDBEE:
            default:
                return { src: '../../../img/budbee-logo.png', alt: 'Budbee logo' }
        }
    }

    render() {
        const { order, error } = this.state

        if (error !== null) {
            return (
                <Grid fluid>
                    <Col md={8} mdOffset={2}>
                        <Alert type="danger" message={error} />
                    </Col>
                </Grid>
            )
        }

        if (order == null) {
            return null
        }

        const generalHeader = <h3>General</h3>
        const collectionPointHeader = <h3>Merchant Address</h3>
        const deliveryAddressHeader = <h3>Consumer Address</h3>
        const endCustomerHeader = <h3>Consumer</h3>
        const terminalInformationHeader = <h3>Terminal Information</h3>
        const orderSettingsHeader = <h3>{this.isLockerOrder() ? 'Box Delivery Settings' : 'Home Delivery Settings'}</h3>
        const notificationsHeaders = <h3>Consumer notifications</h3>
        const callLogHeader = <h3>Calls made to Consumer</h3>
        const commentsHeader = <h3>Comments</h3>
        const deliveryPhotosHeader = <h3>Photos</h3>
        const signatureHeader = <h3>Signature</h3>
        const eventsHeader = <h3>Events</h3>

        return (
            <div>
                <HtmlTitle title={order.token} />
                {this.renderCancelOrderModal()}
                {this.renderCancelDeliveryAttemptModal()}
                {this.renderMissDeliveryAttemptModal()}
                {this.renderBookDeliveryAttemptModal()}
                {this.renderBookReturnModal()}
                {this.renderCreateDriverFeedbackModal()}
                {this.renderConvertToHomeModal()}

                <div className="order-button-wrapper">
                    <div className="pull-right">
                        <Button
                            disabled={order.cancellation != null || this.state.crossReturnPostedEvent != null}
                            bsStyle="danger"
                            bsSize="small"
                            onClick={() => this.setState({ showCancelOrderModal: true })}
                        >
                            Cancel Order
                        </Button>
                        <Button
                            disabled={!this.state.canConvertToHome.allowed}
                            bsStyle="danger"
                            bsSize="small"
                            title={
                                this.state.canConvertToHome.allowed === false
                                    ? this.state.canConvertToHome.notAllowedReason
                                    : ''
                            }
                            onClick={() => this.setState({ showConvertToHomeModal: true })}
                        >
                            Convert to home
                        </Button>
                        <Button
                            disabled={!this.state.canCreateStandaloneReturn}
                            bsStyle="danger"
                            bsSize="small"
                            onClick={this.toggleStandaloneReturnModal}
                        >
                            Create Standalone Return
                        </Button>
                    </div>
                </div>
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2} className="order-information">
                            <div>
                                <div className="pull-right">
                                    <Link to={`/admin/merchants/${order.buyer.id}`}>
                                        {order.buyer.logo ? (
                                            <img src={order.buyer.logo.url} style={{ maxHeight: 100 }} alt="" />
                                        ) : (
                                            order.buyer.name
                                        )}
                                    </Link>
                                </div>
                                <h1>
                                    <img
                                        style={{ height: 50, width: 50, marginRight: 15 }}
                                        src={this.getBrandLogoImgProps(order.brand).src}
                                        alt={this.getBrandLogoImgProps(order.brand).alt}
                                    />

                                    <span>Order </span>
                                    <strong>{order.token}</strong>
                                    {order.cartId.startsWith('Return-{') ? (
                                        <small
                                            className="label label-warning"
                                            style={{ marginLeft: 10, fontSize: '50%', verticalAlign: 'middle' }}
                                        >
                                            Merchant return
                                        </small>
                                    ) : null}
                                    {order.cartId.startsWith('Collection-{') ? (
                                        <small
                                            className="label label-success"
                                            style={{ marginLeft: 10, fontSize: '50%', verticalAlign: 'middle' }}
                                        >
                                            Merchant pickup
                                        </small>
                                    ) : null}
                                    {this.isLockerOrder() ? (
                                        <small
                                            className="label label-success"
                                            style={{ marginLeft: 10, fontSize: '50%', verticalAlign: 'middle' }}
                                        >
                                            Box delivery
                                        </small>
                                    ) : null}
                                </h1>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderCancellation()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderCrossReturnBooked()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={commentsHeader}>
                                <Comments order={this.state.order} />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={this.renderParcelPanelHeader()}>
                                <Parcels
                                    merchant={order.buyer}
                                    deliveryAddress={this.state.order.deliveryAddress}
                                    parcels={order.parcels}
                                    recalledParcel={this.recalledParcel}
                                    fetchOrder={this.fetchOrder}
                                    fetchScanEvents={this.fetchScanEvents}
                                    loadingScanEvents={this.state.loadingScanEvents}
                                    loadingScanEventsFailed={this.state.loadingScanEventsFailed}
                                    barcodeScanEvents={this.state.barcodeScanEvents}
                                />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderDeliveryInfo()}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderLockerConsignments()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} mdOffset={2}>
                            <Panel header={endCustomerHeader}>
                                <EndCustomer
                                    editable={this.state.crossReturnPostedEvent == null}
                                    endCustomer={order.endCustomer}
                                    onFormChange={this.onEndCustomerChange}
                                    onEndCustomerSave={this.onEndCustomerSave}
                                />
                            </Panel>
                            <Panel header={terminalInformationHeader}>
                                {this.isZoneManagementEnabled() ? (
                                    <TerminalInformation
                                        terminalInformation={order.deliveryPostalCodeZone}
                                        injectionTerminal={order.collectionPoint.injectionTerminal}
                                        deliveryAddress={order.deliveryAddress}
                                    />
                                ) : (
                                    <TerminalInformation
                                        terminalInformation={order.deliveryPostalCodeZone}
                                        injectionTerminal={order.collectionPoint.injectionTerminal}
                                    />
                                )}
                            </Panel>
                        </Col>
                        <Col md={4}>
                            <Panel header={this.isLockerOrder() ? <h3>Addresses</h3> : deliveryAddressHeader}>
                                <div>
                                    {this.isLockerOrder() ? null : <DeliveryMap address={order.deliveryAddress} />}
                                    {this.isLockerOrder() ? (
                                        <div>
                                            <strong>Locker:</strong> {order.locker.name} ({order.locker.identifier}){' '}
                                            {order.locker.enabled ? (
                                                <Label bsStyle="success">Enabled</Label>
                                            ) : (
                                                <Label bsStyle="danger">Disabled</Label>
                                            )}
                                            <Address isLockerAddress address={order.locker.address} />
                                            {order.locker.openingHours.map(({ day, open, close }) => (
                                                <span key={day}>
                                                    <strong style={{ display: 'inline-block', width: 40 }}>
                                                        {dayAbbr()(day)}:
                                                    </strong>{' '}
                                                    {open == null ? 'Closed' : `${open} - ${close}`}
                                                    <br />
                                                </span>
                                            ))}
                                        </div>
                                    ) : null}
                                    <Address
                                        editable={!this.isLockerOrder() && this.state.crossReturnPostedEvent == null}
                                        showError={this.state.showError}
                                        showWillSaveCoordinatesWarning={this.state.saveCoordinatesForThisAddress}
                                        submitting={this.state.submitting}
                                        header="Consumer Address"
                                        headerStyle={{ marginTop: '1.5em' }}
                                        address={order.deliveryAddress}
                                        onDeliveryAddressChange={this.onDeliveryAddressChange}
                                        onEditAddressSave={this.updateAddress}
                                    />
                                </div>
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={orderSettingsHeader}>
                                <OrderSettings
                                    editable={this.state.crossReturnPostedEvent == null}
                                    countryCode={order.deliveryAddress.countryCode}
                                    settings={order.settings}
                                    productCodes={order.productCodes}
                                    isLockerOrder={this.isLockerOrder()}
                                    onUpdateSetting={this.onUpdateSetting}
                                    onSaveSettings={this.onSaveSettings}
                                />
                            </Panel>
                        </Col>
                    </Row>

                    {order.bankId != null ? (
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <Panel header={<h3>BankId</h3>}>
                                    <dl>
                                        <dt>Identified</dt>
                                        <dd>
                                            {order.bankId.success ? (
                                                <Label bsStyle="success">Success</Label>
                                            ) : (
                                                <Label bsStyle="danger">Failed</Label>
                                            )}
                                        </dd>
                                        <dt>Timestamp</dt>
                                        <dd>
                                            {moment(order.bankId.timestamp)
                                                .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                                                .format('YYYY-MM-DD HH:mm')}
                                        </dd>
                                    </dl>
                                </Panel>
                            </Col>
                        </Row>
                    ) : null}

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={callLogHeader}>
                                <CallLog order={this.state.order} />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={notificationsHeaders}>
                                <NotificationsLog order={this.state.order} />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderFreeTimeslots()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderPurchases()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderOrderChanges()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={deliveryPhotosHeader}>
                                <Photos order={this.state.order} />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={signatureHeader}>
                                <Signature order={this.state.order} />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Panel header={eventsHeader}>
                                <JournalEvents
                                    timeZone={mapTimeZoneToCountry(order.deliveryAddress.countryCode)}
                                    loadingScanEvents={this.state.loadingScanEvents}
                                    loadingScanEventsFailed={this.state.loadingScanEventsFailed}
                                    allScanEvents={this.state.allScanEvents}
                                />
                            </Panel>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.renderRatings()}
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} mdOffset={2}>
                            <Panel header={generalHeader}>
                                <General order={order} />
                            </Panel>
                        </Col>
                        <Col md={4}>
                            <Panel header={collectionPointHeader}>
                                <CollectionPoint collectionPoint={order.collectionPoint} />
                            </Panel>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

View.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
}
