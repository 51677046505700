import React from 'react'
import { Col } from 'react-bootstrap'
import ShoppingCollectionsForm from './components/shopping-collections-form/shopping-collections-form'

const ShoppingCollections = () => (
    <Col className="mt-sm" md={12} lg={10}>
        <ShoppingCollectionsForm />
    </Col>
)

export default ShoppingCollections
