import React from 'react'
import PropTypes from 'prop-types'

const AdjustPriceItem = ({ originalPrice, updatedPrice = originalPrice, priceExpression }) => {
    if (originalPrice === updatedPrice) {
        return <div>{priceExpression(originalPrice)}</div>
    }

    return (
        <div>
            <small style={{ textDecoration: 'line-through' }}>{priceExpression(originalPrice)}</small>
            <div className="text-success">{priceExpression(updatedPrice)}</div>
        </div>
    )
}

export default AdjustPriceItem

AdjustPriceItem.propTypes = {
    originalPrice: PropTypes.number.isRequired,
    updatedPrice: PropTypes.number,
    priceExpression: PropTypes.func.isRequired,
}
