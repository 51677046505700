import { ROUTE_SEARCH_INITIATED, ROUTE_SEARCH_LOADED } from '../constants/actionTypes'

export default function routeSearchReducer(state = { loading: false }, action) {
    switch (action.type) {
        case ROUTE_SEARCH_INITIATED:
            return {
                ...state,
                loading: true,
                results: [],
            }

        case ROUTE_SEARCH_LOADED:
            return {
                ...state,
                loading: false,
                results: action.results,
            }

        default:
            return state
    }
}
