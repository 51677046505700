import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import moment from 'moment'

export default class CommentModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            comment: '',
            permissions: {},
        }
    }

    componentDidMount() {
        const permissions = this.props.commentPermissions || []
        const permissionMap = {}
        permissions.forEach((permission) => {
            const name = (permission.name.split(/to/)[1] || permission.name).trim()

            // Previously filtered out 'Buyer' (name !== 'Buyer') as a checked alternative
            // Now check all Roles by default
            permissionMap[permission.id] = {
                name,
                canView: true,
            }
        })

        this.setState({ permissions: permissionMap })
    }

    _renderCommentPermissions() {
        return (
            <div>
                <h5>Comment visible to:</h5>
                <ul className="list-inline">
                    {Object.keys(this.state.permissions).map((key) => {
                        const permission = this.state.permissions[key]

                        return (
                            <li key={key} className="checkbox">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={permission.canView}
                                        onChange={this._onPermissionChange.bind(this, key)}
                                    />{' '}
                                    {permission.name}
                                </label>
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    _renderComments(comments) {
        const { timeZone } = this.props
        if (comments == null) {
            comments = []
        }

        const hasComments = comments.length > 0

        const result = comments.map((c, i) => (
            <blockquote key={i}>
                <p>{c.comment ? c.comment : c.message}</p>
                <footer>
                    {c.userName}
                    {c.admin ? ' - Admin' : ''}{' '}
                    {moment(c.date)
                        .tz(timeZone)
                        .format('YYYY-MM-DD HH:mm:ss')}
                </footer>
            </blockquote>
        ))

        return (
            <div>
                {result}
                {hasComments ? <hr /> : null}
            </div>
        )
    }

    _onChange(e) {
        this.setState({ comment: e.target.value })
    }

    _onPermissionChange(prop, e) {
        const permissions = {
            ...this.state.permissions,
            [prop]: {
                canView: e.target.checked,
                name: this.state.permissions[prop].name,
            },
        }

        this.setState({ permissions })
    }

    _submitComment = () => {
        const permissionIds = Object.keys(this.state.permissions).filter((key) => this.state.permissions[key].canView)
        if (this.state.comment == null || this.state.comment.trim() === '') {
            return
        }
        this.props.onSubmit(
            this.props.routeId,
            this.props.dataId,
            this.props.dataType,
            this.props.orderToken,
            this.state.comment,
            permissionIds,
        )
        this.setState({ comment: '' })
    }

    render() {
        return (
            <Modal
                className={this.props.className}
                show={this.props.show}
                title="Comments"
                animation={false}
                onHide={this.props.onRequestHide}
            >
                <div className="modal-body">
                    {this._renderComments(this.props.comments)}

                    <form className="form-horizontal">
                        <div className="form-group">
                            <div className="col-sm-12">
                                <label htmlFor="comment">Add a comment</label>
                                <textarea
                                    className="form-control"
                                    id="comment"
                                    rows="5"
                                    autoFocus
                                    value={this.state.comment}
                                    onChange={this._onChange.bind(this)}
                                />
                            </div>
                        </div>

                        {this._renderCommentPermissions()}

                        <button type="button" className="btn btn-primary" onClick={this._submitComment}>
                            Save
                        </button>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={this.props.onRequestHide}>
                        Close
                    </button>
                </div>
            </Modal>
        )
    }
}

CommentModal.propTypes = {
    comments() {
        React.PropTypes.array.isRequired
    },
}
