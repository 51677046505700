import * as types from '../constants/actionTypes'
import auth from '../auth'
import {
    whoami,
    getUsers,
    getUser,
    createUser as create,
    editUser as edit,
    deleteUser as remove,
    changePassword as resetPassword,
    getForCourier,
    blacklistToken,
} from '../utils/user-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import { getDriverFeedbackById, deleteDriverFeedback as removeDriverFeedback } from '../utils/drivers-webapi'

const loginLoading = () => ({
    type: types.USER_LOGIN,
})

const loginFail = (error) => ({
    type: types.USER_LOGIN_ERROR,
    status: error.status,
    message: 'Could not login. Wrong username and/or password.',
})

const loadingUsers = () => ({
    type: types.LOADING_USERS,
})

const usersLoaded = (response) => ({
    type: types.USERS_LOADED,
    status: 200,
    response,
})

const driverFeedbackLoaded = (driverFeedback) => ({
    type: types.DRIVER_FEEDBACK_LOADED,
    status: 200,
    driverFeedback,
})

const userLoaded = (user) => ({
    type: types.USER_LOADED,
    status: 200,
    user,
})

const userUpdated = () => ({
    type: types.USER_UPDATED,
})

const updateUserFailed = (errors, statusCode) => ({
    type: types.UPDATE_USER_FAILED,
    errors,
    statusCode,
})

const userCreated = (user) => ({
    type: types.USER_CREATED,
    status: 200,
    user,
})

const createUserFailed = (error) => ({
    type: types.USER_CREATE_FAILED,
    status: error.status,
    message: 'Could not create user',
})

const usersDeleted = (id) => ({
    type: types.USER_DELETED,
    status: 200,
    id,
})

const currentUserLoaded = (user) => {
    return {
        type: types.CURRENT_USER_LOADED,
        status: 200,
        user,
    }
}

const userSelected = (id) => ({
    type: types.USER_SELECTED,
    status: 200,
    id,
})

const changePasswordSuccess = () => ({
    type: types.USER_PASSWORD_CHANGE_SUCCESS,
    status: 200,
})

const changePasswordFail = (error) => ({
    type: types.USER_PASSWORD_CHANGE_FAIL,
    status: error.status,
})

export const updatingUser = () => ({
    type: types.UPDATING_USER,
})

export const updateUserCancelled = () => ({
    type: types.UPDATE_USER_CANCELLED,
})

export const deleteUser = (id) => (dispatch) =>
    remove(id)
        .then(compose(dispatch, usersDeleted))
        .catch(handleError)

export const editUser = (id, user, router) => (dispatch) => {
    edit(id, user)
        .then(() => {
            dispatch(userUpdated)
            dispatch(router.push(`/admin/users/${id}`))
        })
        .catch((err) => {
            if ([400, 422].includes(err.status)) {
                err.json()
                    .then((res) => {
                        dispatch(updateUserFailed(res.errors, err.status))
                    })
                    .catch((jsonErr) => {
                        dispatch(updateUserFailed(['Check your data and try again'], err.status))
                        handleError(jsonErr)
                    })
            } else {
                dispatch(updateUserFailed(['An error has occurred.'], err.status))
                handleError(err)
            }
        })
}

export const createUser = (user, router) => (dispatch) =>
    create(user)
        .then((response) => {
            dispatch(router.push('/admin/users'))
            dispatch(userCreated(response))
        })
        .catch(compose(dispatch, createUserFailed, handleError))

export const logout = () => (dispatch) => blacklistToken().then(() => dispatch({ type: types.CURRENT_USER_LOGGED_OUT }))

export const login = (token, callback) => (dispatch) => {
    dispatch(loginLoading())
    auth.setToken(token)
    whoami()
        .then((user) => {
            auth.setPermissions(user.grantedPermissions)
            dispatch(currentUserLoaded(user))
            callback()
        })
        .catch(compose(dispatch, loginFail))
}

export const fetchUsers = () => (dispatch) => {
    dispatch(loadingUsers())
    return getUsers()
        .then(compose(dispatch, usersLoaded))
        .catch(handleError)
}

export const fetchUser = (id) => (dispatch) =>
    getUser(id)
        .then(compose(dispatch, userLoaded))
        .catch(handleError)

export const currentUserRequested = () => ({ type: types.CURRENT_USER_REQUESTED })

export const fetchCurrentUser = () => (dispatch) => {
    dispatch(currentUserRequested())
    return whoami()
        .then(compose(dispatch, currentUserLoaded))
        .catch(handleError)
}

export const selectUser = (id) => (dispatch) => dispatch(userSelected(id))

export const changePassword = (id, currentPassword, newPassword) => (dispatch) =>
    resetPassword(id, currentPassword, newPassword)
        .then(compose(dispatch, changePasswordSuccess))
        .catch(compose(dispatch, changePasswordFail))

export const getCourierUsers = (courierId) => (dispatch) => {
    dispatch(loadingUsers())
    const promise = courierId != null ? getForCourier(courierId) : getUsers()
    return promise.then(compose(dispatch, usersLoaded)).catch(handleError)
}

export const fetchDriverFeedbackById = (id) => (dispatch) => {
    getDriverFeedbackById(id)
        .then(compose(dispatch, driverFeedbackLoaded))
        .catch(handleError)
}

export const deleteDriverFeedback = (userId, feedbackId) => (dispatch) => {
    removeDriverFeedback(userId, feedbackId)
        .then(() => setTimeout(() => dispatch(fetchDriverFeedbackById(userId)), 1000))
        .catch(handleError)
}
