import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'

/**
 * Render a Cancellation
 */
const Cancellation = ({ cancellation, deliveryAddress }) => {
    if (cancellation == null) {
        return null
    }

    return (
        <div>
            <label className="strong-label">Date</label>
            {moment(cancellation.date)
                .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                .format('YYYY-MM-DD HH:mm:ss')}

            <label className="strong-label">Comment</label>
            {cancellation.comment}

            <label className="strong-label">Category</label>
            {cancellation.cancellationCategory}
        </div>
    )
}

export default Cancellation

Cancellation.propTypes = {
    cancellation: PropTypes.object.isRequired,
    deliveryAddress: PropTypes.object.isRequired,
}
