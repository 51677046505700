import React, { Component } from 'react'
import { Modal, Button, FormGroup, ControlLabel, FormControl, InputGroup, Col, Row, Table } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { DATE_FORMAT } from '../../../../../utils/date'
import GroupRow from './group-row'

class AdjustTiersModal extends Component {
    constructor(props, ...rest) {
        super(props, ...rest)
        this.state = this.resetState(props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(this.resetState(nextProps))
        }
    }

    resetState = (props) => ({
        isDirty: false,
        percentage: '',
        startDate: moment(),
        focused: null,
        priceTiers: props.priceTiers.map((group) => ({
            ...group,
            tiers: group.tiers.map((tier) => {
                return {
                    ...tier,
                    newFixedRate: tier.fixedRate,
                    newIncrementalRate: tier.incrementalRate,
                }
            }),
        })),
        tierIds: props.priceTiers.flatMap(({ tiers }) => tiers).map(({ id }) => id),
        checkedTiers: props.priceTiers.flatMap(({ tiers }) => tiers).map(({ id }) => id),
    })

    getValidationState = (validExpression) => {
        if (!this.state.isDirty) {
            return null
        }

        return validExpression() ? 'success' : 'error'
    }

    getPercentageValidationState = () =>
        this.getValidationState(() => {
            const percentage = parseFloat(this.state.percentage)
            return Number.isFinite(percentage) && !Number.isNaN(percentage) && percentage !== 0
        })

    handleChange = (state) =>
        this.setState({
            isDirty: true,
            ...state,
        })

    handlePercentageChange = (e) => {
        const percentage = e.target.value
        const percentageChange = 1 + parseFloat(percentage) / 100
        const priceTiers = this.props.priceTiers.map((group) => ({
            ...group,
            tiers: group.tiers.map((tier) => ({
                ...tier,
                newFixedRate: tier.fixedRate * percentageChange,
                newIncrementalRate: tier.incrementalRate * percentageChange,
            })),
        }))

        this.setState({ priceTiers })

        this.handleChange({ percentage })
    }

    handleValidDateChange = (startDate) => {
        this.setState({ startDate })
    }

    handleFocusedValidDateChange = ({ focused }) => this.setState({ focused })

    handleTierCheck = (tierId, checked) => {
        this.setState((state) => {
            let checkedTiers
            if (checked) {
                checkedTiers = [...state.checkedTiers, tierId]
            } else {
                checkedTiers = state.checkedTiers.filter((id) => id !== tierId)
            }
            return {
                checkedTiers,
            }
        })
    }

    handleSubmit = () => {
        const form = this.state.priceTiers
            .flatMap(({ tiers }) => tiers)
            .filter((tier) => this.state.checkedTiers.includes(tier.id))
            .map((tier) => ({
                tierId: tier.id,
                date: this.state.startDate.format(DATE_FORMAT),
                multiplierPercentage: 100 + parseFloat(this.state.percentage),
            }))
        this.props.modifyPriceTiers(form).then(this.props.onClose)
    }

    render() {
        const { partitionName, show, onClose, unit, enumTier, quantityEnumMap } = this.props
        const { percentage, startDate, focused, priceTiers } = this.state

        if (!show) {
            return null
        }

        return (
            <Modal className="adjust-price-tiers" show onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adjust tier price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <FormGroup controlId="percentage" validationState={this.getPercentageValidationState()}>
                                <ControlLabel>Enter a percentage change</ControlLabel>
                                <InputGroup>
                                    <FormControl
                                        type="number"
                                        value={percentage}
                                        placeholder="0.0"
                                        onChange={this.handlePercentageChange}
                                    />
                                    <InputGroup.Addon>%</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>Effective (optional)</ControlLabel>
                                <SingleDatePicker
                                    date={startDate}
                                    onDateChange={this.handleValidDateChange}
                                    focused={focused}
                                    onFocusChange={this.handleFocusedValidDateChange}
                                    displayFormat={DATE_FORMAT}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Table className="price-list-table striped bordered condensed hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{partitionName}</th>
                                        <th>Tiers ( Price &amp; {unit.name} )</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {priceTiers.map((group, i) => (
                                        <GroupRow
                                            key={group.key.join(',')}
                                            i={i + 1}
                                            group={group}
                                            unit={unit}
                                            onTierCheck={this.handleTierCheck}
                                            enumTier={enumTier}
                                            quantityEnumMap={quantityEnumMap}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" onClick={this.handleSubmit} bsStyle="primary">
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default AdjustTiersModal

AdjustTiersModal.propTypes = {
    priceTiers: PropTypes.array.isRequired,
    partitionName: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    unit: PropTypes.object.isRequired,
    modifyPriceTiers: PropTypes.func.isRequired,
    enumTier: PropTypes.bool.isRequired,
    quantityEnumMap: PropTypes.object,
}
