import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const SUCCESS = 'success'
const INFO = 'info'
const WARNING = 'warning'
const DANGER = 'danger'

export default class Alert extends Component {
    render() {
        if (this.props.message == null) {
            return null
        }

        const classes = {
            alert: true,
            'alert-success': SUCCESS === this.props.type,
            'alert-info': INFO === this.props.type,
            'alert-warning': WARNING === this.props.type,
            'alert-danger': DANGER === this.props.type,
        }

        return <div className={cx(this.props.classNames, classes)}>{this.props.message}</div>
    }
}

Alert.propTypes = {
    type: PropTypes.oneOf([SUCCESS, INFO, WARNING, DANGER]),
}
