import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Form, FormGroup, FormControl, Button, ControlLabel } from 'react-bootstrap'

import { createReturnPalletGroup } from '../../actions/return-pallet-groups'

class ReturnPalletGroupCreateForm extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
        }
    }

    createGroup = () => {
        const { dispatch } = this.props
        const { name } = this.state
        dispatch(createReturnPalletGroup(name))
        this.setState({ name: '' })
    }

    onNameChange = (e) => {
        const name = e.target.value.slice(0, 50)
        this.setState({ name })
    }

    render = () => {
        const { name } = this.state
        const isSubmitDisabled = name.trim().length === 0
        return (
            <Form inline>
                <FormGroup>
                    <ControlLabel>New group</ControlLabel>{' '}
                    <FormControl type="text" value={name} placeholder="Name" onChange={this.onNameChange} />
                </FormGroup>{' '}
                <Button type="submit" bsStyle="primary" disabled={isSubmitDisabled} onClick={this.createGroup}>
                    Create
                </Button>
            </Form>
        )
    }
}

ReturnPalletGroupCreateForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect()(ReturnPalletGroupCreateForm)
