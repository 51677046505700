import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import RecurringPaymentTransactionInline from './admin-recurring-payment-transactions-inline'

const AdminRecurringPaymentTransactions = (props) => {
    const { transactions } = props
    return (
        <Table striped className="text-center">
            <thead>
                <tr className="consumer-subscriptions-header">
                    <th>Adyen Reference</th>
                    <th>Amount</th>
                    <th>Initiated At</th>
                    <th>Status</th>
                    <th>Processed</th>
                </tr>
            </thead>
            <tbody>
                {transactions
                    ? transactions.map((transaction) => {
                          return (
                              <RecurringPaymentTransactionInline
                                  key={transaction.adyenReference}
                                  adyenReference={transaction.adyenReference}
                                  amount={transaction.amount}
                                  currency={transaction.currency}
                                  initiatedAt={transaction.submittedAt}
                                  status={transaction.status}
                                  processed={transaction.processed}
                              />
                          )
                      })
                    : null}
            </tbody>
        </Table>
    )
}

AdminRecurringPaymentTransactions.propTypes = {
    transactions: PropTypes.array,
}

export default AdminRecurringPaymentTransactions
