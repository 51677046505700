import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Button, Image, Col } from 'react-bootstrap'

import { addCollectionImage } from '../../../../utils/shopping-collection-api'
import { handleError } from '../../../../utils/handle-error'
import MerchantInput from '../merchant-input'
import Uploader from '../../../uploader'

const initData = {
    title: null,
    order: null,
    previousPrice: null,
    currentPrice: null,
    imageUrl: null,
    url: null,
    created: null,
}
export default class ProductForm extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            ...initData,
            preView: null,
            fileObject: null,
            merchantId: this.props.merchantId || null,
        }
    }

    handleInputChange = (evt) => {
        const { name, value } = evt.target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    handleProductData = () => {
        this.props.saveProductData({
            ...this.state,
            order: Number(this.state.order),
            currentPrice: Number(this.state.currentPrice),
            previousPrice: Number(this.state.previousPrice),
        })
        this.setState({ ...initData, merchantId: null })
    }

    onUpload = ([file]) => {
        if (file == null) {
            return
        }

        this.setState({
            preView: URL.createObjectURL(file),
            fileObject: file,
        })
    }

    render() {
        return (
            <div>
                <p>Product Details:</p>
                <MerchantInput
                    type="text"
                    name="title"
                    required
                    controlId="title"
                    label="Product Title"
                    value={this.state.title}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="number"
                    name="order"
                    required
                    controlId="order"
                    label="Product order"
                    value={this.state.order}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="text"
                    name="merchantId"
                    required
                    controlId="merchantId"
                    label="Merchant Id"
                    value={this.state.merchantId}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="number"
                    name="currentPrice"
                    required
                    controlId="currentPrice"
                    label="Price"
                    value={this.state.currentPrice}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="number"
                    name="previousPrice"
                    required
                    controlId="previousPrice"
                    label="Previous Price"
                    value={this.state.previousPrice}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="text"
                    name="imageUrl"
                    required
                    controlId="imageUrl"
                    label="Image Url"
                    value={this.state.imageUrl}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <MerchantInput
                    type="text"
                    name="url"
                    required
                    controlId="url"
                    label="Url"
                    value={this.state.url}
                    onChange={this.handleInputChange}
                    validate={() => true}
                />
                <FormGroup>
                    <Col smOffset={3} sm={9}>
                        <Uploader onUpload={this.onUpload} />
                        {this.state.preView && (
                            <div>
                                <p>Preview</p>
                                <Image src={this.state.preView} responsive />
                            </div>
                        )}
                        <br />
                        <Button
                            className="pull-right"
                            onClick={() => {
                                addCollectionImage(this.props.groupId, this.props.collectionId, this.state.fileObject)
                                    .then((res) => {
                                        this.setState({ imageUrl: res.url })
                                    })
                                    .catch((err) => {
                                        handleError(err)
                                    })
                            }}
                        >
                            Upload
                        </Button>
                        <Button onClick={this.handleProductData}>save product </Button>
                    </Col>
                </FormGroup>
            </div>
        )
    }
}

ProductForm.propTypes = {
    merchantId: PropTypes.number,
    saveProductData: PropTypes.func.isRequired,
    groupId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
}
