import Webapi from './webapi'
import auth from '../auth'

const contentTypeV1 = 'application/vnd.budbee.rejected-invoices-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .contentType(contentTypeV1)
        .accept(contentTypeV1)
        .path('invoices')

export const getRejectedInvoiceItems = (rejectedInvoiceId) =>
    webapi()
        .path('rejected')
        .path(rejectedInvoiceId)
        .path('item')
        .get()

export const createRejectedInvoiceItem = (rejectedInvoiceId, invoiceItem) =>
    webapi()
        .path('rejected')
        .path(rejectedInvoiceId)
        .path('item')
        .post(invoiceItem)

export const deleteRejectedInvoiceItem = (rejectedInvoiceItemId) =>
    webapi()
        .path('rejected')
        .path('item')
        .path(rejectedInvoiceItemId)
        .delete()

export const getCourierRejectedInvoices = (courierId) =>
    webapi()
        .path('rejected')
        .path('couriers')
        .path(courierId)
        .get()

export const getCourierRejectedInvoice = (rejectedInvoiceId, courierId) =>
    webapi()
        .path('rejected')
        .path(rejectedInvoiceId)
        .path('couriers')
        .path(courierId)
        .get()
