import Api from './api'
import auth from '../auth'

const baseUrl = '/api/collection-points'
const contentType = 'application/vnd.budbee.collection-points-v1+json'

export default {
    addZones(id, zoneIds) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(zoneIds)
    },

    getCPZones(id) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    storePostalCodeZones(id, zones) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(zones)
    },

    removeCPZones(id, zones) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones')
            .path('remove')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .delete(zones)
    },

    getZones(id, buyerId) {
        return new Api(baseUrl, buyerId)
            .path(id)
            .path('zones')
            .path(buyerId)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    addZoneTransits(id, zoneTransits) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones-transits')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .put(zoneTransits)
    },

    getZoneTransits(id) {
        return new Api(baseUrl)
            .path(id)
            .path('postal-code-zones-transits')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    getCPIntervals(id) {
        return new Api(baseUrl)
            .path(id)
            .path('intervals')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    deleteCPIntervals(intervals) {
        return new Api(baseUrl)
            .path('intervals')
            .path('remove')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .delete(intervals)
    },

    saveCPIntervals(id, intervals) {
        return new Api(baseUrl)
            .path(id)
            .path('intervals')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .post(intervals)
    },
}
