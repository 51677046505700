import { SMS_REQUEST_ERROR, SMS_REQUEST_RESPONSE, SMS_REQUEST_SENT } from '../constants/actionTypes'
import smsState from './initialState/smsState'

export default function SmsReducer(state = smsState, action) {
    switch (action.type) {
        case SMS_REQUEST_SENT:
            return { ...state, sending: true }

        case SMS_REQUEST_RESPONSE:
            return { ...state, error: false, sending: false }

        case SMS_REQUEST_ERROR:
            return { ...state, error: true, sending: false }

        default:
            return state
    }
}
