import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, ControlLabel, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap'
import { getRegionalSettings } from '../../utils/settings-api'
import { handleError } from '../../utils/handle-error'

export default class ShippingCost extends Component {
    constructor(props) {
        super(props)

        this.state = {
            shipping: [],
            regionalSettings: {},
        }
    }

    componentDidMount() {
        const shippingCosts = this.props.value.map((element) =>
            element.freeThresholdInCents == null
                ? {
                      ...element,
                      check: true,
                  }
                : element,
        )

        this.setState({
            shipping: [...shippingCosts],
        })

        getRegionalSettings()
            .then((res) => {
                this.setState({
                    regionalSettings: res,
                })
            })
            .catch(handleError)
    }

    onChange = (prop, name, country) => (e) => {
        const { shipping } = this.state
        const { id, currency } = country
        const shippingCosts = shipping.find((element) => element.country === id)
        const value = e.target[prop] * 100

        if (shippingCosts) {
            this.setState(
                {
                    shipping: shipping.map((element) =>
                        element.country === id
                            ? {
                                  ...element,
                                  [name]: value,
                              }
                            : element,
                    ),
                },
                () => this.props.onChange(this.state.shipping),
            )
        } else {
            this.setState(
                {
                    shipping: [
                        ...shipping,
                        {
                            shippingPriceInCents: 0,
                            [name]: value,
                            country: id,
                            currency: {
                                code: currency,
                            },
                        },
                    ],
                },
                () => this.props.onChange(this.state.shipping),
            )
        }
    }

    onFreeThresholdChange = (country) => (e) => {
        const { shipping } = this.state
        this.setState(
            {
                shipping: shipping.map((element) =>
                    element.country === country.id
                        ? {
                              ...element,
                              freeThresholdInCents: e.target.checked ? null : 0,
                              check: e.target.checked,
                          }
                        : element,
                ),
            },
            () => this.props.onChange(this.state.shipping),
        )
    }

    renderCountry = (country) => {
        const { shipping } = this.state
        const { id, label, currency } = country
        const shippingCosts = shipping.find((element) => element.country === id)

        let threshold = ''
        let shippingPrice = ''
        let checked = false
        if (shippingCosts) {
            const { freeThresholdInCents, shippingPriceInCents, check } = shippingCosts

            threshold = freeThresholdInCents / 100
            shippingPrice = shippingPriceInCents / 100
            checked = check
        }

        return (
            <div key={id}>
                <Row>
                    <Col sm={12}>
                        <ControlLabel>{label}</ControlLabel>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} smOffset={0}>
                        <FormGroup>
                            <Col sm={7}>
                                <ControlLabel bsClass="small">Free Shipping Threshold</ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon>{currency}</InputGroup.Addon>
                                    <FormControl
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={threshold}
                                        disabled={checked}
                                        onChange={this.onChange('value', 'freeThresholdInCents', country)}
                                    />
                                </InputGroup>
                                <Checkbox checked={checked} onChange={this.onFreeThresholdChange(country)}>
                                    {checked ? 'Enable free shipping' : 'Disable free shipping'}
                                </Checkbox>
                            </Col>
                            <Col sm={5}>
                                <ControlLabel bsClass="small">Shipping Price</ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon>{currency}</InputGroup.Addon>
                                    <FormControl
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={shippingPrice}
                                        onChange={this.onChange('value', 'shippingPriceInCents', country)}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const { regionalSettings } = this.state

        if (regionalSettings.countries) {
            return <div>{regionalSettings.countries.map(this.renderCountry)}</div>
        }

        return <div />
    }
}

ShippingCost.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func,
}
