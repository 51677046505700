export default {
    error: {
        status: '',
        statusText: '',
    },
    loading: false,
    group: {},
    groups: [],
    postalCodeZones: [],
    previousGroup: {}
}
