import React from 'react'
import { Col } from 'react-bootstrap'
import ProductSettingsForm from './components/product-settings-form'

const Products = () => (
    <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
        <ProductSettingsForm />
    </Col>
)

export default Products
