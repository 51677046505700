import * as types from '../constants/actionTypes'
import {
    getRecurringPickups,
    getRecurringPickup,
    getRecurringPickupLocations,
    getMultipleRecurringPickupLocations,
    createRecurringPickup as create,
    editRecurringPickup as edit,
    createOneTimePickup,
    deleteRecurringPickup as remove,
    createMerchantHoliday,
    fetchMerchantHolidays,
    removeMerchantHoliday,
    fetchHistory,
} from '../utils/recurring-pickups-webapi'
import { compose } from '../utils/compose'
import { handleError, handleErrorWithJson } from '../utils/handle-error'

export const fetchingRecurringPickups = () => ({
    type: types.FETCHING_RECURRING_PICKUPS,
})

export const recurringPickupLoaded = (data) => ({
    type: types.RECURRING_PICKUP_LOADED,
    status: 200,
    data,
})

export const recurringPickupsLoaded = (data) => ({
    type: types.RECURRING_PICKUPS_LOADED,
    status: 200,
    data,
})

export const recurringPickupDeleted = (id) => () => ({
    type: types.RECURRING_PICKUP_DELETED,
    status: 200,
    id,
})

export const recurringPickupCollectionPointsLoaded = (data) => ({
    type: types.RECURRING_PICKUP_LOCATIONS_LOADED,
    status: 200,
    data,
})

export const recurringPickupCollectionPointsMultipleLoaded = (data) => ({
    type: types.RECURRING_PICKUP_LOCATIONS_MULTIPLE_LOADED,
    status: 200,
    data,
})

export const recurringPickupCollectionPointSchedulesLoaded = (data) => ({
    type: types.RECURRING_PICKUP_COLLECTION_POINT_SCHEDULES_LOADED,
    status: 200,
    data,
})

export const recurringPickupCreated = (data) => ({
    type: types.RECURRING_PICKUP_CREATED,
    status: 200,
    data,
})

export const fetchingMerchantHolidays = () => ({
    type: types.FETCHING_MERCHANT_HOLIDAYS,
})

export const merchantHolidaysLoaded = (data) => ({
    type: types.MERCHANT_HOLIDAYS_LOADED,
    status: 200,
    data,
})

export const recurringPickupHistoryFetched = (data) => ({
    type: types.RECURRING_PICKUP_HISTORY_FETCHED,
    data,
})

export const removeRoute = (id) => (dispatch) =>
    remove(id)
        .then(recurringPickupDeleted(id))
        .then(dispatch)
        .catch(handleError)

export const fetchRecurringPickups = (routeType) => (dispatch) => {
    dispatch(fetchingRecurringPickups())

    return getRecurringPickups(routeType)
        .then(recurringPickupsLoaded)
        .then(dispatch)
        .catch(handleError)
}

export const fetchRecurringPickup = (id) => (dispatch) => {
    dispatch(fetchingRecurringPickups())

    return getRecurringPickup(id)
        .then(recurringPickupLoaded)
        .then(dispatch)
        .catch(handleError)
}

export const fetchRecurringPickupCollectionPoints = (dayOfWeek) => (dispatch) =>
    getRecurringPickupLocations(dayOfWeek)
        .then(recurringPickupCollectionPointsLoaded)
        .then(dispatch)
        .catch(handleError)

export const fetchRecurringPickupCollectionMultiplePoints = (daysOfWeek) => (dispatch) =>
    getMultipleRecurringPickupLocations(daysOfWeek)
        .then(recurringPickupCollectionPointsMultipleLoaded)
        .then(dispatch)
        .catch(handleError)

export const fetchRecurringPickupCollectionPointSchedules = (daysOfWeek) => (dispatch) =>
    getMultipleRecurringPickupLocations(daysOfWeek)
        .then(recurringPickupCollectionPointSchedulesLoaded)
        .then(dispatch)
        .catch(handleError)

export const createRecurringPickup = (model, router) => (dispatch) =>
    create(model)
        .then((response) => {
            dispatch(router.push('/admin/recurring-routes'))
            dispatch(recurringPickupCreated(response))
        })
        .catch(handleError)

export const editRecurringPickup = (id, model, router) => (dispatch) =>
    edit(id, model)
        .then(() => {
            dispatch(router.push('/admin/recurring-routes'))
        })
        .catch(handleErrorWithJson)

export const createHoliday = (data) => () => createMerchantHoliday(data).catch(handleError)

export const fetchHolidays = () => (dispatch) =>
    fetchMerchantHolidays()
        .then(compose(dispatch, merchantHolidaysLoaded))
        .catch(handleError)

export const removeHoliday = (id) => () => removeMerchantHoliday(id)

export const createOneTimeRoute = (id, model, router) => (dispatch) =>
    createOneTimePickup(id, model)
        .then(() => {
            dispatch(router.push('/admin/recurring-routes'))
        })
        .catch(handleErrorWithJson)

export const fetchRecurringPickupHistory = (id) => (dispatch) =>
    fetchHistory(id)
        .then(compose(dispatch, recurringPickupHistoryFetched))
        .catch(handleError)
