import Webapi from './webapi'
import auth from '../auth'
import { LINEHAUL_HUB_OR_REGION, MERCHANT_PRIORITY, ON_DEMAND_ROUTE } from './terminal-sorting-rule-types'

const contentType = 'application/vnd.budbee.terminal-sorting-v2+json'
const contentTypeV3 = 'application/vnd.budbee.terminal-sorting-v3+json'
const contentTypeV4 = 'application/vnd.budbee.terminal-sorting-v4+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('terminals')

export const getSortingProfiles = (terminalId) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .get()

export const setApplySelectedSortingProfile = (terminalId, profile) =>
    webapi()
        .path(terminalId)
        .path(`sorting-profiles/${profile}/apply`)
        .put()

export const setSortingProfile = (terminalId, profile) =>
    webapi()
        .path(terminalId)
        .path(`sorting-profiles/${profile}/persist`)
        .put()

export const getSortingLanes = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('lanes')
        .get()

export const getRules = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('rules')
        .get()

export const getProfileRules = (terminalId, profile) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .path(profile)
        .get()

export const getRouteSortingRules = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('route-rules')
        .get()

export const getLockerSortingRules = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('locker-rules')
        .get()

export const listCurrentRouteSortingStatuses = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('list-current-route-sorting-statuses')
        .get()

export const createDateSortingRule = (terminalSortingLaneId, date, recurToday, lesserThan, greaterThan) =>
    webapi()
        .path('date-rules')
        .post({
            terminalSortingLaneId,
            date,
            recurToday,
            lesserThan,
            greaterThan,
        })

export const createTemporalSortingRule = (terminalSortingLaneId, temporal) =>
    webapi()
        .path('temporal-rules')
        .post({
            terminalSortingLaneId,
            temporal,
        })

export const createOnDemandRouteSortingRule = (terminalId, terminalSortingLaneId, routeId) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules`)
        .post({
            terminalSortingLaneId,
            type: ON_DEMAND_ROUTE,
            attributes: { routeId },
        })

export const createRouteSortingRule = (terminalSortingLaneId, route) =>
    webapi()
        .path('route-rules')
        .post({ terminalSortingLaneId, route })

export const createLockerStopSortingRule = (terminalId, terminalSortingLaneId, lockerStopId) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules`)
        .post({
            terminalSortingLaneId,
            type: 'LOCKER_STOP',
            attributes: { lockerStopId },
        })

export const createReturnToMerchantSortingRule = (terminalId, terminalSortingLaneId, merchantId, merchantGroupId) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules`)
        .post({
            terminalSortingLaneId,
            type: 'RETURN_TO_MERCHANT',
            attributes: { merchantId, merchantGroupId },
        })

export const createLinehaulHubOrRegionSortingRule = (
    terminalId,
    terminalSortingLaneId,
    groupWarehouseId,
    groupWarehouseType,
) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules`)
        .post({
            terminalSortingLaneId,
            type: LINEHAUL_HUB_OR_REGION,
            attributes: { groupWarehouseId, groupWarehouseType },
        })

export const createSortingRules = (terminalId, terminalSortingLaneId, rules) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules/batch`)
        .post(rules)

export const createTerminalSortingRule = (terminalSortingLaneId, terminalId) =>
    webapi()
        .path('terminal-rules')
        .post({ terminalSortingLaneId, terminalId })

export const createReturnLinehaulSortingRule = (terminalSortingLaneId, terminalId) =>
    webapi()
        .path('return-linehaul-rules')
        .post({ terminalSortingLaneId, terminalId })

export const createLockerSortingRule = (terminalSortingLaneId, lockerId) =>
    webapi()
        .path('locker-rules')
        .post({ terminalSortingLaneId, lockerId })

export const createLockerLinehaulSortingRule = (terminalSortingLaneId, terminalId) =>
    webapi()
        .path('locker-linehaul-rules')
        .post({ terminalSortingLaneId, terminalId })

export const createMerchantPrioritySortingRule = (
    terminalId,
    terminalSortingLaneId,
    merchantId,
    merchantGroupId,
    exclusivePriority,
) =>
    webapi()
        .path(`${terminalId}/lanes/${terminalSortingLaneId}/rules`)
        .post({
            terminalSortingLaneId,
            type: MERCHANT_PRIORITY,
            attributes: { merchantId, merchantGroupId, exclusivePriority },
        })

export const createReadFailSortingRule = (terminalSortingLaneId) =>
    webapi()
        .path('read-fail-rules')
        .post({ terminalSortingLaneId })

export const createOversizeLockerParcelSortingRule = (terminalSortingLaneId) =>
    webapi()
        .path('oversize-locker-parcel-rules')
        .post({ terminalSortingLaneId })

export const deleteDateSortingRule = (id) =>
    webapi()
        .path('date-rules')
        .path(id)
        .delete()

export const deleteTemporalSortingRule = (id) =>
    webapi()
        .path('temporal-rules')
        .path(id)
        .delete()

export const deleteRouteSortingRule = (id) =>
    webapi()
        .path('route-rules')
        .path(id)
        .delete()

export const deleteTerminalSortingRule = (id) =>
    webapi()
        .path('terminal-rules')
        .path(id)
        .delete()

export const deleteReturnLinehaulSortingRule = (id) =>
    webapi()
        .path('return-linehaul-rules')
        .path(id)
        .delete()

export const deleteLockerSortingRule = (id) =>
    webapi()
        .path('locker-rules')
        .path(id)
        .delete()

export const deleteLockerLinehaulSortingRule = (id) =>
    webapi()
        .path('locker-linehaul-rules')
        .path(id)
        .delete()

export const deleteSortingRule = (id) =>
    webapi()
        .path('rules')
        .path(id)
        .delete()

export const deleteReadFailSortingRule = (id) =>
    webapi()
        .path('read-fail-rules')
        .path(id)
        .delete()

export const deleteOversizeLockerParcelSortingRule = (id) =>
    webapi()
        .path('oversize-locker-parcel-rules')
        .path(id)
        .delete()

export const setBookDeliveryAutomatically = (terminalId, enabled) =>
    webapi()
        .path(terminalId)
        .path('toggle-delivery-booking')
        .put({ enabled })

export const editSortingMachineAssignDirection = (terminalId, direction) =>
    webapi()
        .path(terminalId)
        .path('sorting/sorting-machine-assign-direction')
        .put({ direction })

export const editMinimumNumberOfRoutesPerLane = (terminalId, value) =>
    webapi()
        .path(terminalId)
        .path('sorting/minimum-number-of-routes-per-lane')
        .put({ value })

export const editMaximumNumberOfRoutesPerLane = (terminalId, value) =>
    webapi()
        .path(terminalId)
        .path('sorting/maximum-number-of-routes-per-lane')
        .put({ value })

export const backorderMissingParcels = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('sorting/backorder-missing-parcels')
        .post()

export const backorderNonVerifiedParcels = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('sorting/backorder-non-verified-parcels')
        .queryParam('dryRun', false)
        .post()

export const getBackorderNonVerifiedParcels = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('sorting/backorder-non-verified-parcels')
        .queryParam('dryRun', true)
        .post()

export const setLaneEnabled = (terminalId, laneId, enabled) =>
    webapi()
        .path(terminalId)
        .path('lanes')
        .path(laneId)
        .path('enabled')
        .put({ enabled })

export const assignOnDemandRoutesToLanes = (terminalId, laneIds, merchant) =>
    webapi()
        .path(terminalId)
        .path('sorting/assign-on-demand-routes-to-lanes')
        .post({ laneIds, merchantId: merchant })

export const assignRoutesToLanes = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('sorting/assign-routes-to-lanes')
        .post()

export const getTerminalLoadingSlots = (terminalId, date) =>
    webapi()
        .path(terminalId)
        .path('loading-slots')
        .path(date)
        .get()

export const getLockersForSorting = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('lockers-for-sorting')
        .get()

export const deleteAllRules = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('rules')
        .delete()

export const getBookDelivery = (terminalId) =>
    webapi()
        .accept(contentTypeV3)
        .contentType(contentTypeV3)
        .path(terminalId)
        .path('delivery-booking')
        .get()

export const setBookDelivery = (terminalId, data) =>
    webapi()
        .accept(contentTypeV3)
        .contentType(contentTypeV3)
        .path(terminalId)
        .path('delivery-booking')
        .put(data)

export const createProfileSortingRule = (terminalId, profile, terminalSortingLaneId, type, attributes = {}) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .path(profile)
        .path('lane')
        .path(terminalSortingLaneId)
        .path('rule')
        .post({ type, attributes })

export const createProfileSortingRules = (terminalId, profile, terminalSortingLaneId, rules) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .path(profile)
        .path('lane')
        .path(terminalSortingLaneId)
        .path('rules')
        .path('batch')
        .post(rules)

export const deleteProfileSortingRule = (terminalId, profile, ruleId) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .path(profile)
        .path('rule')
        .path(ruleId)
        .delete()

export const deleteProfileSortingRules = (terminalId, profile) =>
    webapi()
        .accept(contentTypeV4)
        .contentType(contentTypeV4)
        .path(terminalId)
        .path('sorting-profiles')
        .path(profile)
        .path('rules')
        .delete()
