import React from 'react'
import PropTypes from 'react/lib/ReactPropTypes'
import Loader from 'react-loader'
import { connect } from 'react-redux'
import { Col, Row, Panel } from 'react-bootstrap'
import auth from '../../auth'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'
import { getCourierRejectedInvoices } from '../../utils/rejection-invoice-webapi'
import { fetchCourier } from '../../actions/couriers'
import RejectedInvoiceList from './rejected-invoice-list'

class RejectedInvoiceView extends React.Component {
    transitionToManageRejectedInvoiceItems = (courierId, rejectedInvoiceId) => () => {
        this.props.router.push(`/admin/couriers/${courierId}/rejected-invoices/${rejectedInvoiceId}`)
    }

    transitionToCourierPage = () => {
        this.props.router.push('/admin/couriers')
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.transitionToCourierPage()
            return
        }
        const { dispatch } = this.props
        dispatch(fetchCourier(this.props.params.id))
    }

    render() {
        if (this.props.loading) {
            return <Loader color="#BFBFBF" />
        }
        const invoiceHeader = <h4>Manage rejected invoices</h4>

        return (
            <Col md={10} mdOffset={1}>
                <Col md={10} mdOffset={1}>
                    <Row>
                        <h2> Rejected invoices for courier {this.props.courier.name} </h2>
                    </Row>
                    <Row>
                        <Panel header={invoiceHeader}>
                            <RejectedInvoiceList
                                courierId={parseInt(this.props.params.id, 10)}
                                merchantId={null}
                                loadRejectedInvoices={getCourierRejectedInvoices}
                                router={this.props.router}
                            />
                        </Panel>
                    </Row>
                </Col>
            </Col>
        )
    }
}
RejectedInvoiceView.propTypes = {
    dispatch: PropTypes.func,
    params: PropTypes.object,
    router: PropTypes.object,
    courier: PropTypes.object,
    loading: PropTypes.bool,
}

const mapStateToProps = ({ couriers: { loading, error, courier } }) => ({
    loading,
    error,
    courier,
})

export default connect(mapStateToProps)(RejectedInvoiceView)
