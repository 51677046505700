import React from 'react'
import { Row, Col } from 'react-bootstrap'
import PropTypes from 'react/lib/ReactPropTypes'
import InvoiceItemView from './invoice-item-view'
import RecurringInvoiceView from './recurring-invoice-item-view'

export default class FixedCosts extends React.Component {
    render() {
        return (
            <div>
                <RecurringInvoiceView
                    deleteRecurringInvoiceItem={this.props.deleteRecurringInvoiceItem}
                    invoiceRecipientId={this.props.invoiceRecipientId}
                    createRecurringInvoiceItem={this.props.createRecurringInvoiceItem}
                    loadRecurringInvoiceItems={this.props.loadRecurringInvoiceItems}
                    invoiceTypes={this.props.invoiceTypes}
                    invoiceArticles={this.props.invoiceArticles}
                />
                <Col md={12}>
                    <Row>
                        <h3> Manage invoices</h3>
                        <h5> Which have not yet been booked</h5>
                    </Row>
                </Col>
                <InvoiceItemView
                    deleteInvoiceItem={this.props.deleteInvoiceItem}
                    invoiceRecipientId={this.props.invoiceRecipientId}
                    createInvoiceItem={this.props.createInvoiceItem}
                    loadAllInvoiceItems={this.props.loadAllInvoiceItems}
                    invoiceTypes={this.props.invoiceTypes}
                    invoiceArticles={this.props.invoiceArticles}
                />
            </div>
        )
    }
}

FixedCosts.propTypes = {
    invoiceRecipientId: PropTypes.number,
    loadRecurringInvoiceItems: PropTypes.func,
    loadAllInvoiceItems: PropTypes.func,
    createRecurringInvoiceItem: PropTypes.func,
    createInvoiceItem: PropTypes.func,
    deleteRecurringInvoiceItem: PropTypes.func,
    deleteInvoiceItem: PropTypes.func,
    invoiceTypes: PropTypes.array,
    invoiceArticles: PropTypes.array,
}
