import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import Map from '../../map/map'
import { decodePolylines } from '../../map/polyline-decode'
import Chart from './chart'
import ControlPanel from './control-panel'
import { getRoute, getRouteAnalysis } from '../../../utils/route-webapi'
import { handleError } from '../../../utils/handle-error'

export default class RouteAnalysis extends Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
    }

    state = {
        loading: true,
        route: null,
        analysis: null,
        selectedStop: null,
        displayEstimatedRoute: true,
        displayActualRoute: true,
        markers: [],
        estimatedRouteCoordinates: [],
        actualRouteCoordinates: [],
        zoomToLocation: null,
    }

    componentDidMount() {
        this.setState({ loading: true })

        getRoute(this.props.params.routeId)
            .then((route) => {
                this.setState({ loading: false, route })
                this.getAnalysis(route.id)
                if (route.completed) {
                    this.getAnalysis(route.id)
                }
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    onClickChart = (index) => {
        const { analysis } = this.state
        const stop = analysis.stops[index]

        this.setState({
            selectedStop: stop.sequence,
            zoomToLocation: stop.coordinate,
        })

        if (this.state.displayEstimatedRoute) {
            const estimatedRoute = this.getRouteCoordinates(analysis.estimatedRoute, stop.sequence)
            this.setState({
                estimatedRouteCoordinates: estimatedRoute,
            })
        }

        if (this.state.displayActualRoute) {
            const actualRoute = this.getRouteCoordinates(analysis.actualRoute, stop.sequence)
            this.setState({
                actualRouteCoordinates: actualRoute,
            })
        }
    }

    getAnalysis = (routeId) => {
        getRouteAnalysis(routeId)
            .then((analysis) => {
                this.setState({ analysis })
                this.analyzeRoute(analysis)
            })
            .catch((e) => {
                handleError(e)
                this.setState({ loading: false })
            })
    }

    getMarkers = (route) => {
        const markers = []

        route.terminalStops.forEach((stop) => {
            const marker = this.markerForStop(stop, '#F39C12')
            if (marker) {
                markers.push(marker)
            }
        })

        route.crossdockStops.forEach((stop) => {
            const marker = this.markerForStop(stop, '#F39C12')
            if (marker) {
                markers.push(marker)
            }
        })

        route.consumerStops.forEach((stop) => {
            const cancelled = stop.consignments.every((sc) => sc.consignment.cancellation !== null)
            const marker = this.markerForStop(stop, cancelled ? '#BDC3C7' : '#41CDA5')
            if (marker) {
                markers.push(marker)
            }
        })

        route.restStops.forEach((stop) => {
            const marker = this.markerForStop(stop, '#F39C12')
            if (marker) {
                markers.push(marker)
            }
        })

        return markers.sort((a, b) => a.label - b.label)
    }

    getRouteCoordinates = (route, sequence = Number.MAX_SAFE_INTEGER) => {
        const polylines = route.filter((leg) => leg.toSequence <= sequence).map((step) => step.polyline)
        return decodePolylines(polylines)
    }

    markerForStop = (stop, color) => {
        if (stop.address.coordinate) {
            return {
                label: stop.sequence,
                coordinate: stop.address.coordinate,
                color,
            }
        }

        return null
    }

    analyzeRoute = (analysis) => {
        this.setState({
            estimatedRouteCoordinates: this.getRouteCoordinates(analysis.estimatedRoute),
            actualRouteCoordinates: this.getRouteCoordinates(analysis.actualRoute),
        })
    }

    mapLoaded = () => {
        const { route, analysis } = this.state

        if (!route) {
            return
        }

        const markers = this.getMarkers(route)
        this.setState({ markers })

        if (analysis) {
            this.analyzeRoute(analysis)
        } else {
            const birdRoute = markers.map(({ coordinate: { latitude, longitude } }) => [longitude, latitude])
            this.setState({ estimatedRouteCoordinates: birdRoute })
        }
    }

    renderControlPanel() {
        const { route, analysis, selectedStop } = this.state

        if (!route) {
            return null
        }

        return (
            <ControlPanel
                routeId={route.id}
                date={route.dueDate}
                analysis={analysis}
                onToggleDisplayRoute={(checked) => {
                    this.setState({ displayEstimatedRoute: checked })
                    if (checked) {
                        const polyline = selectedStop
                            ? this.getRouteCoordinates(analysis.estimatedRoute, selectedStop)
                            : this.getRouteCoordinates(analysis.estimatedRoute)
                        this.setState({
                            estimatedRouteCoordinates: polyline,
                        })
                    } else {
                        this.setState({
                            estimatedRouteCoordinates: [],
                        })
                    }
                }}
                onToggleDisplayHeatmap={(checked) => {
                    this.setState({ displayActualRoute: checked })
                    if (checked) {
                        const polyline = selectedStop
                            ? this.getRouteCoordinates(analysis.actualRoute, selectedStop)
                            : this.getRouteCoordinates(analysis.actualRoute)
                        this.setState({
                            actualRouteCoordinates: polyline,
                        })
                    } else {
                        this.setState({
                            actualRouteCoordinates: [],
                        })
                    }
                }}
            />
        )
    }

    renderChart() {
        const { analysis } = this.state

        if (!analysis) {
            return null
        }

        return <Chart stops={analysis.stops} timezone={analysis.zoneId} onClick={this.onClickChart} />
    }

    render() {
        const {
            loading,
            markers,
            displayEstimatedRoute,
            displayActualRoute,
            estimatedRouteCoordinates,
            actualRouteCoordinates,
            zoomToLocation,
        } = this.state

        if (loading) {
            return <Loader />
        }

        return (
            <div style={{ backgroundColor: '#191A1A' }}>
                <div style={{ width: '100vw', height: '75vh' }}>
                    <Map
                        onLoad={this.mapLoaded}
                        markers={markers}
                        displayPolyline={displayEstimatedRoute}
                        displayHeatmap={displayActualRoute}
                        polylineCoordinates={estimatedRouteCoordinates}
                        heatmapCoordinates={actualRouteCoordinates}
                        zoomToLocation={zoomToLocation}
                    >
                        {this.renderControlPanel()}
                    </Map>
                </div>
                <div style={{ width: '100vw', height: '20vh' }}>{this.renderChart()}</div>
            </div>
        )
    }
}
