import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, Checkbox, ControlLabel } from 'react-bootstrap'
import editMerchant from './edit-merchant-hoc'
import MerchantInput from './merchant-input'
import DeliveryPreferencesSelect from '../../inputs/selects/deliveryPreferences'
import ConsolidationSupportSelect from '../../inputs/selects/ConsolidationSupportSelect'

export const deliveryPreferencesTypes = {
    LEAVE_OUTSIDE_DOOR: 'Leave outside the door',
    LEAVE_WITH_NEIGHBOUR: 'Leave with neighbour',
    KNOCK_ON_DOOR: 'Knock on door',
    RING_DOOR_BELL: 'Ring doorbell',
}

export const returnPreferencesTypes = {
    BOX_RETURN: 'Box Return',
    HOME_RETURN: 'Home Return',
    SAME_AS_DELIVERY: 'Same as delivery',
}

class DistributionSettingsForm extends Component {
    handleChangeSettingsBasedOnCountry = (countryCode) => (name) => (value) => {
        this.props.changeSettingsBasedOnCountry(value, name, countryCode)
    }

    handleChangeSettings = (name) => (value) => this.props.changeSettings(value, name, true)

    handleOnChangeSettingsBasedOnCountry = (countryCode) => (name) => {
        return this.props.onChangeSettingsBasedOnCountry('checked', name, countryCode)
    }

    handleSettingChange = (type) => (name) => {
        return this.props.onSettingsChange(type === 'checkbox' ? 'checked' : 'value', name)
    }

    onSelectIntervalInCheckoutChange = (countryCode = null) => (e) => {
        const { onSettingsChange, onChangeSettingsBasedOnCountry } = this.props

        if (countryCode) {
            if (!e.target.checked) {
                onChangeSettingsBasedOnCountry(
                    'checked',
                    'allowedToCreateDeliveryAttemptsAfterCutoff',
                    countryCode,
                )({ target: { checked: false } })
            }
            onChangeSettingsBasedOnCountry('checked', 'selectIntervalInCheckout', countryCode)(e)
        } else {
            if (!e.target.checked) {
                onSettingsChange(
                    'checked',
                    'allowedToCreateDeliveryAttemptsAfterCutoff',
                )({ target: { checked: false } })
            }
            onSettingsChange('checked', 'selectIntervalInCheckout')(e)
        }
    }

    renderConfigurableSettingsBasedOnCountry = (countrySettings) => {
        const { allCountries, renderSettingsBasedOnCountriesHeader } = this.props

        const country = allCountries.find((c) => c.id === countrySettings.countryCode)

        if (!country) {
            return null
        }

        const isCountryWithLeaveWithNeighbour = country.id === 'NL' || country.id === 'BE'

        const handleCheckboxChange = this.handleOnChangeSettingsBasedOnCountry(countrySettings.countryCode)

        const handleSelectChange = this.handleChangeSettingsBasedOnCountry(countrySettings.countryCode)

        return (
            <div key={countrySettings.countryCode} className="country-based-settings">
                {renderSettingsBasedOnCountriesHeader(country, countrySettings)}
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={countrySettings.selectIntervalInCheckout}
                            onChange={this.onSelectIntervalInCheckoutChange(countrySettings.countryCode)}
                        >
                            Should Select Delivery Date in E-commerce Checkout
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.allowedToCreateDeliveryAttemptsAfterCutoff}
                            disabled={!countrySettings.selectIntervalInCheckout}
                            onChange={handleCheckboxChange('allowedToCreateDeliveryAttemptsAfterCutoff')}
                        >
                            Allowed to Create Delivery Attempts after cutoff (will be immediately Cancelled because of
                            delay)
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.onDemandPickup}
                            onChange={handleCheckboxChange('onDemandPickup')}
                        >
                            On demand pickup service
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.allowConvertBoxToHome}
                            onChange={handleCheckboxChange('allowConvertBoxToHome')}
                        >
                            Allow conversions to home order
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.allowConsumerToChangeDate}
                            onChange={handleCheckboxChange('allowConsumerToChangeDate')}
                        >
                            Allow consumer to change date for delivery
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.allowConsumerToEditOutsideDoor}
                            onChange={handleCheckboxChange('allowConsumerToEditOutsideDoor')}
                        >
                            Allow consumer to edit outside door
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.signatureRequired}
                            onChange={handleCheckboxChange('signatureRequired')}
                        >
                            Signature required by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.identificationCheckRequired}
                            onChange={handleCheckboxChange('identificationCheckRequired')}
                        >
                            Identification check by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.offerReturns}
                            onChange={handleCheckboxChange('offerReturns')}
                        >
                            Offer Return Service
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.offerReturnFromBuyers}
                            onChange={handleCheckboxChange('offerReturnFromBuyers')}
                        >
                            Offer Return Service from Merchant Portal
                        </Checkbox>
                        <Checkbox
                            checked={Boolean(countrySettings.hasTimeslots)}
                            onChange={handleCheckboxChange('hasTimeslots')}
                        >
                            Has timeslots
                        </Checkbox>
                        {/* TODO: Show this text when backend functionality is done */}
                        {/* <Checkbox
                            checked={countrySettings.defaultReturnTypeToDeliveryMethod}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={handleCheckboxChange('defaultReturnTypeToDeliveryMethod')}
                        >
                            {true === false ? 'Allow consumer only to return parcel same way it was delivered' : ''}
                        </Checkbox>
                        <Checkbox
                            checked={countrySettings.defaultReturnTypeToBox}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={handleCheckboxChange('defaultReturnTypeToBox')}
                        >
                            {true === false ? 'Allow consumer only to return parcel with Budbee Box' : ''}
                        </Checkbox> */}
                        {isCountryWithLeaveWithNeighbour && (
                            <Checkbox
                                checked={countrySettings.alternativelyLeaveWithNeighbour}
                                onChange={handleCheckboxChange('alternativelyLeaveWithNeighbour')}
                            >
                                Alternatively leave with neighbour as default for orders left in person
                            </Checkbox>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Consolidation support</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <ConsolidationSupportSelect
                            value={countrySettings.consolidationSupport}
                            onChange={handleSelectChange('consolidationSupport')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Default return type</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <DeliveryPreferencesSelect
                            country={countrySettings.countryCode}
                            selected={countrySettings.returnMode}
                            onChange={handleSelectChange('returnMode')}
                            optionsData={returnPreferencesTypes}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Default delivery option</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <DeliveryPreferencesSelect
                            country={countrySettings.countryCode}
                            selected={countrySettings.deliveryMode}
                            onChange={handleSelectChange('deliveryMode')}
                            optionsData={deliveryPreferencesTypes}
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }

    render() {
        const { merchant, merchantSettings } = this.props

        const countriesWithSettings = merchant.merchantSettings.map((settings) => settings.countryCode)
        const hasSettingsForCountry = (country) => countriesWithSettings.includes(country)
        const hasAvailableMarket = (country) => merchant.availableMarkets.includes(country)
        const isInNLorBEMarket = hasAvailableMarket('NL') || hasAvailableMarket('BE')
        const hasNLOrBESettings = hasSettingsForCountry('NL') || hasSettingsForCountry('BE')

        // if there is a NL or BE specific merchant settings, then manage leave with neighbour with those
        // country specific settings
        const showDefaultLeaveWithNeighbourOption = isInNLorBEMarket && !hasNLOrBESettings

        const handleCheckboxChange = this.handleSettingChange('checkbox')
        const handleInputChange = this.handleSettingChange('input')

        return (
            <div>
                <MerchantInput
                    controlId="defaultMissRetries"
                    type="number"
                    min={0}
                    step={1}
                    label="Override number of miss retries"
                    value={merchantSettings.defaultMissRetries}
                    onChange={handleInputChange('defaultMissRetries')}
                    help="Overrides the number of miss retries order setting"
                />
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={merchantSettings.selectIntervalInCheckout}
                            onChange={this.onSelectIntervalInCheckoutChange()}
                        >
                            Should Select Delivery Date in E-commerce Checkout
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.allowedToCreateDeliveryAttemptsAfterCutoff}
                            disabled={!merchantSettings.selectIntervalInCheckout}
                            onChange={handleCheckboxChange('allowedToCreateDeliveryAttemptsAfterCutoff')}
                        >
                            Allowed to Create Delivery Attempts after cutoff (will be immediately Cancelled because of
                            delay)
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.onDemandPickup}
                            onChange={handleCheckboxChange('onDemandPickup')}
                        >
                            On demand pickup service
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.allowConvertBoxToHome}
                            onChange={handleCheckboxChange('allowConvertBoxToHome')}
                        >
                            Allow conversions to home order
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.allowConsumerToChangeDate}
                            onChange={handleCheckboxChange('allowConsumerToChangeDate')}
                        >
                            Allow consumer to change date for delivery
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.allowConsumerToEditOutsideDoor}
                            onChange={handleCheckboxChange('allowConsumerToEditOutsideDoor')}
                        >
                            Allow consumer to edit outside door
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.signatureRequired}
                            onChange={handleCheckboxChange('signatureRequired')}
                        >
                            Signature required by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.identificationCheckRequired}
                            onChange={handleCheckboxChange('identificationCheckRequired')}
                        >
                            Identification check by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.offerReturns}
                            onChange={handleCheckboxChange('offerReturns')}
                        >
                            Offer Return Service
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.offerReturnFromBuyers}
                            onChange={handleCheckboxChange('offerReturnFromBuyers')}
                        >
                            Offer Return Service from Merchant Portal
                        </Checkbox>
                        <Checkbox
                            checked={Boolean(merchantSettings.hasTimeslots)}
                            onChange={handleCheckboxChange('hasTimeslots')}
                        >
                            Has timeslots
                        </Checkbox>
                        {/* TODO: Show this text when backend functionality is done */}
                        {/* <Checkbox
                            checked={merchantSettings.defaultReturnTypeToDeliveryMethod}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={handleCheckboxChange('defaultReturnTypeToDeliveryMethod')}
                        >
                            {true === false ? 'Allow consumer only to return parcel same way it was delivered' : ''}
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.defaultReturnTypeToBox}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={handleCheckboxChange('defaultReturnTypeToBox')}
                        >
                            {true === false ? 'Allow consumer only to return parcel with Budbee Box' : ''}
                        </Checkbox> */}
                        {showDefaultLeaveWithNeighbourOption && (
                            <Checkbox
                                checked={merchantSettings.alternativelyLeaveWithNeighbour}
                                onChange={handleCheckboxChange('alternativelyLeaveWithNeighbour')}
                            >
                                <b>NL & BE Only</b> - Alternatively leave with neighbour as default for orders left in
                                person
                            </Checkbox>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Consolidation support</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <ConsolidationSupportSelect
                            value={merchantSettings.consolidationSupport}
                            onChange={this.handleChangeSettings('consolidationSupport')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Default return type</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <DeliveryPreferencesSelect
                            country="general"
                            onChange={this.handleChangeSettings('returnMode')}
                            selected={merchantSettings.returnMode}
                            optionsData={returnPreferencesTypes}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={3}>
                        <ControlLabel>Default delivery option</ControlLabel>
                    </Col>
                    <Col sm={12} lg={6}>
                        <DeliveryPreferencesSelect
                            country="general"
                            onChange={this.handleChangeSettings('deliveryMode')}
                            selected={merchantSettings.deliveryMode}
                            optionsData={deliveryPreferencesTypes}
                        />
                    </Col>
                </FormGroup>
                {merchant.merchantSettings.map((countrySettings) =>
                    this.renderConfigurableSettingsBasedOnCountry(countrySettings),
                )}
            </div>
        )
    }
}

export default editMerchant(DistributionSettingsForm)

DistributionSettingsForm.propTypes = {
    allCountries: PropTypes.array,
    merchant: PropTypes.object.isRequired,
    merchantSettings: PropTypes.object.isRequired,
    changeSettings: PropTypes.func.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
    onChangeSettingsBasedOnCountry: PropTypes.func.isRequired,
    changeSettingsBasedOnCountry: PropTypes.func.isRequired,
    renderSettingsBasedOnCountriesHeader: PropTypes.func.isRequired,
}
