import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, Checkbox } from 'react-bootstrap'
import editMerchant from './edit-merchant-hoc'
import MerchantInput from './merchant-input'

class NotificationSettingsForm extends Component {
    onOrderCreatedEmailChange = (name) => (e) => {
        const { onSettingsChange, merchantSettings } = this.props
        const { value } = e.target
        const emailLinks = { ...merchantSettings.emailLinks, [name]: value }
        onSettingsChange('value', 'emailLinks')({ target: { value: emailLinks } })
    }

    render() {
        const { merchantSettings, onSettingsChange } = this.props
        return (
            <div>
                <MerchantInput
                    controlId="orderCreatedSECampaign"
                    type="url"
                    label="Order created email link SE"
                    value={merchantSettings.emailLinks.orderCreatedSECampaign || ''}
                    onChange={this.onOrderCreatedEmailChange('orderCreatedSECampaign')}
                    placeholder="http://example.com/path-to-email"
                />
                <MerchantInput
                    controlId="orderCreatedFICampaign"
                    type="url"
                    label="Order created email link FI"
                    value={merchantSettings.emailLinks.orderCreatedFICampaign || ''}
                    onChange={this.onOrderCreatedEmailChange('orderCreatedFICampaign')}
                    placeholder="http://example.com/path-to-email"
                />
                <MerchantInput
                    controlId="orderCreatedDKCampaign"
                    type="url"
                    label="Order created email link DK"
                    value={merchantSettings.emailLinks.orderCreatedDKCampaign || ''}
                    onChange={this.onOrderCreatedEmailChange('orderCreatedDKCampaign')}
                    placeholder="http://example.com/path-to-email"
                />
                <MerchantInput
                    controlId="orderCreatedNLCampaign"
                    type="url"
                    label="Order created email link NL"
                    value={merchantSettings.emailLinks.orderCreatedNLCampaign || ''}
                    onChange={this.onOrderCreatedEmailChange('orderCreatedNLCampaign')}
                    placeholder="http://example.com/path-to-email"
                />
                <MerchantInput
                    controlId="orderCreatedBECampaign"
                    type="url"
                    label="Order created email link BE"
                    value={merchantSettings.emailLinks.orderCreatedBECampaign || ''}
                    onChange={this.onOrderCreatedEmailChange('orderCreatedBECampaign')}
                    placeholder="http://example.com/path-to-email"
                />
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={merchantSettings.smsSendCollected}
                            onChange={onSettingsChange('checked', 'smsSendCollected')}
                        >
                            Notify consumer when driver has collected parcel for delivery
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.smsSendEta}
                            onChange={onSettingsChange('checked', 'smsSendEta')}
                        >
                            Notify consumer when driver is arriving shortly
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.smsSendDelivered}
                            onChange={onSettingsChange('checked', 'smsSendDelivered')}
                        >
                            Notify consumer when delivery succeeded
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.smsSendMiss}
                            onChange={onSettingsChange('checked', 'smsSendMiss')}
                        >
                            Notify consumer when delivery failed (Miss)
                        </Checkbox>
                    </Col>
                </FormGroup>
            </div>
        )
    }
}

export default editMerchant(NotificationSettingsForm)

NotificationSettingsForm.propTypes = {
    merchantSettings: PropTypes.object.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
}
