export default (id, coordinates) => ({
    id,
    type: 'heatmap',
    source: {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates,
            },
        },
    },
    paint: {
        'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 8, 15],
    },
})
