import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { isExcludedOrderSetting } from '../../../utils/order-settings'

export const deliveryPreferencesTypes = {
    LEAVE_OUTSIDE_DOOR: 'Leave outside the door',
    LEAVE_WITH_NEIGHBOUR: 'Leave with neighbour',
    KNOCK_ON_DOOR: 'Knock on door',
    RING_DOOR_BELL: 'Ring doorbell',
}

const DeliveryPreferences = (props) => {
    const { selected, stateField, country, onChange, optionsData } = props

    const options = Object.keys(optionsData)
        .filter((key) => {
            return !isExcludedOrderSetting(country, key)
        })
        .map((key) => {
            return { label: optionsData[key], value: key }
        })

    return (
        <Select
            onChange={(option) => (stateField != null ? onChange(stateField, option.value) : onChange(option.value))}
            options={options}
            value={selected}
            placeholder={optionsData[selected]}
            clearable={false}
        />
    )
}

DeliveryPreferences.propTypes = {
    selected: PropTypes.string,
    country: PropTypes.string,
    stateField: PropTypes.string,
    onChange: PropTypes.func,
    optionsData: PropTypes.object,
}

export default DeliveryPreferences
