import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getOrderComments, addOrderComment } from '../../../utils/order-webapi'
import { handleError } from '../../../utils/handle-error'
import auth from '../../../auth'
import { getCommentPermissions } from '../../../utils/comment-webapi'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../../utils/role'

/**
 * Render Comments
 */
export default class Comments extends Component {
    static propTypes = {
        order: PropTypes.object,
    }

    canCreateComment = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)

    state = {
        permissions: [],
        comment: '',
        comments: [],
    }

    componentDidMount() {
        getCommentPermissions().then((permissions) => {
            const permissionMap = {}
            permissions.forEach((permission) => {
                const name = (permission.name.split(/to/)[1] || permission.name).trim()
                permissionMap[permission.id] = {
                    name,
                    canView: true,
                }
            })
            this.setState({ permissions: permissionMap })
        })

        if (this.props.order) {
            this.getComments()
        }
    }

    onPermissionChange = (prop) => (e) => {
        const permissions = {
            ...this.state.permissions,
            [prop]: {
                canView: e.target.checked,
                name: this.state.permissions[prop].name,
            },
        }

        this.setState({ permissions })
    }

    getComments = () => {
        getOrderComments(this.props.order.token)
            .then((comments) => this.setState({ comments }))
            .catch(handleError)
    }

    submitComment = () => {
        const permissions = Object.keys(this.state.permissions).filter((key) => this.state.permissions[key].canView)

        addOrderComment(this.props.order.token, this.state.comment, permissions)
            .then(() => {
                this.getComments()
                this.setState({ comment: '' })
            })
            .catch((e) => console.error(e))
    }

    renderRow = (comment) => {
        const { deliveryAddress } = this.props.order
        const date = moment(comment.date)
            .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
            .format('YYYY-MM-DD HH:mm:ss')
        const sender = comment.endCustomer
            ? `${comment.endCustomer.name} (Consumer)`
            : `${comment.user.firstName} ${comment.user.lastName}`
        return (
            <tr key={comment.id}>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{date}</td>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{sender}</td>
                <td>{comment.message}</td>
            </tr>
        )
    }

    renderPermissions() {
        return (
            <div>
                <h5>Comment visible to:</h5>
                <ul className="list-inline">
                    {Object.entries(this.state.permissions).map(([key, permission]) => (
                        <li key={key} className="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={permission.canView}
                                    onChange={this.onPermissionChange(key)}
                                />
                                {permission.name}
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    render() {
        const { comments } = this.state

        if (comments === null) {
            return null
        }

        let commentsFrm = null
        if (this.canCreateComment) {
            commentsFrm = (
                <div>
                    <form>
                        <FormGroup>
                            <ControlLabel>New Comment</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                placeholder="Comment..."
                                value={this.state.comment}
                                onChange={(e) => this.setState({ comment: e.target.value })}
                            />
                        </FormGroup>
                        {this.renderPermissions()}
                        <Button disabled={this.state.comment === ''} onClick={this.submitComment}>
                            Submit
                        </Button>
                    </form>
                </div>
            )
        }

        if (comments.length > 0) {
            return (
                <div>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>From</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>{comments.map(this.renderRow)}</tbody>
                    </Table>
                    {commentsFrm}
                </div>
            )
        }
        return <div>{commentsFrm}</div>
    }
}
