import React, { Component } from 'react'
import { FormControl } from 'react-bootstrap'
import Coordinate from './coordinate.js'

const Status = {
    NA: 'NA',
    EDITED: 'EDITED',
    SAVED: 'SAVED',
    FAILED: 'FAILED',
}

export default class GeocodedAddress extends Component {
    constructor(props) {
        super(props)
        this.renderRow = this.renderRow.bind(this)
        this.renderInput = this.renderInput.bind(this)
        this.renderCoordinateInput = this.renderCoordinateInput.bind(this)
    }

    renderInput(field) {
        return (
            <div>
                <FormControl
                    bsSize="sm"
                    value={this.props.result.data[field]}
                    onChange={(e) => this.props.onUpdate(this.props.result.data.id, field, e.target.value)}
                />
            </div>
        )
    }

    renderCoordinateInput(coordinate) {
        const onUpdate = (axis, value) => {
            this.props.onCoordinateUpdate(this.props.result.data.id, coordinate, axis, value)
        }
        return (
            <Coordinate
                lat={this.props.result.data[coordinate].latitude}
                lng={this.props.result.data[coordinate].longitude}
                onUpdate={onUpdate}
            />
        )
    }

    renderStatus() {
        if (this.props.result.status === Status.EDITED) {
            return '*'
        }
        if (this.props.result.status === Status.SAVED) {
            return '✔'
        }
        if (this.props.result.status === Status.FAILED) {
            return '✖'
        }
        return ''
    }

    renderRow() {
        return (
            <tr>
                <td>{this.renderStatus()}</td>
                <td>{this.renderInput('street')}</td>
                <td>{this.renderInput('postalCode')}</td>
                <td>{this.renderInput('locality')}</td>
                <td>
                    <FormControl componentClass="select">
                        <option value={this.props.result.data.country}>{this.props.result.data.country}</option>
                    </FormControl>
                </td>
                <td>{this.renderCoordinateInput('coordinate')}</td>
                <td>{this.renderCoordinateInput('navigationCoordinate')}</td>
            </tr>
        )
    }

    render() {
        return this.renderRow()
    }
}
