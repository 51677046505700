import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, DropdownButton, MenuItem, Glyphicon, Button, Label, OverlayTrigger, Popover } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'
import auth from '../../../auth'
import '../../../../styles/less/popover.less'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TERMINAL_ADMIN, TRAFFIC_CONTROLLER } from '../../../utils/role'

const DATE_FORMAT = 'YYYY-MM-DD'
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
const SHORT_TIME_FORMAT = 'HH'
const TIME_FORMAT = 'HH:mm'

const routeUrl = ({ routeId }) => `/admin/routes/${routeId}`
const driverUrl = ({ driversId }) => `/admin/users/${driversId}`
const renderNoOrder = () => (
    <div>
        <p className="text-muted text-center">No order could be found</p>
    </div>
)

/**
 * Render delivery attempts for an Order
 */
export default class Consignments extends Component {
    onMissDeliveryAttempt(deliveryAttempt) {
        this.props.onMissDeliveryAttempt(deliveryAttempt)
    }

    onCancelDeliveryAttempt(deliveryAttempt) {
        this.props.onCancelDeliveryAttempt(deliveryAttempt)
    }

    onBookDeliveryAttempt = (deliveryAttempt) => this.props.onBookDeliveryAttempt(deliveryAttempt)

    onBookReturn = (deliveryAttempt) => this.props.onBookReturn(deliveryAttempt)

    onCreateDriverFeedback = (deliveryAttempt) => this.props.onCreateDriverFeedback(deliveryAttempt)

    canEditDeliveryAttempts = auth.hasAnyRole(
        OPERATIONS_ADMIN,
        OPERATIONS_COORDINATOR,
        TRAFFIC_CONTROLLER,
        TERMINAL_ADMIN,
    )

    canViewOrEditDriverFeedback = auth.hasAnyRole(TRAFFIC_CONTROLLER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    remarks({ cancellation, miss }) {
        if (cancellation == null && miss == null) {
            return ''
        }

        if (cancellation != null) {
            return this.renderMissOrCancellation('Cancelled', 'red', cancellation)
        }

        return this.renderMissOrCancellation('Missed', 'orange', miss)
    }

    datePeriod(start, stop, type) {
        const { order } = this.props
        const startTime = moment(start).tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
        const stopTime = moment(stop).tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
        const short = startTime.minute() === 0 && stopTime.minute() === 0

        const label = (() => {
            if (type === 'DELIVERY') {
                // eslint-disable-next-line jsx-a11y/label-has-for
                return <Label bsStyle="info">Delivery</Label>
            }
            if (type === 'RETURN') {
                // eslint-disable-next-line jsx-a11y/label-has-for
                return <Label bsStyle="warning">Return</Label>
            }

            return null
        })()

        function printStopDateIfDifferent() {
            if (moment(stop).format(DATE_FORMAT) === moment(start).format(DATE_FORMAT)) {
                return ''
            }
            return `${moment(stop).format(DATE_FORMAT)} `
        }

        return (
            <div>
                <div>
                    {moment(start).format(DATE_FORMAT)}{' '}
                    <strong>{startTime.format(short ? SHORT_TIME_FORMAT : TIME_FORMAT)}</strong>
                    <i> – </i>
                    {printStopDateIfDifferent()}
                    <strong>{stopTime.format(short ? SHORT_TIME_FORMAT : TIME_FORMAT)}</strong>
                </div>
                {label}
            </div>
        )
    }

    renderNoDeliveryAttempts() {
        // only delivery orders can not have a consignment. so we can default 'DELIVERY' in renderBookDeliveryButton
        return (
            <div>
                <p className="text-muted text-center">No delivery attempts</p>
                <div className="text-center">{this.renderBookDeliveryButton('DELIVERY')}</div>
            </div>
        )
    }

    renderBookDeliveryButton(consignmentToBookType) {
        if (this.props.isLockerOrder) {
            return null
        }
        return (
            <Button
                bsStyle="info"
                disabled={this.props.order.cancellation != null || this.props.crossReturnBooked}
                onClick={() =>
                    consignmentToBookType === 'DELIVERY' ? this.onBookDeliveryAttempt(null) : this.onBookReturn(null)
                }
            >
                {consignmentToBookType === 'DELIVERY' ? 'Book Delivery' : 'Book Return'}
            </Button>
        )
    }

    renderConsignmentAction(deliveryAttempt, consignmentToBookType, driversId) {
        if (!this.canEditDeliveryAttempts) {
            return null
        }

        const consignment = this.props.consignments.find((c) => c.id === deliveryAttempt.consignmentId)
        const noun = consignmentToBookType === 'RETURN' ? 'Return' : 'Delivery'
        const onBook = consignmentToBookType === 'RETURN' ? this.onBookReturn : this.onBookDeliveryAttempt
        const onDriverFeedback = this.onCreateDriverFeedback

        if (!consignment) {
            return null
        }

        return (
            <DropdownButton
                bsStyle="info"
                bsSize="small"
                title={<Glyphicon glyph="edit" />}
                id="dropdown-delivery-attempt-actions"
            >
                <MenuItem
                    eventKey="1"
                    disabled={consignment.miss != null}
                    onSelect={() => this.onMissDeliveryAttempt(consignment)}
                >
                    <Glyphicon glyph="ban-circle" /> Miss {noun}
                </MenuItem>
                <MenuItem eventKey="2" onSelect={() => this.onCancelDeliveryAttempt(consignment)}>
                    <Glyphicon glyph="remove" /> Cancel {noun}
                </MenuItem>
                <MenuItem divider />
                <MenuItem
                    eventKey="3"
                    disabled={this.props.order.cancellation != null || this.props.crossReturnBooked}
                    onSelect={() => onBook(consignment)}
                >
                    <Glyphicon glyph="calendar" /> Book new {noun}
                </MenuItem>
                <MenuItem
                    disabled={!this.canViewOrEditDriverFeedback || !driversId}
                    eventKey="4"
                    onSelect={() => onDriverFeedback(consignment)}
                >
                    <Glyphicon glyph="thumbs-down" /> Add Driver Feedback
                </MenuItem>
            </DropdownButton>
        )
    }

    renderMissOrCancellation(header, color, data) {
        const { deliveryAddress } = this.props.order
        return (
            <div style={{ paddingBottom: 10 }}>
                <b style={{ color }}>{header}</b>
                <br />
                {moment(data.date)
                    .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                    .format(DATE_TIME_FORMAT)}
                <br />
                {data.category}
                <br />
                <span style={{ color: 'grey' }}>{data.comment}</span>
                <br />- by <a href={`/admin/users/${data.userId}`}>{data.usersName}</a>
            </div>
        )
    }

    renderDriverFeedback(consignmentId) {
        if (!this.canViewOrEditDriverFeedback) {
            return null
        }

        const { orderDriverFeedback } = this.props
        const consignmentDriverFeedback = orderDriverFeedback.filter((c) => c.consignmentId === consignmentId)

        const popover = (
            <Popover id="popover-driver-feedback" className="popover">
                {consignmentDriverFeedback.map((item) => (
                    <div style={{ paddingBottom: 10 }} key={item.id}>
                        <b style={{ color: 'orange' }}>Feedback</b>
                        <span style={{ color: 'grey' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;({item.consignmentId})</span>
                        <br />
                        {moment(item.date).format(DATE_TIME_FORMAT)}
                        <br />
                        {item.category}
                        <br />
                        {item.issueType}
                        <br />
                        <span style={{ color: 'grey' }}>{item.comment}</span>
                        <br />
                        <span> - by {item.createdBy}</span>
                    </div>
                ))}
            </Popover>
        )

        if (consignmentDriverFeedback.length <= 0)
            return (
                <div>
                    <Label style={{ backgroundColor: 'lightgrey' }}>No feedback</Label>
                </div>
            )

        if (!this.canViewOrEditDriverFeedback)
            return (
                <div>
                    <OverlayTrigger
                        overlay={<Popover id="popover-no-access">No permission to view details</Popover>}
                        placement="left"
                    >
                        <Label bsStyle="warning">Has feedback</Label>
                    </OverlayTrigger>
                </div>
            )

        return (
            <div>
                <OverlayTrigger overlay={popover} placement="left">
                    <Label bsStyle="warning">Show feedback</Label>
                </OverlayTrigger>
            </div>
        )
    }

    renderConsignment(consignment) {
        const { order } = this.props
        const activeColor = '#CAE1FF'
        const style =
            consignment.miss === null && consignment.cancellation === null ? { backgroundColor: activeColor } : {}

        const consignmentWithParcels = order.consignments.find((x) => x.id === consignment.consignmentId)
        return (
            <tr style={style} key={consignment.consignmentId}>
                <td>{this.renderConsignmentAction(consignment, consignment.type, consignment.driversId)}</td>
                <td>{consignment.consignmentId}</td>
                <td>
                    {moment(consignment.createdAt)
                        .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                        .format(DATE_TIME_FORMAT)}
                </td>
                <td>
                    {moment(consignment.deadline)
                        .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                        .format(DATE_TIME_FORMAT)}
                </td>
                <td>{this.datePeriod(consignment.delivery.start, consignment.delivery.stop, consignment.type)}</td>
                <td>
                    {consignment.estimatedDelivery
                        ? moment(consignment.estimatedDelivery)
                              .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
                              .format(DATE_TIME_FORMAT)
                        : null}
                </td>
                <td>
                    {consignmentWithParcels
                        ? consignmentWithParcels.parcels.map((parcel) => (
                              <div key={parcel.packageId}>{`${parcel.packageId}\n`}</div>
                          ))
                        : null}
                </td>
                <td>
                    <a href={driverUrl(consignment)}>{consignment.driversName}</a>
                    {this.renderDriverFeedback(consignment.consignmentId)}
                </td>
                <td>
                    <a href={routeUrl(consignment)}>{consignment.routeId}</a>
                </td>
                <td>{this.remarks(consignment)}</td>
            </tr>
        )
    }

    renderConsignments() {
        return (
            <Table striped condensed>
                <thead>
                    <tr>
                        <th>Actions</th>
                        <th>ID</th>
                        <th>Booked at</th>
                        <th>Deadline for Routing</th>
                        <th>Time Window</th>
                        <th>ETA</th>
                        <th>Parcels</th>
                        <th>Driver</th>
                        <th>Route</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.deliveryInformation
                        .sort((a, b) => b.consignmentId - a.consignmentId)
                        .map((item) => this.renderConsignment(item))}
                </tbody>
            </Table>
        )
    }

    render() {
        const { consignments, order } = this.props
        if (!order) {
            return renderNoOrder()
        }

        if (consignments.length === 0) {
            return this.renderNoDeliveryAttempts()
        }

        const anyActiveConsignment = consignments.some((consignment) => consignment.cancellation == null)
        const consignmentToBookType = consignments.length > 0 ? consignments[consignments.length - 1].type : 'DELIVERY'

        return (
            <div>
                {!anyActiveConsignment ? (
                    <div>
                        <p className="text-muted text-center">No active consignment</p>
                        <div className="text-center">{this.renderBookDeliveryButton(consignmentToBookType)}</div>
                    </div>
                ) : null}
                {this.renderConsignments()}
            </div>
        )
    }
}

Consignments.propTypes = {
    deliveryInformation: PropTypes.array.isRequired,
    order: PropTypes.object.isRequired,
    consignments: PropTypes.array.isRequired,
    crossReturnBooked: PropTypes.bool.isRequired,
    onMissDeliveryAttempt: PropTypes.func.isRequired,
    onCancelDeliveryAttempt: PropTypes.func.isRequired,
    onBookDeliveryAttempt: PropTypes.func.isRequired,
    onBookReturn: PropTypes.func.isRequired,
    onCreateDriverFeedback: PropTypes.func.isRequired,
    orderDriverFeedback: PropTypes.array,
    isLockerOrder: PropTypes.bool,
}
