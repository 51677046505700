import React from 'react'
import { Col } from 'react-bootstrap'
import GeneralSettingsForm from './components/general-settings-form'

const Settings = () => (
    <Col md={10} mdOffset={1} lg={7}>
        <h2 className="mb-md">General settings</h2>
        <GeneralSettingsForm />
    </Col>
)

export default Settings
