import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel, Button } from 'react-bootstrap'
import { getOrderDeliveryPhotos } from '../../../utils/order-webapi'
import { handleError } from '../../../utils/handle-error'

const renderPhoto = (image) => <img className="img-responsive center-block" src={image.url} alt="" />

export default class Photos extends Component {
    static propTypes = {
        order: PropTypes.object,
    }

    state = {
        open: false,
        orderImages: [],
    }

    componentDidMount() {
        if (this.props.order) {
            this.updateOrderImages()
        }
    }

    updateOrderImages = () => {
        // Retrieve a list of any photos taken by the driver
        getOrderDeliveryPhotos(this.props.order.token)
            .then((orderImages) => this.setState({ orderImages }))
            .catch(handleError)
    }

    renderImagePanel(images) {
        if (!this.state.open) {
            return null
        }

        return (
            <Panel collapsible expanded={this.state.open}>
                <div>{images.map(renderPhoto)}</div>
            </Panel>
        )
    }

    render() {
        const { orderImages } = this.state

        if (orderImages == null) {
            return null
        }

        return (
            <div>
                <Button onClick={() => this.setState({ open: !this.state.open })}>
                    {this.state.open ? 'Hide Photos' : 'Show Photos'} ({orderImages.length})
                </Button>
                {this.renderImagePanel(orderImages)}
            </div>
        )
    }
}
