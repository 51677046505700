import {
    REGIONAL_SETTINGS_LOADED,
    REGIONAL_SETTINGS_FAILED_TO_LOAD,
    UI_SETTINGS_LOADED,
} from '../constants/actionTypes'
import defaultState from './initialState/regional-settings-state'

export default function RegionalSettingsReducer(state = defaultState, action) {
    switch (action.type) {
        case REGIONAL_SETTINGS_LOADED:
            return {
                ...state,
                cities: action.response.cities,
                countries: action.response.countries,
                currencies: action.response.currencies,
            }

        case REGIONAL_SETTINGS_FAILED_TO_LOAD:
            return {
                ...state,
                cities: action.response.cities,
                countries: action.response.countries,
                currencies: action.response.currencies,
            }

        case UI_SETTINGS_LOADED:
            return {
                ...state,
                cancelReasons: action.response.cancelReasons,
            }

        default:
            return state
    }
}
