import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, FormControl, Form, FormGroup, ControlLabel } from 'react-bootstrap'

/**
 * Render an End Customer
 */
export default class Consumer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editing: false,
        }
    }

    onFormChange = (e) => {
        this.props.onFormChange(e.target.name, e.target.value)
    }

    cancelEditing = () => {
        this.setState((prevState) => ({ editing: !prevState.editing }))
    }

    toggleEdit = () => {
        if (this.state.editing) {
            this.props.onConsumerSave()
        }
        this.setState((prevState) => ({ ...prevState, editing: !prevState.editing }))
    }

    value = (o) => {
        return o || <span className="text-muted">N/A</span>
    }

    renderEditInfo() {
        const { consumer } = this.props
        return (
            <Form onChange={this.onFormChange}>
                <FormGroup controlId="form">
                    <ControlLabel>Name</ControlLabel>
                    <FormControl name="name" defaultValue={consumer.name} type="text" placeholder="Name" />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl name="email" defaultValue={consumer.email} type="email" placeholder="Email" />
                </FormGroup>
            </Form>
        )
    }

    renderInfo() {
        const { consumer } = this.props
        return (
            <div>
                <div>
                    <label className="strong-label">Name</label>
                    {this.value(consumer.name)}
                </div>

                <div>
                    <label className="strong-label">Telephone Number</label>
                    <a href={`tel:${consumer.phoneNumber}`}>{this.value(consumer.phoneNumber)}</a>
                </div>

                <div>
                    <label className="strong-label">Email</label>
                    <a href={`mailto:${consumer.email}`}>{this.value(consumer.email)}</a>
                </div>
            </div>
        )
    }

    renderInformation() {
        return (
            <Row>
                <Col md={8}>{this.state.editing ? this.renderEditInfo() : this.renderInfo()}</Col>
            </Row>
        )
    }

    renderEditButton() {
        return (
            <Row style={{ marginTop: '5px' }}>
                <Col md={6}>
                    <Button bsSize="small" onClick={this.toggleEdit}>
                        {this.state.editing ? 'Done' : 'Edit'}
                    </Button>
                    {this.state.editing && (
                        <Button bsSize="small" onClick={this.cancelEditing}>
                            Cancel
                        </Button>
                    )}
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div>
                {this.renderInformation()}
                {this.renderEditButton()}
            </div>
        )
    }
}

Consumer.propTypes = {
    consumer: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    onConsumerSave: PropTypes.func.isRequired,
}
