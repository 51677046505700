import {
    RECURRING_PICKUP_LOADED,
    RECURRING_PICKUPS_LOADED,
    FETCHING_RECURRING_PICKUPS,
    RECURRING_PICKUP_LOCATIONS_LOADED,
    RECURRING_PICKUP_DELETED,
    FETCHING_MERCHANT_HOLIDAYS,
    MERCHANT_HOLIDAYS_LOADED,
    RECURRING_PICKUP_HISTORY_FETCHED,
    RECURRING_PICKUP_LOCATIONS_MULTIPLE_LOADED,
    RECURRING_PICKUP_COLLECTION_POINT_SCHEDULES_LOADED,
} from '../constants/actionTypes'
import defaultState from './initialState/recurring-pickups'
import { concatObjectSortAndRemoveDuplicates } from '../utils/recurring-pickups'

export default function recurringPickupsReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCHING_RECURRING_PICKUPS:
            return {
                ...state,
                loaded: false,
                data: [],
                selected: {},
                locations: [],
                multipleLocations: {},
                multipleLocationsOptions: [],
            }

        case RECURRING_PICKUPS_LOADED:
            return {
                ...state,
                loaded: true,
                data: action.data,
            }

        case RECURRING_PICKUP_LOADED:
            return {
                ...state,
                loaded: true,
                selected: action.data,
            }

        case RECURRING_PICKUP_LOCATIONS_LOADED:
            return {
                ...state,
                locations: action.data,
            }

        case RECURRING_PICKUP_LOCATIONS_MULTIPLE_LOADED:
            return {
                ...state,
                multipleLocations: action.data,
            }

        case RECURRING_PICKUP_COLLECTION_POINT_SCHEDULES_LOADED:
            return {
                ...state,
                multipleLocationsOptions: concatObjectSortAndRemoveDuplicates(action.data)
            }

        case RECURRING_PICKUP_DELETED:
            return {
                ...state,
                data: state.data.filter(({ id }) => id !== action.id),
            }

        case FETCHING_MERCHANT_HOLIDAYS:
            return {
                ...state,
                loaded: false,
                data: [],
            }

        case MERCHANT_HOLIDAYS_LOADED:
            return {
                ...state,
                loaded: true,
                merchantHolidays: action.data,
            }

        case RECURRING_PICKUP_HISTORY_FETCHED:
            return {
                ...state,
                recurringPickupHistory: action.data,
            }

        default:
            return state
    }
}
