import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Alert, Grid, Row, Col } from 'react-bootstrap'
import Loader from 'react-loader'
import HtmlTitle from '../html-title'
import Form from './form'
import * as recurringPickupsActions from '../../actions/recurring-pickups'
import * as ownerActions from '../../actions/owner'
import * as terminalActions from '../../actions/terminals'
import * as driverActions from '../../actions/user'
import auth from '../../auth'
import { matchPickups, STOP_TYPE, validate, checkIfMerchantRoute } from '../../utils/recurring-pickups'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

const initialPickups = () => []

class RecurringPickupCopyHandler extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            initialModel: {
                name: '',
                nameOriginal: '',
                pickups: initialPickups(),
                dayOfWeek: '-1',
                courierId: '-1',
                driverId: '-1',
                remuneration: '',
                currency: 'SEK',
                maxPallets: '',
                vehicleType: '',
                vehicleTypeFreeText: '',
                vehicleUtilisationFactor: 100,
                linehaulCost: null,
                requiredPallets: null,
            },
            isReadOnly: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        this.props.recurringPickupsActions.fetchRecurringPickup(this.props.params.id)
        if (this.props.location.query.cps != null) {
            const selectedDaysKey = this.props.location.query.cps.split(',')
            selectedDaysKey
                .filter((s) => !Number.isNaN(Number(s)))
                .map((id) => this.props.recurringPickupsActions.fetchRecurringPickup(id))

            const daysOfWeek = selectedDaysKey.filter((s) => Number.isNaN(Number(s)))
            this.props.recurringPickupsActions.fetchRecurringPickupCollectionMultiplePoints(daysOfWeek)
        }

        this.props.ownerActions.fetchOwnersWebapi()
        this.props.terminalActions.fetchTerminalsWebapi()
    }

    componentWillReceiveProps(nextProps) {
        this.setState((state) => ({
            initialModel: {
                ...state.initialModel,
                id: nextProps.pickup.id,
                name: `${nextProps.pickup.name} (COPY)`,
                nameOriginal: nextProps.pickup.name,
                dayOfWeek: nextProps.pickup.dayOfWeek != null ? nextProps.pickup.dayOfWeek.toUpperCase() : '-1',
                isOneTimeRoute: false,
                courierId: '-1',
                driverId: '-1',
                maxPallets: nextProps.pickup.maxPallets,
                vehicleType: nextProps.pickup.vehicleType,
                vehicleTypeFreeText: nextProps.pickup.vehicleTypeFreeText,
                vehicleUtilisationFactor: parseInt(nextProps.pickup.vehicleUtilisationFactor * 100),
                remuneration: '',
                linehaulCost: null,
                currency: nextProps.pickup.currency,
                pickups:
                    nextProps.pickup.stops != null
                        ? nextProps.pickup.stops.map((stop, sortOrder) => {
                              switch (stop.type) {
                                  case STOP_TYPE.MERCHANT:
                                      return {
                                          ...stop,
                                          id: sortOrder,
                                          merchantScheduleId: stop.collectionPointMerchantScheduleId,
                                      }
                                  default:
                                      return {
                                          ...stop,
                                          id: sortOrder,
                                      }
                              }
                          })
                        : [],
            },
            isReadOnly: state.isReadOnly || Boolean(nextProps.pickup.instaboxId),
        }))
    }

    onCourierChange = (courierId) => {
        this.props.driverActions.getCourierUsers(courierId)
    }

    onSubmit = (form, e) => {
        e.preventDefault()

        form.days.map((day) => this.submitRoute(day, form))
    }

    submitRoute(day, form) {
        const newPickups = matchPickups(day, form, form.collectionPoints)

        if (day.selected) {
            const stops = form.pickups.map((pickup, sortOrder) => {
                switch (pickup.type) {
                    case STOP_TYPE.MERCHANT:
                        return {
                            type: pickup.type,
                            merchantScheduleId: newPickups.find(
                                (p) =>
                                    pickup.merchantName === p.buyerName &&
                                    pickup.collectionPointName === p.collectionPointName &&
                                    pickup.startTime === p.startTime &&
                                    pickup.endTime === p.endTime,
                            ).id,
                            returns: pickup.returns,
                            pickup: pickup.pickup,
                            sortOrder,
                        }
                    case STOP_TYPE.TERMINAL:
                        return {
                            type: pickup.type,
                            terminalId: pickup.terminalId,
                            startTime: pickup.startTime,
                            endTime: pickup.endTime,
                            sortOrder,
                        }
                    default:
                        return {
                            ...pickup,
                            sortOrder,
                        }
                }
            })
            checkIfMerchantRoute(form.pickups) ? null : form.linehaulCost = null
            const model = {
                name: form.name,
                dayOfWeek: day.enum,
                courierId: form.courierId,
                driverId: form.driverId === '-1' ? null : form.driverId,
                remuneration: form.remuneration,
                currency: form.currency,
                pallets: form.maxPallets,
                vehicleType: form.vehicleType,
                vehicleUtilisationFactor: form.vehicleUtilisationFactor / 100,
                linehaulCost: form.linehaulCost,
                stops,
            }

            this.props.recurringPickupsActions.createRecurringPickup(model, this.props.router)
        }
    }

    render() {
        const { fetchRecurringPickupCollectionPoints, fetchRecurringPickupCollectionPointSchedules } = this.props.recurringPickupsActions
        const { isReadOnly } = this.state
        return (
            <div>
                <HtmlTitle title="Recurring pickups" />

                <Loader color="#bfbfbf" loaded={this.props.loaded}>
                    <Grid fluid>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                {isReadOnly ? (
                                    <Alert bsStyle="danger">
                                        <span>Cannot copy routes managed through Orderadmin.</span>
                                    </Alert>
                                ) : (
                                    <h1>Copying route: [{this.state.initialModel.nameOriginal}]</h1>
                                )}
                            </Col>
                        </Row>
                        {!isReadOnly && (
                            <Form
                                onSubmit={this.onSubmit}
                                onCourierChange={this.onCourierChange}
                                initialModel={this.state.initialModel}
                                owners={this.props.owners}
                                drivers={this.props.drivers}
                                terminals={this.props.terminals}
                                locations={this.props.locations}
                                validate={validate}
                                fetchRecurringPickupCollectionPoints={fetchRecurringPickupCollectionPoints}
                                fetchRecurringPickupCollectionPointSchedules={
                                    fetchRecurringPickupCollectionPointSchedules
                                }
                                initialPickups={initialPickups}
                                onDelete={this.onDelete}
                                query={this.props.location.query.cps}
                                multipleLocations={this.props.multipleLocations}
                                multipleLocationsOptions={this.props.multipleLocationsOptions}
                                showSubmitButtonCopy
                                checkIfMerchantRoute={checkIfMerchantRoute}
                            />
                        )}
                    </Grid>
                </Loader>
            </div>
        )
    }
}

RecurringPickupCopyHandler.propTypes = {
    router: PropTypes.object,
    params: PropTypes.object,
    recurringPickupsActions: PropTypes.object,
    ownerActions: PropTypes.object,
    terminalActions: PropTypes.object,
    driverActions: PropTypes.object,
    pickup: PropTypes.object,
    loaded: PropTypes.bool,
    owners: PropTypes.array,
    terminals: PropTypes.array,
    locations: PropTypes.array,
    drivers: PropTypes.array,
    location: PropTypes.object,
    query: PropTypes.array,
    multipleLocations: PropTypes.object,
    multipleLocationsOptions: PropTypes.array,
}

const mapStateToProps = ({
    owners,
    terminals,
    users,
    recurringPickups: { loaded, locations, selected: pickup, multipleLocations, multipleLocationsOptions },
}) => ({
    loaded,
    pickup,
    owners: owners.data,
    terminals: terminals.terminals,
    drivers: users.data,
    locations,
    multipleLocations,
    multipleLocationsOptions,
})

const mapDispatchToProps = (dispatch) => ({
    recurringPickupsActions: bindActionCreators(recurringPickupsActions, dispatch),
    driverActions: bindActionCreators(driverActions, dispatch),
    ownerActions: bindActionCreators(ownerActions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPickupCopyHandler)
