import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Button } from 'react-bootstrap'
import { getLockers } from '../../utils/lockers-webapi'
import { createStandaloneReturn } from '../../utils/order-webapi'

const PARTY_CONSUMER='CONSUMER'

export default class StandaloneReturnModal extends Component {
    constructor(...args) {
        super(...args)
        const [props] = args
        this.state = {
            order: props.order,
            lockers: [],
            returnLocker: '',
            onHide: props.onHide
        }
    }

    componentDidMount() {
        this.fetchLockers()
    }

    fetchLockers() {
        getLockers(this.state.order.deliveryAddress.countryCode, this.state.order.deliveryAddress.postalCode)
            .then((lockers) => {
                this.setState({ lockers: lockers.lockers })
            })
            .catch((e) => {
                console.error(e)
            })
    }

    createStandaloneReturn = () => {
        const returnRequest = {
            party: PARTY_CONSUMER,
            identifier: this.state.returnLocker,
            size: "M", // TODO: How to set a proper size for this parcel?
        }
        createStandaloneReturn(this.state.order.token, returnRequest)
        .then((_) => {
            this.props.onHide()
        })
        .catch((e) => { 
            console.error(e)
        })
    }

    onParcelStatusChange(e) {
        const lockerRef = e.target.value 
        this.setState({ returnLocker: lockerRef })
    }

    renderStatusSelect() {
        return (
            <select
                className="form-control"
                id="lockers"
                onChange={ (e) => this.onParcelStatusChange(e) }
            >
                <option value={0}>Select Locker</option>
                {this.state.lockers.map((locker) => {
                    return (
                        <option key={locker.lockerIdentifier} value={locker.lockerIdentifier}>
                            {locker.lockerAddress.name}
                        </option>
                    )
                })}
            </select>
        )
    }

    renderLockers() {
        return (
            <tr key={this.state.order.id}>
                <td>{this.state.order.token}</td>
                <td>{this.renderStatusSelect(this.state.lockers)}</td>
            </tr>
        )
    }

    renderBody() {
        return (
            <Modal.Body>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Order</th>
                        <th>Locker for return dropoff</th>
                    </tr>
                </thead>
                <tbody>{this.renderLockers(this.state.lockers)}</tbody>
            </Table>
        </Modal.Body>
        )
    }

    renderFooter() {
        return (
            <Modal.Footer>
                <Button onClick={this.state.onHide}>Cancel</Button>
                <Button bsStyle="primary" onClick={this.createStandaloneReturn}>
                    Create return
                </Button>
            </Modal.Footer>
        )
    }
    
    render() {
        return (
            <Modal show={this.props.show} title="Parcel Status" onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Create standalone return</Modal.Title>
                </Modal.Header>
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}

StandaloneReturnModal.propTypes = {
    order: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
}
