import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.ratings-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('ratings')

export const setRatingCategory = (rating, category) =>
    webapi()
        .path(rating)
        .path('category')
        .put(category)

export const invalidateRating = (rating) =>
    webapi()
        .path(rating)
        .path('invalidate')
        .post()

export const lockerBoxRating = (orderId) =>
    webapi()
        .accept('application/vnd.budbee.ratings-v1+json')
        .contentType('application/vnd.budbee.ratings-v1+json')
        .path(orderId)
        .path('locker-box-order')
        .get()
