import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Col,
    FormGroup,
    ControlLabel,
    Form as BsForm,
    Button,
    Alert,
    Glyphicon,
    InputGroup,
    Row,
} from 'react-bootstrap'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DatePicker from 'react-datepicker'
import * as actions from '../../actions/owner'
import currencies from '../../utils/currencies'
import PricePointList from './components/price-point/price-point-list'
import mapType from '../../utils/map-price-point-type'

const pricePointTypes = [
    {
        label: mapType.toText('PRICE_PER_STOP'),
        value: 'PRICE_PER_STOP',
    },
    {
        label: mapType.toText('PRICE_PER_MISS'),
        value: 'PRICE_PER_MISS',
    },
    {
        label: mapType.toText('PRICE_PER_HOUR'),
        value: 'PRICE_PER_HOUR',
    },
    {
        label: mapType.toText('PRICE_PER_CANCELLATION_PRE_CUTOFF'),
        value: 'PRICE_PER_CANCELLATION_PRE_CUTOFF',
    },
    {
        label: mapType.toText('PRICE_PER_CANCELLATION_POST_CUTOFF'),
        value: 'PRICE_PER_CANCELLATION_POST_CUTOFF',
    },
    {
        label: mapType.toText('START_DEBIT_PER_DAY'),
        value: 'START_DEBIT_PER_DAY',
    },
    {
        label: mapType.toText('START_DEBIT_PER_ROUTE'),
        value: 'START_DEBIT_PER_ROUTE',
    },
    {
        label: mapType.toText('MINIMUM_DEBIT_PER_DAY'),
        value: 'MINIMUM_DEBIT_PER_DAY',
    },
    {
        label: mapType.toText('MINIMUM_DEBIT_PER_ROUTE'),
        value: 'MINIMUM_DEBIT_PER_ROUTE',
    },
    {
        label: mapType.toText('RATING_INCENTIVE'),
        value: 'RATING_INCENTIVE',
    },
]

const currencyOptions = currencies()

class PricePoints extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: false,
            price: false,
            currency: 'SEK',
            types: [],
            validFrom: null,
            validTo: null,
            invalid: {
                price: null,
                types: null,
            },
        }
    }

    componentWillMount() {
        this.initialState = this.state
    }

    componentDidMount() {
        this.fetchPricePoints()
    }

    onError = (error) => {
        this.setState({ error: error.status })
    }

    onUpdate = () => {
        this.setState({ ...this.initialState })
    }

    changePrice = (event) => {
        this.setState({ price: event.target.value })
    }

    changePriceType = (types) => {
        this.setState({ types: types.map((t) => t.value) })
    }

    changeFromDate = (validFrom) => {
        this.setState({ validFrom })
    }

    changeToDate = (validTo) => {
        this.setState({ validTo })
    }

    fetchPricePoints = () => {
        this.props.actions.getPricePointListWebapi(this.props.params.id)
    }

    deletePricePoints = (pricePointIds) => {
        this.props.actions.removePricePointsWebapi(this.props.params.id, pricePointIds)
    }

    savePricePoints = () => {
        this.setState({ error: false })
        if (this.validate()) {
            const pricePoints = {
                types: this.state.types,
                currency: this.state.currency,
                amount: Number(this.state.price).toFixed(2) * 100,
                validFrom: this.state.validFrom,
                validTo: this.state.validTo,
            }

            this.props.actions
                .createPricePointsWebapi(this.props.params.id, pricePoints)
                .then(this.onUpdate)
                .catch(this.onError)
        }
    }

    validate() {
        this.setState((prevState) => ({
            invalid: {
                price: !prevState.price ? 'error' : 'success',
                types: prevState.types.length < 1 ? 'error' : 'success',
            },
        }))

        if (this.state.price && this.state.types.length > 0) {
            return true
        }

        return false
    }

    render() {
        const { courier, priceList, loading } = this.props
        const { invalid, types, currency, error, validFrom, validTo } = this.state

        if (loading) {
            return null
        }

        return (
            <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
                <BsForm horizontal>
                    <FormGroup validationState={invalid.types}>
                        <Col componentClass={ControlLabel} sm={3}>
                            Price Type
                        </Col>
                        <Col sm={9}>
                            <Select
                                style={{ zIndex: 1 }}
                                multi
                                value={types}
                                options={pricePointTypes}
                                onChange={this.changePriceType}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                            Currency
                        </Col>
                        <Col sm={9}>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={currencyOptions}
                                value={currency}
                                onChange={(item) => this.setState({ currency: item.value })}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup validationState={invalid.price}>
                        <Col componentClass={ControlLabel} sm={3}>
                            Price
                        </Col>
                        <Col sm={9}>
                            <InputGroup>
                                <div className="input-group-btn">
                                    <button type="button" className="btn btn-default" disabled>
                                        {currency}
                                    </button>
                                </div>
                                <input
                                    type="Number"
                                    style={{ zIndex: 0 }}
                                    step="0,01"
                                    name="amount"
                                    placeholder="56,00"
                                    className="form-control"
                                    onChange={this.changePrice}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                            Date Range
                        </Col>
                        <Col sm={9}>
                            <Row>
                                <Col sm={6}>
                                    <DatePicker
                                        className="form-control"
                                        selected={validFrom}
                                        onChange={this.changeFromDate}
                                        placeholderText="Valid from"
                                    />
                                </Col>
                                <Col sm={6}>
                                    <DatePicker
                                        className="form-control"
                                        selected={validTo}
                                        onChange={this.changeToDate}
                                        placeholderText="Valid to"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={9} smOffset={3}>
                            {error === 400 && (
                                <Alert bsStyle="warning">
                                    <Glyphicon glyph="thumbs-down" /> Check your data and try again
                                </Alert>
                            )}
                            {error === 500 && (
                                <Alert bsStyle="danger">
                                    <Glyphicon glyph="thumbs-down" /> Oops, something went wrong!
                                </Alert>
                            )}
                            <Button bsStyle="primary" onClick={this.savePricePoints}>
                                Save price point
                            </Button>
                        </Col>
                    </FormGroup>
                </BsForm>
                <PricePointList courier={courier} list={priceList} deletePricePoints={this.deletePricePoints} />
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({
    courier: state.couriers.courier,
    loading: state.owners.loading,
    priceList: state.owners.priceList,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PricePoints)

PricePoints.propTypes = {
    params: PropTypes.object.isRequired,
    actions: PropTypes.object,
    priceList: PropTypes.array,
    courier: PropTypes.object,
    loading: PropTypes.bool,
}
