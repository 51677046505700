import trim from './trim'
import auth from '../auth'

class Api {
    constructor(url) {
        if (url == null) {
            throw new Error('URL cannot be null')
        }

        // eslint-disable-next-line no-undef
        this.url = trim(__webpack_public_path__, '/') + url
        this.headers = {}
        this.urlParts = []
        this.queryParams = {}
    }

    path(part) {
        this.urlParts.push(part)
        return this
    }

    queryParam(key, val) {
        if (this.queryParams[key] == null) {
            this.queryParams[key] = []
        }
        this.queryParams[key].push(val)

        return this
    }

    header(header, value) {
        if (this.headers[header] == null) {
            this.headers[header] = []
        }
        this.headers[header].push(value)

        return this
    }

    accept(value) {
        return this.header('Accept', value)
    }

    type(value) {
        return this.header('Content-Type', value)
    }

    get() {
        return this.request('get')
    }

    post(data, isJson) {
        return this.request('post', data, isJson)
    }

    put(data, isJson) {
        return this.request('put', data, isJson)
    }

    delete(data) {
        return this.request('delete', data)
    }

    request(method, data, isJson = true) {
        return fetch(this.getUrl(), {
            method,
            headers: this.getHeaders(),
            body: (() => {
                if (data != null) {
                    if (isJson) {
                        return JSON.stringify(data)
                    }

                    return data
                }

                return undefined // FF treats `null` as body
            })(),
        })
            .then(Api.status)
            .then(Api.parse)
    }

    getUrl() {
        const parts = this.urlParts
            .filter((part) => part != null)
            .map((part) => part.toString())
            .map((part) => trim(part, '/'))
            .join('/')

        const query = Object.keys(this.queryParams)
            .reduce((q, key) => {
                const values = this.queryParams[key]
                return [...q, ...values.map((v) => `${encodeURIComponent(key)}=${v ? encodeURIComponent(v) : ''}`)]
            }, [])
            .join('&')

        return trim(`${this.url}/${parts}?${query}`, '/?')
    }

    getHeaders() {
        const headers = {}
        Object.getOwnPropertyNames(this.headers).forEach((header) => {
            headers[header] = this.headers[header].join(' ')
        })

        return headers
    }

    static status(res) {
        if (res.status >= 200 && res.status < 300) {
            return Promise.resolve(res)
        }
        if (res.status === 401) {
            const requestPage = window.location.pathname
            if (requestPage !== '/login' && requestPage !== '/logout' && requestPage !== '/login/session') {
                auth.setRequestedPage(requestPage)
            }

            auth.clear()
            window.router.push('/login')
            return Promise.reject(res)
        }
        return Promise.reject(res)
    }

    static parse(res) {
        return res.json().catch(() => Promise.resolve(res))
    }
}

export default Api
