import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import Loader from 'react-loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import HtmlTitle from '../html-title'
import '../../../styles/less/zoneManagement.less'
import { mapSort, sortStrings } from '../../utils/sorting'
import { prop } from '../../utils/prop'
import { OPERATIONS_COORDINATOR } from '../../utils/role'

import * as actions from '../../actions/zoneManagement'
import * as terminalActions from '../../actions/terminals'
import auth from '../../auth'

class ZoneManagementViewHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            terminalName: '',
            environmentalPostalCodes: [],
            nonEnvironmentalPostalCodes: [],
        }
    }

    componentDidMount() {
        const { terminalCode } = this.props.params

        if (!auth.hasAnyRole(OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin')
            return
        }

        this.props.terminalActions.fetchTerminalsWebapi().then(() => {
            const { terminals } = this.props
            this.setState({ terminalName: terminals.find((x) => x.code === terminalCode).name })
        })

        this.props.actions
            .fetchPostalCodeZones(this.props.params.groupId)
            .then(() =>
                this.setState({
                    nonEnvironmentalPostalCodes: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).postalCodes
                        .filter((x) => x.fossilFree === false)
                        .sort(mapSort(sortStrings, prop('postalCode'))),
                    environmentalPostalCodes: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).postalCodes
                        .filter((x) => x.fossilFree === true)
                        .sort(mapSort(sortStrings, prop('postalCode'))),
                }),
            )
            .finally(() => this.setState({ loading: false }))
    }

    onCancel = () => this.transition()

    transition = () => {
        const { type, groupName, groupId } = this.props.params
        this.props.router.push(`/admin/zone-management/${type}/${groupName}/${groupId}/view`)
    }

    render() {
        const { type } = this.props.params
        const { terminalName, environmentalPostalCodes, nonEnvironmentalPostalCodes } = this.state

        let nonEnvironmentalPostalCodesString = ''
        let environmentalPostalCodesString = ''

        nonEnvironmentalPostalCodes.forEach((element) => {
            nonEnvironmentalPostalCodesString = nonEnvironmentalPostalCodesString.concat('\n', element.postalCode)
        })
        environmentalPostalCodes.forEach((element) => {
            environmentalPostalCodesString = environmentalPostalCodesString.concat('\n', element.postalCode)
        })

        return (
            <div>
                <HtmlTitle title="View zone" />
                <Loader color="#bfbfbf" loaded={!this.state.loading}>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Col md={12}>
                                <FormGroup>
                                    <h1>{`View ${terminalName} ${type} Zone`}</h1>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Terminal</ControlLabel>
                                    <FormControl name="name" value={terminalName} readOnly />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Environmental postal codes</ControlLabel>
                                    <FormControl
                                        className="postal-code-textarea"
                                        componentClass="textarea"
                                        readOnly
                                        value={environmentalPostalCodesString.trim()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Non-environmental postal codes</ControlLabel>
                                    <FormControl
                                        className="postal-code-textarea"
                                        componentClass="textarea"
                                        readOnly
                                        value={nonEnvironmentalPostalCodesString.trim()}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3} mdOffset={9}>
                                <FormGroup>
                                    <Button
                                        className="pull-right"
                                        type="button"
                                        bsStyle="default"
                                        onClick={this.onCancel}
                                    >
                                        Cancel
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = ({ zoneManagement, terminals }) => ({ zoneManagement, terminals: terminals.terminals })

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagementViewHandler)

ZoneManagementViewHandler.propTypes = {
    router: PropTypes.object,
    actions: PropTypes.object,
    zoneManagement: PropTypes.object,
    params: PropTypes.object,
    terminals: PropTypes.array,
    terminalActions: PropTypes.object,
}
