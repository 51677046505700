export const DATE = 'DATE'
export const DISTRIBUTION_ROUTE = 'DISTRIBUTION_ROUTE'
export const LOCKER = 'LOCKER'
export const LOCKER_LINEHAUL = 'LOCKER_LINEHAUL'
export const LOCKER_STOP = 'LOCKER_STOP'
export const MERCHANT_PRIORITY = 'MERCHANT_PRIORITY'
export const OVERSIZE_LOCKER_PARCEL = 'OVERSIZE_LOCKER_PARCEL'
export const READ_FAIL = 'READ_FAIL'
export const RETURN_LINEHAUL = 'RETURN_LINEHAUL'
export const TEMPORAL = 'TEMPORAL'
export const LINEHAUL = 'LINEHAUL'
export const RETURN_TO_MERCHANT = 'RETURN_TO_MERCHANT'
export const LINEHAUL_HUB_OR_REGION = 'LINEHAUL_HUB_OR_REGION'
export const ON_DEMAND_ROUTE = 'ON_DEMAND_ROUTE'
