import React from 'react'

import auth from '../auth'
import { handleError } from '../utils/handle-error'
import { findGroundOpsAdminUrlFromHostname } from '../utils/service-url'

const rootElementId = 'ground-ops-admin-root'
const basename = '/admin/ground-operations'
const assetManifestPath = '/asset-manifest.json'

const fetchAssetManifest = (baseUrl) => fetch(`${baseUrl}${assetManifestPath}`).then((res) => res.json())

class GroundOperationsIndex extends React.Component {
    baseUrl = findGroundOpsAdminUrlFromHostname()

    script = null

    styleSheet = null

    groundOpsAdminClient = null

    componentDidMount = () => {
        fetchAssetManifest(this.baseUrl)
            .then((assetManifest) => Promise.all([this.loadScript(assetManifest), this.loadStyleSheet(assetManifest)]))
            .then(this.initializeGroundOpsAdminClient)
            .catch(handleError)
    }

    componentWillUnmount = () => {
        this.destroyGroundOpsAdminClient()
    }

    loadScript = (assetManifest) =>
        new Promise((resolve, reject) => {
            this.script = document.createElement('script')
            this.script.onload = resolve
            this.script.onerror = reject
            this.script.async = true
            this.script.setAttribute('data-is-micro-frontend', true)
            this.script.src = `${this.baseUrl}${assetManifest.files['main.js']}`
            document.head.appendChild(this.script)
        })

    loadStyleSheet = (assetManifest) =>
        new Promise((resolve, reject) => {
            const styleSheetPath = assetManifest.files['main.css']
            if (styleSheetPath) {
                this.styleSheet = document.createElement('link')
                this.styleSheet.onload = resolve
                this.styleSheet.onerror = reject
                this.styleSheet.setAttribute('rel', 'stylesheet')
                this.styleSheet.setAttribute('type', 'text/css')
                this.styleSheet.setAttribute('href', `${this.baseUrl}${styleSheetPath}`)
                document.head.appendChild(this.styleSheet)
            } else {
                resolve()
            }
        })

    initializeGroundOpsAdminClient = () => {
        if (!window.groundOpsAdmin || !window.groundOpsAdmin.GroundOpsAdminClient) {
            throw new Error('Unable to load Ground Ops Admin')
        }

        const { baseUrl } = this
        const authHeader = auth.get()
        const tokenStartIndex = 7
        const webapiJWT = authHeader.substring(tokenStartIndex)

        this.groundOpsAdminClient = new window.groundOpsAdmin.GroundOpsAdminClient()
        this.groundOpsAdminClient.initialize({
            rootElementId,
            basename,
            webapiJWT,
            baseUrl,
        })
    }

    destroyGroundOpsAdminClient = () => {
        if (this.script) {
            document.head.removeChild(this.script)
        }
        if (this.styleSheet) {
            document.head.removeChild(this.styleSheet)
        }
        if (this.groundOpsAdminClient) {
            this.groundOpsAdminClient.destroy()
        }
    }

    render = () => <div id={rootElementId} />
}

export default GroundOperationsIndex
