import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, FormControl, ControlLabel, Form as BsForm, Button, Checkbox } from 'react-bootstrap'
import Select from 'react-select'
import HtmlTitle from '../html-title'
import BillingPeriods from '../../utils/billing-periods'
import DueDaysInvoices from '../../utils/due-days-invoices'
import isEmpty from '../../utils/isEmptyValue'

const billingPeriods = BillingPeriods()
const dueDaysInvoices = DueDaysInvoices()

export default class Form extends Component {
    onChange = (prop, name) => (e) =>
        this.props.onChange({
            ...this.props.courier,
            [name]: e.target[prop],
        })

    render() {
        return (
            <div>
                <HtmlTitle title={this.props.courier.name} />
                <BsForm horizontal onSubmit={this.props.onSubmit}>
                    <h2>General</h2>
                    <hr />
                    <FormGroup controlId="name" validationState={!isEmpty(this.props.courier.name) ? null : 'error'}>
                        <Col sm={3} componentClass={ControlLabel}>
                            Name
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                required
                                value={this.props.courier.name}
                                onChange={this.onChange('value', 'name')}
                                placeholder="Courier name"
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        controlId="operatingCountries"
                        validationState={this.props.courier.countries.length > 0 ? null : 'error'}
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            Operating in
                        </Col>
                        <Col sm={9}>
                            <Select
                                required
                                placeholder="Select countries..."
                                value={this.props.courier.countries}
                                options={this.props.countries.map((country) => ({
                                    value: country.id,
                                    label: country.label,
                                }))}
                                onChange={(options) => this.props.onCountriesChange(options)}
                                allowCreate={false}
                                ignoreCase
                                multi
                            />
                        </Col>
                    </FormGroup>
                    <h2>Invoice</h2>
                    <hr />
                    <FormGroup
                        controlId="companyLegalName"
                        validationState={!isEmpty(this.props.courier.courierSettings.companyLegalName) ? null : 'error'}
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            Company legal name
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                placeholder="Company name displayed on invoice"
                                value={this.props.courier.courierSettings.companyLegalName}
                                onChange={this.props.onSettingsChange('value', 'companyLegalName')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="selfBillRecipientOverride">
                        <Col sm={3} componentClass={ControlLabel}>
                            Override self-bill recipient to (Netsuite)
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="number"
                                placeholder="Carriers ID for courier to receive self-bills"
                                value={this.props.courier.courierSettings.selfBillRecipientOverride}
                                onChange={this.props.onSettingsChange('value', 'selfBillRecipientOverride')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        controlId="companyRegistrationNumber"
                        validationState={
                            !isEmpty(this.props.courier.courierSettings.companyRegistrationNumber) ? null : 'error'
                        }
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            Company Registration number
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                required
                                type="text"
                                placeholder="Org nr"
                                value={this.props.courier.courierSettings.companyRegistrationNumber}
                                onChange={this.props.onSettingsChange('value', 'companyRegistrationNumber')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        controlId="vatNumber"
                        validationState={!isEmpty(this.props.courier.courierSettings.vatNumber) ? null : 'error'}
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            VAT Number
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                required
                                value={this.props.courier.courierSettings.vatNumber}
                                onChange={this.props.onSettingsChange('value', 'vatNumber')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="billingPeriod">
                        <Col componentClass={ControlLabel} sm={3}>
                            Billing period
                        </Col>
                        <Col sm={9}>
                            <Select
                                value={this.props.courier.courierSettings.billingPeriod}
                                options={billingPeriods}
                                onChange={this.props.onSettingsChangeFromList('billingPeriod')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="dueDaysInvoice">
                        <Col componentClass={ControlLabel} sm={3}>
                            Due days invoice
                        </Col>
                        <Col sm={9}>
                            <Select
                                value={this.props.courier.courierSettings.dueDaysInvoice}
                                options={dueDaysInvoices}
                                onChange={this.props.onSettingsChangeFromList('dueDaysInvoice')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup
                        controlId="invoiceEmail"
                        validationState={!isEmpty(this.props.courier.courierSettings.invoiceEmail) ? null : 'error'}
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            Invoice email
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                required
                                type="email"
                                placeholder="invoice email"
                                value={this.props.courier.courierSettings.invoiceEmail}
                                onChange={this.props.onSettingsChange('value', 'invoiceEmail')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="invoiceEmailCopy">
                        <Col sm={3} componentClass={ControlLabel}>
                            Invoice email CC
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="email"
                                placeholder="invoice email copy"
                                value={this.props.courier.courierSettings.invoiceEmailCopy}
                                onChange={this.props.onSettingsChange('value', 'invoiceEmailCopy')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="checkboxes">
                        <Col sm={9} smOffset={3}>
                            <Checkbox
                                checked={this.props.courier.courierSettings.terminalEndStopAlways}
                                onChange={this.props.onSettingsChange('checked', 'terminalEndStopAlways')}
                            >
                                Requires a final terminal stop in all routes
                            </Checkbox>
                            <Checkbox
                                checked={this.props.courier.courierSettings.vatOnInvoice}
                                onChange={this.props.onSettingsChange('checked', 'vatOnInvoice')}
                            >
                                VAT on invoice
                            </Checkbox>
                            <Checkbox
                                checked={this.props.courier.courierSettings.combinedInvoicing}
                                onChange={this.props.onSettingsChange('checked', 'combinedInvoicing')}
                            >
                                Combined invoicing with parent
                            </Checkbox>
                        </Col>
                    </FormGroup>
                    <h4>Company address</h4>
                    <hr />
                    <FormGroup
                        controlId="companyCountry"
                        validationState={!isEmpty(this.props.courier.courierSettings.companyCountry) ? null : 'error'}
                    >
                        <Col sm={3} componentClass={ControlLabel}>
                            Country
                        </Col>
                        <Col sm={9}>
                            <Select
                                placeholder="Select country..."
                                value={this.props.courier.courierSettings.companyCountry}
                                options={this.props.countries.map((country) => ({
                                    value: country.id,
                                    label: country.label,
                                }))}
                                onChange={this.props.onSettingsChangeFromList('companyCountry')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="companyCity">
                        <Col sm={3} componentClass={ControlLabel}>
                            City
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={this.props.courier.courierSettings.companyCity}
                                onChange={this.props.onSettingsChange('value', 'companyCity')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="companyPostalCode">
                        <Col sm={3} componentClass={ControlLabel}>
                            Postal Code
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={this.props.courier.courierSettings.companyPostalCode}
                                onChange={this.props.onSettingsChange('value', 'companyPostalCode')}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup controlId="companyAddress">
                        <Col sm={3} componentClass={ControlLabel}>
                            Address
                        </Col>
                        <Col sm={9}>
                            <FormControl
                                type="text"
                                value={this.props.courier.courierSettings.companyAddress}
                                onChange={this.props.onSettingsChange('value', 'companyAddress')}
                            />
                        </Col>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                        <Col sm={9} smOffset={3}>
                            <Button type="submit" bsStyle="primary" disabled={!this.props.validate(this.props.courier)}>
                                Save
                            </Button>
                            <Button onClick={this.props.onCancel}>Cancel</Button>
                        </Col>
                    </FormGroup>
                </BsForm>
            </div>
        )
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    courier: PropTypes.object.isRequired,
    validate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
    onSettingsChangeFromList: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCountriesChange: PropTypes.func.isRequired,
    countries: PropTypes.array,
}
