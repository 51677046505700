import { curry } from './curry'

export const path = curry((paths, obj) => {
    let current = obj
    let idx = 0
    while (idx < paths.length) {
        if (current == null) {
            return undefined
        }

        current = current[paths[idx]]
        idx += 1
    }

    return current
})
