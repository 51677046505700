import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('consumers')

export const consumerSearch = (searchOption, query) =>
    webapi()
        .path('find')
        .post({ [searchOption]: query })

export const updateConsumer = (consumerId, endCustomer) =>
    webapi()
        .path('consumer')
        .queryParam('consumerId', consumerId)
        .put(endCustomer)

export const deleteNotificationToken = (token) =>
    webapi()
        .path(token)
        .path('push-notification-token')
        .delete()

export const consumerResidences = (consumerId) =>
    webapi()
        .path('residences')
        .queryParam('consumerId', consumerId)
        .get()

export const updateResidence = (residenceId, consumerId, settings) =>
    webapi()
        .path('residences')
        .path(residenceId)
        .path('settings')
        .queryParam('consumerId', consumerId)
        .put(settings)

export const consumerSubscriptions = (phoneNumber) =>
    webapi()
        .path('timeslot-subscription')
        .path('all')
        .queryParam('phoneNumber', phoneNumber)
        .get()

export const consumerCurrentSubscription = (phoneNumber) =>
    webapi()
        .path('timeslot-subscription')
        .queryParam('phoneNumber', phoneNumber)
        .get()

export const cityAvailableTimeSlots = (city) =>
    webapi()
        .path('timeslots')
        .path('timeslots-schedules')
        .queryParam('city', city)
        .get()

export const updateCurrentSubscription = (consumerId, subscription) =>
    webapi()
        .path('timeslot-subscription')
        .path('current')
        .queryParam('consumerId', consumerId)
        .put(subscription)

export const cancelCurrentSubscription = (consumerId) =>
    webapi()
        .path('timeslot-subscription')
        .path('current')
        .queryParam('consumerId', consumerId)
        .delete()

export const consumerOrders = (consumerId) =>
    webapi()
        .path('orders')
        .queryParam('consumerId', consumerId)
        .get()
