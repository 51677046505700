import { getRegionalSettings, getUISettings, getInvoiceSettings } from '../utils/settings-api'
import {
    REGIONAL_SETTINGS_LOADED,
    REGIONAL_SETTINGS_FAILED_TO_LOAD,
    UI_SETTINGS_LOADED,
    INVOICE_SETTINGS_LOADED,
} from '../constants/actionTypes'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

export const regionalSettingsLoaded = (settings) => ({
    type: REGIONAL_SETTINGS_LOADED,
    response: settings,
})

export const regionalSettingsFailedToLoad = (e) => ({
    type: REGIONAL_SETTINGS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

export const uiSettingsLoaded = (settings) => ({
    type: UI_SETTINGS_LOADED,
    response: settings,
})

export const invoiceSettingsLoaded = (settings) => ({
    type: INVOICE_SETTINGS_LOADED,
    response: settings,
})

export const fetchRegionalSettings = () => (dispatch) =>
    getRegionalSettings()
        .then(compose(dispatch, regionalSettingsLoaded))
        .catch(compose(dispatch, regionalSettingsFailedToLoad, handleError))

export const fetchUISettings = () => (dispatch) =>
    getUISettings()
        .then(compose(dispatch, uiSettingsLoaded))
        .catch(handleError)

export const fetchInvoiceSettings = () => (dispatch) =>
    getInvoiceSettings()
        .then(compose(dispatch, invoiceSettingsLoaded))
        .catch(handleError)
