import React from 'react'

export default class AppHandler extends React.Component {
    render() {
        return (
            <div id="application" className="full-height">
                {this.props.children}
            </div>
        )
    }
}
