import React, { Component } from 'react'
import { Button } from 'react-bootstrap/lib'

export default class DiscountRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            delete: false,
        }
    }

    trash() {
        this.setState({ delete: true })
    }

    undo() {
        this.setState({ delete: false })
    }

    delete() {
        this.props.deleteDiscount(this.props.discount.id)
        this.setState({ delete: false })
    }

    render() {
        return (
            <tr>
                <td>{this.props.i}</td>
                <td>&ge; {this.props.discount.fromQuantity} </td>
                <td>{this.props.discount.discountPercentage} %</td>
                <td style={{ width: 70 }}>
                    {this.state.delete ? (
                        <span>
                            <button type="button" className="btn btn-xs btn-primary" onClick={this.delete.bind(this)}>
                                <span className="glyphicon glyphicon-ok" />
                            </button>
                            <button type="button" className="btn btn-xs" onClick={this.undo.bind(this)}>
                                <span className="glyphicon glyphicon-remove" />
                            </button>
                        </span>
                    ) : (
                        <button type="button" className="btn btn-xs btn-danger" onClick={this.trash.bind(this)}>
                            <span className="glyphicon glyphicon-trash" />
                        </button>
                    )}
                </td>
            </tr>
        )
    }
}
