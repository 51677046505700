import {
    FETCHING_TERMINAL_LIMITS,
    TERMINAL_LIMITS_LOADED,
    TERMINAL_LIMITS_FAILED_TO_LOAD,
    TERMINAL_LIMIT_LOADED,
    TERMINAL_LIMIT_REMOVED,
    TERMINAL_LIMIT_FAILED_TO_CREATE,
    TERMINAL_LIMIT_FAILED_TO_REMOVE,
    MERCHANT_LIMIT_REMOVED,
    CONSIGNMENT_COUNTS_LOADED,
    CONSIGNMENT_COUNTS_FAILED,
    LOADING_ROUTING_DEADLINES,
    ROUTING_DEADLINES_LOADED,
    LOADING_ROUTING_DEADLINES_FAILED,
} from '../constants/actionTypes'
import terminalLimitsState from './initialState/city-limits-state'
import { upsert } from '../utils/upsert'
import { routerActions } from 'react-router-redux'

export default function TerminalLimitsReducer(state = terminalLimitsState, action) {
    switch (action.type) {
        case FETCHING_TERMINAL_LIMITS:
            return {
                ...state,
                loading: true,
                limits: [],
            }

        case TERMINAL_LIMITS_LOADED:
            return {
                ...state,
                loading: false,
                error: null,
                limits: action.payload,
            }

        case TERMINAL_LIMITS_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                limits: [],
            }

        case TERMINAL_LIMIT_LOADED:
            const limit = action.payload
            return { ...state, limits: upsert(limit, state.limits) }

        case MERCHANT_LIMIT_REMOVED:
            const { limitId, merchantId } = action.payload
            const updated = state.limits.map((l) => {
                if (l.id === limitId) {
                    return {
                        ...l,
                        merchantConsignmentLimits: l.merchantConsignmentLimits.filter(
                            (ml) => ml.merchantId !== merchantId,
                        ),
                    }
                }

                return l
            })
            return {
                ...state,
                limits: updated,
            }

        case TERMINAL_LIMIT_FAILED_TO_CREATE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case TERMINAL_LIMIT_FAILED_TO_REMOVE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case TERMINAL_LIMIT_REMOVED:
            return {
                ...state,
                limits: state.limits.filter((l) => l.id !== action.cityLimit.id),
            }

        case CONSIGNMENT_COUNTS_LOADED:
            return {
                ...state,
                consignmentCounts: action.counts.payload,
            }

        case CONSIGNMENT_COUNTS_FAILED:
            return {
                ...state,
                error: action.error,
            }

        case LOADING_ROUTING_DEADLINES:
            return {
                ...state,
                loadingRoutingDeadlines: true,
                loadingRoutingDeadlinesError: false,
            }

        case ROUTING_DEADLINES_LOADED:
            return {
                ...state,
                routingDeadlines: action.payload,
                loadingRoutingDeadlines: false,
                loadingRoutingDeadlinesError: false,
            }

        case LOADING_ROUTING_DEADLINES_FAILED:
            return {
                ...state,
                loadingRoutingDeadlines: false,
                loadingRoutingDeadlinesError: true,
            }
        default:
            return state
    }
}
