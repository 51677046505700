export const partition = (xs, predicate) =>
    xs.reduce(
        ([yes, no], x) => {
            if (predicate(x)) {
                return [[...yes, x], no]
            }

            return [yes, [...no, x]]
        },
        [[], []],
    )
