import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Table } from 'react-bootstrap'

import ReturnPalletGroupEditModal from './return-pallet-group-edit-modal'
import ReturnPalletGroupRemoveModal from './return-pallet-group-remove-modal'
import { getReturnPalletGroups, removeReturnPalletGroup } from '../../actions/return-pallet-groups'

class ReturnPalletGroupTable extends Component {
    constructor() {
        super()
        this.state = {
            editGroupId: null,
            showGroupEditModal: false,
            groupToRemove: null,
            showGroupRemoveModal: false,
        }
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(getReturnPalletGroups)
    }

    showGroupEditModal = (id) => () => this.setState({ editGroupId: id, showGroupEditModal: true })

    hideGroupEditModal = () => this.setState({ showGroupEditModal: false })

    showGroupRemoveModal = (groupToRemove) => () => this.setState({ groupToRemove, showGroupRemoveModal: true })

    hideGroupRemoveModal = () => this.setState({ showGroupRemoveModal: false })

    onConfirmRemoval = () => {
        this.hideGroupRemoveModal()
        this.props.dispatch(removeReturnPalletGroup(this.state.groupToRemove.id))
    }

    render = () => {
        const { groups } = this.props
        const { editGroupId, groupToRemove, showGroupEditModal, showGroupRemoveModal } = this.state
        const middleAlign = { verticalAlign: 'middle' }
        return (
            <div>
                <Table striped>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Master merchant</th>
                            <th>Nr of merchants</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groups.map((group) => (
                            <tr key={group.id}>
                                <td style={middleAlign}>{group.id}</td>
                                <td style={middleAlign}>{group.name}</td>
                                <td style={middleAlign}>{group.groupMaster && group.groupMaster.name}</td>
                                <td style={middleAlign}>{group.merchants.length}</td>
                                <td style={middleAlign} className="text-right">
                                    <Link
                                        name="carriers-edit"
                                        className="btn btn-default"
                                        onClick={this.showGroupEditModal(group.id)}
                                    >
                                        Edit
                                    </Link>
                                    <Link
                                        name="carriers-edit"
                                        className="btn btn-danger"
                                        onClick={this.showGroupRemoveModal(group)}
                                    >
                                        Delete
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ReturnPalletGroupEditModal
                    groupId={editGroupId}
                    show={showGroupEditModal}
                    onHide={this.hideGroupEditModal}
                />
                <ReturnPalletGroupRemoveModal
                    group={groupToRemove}
                    show={showGroupRemoveModal}
                    onHide={this.hideGroupRemoveModal}
                    onConfirmRemoval={this.onConfirmRemoval}
                />
            </div>
        )
    }
}

ReturnPalletGroupTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
}

const mapStateToProps = ({ returnPalletGroups: { groups } }) => ({
    groups,
})

export default connect(mapStateToProps)(ReturnPalletGroupTable)
