import React from 'react'
import { Col } from 'react-bootstrap'
import ShoppingSettingsForm from './components/shopping-settings-form/shopping-settings-form'

const Shopping = () => (
    <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
        <ShoppingSettingsForm />
    </Col>
)

export default Shopping
