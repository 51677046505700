import React, { Component } from 'react'

export default class Coordinate extends Component {
    render() {
        const labelStyle = { display: 'inline-block', width: '30px' }
        const onUpdate = (axis, e) => {
            this.props.onUpdate(axis, e.target.value)
        }
        return (
            <div>
                <input style={labelStyle} value="Lat" disabled />
                <input value={this.props.lat} onChange={(e) => onUpdate('latitude', e)} />
                <input style={labelStyle} value="Lng" disabled />
                <input value={this.props.lng} onChange={(e) => onUpdate('longitude', e)} />
            </div>
        )
    }
}
