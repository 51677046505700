import React, { Component } from 'react'
import PropTypes from 'react/lib/ReactPropTypes'
import moment from 'moment'
import Loader from 'react-loader'
import { Button, Col, Row } from 'react-bootstrap'
import CreateRejectionInvoiceItemModal from './create-rejected-invoice-modal'
import { handleError } from '../../utils/handle-error'

export default class RejectedInvoiceItemList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            rejectedInvoices: [],
            invoiceChecked: [],
            openCreateManualModal: false,
            fetchingInvoices: true,
        }
    }

    componentDidMount() {
        this.loadRejectedInvoices()
    }

    loadRejectedInvoices = () => {
        this.setState({ fetchingInvoices: true })

        this.props
            .loadAllInvoiceItems(this.props.rejectedInvoiceId)
            .then((resp) => {
                this.setState({
                    rejectedInvoices: resp,
                    fetchingInvoices: false,
                    invoiceChecked: Object.values(resp.map(() => false)),
                })
            })
            .catch((err) => {
                this.setState({ fetchingInvoices: false })
                handleError(err)
            })
    }

    toggleCheckInvoice = (index) => {
        this.setState((prevState) => ({
            invoiceChecked: Object.values({
                ...prevState.invoiceChecked,
                [index]: !prevState.invoiceChecked[index],
            }),
        }))
    }

    deleteMarkedInvoices = () => {
        for (let i = 0; i < this.state.invoiceChecked.length; i += 1) {
            if (this.state.invoiceChecked[i]) {
                this.props.deleteInvoiceItem(this.state.rejectedInvoices[i].id).then(this.loadRejectedInvoices)
            }
        }
    }

    render() {
        return (
            <Col md={12}>
                <Row>
                    <CreateRejectionInvoiceItemModal
                        invoiceCurrency={this.props.invoiceCurrency}
                        invoiceRecipientId={this.props.rejectedInvoiceId}
                        show={this.state.openCreateManualModal}
                        loadAllInvoiceItems={this.loadRejectedInvoices}
                        createInvoiceItem={this.props.createInvoiceItem}
                        close={() => this.setState({ openCreateManualModal: false })}
                        invoiceTypes={this.props.invoiceTypes}
                        invoiceArticles={this.props.invoiceArticles}
                    />
                    <Button
                        className="pull-right"
                        bsStyle="success"
                        onClick={() => this.setState({ openCreateManualModal: true })}
                    >
                        <i className="glyphicon glyphicon-plus" /> Create invoice row
                    </Button>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>
                                    <Button bsStyle="danger" bsSize="xsmall" onClick={this.deleteMarkedInvoices}>
                                        <i className="glyphicon glyphicon-minus small" />
                                    </Button>
                                </th>
                                <th>#</th>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Custom Description</th>
                                <th>Invoice account</th>
                                <th>Invoice Article</th>
                                <th>Amount</th>
                                <th>Currency</th>
                                <th>Created at</th>
                                <th>Created by</th>
                            </tr>
                        </thead>
                        {this.state.fetchingInvoices ? (
                            <Loader />
                        ) : (
                            <tbody>
                                {this.state.rejectedInvoices.map((ri, i) => (
                                    <tr key={ri.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={this.state.invoiceChecked[i]}
                                                onChange={() => this.toggleCheckInvoice(i)}
                                            />
                                        </td>
                                        <td>{ri.id}</td>
                                        <td>{moment(ri.date.join('-'), 'YYYY-MM-DD').format('YYYY-MM-DD')}</td>
                                        <td>{ri.description}</td>
                                        <td>{ri.customInvoiceDescription}</td>
                                        <td>{ri.invoiceAccountType}</td>
                                        <td>{ri.invoiceArticle}</td>
                                        <td>{ri.amountInCents / 100}</td>
                                        <td>{ri.currency.code}</td>
                                        <td>{moment.unix(ri.createdAt / 10e2).format('YYYY-MM-DD')}</td>
                                        <td>{`${ri.createdByUser.firstName} ${ri.createdByUser.lastName}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                </Row>
            </Col>
        )
    }
}

RejectedInvoiceItemList.propTypes = {
    invoiceCurrency: PropTypes.string,
    rejectedInvoiceId: PropTypes.number,
    loadAllInvoiceItems: PropTypes.func,
    createInvoiceItem: PropTypes.func,
    deleteInvoiceItem: PropTypes.func,
    invoiceTypes: PropTypes.array,
    invoiceArticles: PropTypes.array,
}
