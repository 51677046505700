import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Alert, Button, Col, FormControl, Glyphicon, InputGroup, Modal, Panel, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import groupBy from 'lodash/groupBy'
import differenceBy from 'lodash/differenceBy'
import isEqual from 'lodash/isEqual'
import * as collectionPointActions from '../../../actions/collection-points'
import PostalCodeZones from '../../postal-code-zones/postal-code-zones'
import ManagePricePoints from './price-points'
import ManageTransitPoints from './transit-points'
import ManagePickup from './pickup-schedules'
import auth from '../../../auth'
import { hd } from '../../../utils/hd'
import EditCollectionPoint from './edit-collection-point'
import TerminalSelect from '../../common/terminal-select'
import CountrySelect from '../../common/country-select'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../../utils/role'

const hours = (seconds) => {
    if (seconds == null) {
        return null
    }

    return parseInt(seconds / 3600, 10)
}

const minutes = (seconds) => {
    if (seconds == null) {
        return null
    }

    const minutesLeft = seconds % 3600
    return parseInt(minutesLeft / 60, 10)
}

const time = (fullHours, fullMinutes) => parseInt(fullHours, 10) * 3600 + parseInt(fullMinutes, 10) * 60

class CollectionPoint extends Component {
    constructor(props) {
        super(props)
        this.hasElevatedActions = auth.hasAnyRole(
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
        )
        this.state = {
            openid: false,
            nav: this.hasElevatedActions ? 0 : 1,
            delete: false,
            changed: false,
            saveCodes: false,
            newZones: [],
            previouslySelectedZones: [],
            removeCodes: false,
            selectedTerminal: this.props.cp.injectionTerminal,
            orderFulfillmentTime: this.props.cp.orderFulfillmentTime,
            orderFulfillmentTimeHours: hours(this.props.cp.orderFulfillmentTime),
            orderFulfillmentTimeMinutes: minutes(this.props.cp.orderFulfillmentTime),
        }
    }

    componentDidUpdate(prevProps) {
        const { cpzones } = this.props

        if (!isEqual(cpzones, prevProps.cpzones)) {
            const zoneOpt = this.zoneOpt()
            if (hd(zoneOpt) != null) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({ previouslySelectedZones: hd(zoneOpt).data })
            }
        }
    }

    onPostalCodeZoneChange = (postalCodeZones) => {
        const { cpzones, cp } = this.props

        const newPostalCodeData = postalCodeZones.map((p) => ({
            id: p.value,
            title: p.label,
            countryCode: p.countryCode,
            city: p.city,
        }))

        const newZoneIds = newPostalCodeData.map((c) => c.id)

        const zoneIdsToRemove = cpzones
            .find((pk) => pk.collectionId === cp.id)
            .data.filter((d) => !newZoneIds.includes(d.id))
            .map((i) => i.id)

        this.setState({
            changed: true,
            saveCodes: newZoneIds,
            removeCodes: zoneIdsToRemove,
            previouslySelectedZones: newPostalCodeData,
        })
    }

    onCountryChange = (countries) => {
        if (!countries.length) {
            return
        }
        const newCountriesValues = countries.map((c) => c.value)
        const newZones = this.props.postalcodes.filter((p) => newCountriesValues.includes(p.countryCode))
        this.setState({
            changed: true,
            newZones,
        })
    }

    storeTransitsData = () => {
        let transitsData = this.props.transitsData.filter((col) => col.cid === this.props.cp.id)
        if (transitsData.length > 0) {
            transitsData = hd(transitsData).transits
        }
        this.props.actions.storeTransitsData(this.props.cp.id, transitsData)
        this.setState({ delete: false })
    }

    removeTransitRoute = (index) => {
        if (Number.isInteger(index)) {
            this.props.actions.removeTransitsData(this.props.cp.id, index)
        } else {
            this.props.actions.removeTransitsData(this.props.cp.id)
        }
        this.setState({ delete: true })
    }

    changeTab = (i) => (e) => {
        e.preventDefault()
        this.setState({ nav: i })
    }

    removeCollectionPoint = (merchantId, collectionPointId) => () => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Remove collection point?')) {
            this.props.actions.removeCollectionPoint(merchantId, collectionPointId)
        }
    }

    reverseRemove = () => {
        this.props.actions.removeUndoTransitsData(this.props.cp.id)
        this.setState({ delete: false })
    }

    validate = () => {
        const { selectedTerminal, orderFulfillmentTime } = this.state

        return selectedTerminal && orderFulfillmentTime && !Number.isNaN(orderFulfillmentTime)
    }

    updateChanges = () => {
        const { actions, cp: collectionPoint, buyerId: merchantId } = this.props
        const { saveCodes, removeCodes, orderFulfillmentTime, selectedTerminal, previouslySelectedZones } = this.state

        if (saveCodes) {
            actions.storePostalCodeZones(merchantId, collectionPoint.id, saveCodes, previouslySelectedZones)
        }
        if (removeCodes) {
            actions.removeCPZones(collectionPoint.id, removeCodes, previouslySelectedZones)
        }
        if (
            orderFulfillmentTime !== collectionPoint.orderFulfillmentTime ||
            selectedTerminal !== collectionPoint.injectionTerminal
        ) {
            actions.storeInjectionTerminalAndFulfillmentTime(collectionPoint.id, selectedTerminal, orderFulfillmentTime)
        }

        this.setState({ changed: false, saveCodes: false, removeCodes: false })
    }

    showCollectionPoint = (cpid) => () => {
        if (this.state.openid === cpid) {
            this.setState({ openid: false })
        } else {
            this.setState({ openid: cpid })
        }
    }

    missingCities = () => {
        const { postalcodes } = this.props
        const { previouslySelectedZones } = this.state

        const countryCodes = []
        let diff = ''

        const citiesGroupedByCountry = groupBy(previouslySelectedZones, 'countryCode')
        Object.keys(citiesGroupedByCountry).forEach((key) => {
            countryCodes.push(key)
        })

        const allZonesByCountry = postalcodes.filter((p) => countryCodes.includes(p.countryCode))

        diff = differenceBy(allZonesByCountry, previouslySelectedZones, 'id')
            .map((d) => d.title)
            .join(', ')

        return diff
    }

    zoneOpt = () => {
        const { cpzones, cp } = this.props

        return cpzones
            .map((cpz) => ({
                collectionId: cpz.collectionId,
                data: cpz.data.map((opt) => ({
                    id: opt.id,
                    title: opt.title,
                    countryCode: opt.countryCode,
                    city: opt.city,
                })),
            }))
            .filter((pk) => pk.collectionId === cp.id)
    }

    renderHeader(cpid) {
        const { cp } = this.props
        const { openid } = this.state
        return (
            <div
                onClick={this.showCollectionPoint(cpid)}
                onKeyDown={this.showCollectionPoint(cpid)}
                tabIndex={cpid}
                role="button"
            >
                {cp.id} : {cp.name}
                <Glyphicon className="open" glyph={openid === cpid ? 'chevron-up' : 'chevron-down'} />
            </div>
        )
    }

    render() {
        if (this.props.pricePoints == null && this.props.transits == null) {
            return null
        }

        if (this.props.postalcodes == null) {
            return null
        }

        const address = [
            `${this.props.cp.address.street},`,
            this.props.cp.address.postalCode,
            this.props.cp.address.city,
        ].join(' ')

        const missingCities = this.missingCities()

        const zoneOpt = this.zoneOpt()

        return (
            <Panel
                className={cx('collection-point', { closed: this.state.openid !== this.props.cp.id })}
                header={this.renderHeader(this.props.cp.id)}
                key={this.props.cp.id}
            >
                <Row>
                    <Col md={12}>
                        <Button
                            bsStyle="danger"
                            bsSize="xs"
                            className="pull-right"
                            onClick={this.removeCollectionPoint(this.props.buyerId, this.props.cp.id)}
                        >
                            &times;
                        </Button>
                        {this.hasElevatedActions && (
                            <EditCollectionPoint merchantId={this.props.buyerId} collectionPoint={this.props.cp} />
                        )}
                        <dl className="dl-horizontal">
                            <dt>Address</dt>
                            <dd>{address}</dd>
                            {!this.hasElevatedActions && [
                                <dt>Name</dt>,
                                <dd>{this.props.cp.name}</dd>,
                                <dt>Reference Person</dt>,
                                <dd>{this.props.cp.referencePerson}</dd>,
                                <dt>Telephone Number</dt>,
                                <dd>{this.props.cp.phoneNumber}</dd>,
                            ]}
                            <dt className="dl-horizontal__country--title">Select Country</dt>
                            <dd className="dl-horizontal__country--dd">
                                {missingCities ? (
                                    <Alert bsStyle="info" style={{ marginTop: 18, marginBottom: 5 }}>
                                        <p>
                                            Cities that are not in the list: <b>{missingCities}</b>
                                        </p>
                                    </Alert>
                                ) : (
                                    ''
                                )}

                                <p>Choose a country to pre-fill all delivery zones</p>
                                <CountrySelect multi onSelect={(countries) => this.onCountryChange(countries)} />
                            </dd>
                            <dt style={{ marginTop: 10 }}>Delivery Zones</dt>
                            <dd style={{ paddingRight: 20, marginTop: 10, marginBottom: 10 }}>
                                {hd(zoneOpt) != null ? (
                                    <PostalCodeZones
                                        disabled={!this.hasElevatedActions}
                                        zones={this.props.postalcodes.map((item) => item)}
                                        onChange={this.onPostalCodeZoneChange}
                                        newZones={this.state.newZones}
                                        defaultValue={hd(zoneOpt).data}
                                    />
                                ) : null}
                            </dd>
                            <dt>Injection terminal</dt>
                            <dd style={{ paddingRight: 20, marginTop: 10, marginBottom: 10 }}>
                                <TerminalSelect
                                    disabled={!this.hasElevatedActions}
                                    multi={false}
                                    value={this.state.selectedTerminal}
                                    onSelect={(selectedTerminal) => {
                                        this.setState({
                                            selectedTerminal: selectedTerminal == null ? null : selectedTerminal.code,
                                            changed: true,
                                        })
                                    }}
                                />
                            </dd>
                            <dt>Order fulfillment time</dt>
                            <dd>
                                <InputGroup style={{ width: 100, display: 'inline-table', marginRight: 10 }}>
                                    <FormControl
                                        type="number"
                                        min="0"
                                        value={this.state.orderFulfillmentTimeHours}
                                        disabled={!this.hasElevatedActions}
                                        onChange={({ target: { value: orderFulfillmentTimeHours } }) =>
                                            this.setState((state) => ({
                                                changed: true,
                                                orderFulfillmentTimeHours,
                                                orderFulfillmentTime: time(
                                                    orderFulfillmentTimeHours,
                                                    state.orderFulfillmentTimeMinutes,
                                                ),
                                            }))
                                        }
                                    />
                                    <InputGroup.Addon>h</InputGroup.Addon>
                                </InputGroup>
                                <InputGroup style={{ width: 100, display: 'inline-table' }}>
                                    <FormControl
                                        type="number"
                                        min="0"
                                        value={this.state.orderFulfillmentTimeMinutes}
                                        disabled={!this.hasElevatedActions}
                                        onChange={({ target: { value: orderFulfillmentTimeMinutes } }) =>
                                            this.setState((state) => ({
                                                changed: true,
                                                orderFulfillmentTimeMinutes,
                                                orderFulfillmentTime: time(
                                                    state.orderFulfillmentTimeHours,
                                                    orderFulfillmentTimeMinutes,
                                                ),
                                            }))
                                        }
                                    />
                                    <InputGroup.Addon>m</InputGroup.Addon>
                                </InputGroup>
                            </dd>
                            <dt />
                            <dd className={this.state.changed ? 'show' : 'hide'} style={{ padding: 20 }}>
                                <Button
                                    className="pull-right"
                                    bsStyle="success"
                                    disabled={!this.validate()}
                                    onClick={this.updateChanges}
                                >
                                    <Glyphicon glyph="floppy-save" /> Store
                                </Button>
                            </dd>
                        </dl>
                    </Col>
                </Row>
                <ul className="nav nav-tabs">
                    {this.hasElevatedActions ? (
                        <li className={this.state.nav === 0 ? 'active' : ''}>
                            <a href="#" onClick={this.changeTab(0)}>
                                Price Points
                            </a>
                        </li>
                    ) : null}
                    <li className={this.state.nav === 1 ? 'active' : ''}>
                        <a href="#" onClick={this.changeTab(1)}>
                            Transit Points
                        </a>
                    </li>
                    <li className={this.state.nav === 2 ? 'active' : ''}>
                        <a href="#" onClick={this.changeTab(2)}>
                            Merchant Schedules
                        </a>
                    </li>
                </ul>
                {this.hasElevatedActions ? (
                    <div className={this.state.nav === 0 ? 'show' : 'hide'}>
                        <ManagePricePoints
                            buyerId={this.props.buyerId}
                            cid={this.props.cp.id}
                            priceList={this.props.pricePoints.length > 0 ? this.props.pricePoints[0].data : null}
                            postalcodes={this.props.postalcodes}
                            postalcodeZones={hd(
                                this.props.cpzones
                                    .filter((pk) => pk.collectionId === this.props.cp.id)
                                    .map((cpz) => cpz.data),
                            )}
                            onDelete={this.props.canDeletePricePoints}
                        />
                    </div>
                ) : null}
                <div className={this.state.nav === 1 ? 'show' : 'hide'}>
                    <ManageTransitPoints
                        cid={this.props.cp.id}
                        transits={this.props.transits}
                        transitsData={this.props.transitsData}
                        postalcodes={hd(
                            this.props.cpzones
                                .filter((pk) => pk.collectionId === this.props.cp.id)
                                .map((cpz) => cpz.data),
                        )}
                        warehouses={this.props.warehouses}
                        loading={this.props.loading}
                        saving={this.props.saving}
                        addTransitsData={this.addTransitsData}
                        storeTransitsData={this.storeTransitsData}
                        removeTransitRoute={this.removeTransitRoute}
                        canEdit={this.hasElevatedActions}
                    />
                </div>
                <div className={this.state.nav === 2 ? 'show' : 'hide'}>
                    <ManagePickup
                        cid={this.props.cp.id}
                        buyerId={this.props.buyerId}
                        loading={this.props.loading}
                        saving={this.props.saving}
                        canEdit={this.hasElevatedActions}
                    />
                </div>
                <Modal show={this.state.delete} animation>
                    <div style={{ textAlign: 'center' }}>
                        <Alert bsStyle="warning" style={{ padding: 20 }}>
                            <span>Are you sure you want to remove Data? </span>
                            <div style={{ paddingTop: 25 }}>
                                <Button onClick={this.reverseRemove}>
                                    <Glyphicon glyph="arrow-left" /> Cancel
                                </Button>
                                <Button bsStyle="danger" onClick={this.storeTransitsData}>
                                    <Glyphicon glyph="trash" /> Delete
                                </Button>
                            </div>
                        </Alert>
                    </div>
                </Modal>
            </Panel>
        )
    }
}
CollectionPoint.propTypes = {
    actions: PropTypes.object.isRequired,
    pricePoints: PropTypes.array,
    postalcodes: PropTypes.array,
    canDeletePricePoints: PropTypes.bool,
    transits: PropTypes.array,
    transitsData: PropTypes.array,
    warehouses: PropTypes.array,
    cpzones: PropTypes.array,
    cp: PropTypes.object,
    buyerId: PropTypes.number,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    cpzones: state.collection.cpzones,
    collectionId: state.collection.collectionId,
    postalcodes: state.collection.postcodes,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(collectionPointActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPoint)
