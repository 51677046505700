export const NotStarted = 'NotStarted'
export const OnRouteCollection = 'OnRouteCollection'
export const Collected = 'Collected'
export const CrossDocked = 'CrossDocked'
export const OnRouteDelivery = 'OnRouteDelivery'
export const Delivered = 'Delivered'
export const Miss = 'Miss'
export const Cancelled = 'Cancelled'
export const Backordered = 'Backordered'
export const ReturnedToTerminal = 'ReturnedToTerminal'
export const ReturnedToMerchant = 'ReturnedToMerchant'
