import React from 'react'
import PropTypes from 'prop-types'

const ThumbnailLogoPreview = (props) => {
    const { backgroundColor, image } = props
    const bg = backgroundColor || 'white'

    return (
        <div
            style={{
                width: 72,
                height: 72,
                backgroundColor: bg,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {image && (
                <img
                    style={{ maxWidth: 56, maxHeight: 56, objectFit: 'contain' }}
                    src={image.url}
                    alt="Thumbnail logo preview"
                />
            )}
        </div>
    )
}

ThumbnailLogoPreview.propTypes = {
    backgroundColor: PropTypes.string,
    image: PropTypes.object,
}

export default ThumbnailLogoPreview
