import React, { Component } from 'react'
import Modal from 'react-bootstrap/lib/Modal'

export default class DeleteModal extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Modal show={this.props.show} animation>
                <div className="panel-body" style={{ textAlign: 'center' }}>
                    <div className="alert alert-warning" role="alert" style={{ padding: 20 }}>
                        <span>Are you sure you want to remove Data? </span>
                        <div style={{ paddingTop: 25 }}>
                            <button type="button" className="btn btn-default" onClick={this.props.reverse.bind(this)}>
                                <i className="glyphicon glyphicon-arrow-left" /> Cancel
                            </button>
                            <button type="button" className="btn btn-danger" onClick={this.props.remove.bind(this)}>
                                <i className="glyphicon glyphicon-trash" /> Delete
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
