export const swedenFullName = 'Sweden'
export const denmarkFullName = 'Denmark'
export const finlandFullName = 'Finland'
export const netherlandsFullName = 'Netherlands'
export const belgiumFullName = 'Belgium'
export const germanyFullName = 'Germany'
export const norwayFullName = 'Norway'

export default function getNameFromCountryCode(countryCode) {
    // TODO: use "settings/regions" endpoint instead

    let name
    switch (countryCode) {
        case 'SE':
            name = swedenFullName
            break
        case 'FI':
            name = finlandFullName
            break
        case 'DK':
            name = denmarkFullName
            break
        case 'NL':
            name = netherlandsFullName
            break
        case 'BE':
            name = belgiumFullName
            break
        case 'DE':
            name = germanyFullName
            break
        case 'NO':
            name = norwayFullName
            break
        default:
            return countryCode
    }
    return name
}
