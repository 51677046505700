import auth from '../auth'
import Webapi from './webapi'
import dev from './dev'

const contentTypeV1 = dev('application/json', 'application/vnd.budbee.lockers-v1+json')

const contentTypeV2 = dev('application/json', 'application/vnd.budbee.lockers-v2+json')

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentTypeV2)
        .contentType(contentTypeV2)
        .path('lockers')

export const resetLockerCodeForPackage = (packageId) =>
    webapi()
        .path('hotfixes')
        .path('compartment-did-not-open')
        .path(packageId)
        .header('Accept', contentTypeV2)
        .header('Content-Type', contentTypeV2)
        .header('Authorization', auth.get())
        .get()

export const extendExpiryDateForPackage = (packageId, days) =>
    webapi()
        .path('extend-expire-date')
        .path(packageId)
        .path(days)
        .header('Accept', contentTypeV1)
        .header('Content-Type', contentTypeV1)
        .header('Authorization', auth.get())
        .post({})

export const getLockers = (countryCode, postalCode) =>
    webapi()
        .path('available-lockers')
        .path(countryCode)
        .path(postalCode)
        .queryParam('language', 'SE')
        .queryParam('fromPlaceApi', true)
        .header('Accept', contentTypeV1)
        .header('Content-Type', contentTypeV1)
        .header('Authorization', auth.get())
        .get()
