import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Nav, NavItem, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import HtmlTitle from '../html-title'
import auth from '../../auth'
import * as actions from '../../actions/merchants'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

class MerchantHandler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasRole: false,
        }
    }

    componentDidMount() {
        // More specific roles to be added later
        const hasRole = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

        if (!hasRole) {
            this.props.router.push('/admin/merchants')
            return
        }

        this.setState({ hasRole })

        this.props.actions.fetchMerchant(this.props.params.id)
    }

    render() {
        const merchantId = this.props.params.id
        const merchantUrlBase = `/admin/merchants/${merchantId}`

        return (
            <div className="sidebar-wrapper couriers">
                <nav className="sidebar navbar navbar-default">
                    <Link className="back-link" to="/admin/merchants">
                        <small>&larr; Back to merchants</small>
                    </Link>
                    <div className="navbar-header">
                        <span className="navbar-brand">{this.props.buyer && this.props.buyer.name}</span>
                    </div>
                    <Nav navbar>
                        {this.state.hasRole && (
                            <IndexLinkContainer to={merchantUrlBase}>
                                <NavItem>
                                    <Glyphicon glyph="transfer" />
                                    Collection points
                                </NavItem>
                            </IndexLinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/general`}>
                                <NavItem>
                                    <Glyphicon glyph="cog" />
                                    General Settings
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/invoicing`}>
                                <NavItem>
                                    <Glyphicon glyph="list-alt" />
                                    Invoicing
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/distribution`}>
                                <NavItem>
                                    <Glyphicon glyph="road" />
                                    Distribution
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/notifications`}>
                                <NavItem>
                                    <Glyphicon glyph="bell" />
                                    Notifications
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/products`}>
                                <NavItem disabled>
                                    <Glyphicon glyph="shopping-cart" />
                                    Additional products
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`${merchantUrlBase}/shopping`}>
                                <NavItem>
                                    <Glyphicon glyph="shopping-cart" />
                                    Shopping
                                </NavItem>
                            </LinkContainer>
                        )}
                    </Nav>
                </nav>
                <div className="content-wrapper">
                    <Grid fluid>
                        <Row>
                            <Loader color="#BFBFBF" loaded={!this.props.loading} />
                            {this.props.buyer && <HtmlTitle title={this.props.buyer.name} />}
                            {this.props.buyer == null || !this.state.hasRole ? null : this.props.children}
                        </Row>
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ...state.buyers,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MerchantHandler)

MerchantHandler.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object,
    buyer: PropTypes.object,
    loading: PropTypes.bool,
    children: PropTypes.node.isRequired,
}
