import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert, Button, Checkbox, FormGroup, Modal, Table } from 'react-bootstrap'
import Loader from 'react-loader'
import moment from 'moment'
import { eventsToExclude, filteredEvents, superfluousEventsToExcludeFirstOf } from '../../../utils/journal-events'

export default class JournalEventsModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            journalEvents: [],
            showExcluded: false,
            showHelp: false,
        }
    }

    componentDidMount() {
        this.handleScanEvents()
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.handleScanEvents()
        }
    }

    handleScanEvents = () => {
        const { allScanEvents } = this.props

        const journalEvents = allScanEvents.map((x) => ({ ...x, showAll: false }))
        this.setState({ journalEvents })
    }

    onToggleExcludedEvents = () => {
        this.setState((prevState) => ({ showExcluded: !prevState.showExcluded }))
    }

    onToggleHelp = () => {
        this.setState((prevState) => ({ showHelp: !prevState.showHelp }))
    }

    showFullEntry = (entryId) => () => {
        this.setState((prevState) => ({ journalEvents: this.updateJournalEvents(prevState.journalEvents, entryId) }))
    }

    updateJournalEvents = (journalEvents, entryId) => {
        const updatedJournalEvents = [...journalEvents]
        const index = updatedJournalEvents.findIndex((x) => x.id === entryId)
        const entry = updatedJournalEvents[index]
        entry.showAll = !entry.showAll
        return updatedJournalEvents
    }

    helpPopover = () => {
        const { showHelp } = this.state
        return (
            <Modal show={showHelp}>
                <Modal.Header closeButton>
                    <Modal.Title>Journal Events</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>The first of each of these event types is hidden unless you show all:</p>
                    <ul>
                        {superfluousEventsToExcludeFirstOf.map((x) => (
                            <li key={x}>{x}</li>
                        ))}
                    </ul>
                    <p>These events are hidden unless you show all:</p>
                    <ul>
                        {eventsToExclude.map((x) => (
                            <li key={x}>{x}</li>
                        ))}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.onToggleHelp}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { show, onClose, loadingScanEvents, loadingScanEventsFailed } = this.props
        const { journalEvents, showExcluded } = this.state
        const events = showExcluded ? journalEvents : filteredEvents(journalEvents)
        return (
            <Modal bsSize="large" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Journal Events</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loader loaded={!loadingScanEvents} />
                    {this.helpPopover()}
                    <FormGroup>
                        <Checkbox inline value={showExcluded} onClick={this.onToggleExcludedEvents}>
                            Show all events
                        </Checkbox>
                        <Button bsSize="small" className="pull-right" onClick={this.onToggleHelp}>
                            Show help
                        </Button>
                    </FormGroup>
                    {!loadingScanEvents && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Timestamp</th>
                                    <th>Additional</th>
                                </tr>
                            </thead>
                            {events.map((x) => (
                                <tbody key={x.id}>
                                    <tr>
                                        <td>{x.event.event}</td>
                                        <td>{moment.utc(x.timestamp).toISOString()} UTC</td>
                                        <td className="text-center">
                                            <Button
                                                style={{ padding: 0 }}
                                                bsStyle="link"
                                                onClick={this.showFullEntry(x.id)}
                                            >
                                                {x.showAll ? 'Hide' : 'Show'}
                                            </Button>
                                        </td>
                                    </tr>
                                    {x.showAll && (
                                        <tr>
                                            <td colSpan="3">
                                                <pre style={{ fontSize: '1.2rem', color: '#337ab7' }}>
                                                    {JSON.stringify(x, null, 4)}
                                                </pre>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            ))}
                        </Table>
                    )}
                    {loadingScanEventsFailed && <Alert bsStyle="danger">Failed to load events</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

JournalEventsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loadingScanEvents: PropTypes.bool.isRequired,
    loadingScanEventsFailed: PropTypes.bool.isRequired,
    allScanEvents: PropTypes.array.isRequired,
}
