import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const driverLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((level) => ({
    label: `Level ${level}`,
    value: level,
}))

export default class DriverLevelSelect extends Component {
    state = {
        selected: null,
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            if (value.label != null || value.length != null) {
                this.setState({ selected: value })
            } else {
                this.setState({ selected: driverLevels.find((level) => level.value === value) })
            }
        }
    }

    onChange = (selected) => {
        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    render() {
        const { multi } = this.props
        const { selected } = this.state
        const placeholder = `Select driver level${multi ? 's' : ''}...`

        return (
            <Select
                disabled={this.props.disabled}
                placeholder={placeholder}
                value={selected}
                options={driverLevels}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable
                multi={multi}
            />
        )
    }
}

DriverLevelSelect.propTypes = {
    disabled: PropTypes.bool,
    multi: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
}
