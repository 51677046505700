import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { Alert, Grid, Row, Col, Panel, Table, DropdownButton, MenuItem } from 'react-bootstrap'
import moment from 'moment'
import * as actions from '../../actions/recurring-pickups'
import * as terminalActions from '../../actions/terminals'
import HtmlTitle from '../html-title'
import { formatTime } from './format-time'
import { displayName } from '../../utils/display-name'
import { ifElse } from '../../utils/if-else'
import auth from '../../auth'
import RecurringRouteMap from './map/recurring-route-map'
import CreateInvoiceModal from '../invoices/create-invoice-modal'
import { INVOICE_TYPES_COURIER } from '../couriers/manage-invoice-items'
import { INVOICE_TYPES_MERCHANT } from '../buyers/manage-invoice-items'
import { createInvoiceForRoute } from '../../utils/invoice-webapi'
import { fetchInvoiceSettings } from '../../actions/regional-settings'
import { distinctBy } from '../../utils/distinct-by'
import { handleError } from '../../utils/handle-error'
import { cancelRoute } from '../../utils/route-webapi'
import { coalesce } from '../../utils/coalesce'
import Confirm from '../confirm'
import { STOP_TYPE } from '../../utils/recurring-pickups'
import {
    MERCHANT_ADMIN,
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

const distinctByValue = distinctBy(({ value }) => value)

const renderMerchantStop = (stop) =>
    displayName(
        ' - ',
        stop.merchantName,
        stop.collectionPointName,
        ifElse(stop.pickup, 'With pickup'),
        ifElse(stop.returns, 'With returns'),
    )

const renderTerminalStop = (stop) => stop.terminalName

const renderRestStop = () => 'Rest'

const renderStop = (stop, i) => {
    switch (stop.type) {
        case STOP_TYPE.MERCHANT:
            return renderMerchantStop(stop)
        case STOP_TYPE.TERMINAL:
            return renderTerminalStop(stop)
        case STOP_TYPE.REST:
            return renderRestStop()
        default:
            return null
    }
}

const renderStops = (stops) => {
    if (stops == null) {
        return null
    }

    return (
        <ol>
            {stops.map((stop, i) => (
                <li key={`${stop.id}|${i}`}>{renderStop(stop)}</li>
            ))}
        </ol>
    )
}

class RecurringPickupHandler extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            selectedRoute: null,
            invoiceTypes: [],
            invoiceArticles: [],
            invoiceRecipientOptions: null,
            prefill: {},
            cancelRouteFeedback: { displayFeedback: false, error: false, routeId: null },
            showCancelModal: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
            MERCHANT_ADMIN,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        this.props.actions.fetchRecurringPickup(this.props.params.id)
        this.props.actions.fetchRecurringPickupHistory(this.props.params.id)
        this.props.terminalActions.fetchTerminalsWebapi()
        this.props.dispatch(fetchInvoiceSettings())
    }

    createInvoiceItem = (routeId, invoice) =>
        createInvoiceForRoute(routeId, invoice)
            .then(() => this.setState({ selectedRoute: null }))
            .catch(handleError)

    openModal({ id, date, city }, invoiceTypes, invoiceArticles, invoiceRecipientOptions) {
        this.setState({
            selectedRoute: id,
            invoiceRecipientOptions:
                invoiceRecipientOptions != null
                    ? distinctByValue(invoiceRecipientOptions.map(({ id: value, name: label }) => ({ value, label })))
                    : null,
            invoiceTypes,
            invoiceArticles: invoiceArticles.map(({ id: value, label }) => ({ value, label })),
            prefill: { date, city },
        })
    }

    onConfirmCancel = (id) => {
        this.setState({ showCancelModal: true, cancelRouteFeedback: { routeId: id } })
    }

    renderCancelRouteConfirmation() {
        const { showCancelModal } = this.state
        const { routeId } = this.state.cancelRouteFeedback
        return (
            <Confirm
                title="Cancel merchant route"
                show={showCancelModal}
                onClose={() => {
                    this.setState({ showCancelModal: false })
                }}
                onConfirm={() =>
                    cancelRoute(routeId)
                        .then(() =>
                            this.setState({
                                showCancelModal: false,
                                cancelRouteFeedback: { displayFeedback: true, routeId, error: false },
                            }),
                        )
                        .catch((error) => {
                            this.setState({
                                showCancelModal: false,
                                cancelRouteFeedback: { displayFeedback: true, routeId, error: true },
                            })
                            handleError(error)
                        })
                }
            >
                Are you sure you want to cancel route: {routeId}?
            </Confirm>
        )
    }

    renderRouteCancelFeedback() {
        const { error, routeId } = this.state.cancelRouteFeedback

        if (error) {
            return <Alert bsStyle="danger">Failed to cancel route: {routeId}</Alert>
        }
        return <Alert bsStyle="success">Successfully cancelled route: {routeId}</Alert>
    }

    renderPickupHistory(history) {
        return history
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .flatMap((entry) => {
                const rowspan = entry.merchants.length
                return entry.merchants.map((merchant, idx) => {
                    const key = `${entry.id}|${merchant.id}`
                    if (idx === 0) {
                        return (
                            <tr key={key}>
                                <td rowSpan={rowspan}>
                                    <Link to={`/admin/routes/${entry.id}`} title="View route">
                                        {entry.id}
                                    </Link>
                                </td>
                                <td rowSpan={rowspan}>{entry.date}</td>
                                <td>{merchant.name}</td>
                                <td>{merchant.pallets}</td>
                                <td rowSpan={rowspan}>{entry.courier}</td>
                                <td rowSpan={rowspan}>{entry.driver}</td>
                                <td rowSpan={rowspan}>
                                    <DropdownButton
                                        pullRight
                                        bsSize="small"
                                        title="Actions"
                                        id="merchant-routes-dropdown"
                                    >
                                        {moment(entry.date).isSameOrAfter(moment().format('LL')) && (
                                            <MenuItem onClick={() => this.onConfirmCancel(entry.id)}>
                                                Cancel route
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={() =>
                                                this.openModal(
                                                    entry,
                                                    INVOICE_TYPES_COURIER,
                                                    this.props.courierInvoiceArticles,
                                                )
                                            }
                                        >
                                            Manual invoice for courier
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                this.openModal(
                                                    entry,
                                                    INVOICE_TYPES_MERCHANT,
                                                    this.props.merchantInvoiceArticles,
                                                    entry.merchants,
                                                )
                                            }
                                        >
                                            Manual invoice for merchant
                                        </MenuItem>
                                    </DropdownButton>
                                </td>
                            </tr>
                        )
                    }
                    return (
                        <tr key={key}>
                            <td>{merchant.name}</td>
                            <td>{merchant.pallets}</td>
                        </tr>
                    )
                })
            })
    }

    render() {
        const { pickup, terminals } = this.props
        const { displayFeedback } = this.state.cancelRouteFeedback

        if (!pickup.stops) {
            return null
        }

        return (
            <div>
                <HtmlTitle title="Recurring pickups" />
                {this.renderCancelRouteConfirmation()}
                <Loader color="#bfbfbf" loaded={this.props.loaded}>
                    <Grid fluid>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <h1>{pickup.name}</h1>
                                <Panel className="bump-down">
                                    <Row>
                                        <Col md={3}>
                                            <dl>
                                                <dt>Day of week</dt>
                                                <dd>{pickup.dayOfWeek}</dd>
                                            </dl>
                                            <dl>
                                                <dt>Interval</dt>
                                                <dd>
                                                    {formatTime(pickup.stops[0].startTime)}
                                                    {' - '}
                                                    {formatTime(pickup.stops[pickup.stops.length - 1].endTime)}
                                                </dd>
                                            </dl>
                                        </Col>
                                        <Col md={3}>
                                            <dl>
                                                <dt>Courier</dt>
                                                <dd>{pickup.courierName}</dd>
                                            </dl>
                                            <dl>
                                                <dt>Agreed vehicle type</dt>
                                                <dd>
                                                    {coalesce(pickup.prettyVehicleType, pickup.vehicleTypeFreeText)}
                                                </dd>
                                            </dl>
                                        </Col>
                                        <Col md={3}>
                                            <dl>
                                                <dt>Remuneration (courier)</dt>
                                                <dd>
                                                    {pickup.remuneration} {pickup.currency}
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt>Pallet capacity used</dt>
                                                <dd>
                                                    {pickup.requiredPallets}
                                                    {' / '}
                                                    {pickup.maxPallets}
                                                </dd>
                                            </dl>
                                        </Col>
                                        <Col md={3}>
                                            <dl>
                                                <dt>Driver</dt>
                                                <dd>{pickup.driverName}</dd>
                                            </dl>
                                            <dl>
                                                <dt>Vehicle utilisation</dt>
                                                <dd>{parseInt(pickup.vehicleUtilisationFactor * 100)}%</dd>
                                            </dl>
                                        </Col>
                                    </Row>
                                    {pickup.linehaulCost && pickup.linehaulCost > 0 ?
                                        <Row>
                                            <Col md={12}>
                                                <dl>
                                                    <dt>Cost allocated to linehaul</dt>
                                                    <dd>{pickup.linehaulCost} {pickup.currency} (The total cost is {pickup.remuneration} {pickup.currency}. {pickup.linehaulCost} {pickup.currency} is allocated to linehaul and {pickup.remuneration-pickup.linehaulCost} {pickup.currency} is allocated to merchant)</dd>
                                                </dl>
                                            </Col>
                                            
                                        </Row>
                                        : null}
                                </Panel>

                                <Panel>
                                    <Row>
                                        <Col xs={8}>
                                            <RecurringRouteMap
                                                height={500}
                                                recurringPickup={pickup}
                                                terminals={terminals}
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <dl>
                                                <dt>Pickups</dt>
                                                <dd>{renderStops(pickup.stops)}</dd>
                                            </dl>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={8} mdOffset={2}>
                                {displayFeedback ? this.renderRouteCancelFeedback() : null}
                                <Panel>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="rowgroup">#</th>
                                                <th>Date</th>
                                                <th scope="row">Merchant</th>
                                                <th>Actual pallets</th>
                                                <th scope="rowgroup">Courier</th>
                                                <th>Driver</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>{this.renderPickupHistory(this.props.recurringPickupHistory)}</tbody>
                                    </Table>
                                    <CreateInvoiceModal
                                        invoiceRecipientId={this.state.selectedRoute}
                                        show={this.state.selectedRoute != null}
                                        createInvoiceItem={this.createInvoiceItem}
                                        close={() => this.setState({ selectedRoute: null })}
                                        invoiceTypes={this.state.invoiceTypes}
                                        invoiceArticles={this.state.invoiceArticles}
                                        invoiceRecipientOptions={this.state.invoiceRecipientOptions}
                                        prefill={this.state.prefill}
                                    />
                                </Panel>
                            </Col>
                        </Row>
                    </Grid>
                </Loader>
            </div>
        )
    }
}
RecurringPickupHandler.propTypes = {
    dispatch: PropTypes.func,
    router: PropTypes.object,
    params: PropTypes.object,
    actions: PropTypes.object,
    terminalActions: PropTypes.object,
    loaded: PropTypes.bool,
    pickup: PropTypes.object,
    terminals: PropTypes.array,
    recurringPickupHistory: PropTypes.array,
    courierInvoiceArticles: PropTypes.array,
    merchantInvoiceArticles: PropTypes.array,
}

const mapStateToProps = ({
    recurringPickups: { loaded, selected: pickup, recurringPickupHistory },
    terminals,
    invoiceSettings: {
        articles: { courier: courierInvoiceArticles, merchant: merchantInvoiceArticles },
    },
}) => ({
    loaded,
    pickup,
    terminals: terminals.terminals,
    recurringPickupHistory,
    courierInvoiceArticles,
    merchantInvoiceArticles,
})

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    actions: bindActionCreators(actions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPickupHandler)
