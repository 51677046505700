import * as types from '../constants/actionTypes'
import CollectionApi from '../utils/collection-point-api'
import auth from '../auth'
import { hd } from '../utils/hd'
import {
    createMerchantPricePoint,
    createMerchantSchedule,
    deleteMerchantPricePoints,
    deleteMerchantSchedule,
    getMerchant,
    getMerchantPricePoints,
    getMerchantSchedules,
} from '../utils/merchant-webapi'
import {
    createCollectionPointIntervals,
    deleteCollectionPoint,
    deleteCollectionPointIntervals,
    editCollectionPointPostalCodeZoneTransits,
    getCollectionPointIntervals,
    getCollectionPointPostalCodeZones,
    getCollectionPointPostalCodeZoneTransits,
    saveInjectionTerminal,
    saveInjectionTerminalAndFulfillmentTime,
    saveOrderFulfillmentTime,
} from '../utils/collection-point-webapi'
import { handleError } from '../utils/handle-error'
import { getPostalcodeZones } from '../utils/postal-code-webapi'
import { getTerminals } from '../utils/terminal-webapi'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../utils/role'

export const transitsLoaded = (transits, cid) => ({
    type: types.TRANSIT_LIST_LOADED,
    status: 200,
    transits,
    cid,
})

export const buyerLoaded = (buyer) => ({
    type: types.BUYER_LOADED,
    status: 200,
    buyer,
})

export const cpZonesLoaded = (cpzones, cid) => ({
    type: types.CP_ZONES_LOADED,
    status: 200,
    cpzones,
    cid,
})

export const pricePointsLoaded = (response, collectionId) => ({
    type: types.CP_PRICE_POINTS_LOADED,
    status: 200,
    response,
    collectionId,
})

export const listDataLoaded = (pcodes, warehouses) => ({
    type: types.LIST_DATA_LOADED,
    status: 200,
    pcodes,
    warehouses,
})

export const addTransitsData = (route, cid) => ({
    type: types.TRANSIT_ADD_DATA,
    status: 200,
    route,
    cid,
})

export const saveTransitsRoute = (cid) => ({
    type: types.TRANSIT_SAVE_DATA,
    status: 200,
    cid,
})

export const removeTransitsData = (cid, index) => ({
    type: types.TRANSIT_REMOVE_DATA,
    status: 200,
    index,
    cid,
})

export const removeUndoTransitsData = (cid) => ({
    type: types.TRANSIT_REMOVE_UNDO,
    status: 200,
    cid,
})

export const savingTransitData = (cid) => ({
    type: types.TRANSIT_DATA_STORE,
    status: 200,
    cid,
})

export const savingTransitSuccess = (cid) => ({
    type: types.TRANSIT_DATA_SAVED,
    status: 200,
    cid,
})

export const transitError = (error) => ({
    type: types.TRANSIT_ERROR,
    status: error.status,
    error,
})

export const transitReset = (cid) => ({
    type: types.TRANSIT_RESET,
    status: 200,
    cid,
})

export const transitResetData = (cid) => ({
    type: types.TRANSIT_RESET_DATA,
    status: 200,
    cid,
})

export const intervalsLoaded = (intervals, cid) => ({
    type: types.INTERVALS_LOADED,
    status: 200,
    intervals,
    cid,
})

export const pickupScheduleLoaded = (pickups, cid) => ({
    type: types.PICKUP_SCHEDULE_LOADED,
    status: 200,
    pickups,
    cid,
})

export const startLoading = () => ({
    type: types.LOADING_START,
    status: 200,
})

export const stopLoading = () => ({
    type: types.LOADING_STOP,
    status: 200,
})

export const injectionTerminalUpdated = (collectionPointId, injectionTerminal) => ({
    type: types.COLLECTION_POINT_INJECTION_TERMINAL_UPDATED,
    collectionPointId,
    injectionTerminal,
})

export const orderFulfillmentTimeUpdated = (collectionPointId, orderFulfillmentTime) => ({
    type: types.COLLECTION_POINT_ORDER_FULFILLMENT_TIME_UPDATED,
    collectionPointId,
    orderFulfillmentTime,
})

export const storeTransitsData = (cid, transits) => (dispatch) =>
    editCollectionPointPostalCodeZoneTransits(cid, transits)
        .then((response) => {
            dispatch(transitsLoaded(response, cid))
            dispatch(savingTransitSuccess(cid))
        })
        .catch((error) => {
            dispatch(transitResetData(cid))
            dispatch(transitError(error))
        })

export const fetchListData = () => (dispatch) =>
    Promise.all([getPostalcodeZones(), getTerminals()]).then(([pcodes, warehouses]) => {
        dispatch(listDataLoaded(pcodes, warehouses))
    })

export const fetchCPZones = (cid) => (dispatch) =>
    getCollectionPointPostalCodeZones(cid)
        .then((zones) => {
            dispatch(cpZonesLoaded(zones, cid))
        })
        .catch(handleError)

export const fetchPricePoints = (merchantId, collectionPointId) => (dispatch) =>
    getMerchantPricePoints(merchantId, collectionPointId).then((pricePoints) =>
        dispatch(pricePointsLoaded(pricePoints, collectionPointId)),
    )

export const storePostalCodeZones = (merchantId, collectionPointId, postalCodeZones, newPostalCodeData) => (dispatch) =>
    CollectionApi.storePostalCodeZones(collectionPointId, postalCodeZones)
        .then((zones) => {
            if (zones && zones.length > 0) {
                dispatch(cpZonesLoaded(newPostalCodeData, collectionPointId))
            }

            setTimeout(() => dispatch(fetchPricePoints(merchantId, collectionPointId)), 1000)
        })
        .catch(handleError)

export const removeCPZones = (cid, rzones, newPostalCodeData) => (dispatch) =>
    CollectionApi.removeCPZones(cid, rzones)
        .then(() => {
            dispatch(cpZonesLoaded(newPostalCodeData, cid))
        })
        .catch(handleError)

export const newPostalCodeZones = (postalCodeZones, collectionPointId) => (dispatch) => {
    dispatch(cpZonesLoaded(postalCodeZones, collectionPointId))
}

export const storeInjectionTerminal = (cid, injectionTerminal) => (dispatch) =>
    saveInjectionTerminal(cid, injectionTerminal)
        .then(() => dispatch(injectionTerminalUpdated(cid, injectionTerminal)))
        .catch(handleError)

export const storeOrderFulfillmentTime = (cid, orderFulfillmentTime) => (dispatch) =>
    saveOrderFulfillmentTime(cid, orderFulfillmentTime)
        .then(() => dispatch(orderFulfillmentTimeUpdated(cid, orderFulfillmentTime)))
        .catch(handleError)

export const storeInjectionTerminalAndFulfillmentTime = (cid, injectionTerminal, orderFulfillmentTime) => (dispatch) =>
    saveInjectionTerminalAndFulfillmentTime(cid, injectionTerminal, orderFulfillmentTime)
        .then(() => {
            dispatch(injectionTerminalUpdated(cid, injectionTerminal))
            dispatch(orderFulfillmentTimeUpdated(cid, orderFulfillmentTime))
        })
        .catch(handleError)

export const fetchTransitsData = (cid) => (dispatch) =>
    getCollectionPointPostalCodeZoneTransits(cid)
        .then((transits) => dispatch(transitsLoaded(transits, cid)))
        .catch(handleError)

export const fetchCPData = (bid) => (dispatch) => {
    dispatch(startLoading())
    return getMerchant(bid).then((buyer) => {
        dispatch(buyerLoaded(buyer))
        buyer.collectionPoints.forEach((collectionPoint) => {
            const fn = (id) => {
                dispatch(fetchCPZones(id))
                dispatch(fetchTransitsData(id))
                dispatch(stopLoading())
            }

            const { id } = collectionPoint
            if (auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)) {
                getMerchantPricePoints(buyer.id, id).then((pp) => {
                    dispatch(pricePointsLoaded(pp, id))
                    fn(id)
                })
            } else {
                fn(id)
            }
        })
    })
}

export const createPricePoints = (merchantId, pricePoints) => (dispatch) =>
    createMerchantPricePoint(merchantId, pricePoints).then(() =>
        setTimeout(() => dispatch(fetchPricePoints(merchantId, hd(pricePoints.collectionPointIds))), 1000),
    )

export const removePricePoints = (merchantId, collectionPointId, pricePoints) => (dispatch) =>
    deleteMerchantPricePoints(merchantId, pricePoints).then(() =>
        setTimeout(() => dispatch(fetchPricePoints(merchantId, collectionPointId)), 1000),
    )

export const fetchCPIntervals = (cid) => (dispatch) =>
    getCollectionPointIntervals(cid).then((intervals) => dispatch(intervalsLoaded(intervals, cid)))

export const removeCPIntervals = (cid, intervalIds) => (dispatch) =>
    deleteCollectionPointIntervals(intervalIds)
        .then(() => dispatch(fetchCPIntervals(cid)))
        .then((intervals) => dispatch(intervalsLoaded(intervals, cid)))

export const saveCPIntervals = (cid, intervalData) => (dispatch) =>
    createCollectionPointIntervals(cid, intervalData).then((intervals) => dispatch(intervalsLoaded(intervals, cid)))

export const fetchCPSchedules = (buyerId, cid) => (dispatch) =>
    getMerchantSchedules(buyerId, cid).then((schedules) => dispatch(pickupScheduleLoaded(schedules, cid)))

export const savePickupSchedule = (buyerId, pickupData) => (dispatch) =>
    createMerchantSchedule(buyerId, pickupData).then(() =>
        dispatch(fetchCPSchedules(buyerId, pickupData.collectionPointId)),
    )

export const removeCPSchedules = (ids, buyerId, cid) => (dispatch) => {
    const promises = ids.map((id) => deleteMerchantSchedule(buyerId, id))
    Promise.all(promises).then(() => setTimeout(() => dispatch(fetchCPSchedules(buyerId, cid)), 1000))
}

export const removeCollectionPoint = (merchantId, collectionPointId) => (dispatch) =>
    deleteCollectionPoint(collectionPointId)
        .then(() => dispatch(fetchCPData(merchantId)))
        .catch(handleError)
