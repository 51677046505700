import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
import auth from '../auth'
import getMenuItems from '../utils/get-menu-items'
import * as userActions from '../actions/user'

const renderNavItem = (item) => {
    if (!item.visible) {
        return null
    }

    return (
        <LinkContainer key={item.route} to={`/admin/${item.route}`}>
            <NavItem>{item.text}</NavItem>
        </LinkContainer>
    )
}

const renderNavDropdown = (item, i) => {
    if (!item.children.some(({ visible }) => visible)) {
        return null
    }

    return (
        <NavDropdown key={item.text} eventKey={i} title={item.text} id={`nav-dropdown-${i}`}>
            {item.children
                .filter(({ visible }) => visible)
                .map((c) => (
                    <LinkContainer key={c.route} to={`/admin/${c.route}`}>
                        <MenuItem>{c.text}</MenuItem>
                    </LinkContainer>
                ))}
        </NavDropdown>
    )
}

const renderNavigationItem = (item, i) => {
    if (item.children == null || item.children.length === 0) {
        return renderNavItem(item, i)
    }

    return renderNavDropdown(item, i)
}

const AdminHandler = ({ router, children, currentUser, fetchingCurrentUser, actions }) => {
    if (!auth.isLoggedIn()) {
        auth.setRequestedPage(window.location.pathname)
        router.push('/login')
        return null
    }
    if (!currentUser && !fetchingCurrentUser) {
        actions.fetchCurrentUser()
    }
    const user = currentUser || { firstName: '', lastName: '', email: '' }
    const menuItems = getMenuItems()
    const logout = (e) => {
        e.preventDefault()
        actions.logout()
        auth.clear()
        router.push('/login')
    }

    return (
        <div>
            <Navbar collapseOnSelect className="navbar-wide">
                <Navbar.Header>
                    <Navbar.Brand>Budbee</Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav>{menuItems.map(renderNavigationItem)}</Nav>
                    <Nav pullRight>
                        <NavDropdown eventKey={1} title={`${user.firstName} ${user.lastName}`} id="profile-dropdown">
                            <MenuItem header>{user.email}</MenuItem>
                            <MenuItem onClick={logout}>Log out</MenuItem>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {children}
        </div>
    )
}

AdminHandler.propTypes = {
    router: PropTypes.object.isRequired,
    children: PropTypes.node,
    currentUser: PropTypes.object.isRequired,
    fetchingCurrentUser: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
}

AdminHandler.contextTypes = {
    router() {},
}

const mapDispatchToProps = () => (dispatch) => ({
    actions: {
        fetchCurrentUser: () => userActions.fetchCurrentUser()(dispatch),
        logout: () => userActions.logout()(dispatch),
    },
})

const mapStateToProps = ({ users }) => ({ currentUser: users.current, fetchingCurrentUser: users.fetchingCurrentUser })
export default connect(mapStateToProps, mapDispatchToProps)(AdminHandler)
