import React from 'react'
import {
    Alert,
    Row,
    Col,
    Panel,
    Button,
    Checkbox,
    FormGroup,
    Label,
    FormControl,
    DropdownButton,
    MenuItem,
    ListGroup,
    ListGroupItem,
    ControlLabel,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CountrySelect from '../common/country-select'
import CitySelect from '../common/city-select'
import { getBanners, createBanner, updateBanner, getActiveBanners } from '../../utils/banners-webapi'

const CLEAR_BANNER = {
    localMessage: '',
    englishMessage: '',
    color: 'INFO',
    name: '',
    active: false,
}

class TrackingBanners extends React.Component {
    constructor(props) {
        super(props)
        this.onCreateBanner = this.onCreateBanner.bind(this)
        this.onSaveBanner = this.onSaveBanner.bind(this)
        this.state = {
            country: null,
            city: null,
            openBannerId: null,
            editing: null,
            createNew: false,
            banners: [],
            activeBanners: [],
            bannerActiveOnCountry: null,
            bannerActiveOnCity: null,
            message: null,
        }
    }

    componentDidMount() {
        this.fetchActiveBanners()
    }

    fetchActiveBanners() {
        return getActiveBanners().then((r) => this.setState({ activeBanners: r.banners }))
    }

    onUpdateBannerFilter(country, city) {
        if (country === null && city === null) {
            this.setState({ country: null, city: null, editing: null, createNew: false, openBannerId: null })
            return this.fetchActiveBanners()
        }
        this.setState({ country, city, editing: null, createNew: false, openBannerId: null, activeBanners: [] })
        const countryToQuery = city == null ? country : null
        return getBanners(countryToQuery, city).then((r) => this.setState({ ...r }))
    }

    renderForm() {
        const { country, city } = this.state
        return (
            <Panel>
                <form>
                    <Row>
                        <FormGroup>
                            <Col md={6}>
                                <ControlLabel>Select Country</ControlLabel>
                                <CountrySelect
                                    value={country}
                                    onSelect={(option) => this.onUpdateBannerFilter(option ? option.value : null, null)}
                                />
                            </Col>
                            <Col md={6}>
                                <ControlLabel>Select City</ControlLabel>
                                <CitySelect
                                    disabled={!country}
                                    multi={false}
                                    value={city}
                                    onSelect={(option) =>
                                        this.onUpdateBannerFilter(country, option ? option.value : null)
                                    }
                                    countryFilter={country}
                                />
                            </Col>
                        </FormGroup>
                    </Row>
                </form>
            </Panel>
        )
    }

    renderBanners() {
        /* eslint-disable jsx-a11y/label-has-for */
        const { banners, openBannerId } = this.state
        const renderItem = (banner) => (
            <ListGroupItem key={banner.id} style={{ marginLeft: 0 }} onClick={() => this.onSelectBanner(banner)}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {openBannerId === banner.id ? <strong>{banner.name}</strong> : <span>{banner.name}</span>}
                    {banner.active ? <Label>Active</Label> : null}
                </div>
            </ListGroupItem>
        )
        return <ListGroup>{banners.map(renderItem)}</ListGroup>
    }

    static renderAlertIcon() {
        return (
            <span>
                <div className="color-sample-alert" />
                Alert
            </span>
        )
    }

    static renderInfoIcon() {
        return (
            <span>
                <div className="color-sample-info" />
                Info
            </span>
        )
    }

    renderBanner() {
        const { editing } = this.state
        return (
            <Row>
                <ControlLabel>Name</ControlLabel>
                <FormControl
                    type="text"
                    value={editing.name}
                    onChange={(e) => this.setState({ editing: { ...editing, name: e.target.value } })}
                />
                <ControlLabel>Banner Colour</ControlLabel>
                <br />
                <DropdownButton
                    id="banner-color"
                    title={
                        editing.color === 'INFO' ? TrackingBanners.renderInfoIcon() : TrackingBanners.renderAlertIcon()
                    }
                >
                    <MenuItem onSelect={() => this.setState({ editing: { ...editing, color: 'ALERT' } })}>
                        {TrackingBanners.renderAlertIcon()}
                    </MenuItem>
                    <MenuItem onSelect={() => this.setState({ editing: { ...editing, color: 'INFO' } })}>
                        {TrackingBanners.renderInfoIcon()}
                    </MenuItem>
                </DropdownButton>
                <Row style={{ marginTop: 5 }}>
                    <Col md={6}>
                        <ControlLabel>Local Language</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            value={editing.localMessage}
                            style={{ height: 150, resize: 'none' }}
                            maxLength={140}
                            onChange={(e) => this.setState({ editing: { ...editing, localMessage: e.target.value } })}
                        />
                    </Col>
                    <Col md={6}>
                        <ControlLabel>English</ControlLabel>
                        <FormControl
                            componentClass="textarea"
                            style={{ height: 150, resize: 'none' }}
                            maxLength={140}
                            value={editing.englishMessage}
                            onChange={(e) => this.setState({ editing: { ...editing, englishMessage: e.target.value } })}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>Messages have a 140 character limit!</Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                    <Col md={12}>
                        <ControlLabel>Activate Banner</ControlLabel>
                        <Checkbox
                            title="Active"
                            checked={editing.active}
                            onChange={(e) => this.setState({ editing: { ...editing, active: e.target.checked } })}
                        />
                        {this.renderActivationWarning()}
                    </Col>
                </Row>
            </Row>
        )
    }

    renderActivationWarning() {
        const { editing, bannerActiveOnCountry, city, country, bannerActiveOnCity, openBannerId } = this.state
        if (!editing.active) {
            return null
        }
        if (!bannerActiveOnCountry && !bannerActiveOnCity) {
            return null
        }
        const warn = (message) => <Alert bsStyle="warning">{message}</Alert>

        const prettyCity = this.prettyCity(city)
        const prettyCountry = this.prettyCountry(country)

        if (bannerActiveOnCity && city && openBannerId !== bannerActiveOnCity.id) {
            return warn(
                `Activating this banner will deactivate the current banner "${bannerActiveOnCity.name}" for ${prettyCity}`,
            )
        }
        if (bannerActiveOnCountry && !city && openBannerId !== bannerActiveOnCountry.id) {
            return warn(
                `Activating this banner will deactivate the current banner "${bannerActiveOnCountry.name}" for ${prettyCountry}`,
            )
        }
        if (
            bannerActiveOnCountry &&
            city &&
            openBannerId !== bannerActiveOnCountry.id &&
            (!bannerActiveOnCity || bannerActiveOnCity.id !== openBannerId)
        ) {
            return warn(
                `Activating this banner will override the current banner "${bannerActiveOnCountry.name}" for ${prettyCity}`,
            )
        }
        return null
    }

    renderExistingBannerOptions() {
        return (
            <Row style={{ marginTop: 20 }}>
                <Button bsStyle="primary" type="submit" onClick={this.onSaveBanner}>
                    Save
                </Button>
            </Row>
        )
    }

    renderNewBannerOptions() {
        return (
            <Row style={{ marginTop: 20 }}>
                <Button id="create-banner" pullRight bsStyle="primary" onClick={this.onCreateBanner}>
                    Create
                </Button>
            </Row>
        )
    }

    onSelectBanner(banner) {
        const { openBannerId } = this.state
        if (banner.id === openBannerId) {
            return
        }
        this.setState({
            editing: {
                localMessage: banner.localMessage,
                englishMessage: banner.englishMessage,
                color: banner.color,
                active: banner.active,
                name: banner.name,
            },
            openBannerId: banner.id,
            createNew: false,
        })
    }

    onSaveBanner() {
        const { editing, country, city, openBannerId } = this.state
        const request = {
            ...editing,
            country,
            city,
        }
        updateBanner(openBannerId, request)
            .then((r) => this.setState({ ...r, message: { style: 'success', content: 'Saved!' } }))
            .catch(() => this.setState({ message: { style: 'danger', content: 'Failed to Save Banner!' } }))
    }

    onCreateBanner() {
        const { editing, country, city } = this.state
        const request = {
            ...editing,
            country,
            city,
        }
        createBanner(request)
            .then((r) =>
                this.setState({ ...r, editing: CLEAR_BANNER, message: { style: 'success', content: 'Created!' } }),
            )
            .catch(() => this.setState({ message: { style: 'danger', content: 'Failed to Create Banner!' } }))
    }

    createNewBanner() {
        this.setState({
            editing: {
                localMessage: '',
                englishMessage: '',
                color: 'INFO',
                name: '',
                active: false,
            },
            openBannerId: null,
            createNew: true,
        })
    }

    renderFilteredBanners() {
        const { openBannerId, createNew, editing, country } = this.state
        return (
            <Row>
                <Col md={4}>
                    <Button
                        disabled={!country}
                        style={{ marginBottom: 15, width: '100%' }}
                        bsStyle="primary"
                        onClick={() => this.createNewBanner()}
                    >
                        Create New Banner
                    </Button>
                    {this.renderBanners()}
                </Col>
                <Col md={7}>
                    {editing ? this.renderBanner() : null}
                    {openBannerId !== null ? this.renderExistingBannerOptions() : null}
                    {createNew ? this.renderNewBannerOptions() : null}
                </Col>
            </Row>
        )
    }

    onJumpToBanner(banner) {
        this.onUpdateBannerFilter(banner.country, banner.city).then(() => this.onSelectBanner(banner))
    }

    renderActiveBanners() {
        const { activeBanners } = this.state
        const bannerRow = (banner) => (
            <tr key={banner.id}>
                <td>{banner.country}</td>
                <td>{banner.city}</td>
                <td>
                    <a
                        onKeyDown={() => this.onJumpToBanner(banner)}
                        onClick={() => this.onJumpToBanner(banner)}
                        role="link"
                        tabIndex={0}
                        style={{ cursor: 'pointer' }}
                    >
                        {banner.name}
                    </a>
                </td>
            </tr>
        )
        return (
            <Row>
                <Col md={10} mdOffset={2}>
                    <h3>Current Active Banners</h3>
                    <br />
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Country</th>
                                <th>City</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>{activeBanners.map(bannerRow)}</tbody>
                    </table>
                </Col>
            </Row>
        )
    }

    prettyCountry(countryCode) {
        return this.props.countries
            .filter((country) => country.id === countryCode)
            .map((country) => country.englishExonym)[0]
    }

    prettyCity(cityId) {
        return this.props.cities.filter((city) => city.id === cityId).map((city) => city.englishExonym)[0]
    }

    renderMessage() {
        const { message } = this.state
        if (!message) {
            return null
        }
        const dismiss = () => this.setState({ message: null })
        return (
            <Alert bsStyle={message.style} onDismiss={dismiss}>
                {message.content}
            </Alert>
        )
    }

    render() {
        const { activeBanners } = this.state
        return (
            <div className="container-fluid">
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderForm()}
                    </Col>
                </Row>
                {this.renderMessage()}
                <hr />
                {activeBanners.length !== 0 ? this.renderActiveBanners() : this.renderFilteredBanners()}
            </div>
        )
    }
}

TrackingBanners.propTypes = {
    countries: PropTypes.array,
    cities: PropTypes.array,
}

const mapStateToProps = (state) => ({
    countries: state.regionalSettings.countries,
    cities: state.regionalSettings.cities,
})

export default connect(mapStateToProps)(TrackingBanners)
