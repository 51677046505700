/**
 * Enum for country codes.
 * Uses: country standard ISO 3166-2
 */

export const SWEDEN = 'SE'
export const FINLAND = 'FI'
export const DENMARK = 'DK'
export const NETHERLANDS = 'NL'
export const BELGIUM = 'BE'
export const GERMANY = 'DE'
export const ALAND = 'AX'
export const CZECH = 'CZ'
export const NORWAY = 'NO'

export default () => [
    { value: SWEDEN, label: 'Sverige' },
    { value: FINLAND, label: 'Finland' },
    { value: DENMARK, label: 'Denmark' },
    { value: NETHERLANDS, label: 'Netherlands' },
    { value: BELGIUM, label: 'Belgium' },
    { value: GERMANY, label: 'Germany' },
    { value: ALAND, label: 'Åland' },
    { value: CZECH, label: 'Czech Republic' },
    { value: NORWAY, label: 'Norge' },
]
