import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    ButtonGroup,
    Glyphicon,
    Modal,
    Panel,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormControl,
} from 'react-bootstrap'
import Parcel from './parcel'
import Article from './article'
import { sealBox } from '../../../utils/parcel-webapi'
import { resetLockerCodeForPackage, extendExpiryDateForPackage } from '../../../utils/lockers-webapi'
import { partition } from '../../../utils/partition'
import { handleError } from '../../../utils/handle-error'
import { currentStatus } from '../../../utils/parcel-util'
import Confirm from '../../confirm'

/**
 * Render Parcels (both Scannable Parcels and Non-scannable "Articles")
 */
export default class Parcels extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showResetLockerCodeConfirmation: false,
            showExtendTimeConfirmation: false,
            showSealBoxConfirmation: false,
        }
    }

    showSealBoxConfirmation = (packageId, parcelId) => {
        this.setState({
            showSealBoxConfirmation: true,
            sealBoxPackageId: packageId,
            sealBoxParcelId: parcelId,
        })
    }

    sealBoxForParcel = (parcelId) => {
        sealBox(parcelId)
            .then(this.props.fetchOrder)
            .catch(handleError)
    }

    resetCode = (packageId) => {
        resetLockerCodeForPackage(packageId)
            .then(() => {
                setTimeout(() => this.props.fetchOrder(), 1000)
            })
            .catch((error) => {
                if (error.status === 409) {
                    error.text().then((result) => alert(result))
                } else {
                    handleError(error)
                }
            })
    }

    confirmResetLockerCode = (packageId) => {
        this.setState({
            showResetLockerCodeConfirmation: true,
            resetLockerPackageId: packageId,
        })
    }

    canResetCode = (parcel) => {
        if (!parcel.parcelStatusUpdates || parcel.parcelStatusUpdates.length === 0) {
            return false
        }
        const latestStatus = currentStatus(parcel)
        return latestStatus.status !== 'NotStarted' && latestStatus.status !== 'ReturnedToMerchant'
    }

    canExtendExpiryTime = (parcel) => {
        if (!parcel.parcelStatusUpdates || parcel.parcelStatusUpdates.length === 0) {
            return false
        }
        const latestStatus = currentStatus(parcel)
        return latestStatus.status !== 'PickedUp'
    }

    extendExpiryTime = (packageId, days) => {
        extendExpiryDateForPackage(packageId, days)
            .then(() => {
                this.setState({
                    showExtendTimeConfirmation: false,
                    extendTimePackageId: null,
                    extendTimeAmountOfDays: 1,
                })
                this.props.fetchOrder()
            })
            .catch(handleError)
    }

    openExtendTimeModal = (packageId) => {
        this.setState({
            showExtendTimeConfirmation: true,
            extendTimePackageId: packageId,
            extendTimeAmountOfDays: 1,
        })
    }

    confirmExtendTime = (packageId, days) => {
        this.setState({
            showExtendTimeConfirmation: true,
            extendTimeAmountOfDays: days,
            extendTimePackageId: packageId,
        })
    }

    renderSealBoxConfirmation() {
        const { showSealBoxConfirmation, sealBoxPackageId, sealBoxParcelId } = this.state
        return (
            <Confirm
                title="Are you sure you want to seal the box?"
                show={showSealBoxConfirmation}
                onClose={() => {
                    this.setState({
                        showSealBoxConfirmation: false,
                        sealBoxPackageId: null,
                        sealBoxParcelId: null,
                    })
                }}
                onConfirm={() => this.sealBoxForParcel(sealBoxParcelId)}
            >
                Are you sure you want to seal the box for parcel {sealBoxPackageId}?
            </Confirm>
        )
    }

    renderResetLockerCodeConfirmation() {
        const { showResetLockerCodeConfirmation, resetLockerPackageId } = this.state
        return (
            <Confirm
                title="Are you sure you want to reset locker code?"
                show={showResetLockerCodeConfirmation}
                onClose={() => {
                    this.setState({
                        resetLockerPackageId: null,
                        showResetLockerCodeConfirmation: false,
                    })
                }}
                onConfirm={() => this.resetCode(resetLockerPackageId)}
            >
                By utilizing this feature you reset the box assignment and parcel status. This means that the parcel
                will be reassigned to same box as it was previously in and the parcel status will be set to delivered.
                The consumer will be notified of the delivery and will be able to see a new code on order.{' '}
                <b>This action is irreversible.</b>
                <br />
                Are you sure you want to reset locker code for package with id {resetLockerPackageId}?
            </Confirm>
        )
    }

    renderExtendTimeConfirmation() {
        const { showExtendTimeConfirmation, extendTimeAmountOfDays, extendTimePackageId } = this.state
        const close = () => {
            this.setState({
                showExtendTimeConfirmation: false,
                extendTimeAmountOfDays: 1,
                extendTimePackageId: null,
            })
        }
        const onChange = (e) => {
            this.setState({
                extendTimeAmountOfDays: Math.max(0, Math.min(Number(e.target.value), 7)),
            })
        }
        return (
            <Modal show={showExtendTimeConfirmation} onHide={close}>
                <ModalHeader closeButton>Extend expiry time</ModalHeader>
                <ModalBody>
                    <p>Choose how many days you want to extend the expiry time with</p>
                    <span>Number of days</span>
                    <FormControl type="number" value={extendTimeAmountOfDays} onChange={onChange} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={close}>Close</Button>
                    <Button onClick={() => this.extendExpiryTime(extendTimePackageId, extendTimeAmountOfDays)}>
                        Extend time
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    renderParcel = (parcel) => {
        const {
            merchant,
            deliveryAddress,
            recalledParcel,
            fetchScanEvents,
            loadingScanEvents,
            loadingScanEventsFailed,
            barcodeScanEvents,
        } = this.props
        const header = (
            <span title={`id:${parcel.id}`}>
                {parcel.assignedToBox ? (
                    <ButtonGroup className="pull-right">
                        <Button
                            type="button"
                            bsSize="small"
                            onClick={() => this.confirmResetLockerCode(parcel.packageId)}
                            disabled={!this.canResetCode(parcel)}
                        >
                            <Glyphicon glyph="repeat" /> Reset code
                        </Button>
                        <Button
                            type="button"
                            bsSize="small"
                            onClick={() => this.confirmExtendTime(parcel.packageId)}
                            disabled={!this.canExtendExpiryTime(parcel)}
                        >
                            <Glyphicon glyph="time" /> Extend expiry time
                        </Button>
                        <Button
                            type="button"
                            bsSize="small"
                            onClick={() => this.showSealBoxConfirmation(parcel.packageId, parcel.id)}
                            disabled={parcel.sealedBox}
                        >
                            <Glyphicon glyph="lock" /> Seal box
                        </Button>
                    </ButtonGroup>
                ) : null}
                Parcel: {parcel.packageId}
            </span>
        )

        return (
            <Panel header={header} key={parcel.id}>
                <Parcel
                    parcel={parcel}
                    merchant={merchant}
                    deliveryAddress={deliveryAddress}
                    recalledParcel={recalledParcel}
                    fetchScanEvents={fetchScanEvents}
                    loadingScanEvents={loadingScanEvents}
                    loadingScanEventsFailed={loadingScanEventsFailed}
                    barcodeScanEvents={barcodeScanEvents}
                />
            </Panel>
        )
    }

    renderArticle = (article) => {
        const { deliveryAddress } = this.props
        const header = <span>Article: {article.title} </span>

        return (
            <Panel header={header} key={article.id}>
                <Article deliveryAddress={deliveryAddress} article={article} />
            </Panel>
        )
    }

    render() {
        const { parcels: allParcels } = this.props
        if (allParcels === null) {
            return null
        }

        const [parcels, articles] = partition(allParcels, (p) => p.title == null)

        return (
            <div>
                {parcels.map(this.renderParcel)}
                {articles.map(this.renderArticle)}
                {this.renderSealBoxConfirmation()}
                {this.renderResetLockerCodeConfirmation()}
                {this.renderExtendTimeConfirmation()}
            </div>
        )
    }
}

Parcels.propTypes = {
    merchant: PropTypes.object.isRequired,
    deliveryAddress: PropTypes.object.isRequired,
    parcels: PropTypes.array.isRequired,
    recalledParcel: PropTypes.func.isRequired,
    fetchOrder: PropTypes.func.isRequired,
    fetchScanEvents: PropTypes.func.isRequired,
    loadingScanEvents: PropTypes.bool.isRequired,
    loadingScanEventsFailed: PropTypes.bool.isRequired,
    barcodeScanEvents: PropTypes.array.isRequired,
}
