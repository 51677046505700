import {
    FETCHING_POSTAL_CODE_ZONES,
    POSTAL_CODE_ZONES_LOADED,
    POSTAL_CODE_ZONES_FAILED_TO_LOAD,
} from '../constants/actionTypes'
import postalCodeZonesState from './initialState/postal-code-zones-state'
import { mapSort, sortStrings } from '../utils/sorting'
import { prop } from '../utils/prop'

export default function PostCodeReducer(state = postalCodeZonesState, action) {
    switch (action.type) {
        case FETCHING_POSTAL_CODE_ZONES:
            return {
                ...state,
                loading: true,
                postalCodeZones: [],
            }

        case POSTAL_CODE_ZONES_LOADED:
            return {
                ...state,
                error: null,
                postalCodeZones: action.postalCodeZones.sort(mapSort(sortStrings, prop('title'))),
            }

        case POSTAL_CODE_ZONES_FAILED_TO_LOAD:
            return {
                ...state,
                error: action.error,
                postalCodeZones: [],
            }

        default:
            return state
    }
}
