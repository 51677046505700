import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Form, Checkbox, Button } from 'react-bootstrap'
import './control-panel.css'

export default class ControlPanel extends Component {
    constructor() {
        super()

        this.state = {
            showHelp: false,
        }
    }

    renderHelp() {
        const { showHelp } = this.state

        if (!showHelp) {
            return (
                <Button bsStyle="link" onClick={() => this.setState({ showHelp: true })}>
                    Show help
                </Button>
            )
        }

        return (
            <div>
                <p>
                    <strong>ETA</strong> - Estimated time Driver will arrive at a stop
                </p>
                <p>
                    <strong>ATA</strong> - Actual time Driver arrived at a stop
                </p>
                <p>
                    <strong>ST</strong> (Service Time) - Time it takes for a Driver to perform delivery
                </p>
                <p>
                    <strong>DT</strong>
                    (Drive Time) - Estimated / Actual time it takes for Driver to travel between two stops
                </p>
            </div>
        )
    }

    renderAnalysis() {
        const { analysis, onToggleDisplayRoute, onToggleDisplayHeatmap } = this.props

        if (!analysis) {
            return null
        }

        return (
            <Form horizontal>
                <Checkbox defaultChecked onChange={(e) => onToggleDisplayRoute(e.target.checked)}>
                    Display Routed route
                </Checkbox>
                <Checkbox defaultChecked onChange={(e) => onToggleDisplayHeatmap(e.target.checked)}>
                    Display Driver heatmap
                </Checkbox>

                <br />

                {analysis.driverName && (
                    <p>
                        <strong>Driver</strong>: {analysis.driverName}
                    </p>
                )}
                {analysis.driverLevel && (
                    <p>
                        <strong>Driver Level</strong>: {analysis.driverLevel}
                    </p>
                )}
            </Form>
        )
    }

    render() {
        const { routeId, date } = this.props

        return (
            <div className="control-panel">
                <div className="control-panel-inner">
                    <h3>Route {routeId}</h3>
                    <small>{moment(date).format('YYYY-MM-DD')}</small>

                    {this.renderAnalysis()}
                    {this.renderHelp()}
                </div>
            </div>
        )
    }
}

ControlPanel.propTypes = {
    routeId: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    analysis: PropTypes.object,
    onToggleDisplayRoute: PropTypes.func.isRequired,
    onToggleDisplayHeatmap: PropTypes.func.isRequired,
}
