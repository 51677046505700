import React from 'react'

export default class TimeoutComponent extends React.Component {
    constructor(props) {
        super(props)
        this.intervals = []
        this.timeouts = []
    }

    componentWillUnmount() {
        this.intervals.forEach(clearInterval)
        this.timeouts.forEach(clearTimeout)
    }

    setInterval() {
        return this.intervals.push(setInterval(...arguments))
    }

    setTimeout() {
        return this.timeouts.push(setTimeout(...arguments))
    }
}
