import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import Select from 'react-select'
import { Grid, Col, ControlLabel, FormGroup } from 'react-bootstrap'
import { canFetchCouriers } from '../../../utils/role'
import TerminalSelect from '../../common/terminal-select'
import routeTypes from '../../../utils/route-types-for-filtering'
import { flexStyle } from '../../../utils/styles'
import '../../../../styles/less/recurring-routes.less'

const toOption = ({ id: value, name: label }) => ({ value, label })
const toMerchantOption = (merchant) => ({ value: merchant, label: merchant })
const toCountryOption = (country) => ({ value: country.id, label: country.id })
const routeTypeOptions = routeTypes()

export default class FilterHeader extends Component {
    renderHeader() {
        return (
            <Col style={flexStyle} className="filter-header">
                {this.renderSelectCountries()}
                {this.renderSelectMerchants()}
                {canFetchCouriers() ? this.renderSelectCourier() : null}
                {this.renderSelectTerminal()}
                {this.renderSelectRouteType()}
            </Col>
        )
    }

    renderSelectCourier() {
        return (
            <Col md={12}>
                <FormGroup controlId="courier">
                    <ControlLabel>Courier</ControlLabel>
                    <Select
                        componentClass="select"
                        placeholder="Select courier..."
                        value={this.props.selected}
                        onChange={this.props.onCourierChange}
                        options={this.props.couriers.map(toOption)}
                        clearable
                        searchable
                    />
                </FormGroup>
            </Col>
        )
    }

    renderSelectCountries() {
        const { countries, selectedCountries, onCountryChange } = this.props

        return (
            <Col md={12}>
                <FormGroup controlId="merchants">
                    <ControlLabel>Countries</ControlLabel>
                    <Select
                        disabled={false}
                        placeholder="Select countries..."
                        value={selectedCountries}
                        options={countries.map(toCountryOption)}
                        onChange={onCountryChange}
                        allowCreate={false}
                        ignoreCase
                        searchable
                        clearable
                        multi
                    />
                </FormGroup>
            </Col>
        )
    }

    renderSelectMerchants() {
        const { merchants, selectedMerchants, onMerchantChange } = this.props

        return (
            <Col md={12}>
                <FormGroup controlId="merchants">
                    <ControlLabel>Merchants</ControlLabel>
                    <Select
                        disabled={false}
                        placeholder="Select merchants.."
                        value={selectedMerchants}
                        options={merchants.map(toMerchantOption)}
                        onChange={onMerchantChange}
                        allowCreate={false}
                        ignoreCase
                        searchable
                        clearable
                        multi
                    />
                </FormGroup>
            </Col>
        )
    }

    renderSelectTerminal() {
        const { selectedTerminal, onTerminalChange } = this.props
        return (
            <Col md={12}>
                <FormGroup controlId="terminal">
                    <ControlLabel>Terminal</ControlLabel>
                    <TerminalSelect value={selectedTerminal} onSelect={onTerminalChange} />
                </FormGroup>
            </Col>
        )
    }

    renderSelectRouteType() {
        const { selectedRouteType, onRouteTypeChange } = this.props

        return (
            <Col md={12}>
                <FormGroup controlId="routeTypes">
                    <ControlLabel>Route type</ControlLabel>
                    <Select
                        disabled={false}
                        placeholder="Select route type.."
                        value={selectedRouteType}
                        options={routeTypeOptions}
                        onChange={onRouteTypeChange}
                        allowCreate={false}
                        ignoreCase
                    />
                </FormGroup>
            </Col>
        )
    }

    render() {
        return (
            <div>
                <Loader color="#BFBFBF" loaded={this.props.loaded}>
                    <Grid fluid>{this.renderHeader()}</Grid>
                </Loader>
            </div>
        )
    }
}

FilterHeader.propTypes = {
    loaded: PropTypes.bool,
    couriers: PropTypes.array,
    merchants: PropTypes.array,
    countries: PropTypes.array,
    selected: PropTypes.object,
    selectedMerchants: PropTypes.array,
    selectedCountries: PropTypes.array,
    selectedTerminal: PropTypes.object,
    onCourierChange: PropTypes.func,
    onMerchantChange: PropTypes.func,
    onCountryChange: PropTypes.func,
    onTerminalChange: PropTypes.func,
    onRouteTypeChange: PropTypes.func,
    selectedRouteType: PropTypes.object,
}
