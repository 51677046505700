import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.holidays-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('holidays')

export const getHolidays = () => webapi().get()

export const createHoliday = (request) => webapi().post(request)

export const editCities = (id, cities) =>
    webapi()
        .path(id)
        .path('cities')
        .put({ cities })

export const unlockHolidayForMerchant = (id, merchantId, cityId) =>
    webapi()
        .path(id)
        .path('unlock')
        .put({ merchantId, cityId })

export const updateUnblockedLockerCities = (id, addedCities, removedCities) =>
    webapi()
        .path(id)
        .path('unblocked-locker-cities')
        .put({ addedCities, removedCities })

export const resetHolidayLockForMerchant = (id, merchantId, cityId) =>
    webapi()
        .path(id)
        .path('lock')
        .put({ merchantId, cityId })

export const deleteHoliday = (id) =>
    webapi()
        .path(id)
        .delete()
