import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('sub-terminals')

export const getSubTerminals = (terminalCode) =>
    webapi()
        .path(terminalCode)
        .get()
