import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Button, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import Alert from '../alert'
import { setParcelStatus } from '../../utils/parcel-webapi'
import { isBulkSplit, hasPallets, AVAILABLE_STATUSES } from '../../utils/parcel-util'

const BACKORDER_WARNING = 'WARNING! BACKORDERING PARCELS CAN NOT BE REVERSED!'

const getLatestStatusUpdate = (p) => {
    if (p.parcelStatusUpdates !== null && p.parcelStatusUpdates.length > 0) {
        return p.parcelStatusUpdates[p.parcelStatusUpdates.length - 1].status
    }
    return '0'
}

const findChildParcels = (pallets, palletId) => {
    const pallet = pallets.find((x) => x.palletId === palletId)
    return pallet ? pallet.parcels : []
}

const findAndUpdateChildParcelStatuses = (palletsWithParcels, pallet) =>
    findChildParcels(palletsWithParcels, pallet.id).map((parcel) => ({ ...parcel, newStatus: pallet.newStatus }))

const findParcelsWithSameStatus = (parcels, parcel) => {
    return parcels.findIndex((differentStatusParcels) => {
        return differentStatusParcels.every((x) => x.newStatus === parcel.newStatus)
    })
}

const parcelAndChildParcels = (parcel, childParcels) => {
    return [parcel, ...childParcels]
}

const addParcelAndChildParcelsToOtherParcelsWithSameStatus = (
    parcels,
    sameStatusParcelsIndex,
    parcel,
    childParcels,
) => [...parcels[sameStatusParcelsIndex], parcel, ...childParcels]

const addParcelToOtherParcelsWithSameStatus = (parcels, sameStatusParcelsIndex, parcel) => [
    ...parcels[sameStatusParcelsIndex],
    parcel,
]

const parcelsWithSameStatus = (parcels, sameStatusParcelsIndex, updatedParcels) => {
    return parcels.map((parcel, index) => (index === sameStatusParcelsIndex ? updatedParcels : parcel))
}

const groupParcelsByStatus = (parcelsToUpdate, palletsWithParcels) => {
    return parcelsToUpdate.reduce((parcels, parcel) => {
        const sameStatusParcelsIndex = findParcelsWithSameStatus(parcels, parcel)
        const parcelsWithSameStatusExists = sameStatusParcelsIndex > -1

        if (parcel.updateForChildParcels) {
            const childParcels = findAndUpdateChildParcelStatuses(palletsWithParcels, parcel)

            if (!parcelsWithSameStatusExists) {
                return [...parcels, parcelAndChildParcels(parcel, childParcels)]
            }
            const updatedParcels = addParcelAndChildParcelsToOtherParcelsWithSameStatus(
                parcels,
                sameStatusParcelsIndex,
                parcel,
                childParcels,
            )
            return parcelsWithSameStatus(parcels, sameStatusParcelsIndex, updatedParcels)
        }

        if (!parcelsWithSameStatusExists) {
            return [...parcels, [parcel]]
        }
        const updatedParcels = addParcelToOtherParcelsWithSameStatus(parcels, sameStatusParcelsIndex, parcel)
        return parcelsWithSameStatus(parcels, sameStatusParcelsIndex, updatedParcels)
    }, [])
}

export default class ParcelStatusModal extends Component {
    constructor(...args) {
        super(...args)

        const [props] = args
        this.state = {
            error: null,
            parcels: props.parcels.map((parcel) => ({
                ...parcel,
                change: false,
                status: getLatestStatusUpdate(parcel),
                updateForChildParcels: false,
            })),
            showCodeRequired: false,
            enterCode: false,
            code: '',
            invalidCode: false,
        }
    }

    onUpdateParcelStatus = () => {
        const { codeRequired } = this.props
        const { parcels, code, enterCode } = this.state
        const anyDelivered = parcels.some(({ newStatus }) => newStatus === 'Delivered')

        if (!enterCode && codeRequired && anyDelivered) {
            this.setState({ showCodeRequired: true })
            return
        }

        this.updateParcelStatus(code)
    }

    updateParcelStatus(code) {
        const { palletsWithParcels } = this.props
        const { parcels } = this.state
        const parcelsToUpdate = parcels.filter((parcel) => parcel.change === true)

        const groupedParcelsByStatus = groupParcelsByStatus(parcelsToUpdate, palletsWithParcels)

        const parcelStatusUpdates = groupedParcelsByStatus
            .map((groupedParcels) => {
                const parcelIds = groupedParcels.map((parcel) => parcel.id)
                const firstParcel = groupedParcels.find((parcel) => parcel !== undefined)
                return firstParcel ? setParcelStatus(parcelIds, firstParcel.newStatus, code) : null
            })
            .filter((parcel) => parcel)

        Promise.all(parcelStatusUpdates)
            .then(() => {
                if (this.props.onUpdate) {
                    this.props.onUpdate()
                }
                this.resetAndHide()
            })
            .catch((e) => {
                // 409: Conflict - invalid code
                if (e.status === 409) {
                    this.setState({ invalidCode: true })
                    return
                }

                console.error(e)
                this.setState({ error: 'Could not update statuses, try again' })
            })
    }

    renderParcels(parcels) {
        const rows = parcels.map((parcel) => {
            const currentStatus = getLatestStatusUpdate(parcel)
            return (
                <tr key={parcel.id}>
                    <td>{parcel.packageId ? parcel.packageId : parcel.title}</td>
                    <td>{currentStatus}</td>
                    <td>{currentStatus === 'Backordered' ? null : this.renderStatusSelect(parcel)}</td>
                    {isBulkSplit(parcel) ? (
                        <td style={{ textAlign: 'center' }}>
                            <input type="checkbox" checked={parcel.change} onChange={(e) => this.onCheck(e, parcel)} />
                        </td>
                    ) : (
                        <td />
                    )}
                </tr>
            )
        })
        return rows
    }

    onCheck(event, pallet) {
        const { parcels } = this.state
        const { checked } = event.target

        const newParcels = parcels.map((parcel) =>
            parcel.id === pallet.id ? { ...parcel, updateForChildParcels: checked } : parcel,
        )

        this.setState({ parcels: newParcels })
    }

    renderStatusSelect(parcel) {
        const statuses = AVAILABLE_STATUSES.filter((status) => status.applicableToType.includes(parcel.type))

        return (
            <select
                className="form-control"
                id="parcel-status"
                onChange={(e) => this.onParcelStatusChange(e, parcel.id)}
            >
                <option value={0}>Select status</option>
                {statuses.map((s) => {
                    if (s.value === getLatestStatusUpdate(parcel)) {
                        return null
                    }
                    return (
                        <option key={s.value} value={s.value}>
                            {s.label}
                        </option>
                    )
                })}
            </select>
        )
    }

    parcelsHaveBeenUpdated() {
        const { parcels } = this.state
        return parcels.filter((parcel) => parcel.change === true).length > 0
    }

    onParcelStatusChange(e, parcelToUpdateId) {
        e.preventDefault()
        const { parcels } = this.state

        const parcelToUpdate = parcels.find((parcel) => parcelToUpdateId === parcel.id)
        const updatedStatus = e.target.value

        const updatedParcel = {
            ...parcelToUpdate,
            newStatus: updatedStatus,
            change: updatedStatus !== '0',
        }
        const updatedParcels = parcels.map((parcel) => (parcel.id === parcelToUpdate.id ? updatedParcel : parcel))

        this.setState({ parcels: updatedParcels })
    }

    onCodeChange = (e) => {
        this.setState({ code: e.target.value })
    }

    goBack = () => {
        if (this.state.enterCode) {
            this.setState({ enterCode: false, showCodeRequired: true, code: '', invalidCode: false })
            return
        }
        if (this.state.showCodeRequired) {
            this.setState({ enterCode: false, showCodeRequired: false, code: '', invalidCode: false })
            return
        }

        this.resetAndHide()
    }

    resetAndHide = () => {
        const { parcels } = this.state
        this.setState({
            parcels: parcels.map((p) => ({
                ...p,
                change: false,
                status: getLatestStatusUpdate(p),
                updateForChildParcels: false,
            })),
        })
        this.props.onHide()
    }

    deliverWithoutCode = () => {
        this.updateParcelStatus(null)
    }

    deliverWithCode = () => {
        this.setState({ enterCode: true, showCodeRequired: false })
    }

    renderBody() {
        if (this.state.enterCode) {
            return (
                <Modal.Body>
                    <h3>Delivery requires Bank ID identification</h3>
                    <FormGroup>
                        <ControlLabel>Code:</ControlLabel>
                        <FormControl autoFocus value={this.state.code} onChange={this.onCodeChange} />
                    </FormGroup>
                    {this.state.invalidCode ? <Alert type="danger" message="Invalid code" /> : null}
                </Modal.Body>
            )
        }

        if (this.state.showCodeRequired) {
            return (
                <Modal.Body>
                    <h3>Delivery requires Bank ID identification</h3>
                    <Alert
                        type="danger"
                        message="This delivery requires a code from the consumer in order to perform the delivery."
                    />
                    <h4>Deliver without code</h4>
                    <p>
                        Use if the driver already delivered the parcel without following the instructions in the driver
                        app. Write a QC# and notify Merchant success team.
                    </p>
                    <h4>Deliver with code</h4>
                    <p>
                        Use if the driver has received the code from the consumer but it says wrong consumer code in the
                        driver application. No report has to be made.
                    </p>
                </Modal.Body>
            )
        }

        return (
            <Modal.Body>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th>Package Id / Title</th>
                            <th>Current Status</th>
                            <th>New Status</th>
                            {hasPallets(this.state.parcels) ? <th>Apply to pallet parcels</th> : null}
                        </tr>
                    </thead>
                    <tbody>{this.renderParcels(this.props.parcels)}</tbody>
                </Table>
            </Modal.Body>
        )
    }

    renderFooter() {
        if (this.state.showCodeRequired) {
            return (
                <Modal.Footer>
                    <Button className="pull-left" onClick={this.goBack}>
                        Cancel
                    </Button>
                    <Button bsStyle="danger" onClick={this.deliverWithoutCode}>
                        Deliver without code
                    </Button>
                    <Button bsStyle="success" onClick={this.deliverWithCode}>
                        Deliver with code
                    </Button>
                </Modal.Footer>
            )
        }

        return (
            <Modal.Footer>
                <p>
                    {this.state.parcels.filter((parcel) => parcel.newStatus === 'Backordered').length > 0
                        ? BACKORDER_WARNING
                        : null}
                </p>
                <br />
                <Alert type="danger" message={this.state.error} />
                <Button onClick={this.goBack}>Cancel</Button>
                <Button bsStyle="primary" disabled={!this.parcelsHaveBeenUpdated()} onClick={this.onUpdateParcelStatus}>
                    Set Status
                </Button>
            </Modal.Footer>
        )
    }

    render() {
        return (
            <Modal show={this.props.show} title="Parcel Status" onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Parcel Status</Modal.Title>
                </Modal.Header>
                {this.renderBody()}
                {this.renderFooter()}
            </Modal>
        )
    }
}

ParcelStatusModal.propTypes = {
    parcels: PropTypes.array.isRequired,
    palletsWithParcels: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    codeRequired: PropTypes.bool.isRequired,
}
