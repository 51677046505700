import {
    FETCHING_BUYER,
    FETCHING_BUYERS,
    BUYERS_LOADED,
    BUYER_LOADED,
    BUYERS_ZONES_LOADED,
    BUYER_SELECTED,
    PRICE_TIERS_LOADED,
    TIER_ENUMS_LOADED,
    UPDATING_MERCHANT,
    MERCHANT_UPDATED,
    MERCHANT_FAILED_TO_UPDATE,
    BUYERS_FAILED_TO_LOAD,
} from '../constants/actionTypes'
import BuyerApi from '../utils/buyer-api'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import {
    getMerchantPriceTiers,
    createMerchantPriceTier,
    deleteMerchantPriceTier,
    getMerchantPostalCodeZones,
    getMerchants,
    getMerchant,
    modifyPriceTiers as modifyMerchantPriceTiers,
    editMerchant,
} from '../utils/merchant-webapi'
import { getTierEnumMappings } from '../utils/settings-api'
import { constant } from '../utils/constant'

const fetchingBuyers = () => ({
    type: FETCHING_BUYERS,
})

const fetchingBuyer = () => ({
    type: FETCHING_BUYER,
})

const buyerLoaded = (buyer) => ({
    type: BUYER_LOADED,
    status: 200,
    buyer,
})

const buyersLoaded = (buyers) => ({
    type: BUYERS_LOADED,
    status: 200,
    buyers,
})

const loadingBuyersFailed = () => ({
    type: BUYERS_FAILED_TO_LOAD,
})

const zonesLoaded = (response) => ({
    type: BUYERS_ZONES_LOADED,
    status: 200,
    response,
})
const tierEnumsLoaded = (response) => ({
    type: TIER_ENUMS_LOADED,
    status: 200,
    response,
})

const priceTiersLoaded = (priceTierType, response) => ({
    type: PRICE_TIERS_LOADED,
    status: 200,
    response,
    priceTierType,
})

const buyerSelected = (id) => ({
    type: BUYER_SELECTED,
    status: 200,
    id,
})

const updatingMerchant = () => ({
    type: UPDATING_MERCHANT,
})

const merchantUpdated = (merchant) => ({
    type: MERCHANT_UPDATED,
    merchant,
})

const merchantFailedToUpdate = (e) => ({
    type: MERCHANT_FAILED_TO_UPDATE,
    status: e.status,
    error: e.statusText,
})

export const fetchMerchant = (id) => (dispatch) => {
    dispatch(fetchingBuyer())
    return getMerchant(id)
        .then(compose(dispatch, buyerLoaded))
        .catch(handleError)
}

export const fetchMerchants = () => (dispatch) => {
    dispatch(fetchingBuyers())
    return getMerchants()
        .then(compose(dispatch, buyersLoaded))
        .catch((error) => {
            dispatch(loadingBuyersFailed())
            return handleError(error)
        })
}

export const fetchZones = (merchantId) => (dispatch) =>
    BuyerApi.getZones(merchantId)
        .then(compose(dispatch, zonesLoaded))
        .catch(handleError)

export const fetchZonesWebapi = (merchantId) => (dispatch) =>
    getMerchantPostalCodeZones(merchantId)
        .then(compose(dispatch, zonesLoaded))
        .catch(handleError)

export const fetchPriceTiers = (type, merchantId) => (dispatch) =>
    BuyerApi.getPriceTiers(type, merchantId)
        .then((res) => dispatch(priceTiersLoaded(type, res)))
        .catch(handleError)

export const fetchPriceTiersWebapi = (type, merchantId) => (dispatch) =>
    getMerchantPriceTiers(type, merchantId)
        .then((res) => dispatch(priceTiersLoaded(type, res)))
        .catch(handleError)

export const savePriceTier = (type, merchantId, data) => (dispatch) =>
    createMerchantPriceTier(type, merchantId, data)
        .then(() => dispatch(fetchPriceTiersWebapi(type, merchantId)))
        .catch(handleError)

export const deletePriceTier = (type, merchantId, tierId) => (dispatch) =>
    deleteMerchantPriceTier(type, merchantId, tierId)
        .then(() => dispatch(fetchPriceTiersWebapi(type, merchantId)))
        .catch(handleError)

export const selectBuyer = (id) => (dispatch) => dispatch(buyerSelected(id))

export const modifyPriceTiers = (merchantId, type, modifications) => (dispatch) =>
    modifyMerchantPriceTiers(merchantId, modifications)
        .then(() => dispatch(fetchPriceTiersWebapi(type, merchantId)))
        .catch(handleError)

export const fetchTierMappings = () => (dispatch) =>
    getTierEnumMappings()
        .then(compose(dispatch, tierEnumsLoaded))
        .catch(handleError)

export const updateMerchant = (id, merchant) => (dispatch) => {
    dispatch(updatingMerchant())
    return editMerchant(id, merchant)
        .then(compose(dispatch, merchantUpdated, constant(merchant)))
        .catch((error) => {
            compose(dispatch, merchantFailedToUpdate, handleError)(error)
            throw error
        })
}
