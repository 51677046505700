import Api from './api'
import dev from './dev'
import auth from '../auth'

const baseUrl = '/api/parcels'
const contentType = dev('application/json', 'application/vnd.budbee.parcels-v1+json')

export default {
    getScanningLog(parcelId) {
        return new Api(baseUrl)
            .path(parcelId)
            .path('scanning-log')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    addStatusToParcels(parcels, status) {
        const json = {
            parcels,
            status,
        }

        return new Api(baseUrl)
            .path('status')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .put(json)
    },

    getParcelsAssignedToRouteId(routeId) {
        return new Api(baseUrl)
            .path('route')
            .path(routeId)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    generateShippingLabel(parcelId) {
        return new Api(baseUrl)
            .path(parcelId)
            .path('shipping-label')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .get()
    },
}
