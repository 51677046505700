export const GENERAL_SETTINGS = 'GENERAL_SETTINGS'
export const INVOICING_SETTINGS = 'INVOICING_SETTINGS'
export const PER_HOUR = 'PER_HOUR'
export const PER_STOP = 'PER_STOP'
export const DISTANCE = 'DISTANCE'
export const STOP_BONUS_PER_ROUTE = 'STOP_BONUS_PER_ROUTE'
export const SUCCESSFUL_DELIVERY_BONUS_PER_ROUTE = 'SUCCESSFUL_DELIVERY_BONUS_PER_ROUTE'
export const RATING_FIVE_STAR_PER_STOP = 'RATING_FIVE_STAR_PER_STOP'
export const PHONE_BONUS = 'PHONE_BONUS_PER_ROUTE'
export const VEHICLE_INSPECTION_TIME_PER_ROUTE = 'VEHICLE_INSPECTION_TIME_PER_ROUTE'
export const OTHER_FIXED_COSTS_PER_ROUTE = 'OTHER_FIXED_COSTS_PER_ROUTE'
export const SUPPORT_BONUS = 'SUPPORT_BONUS_PER_ROUTE'
export const MULTIPLE_PARCELS = 'MULTIPLE_PARCELS'
export const MULTIPLE_LARGE_PARCELS = 'MULTIPLE_LARGE_PARCELS'
export const MULTIPLE_PARCELS_MULTI_CONSUMER_STOP = 'MULTIPLE_PARCELS_MULTI_CONSUMER_STOP'
export const STAFFING_MULTIPLIER = 'STAFFING_MULTIPLIER'
export const VACATION_MULTIPLIER = 'VACATION_MULTIPLIER'
export const SOCIAL_FEE_MULTIPLIER = 'SOCIAL_FEE_MULTIPLIER'
export const CAMPAIGN_MULTIPLIER = 'CAMPAIGN_MULTIPLIER'
export const OTHER_MULTIPLIER = 'OTHER_MULTIPLIER'
export const MIN_DEBIT_PER_ROUTE = 'MIN_DEBIT_PER_ROUTE'
export const MIN_HOURS_PER_ROUTE = 'MIN_HOURS_PER_ROUTE'
export const MIN_DEBIT_PER_HOUR = 'MIN_DEBIT_PER_HOUR'
export const PER_CONSUMER_STOP = 'PER_CONSUMER_STOP'
const BASE = 'BASE'
const BONUS = 'BONUS'
const FIXED = 'FIXED'
const MULTIPLIER = 'MULTIPLIER'
const MULTIPLICATIVE = 'MULTIPLICATIVE'
const INCREMENTAL = 'INCREMENTAL'
const ADDITIVE = 'ADDITIVE'
const ELECTRIC_CAR = 'ELECTRIC_CAR'
const ENVIRONMENTAL_CAR = 'ENVIRONMENTAL_CAR'
const DISTRIBUTION = 'DISTRIBUTION'

export const OPERATOR_GROUPS = {
    ADDITIVE,
    MULTIPLICATIVE,
}

export const COMPENSATION_GROUPS = {
    BASE,
    BONUS,
    FIXED,
    MULTIPLIER,
}

export const PRICE_TYPES = {
    FIXED,
    INCREMENTAL,
}

export const VEHICLE_TYPE = {
    ELECTRIC_CAR,
    ENVIRONMENTAL_CAR,
}

const MONDAY_TO_FRIDAY = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']
const SATURDAY_TO_SUNDAY = ['SATURDAY', 'SUNDAY']
const SATURDAY = ['SATURDAY']

export const defaultCompensationModels = {
    'Swedish Standard model': {
        title: 'Swedish Hourly Compensation - Standard',
        description: 'Swedish 30 days payment term-model',
        tiers: [
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 27200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 27700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 28800,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 29400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 30000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 32500,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 31700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 32200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33300,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34500,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 37000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 28700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 29200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 30300,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 30900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 31500,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34800,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 35400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 36000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 37000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
        ],
    },
    'Swedish 60 days model': {
        title: 'Swedish Hourly Compensation - 60 Days',
        description: 'Swedish 60 days payment term-model',
        tiers: [
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 27900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 28400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 29500,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 30100,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 30700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 32400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 32900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34600,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 35200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 37700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 29400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 29900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 31000,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 31600,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 32200,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 33900,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 34400,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 35500,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 36100,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 36700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 37700,
                currency: 'SEK',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY_TO_SUNDAY,
            },
        ],
    },
    'Netherlands 30 days model': {
        title: 'Netherland Hourly Compensation - 30 Days',
        description: 'Netherland 30 days payment term-model',
        tiers: [
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 2850,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 2900,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3070,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3140,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3220,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3340,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3780,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3830,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4000,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4070,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4150,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4270,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3060,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3110,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3280,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3350,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3430,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3550,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: MONDAY_TO_FRIDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 3990,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [5],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4040,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [6],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4210,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [7],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4280,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [8],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4360,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [9],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: PER_HOUR,
                fixedPriceInCents: 0,
                incrementalPriceInCents: 4480,
                currency: 'EUR',
                validFrom: undefined,
                validTo: undefined,
                driverLevels: [10],
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
                daysOfWeek: SATURDAY,
            },
            {
                group: BASE,
                type: DISTANCE,
                validFrom: undefined,
                validTo: undefined,
                currency: 'EUR',
                incrementalPriceInCents: 1540,
                fixedPriceInCents: 0,
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ENVIRONMENTAL_CAR,
            },
            {
                group: BASE,
                type: DISTANCE,
                validFrom: undefined,
                validTo: undefined,
                currency: 'EUR',
                incrementalPriceInCents: 1294,
                fixedPriceInCents: 0,
                routeType: DISTRIBUTION,
                vehicleType: VEHICLE_TYPE.ELECTRIC_CAR,
            },
        ],
    },
}

export const compensationTypes = [
    {
        id: PER_HOUR,
        label: 'Amount Per Hour',
        title: 'hourly compensation',
        price_type: INCREMENTAL,
        price_variable: 'Hour',
        group: BASE,
        operator: ADDITIVE,
        example: 'Applied to the estimated hours of the route. The amount specified is per hour. ',
    },
    {
        id: PER_STOP,
        label: 'Amount Per Stop',
        title: 'stop compensation',
        price_type: INCREMENTAL,
        price_variable: 'Stop',
        group: BASE,
        operator: ADDITIVE,
        example:
            'Applied to the estimated amount of stops per route. ' +
            'Cancelled stops are not included in the compensation.',
    },
    {
        id: PER_CONSUMER_STOP,
        label: 'Amount Per Consumer and Stop',
        title: 'consumer stop compensation',
        price_type: INCREMENTAL,
        price_variable: 'Consumer',
        group: BASE,
        operator: ADDITIVE,
        example:
            'Applied to the amount of consumers per route. ' +
            'Cancelled consumer stops are not included in the compensation.',
    },
    {
        id: DISTANCE,
        label: 'Amount per 100 Kilometer',
        title: 'distance compensation',
        price_type: INCREMENTAL,
        price_variable: '100 Kilometer',
        group: BASE,
        operator: ADDITIVE,
        example: 'Based on the estimated distance of the route. The amount is per 100 km and in the route. ',
    },
    {
        id: STOP_BONUS_PER_ROUTE,
        label: 'Amount Per Stops Range',
        title: 'Stop Bonus Per Route',
        price_variable: 'Stops In Route',
        price_type: FIXED,
        group: BONUS,
        operator: ADDITIVE,
        example:
            'Bonus amount when the number of stops in route is within a bonus threshold.' +
            ' Only non-cancelled consumers stops are counted.',
    },
    {
        id: SUCCESSFUL_DELIVERY_BONUS_PER_ROUTE,
        label: 'Amount Per Successful Delivery Range',
        title: 'Successful Delivery Bonus Per Route',
        price_variable: 'Successfull Deliveries In Route',
        price_type: FIXED,
        group: BONUS,
        operator: ADDITIVE,
        example:
            'Bonus amount when the number of successful deliveries in route is within a bonus threshold.' +
            ' Only non-cancelled deliveries and returns are counted.',
    },
    {
        id: RATING_FIVE_STAR_PER_STOP,
        label: 'Amount Per Top Rating And Stop',
        title: '5-Star rating Bonus per Stop',
        price_variable: 'Top Rating And Stop',
        price_type: INCREMENTAL,
        group: FIXED,
        operator: ADDITIVE,
        example: 'This bonus amount per stop for having at least one 5 star rating on a stop in a route.',
    },
    {
        id: MULTIPLE_PARCELS,
        label: 'Amount Per Multiple Parcel',
        title: 'Multiple Parcel Bonus Per Stop',
        price_variable: 'Parcels',
        price_type: INCREMENTAL,
        group: BONUS,
        operator: ADDITIVE,
        example:
            'Bonus amount per stop when the number of parcels is above the bonus threshold.' +
            ' Any Multiple Heavy Parcel cannot also account for this bonus.',
    },
    {
        id: MULTIPLE_LARGE_PARCELS,
        label: 'Amount Per Multiple Heavy Parcel',
        title: 'Multiple Heavy Parcel Bonus Per Stop',
        price_type: INCREMENTAL,
        price_variable: 'Heavy Parcels',
        group: BONUS,
        operator: ADDITIVE,
        example: 'Bonus amount per stop when the number of heavy parcels is above the bonus threshold.',
    },
    {
        id: MULTIPLE_PARCELS_MULTI_CONSUMER_STOP,
        label: 'Amount Per Multiple Parcel For Parcels with Multiple Consumers',
        title: 'Multiple Parcel Bonus Per Stop With Multiple Consumers',
        price_type: INCREMENTAL,
        price_variable: 'Parcels',
        group: BONUS,
        operator: ADDITIVE,
        example:
            'Bonus amount per stop with multiple consumers when the number of parcels is above the bonus threshold.' +
            ' Any Multiple Heavy Parcel cannot also account for this bonus.',
    },
    {
        id: PHONE_BONUS,
        label: 'Amount Per Route',
        title: 'Phone Bonus Per Route',
        price_type: FIXED,
        price_variable: 'Route',
        group: FIXED,
        operator: ADDITIVE,
        example: 'A bonus to compensate for the usage of personal phones.',
    },
    {
        id: SUPPORT_BONUS,
        label: 'Amount Per Route',
        title: 'Support Bonus Per Route',
        price_variable: 'Route',
        price_type: FIXED,
        group: FIXED,
        operator: ADDITIVE,
        example: 'An additional bonus gained for each completed support route.',
    },
    {
        id: VEHICLE_INSPECTION_TIME_PER_ROUTE,
        label: 'Paid Vehicle Inspection Time In Minutes Per Route',
        title: ' Vehicle Inspection Time',
        price_type: FIXED,
        price_variable: 'Route',
        group: FIXED,
        operator: ADDITIVE,
        example: 'Paid time to complete vehicle inspection.',
    },
    {
        id: OTHER_FIXED_COSTS_PER_ROUTE,
        label: 'Other Fixed Costs Per Route',
        title: 'Other Fixed Cost Per Route',
        price_type: FIXED,
        price_variable: 'Route',
        group: FIXED,
        operator: ADDITIVE,
        example: 'Other costs per consistent and modified routes.',
    },
    {
        id: MIN_DEBIT_PER_ROUTE,
        label: 'Amount In Minimum Debit',
        title: 'Minimum debit per route',
        price_variable: 'Compensation',
        price_type: FIXED,
        group: FIXED,
        operator: ADDITIVE,
        example: 'A minimum compensation amount before any multipliers are applied.',
    },
    {
        id: MIN_DEBIT_PER_HOUR,
        label: 'Amount In Minimum Debit / Hour',
        title: 'Minimum debit per Hour',
        price_variable: 'Compensation',
        price_type: FIXED,
        group: FIXED,
        operator: ADDITIVE,
        example: 'A minimum compensation amount / hour before any multipliers are applied.',
    },
    {
        id: MIN_HOURS_PER_ROUTE,
        label: 'Amount Minimum Hours Paid',
        title: 'Minimum hours per route',
        price_variable: 'Minimum Hours',
        price_type: FIXED,
        group: FIXED,
        operator: ADDITIVE,
        example: 'A minimum amount of paid hours. Any additional time is added on the end of the paid time. ',
    },
    {
        id: STAFFING_MULTIPLIER,
        label: 'Multiplication factor %',
        title: 'Staffing multiplier',
        price_variable: 'Compensation',
        price_type: INCREMENTAL,
        group: MULTIPLIER,
        operator: MULTIPLICATIVE,
        example: 'A multiplier of the total compensation of one route. Should only be used for staffing agencies.',
    },
    {
        id: VACATION_MULTIPLIER,
        label: 'Multiplication factor %',
        title: 'Vacation multiplier',
        price_variable: 'Compensation',
        price_type: INCREMENTAL,
        group: MULTIPLIER,
        operator: MULTIPLICATIVE,
        example:
            'A multiplier of the total compensation of one route.' +
            ' Used to support country specific vacation adjustments on staffing agencies.',
    },
    {
        id: SOCIAL_FEE_MULTIPLIER,
        label: 'Multiplication factor %',
        title: 'Social fee multiplier',
        price_variable: 'Compensation',
        price_type: INCREMENTAL,
        group: MULTIPLIER,
        operator: MULTIPLICATIVE,
        example: 'A multiplier of the total compensation. Accounts for social fees that vary between countries.',
    },
    {
        id: CAMPAIGN_MULTIPLIER,
        label: 'Multiplication factor %',
        title: 'Campaign multiplier',
        price_variable: 'Compensation',
        price_type: INCREMENTAL,
        group: MULTIPLIER,
        operator: MULTIPLICATIVE,
        example: 'A multiplier on the total compensation. Used for temporary campaigns.',
    },
    {
        id: OTHER_MULTIPLIER,
        label: 'Multiplication factor %',
        title: 'Other multiplier',
        price_variable: 'Compensation',
        price_type: INCREMENTAL,
        group: MULTIPLIER,
        operator: MULTIPLICATIVE,
        example: 'A multiplier on the total compensation. Used whenever other multiplier do not apply.',
    },
]

export const defaultIfEmpty = (value, defaultValue) => value || defaultValue
export const getTime = (time) => (time.hours && time.minutes ? `${time.hours}:${time.minutes}` : null)
export const getTimeWithSeconds = (time) => {
    const formattedTime = getTime(time)

    if (formattedTime == null) {
        return null
    }

    return `${formattedTime}:00`
}
export const getTimeDuration = (from, to) => (from && to ? `${from} to ${to}` : null)

// Converts a cents value to whole currency e.g 1 EUR = 100 CENTS
export const convertCentsToWhole = (cents) => cents / 100
