import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { handleError } from '../../../utils/handle-error'
import { getTerminalInformation } from '../../../utils/terminal-webapi'
import zoneManagementClient from '../../../utils/zone-management-client'

/**
 * Render Terminal Information
 */
export default class TerminalInformation extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            terminal: {},
            terminalData: {},
        }
    }

    componentDidMount() {
        if (this.props.terminalInformation) {
            this.getTerminalInformation()
        }

        if (
            this.props.deliveryAddress &&
            this.props.deliveryAddress.countryCode &&
            this.props.deliveryAddress.postalCode
        ) {
            this.getTerminalInformationFromAddress(
                this.props.deliveryAddress.countryCode,
                this.props.deliveryAddress.postalCode,
            )
        }
    }

    getTerminalInformation = () => {
        getTerminalInformation(this.props.terminalInformation.id)
            .then((terminal) => {
                this.setState({ terminal })
            })
            .catch(handleError)
    }

    getTerminalInformationFromAddress = (countryCode, postalCode) => {
        const query = `query fetchTerminalFromAddressDetails($country:CountryCode!, $postalCode:PostalCode!) {
            fetchPostalCodeArea(country: $country, postalCode: $postalCode) {
              terminal {
                id
                label
              }
            }
          }`

        const variables = {
            country: countryCode,
            postalCode,
        }

        zoneManagementClient
            .send(query, variables)
            .then((response) => {
                const { data } = response
                if (data.fetchPostalCodeArea && data.fetchPostalCodeArea.terminal) {
                    this.setState({
                        terminalData: {
                            ...data.fetchPostalCodeArea.terminal,
                        },
                    })
                }
            })
            .catch(handleError)
    }

    // eslint-disable-next-line class-methods-use-this
    value(o) {
        return o || <span className="text-muted">N/A</span>
    }

    injectionTerminal() {
        if (this.props.injectionTerminal) {
            return (
                <div>
                    <div className="strong-label">Injection Terminal</div>
                    <span className="text-muted">{this.props.injectionTerminal}</span>
                </div>
            )
        }
        return null
    }

    zoneManagementData() {
        const { terminalData } = this.state
        if (terminalData && terminalData.label) {
            return (
                <div>
                    <div className="strong-label">Zone Management</div>
                    <span className="text-muted">
                        Distribution Terminal: {this.value(terminalData ? terminalData.label : null)}
                    </span>
                </div>
            )
        }

        return null
    }

    render() {
        const { terminal } = this.state
        return (
            <div>
                <div>
                    <div className="strong-label">Distribution Terminal</div>
                    {this.value(terminal ? terminal.name : null)}
                    {this.injectionTerminal()}
                    {this.zoneManagementData()}
                </div>
            </div>
        )
    }
}

TerminalInformation.propTypes = {
    terminalInformation: PropTypes.object.isRequired,
    injectionTerminal: PropTypes.object,
    deliveryAddress: PropTypes.object,
}
