import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'

const badWords = ['budbee']

export default class Password extends Component {
    state = {
        score: 0,
        valid: false,
        password: '',
        feedback: '',
    }

    componentDidMount() {
        import('zxcvbn').then(({ default: zxcvbn }) => {
            this.zxcvbn = zxcvbn
            this.forceUpdate()
        })
    }

    onChange = (e) => {
        const { compareAgainstString } = this.props
        const { value } = e.target
        let { score } = this.state

        let valid
        let feedback

        if (compareAgainstString !== null) {
            valid = compareAgainstString === e.target.value
            feedback = valid ? '' : 'Passwords do not match'
        } else {
            const result = this.zxcvbn(value, badWords.concat(this.props.userInputs))
            ;({ score } = result)
            valid = score > 2
            feedback = valid ? (
                'Good'
            ) : (
                <div>
                    {result.feedback.warning}
                    {result.feedback.warning != null && result.feedback.warning !== '' ? <br /> : null}
                    {result.feedback.suggestions.reduce((acc, next) => [...acc, <br key={next} />, next])}
                </div>
            )
        }

        this.setState(
            {
                password: value,
                score,
                valid,
                feedback,
            },
            () => {
                if (this.props.onChange !== null) {
                    this.props.onChange(this.state)
                }
            },
        )
    }

    zxcvbn = null

    validationState() {
        const { password, score } = this.state
        const { compareAgainstString } = this.props

        if (password.length <= 0) return null

        if (compareAgainstString !== null) {
            return compareAgainstString === password ? 'success' : 'error'
        }

        if (score > 2) return 'success'
        if (score > 1) return 'warning'
        if (score >= 0) return 'error'

        return null
    }

    render() {
        if (this.zxcvbn == null) {
            return null
        }

        const { password, feedback } = this.state

        return (
            <FormGroup controlId="password-input" validationState={this.validationState()}>
                <ControlLabel>{this.props.label}</ControlLabel>
                <FormControl
                    type="password"
                    value={password}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                />
                <FormControl.Feedback />
                <HelpBlock>{feedback}</HelpBlock>
            </FormGroup>
        )
    }
}

Password.propTypes = {
    userInputs: PropTypes.array,
    onChange: PropTypes.func,
    compareAgainstString: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
}

Password.defaultProps = {
    userInputs: [],
    onChange: null,
    compareAgainstString: null,
    label: 'Password',
    placeholder: 'Password',
}
