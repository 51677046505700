import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import { updateCurrentSubscription, cancelCurrentSubscription } from '../../../utils/consumer-webapi'
import { cityAvailableTimeSlots } from '../../../utils/time-slot-webapi'
import { handleError } from '../../../utils/handle-error'
import Confirm from '../../confirm'

/**
 * Render an End Customer
 */
class SubscriptionInline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editing: false,
            availableTimeSlots: null,
            showConfirmModal: false,
            subscription: props.subscription,
        }
    }

    getAvailableTimeSlots = (city) => {
        return cityAvailableTimeSlots(city)
            .then((resp) => {
                this.setState({ availableTimeSlots: resp })
            })
            .catch(handleError)
    }

    valueOrNALabel = (o) => o || <span className="text-muted">N/A</span>

    cancelEditing = () => {
        this.setState({ editing: false, subscription: this.props.subscription })
    }

    toggleEdit = () => {
        this.getAvailableTimeSlots(this.state.subscription.city.value)
        this.setState({ editing: true })
    }

    dayIsSupportedForTimeSlots = (dayUppercase) => {
        const { availableTimeSlots } = this.state
        return availableTimeSlots.supportedDayOfWeeks.includes(dayUppercase)
    }

    isValidSubscription = (subscription) => {
        if (this.dayIsSupportedForTimeSlots('WEEKDAY') && subscription.weekday === null) return false
        if (this.dayIsSupportedForTimeSlots('SATURDAY') && subscription.saturday === null) return false
        return !(this.dayIsSupportedForTimeSlots('SUNDAY') && subscription.sunday === null)
    }

    updateSubscription = () => {
        const { subscription } = this.state

        const subscriptionUpdated = {
            ...subscription,
            weekday: this.dayIsSupportedForTimeSlots('WEEKDAY') ? subscription.weekday : null,
            saturday: this.dayIsSupportedForTimeSlots('SATURDAY') ? subscription.saturday : null,
            sunday: this.dayIsSupportedForTimeSlots('SUNDAY') ? subscription.sunday : null,
            cycle: subscription.cycle,
        }

        if (this.isValidSubscription(subscriptionUpdated)) {
            updateCurrentSubscription(this.props.consumer.consumerId, {
                ...subscriptionUpdated,
                city: subscription.city.value,
            })
                .then(() => this.setState({ subscription: subscriptionUpdated, editing: false }))
                .catch(handleError)
        } else {
            alert('Select the time slot for the available days!')
        }
    }

    onCitySelect = (selected) => {
        const { subscription } = this.state

        if (selected.value !== subscription.city.value) {
            this.getAvailableTimeSlots(selected.value)

            this.setState((prevState) => ({
                subscription: {
                    ...prevState.subscription,
                    city: {
                        value: selected.value,
                        name: selected.label,
                    },
                    weekday: null,
                    saturday: null,
                    sunday: null,
                },
            }))
        }
    }

    timePickerLabel = (start, stop) => `${start} - ${stop}`

    onTimePickerChanges = (day, value) => {
        const selectedInterval = JSON.parse(value.value)
        this.setState((prevState) => ({
            subscription: {
                ...prevState.subscription,
                [day]: {
                    start: selectedInterval.start,
                    stop: selectedInterval.stop,
                },
            },
        }))
    }

    renderTimeSlotPicker = (day) => {
        const { subscription, availableTimeSlots } = this.state

        if (!availableTimeSlots || !this.dayIsSupportedForTimeSlots(day.toUpperCase())) {
            return this.valueOrNALabel(null)
        }

        const selectedOption = {
            label: subscription[day]
                ? this.timePickerLabel(subscription[day].start, subscription[day].stop)
                : 'Select time slot...',
            value: null,
        }

        const options = availableTimeSlots[day.toUpperCase()].map((timeslot, index) => {
            const start = moment(timeslot.start, 'HH:mm:ss').format('HH:mm')
            const stop = moment(timeslot.stop, 'HH:mm:ss').format('HH:mm')
            const label = this.timePickerLabel(start, stop)
            if (label === selectedOption.label) selectedOption.value = index
            return {
                value: JSON.stringify({
                    start,
                    stop,
                }),
                label,
            }
        })
        return (
            <Select
                value={selectedOption}
                options={options}
                clearable={false}
                onChange={(value) => this.onTimePickerChanges(day, value)}
                ignoreCase
            />
        )
    }

    renderTimeSlotInterval = (day) => {
        const { subscription } = this.state
        if (!subscription[day]) {
            return this.valueOrNALabel(null)
        }
        return `${subscription[day].start} - ${subscription[day].stop}`
    }

    renderCitySelect = () => {
        const { supportedCities } = this.props
        const { subscription } = this.state
        return (
            <Select
                placeholder="Select city..."
                value={{ value: subscription.city.value, label: subscription.city.name }}
                options={supportedCities.map((city) => ({
                    value: city.value,
                    label: city.name,
                }))}
                onChange={this.onCitySelect}
                allowCreate={false}
                ignoreCase
                clearable={false}
                multi={false}
            />
        )
    }

    statusOfSubscription = () => {
        const { subscription } = this.state
        const { editable } = this.props
        if (!editable) {
            return { text: 'Dead', style: 'danger' }
        }
        if (subscription.suspended) {
            return { text: 'Suspended', style: 'warning' }
        }
        if (subscription.cancelledAt === null) {
            return { text: 'Active', style: 'success' }
        }
        const stillInEffect = moment(new Date()).isSameOrBefore(subscription.validTo, 'day')
        if (stillInEffect) {
            return { text: 'Cancelled', style: 'default' }
        }
        return { text: 'Dead', style: 'danger' }
    }

    renderEditInfo() {
        const { subscription } = this.state
        const { editable } = this.props
        const status = this.statusOfSubscription()
        return (
            <tr>
                <td style={{ width: 250 }}>{this.renderCitySelect()}</td>
                <td>{this.renderTimeSlotPicker('weekday')}</td>
                <td>{this.renderTimeSlotPicker('saturday')}</td>
                <td>{this.renderTimeSlotPicker('sunday')}</td>
                <td>{subscription.validTo}</td>
                <td>
                    <span className={`label label-${status.style}`}>{status.text}</span>
                </td>
                <td>
                    {editable ? (
                        this.renderActionButton()
                    ) : (
                        <span title="Canceled subscription can not be edited!">...</span>
                    )}
                </td>
            </tr>
        )
    }

    renderInfo = () => {
        const { subscription } = this.state
        const { editable } = this.props
        const status = this.statusOfSubscription()
        return (
            <tr>
                <td>{this.valueOrNALabel(subscription.city.name)}</td>
                <td>{this.renderTimeSlotInterval('weekday')}</td>
                <td>{this.renderTimeSlotInterval('saturday')}</td>
                <td>{this.renderTimeSlotInterval('sunday')}</td>
                <td>{subscription.validTo}</td>
                <td>
                    <span className={`label label-${status.style}`}>{status.text}</span>
                </td>
                <td>
                    {editable ? (
                        this.renderActionButton()
                    ) : (
                        <span title="Canceled subscription can not be edited!">...</span>
                    )}
                </td>
            </tr>
        )
    }

    renderSubscription = () => (this.state.editing ? this.renderEditInfo() : this.renderInfo())

    cancelSubscription = () => {
        const { consumer, onCancelSubscription } = this.props
        cancelCurrentSubscription(consumer.consumerId)
            .then(onCancelSubscription)
            .catch(handleError)
    }

    confirmCancelSubscription = () => {
        this.setState({ showConfirmModal: true })
    }

    renderConfirmCancelSubscription = () => {
        const { showConfirmModal } = this.state
        return (
            <Confirm
                show={showConfirmModal}
                title="Cancel current time slot subscription?"
                bsStyle="danger"
                onClose={() => this.setState({ showConfirmModal: false })}
                onConfirm={this.cancelSubscription}
            >
                Are you sure you want to cancel the current time slot subscriptions for this consumer? This action is
                irreversible!
            </Confirm>
        )
    }

    renderActionButton() {
        const { subscription } = this.props
        const { editing } = this.state
        if (editing) {
            return this.renderEditingActionButtons()
        }
        return (
            <Row>
                <Col md={12}>
                    <Button bsSize="small" bsStyle="primary" onClick={this.toggleEdit}>
                        Edit
                    </Button>
                    {subscription.cancelledAt === null ? (
                        <Button bsSize="small" bsStyle="danger" onClick={this.confirmCancelSubscription}>
                            Cancel
                        </Button>
                    ) : null}
                </Col>
                {this.renderConfirmCancelSubscription()}
            </Row>
        )
    }

    renderEditingActionButtons = () => {
        return (
            <Row>
                <Col md={12}>
                    <Button bsSize="small" bsStyle="primary" onClick={this.updateSubscription}>
                        Save
                    </Button>
                    <Button bsSize="small" bsStyle="warning" onClick={this.cancelEditing}>
                        Cancel Changes
                    </Button>
                </Col>
                {this.renderConfirmCancelSubscription()}
            </Row>
        )
    }

    render() {
        return this.renderSubscription()
    }
}

SubscriptionInline.propTypes = {
    subscription: PropTypes.object,
    supportedCities: PropTypes.array,
    consumer: PropTypes.object,
    onCancelSubscription: PropTypes.func,
    editable: PropTypes.bool,
}

export default SubscriptionInline
