import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import * as actions from '../../actions/owner'
import auth from '../../auth'
import List from './components/list'
import HtmlTitle from '../html-title'
import {
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

class Couriers extends Component {
    componentDidMount() {
        if (!this.view) {
            this.props.router.push('/admin')
            return
        }

        this.props.actions.fetchOwnersWebapi()
    }

    create = auth.hasAnyRole(OPERATIONS_ADMIN)

    edit = auth.hasAnyRole(OPERATIONS_ADMIN)

    view = auth.hasAnyRole(
        OPERATIONS_ADMIN,
        OPERATIONS_COORDINATOR,
        TRAFFIC_CONTROLLER,
        TERMINAL_ADMIN,
        TERMINAL_WORKER,
    )

    invoicing = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    render() {
        return (
            <div>
                <HtmlTitle title="Couriers" />
                <Loader color="#BFBFBF" loaded={!this.props.loading} />
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.create ? (
                                <span>
                                    <Link to="/admin/couriers/create" className="btn btn-primary pull-right">
                                        Create
                                    </Link>
                                </span>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <List
                                couriers={this.props.couriers}
                                permissions={{
                                    edit: this.edit,
                                    view: this.view,
                                    invoicing: this.invoicing,
                                }}
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    couriers: state.owners.data,
    loading: state.owners.loading,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Couriers)

Couriers.propTypes = {
    router: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    couriers: PropTypes.array.isRequired,
}
