import { curry } from './curry'

export const distinctBy = curry((mapper, list) => {
    const map = {}
    return Array.from(list).reduce((acc, item) => {
        const m = mapper(item)
        if (!map[m]) {
            map[m] = true
            return [...acc, item]
        }

        return acc
    }, [])
})
