import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, FormGroup, ControlLabel, FormControl, Button, Alert } from 'react-bootstrap'
import moment from 'moment'
import CancelReasonSelect from '../common/cancel-reasons-picker'
import mapTimeZoneToCountry from '../../utils/timezone'
import { cancelDeliveryAttempt, cancelOrder } from '../../utils/order-webapi'
import { handleError } from '../../utils/handle-error'

const renderWarning = () => (
    <Alert bsStyle="warning">
        <strong>Are you sure?</strong> This will irreversably Cancel this Order completely and all of its Delivery
        Attempts. It will never be delivered.
    </Alert>
)

/**
    Modal to Cancel:
    1. a Delivery Attempt
    2. or entire Order

* */
export default class CancelModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            comment: '',
            category: 0,
            errorMsg: null,
        }
    }

    validate() {
        return this.state.category !== 0
    }

    submit = () => {
        if (this.props.deliveryAttempt) {
            return this.cancelDeliveryAttempt()
        }

        return this.cancelOrder()
    }

    cancelDeliveryAttempt = () => {
        const {
            order: { id: orderId },
            deliveryAttempt: { id: deliveryAttemptId },
        } = this.props
        cancelDeliveryAttempt(orderId, deliveryAttemptId, this.state.comment, this.state.category)
            .then(() => {
                this.props.onSuccess()
            })
            .catch((e) => {
                handleError(e)
            })
    }

    cancelOrder = () => {
        cancelOrder(this.props.order.id, this.state.comment, this.state.category)
            .then(() => {
                this.props.onSuccess()
            })
            .catch((e) => {
                if (e.status === 403) {
                    this.setState({
                        errorMsg: "This order has been picked up and can't be cancelled",
                    })
                }
                handleError(e)
            })
    }

    title() {
        if (this.props.deliveryAttempt) {
            const deliveryStart = moment(this.props.deliveryAttempt.interval.startTimestamp.date)
            const deliveryStop = moment(this.props.deliveryAttempt.interval.stopTimestamp.date)

            const timeZoneToShow =
                this.props.order && this.props.order.deliveryAddress
                    ? mapTimeZoneToCountry(this.props.order.deliveryAddress.countryCode)
                    : this.props.timeZone

            return [
                'Cancel Delivery attempt:',
                deliveryStart.tz(timeZoneToShow).format('YYYY-MM-DD HH:mm'),
                '-',
                deliveryStop.tz(timeZoneToShow).format('HH:mm'),
            ].join(' ')
        }

        return `Cancel Order: ${this.props.order.token}`
    }

    buttonTitle() {
        if (this.props.deliveryAttempt) {
            return 'Cancel Delivery Attempt'
        }

        return 'Cancel Order'
    }

    close = () => {
        this.setState({
            errorMsg: null,
        })
        this.props.onClose()
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.title()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.props.deliveryAttempt ? renderWarning() : null}
                    <form>
                        <FormGroup>
                            <ControlLabel>Comment</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                rows="3"
                                placeholder="Add a comment"
                                value={this.state.comment}
                                autoFocus
                                onChange={(e) => this.setState({ comment: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Reason</ControlLabel>
                            <CancelReasonSelect onSelect={(reason) => this.setState({ category: reason.value })} />
                        </FormGroup>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close}>Close</Button>
                    <Button bsStyle="danger" disabled={!this.validate()} onClick={this.submit}>
                        {this.buttonTitle()}
                    </Button>
                </Modal.Footer>
                {this.state.errorMsg ? <Alert bsStyle="danger">{this.state.errorMsg}</Alert> : null}
            </Modal>
        )
    }
}
CancelModal.propTypes = {
    show: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired,
    deliveryAttempt: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    timeZone: PropTypes.string,
}
