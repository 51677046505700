import React from 'react'
import { Link } from 'react-router'
import { Row, Col, Popover, OverlayTrigger, Button, DropdownButton, MenuItem, Label } from 'react-bootstrap'
import moment from 'moment'
import cx from 'classnames'
import ratingCategories from '../../utils/rating-categories'
import mapType from '../../utils/map-rating-type'
import CommentModal from './comment-modal'
import CancelModal from '../orders/cancel-modal'
import { Delivered, Miss } from '../../utils/status'
import { setRatingCategory, invalidateRating } from '../../utils/rating-webapi'

export default class TableRow extends React.Component {
    state = {
        showCancelModal: false,
        showStopModal: false,
        showRouteModal: false,
    }

    toggleRouteModal(e) {
        if (e) {
            e.preventDefault()
        }

        this.setState({ showRouteModal: !this.state.showRouteModal })
    }

    renderStopModalTrigger(orderToken, routeId, dataId, comments, timeZone) {
        return (
            <div>
                <CommentModal
                    timeZone={timeZone}
                    onRequestHide={this.toggleRouteModal.bind(this)}
                    show={this.state.showRouteModal}
                    className="traffix-modal"
                    orderToken={orderToken}
                    routeId={routeId}
                    dataId={dataId}
                    dataType="stop"
                    comments={comments}
                    commentPermissions={this.props.commentPermissions}
                    onSubmit={this.props.onCommentSubmit}
                />
                <a href="#" onClick={this.toggleRouteModal.bind(this)}>
                    Show {comments.length > 0 ? `(${comments.length})` : null}
                </a>
            </div>
        )
    }

    renderCancelModalTrigger(data) {
        if (data.order == null) {
            return null
        }

        if (data.order.cancellationId) {
            return null
        }

        const deliveryAttempt = {
            id: data.order.deliveryAttemptId,
            interval: {
                startTimestamp: {
                    date: data.deliveryEta,
                },
                stopTimestamp: {
                    date: data.deliveryEta,
                },
            },
        }

        const cancelOrder = {
            id: data.order.orderId,
        }

        return (
            <div>
                <CancelModal
                    timeZone={data.timeZone}
                    show={this.state.showCancelModal}
                    order={cancelOrder}
                    deliveryAttempt={deliveryAttempt}
                    onClose={() => {
                        this.setState({ showCancelModal: false })
                    }}
                    onSuccess={() => {
                        this.setState({ showCancelModal: false })
                    }}
                />
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault()
                        this.setState({ showCancelModal: true })
                    }}
                >
                    Cancel
                </a>
            </div>
        )
    }

    renderStatus(data) {
        if (data.order == null) {
            return null
        }

        if (data.order.cancellationId) {
            return <span>Cancelled</span>
        }

        if (data.order.orderStatus === Miss) {
            const popover = (
                <Popover id="table-row-render-status-popover">
                    {data.order.misses.map((m) => (
                        <p key={m.id}>{`${moment(m.date)
                            .tz(data.timeZone)
                            .format('YYYY-MM-DD HH:mm')}: ${m.comment}`}</p>
                    ))}
                </Popover>
            )

            return (
                <OverlayTrigger placement="top" overlay={popover} delayShow={200} delayHide={0}>
                    <span>Miss</span>
                </OverlayTrigger>
            )
        }

        return <span>{data.order.orderStatus}</span>
    }

    formatDate(date, timeZone) {
        const d = moment(date)
        const timeFormat = d.isSame(moment(), 'day') ? 'HH:mm' : 'YYYY-MM-DD HH:mm'
        return d.tz(timeZone).format(timeFormat)
    }

    renderRoutedEta(eta, order, timeZone) {
        const etaColumnClassNames = {
            danger: order != null && order.status != Delivered && moment().isAfter(eta),
        }

        return <td className={cx(etaColumnClassNames)}>{this.formatDate(eta, timeZone)}</td>
    }

    renderLiveEta(eta, timeZone) {
        if (eta === null || typeof eta === undefined) {
            return <td />
        }

        return <td>{this.formatDate(eta, timeZone)}</td>
    }

    renderStatusDate(data) {
        if (data.order == null) {
            return null
        }

        const statusTime = moment(data.order.statusTime)

        if (data.order.orderStatus === Delivered || data.order.orderStatus === Miss) {
            return this.formatDate(statusTime, data.timeZone)
        }
    }

    renderStatusDiff(data) {
        if (data.order == null) {
            return null
        }

        const statusTime = moment(data.order.statusTime)
        const deliveryEta = moment(data.deliveryEta)

        if (data.order.orderStatus === Delivered || data.order.orderStatus === Miss) {
            return `${deliveryEta.diff(statusTime, 'minutes')} min`
        }
    }

    invalidateRating(rating) {
        invalidateRating(rating.id)
            .then()
            .catch((e) => console.error(e))
    }

    onRatingCategoryChange(rating, category) {
        setRatingCategory(rating.id, category)
            .then()
            .catch((e) => console.error(e))
    }

    renderEndCustomerName(data) {
        if (data.order == null) {
            return null
        }

        if (data.order.parcels != null && data.order.parcels.length > 0) {
            const parcels = data.order.parcels.reduce((m, p) => {
                const key = p.title == null ? p.packageId : p.title
                if (m[key]) {
                    m[key] = [p.id, m[key][1] + 1]
                } else {
                    m[key] = [p.id, 1]
                }

                return m
            }, {})
            const popover = (
                <Popover id="table-row-render-end-customer-name">
                    {Object.keys(parcels).map((title) => (
                        <div key={parcels[title][0]}>
                            {parcels[title][1]} st {title}
                        </div>
                    ))}
                </Popover>
            )

            return (
                <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="top"
                    overlay={popover}
                    delayShow={200}
                    delayHide={0}
                >
                    <u>{data.order.endCustomerName}</u>
                </OverlayTrigger>
            )
        }
        return data.order.endCustomerName
    }

    renderRating(rating, timeZone) {
        if (rating == null || rating.score == null) {
            return <span />
        }

        const popover = (
            <Popover id="table-row-render-rating">
                <Row>
                    <Col md={7}>
                        <small>
                            {moment(rating.createdAt)
                                .tz(timeZone)
                                .format('YYYY-MM-DD HH:mm')}
                        </small>
                    </Col>
                    <Col md={5}>
                        <Button
                            bsSize="xsmall"
                            onClick={this.invalidateRating.bind(this, rating)}
                            disabled={rating.invalidatedAt != null}
                        >
                            Invalidate
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>{rating.comment}</Col>
                    <Col md={12}>
                        <small>{rating.score}/5</small>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <DropdownButton
                            bsStyle="info"
                            bsSize="small"
                            title="Category"
                            id={`rating-dropdown-${rating.id}`}
                        >
                            {ratingCategories.map((category, i) => (
                                <MenuItem
                                    eventKey={i}
                                    key={category}
                                    active={!!(rating.ratingCategory && rating.ratingCategory == category)}
                                    onClick={this.onRatingCategoryChange.bind(this, rating, category)}
                                >
                                    {mapType.toText(category)}
                                </MenuItem>
                            ))}
                        </DropdownButton>
                    </Col>
                </Row>
            </Popover>
        )

        return (
            <OverlayTrigger trigger="click" rootClose placement="top" overlay={popover} delayShow={200} delayHide={0}>
                <span>{rating.score}</span>
            </OverlayTrigger>
        )
    }

    renderStopName(data) {
        if (data.order != null) {
            return data.order.buyerName
        }

        if (data.stopType === 'GARAGE') {
            return 'Garage'
        }
        if (data.stopType === 'TERMINAL') {
            return 'Terminal'
        }
        if (data.stopType === 'CROSSDOCK') {
            return 'Crossdock'
        }
        if (data.stopType === 'REST') {
            return 'Rest'
        }
    }

    renderOrder(data) {
        if (data.order != null) {
            return (
                <div>
                    <Link name="order" to={`/admin/orders/${data.order.orderToken}`}>
                        {data.order.orderToken}
                    </Link>
                    {data.order.consignmentType === 'RETURN' ? <Label bsStyle="warning">Return</Label> : null}
                </div>
            )
        }

        return null
    }

    render() {
        return (
            <tr key={this.props.data.routeId + this.props.rowKey} className={cx(this.props.rowClassNames)}>
                <td>{this.props.data.stopSeq}</td>
                <td>{this.renderStopName(this.props.data)}</td>
                <td>{this.renderOrder(this.props.data)}</td>
                <td>{this.renderStatus(this.props.data)}</td>
                <td className="aws-images">
                    {this.props.data.awsImages.map((ai, i) => (
                        <a key={i} href={ai} target="_blank">
                            {i + 1}
                        </a>
                    ))}
                </td>
                {this.renderRoutedEta(this.props.deliveryEta, this.props.data.order, this.props.data.timeZone)}
                {this.props.data.order != null ? (
                    this.renderLiveEta(this.props.data.order.eta, this.props.data.timeZone)
                ) : (
                    <td />
                )}
                <td className={cx(this.props.deliveredColumnClassnames)}>{this.renderStatusDate(this.props.data)}</td>
                <td>{this.renderStatusDiff(this.props.data)}</td>
                <td>{this.renderEndCustomerName(this.props.data)}</td>
                <td>{this.renderCancelModalTrigger(this.props.data)}</td>
                <td>{this.props.data.order != null ? this.props.data.order.endCustomerPhoneNo : null}</td>
                <td>
                    {this.props.data.order != null
                        ? `${this.props.data.order.endCustomerStreet} ${this.props.data.order.endCustomerPostalCode} ${this.props.data.order.endCustomerCity}`
                        : null}
                </td>
                <td className={cx(this.props.data.ratingColumnClassnames)}>
                    {this.props.data.order != null
                        ? this.renderRating(this.props.data.order.rating, this.props.data.timeZone)
                        : null}
                </td>
                <td>
                    {this.props.data.order != null
                        ? this.renderStopModalTrigger(
                              this.props.data.order.orderToken,
                              this.props.data.routeId,
                              this.props.data.stopId,
                              this.props.data.stopComments,
                              this.props.data.timeZone,
                          )
                        : null}
                </td>
            </tr>
        )
    }
}
