import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Map from '../map/map'
import '../../../styles/less/delivery-map.less'

export default class DeliveryMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayEstimatedRoute: false,
            markers: [],
            sortOrder: '1',
            color: '#41CDA5',
            label: 'D',
        }
    }

    updateMarkers = (address) => {
        const { sortOrder, color, label } = this.state

        if (!address) {
            return
        }

        const markers = [
            {
                sortOrder,
                coordinate: address.coordinate,
                color,
                label,
            },
        ]

        this.setState({ markers })
    }

    mapLoaded = () => {
        const { address } = this.props

        this.updateMarkers(address)
    }

    render() {
        const { markers, displayEstimatedRoute } = this.state

        return (
            <div className="delivery-map">
                <Map
                    onLoad={this.mapLoaded}
                    markers={markers}
                    displayPolyline={displayEstimatedRoute}
                    displayHeatmap={false}
                    heatmapCoordinates={[]}
                    zoomToLocation={null}
                />
            </div>
        )
    }
}

DeliveryMap.propTypes = {
    address: PropTypes.object,
}
