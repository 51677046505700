import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Glyphicon, Grid, Row } from 'react-bootstrap'
import { findRecentImages, acceptImage, denyImage } from '../../../utils/user-webapi'
import auth from '../../../auth'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../../utils/role'

export default class RecentImages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.props.router.push('/admin')
        }
        this.update()
    }

    update = () => {
        this.setState({ isLoading: true })

        findRecentImages()
            .then((users) => this.setState({ users, isLoading: false }))
            .catch((e) => {
                this.setState({ isLoading: false })
            })
    }

    acceptImage(userId) {
        return () => this.filterUser(acceptImage(userId), userId)
    }

    denyImage(userId) {
        return () => this.filterUser(denyImage(userId), userId)
    }

    filterUser(promise, userId) {
        promise
            .then(() => this.setState((current) => ({ users: current.users.filter((user) => user.id !== userId) })))
            .catch((e) => console.error(e))
    }

    renderUsers(users) {
        if (this.state.isLoading) {
            return null
        }

        return users.map(this.renderUser, this)
    }

    renderUser(user) {
        return (
            <div className="media" key={user.id}>
                <div className="media-left" style={{ display: 'inline-flex', width: 300 }}>
                    <img
                        className="media-object img-thumbnail"
                        src={user.userSettings.image.url}
                        width="300"
                        alt={`${user.firstName} ${user.lastName}`}
                    />
                </div>
                <div className="media-body">
                    <h4 className="media-heading">
                        {user.firstName} {user.lastName}
                    </h4>
                    <div>
                        <button type="button" className="btn btn-success" onClick={this.acceptImage(user.id)}>
                            <Glyphicon glyph="ok" /> Accept
                        </button>
                        <button type="button" className="btn btn-danger" onClick={this.denyImage(user.id)}>
                            <Glyphicon glyph="remove" /> Deny
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { users } = this.state

        return (
            <Grid fluid>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <button type="button" className="btn btn-default" onClick={this.update}>
                            <Glyphicon glyph="refresh" className={this.state.isLoading ? 'refreshing' : null} />
                        </button>
                        {this.renderUsers(users)}
                    </Col>
                </Row>
            </Grid>
        )
    }
}
RecentImages.propTypes = {
    router: PropTypes.object,
}
