import { curry } from './curry'
import { prop } from './prop'

export const notValue = curry((value, obj, key) => {
    const it = [null, undefined, ''].indexOf(key) === -1 ? prop(key, obj) : obj

    if (value == null) {
        return it != null
    }

    return it !== value
})
