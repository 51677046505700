import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../../actions/collection-points'
import PricePointList from '../../../price-points/list'
import CreateModal from './modal'
import DeleteModal from '../../../common/modal-delete'
import auth from '../../../../auth'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER } from '../../../../utils/role'

class ManagePricePoints extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        buyerId: PropTypes.number,
        cid: PropTypes.number,
        postalcodes: PropTypes.array,
        postalcodeZones: PropTypes.array,
        priceList: PropTypes.array,
        msg: PropTypes.node,
        added: PropTypes.bool,
        saving: PropTypes.bool,
        success: PropTypes.bool,
    }

    canModify = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER)

    state = {
        delete: false,
        pricePointIds: false,
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen })
    }

    reverseRemove = () => {
        this.setState({ delete: false })
    }

    createPricePoints = (pricePoints) => {
        this.props.actions.createPricePoints(this.props.buyerId, pricePoints)
    }

    removePricePoints = () => {
        this.props.actions.removePricePoints(this.props.buyerId, this.props.cid, this.state.pricePointIds)
        this.setState({ delete: false })
    }

    deletePricePoints = (pricePointIds) => {
        if (pricePointIds.length > 0) {
            this.setState({ delete: true, pricePointIds })
        }
    }

    render() {
        if (!this.props.postalcodes || !this.props.postalcodeZones) {
            return null
        }
        return (
            <div>
                <div>
                    {this.canModify ? (
                        <button type="button" className="btn btn-default margin-btn right" onClick={this.toggleModal}>
                            <i className="glyphicon glyphicon-plus" /> Create Price Points
                        </button>
                    ) : null}
                </div>
                <div>
                    <PricePointList
                        priceList={this.props.priceList}
                        postalcodeZones={this.props.postalcodes}
                        onDelete={this.canModify ? this.deletePricePoints : null}
                    />
                </div>
                {this.canModify ? (
                    <DeleteModal
                        show={this.state.delete}
                        reverse={this.reverseRemove}
                        remove={this.removePricePoints}
                    />
                ) : null}
                {this.canModify ? (
                    <CreateModal
                        show={this.state.modalOpen}
                        success={this.props.success}
                        saving={this.props.saving}
                        added={this.props.added}
                        msg={this.props.msg}
                        cid={this.props.cid}
                        toggle={this.toggleModal}
                        onCreate={this.createPricePoints}
                        postalcodes={this.props.postalcodeZones.filter((pp) => pp.id !== 0)}
                    />
                ) : null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        saving: state.collection.saving,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePricePoints)
