import auth from '../auth'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    COURIER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
    MERCHANT_ADMIN,
    USER_MANAGER,
    LEGACY_BUDBEE_TERMINAL_WORKER,
    LEGACY_TERMINAL_WORKER,
} from './role'

function getMenuItems() {
    return [
        {
            text: 'Account Management',
            children: [
                {
                    route: 'users',
                    text: 'Users',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        COMMERCIAL_MANAGER,
                        COMMERCIAL_USER,
                        TERMINAL_ADMIN,
                        COURIER,
                        USER_MANAGER,
                    ),
                },
                {
                    route: 'couriers',
                    text: 'Couriers',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                    ),
                },
                {
                    route: 'merchants',
                    text: 'Merchants',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        COMMERCIAL_MANAGER,
                        COMMERCIAL_USER,
                    ),
                },
                {
                    route: 'social',
                    text: 'Social share',
                    visible: auth.hasAnyPermission(['view_buyers', 'view_all_users']),
                },
            ],
        },
        {
            route: 'geocoding',
            text: 'Geocoding',
            visible: auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER),
        },
        {
            text: 'Scheduling',
            children: [
                {
                    route: 'scheduler',
                    text: 'Route Scheduler',
                    visible: auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER, COURIER),
                },
                {
                    route: 'holidays',
                    text: 'Public Holidays',
                    visible: auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER),
                },
                {
                    route: 'locker-holidays',
                    text: 'Locker holiday exclusions',
                    visible: auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER),
                },
                {
                    route: 'terminal-limits',
                    text: 'Terminal Consignment Limits',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                        LEGACY_BUDBEE_TERMINAL_WORKER,
                        LEGACY_TERMINAL_WORKER,
                    ),
                },
                {
                    route: 'mail',
                    text: 'Mail Notifications',
                    visible: auth.hasPermission('create_email_notifications'),
                },
                {
                    route: 'recurring-routes',
                    text: 'Recurring routes',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        COMMERCIAL_MANAGER,
                        COMMERCIAL_USER,
                        MERCHANT_ADMIN,
                    ),
                },
                {
                    route: 'chrono-routes',
                    text: 'Chrono Routes',
                    visible: true,
                },
            ],
        },
        {
            text: 'Search',
            children: [
                {
                    route: 'search',
                    text: 'Order Search',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                        COMMERCIAL_MANAGER,
                        COMMERCIAL_USER,
                    ),
                },
                {
                    route: 'route-search',
                    text: 'Route Search',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                        COMMERCIAL_MANAGER,
                        COMMERCIAL_USER,
                        COURIER,
                    ),
                },
                {
                    route: 'consumers',
                    text: 'Consumer Search',
                    visible: auth.hasAnyRole(TRAFFIC_CONTROLLER, COMMERCIAL_MANAGER),
                },
            ],
        },
        {
            text: 'Terminal',
            children: [
                {
                    route: 'terminal-sorting',
                    text: 'Terminal Sorting',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                    ),
                },
                {
                    route: 'terminal-loading',
                    text: 'Terminal Loading',
                    visible: auth.hasAnyRole(
                        OPERATIONS_ADMIN,
                        OPERATIONS_COORDINATOR,
                        TRAFFIC_CONTROLLER,
                        TERMINAL_ADMIN,
                        TERMINAL_WORKER,
                    ),
                },
                {
                    route: 'hub-pallet-configuration',
                    text: 'Hub Pallet Configuration',
                    visible: auth.hasAnyRole(OPERATIONS_ADMIN),
                },
                {
                    route: 'return-pallet-configuration',
                    text: 'Return Pallet Configuration',
                    visible: auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR),
                },
                {
                    route: 'auto-consignment-booking',
                    text: 'Auto Consignment Booking',
                    visible: auth.hasAnyRole(TERMINAL_ADMIN),
                },
            ],
        },
        {
            route: 'roles',
            text: 'Roles',
            visible: auth.hasPermission('view_roles'),
        },
        {
            route: 'ground-operations',
            text: 'Ground Operations',
            visible: auth.hasAnyRole(TERMINAL_ADMIN, TERMINAL_WORKER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR),
        },
        {
            text: 'Communication',
            visible: auth.hasAnyRole(COMMERCIAL_MANAGER),
            children: [
                {
                    route: 'tracking-banners',
                    visible: auth.hasAnyRole(COMMERCIAL_MANAGER),
                    text: 'Banners',
                },
            ],
        },
        {
            route: 'zone-management',
            text: 'Zones',
            visible: auth.hasAnyRole(OPERATIONS_COORDINATOR),
        },
        {
            route: 'other',
            text: 'Other',
            visible: true,
            children: [
                {
                    route: 'ingrid-dedup',
                    visible: true,
                    text: 'Ingrid Dedup',
                },
            ],
        },
    ]
}

export default getMenuItems
