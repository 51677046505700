import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { humanTimeDiff } from '../../../utils/times'

export default class JournalEventsList extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    timeDiff = (time) => {
        return moment(time).diff(moment())
    }

    render() {
        const { events, timeZone, show } = this.props

        if (!show || events == null || events.length === 0) {
            return null
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Timestamp</th>
                    </tr>
                </thead>
                {events.map((x) => (
                    <tbody key={x.id}>
                        <tr>
                            <td>{x.event.event}</td>
                            <td>
                                {moment(x.timestamp)
                                    .tz(timeZone)
                                    .format('YYYY-MM-DD HH:mm:ss')}{' '}
                                ({humanTimeDiff(x.timestamp)})
                            </td>
                        </tr>
                    </tbody>
                ))}
            </Table>
        )
    }
}

JournalEventsList.propTypes = {
    show: PropTypes.bool.isRequired,
    timeZone: PropTypes.string.isRequired,
    events: PropTypes.array.isRequired,
}
