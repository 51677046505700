import React from 'react'
import PropTypes from 'prop-types'
import { Table, Row, Col } from 'react-bootstrap'
import GroupRow from './group-row'
import AdjustTierButton from './adjust-tiers-button'

const Tiers = ({ priceTiers, unit, partitionName, deletePriceTier, modifyPriceTiers, enumTier, quantityEnumMap }) => {
    if (priceTiers == null) {
        return null
    }

    return (
        <div>
            <Table className="price-list-table striped bordered condensed hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{partitionName}</th>
                        <th>Tiers ( Price &amp; {unit.name} )</th>
                    </tr>
                </thead>
                <tbody>
                    {priceTiers.map((group, i) => (
                        <GroupRow
                            key={group.key.join(',')}
                            i={i + 1}
                            group={group}
                            unit={unit}
                            deletePriceTier={deletePriceTier}
                            enumTier={enumTier}
                            quantityEnumMap={quantityEnumMap}
                        />
                    ))}
                </tbody>
            </Table>
            {modifyPriceTiers != null ? (
                <Row className="mt-sm">
                    <Col sm={12}>
                        <AdjustTierButton
                            priceTiers={priceTiers}
                            partitionName={partitionName}
                            unit={unit}
                            modifyPriceTiers={modifyPriceTiers}
                            enumTier={enumTier}
                            quantityEnumMap={quantityEnumMap}
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    )
}

Tiers.propTypes = {
    priceTiers: PropTypes.array,
    unit: PropTypes.object,
    partitionName: PropTypes.node,
    deletePriceTier: PropTypes.func,
    modifyPriceTiers: PropTypes.func,
    enumTier: PropTypes.bool.isRequired,
    quantityEnumMap: PropTypes.object,
}

export default Tiers
