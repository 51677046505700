import React from 'react'
import { Col } from 'react-bootstrap'
import AddShoppingIndexing from './components/shopping-indexing-form/ShoppingIndexingForm'

const Coupons = () => (
    <Col className="mt-sm" md={12} mdOffset={1} lg={10}>
        <AddShoppingIndexing />
    </Col>
)

export default Coupons
