import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Nav, NavItem, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import HtmlTitle from '../html-title'
import auth from '../../auth'
import * as actions from '../../actions/couriers'
import { OPERATIONS_ADMIN } from '../../utils/role'

class CourierHandler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            hasRole: false,
        }
    }

    componentDidMount() {
        // More specific roles to be added later
        const hasRole = auth.hasAnyRole(OPERATIONS_ADMIN)

        if (!hasRole) {
            this.props.router.push('/admin/couriers')
            return
        }

        this.setState({ hasRole })

        this.props.actions.fetchCourier(this.props.params.id)
    }

    render() {
        return (
            <div className="sidebar-wrapper couriers">
                <nav className="sidebar navbar navbar-default">
                    <Link className="back-link" to="/admin/couriers">
                        <small>&larr; Back to couriers</small>
                    </Link>
                    <div className="navbar-header">
                        <span className="navbar-brand">{this.props.courier && this.props.courier.name}</span>
                    </div>
                    <Nav navbar>
                        {this.state.hasRole && (
                            <IndexLinkContainer to={`/admin/couriers/${this.props.params.id}`}>
                                <NavItem>
                                    <Glyphicon glyph="cog" />
                                    General settings
                                </NavItem>
                            </IndexLinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`/admin/couriers/${this.props.params.id}/invoicing`}>
                                <NavItem>
                                    <Glyphicon glyph="list-alt" />
                                    Invoicing
                                </NavItem>
                            </LinkContainer>
                        )}
                        {this.state.hasRole && (
                            <LinkContainer to={`/admin/couriers/${this.props.params.id}/danger-zone`}>
                                <NavItem>
                                    <Glyphicon glyph="bell" />
                                    Danger zone
                                </NavItem>
                            </LinkContainer>
                        )}
                    </Nav>
                </nav>
                <div className="content-wrapper">
                    <Grid fluid>
                        <Row>
                            <Loader color="#BFBFBF" loaded={!this.props.loading} />
                            {this.props.courier && <HtmlTitle title={this.props.courier.name} />}
                            {this.props.courier == null || !this.state.hasRole ? null : this.props.children}
                        </Row>
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    ...state.couriers,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CourierHandler)

CourierHandler.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object,
    courier: PropTypes.object,
    loading: PropTypes.bool,
    children: PropTypes.node.isRequired,
}
