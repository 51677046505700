import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .accept(contentType)
        .contentType(contentType)
        .authorization(auth.get())
        .path('consignment-limits')

export const getTerminalLimits = () => webapi().get()

export const setTerminalLimit = (request) => webapi().post(request)

export const setMerchantConsignmentLimit = (limitId, merchantId, maximumNumberOfConsignments) =>
    webapi()
        .path(limitId)
        .path('merchants')
        .path(merchantId)
        .put({ maximumNumberOfConsignments })

export const removeMerchantConsignmentLimit = (limitId, merchantId) =>
    webapi()
        .path(limitId)
        .path('merchants')
        .path(merchantId)
        .delete()

export const getActiveConsignmentsCount = () =>
    new Webapi()
        .accept(contentType)
        .contentType(contentType)
        .authorization(auth.get())
        .path('consignment-count')
        .get()
