import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import FixedCosts from '../invoices/index'
import auth from '../../auth'
import { fetchMerchant } from '../../actions/merchants'
import {
    getAllRecurringInvoicesForMerchant,
    getAllInvoicesForMerchant,
    createInvoiceForMerchant,
    deleteInvoice,
    createRecurringInvoiceForMerchant,
    deleteRecurringInvoice,
} from '../../utils/invoice-webapi'

import { fetchInvoiceSettings } from '../../actions/regional-settings'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

export const INVOICE_TYPES_MERCHANT = [
    { value: 'DEBIT', label: 'Debit + (We will invoice this)' },
    { value: 'CREDIT', label: 'Credit - (We will deduct this from the Merchants next invoice)' },
]

const toOption = ({ id: value, label }) => ({ value, label })

class MerchantInvoiceItems extends Component {
    constructor(...args) {
        super(...args)
        this.hasAuth = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
    }

    componentDidMount() {
        if (!this.hasAuth) {
            this.props.router.push('/admin/merchants')
            return
        }
        const { dispatch } = this.props
        dispatch(fetchMerchant(this.props.params.id))
        dispatch(fetchInvoiceSettings())
    }

    render() {
        if (this.props.loading || this.props.buyer === null) {
            return <Loader color="#BFBFBF" />
        }
        const image =
            this.props.buyer != null && this.props.buyer.logo != null ? (
                <img className="img-responsive" src={this.props.buyer.logo.url} alt="logo" />
            ) : null
        return (
            <Col md={10} mdOffset={1}>
                <Row>
                    <Col md={7} mdOffset={1}>
                        <h2> Manage invoices for {this.props.buyer.name}</h2>
                    </Col>
                    <Col mdOffset={1} md={2}>
                        <div>{image}</div>
                    </Col>
                </Row>
                <FixedCosts
                    onlyManual={false}
                    invoiceRecipientId={this.props.buyer.id}
                    loadRecurringInvoiceItems={getAllRecurringInvoicesForMerchant}
                    loadAllInvoiceItems={getAllInvoicesForMerchant}
                    createInvoiceItem={createInvoiceForMerchant}
                    deleteInvoiceItem={deleteInvoice}
                    createRecurringInvoiceItem={createRecurringInvoiceForMerchant}
                    deleteRecurringInvoiceItem={deleteRecurringInvoice}
                    invoiceTypes={INVOICE_TYPES_MERCHANT}
                    invoiceArticles={this.props.invoiceArticles.map(toOption)}
                />
            </Col>
        )
    }
}
MerchantInvoiceItems.propTypes = {
    dispatch: PropTypes.func,
    params: PropTypes.object,
    router: PropTypes.object,
    buyer: PropTypes.object,
    loading: PropTypes.bool,
    invoiceArticles: PropTypes.array,
}

const mapStateToProps = ({
    buyers: { loading, error, buyer },
    invoiceSettings: {
        articles: { merchant: invoiceArticles },
    },
}) => ({
    loading,
    error,
    buyer,
    invoiceArticles,
})

export default connect(mapStateToProps)(MerchantInvoiceItems)
