import 'rc-time-picker/assets/index.css'
import 'rc-slider/assets/index.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import {
    Alert,
    Button,
    Col,
    ControlLabel,
    DropdownButton,
    FormControl,
    FormGroup,
    Glyphicon,
    HelpBlock,
    InputGroup,
    MenuItem,
    Row,
} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import cn from 'classnames'
import { curry } from '../../utils/curry'
import { path } from '../../utils/path'
import { flexStyle } from '../../utils/styles'
import { getDayOfWeekFromDate } from './format-time'
import RecurringRouteMap from './map/recurring-route-map'
import { filterPickupsNotFoundInLocations, STOP_TYPE } from '../../utils/recurring-pickups'
import vehicleTypes from '../../utils/vehicle-types'
import RecurringStop from './components/recurring-stop'

const renderOwner = ({ id, name }) => (
    <option key={id} value={id}>
        {name}
    </option>
)
renderOwner.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
}

const renderDriver = ({ id, firstName, lastName }) => (
    <option key={id} value={id}>
        {firstName} {lastName}
    </option>
)
renderDriver.propTypes = {
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
}

export default class Form extends Component {
    constructor(...args) {
        super(...args)
        const [props] = args
        this.state = props.initialModel
        this.state.requiredPallets = this.calculatePallets(this.state.pickups)
        this.state.collectionPoints = []
        this.state.selectedDays = []
        this.state.days = [
            { name: 'Mon', enum: 'MONDAY', selected: this.checkSelectedDay('MONDAY') },
            { name: 'Tue', enum: 'TUESDAY', selected: this.checkSelectedDay('TUESDAY') },
            { name: 'Wed', enum: 'WEDNESDAY', selected: this.checkSelectedDay('WEDNESDAY') },
            { name: 'Thu', enum: 'THURSDAY', selected: this.checkSelectedDay('THURSDAY') },
            { name: 'Fri', enum: 'FRIDAY', selected: this.checkSelectedDay('FRIDAY') },
            { name: 'Sat', enum: 'SATURDAY', selected: this.checkSelectedDay('SATURDAY') },
            { name: 'Sun', enum: 'SUNDAY', selected: this.checkSelectedDay('SUNDAY') },
        ]
        this.state.errorCollectionPoint = false
    }

    checkSelectedDay(day) {
        return this.state.dayOfWeek === day || this.state.selectedDays.includes(day)
    }

    componentDidMount() {
        if (this.state.dayOfWeek !== '-1' || this.state.date) {
            const dayOfWeek = this.state.dayOfWeek ? this.state.dayOfWeek : this.state.date.format('dddd').toUpperCase()
            this.props.fetchRecurringPickupCollectionPoints(dayOfWeek)
            if (this.props.query != null) {
                const selectedDaysArray = this.props.query.split(',')
                const selectedDays = []
                while (selectedDaysArray.length) {
                    selectedDays.push(selectedDaysArray.splice(0, 2))
                }

                this.setState(
                    (state) => ({
                        selectedDays: selectedDays.map((d) => d[1].toUpperCase()),
                        days: state.days.map((d) => ({
                            ...d,
                            selected:
                                state.selectedDays.includes(d.enum) || d.enum === this.props.initialModel.dayOfWeek,
                        })),
                    }),
                    () => this.props.fetchRecurringPickupCollectionPointSchedules(this.state.selectedDays),
                )
            } else {
                this.setState(
                    (state) => ({
                        selectedDays: dayOfWeek,
                        days: state.days.map((d) => ({
                            ...d,
                            selected:
                                state.selectedDays.includes(d.enum) || d.enum === this.props.initialModel.dayOfWeek,
                        })),
                    }),
                    () => this.props.fetchRecurringPickupCollectionPointSchedules(this.state.selectedDays),
                )
            }
        }
    }

    componentWillReceiveProps(props) {
        if (props.multipleLocations != null && Object.keys(props.multipleLocations).length !== 0) {
            const collectionPoints = []
            this.state.days.map((d) =>
                collectionPoints.push({ day: d.enum, locations: props.multipleLocations[d.enum] }),
            )
            this.setState({
                collectionPoints,
            })
        }
        if (props.locations.length !== 0) {
            this.setState((state) => ({
                collectionPoints: [...state.collectionPoints, { day: state.dayOfWeek, locations: props.locations }],
                days: state.days.map((d) => ({
                    ...d,
                    selected: state.selectedDays.includes(d.enum),
                })),
                pickups: state.pickups.map((p) => {
                    if (p.merchantScheduleId === '-1' || p.type !== STOP_TYPE.MERCHANT || p.collectionPointDeleted) {
                        return p
                    }
                    const location = props.locations.find(({ id }) => p.merchantScheduleId === id)
                    if (location) {
                        const { pallets, startTime, coordinate, endTime } = location
                        return {
                            ...p,
                            pallets,
                            startTime,
                            coordinate,
                            endTime,
                        }
                    }
                    return p
                }),
            }))
        }
    }

    calculatePallets(pickups) {
        return pickups
            .filter((x) => x.type === STOP_TYPE.MERCHANT)
            .reduce((acc, { id: pickupId, returns, pickup }) => {
                if (returns && !pickup) {
                    return acc
                }

                const parsedPickupId = parseInt(pickupId, 10)
                const location = this.props.locations.find(({ id: locationId }) => parsedPickupId === locationId)
                if (location == null) {
                    return acc
                }
                return acc + location.pallets
            }, 0)
    }

    newStop = (type, nextId) => {
        switch (type) {
            case STOP_TYPE.MERCHANT:
                return this.merchantStop(nextId)
            case STOP_TYPE.TERMINAL:
                return this.terminalStop(nextId)
            case STOP_TYPE.REST:
                return this.restStop(nextId)
            default:
                return null
        }
    }

    merchantStop = (id) => ({
        id,
        merchantScheduleId: '-1',
        type: STOP_TYPE.MERCHANT,
        returns: false,
    })

    terminalStop = (id) => ({
        id,
        terminalId: '-1',
        type: STOP_TYPE.TERMINAL,
    })

    restStop = (id) => ({
        id,
        type: STOP_TYPE.REST,
    })

    addStop = (type, id) => () => {
        this.setState((state) => ({
            pickups: [...state.pickups, this.newStop(type, id)],
        }))
    }

    arrayMove = (arr, fromIndex, toIndex) => {
        const fromItem = arr[fromIndex]
        const toItem = arr[toIndex]
        return arr.map((item, idx) => {
            if (fromIndex === idx) {
                return toItem
            }
            if (toIndex === idx) {
                return fromItem
            }

            return item
        })
    }

    advancePickup = curry((idx, _e) => {
        const { pickups } = this.state
        const updatedPickups = this.arrayMove(pickups, idx, idx + 1)
        this.setState({ pickups: updatedPickups })
    })

    delayPickup = curry((idx, _e) => {
        const { pickups } = this.state
        const updatedPickups = this.arrayMove(pickups, idx, idx - 1)
        this.setState({ pickups: updatedPickups })
    })

    removePickup = curry((idx, _e) => {
        this.setState((state) => {
            const pickups = state.pickups.filter((_pickup, i) => idx !== i)
            const requiredPallets = this.calculatePallets(pickups)
            const validCollectionPoints = this.checkValidCollectionPoints(pickups)
            return {
                pickups,
                requiredPallets,
                errorCollectionPoint: !validCollectionPoints,
            }
        })
    })

    onChange = curry((p, name, e) => {
        this.setState({ [name]: path(['target', p], e) })
    })

    onTimeChange = curry((pickup, name, time) => {
        this.setState((state) => {
            const pickups = state.pickups.map((p) => {
                if (p.id === pickup.id) {
                    return {
                        ...p,
                        [name]: time.value,
                    }
                }
                return p
            })
            return { pickups }
        })
    })

    onNumberChange = curry((p, name, e) => {
        const value = parseInt(path(['target', p], e), 10)
        this.setState({ [name]: value })
    })

    onPickupChange = (rawLocationId, sortOrder) => {
        const locationId = parseInt(rawLocationId, 10)
        if (!locationId) {
            return
        }

        this.setState((state) => {
            const {
                pallets,
                coordinate,
                startTime,
                endTime,
                buyerName,
                collectionPointName,
            } = this.props.multipleLocationsOptions.find(({ id }) => locationId === id)
            const pickups = state.pickups.map((pickup, i) =>
                sortOrder === i
                    ? {
                          ...pickup,
                          merchantScheduleId: locationId,
                          merchantName: buyerName,
                          collectionPointName,
                          collectionPointDeleted: false,
                          pallets,
                          coordinate,
                          startTime,
                          endTime,
                          returns: true,
                          pickup: true,
                      }
                    : pickup,
            )

            const requiredPallets = this.calculatePallets(pickups)
            const validCollectionPoints = this.checkValidCollectionPoints(pickups)

            return {
                pickups,
                requiredPallets,
                errorCollectionPoint: !validCollectionPoints,
            }
        })
    }

    onTerminalChange = curry((p, sortOrder, terminal) => {
        this.setState((state) => {
            const pickups = state.pickups.map((pickup, i) =>
                sortOrder === i
                    ? {
                          ...pickup,
                          terminalId: terminal == null ? '-1' : terminal.id,
                      }
                    : pickup,
            )
            return { pickups }
        })
    })

    checkValidCollectionPoints(pickups) {
        const { isOneTimeRoute, days, selectedDays, collectionPoints } = this.state

        if (isOneTimeRoute) {
            return true
        }
        return days.every((day) =>
            selectedDays.includes(day.enum)
                ? filterPickupsNotFoundInLocations(
                      pickups,
                      collectionPoints.find((d) => d.day === day.enum),
                  ).length === 0
                : true,
        )
    }

    onPickupOptionChange = (sortOrder, type, value) => {
        this.setState((state) => {
            const pickups = state.pickups.map((pickup, i) => {
                return sortOrder === i ? { ...pickup, [type]: value } : pickup
            })
            const requiredPallets = this.calculatePallets(pickups)
            return { pickups, requiredPallets }
        })
    }

    onCurrencyChange = curry((currency, _e) => {
        this.setState({ currency })
    })

    onDateChange = (date) => {
        const dateChange = date !== this.state.date
        const isSameWeekday = getDayOfWeekFromDate(date) === getDayOfWeekFromDate(this.state.date)
        if (dateChange && !isSameWeekday) {
            if (!window.confirm('If you change the date, the current pickups will be cleared. Continue?')) {
                return
            }
        }
        this.props.fetchRecurringPickupCollectionPoints(getDayOfWeekFromDate(date))
        this.setState((state) => ({
            date: moment(date),
            pickups: dateChange && !isSameWeekday ? this.props.initialPickups() : state.pickups,
        }))
    }

    onSubmit = (e) => {
        this.props.onSubmit(this.state, e)
    }

    onCourierChange = (e) => {
        const courierId = e.target.value
        if (
            this.state.driverId !== '-1' &&
            !window.confirm('If you change courier, the driver will be cleared. Continue?')
        ) {
            return
        }
        this.setState({ courierId, driverId: '-1' })
        this.props.onCourierChange(courierId)
    }

    renderMap() {
        const { pickups } = this.state
        const { terminals } = this.props

        const stops = pickups.map((pickup, index) => ({ ...pickup, sortOrder: index }))
        return <RecurringRouteMap height={500} recurringPickup={{ stops }} terminals={terminals} />
    }

    renderStop(pickup, sortOrder) {
        const { multipleLocationsOptions, isReadOnly } = this.props
        const { isOneTimeRoute, pickups } = this.state

        return (
            <RecurringStop
                isReadOnly={isReadOnly}
                key={sortOrder}
                pickup={pickup}
                sortOrder={sortOrder}
                isOneTimeRoute={isOneTimeRoute}
                onPickupChange={this.onPickupChange}
                onPickupOptionChange={this.onPickupOptionChange}
                onTerminalChange={this.onTerminalChange(pickup)}
                onTimeChange={this.onTimeChange(pickup)}
                multipleLocationsOptions={multipleLocationsOptions}
                moveUp={this.delayPickup(sortOrder)}
                moveDown={this.advancePickup(sortOrder)}
                remove={this.removePickup(sortOrder)}
                totalStops={pickups.length}
            />
        )
    }

    renderPalletAlert() {
        if (this.state.maxPallets !== '' && this.state.requiredPallets > this.state.maxPallets) {
            return (
                <Alert bsStyle="danger">
                    The current pickups pallet requirement are exceeding the max number of pallets for this route
                </Alert>
            )
        }

        return null
    }

    onDelete = ({ name }) => () => {
        this.props.onDelete(name)
    }

    selectDayOfWeek = curry((p, dayOfWeek, e) => {
        e.preventDefault()
        if (this.props.allowedToChangeDays) {
            return
        }
        const dayChange = dayOfWeek !== this.state.dayOfWeek
        const configuredPickups = this.state.pickups.some(({ id }) => id !== '-1')
        if (dayChange && configuredPickups) {
            if (!window.confirm('If you change day of week, the current pickups will be cleared. Continue?')) {
                return
            }
        }

        if (!this.props.singleDayEnable) {
            if (this.state.selectedDays.includes(dayOfWeek)) {
                const selectedDayArray = [...this.state.selectedDays]
                const index = selectedDayArray.indexOf(dayOfWeek)
                selectedDayArray.splice(index, 1)
                this.setState(
                    (state) => ({
                        selectedDays: selectedDayArray,
                        days: state.days.map((d) => ({
                            ...d,
                            selected: selectedDayArray.includes(d.enum),
                        })),
                    }),
                    () => this.props.fetchRecurringPickupCollectionPointSchedules(this.state.selectedDays),
                )
            } else {
                this.setState(
                    (state) => ({
                        days: state.days.map((d) => ({
                            ...d,
                            selected: state.selectedDays.includes(d.enum) || d.enum === dayOfWeek,
                        })),
                        selectedDays: [...state.selectedDays, dayOfWeek],
                        dayOfWeek,
                        pickups: dayChange ? this.props.initialPickups() : state.pickups,
                    }),
                    () => this.props.fetchRecurringPickupCollectionPointSchedules(this.state.selectedDays),
                )
            }
        } else {
            this.setState(
                (state) => ({
                    days: state.days.map((d) => ({ ...d, selected: d.enum === dayOfWeek })),
                    selectedDays: [dayOfWeek],
                    dayOfWeek,
                    pickups: dayChange ? this.props.initialPickups() : state.pickups,
                }),
                () => this.props.fetchRecurringPickupCollectionPointSchedules(this.state.selectedDays),
            )
        }
        this.props.fetchRecurringPickupCollectionPoints(dayOfWeek)
    })

    renderCollectionPointError() {
        const { errorCollectionPoint } = this.state
        if (!errorCollectionPoint) {
            return null
        }
        return (
            <Alert bsStyle="danger">
                <strong>Issue found with a pickup</strong>
                <p>
                    Merchant schedule may not be set up for all selected days or there is an interval difference on some
                    days
                </p>
            </Alert>
        )
    }

    render() {
        const { isReadOnly } = this.props
        const { pickups } = this.state

        const vehicles = vehicleTypes().filter(
            (vehicle) =>
                !(
                    vehicle.value === 'TRUCK' ||
                    vehicle.value === 'ENVIRONMENTAL_TRUCK' ||
                    vehicle.value === 'ELECTRIC_TRUCK' ||
                    vehicle.value === 'HEAVY_TRUCK'
                ),
        )

        return (
            <form onSubmit={this.onSubmit}>
                <Row>
                    <Col md={4} mdOffset={2}>
                        {this.state.isOneTimeRoute && (
                            <FormGroup>
                                <Col componentClass={ControlLabel}>Date</Col>
                                <Col>
                                    <DatePicker
                                        disabled={isReadOnly}
                                        className="form-control"
                                        dateFormat="YYYY-MM-DD"
                                        selected={this.state.date}
                                        minDate={moment()}
                                        selectStart
                                        startDate={this.state.date}
                                        placeholder="Select Start Date"
                                        onChange={(date) => this.onDateChange(date)}
                                    />
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <ControlLabel>Name</ControlLabel>
                            <FormControl
                                disabled={isReadOnly}
                                name="name"
                                value={this.state.name}
                                onChange={this.onChange('value', 'name')}
                            />
                        </FormGroup>
                        {!this.state.isOneTimeRoute && (
                            <FormGroup>
                                <Col componentClass={ControlLabel}>Day of week</Col>
                                <Col
                                    className={cn('btn-group dow', { 'modal-error': !this.state.dayOfWeek })}
                                    role="group"
                                    aria-label="Days"
                                    style={flexStyle}
                                >
                                    {this.state.days.map((item) => (
                                        <FormControl
                                            disabled={isReadOnly}
                                            componentClass="button"
                                            key={item.name}
                                            className={cn('btn', {
                                                'btn-primary': item.selected,
                                                'btn-default': !item.selected,
                                            })}
                                            onClick={this.selectDayOfWeek('dayOfWeek', item.enum)}
                                        >
                                            {item.name}
                                        </FormControl>
                                    ))}
                                </Col>
                            </FormGroup>
                        )}
                        <FormGroup>
                            <ControlLabel>Courier</ControlLabel>
                            <FormControl
                                disabled={isReadOnly}
                                componentClass="select"
                                name="courier"
                                value={this.state.courierId}
                                inputRef={(el) => {
                                    this.inputCourier = el
                                }}
                                onChange={this.onCourierChange}
                            >
                                <option value="-1" disabled>
                                    Select
                                </option>
                                {this.props.owners.map(renderOwner)}
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Driver</ControlLabel>
                            <FormControl
                                componentClass="select"
                                name="courier"
                                disabled={isReadOnly || this.state.courierId === '-1'}
                                value={this.state.driverId}
                                onChange={this.onChange('value', 'driverId')}
                            >
                                <option value="-1">Select</option>
                                {this.props.drivers.map(renderDriver)}
                            </FormControl>
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>Pallet capacity of vehicle</ControlLabel>
                            <FormControl
                                disabled={isReadOnly}
                                type="number"
                                name="maxPallets"
                                value={this.state.maxPallets}
                                onChange={this.onNumberChange('value', 'maxPallets')}
                            />
                        </FormGroup>
                        {this.renderPalletAlert()}

                        {this.state.vehicleTypeFreeText != null ? (
                            <FormGroup>
                                <ControlLabel>Old vehicle type</ControlLabel>
                                <FormControl.Static>{this.state.vehicleTypeFreeText}</FormControl.Static>
                            </FormGroup>
                        ) : null}
                        <FormGroup>
                            <ControlLabel>Agreed vehicle type</ControlLabel>
                            <FormControl
                                disabled={isReadOnly}
                                componentClass="select"
                                name="vehicleType"
                                value={this.state.vehicleType}
                                onChange={this.onChange('value', 'vehicleType')}
                            >
                                <option value="">Choose</option>
                                {vehicles.map(({ label, value }) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Vehicle utilisation percentage</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    disabled={isReadOnly}
                                    type="number"
                                    name="vehicleUtilisationFactor"
                                    min={0}
                                    max={100}
                                    value={this.state.vehicleUtilisationFactor}
                                    onChange={this.onChange('value', 'vehicleUtilisationFactor')}
                                />
                                <InputGroup.Addon>%</InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Remuneration (courier)</ControlLabel>
                            <InputGroup>
                                <DropdownButton
                                    disabled={isReadOnly}
                                    id="currency-dropdown"
                                    componentClass={InputGroup.Button}
                                    title={this.state.currency}
                                >
                                    <MenuItem onClick={this.onCurrencyChange('SEK')}>SEK</MenuItem>
                                    <MenuItem onClick={this.onCurrencyChange('EUR')}>EUR</MenuItem>
                                    <MenuItem onClick={this.onCurrencyChange('DKK')}>DKK</MenuItem>
                                </DropdownButton>
                                <FormControl
                                    disabled={isReadOnly}
                                    type="number"
                                    name="remuneration"
                                    placeholder="0"
                                    value={this.state.remuneration}
                                    onChange={this.onChange('value', 'remuneration')}
                                />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Cost allocated to linehaul</ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon>{this.state.currency}</InputGroup.Addon>
                                <FormControl
                                    disabled={isReadOnly}
                                    type="number"
                                    name="linehaulCost"
                                    min={0}
                                    max={this.state.remuneration}
                                    placeholder="0"
                                    value={this.props.checkIfMerchantRoute(pickups) ? this.state.linehaulCost : null}
                                    onChange={this.onChange('value', 'linehaulCost')}
                                />
                            </InputGroup>
                            <HelpBlock>
                                {this.state.linehaulCost && this.props.checkIfMerchantRoute(pickups)
                                    ? `The total cost is ${this.state.remuneration} ${this.state.currency}. ${
                                          this.state.linehaulCost
                                      } ${this.state.currency}  is allocated to linehaul and ${this.state.remuneration -
                                          this.state.linehaulCost} ${this.state.currency} is allocated to merchant.`
                                    : `Only applicapable to merchant routes.`}
                            </HelpBlock>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <div
                            style={{
                                borderBottom: '1px solid gray',
                                opacity: 0.3,
                                marginTop: 25,
                                marginBottom: 18,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} mdOffset={2}>
                        <FormGroup>{this.renderMap()}</FormGroup>
                    </Col>
                    <Col md={4}>
                        {this.renderCollectionPointError()}
                        {pickups.length === 0 ? (
                            <Alert bsStyle="warning">
                                <strong>No stops found!</strong> Begin adding stops to this schedule.
                            </Alert>
                        ) : (
                            pickups.map(this.renderStop, this)
                        )}
                        <Button
                            bsStyle="link"
                            onClick={this.addStop(STOP_TYPE.TERMINAL, pickups.length)}
                            disabled={isReadOnly || this.props.terminals.length === 0 || this.state.selectedDays.length === 0}
                        >
                            <Glyphicon glyph="flag" /> Add terminal
                        </Button>
                        <Button
                            bsStyle="link"
                            onClick={this.addStop(STOP_TYPE.MERCHANT, pickups.length)}
                            disabled={isReadOnly || !this.props.multipleLocationsOptions || this.state.selectedDays.length === 0}
                        >
                            <Glyphicon glyph="shopping-cart" /> Add merchant
                        </Button>
                        <Button disabled={isReadOnly} bsStyle="link" onClick={this.addStop(STOP_TYPE.REST)}>
                            <Glyphicon glyph="heart" /> Add rest
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        <div
                            style={{
                                borderBottom: '1px solid gray',
                                opacity: 0.3,
                                marginTop: 25,
                                marginBottom: 18,
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} mdOffset={6}>
                        <FormGroup style={{ paddingTop: 10, paddingBottom: 25 }}>
                            {this.props.showDeleteButton && (
                                <Button onClick={this.onDelete(this.state)} bsStyle="danger">
                                    Delete route
                                </Button>
                            )}
                            {this.props.showSubmitButtonCreate && (
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    className="pull-right"
                                    disabled={isReadOnly || !this.props.validate(this.state)}
                                >
                                    Save
                                </Button>
                            )}
                            {this.props.showSubmitButtonCopy && (
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    className="pull-right"
                                    disabled={isReadOnly || !this.props.validate(this.state)}
                                >
                                    Save copy
                                </Button>
                            )}
                            {this.props.showSubmitButtonEdit && (
                                <Button
                                    type="submit"
                                    bsStyle="primary"
                                    className="pull-right"
                                    disabled={isReadOnly || !this.props.validate(this.state)}
                                >
                                    Save changes
                                </Button>
                            )}
                            {this.props.showMakeCopyButton && (
                                <Link
                                    type="submit"
                                    className="btn btn-success"
                                    to={`/admin/recurring-routes/${this.props.initialModel.id}/copy`}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (isReadOnly) {
                                            e.preventDefault()
                                        }
                                    }}
                                    disabled={isReadOnly}
                                >
                                    Make a Copy
                                </Link>
                            )}
                            <Link to="/admin/recurring-routes" className="btn btn-default pull-right">
                                Cancel
                            </Link>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
        )
    }
}
Form.propTypes = {
    initialModel: PropTypes.object.isRequired,
    isReadOnly: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCourierChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    initialPickups: PropTypes.func.isRequired,
    fetchRecurringPickupCollectionPoints: PropTypes.func.isRequired,
    fetchRecurringPickupCollectionPointSchedules: PropTypes.func,
    owners: PropTypes.array.isRequired,
    terminals: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    drivers: PropTypes.array.isRequired,
    showDeleteButton: PropTypes.bool,
    showMakeCopyButton: PropTypes.bool,
    showSubmitButtonCreate: PropTypes.bool,
    showSubmitButtonEdit: PropTypes.bool,
    showSubmitButtonCopy: PropTypes.bool,
    validate: PropTypes.func.isRequired,
    checkIfMerchantRoute: PropTypes.func.isRequired,
    allowedToChangeDays: PropTypes.bool,
    singleDayEnable: PropTypes.bool,
    query: PropTypes.string,
    multipleLocations: PropTypes.object,
    multipleLocationsOptions: PropTypes.array,
}
