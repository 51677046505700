import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

export default class PreviewList extends Component {
    renderList = (arr) => {
        return arr.map((item, index) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                    <td>
                        <p style={{ display: 'inline-block', paddingLeft: '8px' }}>{item}</p>
                    </td>
                </tr>
            )
        })
    }

    brands = (brands) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>List: {brands.length}</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(brands)}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render() {
        const { list } = this.props

        return (
            <div>
                <h4>Preview List</h4>
                {this.brands(list)}
            </div>
        )
    }
}

PreviewList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string),
}
