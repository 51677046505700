import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Button, Glyphicon, Label } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getDeliveredStatusVariant, isParcelStatus } from '../../../utils/parcel-util'

/**
 * Render Statuses in a Table
 */
export default class Statuses extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statuses: [],
        }
    }

    componentDidMount() {
        if (this.props.statuses) {
            this.setState({ statuses: this.props.statuses })
        }
    }

    renderStatusCoordinate = (coordinate) => {
        if (!coordinate) {
            return null
        }

        return (
            <Button
                bsSize="xsmall"
                bsClass="pull-right"
                href={`https://www.google.com/maps/search/?api=1&query=${coordinate.latitude},${coordinate.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Glyphicon glyph="export" />
            </Button>
        )
    }

    renderStatus = (status, i) => {
        const { deliveryAddress } = this.props
        const title = getDeliveredStatusVariant(status)

        return (
            <tr key={i}>
                <td>
                    <a href={`/admin/users/${status.user.id}`}>{`${status.user.firstName} ${status.user.lastName}`}</a>
                </td>
                <td>
                    {moment(status.date)
                        .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                        .format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td>
                    {isParcelStatus(status.status) ? (
                        <Label bsStyle="primary">{title}</Label>
                    ) : (
                        <Label bsStyle="warning">{title}</Label>
                    )}
                    {this.renderStatusCoordinate(status.coordinate)}
                </td>
            </tr>
        )
    }

    render() {
        const { statuses } = this.state

        if (statuses == null || statuses.length === 0) {
            return null
        }

        return (
            <Table responsive condensed hover>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Date &#709;</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>{statuses.map(this.renderStatus)}</tbody>
            </Table>
        )
    }
}

Statuses.propTypes = {
    statuses: PropTypes.array,
    deliveryAddress: PropTypes.object,
}
