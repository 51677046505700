export default (fn, keymaker = JSON.stringify) => {
    const cache = {}
    return (...args) => {
        const key = keymaker(args)
        if (cache[key] != null) {
            return cache[key]
        }

        const result = fn(...args)
        cache[key] = result
        return result
    }
}
