import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, FormGroup, ControlLabel, Button } from 'react-bootstrap'
import '../../../../styles/less/zoneManagement.less'

class ZoneDraftChanges extends Component {
    constructor(props) {
        super(props)
    }

    hide = () => {
        this.props.onClose()
    }

    render() {
        const { show, data, selectedZone } = this.props

        return (
            <Modal show={show} onHide={this.hide} className={'summary-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>Summary of changes for {selectedZone} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        {data.postalCodesAdded && data.postalCodesAdded.length > 0 && <ControlLabel>Added postal codes:</ControlLabel>}
                        <div>
                            {data.postalCodesAdded && data.postalCodesAdded.join(', ')}
                        </div>
                    </FormGroup>
                    <FormGroup>
                        {data.postalCodesRemoved && data.postalCodesRemoved.length > 0 && <ControlLabel>Removed postal codes:</ControlLabel>}
                        <div>
                            {data.postalCodesRemoved && data.postalCodesRemoved.join(', ')}
                        </div>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.hide}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

ZoneDraftChanges.propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onClose: PropTypes.func,
    selectedZone: PropTypes.string,
}

export default ZoneDraftChanges
