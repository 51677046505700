import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('drivers')

export const getDriverFeedbackById = (driverId) =>
    webapi()
        .path(driverId)
        .path('feedback')
        .get()

export const createDriverFeedback = (driverId, data) =>
    webapi()
        .path(driverId)
        .path('feedback')
        .post(data)

export const deleteDriverFeedback = (driverId, feedbackId) =>
    webapi()
        .path(driverId)
        .path('feedback')
        .path(feedbackId)
        .delete()
