export default {
    data: [],
    single: {},
    transits: [],
    transitsData: [],
    edit_route: false,
    currentRoute: {},
    deleteRoute: {},
    loading: false,
    saving: false,
    success: false,
    priceList: [],
    intervals: [],
    pickups: [],
    cpzones: [],
    warehouses: [{ value: 1, label: 'Loading ...' }],
    routeRules: [],
    sortingProfiles: [],
    routeSortingStatuses: [],
    lockerStops: [],
    collectionId: null,
    hubPallets: [],
    nonVerifiedParcels: [],
    onDemandRouteRules: [],
}
