import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('timeslots')

export const cityAvailableTimeSlots = (city) =>
    webapi()
        .path('timeslots-schedules')
        .queryParam('city', city)
        .get()
