import { ROUTE_SEARCH_INITIATED, ROUTE_SEARCH_LOADED } from '../constants/actionTypes'
import { compose } from '../utils/compose'
import { searchRoutes } from '../utils/route-webapi'
import { handleError } from '../utils/handle-error'

const searching = () => ({
    type: ROUTE_SEARCH_INITIATED,
})

const searchResultsLoaded = (results) => ({
    type: ROUTE_SEARCH_LOADED,
    results,
})

export const search = (model) => (dispatch) => {
    dispatch(searching())
    return searchRoutes(model)
        .then(compose(dispatch, searchResultsLoaded))
        .catch(handleError)
}
