import Api from './api'
import auth from '../auth'
import dev from './dev'

const baseUrl = '/api/owner-operators'
const contentType = dev('application/json', 'application/vnd.budbee.owner-operators-v1+json')
const contentTypeV2 = dev('application/json', 'application/vnd.budbee.owner-operators-v2+json')

export default {
    create(carrier) {
        return new Api(baseUrl)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentTypeV2)
            .post(carrier)
    },

    get(id) {
        return new Api(baseUrl)
            .path(id)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .get()
    },

    edit(id, carrier) {
        return new Api(baseUrl)
            .path(id)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentTypeV2)
            .put(carrier)
    },

    delete(id) {
        return new Api(baseUrl)
            .path(id)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .delete()
    },

    getPricePointList(carrierId) {
        return new Api(baseUrl)
            .path(carrierId)
            .path('price-points')
            .accept(contentType)
            .header('Authorization', auth.get())
            .type(contentType)
            .get()
    },

    createPricePoints(carrierId, request) {
        return new Api(baseUrl)
            .path(carrierId)
            .path('price-points')
            .accept(contentType)
            .header('Authorization', auth.get())
            .type(contentType)
            .post(request)
    },

    copyPricePoints(carrierId, collectionPointId, pricePoints) {
        return new Api(baseUrl)
            .path(carrierId)
            .path('price-points')
            .path('copy')
            .accept(contentType)
            .header('Authorization', auth.get())
            .type(contentType)
            .post({
                collectionPointId,
                pricePoints,
            })
    },

    removePricePoints(carrierId, pricePointIds) {
        return new Api(baseUrl)
            .path(carrierId)
            .path('price-points')
            .accept(contentType)
            .header('Authorization', auth.get())
            .type(contentType)
            .delete(pricePointIds)
    },
}
