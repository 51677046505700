import * as types from '../constants/actionTypes'
import DiscountsApi from '../utils/discounts-api'
import { handleError } from '../utils/handle-error'
import { compose } from '../utils/compose'
import { getMerchantDiscounts, createMerchantDiscount, deleteMerchantDiscount } from '../utils/merchant-webapi'

export const parcelDiscountsLoaded = (response) => ({
    type: types.PARCEL_DISCOUNTS_LOADED,
    status: 200,
    response,
})

export const fetchParcelDiscounts = (buyerId) => (dispatch) =>
    DiscountsApi.get(buyerId)
        .then(compose(dispatch, parcelDiscountsLoaded))
        .catch(handleError)

export const fetchParcelDiscountsWebapi = (merchantId) => (dispatch) =>
    getMerchantDiscounts(merchantId)
        .then(compose(dispatch, parcelDiscountsLoaded))
        .catch(handleError)

export const addDiscount = (merchantId, data) => (dispatch) =>
    createMerchantDiscount(merchantId, data)
        .then(() => dispatch(fetchParcelDiscountsWebapi(merchantId)))
        .catch(handleError)

export const deleteDiscount = (merchantId, id) => (dispatch) =>
    deleteMerchantDiscount(merchantId, id)
        .then(() => dispatch(fetchParcelDiscountsWebapi(merchantId)))
        .catch(handleError)
