import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.banner-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('banners')

export const getBanners = (country, city) =>
    webapi()
        .queryParam('country', country)
        .queryParam('city', city)
        .get()

export const getActiveBanners = () =>
    webapi()
        .queryParam('active', 'true')
        .get()

export const createBanner = (banner) => webapi().post(banner)

export const updateBanner = (id, banner) =>
    webapi()
        .path(id)
        .put(banner)
