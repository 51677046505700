import React from 'react'
import { render } from 'react-dom'
import { browserHistory } from 'react-router'
import { AppContainer } from 'react-hot-loader'
import Root from './route/Root'

import configureStore from './store/configureStore'
import '../styles/less/app.less'
import 'react-select/dist/react-select.css'
import { syncHistoryWithStore } from 'react-router-redux'
import 'react-dates/initialize'

const store = configureStore()

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store)

const node = document.getElementById('app')
const main = (R) => {
    render(
        <AppContainer>
            <R store={store} history={history} />
        </AppContainer>,
        node,
    )
}

main(Root)

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./route/Root', () => {
        const NewRoot = require('./route/Root').default
        main(NewRoot)
    })
}
