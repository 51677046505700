import Api from './api'
import auth from '../auth'

const baseUrl = '/api/roles'
const contentType = 'application/vnd.budbee.roles-v1+json'

export default {
    find(id) {
        return new Api(baseUrl)
            .accept(contentType)
            .type(contentType)
            .header('Authorization', auth.get())
            .path(id)
            .get()
    },
}
