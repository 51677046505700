import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import Select from 'react-select'
import DatePicker from 'react-bootstrap-date-picker'
import { Button, ControlLabel, Form, FormGroup, Col, Row, HelpBlock, Panel, Grid } from 'react-bootstrap'

import CountrySelect from '../../common/country-select'
import {
    getCouponsListForMerchant,
    getCouponById,
    createNewCoupon,
    deleteCouponById,
    updateCouponById,
} from '../../../utils/coupons-api'
import { shoppingSettingsConfig, getShoppingSettingsForMerchant } from '../../../utils/shopping-webapi'
import { handleError } from '../../../utils/handle-error'
import CouponsList from './coupons-list'
import MerchantInput from './merchant-input'

const initialData = {
    affiliateLink: null,
    affiliatePartner: null,
    category: null,
    code: null,
    country: null,
    description: null,
    id: null,
    merchantId: null,
    merchantThumbnailImageUrl: null,
    publishTime: null,
    terms: null,
    validFrom: null,
    validTo: null,
}

let shoppingConfig = null
class AddCouponsForm extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            ...initialData,
            couponsList: [],
            isSubmitting: false,
            showForm: false,
            submitError: null,
            updateCoupon: false,
            validationError: null,
            checkShoppingSettings: false,
        }
    }

    componentDidMount() {
        this.getShoppingSettings()
        this.getShoppingConfig()
        this.getCoupons()
    }

    getShoppingConfig = () => {
        shoppingSettingsConfig().then((res) => {
            shoppingConfig = res
        })
    }

    getShoppingSettings = () => {
        getShoppingSettingsForMerchant(this.props.merchant.id)
            .then((merchantSettingsResponse) => {
                if (merchantSettingsResponse) {
                    this.setState({ checkShoppingSettings: true })
                }
            })
            .catch((e) => {
                handleError(e)
            })
    }

    // this endpoint is not used currently, here for documentation purpose
    getCoupon = () => {
        getCouponById(this.state.id)
            .then(() => {})
            .catch(() => {})
    }

    getCoupons = () => {
        getCouponsListForMerchant(this.props.merchant.id)
            .then((res) => {
                this.setState({ couponsList: res.coupons })
            })
            .catch((error) => {
                this.setState({ submitError: error }, () => {
                    handleError(error)
                })
            })
            .finally(() => {
                this.setState({ isSubmitting: false })
                this.setState({ ...initialData })
            })
    }

    validate = () => {
        const { validFrom, description, validTo, country } = this.state

        if (!description || !validFrom || !validTo || !country) {
            this.setState({ validationError: 'Please fill the required field' })
            return false
        }

        return true
    }

    onSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const isValid = this.validate()

        if (isValid) {
            this.setState({ isSubmitting: true })
            const date = new Date()
            const {
                affiliateLink,
                affiliatePartner,
                category,
                code,
                country,
                description,
                merchantThumbnailImageUrl,
                terms,
                validFrom,
                validTo,
            } = this.state

            const data = {
                affiliateLink,
                affiliatePartner: (affiliatePartner && affiliatePartner.value) || affiliatePartner,
                category: (category && category.value) || category,
                code,
                country: (country && country.value) || country,
                description,
                merchantId: this.props.merchant.id,
                merchantThumbnailImageUrl,
                publishTime: date.toISOString(),
                terms,
                validFrom,
                validTo,
            }

            if (this.state.updateCoupon) {
                const updateCouponFunc = this.createOrupdate(updateCouponById)
                updateCouponFunc(this.state.id, data)
            } else {
                const createCouponFunc = this.createOrupdate(createNewCoupon)
                createCouponFunc(data)
            }
        }
    }

    createOrupdate = (fn) => {
        return (...args) => {
            fn(...args)
                .then(() => {
                    this.setState({ showForm: false }, () => {
                        this.getCoupons()
                    })
                })
                .catch((error) => {
                    this.setState({ submitError: error }, () => {
                        handleError(error)
                    })
                })
                .finally(() => {
                    this.setState({ isSubmitting: false, updateCoupon: false })
                    this.setState({ ...initialData })
                })
        }
    }

    handledescription(event) {
        this.setState({ description: event.target.value })
    }

    handleCouponTerms(event) {
        this.setState({ terms: event.target.value })
    }

    renderDeleteCoupon = () => {
        return (
            <Button
                bsStyle="danger"
                onClick={() => {
                    this.state.id &&
                        deleteCouponById(this.state.id).then(() => {
                            this.setState({ showForm: false }, () => {
                                this.getCoupons()
                            })
                        })
                }}
            >
                Delete coupon
            </Button>
        )
    }

    editCoupon = (couponToEdit) => {
        this.setState(
            ({ showForm }) => ({
                showForm: !showForm,
                updateCoupon: true,
            }),
            () => this.setState({ ...couponToEdit }),
        )
    }

    showForm = () => {
        this.setState(({ showForm }) => ({
            showForm: !showForm,
        }))
    }

    renderCouponFom = () => {
        const { merchant } = this.props
        const {
            validFrom,
            country,
            category,
            description,
            validationError,
            validTo,
            terms,
            affiliatePartner,
        } = this.state
        return (
            <div>
                <Form horizontal>
                    <FormGroup validationState={country ? 'success' : 'error'}>
                        <Col lg={4} lgPush={3}>
                            <ControlLabel htmlFor="couponCountry">Select Market</ControlLabel>
                            <CountrySelect
                                value={this.state.country}
                                onSelect={(selectedCountry) => this.setState({ country: selectedCountry })}
                            />
                            <HelpBlock>{validationError}</HelpBlock>
                        </Col>
                    </FormGroup>
                    <MerchantInput
                        type="text"
                        required
                        controlId="merchantName"
                        label="Coupon Heading (Merchant Name)"
                        value={merchant.externalName}
                        onChange={() => {}}
                        validate={() => true}
                        disabled
                    />
                    <MerchantInput
                        type="text"
                        required
                        value={description}
                        controlId="description"
                        label="Coupon Description"
                        placeholder="Description of the offer"
                        onChange={(e) => {
                            this.handledescription(e)
                        }}
                        validate={() => description}
                        help={validationError}
                    />
                    <MerchantInput
                        type="text"
                        controlId="terms"
                        value={terms}
                        label="Terms"
                        placeholder="Terms of the offer"
                        onChange={(e) => {
                            this.handleCouponTerms(e)
                        }}
                        validate={() => terms}
                        help={validationError}
                    />
                    <Row style={{ paddingLeft: '22px', paddingRight: '14px' }}>
                        <Col lg={4} lgPush={3}>
                            <FormGroup validationState={validFrom ? 'success' : 'error'}>
                                <ControlLabel htmlFor="validFrom">Activation Date</ControlLabel>
                                <DatePicker
                                    className="form-control"
                                    selected={validFrom}
                                    value={validFrom}
                                    dateFormat="YYYY-MM-DD"
                                    onChange={(e) => {
                                        this.setState({ validFrom: e })
                                    }}
                                    id="validFrom"
                                />
                                <HelpBlock>{validationError}</HelpBlock>
                            </FormGroup>
                        </Col>
                        <Col lg={4} lgPush={4}>
                            <FormGroup validationState={validTo ? 'success' : 'error'}>
                                <ControlLabel htmlFor="validTo">De-activation Date</ControlLabel>
                                <DatePicker
                                    className="form-control"
                                    selected={validTo}
                                    value={validTo}
                                    dateFormat="YYYY-MM-DD"
                                    onChange={(e) => {
                                        this.setState({ validTo: e })
                                    }}
                                    id="validTo"
                                />
                                <HelpBlock>{validationError}</HelpBlock>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Col lg={4} lgPush={1}>
                            <ControlLabel htmlFor="affiliatePartner">Affiliate Partner</ControlLabel>
                        </Col>
                        <Col lg={6} lgPull={1}>
                            <Select
                                id="affiliatePartner"
                                placeholder="Affiliate Partner"
                                options={
                                    shoppingConfig &&
                                    shoppingConfig.affiliatePartners.map((partner) => ({
                                        value: partner.value,
                                        label: partner.name,
                                    }))
                                }
                                value={affiliatePartner}
                                onChange={(option) => {
                                    this.setState({ affiliatePartner: option })
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <MerchantInput
                        type="text"
                        controlId="discountCode"
                        value={this.state.code}
                        label="Discount Code"
                        placeholder="Offer code"
                        onChange={(e) => {
                            this.setState({ code: e.target.value })
                        }}
                    />
                    <MerchantInput
                        type="text"
                        controlId="affiliateLink"
                        value={this.state.affiliateLink}
                        label="Affiliate Link"
                        placeholder="Link to the offer through affiliate"
                        onChange={(e) => {
                            this.setState({ affiliateLink: e.target.value })
                        }}
                    />
                    <FormGroup>
                        <Col lg={4} lgPush={1} style={{ paddingLeft: '52px' }}>
                            <ControlLabel htmlFor="category">Category</ControlLabel>
                        </Col>
                        <Col lg={6} lgPull={1}>
                            <Select
                                id="category"
                                placeholder="select a category"
                                options={
                                    shoppingConfig &&
                                    shoppingConfig.categories.map((partner) => ({
                                        value: partner.value,
                                        label: partner.name,
                                    }))
                                }
                                value={category}
                                onChange={(option) => {
                                    this.setState({ category: option })
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <MerchantInput
                        type="text"
                        controlId="merchantThumbnailImageUrl"
                        value={this.state.merchantThumbnailImageUrl}
                        label="Thumbnail Image Url"
                        placeholder="Link to merchant thumbnail image mrl"
                        onChange={(e) => {
                            this.setState({ merchantThumbnailImageUrl: e.target.value })
                        }}
                    />
                    <Button type="submit" bsStyle="primary" className="pull-right" onClick={this.onSubmit}>
                        {this.state.isSubmitting ? 'loading...' : 'Submit'}
                    </Button>
                </Form>
                {this.renderDeleteCoupon()}
            </div>
        )
    }

    render() {
        const { merchant = initialData } = this.props
        const { submitError, couponsList, checkShoppingSettings } = this.state
        const isError = submitError && submitError.statusText
        const showCoupons = couponsList.length !== 0

        if (!checkShoppingSettings) {
            return (
                <div>
                    <h5>No shopping settings avaliable. Add shopping settings before creating coupons.</h5>
                    <Link to={`/admin/merchants/${this.props.merchant.id}/shopping`}>Shopping Settings</Link>
                </div>
            )
        }

        return (
            <Grid className="show-grid">
                <Row className="g-2">
                    <Col md={2}>
                        <Button bsStyle="primary" onClick={this.showForm}>
                            {this.state.showForm ? <span>Show List</span> : <span> Create Coupon</span>}
                        </Button>
                    </Col>
                    <Col md={8}>
                        {this.state.showForm ? (
                            <div>
                                <Panel bsStyle={isError ? 'danger' : 'primary'}>
                                    {isError || (
                                        <h4>Create new coupon {merchant.externalName} start by selecting a country</h4>
                                    )}
                                </Panel>
                                {this.renderCouponFom()}
                            </div>
                        ) : (
                            <div>
                                <Panel bsStyle="primary">
                                    <h4>List of coupons for {merchant.externalName}</h4>
                                </Panel>
                                {showCoupons ? (
                                    <CouponsList editCoupon={this.editCoupon} couponsList={couponsList} />
                                ) : (
                                    <Panel bsStyle="danger">No available coupons for {merchant.externalName}</Panel>
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Grid>
        )
    }
}

AddCouponsForm.propTypes = {
    merchant: PropTypes.shape({
        externalName: PropTypes.string,
        id: PropTypes.number,
    }),
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
})

export default connect(mapStateToProps, null)(AddCouponsForm)
