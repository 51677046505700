import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

const ListRow = ({ courier, permissions }) => (
    <tr>
        <td>{courier.id}</td>
        <td>{courier.name}</td>
        <td className="text-right">
            {permissions.edit ? (
                <Link name="carriers-edit" className="btn btn-default" to={`/admin/couriers/${courier.id}`}>
                    Edit
                </Link>
            ) : null}
            {permissions.invoicing ? (
                <Link
                    name="carriers-edit-invoice"
                    className="btn btn-default"
                    to={`/admin/couriers/${courier.id}/manage-invoice-items`}
                >
                    Manage invoices
                </Link>
            ) : null}
            {permissions.invoicing ? (
                <Link
                    name="carriers-edit-rejected-invoice"
                    className="btn btn-default"
                    to={`/admin/couriers/${courier.id}/rejected-invoices`}
                >
                    Manage rejected invoices
                </Link>
            ) : null}
        </td>
    </tr>
)

export default ListRow

ListRow.propTypes = {
    courier: PropTypes.object.isRequired,
    permissions: PropTypes.object.isRequired,
}
