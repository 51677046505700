import React, { Component } from 'react'
import { Modal, Button, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'
import PropTypes from 'prop-types'

export default class DeleteConfirmation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
        }
    }

    onHide = () => {
        this.setState({ password: '' })
        this.props.onHide()
    }

    render = () => {
        return (
            <Modal show={this.props.show} onHide={this.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm deletion</Modal.Title>
                </Modal.Header>
                <FormGroup className="delete-confirmation-form-group">
                    <ControlLabel>Name of route to be deleted</ControlLabel>
                    <FormControl
                        type="text"
                        required
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                        placeholder="Name"
                    />
                </FormGroup>
                <Modal.Footer>
                    <Button onClick={this.onHide}>Cancel</Button>
                    <Button
                        disabled={this.state.password !== this.props.password}
                        bsStyle="danger"
                        onClick={this.props.onConfirm}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

DeleteConfirmation.propTypes = {
    show: PropTypes.bool.isRequired,
    password: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
}
