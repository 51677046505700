import React, { Component } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import auth from '../../../auth'
import Settings from '../../../utils/settings'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../../utils/role'
import { isEmpty } from '../../../utils/is-empty'

const getSettingTranslationsByCountryCode = (countryCode) => ({
    outsideDoorOk: 'Leave outside door',
    leaveWithNeighbour: 'Leave with neighbour',
    alternativelyLeaveWithNeighbour: 'Alternatively leave with neighbour',
    knockOnDoor: 'Knock on door instead of using doorbell',
    signatureRequired: countryCode === 'SE' ? 'Signature with BankID required' : 'Signature required',
    fraudDetection: countryCode === 'SE' ? 'Signature with BankID required' : 'Signature required',
    identificationCheckRequired: 'Identification check required',
    recipientMinimumAge: 'Minimum age of recipient',
    recipientMustMatchEndCustomer: 'Recipient must be same as end customer',
    numberOfMissRetries: 'Maximum number of times to retry a failed delivery',
    minimumTemperature: 'Minimum temperature',
    maximumTemperature: 'Maximum temperature',
    onlyDeliverInPerson: 'Only deliver in person',
    deliveryMethod: 'Delivery method',
    deliveryNotificationMethod: 'Delivery notification method',
})

const ProductCodesTable = ({ productCodes }) => {
    if (productCodes == null || productCodes.length < 1) {
        return null
    }

    return (
        <Table responsive striped>
            <thead>
                <tr>
                    <th>Product codes</th>
                </tr>
            </thead>
            <tbody>
                {productCodes.map((code) => (
                    <tr key={code.productCode}>
                        <td>{code.productCode}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

ProductCodesTable.propTypes = {
    productCodes: PropTypes.array,
}

export default class OrderSettings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editing: false,
        }
        this.orderSettings = new Settings(this.props, getSettingTranslationsByCountryCode(this.props.countryCode))
        this.toggleEdit = this.toggleEdit.bind(this)
        this.saveSettings = this.saveSettings.bind(this)
    }

    canEditSettings = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)

    handleToggle(value, setting) {
        const { onUpdateSetting } = this.props

        if (value) {
            switch (setting) {
                case 'outsideDoorOk': {
                    onUpdateSetting(false, 'leaveWithNeighbour')
                    onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                    onUpdateSetting(false, 'signatureRequired')
                    onUpdateSetting(false, 'recipientMustMatchEndCustomer')
                    onUpdateSetting(false, 'identificationCheckRequired')
                    onUpdateSetting(0, 'recipientMinimumAge')
                    break
                }
                case 'onlyDeliverInPerson':
                case 'signatureRequired':
                case 'identificationCheckRequired':
                case 'recipientMustMatchEndCustomer': {
                    onUpdateSetting(false, 'leaveWithNeighbour')
                    onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                    onUpdateSetting(false, 'outsideDoorOk')
                    onUpdateSetting('IN_PERSON', 'deliveryMethod')
                    break
                }
                case 'leaveWithNeighbour': {
                    onUpdateSetting(false, 'knockOnDoor')
                    onUpdateSetting(false, 'outsideDoorOk')
                    onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                    onUpdateSetting(false, 'signatureRequired')
                    onUpdateSetting(false, 'recipientMustMatchEndCustomer')
                    onUpdateSetting(false, 'identificationCheckRequired')
                    onUpdateSetting(0, 'recipientMinimumAge')
                    break
                }
                case 'knockOnDoor': {
                    break
                }
                default: {
                    onUpdateSetting(false, 'outsideDoorOk')
                    onUpdateSetting(false, 'leaveWithNeighbour')
                    onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                    break
                }
            }
        }

        onUpdateSetting(value, setting)
    }

    toggleEdit() {
        this.setState({ editing: true })
    }

    saveSettings() {
        const { onSaveSettings } = this.props
        this.setState({ editing: false }, () => onSaveSettings())
    }

    orderSettingsHandle = (settings, isLockerOrder) => {
        const { countryCode } = this.props
        let orderSettings
        if (isLockerOrder && countryCode === 'SE') {
            orderSettings = { fraudDetection: settings.fraudDetection }
        } else if (isLockerOrder && countryCode !== 'SE') {
            orderSettings = {}
        } else {
            orderSettings = {
                deliveryAndNotification: {
                    deliveryMethod: settings.deliveryMethod,
                    deliveryNotificationMethod: settings.deliveryNotificationMethod,
                },
                identificationOptions: {
                    onlyDeliverInPerson: settings.onlyDeliverInPerson,
                    signatureRequired: settings.signatureRequired,
                    identificationCheckRequired: settings.identificationCheckRequired,
                    recipientMinimumAge: settings.recipientMinimumAge,
                    recipientMustMatchEndCustomer: settings.recipientMustMatchEndCustomer,
                },
                other: {
                    numberOfMissRetries: settings.numberOfMissRetries,
                    singleIndoor: settings.singleIndoor,
                    doubleIndoor: settings.doubleIndoor,
                    installation: settings.installation,
                    returnOfPackaging: settings.returnOfPackaging,
                    recycling: settings.recycling,
                    swap: settings.swap,
                    minimumTemperature: settings.minimumTemperature,
                    maximumTemperature: settings.maximumTemperature,
                },
            }
        }
        return orderSettings
    }

    render() {
        const { settings, productCodes, isLockerOrder } = this.props

        const { editing } = this.state
        const { numberOfMissRetries } = settings
        const orderSettings = this.orderSettingsHandle(settings, isLockerOrder)

        if ((typeof numberOfMissRetries === 'undefined' || numberOfMissRetries === null) && !isLockerOrder) {
            settings.numberOfMissRetries = Infinity
        }

        const editBtn = this.canEditSettings && this.props.editable && (
            <Button className="pull-right" bsSize="small" onClick={editing ? this.saveSettings : this.toggleEdit}>
                {this.state.editing ? 'Done' : 'Edit'}
            </Button>
        )

        return (
            <div>
                {!isEmpty(orderSettings) && (
                    <div>
                        <div className="clearfix">{editBtn}</div>

                        {orderSettings.deliveryAndNotification ? (
                            <div>
                                <div style={{ margin: '20px 0' }}>
                                    {Object.keys(orderSettings.deliveryAndNotification).map((setting, i) =>
                                        this.orderSettings.renderSetting(
                                            setting,
                                            i,
                                            this.state.editing,
                                            this.handleToggle,
                                        ),
                                    )}
                                    <Row style={{ marginBottom: '5px' }}>
                                        <Col md={6}>Delivery PIN code:</Col>
                                        <Col md={6}>
                                            <span className="label label-default">{settings.deliveryPinCode}</span>
                                        </Col>
                                    </Row>
                                </div>

                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>Identification Options</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(orderSettings.identificationOptions).map((setting, i) =>
                                            this.orderSettings.renderSetting(
                                                setting,
                                                i,
                                                this.state.editing,
                                                this.handleToggle,
                                            ),
                                        )}
                                    </tbody>
                                </Table>
                                <Table responsive striped>
                                    <thead>
                                        <tr>
                                            <th>Other</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(orderSettings.other).map((setting, i) =>
                                            this.orderSettings.renderSetting(
                                                setting,
                                                i,
                                                this.state.editing,
                                                this.handleToggle,
                                            ),
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Settings</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(orderSettings).map((setting, i) =>
                                        this.orderSettings.renderSetting(
                                            setting,
                                            i,
                                            this.state.editing,
                                            this.handleToggle,
                                        ),
                                    )}
                                </tbody>
                            </Table>
                        )}
                        <ProductCodesTable productCodes={productCodes} />
                    </div>
                )}
            </div>
        )
    }
}

OrderSettings.propTypes = {
    editable: PropTypes.bool.isRequired,
    onUpdateSetting: PropTypes.func,
    onSaveSettings: PropTypes.func,
    settings: PropTypes.object,
    productCodes: PropTypes.array,
    // eslint-disable-next-line react/no-unused-prop-types
    countryCode: PropTypes.string.isRequired,
    isLockerOrder: PropTypes.bool,
}
