import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Glyphicon } from 'react-bootstrap'
import moment from 'moment'
import sortBy from 'lodash/sortBy'

import CollectionForm from './collections-form'
import { handleError } from '../../../../utils/handle-error'
import { deleteCollection } from '../../../../utils/shopping-collection-api'

const tableHeadingKeys = [
    'Collection Title',
    'Order',
    'Products',
    'Valid From',
    'Valid To',
    'Created on',
    'Size',
    'Actions',
]

export default class CollectionList extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            editCollection: false,
        }
    }

    renderCollections = () => {
        const collections = sortBy(this.props.group.collections, (o) => o.order)

        return (
            <div>
                <p>
                    Collections in group: <b> {this.props.group.title}</b>
                </p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {tableHeadingKeys.map((heading) => {
                                return <th key={heading}>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {collections.map((collection) => {
                            return (
                                <tr key={collection.id}>
                                    <td>{collection.title}</td>
                                    <td>{collection.order}</td>
                                    <td>{collection.products.length}</td>
                                    <td>{moment(collection.validFrom).format('YYYY-MM-DD')}</td>
                                    <td>{moment(collection.validTo).format('YYYY-MM-DD')}</td>
                                    <td>{moment(collection.created).format('YYYY-MM-DD')}</td>
                                    <td>{collection.size}</td>
                                    <td>
                                        <Button
                                            bsSize="xsmall"
                                            onClick={() => {
                                                this.setState({
                                                    editCollection: collection,
                                                })
                                            }}
                                        >
                                            EDIT
                                        </Button>
                                        <Button
                                            bsStyle="danger"
                                            bsSize="xsmall"
                                            onClick={() => {
                                                deleteCollection(this.props.group.id, collection.id)
                                                    .then(() => {
                                                        this.props.getCollectionGroup()
                                                    })
                                                    .catch((err) => {
                                                        handleError(err)
                                                    })
                                            }}
                                        >
                                            <Glyphicon glyph="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderCollections()}
                {this.state.editCollection && (
                    <CollectionForm
                        group={this.props.group}
                        collection={this.state.editCollection}
                        merchantId={this.props.merchantId}
                    />
                )}
            </div>
        )
    }
}

CollectionList.propTypes = {
    group: PropTypes.shape({
        title: PropTypes.string,
        collections: PropTypes.array,
    }).isRequired,
    merchantId: PropTypes.number,
    getCollectionGroup: PropTypes.func.isRequired,
}
