import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

const renderStatus = (status) => {
    if (!status) {
        return <span className="label label-default">Not Requested</span>
    }

    if (status === 'INITIATED') {
        return <span className="label label-info">Initated</span>
    }

    if (status === 'APPROVED') {
        return <span className="label label-success">Approved</span>
    }

    if (status === 'DENIED') {
        return <span className="label label-danger">Denied</span>
    }

    return <span className="label label-default">Unknown!</span>
}

const renderPurchase = (purchase) => (
    <tr key={purchase.reference}>
        <td>{purchase.provider}</td>
        <td>{purchase.reference}</td>
        <td>
            {purchase.costInDollars} {purchase.currencyCode}
        </td>
        <td>{purchase.consignmentId}</td>
        <td>{renderStatus(purchase.status)}</td>
        <td>{renderStatus(purchase.refundStatus)}</td>
    </tr>
)

const Purchases = (props) => {
    const { purchases } = props

    if (purchases == null || purchases.length === 0) {
        return null
    }

    return (
        <Table striped responsive condensed>
            <thead>
                <tr>
                    <th>Provider</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Consignment</th>
                    <th>Status</th>
                    <th>Refund Status</th>
                </tr>
            </thead>
            <tbody>{purchases ? purchases.map(renderPurchase) : null}</tbody>
        </Table>
    )
}

Purchases.propTypes = {
    purchases: PropTypes.array,
}

export default Purchases
