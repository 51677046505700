import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions/tags'

const toOption = ({ id: value, name: label }) => ({ value, label })

class TagSelect extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: null,
        }
    }

    onChange = (tags) => {
        this.props.onChange(tags.map((x) => x.value))
    }

    componentDidMount() {
        this.props.actions.fetchTags()
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            const { tags } = this.props
            this.setState({ selected: tags.map(toOption).filter((x) => value.includes(x.value)) })
        }
    }

    render() {
        const { tags } = this.props
        const { selected } = this.state
        return <Select multi value={selected} options={tags.map(toOption)} onChange={this.onChange} />
    }
}

const mapStateToProps = (state) => ({ tags: state.tags.tags })
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TagSelect)

TagSelect.propTypes = {
    tags: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
}
