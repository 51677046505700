import Webapi from './webapi'
import auth from '../auth'

export const getRegionalSettings = () =>
    new Webapi()
        .authorization(auth.get())
        .path('/settings/regions')
        .get()

export const getInvoiceSettings = () =>
    new Webapi()
        .authorization(auth.get())
        .path('/settings/invoice')
        .get()

export const getUISettings = () =>
    new Webapi()
        .authorization(auth.get())
        .path('/trafiki/ui-settings')
        .get()

export const getTierEnumMappings = () =>
    new Webapi()
        .authorization(auth.get())
        .path('/settings/tier-quantity-enums')
        .get()

export const getFeatureFlag = (flagName) =>
    new Webapi()
        .authorization(auth.get())
        .path('/settings/feature-flags')
        .path(flagName)
        .get()
