import React, { Component } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import Select from 'react-select'
import { arrayMove } from 'react-sortable-hoc'
import PostalCodeZones from '../../../postal-code-zones/postal-code-zones'
import SortList from './sortable'

export default class CreateModal extends Component {
    constructor(...props) {
        super(...props)
        this.state = this.getInitState()
    }

    getInitState = () => ({
        add: false,
        validate: { wh: true, zones: true },
        whouselist: [],
        postalCodeZoneIds: [],
        transits: [],
    })

    validate() {
        this.setState({
            validate: {
                zones: this.state.postalCodeZoneIds.length > 0,
                wh: this.state.whouselist.length > 0,
            },
        })

        if (this.state.postalCodeZoneIds.length > 0 && this.state.whouselist.length > 0) {
            return true
        }
        return false
    }

    onPostalCodeZoneChange(items) {
        this.setState({
            postalCodeZoneIds: items.map((item) => item.value),
            validate: { zones: true, wh: this.state.validate.wh },
        })
        this.props.addTransitsData(
            items.map((item) => item.value),
            this.state.transits,
        )
    }

    selectWarehouse(item) {
        this.state = {
            ...this.state,
            transits: [...this.state.transits, { warehouseId: item.value }],
            whouselist: [...this.state.whouselist, item.label],
            validate: { wh: true, zones: this.state.validate.zones },
        }
        this.forceUpdate()
        this.props.addTransitsData(this.state.postalCodeZoneIds, this.state.transits)
    }

    removeItem(index) {
        this.state = {
            ...this.state,
            transits: [...this.state.transits.slice(0, index), ...this.state.transits.slice(index + 1)],
            whouselist: [...this.state.whouselist.slice(0, index), ...this.state.whouselist.slice(index + 1)],
            validate: { wh: true, zones: this.state.validate.zones },
        }
        this.forceUpdate()
        this.props.addTransitsData(this.state.postalCodeZoneIds, this.state.transits)
    }

    onSortEnd({ oldIndex, newIndex }) {
        this.setState({
            whouselist: arrayMove(this.state.whouselist, oldIndex, newIndex),
            transits: arrayMove(this.state.transits, oldIndex, newIndex),
        })
        this.props.addTransitsData(this.state.postalCodeZoneIds, this.state.transits)
    }

    saveTransitRoute() {
        if (this.validate()) {
            this.setState({ add: true })
            this.props.saveTransitsData(this.state.postalCodeZoneIds, this.state.whouselist)
        }
    }

    resetState() {
        this.setState(this.getInitState())
    }

    closeModal() {
        this.resetState()
        this.props.transitReset()
    }

    cancelModal() {
        this.resetState()
        this.props.transitResetData()
    }

    render() {
        const closeBtn = (
            <button
                type="button"
                className={this.props.success == 'error' ? 'btn btn-danger' : 'btn btn-success'}
                onClick={this.closeModal.bind(this)}
            >
                Close <i className="glyphicon glyphicon-remove" />
            </button>
        )

        const cancelBtn = (
            <button type="button" className="btn" onClick={this.cancelModal.bind(this)}>
                <i className="glyphicon glyphicon-remove" /> Cancel
            </button>
        )

        return (
            <Modal show={this.props.show} animation>
                <div className="panel panel-default transits" style={{ margin: 0 }}>
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            {' '}
                            <i className="glyphicon glyphicon-map-marker" /> Create Transit Points
                        </h3>
                    </div>
                    <div className="panel-body">
                        <div className={`fielditem ${this.state.add ? 'modal-disable' : ''}`}>
                            <div className="label">1. Select Postal Code Zones</div>
                            <div className={!this.state.validate.zones ? 'modal-error' : ''}>
                                <PostalCodeZones
                                    disabled
                                    zones={this.props.postalcodes}
                                    onChange={this.onPostalCodeZoneChange.bind(this)}
                                />
                            </div>
                        </div>
                        <div className={this.state.add ? 'modal-disable' : ''}>
                            <div className="label">2. Select &amp; Sort Transit Points</div>
                            <fieldset className={!this.state.validate.wh ? 'modal-error' : ''}>
                                <Select
                                    options={this.props.warehouses.filter(
                                        (wh) => !this.state.whouselist.includes(wh.label),
                                    )}
                                    multi={false}
                                    allowCreate={false}
                                    ignoreCase
                                    searchable
                                    onChange={this.selectWarehouse.bind(this)}
                                />
                            </fieldset>
                            <br />
                            <fieldset>
                                <SortList
                                    items={this.state.whouselist}
                                    onSortEnd={this.onSortEnd.bind(this)}
                                    removeItem={this.removeItem.bind(this)}
                                />
                            </fieldset>
                        </div>
                    </div>
                    <div className="panel-footer">
                        {this.props.success == 'error' ? (
                            <div className="alert alert-danger">
                                <span style={{ padding: 20 }}>{this.props.msg}</span>
                                <span>{closeBtn}</span>
                            </div>
                        ) : null}

                        {this.props.success == 'success' ? (
                            <div className="alert alert-success">
                                <span style={{ padding: 20 }}>{this.props.msg}</span>
                                <span>{closeBtn}</span>
                            </div>
                        ) : null}

                        {!this.props.success ? (
                            <div>
                                {!this.props.added ? (
                                    <button
                                        type="button"
                                        disabled={this.props.added}
                                        className="btn btn-default right"
                                        onClick={this.saveTransitRoute.bind(this)}
                                    >
                                        <i className="glyphicon glyphicon-plus" /> Add
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-success right"
                                        disabled={!this.props.added}
                                        onClick={this.props.storeTransitsData.bind(this)}
                                    >
                                        <i className="glyphicon glyphicon-floppy-save" /> Store
                                    </button>
                                )}
                                {cancelBtn}
                            </div>
                        ) : null}
                    </div>
                </div>
            </Modal>
        )
    }
}
