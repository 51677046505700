import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Alert, Glyphicon, Button } from 'react-bootstrap'

const ReturnPalletGroupRemoveModal = ({ onHide, onConfirmRemoval, group, show }) => (
    <Modal show={show} aria-labelledby="contained-modal-title" onHide={onHide}>
        <div style={{ textAlign: 'center' }}>
            <Alert bsStyle="warning" style={{ padding: 20, margin: 0 }}>
                <span>Are you sure you want to remove {group && group.name} </span>
                <div style={{ paddingTop: 25 }}>
                    <Button onClick={onHide}>
                        <Glyphicon glyph="arrow-left" /> Cancel
                    </Button>
                    <Button bsStyle="danger" onClick={onConfirmRemoval}>
                        <Glyphicon glyph="trash" /> Delete
                    </Button>
                </div>
            </Alert>
        </div>
    </Modal>
)

ReturnPalletGroupRemoveModal.propTypes = {
    group: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    onConfirmRemoval: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
}

export default ReturnPalletGroupRemoveModal
