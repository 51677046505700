import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.orders-v2+json'
const contentTypev3 = 'application/vnd.budbee.orders-v3+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('orders')

export const orderSearchV2 = (queryParameters) => {
    const request = webapi()
        .accept('application/json')
        .contentType('application/json')
        .path('elastic')
        .path('search')

    Object.keys(queryParameters).map((key) => {
        if (queryParameters[key] != null && queryParameters[key] !== '') {
            request.queryParam([key], queryParameters[key])
        }
        return 1
    })

    return request.get()
}

export const getOrderByToken = (token) =>
    webapi()
        .path(token)
        .get()

export const getOrderConsignments = (token) =>
    webapi()
        .path(token)
        .path('consignments')
        .get()

export const getOrderDeliveryInfo = (token) =>
    webapi()
        .path(token)
        .path('delivery-info')
        .get()

export const getOrderReturnWindows = (token, from, count) =>
    webapi()
        .path(token)
        .path('return-windows')
        .path(from)
        .path(count)
        .get()

export const getOrderComments = (token) =>
    webapi()
        .path(token)
        .path('comments/admin')
        .get()

export const getOrderCallHistory = (token) =>
    webapi()
        .path(token)
        .path('call-history')
        .get()

export const getOrderNotificationHistory = (token) =>
    webapi()
        .path(token)
        .path('notification-history')
        .get()

export const getOrderEndCustomerHistory = (token) =>
    webapi()
        .path(token)
        .path('end-customer-history')
        .get()

export const getOrderDeliveryPhotos = (token) =>
    webapi()
        .path(token)
        .path('images')
        .get()

export const getOrderSignatures = (token) =>
    webapi()
        .path(token)
        .path('signatures')
        .get()

export const getOrderPurchases = (orderId) =>
    new Webapi()
        .authorization(auth.get())
        .accept('application/json')
        .path('orders')
        .path(orderId)
        .path('purchases')
        .get()

export const getFreeTimeslots = (e164PhoneNumber) =>
    new Webapi()
        .authorization(auth.get())
        .accept('application/json')
        .path('timeslots')
        .path('free')
        .queryParam('consumersE164PhoneNumber', e164PhoneNumber)
        .get()

export const createFreeTimeslot = (e164PhoneNumber, issuedReason) =>
    new Webapi()
        .authorization(auth.get())
        .contentType('application/json')
        .path('timeslots')
        .path('free')
        .post({ e164PhoneNumber, issuedReason })

export const bookDeliveryAttempt = (orderId, deliveryAttempt) =>
    webapi()
        .path(orderId)
        .path('delivery-attempts')
        .post(deliveryAttempt)

export const missDeliveryAttempt = (orderId, deliveryAttemptId, comment, categoryId) =>
    webapi()
        .path(orderId)
        .path('delivery-attempts')
        .path(deliveryAttemptId)
        .path('miss')
        .post({ categoryId, comment })

export const cancelDeliveryAttempt = (orderId, deliveryAttemptId, comment, category) =>
    webapi()
        .path(orderId)
        .path('delivery-attempts')
        .path(deliveryAttemptId)
        .path('cancel')
        .post({ category, comment })

export const cancelOrder = (orderId, reason, category) =>
    webapi()
        .path(orderId)
        .path('cancel')
        .post({ category, reason })

export const updateOrderSettings = (token, settings) =>
    webapi()
        .path(token)
        .path('order-settings')
        .put(settings)

export const addOrderComment = (token, message, permissionIds) =>
    webapi()
        .path(token)
        .path('comments')
        .post({ message, permissionIds })

export const updateDeliveryAddress = (token, request) =>
    webapi()
        .path(token)
        .path('delivery-address')
        .put(request)

export const updateConsumer = (token, endCustomer) =>
    webapi()
        .path(token)
        .path('consumer')
        .put(endCustomer)

export const createBulkSplit = (orderToken, pickupConsignmentId) =>
    webapi()
        .path(orderToken)
        .path('consignments')
        .path(pickupConsignmentId)
        .path('return-bulk-split')
        .post()

function areTerminalsPresent(terminals) {
    return terminals !== null && terminals.terminal !== null && terminals.terminal.length > 0
}

export const getOrdersToGeocodeByTerminal = (
    from,
    to,
    findNAOrders,
    excludeCancelledConsignments,
    countryCode,
    terminals,
    maxCount,
) => {
    const request = webapi()
        .contentType(contentTypev3)
        .accept(contentTypev3)
        .path('without-address-coordinates-by-terminals')
        .queryParam('from', from)
        .queryParam('to', to)
        .queryParam('findNAOrders', `${findNAOrders}`)
        .queryParam('excludeCancelledConsignments', `${excludeCancelledConsignments}`)
        .queryParam('maxCount', `${maxCount}`)

    if (countryCode !== null) {
        request.queryParam('countryCode', countryCode)
    }

    if (areTerminalsPresent(terminals)) {
        request.queryParam('terminals', terminals.terminal.map((x) => x.code).join(','))
    }

    return request.get()
}

export const getOrderJournal = (token) =>
    webapi()
        .path(token)
        .path('journal')
        .get()

export const consumerOrder = (orderID) =>
    webapi()
        .path(orderID)
        .get()

export const getOrderDriverFeedback = (orderID) =>
    webapi()
        .path(orderID)
        .path('driver-feedback')
        .get()

export const getVerifyConvertToHome = (orderToken) =>
    webapi()
        .path(orderToken)
        .path('verify')
        .path('convert-to-home')
        .get()
export const convertToHomeDelivery = (orderToken, request) =>
    webapi()
        .path(orderToken)
        .path('delivery-type')
        .put(request)

export const createStandaloneReturn = (token, request) =>
    webapi()
        .path(token)
        .path('locker-return')
        .post(request)
