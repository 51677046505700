import React from 'react'
import { FormGroup, Col, FormControl, ControlLabel, HelpBlock, Button, Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'

const MerchantInput = (props) => {
    const { type, controlId, label, value, onChange, placeholder, required, help, validate, action, ...rest } = props
    return (
        <FormGroup
            controlId={controlId}
            validationState={(required && !value) || (validate != null && !validate(value)) ? 'error' : null}
        >
            <Col componentClass={ControlLabel} sm={3}>
                {label}
            </Col>
            <Col sm={action ? 8 : 9}>
                <FormControl
                    type={type}
                    required={required}
                    value={value == null ? '' : value}
                    onChange={onChange}
                    placeholder={placeholder}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                />
                {help && <HelpBlock>{help}</HelpBlock>}
            </Col>
            {action && (
                <Col sm={1} style={{ padding: 0 }}>
                    <Button bsStyle={action.style} onClick={action.callback}>
                        <Glyphicon glyph={action.icon} />
                    </Button>
                </Col>
            )}
        </FormGroup>
    )
}

MerchantInput.propTypes = {
    type: PropTypes.string,
    controlId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    help: PropTypes.string,
    validate: PropTypes.func,
    action: PropTypes.shape({
        callback: PropTypes.func,
        icon: PropTypes.string,
        style: PropTypes.oneOf(['danger', 'warning', 'success', 'primary']),
    }),
}

export default MerchantInput
