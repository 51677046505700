import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const HtmlTitle = ({ title }) => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
    </Helmet>
)

HtmlTitle.defaultProps = {
    title: 'Budbee',
}

HtmlTitle.propTypes = {
    title: PropTypes.string,
}

export default HtmlTitle
