/**
 *
 * @param  {String} value
 * @returns true if string is empty
 */
const isEmptyString = (value) => {
    return value.trim() === ''
}

/**
 *
 * @param {String | Boolean | Number} value
 * @returns true if value is null, undefined or empty string (spaces equals empty)
 */
const isEmpty = (value) => {
    if (value === null || value === undefined) {
        return true
    }
    if (typeof value === 'string' || value instanceof String) {
        return isEmptyString(value)
    }
    return false
}

export default isEmpty
