import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loader from 'react-loader'
import { Button, Col } from 'react-bootstrap'
import * as actions from '../../actions/collection-points'
import auth from '../../auth'
import HtmlTitle from '../html-title'
import CollectionPoint from './collection-point'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

class CollectionPoints extends Component {
    componentDidMount() {
        if (!auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin/merchants')
            return
        }
        this.props.actions.fetchListData()
        this.props.actions.fetchCPData(this.props.params.id)
    }

    hasElevatedActions = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER)

    transitionToCreateCollectionPoint = () => {
        this.props.router.push(`/admin/merchants/${this.props.params.id}/create-collection-point`)
    }

    render() {
        const { buyer } = this.props
        const { id } = this.props.params
        if (!buyer || buyer.id !== parseInt(id, 10)) {
            return <Loader />
        }

        return (
            <Col md={10} mdOffset={1}>
                <HtmlTitle title={this.props.buyer.name} />
                <h2 className="mb-md">Collection points</h2>
                <div className="page-header">
                    <h3>{this.props.buyer.name}</h3>
                </div>
                {this.hasElevatedActions && (
                    <Button bsStyle="primary" onClick={this.transitionToCreateCollectionPoint}>
                        Create collection point
                    </Button>
                )}
                <div style={{ paddingTop: 20 }}>
                    {this.props.buyer.collectionPoints.map((cp) => (
                        <CollectionPoint
                            key={cp.id}
                            buyerId={this.props.buyer.id}
                            cp={cp}
                            pricePoints={this.props.pricePoints.filter((pp) => pp.collectionId === cp.id)}
                            transits={this.props.transits}
                            transitsData={this.props.transitsData}
                            warehouses={this.props.warehouses}
                            loading={this.props.loading}
                            saving={this.props.saving}
                            canDeletePricePoints={this.hasElevatedActions}
                        />
                    ))}
                </div>
            </Col>
        )
    }
}

const mapStateToProps = ({ collection, buyers }) => ({
    warehouses: collection.warehouses,
    pricePoints: collection.priceList,
    buyer: buyers.buyer,
    transitsData: collection.transitsData,
    transits: collection.transits,
    loading: collection.loading,
    saving: collection.saving,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CollectionPoints)

CollectionPoints.propTypes = {
    router: PropTypes.object,
    actions: PropTypes.object,
    params: PropTypes.object,
    warehouses: PropTypes.array,
    pricePoints: PropTypes.array,
    buyer: PropTypes.object,
    transitsData: PropTypes.array,
    transits: PropTypes.array,
    loading: PropTypes.bool,
    saving: PropTypes.bool,
}
