export const curry = function(fn) {
    const arity = fn.length

    return function $curry(...args) {
        if (args.length < arity) {
            return $curry.bind(this, ...args)
        }

        return fn.call(this, ...args)
    }
}
