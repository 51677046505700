import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty } from 'lodash'
import cx from 'classnames'
import { connect } from 'react-redux'
import { Col, Alert } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import auth from '../../auth'
import * as loadingSchedulerActions from '../../actions/loading-scheduler'
import TerminalSelect from '../common/terminal-select'
import { groupSlots, now, formattedTime } from '../../utils/slots'
import Header from './header'
import HtmlTitle from '../html-title'
import * as selectors from '../../selectors'
import SlotItem from './slot-item'
import {
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

const REFRESH_TIME = 20 * 1000

const msgStyle = { width: '50%', margin: 'auto', textAlign: 'center' }

const failLoadMsg = (
    <Alert bsStyle="danger" style={msgStyle}>
        <strong>Some Resources failed to load!</strong>
    </Alert>
)

const getUserTerminal = (me) => {
    return me != null && me.userSettings != null ? me.userSettings.warehouse : null
}

const noLoadingSlotsMsg = (
    <Alert bsStyle="warning" style={msgStyle}>
        <strong>No loading slots found</strong>
    </Alert>
)

class LoadingScheduler extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            currentTime: now(),
            selectedTerminal: getUserTerminal(props.currentUser),
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        this.timeInterval = setInterval(() => {
            this.setState({ currentTime: now() })
        }, 1000)
    }

    componentWillUnmount() {
        if (this.slotsInterval !== null) {
            clearInterval(this.slotsInterval)
        }
        if (this.timeInterval !== null) {
            clearInterval(this.timeInterval)
        }
    }

    onTerminalSelect = (value) => {
        const today = moment.utc().format('YYYY-MM-DD')
        this.setState({ selectedTerminal: value }, () => this.fetchLoadingSlotsPeriodically(today))
    }

    fetchLoadingSlots = (date) => {
        this.props.actions.fetchLoadingSlots(this.state.selectedTerminal.id, date)
    }

    fetchLoadingSlotsPeriodically = (date) => {
        if (this.slotsInterval != null) {
            clearInterval(this.slotsInterval)
        }

        if (this.state.selectedTerminal != null) {
            this.fetchLoadingSlots(date)
            this.slotsInterval = setInterval(() => this.fetchLoadingSlots(date), REFRESH_TIME)
        }
    }

    timeInterval = null

    slotsInterval = null

    renderSlotRow = ({ loadStart, relatedSlots }, index) => {
        const { currentTime, selectedTerminal } = this.state
        const groupedSlots = relatedSlots.map((slot) => (
            <SlotItem key={slot.routeId} address={selectedTerminal.address} currentTime={currentTime} slot={slot} />
        ))

        const rowCLassNames = cx({ 'slot-container': true })
        return (
            <div className={rowCLassNames} key={index}>
                <div className="slot-time">
                    <strong>{formattedTime(loadStart, selectedTerminal.address.countryCode)}</strong>
                </div>
                <div className="slot-row">{groupedSlots}</div>
            </div>
        )
    }

    renderTerminalSelect() {
        const { selectedTerminal } = this.state
        return (
            <Col md={8} mdOffset={2}>
                <h1>Select Terminal</h1>
                <TerminalSelect value={selectedTerminal} onSelect={this.onTerminalSelect} />
            </Col>
        )
    }

    renderBody() {
        const { slots } = this.props
        const slotsByLoadingTime = groupSlots(slots, (x) => x.start)

        return (
            <div className="loading-container">
                <div className="loading-slot-container">
                    {Object.keys(slotsByLoadingTime).map((key, index) =>
                        this.renderSlotRow(
                            {
                                loadStart: Number(key),
                                relatedSlots: slotsByLoadingTime[key],
                            },
                            index,
                        ),
                    )}
                </div>
            </div>
        )
    }

    render() {
        const {
            slots,
            error: { status },
            isFetching,
        } = this.props
        const { currentTime, selectedTerminal } = this.state

        if (status === 500) {
            return failLoadMsg
        }
        const flexStyles = { display: 'flex', flexDirection: 'column', boxSizing: 'content-box' }

        return (
            <div style={flexStyles}>
                <HtmlTitle title="Terminal loading" />
                {this.renderTerminalSelect()}
                {!isEmpty(selectedTerminal) && (
                    <div style={flexStyles}>
                        <Header selectedTerminal={selectedTerminal} currentTime={currentTime} />
                        {!isFetching && slots.length === 0 ? noLoadingSlotsMsg : this.renderBody()}
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    slots: selectors.notCompletedSlots(state),
    isFetching: state.loadingScheduler.isFetching,
    error: state.loadingScheduler.error,
    currentUser: state.users.current,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(loadingSchedulerActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScheduler)

LoadingScheduler.propTypes = {
    actions: PropTypes.object,
    router: PropTypes.object,
    slots: PropTypes.array,
    isFetching: PropTypes.bool,
    error: PropTypes.object,
    currentUser: PropTypes.object,
}
