import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import { Row, Col, FormGroup, ControlLabel, FormControl, Button, Label } from 'react-bootstrap'
import HtmlTitle from '../html-title'
import { handleError } from '../../utils/handle-error'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { mapSort, sortStrings } from '../../utils/sorting'
import { prop } from '../../utils/prop'
import '../../../styles/less/zoneManagement.less'
import { getRegexForPostalCodeValidation, convertArrayOfPostalCodesToString, convertStringOfPostalCodesToArray } from '../../utils/postalCodeValidation'
import cloneDeep from 'lodash/cloneDeep'
import getNameFromCountryCode from '../../utils/country-codes'
import { OPERATIONS_COORDINATOR } from '../../utils/role'

import * as actions from '../../actions/zoneManagement'
import * as terminalActions from '../../actions/terminals'

import auth from '../../auth'

class ZoneManagementEditHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            saving: false,
            environmentalPostalCodes: [],
            nonEnvironmentalPostalCodes: [],
            environmentalPostalCodesString: '',
            nonEnvironmentalPostalCodesString: '',
            environmentalPostalCodesError: [],
            nonEnvironmentalPostalCodesError: [],
            terminal: null,
            errorMessage: '',
            city: ''
        }
    }

    componentDidMount() {
        const { terminalCode, groupId } = this.props.params

        if (!auth.hasAnyRole(OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin')
            return
        }

        this.props.terminalActions.fetchTerminalsWebapi()
            .then(() => {
                const { terminals } = this.props
                this.setState({ terminal: terminals.find((x) => x.code === terminalCode), terminalName: terminals.find((x) => x.code === terminalCode).name })
            })

        this.props.actions.fetchGroup(groupId)

        this.props.actions
            .fetchPostalCodeZones(groupId)
            .then(() =>
                this.setState({
                    nonEnvironmentalPostalCodes: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).postalCodes
                        .filter((x) => x.fossilFree === false)
                        .sort(mapSort(sortStrings, prop('postalCode'))),
                    environmentalPostalCodes: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).postalCodes
                        .filter((x) => x.fossilFree === true)
                        .sort(mapSort(sortStrings, prop('postalCode'))),
                    city: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).city
                }),
            )
            .then(() =>
                this.setState({
                    environmentalPostalCodesString: convertArrayOfPostalCodesToString(this.state.environmentalPostalCodes),
                    nonEnvironmentalPostalCodesString: convertArrayOfPostalCodesToString(this.state.nonEnvironmentalPostalCodes),
                }),
            )
            .finally(() => this.setState({ loading: false }))
    }

    onCancel = () => this.transition()

    transition = () => {
        const { type, groupName, groupId } = this.props.params
        this.props.router.push(`/admin/zone-management/${type}/${groupName}/${groupId}/edit`)
    }

    onBlur = (stringName, fieldName, postalCodes, isFossilFree, errorName) => {
        const { country } = this.props.zoneManagement.group

        let arrayOfPostalCodes = convertStringOfPostalCodesToArray(postalCodes, isFossilFree, country)
        let stringOfPostalCodes = convertArrayOfPostalCodesToString(arrayOfPostalCodes)

        this.setState({ [fieldName]: arrayOfPostalCodes, [stringName]: stringOfPostalCodes, errorMessage: [] })
        this.validatePostalCodes(arrayOfPostalCodes, fieldName, errorName)

    }

    validatePostalCodes = (array, fieldName, errorName) => {
        const { country } = this.props.zoneManagement.group
        this.setState({ [errorName]: '' })
        let invalidError = []
        let postalCodes = []
        let duplicateError = []

        array.forEach((element, index) => {
            if (!element.postalCode.match(getRegexForPostalCodeValidation(country))) {

                invalidError.push(`${element.postalCode}" on row ${index + 1} seems to be an invalid postal code for ${getNameFromCountryCode(country)}`)
            }
            this.setState({ [errorName]: invalidError })
            postalCodes.push(element.postalCode)
        });

        fieldName === 'environmentalPostalCodes' ?
            (
                this.state.nonEnvironmentalPostalCodes.forEach(element => {
                    postalCodes.push(element.postalCode)
                })
            )
            :
            (
                this.state.environmentalPostalCodes.forEach(element => {
                    postalCodes.push(element.postalCode)
                })
            )

        for (let i = 0; i < postalCodes.length - 1; i++) {
            if (postalCodes.sort()[i + 1] === postalCodes[i]) {
                duplicateError.push(postalCodes[i]);
            }
        }

        duplicateError.length > 0 ? this.setState({ errorMessage: `One or more duplicate postal codes detected. "${duplicateError.join(', ')}" seem to appear in more than one place` }) : this.setState({ errorMessage: '' })
    }

    onSuccess = () => { this.setState({ saving: false }), this.transition() }

    onError = (response) => {
        if (response.statusText.length > 0) {
            this.setState({ errorMessage: response.statusText, saving: false })
        }
        if (response.statusText.length === 0) {
            this.setState({ errorMessage: 'Something went wrong. Make sure all postalcodes are valid for the country', saving: false })
        }
        handleError(response)
    }

    onSave = () => {
        this.setState({ saving: true })
        const { group } = this.props.zoneManagement
        const { groupId } = this.props.params

        var payload = cloneDeep(group)

        let index = payload.postalCodeZones.findIndex((x) => x.terminalCode === this.state.terminal.code)

        if (index !== -1) {
            payload.postalCodeZones[index].postalCodes = this.state.environmentalPostalCodes.concat(this.state.nonEnvironmentalPostalCodes)
        }

        this.props.actions.updateGroup(groupId, payload)
            .then((response) => {
                response.status === 200 && this.onSuccess()
                response.status === 400 && this.onError(response)
            })
            .catch((e) => {
                this.setState({ loading: false })
                handleError(e)
            })
    }

    onChange = (type, value) => this.setState({ [type]: value })

    onChangePostalCodes = (type, value) => {
        this.setState({ [type]: value })
            .then(() =>
                this.setState({
                    nonEnvironmentalPostalCodes: this.props.zoneManagement.postalCodeZones.find((x) => x.terminalCode === terminalCode).postalCodes
                        .filter((x) => x.fossilFree === false)
                        .sort(mapSort(sortStrings, prop('postalCode'))),
                }),
            )
    }

    render() {
        const { type } = this.props.params
        const { terminalName, city } = this.state

        return (
            <div>
                <HtmlTitle title="Edit zone" />
                <Loader color="#bfbfbf" loaded={!this.state.loading && !this.state.saving}>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <Col md={12}>
                                <FormGroup>
                                    <h1>{`Edit ${terminalName} ${type} Zone`}</h1>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Terminal</ControlLabel>
                                    <FormControl name="name" value={terminalName} readOnly />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>City</ControlLabel>
                                    <FormControl name="city" value={city} readOnly />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Environmental postal codes</ControlLabel>
                                    <FormControl
                                        className={this.state.environmentalPostalCodesError.length === 0 ? 'postal-code-textarea' : 'postal-code-textarea error'}
                                        componentClass="textarea"
                                        value={this.state.environmentalPostalCodesString}
                                        onChange={(e) => this.onChange('environmentalPostalCodesString', e.target.value)}
                                        onBlur={(e) => this.onBlur('environmentalPostalCodesString', 'environmentalPostalCodes', e.target.value, true, 'environmentalPostalCodesError')}
                                    />
                                </FormGroup>
                                {this.state.environmentalPostalCodesError && this.state.environmentalPostalCodesError.map((message, index) => (
                                    <Col key={index}>
                                        <Label bsStyle="danger">{message}</Label>
                                    </Col>

                                ))}
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <ControlLabel>Non-environmental postal codes</ControlLabel>
                                    <FormControl
                                        className={this.state.nonEnvironmentalPostalCodesError.length === 0 ? "postal-code-textarea" : "postal-code-textarea error"}
                                        componentClass="textarea"
                                        value={this.state.nonEnvironmentalPostalCodesString}
                                        onChange={(e) => this.onChange('nonEnvironmentalPostalCodesString', e.target.value)}
                                        onBlur={(e) => this.onBlur('nonEnvironmentalPostalCodesString', 'nonEnvironmentalPostalCodes', e.target.value, false, 'nonEnvironmentalPostalCodesError')}
                                    />
                                </FormGroup>
                                {this.state.nonEnvironmentalPostalCodesError && this.state.nonEnvironmentalPostalCodesError.map((message, index) => (
                                    <Col key={index}>
                                        <Label bsStyle="danger">{message}</Label>
                                    </Col>

                                ))}
                            </Col>
                            <Col md={3} mdOffset={9}>
                                <FormGroup>
                                    <Button
                                        className="pull-right"
                                        type="button"
                                        bsStyle="success"
                                        onClick={this.onSave}
                                        disabled={
                                            this.state.environmentalPostalCodesError.length > 0 ||
                                            this.state.nonEnvironmentalPostalCodesError.length > 0 ||
                                            this.state.saving ||
                                            this.state.environmentalPostalCodesString.length + this.state.nonEnvironmentalPostalCodesString.length === 0 ||
                                            this.state.errorMessage.length > 0
                                        }
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        className="pull-right"
                                        type="button"
                                        bsStyle="default"
                                        onClick={this.onCancel}
                                    >
                                        Cancel
                                    </Button>
                                </FormGroup>
                            </Col>
                            {this.state.errorMessage &&
                                <Row>
                                    <Col md={12}>
                                        <div className='error-message'>
                                            <span>{this.state.errorMessage}</span>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = ({
    zoneManagement,
    terminals,
}) => ({
    zoneManagement,
    terminals: terminals.terminals
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagementEditHandler)

ZoneManagementEditHandler.propTypes = {
    router: PropTypes.object,
    actions: PropTypes.object,
    zoneManagement: PropTypes.object,
    params: PropTypes.object,
    terminalActions: PropTypes.object,
}
