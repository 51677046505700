import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('financial')

export const recurringPaymentTransactions = (recurringPaymentIds) =>
    webapi()
        .path('recurring-payment-transactions-query')
        .post({ recurringPaymentIds })
