import { FETCHING_TAGS, TAGS_LOADED, TAGS_FAILED_TO_LOAD } from '../constants/actionTypes'
import defaultState from './initialState/tags-state'

export default function TagsReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCHING_TAGS:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case TAGS_LOADED:
            return {
                ...state,
                tags: action.tags,
                loading: false,
                error: null,
            }

        case TAGS_FAILED_TO_LOAD:
            return {
                ...state,
                tags: [],
                loading: false,
                error: action.error,
            }

        default:
            return state
    }
}
