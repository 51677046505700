import React from 'react'
import { Col } from 'react-bootstrap'
import DistributionSettingsForm from './components/distribution-settings-form'

const Distribution = () => (
    <Col md={10} mdOffset={1} lg={7}>
        <h2 className="mb-md">Distribution</h2>
        <DistributionSettingsForm />
    </Col>
)

export default Distribution
