import Webapi from './webapi'
import auth from '../auth'

const shoppingV1 = 'application/json'
const merchantsV1 = 'application/vnd.budbee.merchants-v1+json'

const webapi = (accept, contentType = accept) => {
    let w = new Webapi().authorization(auth.get()).path('shopping')

    if (accept != null) {
        w.accept(accept)
    }
    if (contentType != null) {
        w = w.contentType(contentType)
    }

    return w
}

export const getShoppingSettingsForMerchant = (id) =>
    webapi()
        .path('merchants')
        .path(id)
        .get()

export const shoppingSettingsConfig = () =>
    webapi()
        .path('config')
        .get()

export const getMerchantById = (id) =>
    webapi()
        .path('v1')
        .path('merchants')
        .path(id)
        .get()

export const createLegacyMerchantSettings = (request) =>
    webapi(merchantsV1)
        .path('merchants')
        .post(request)

export const createMerchantSettings = (request) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .post(request)

export const getAffiliates = () =>
    webapi()
        .path('v1')
        .path('affiliates')
        .get()

export const getCategories = () =>
    webapi()
        .path('v1')
        .path('categories')
        .get()

export const createAffiliateProgram = (id, request) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .path(id)
        .path('programs')
        .post(request)

export const deleteAffiliateProgram = (id, storeId, affiliateId) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .path(id)
        .path('stores')
        .path(storeId)
        .path('programs')
        .path(affiliateId)
        .delete()

export const createStore = (id, request) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .path(id)
        .path('stores')
        .post(request)

export const updateStore = (id, storeId, request) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .path(id)
        .path('stores')
        .path(storeId)
        .put(request)

export const deleteStore = (id, storeId) =>
    webapi(shoppingV1)
        .path('v1')
        .path('merchants')
        .path(id)
        .path('stores')
        .path(storeId)
        .delete()
