import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default class Uploader extends Component {
    constructor(props) {
        super(props)
        this.state = { dragOver: false }
    }

    dragLeave() {
        this.setState({ dragOver: false })
    }

    dragOver(e) {
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'

        this.setState({ dragOver: true })
    }

    drop(e) {
        e.preventDefault()

        this.setState({ dragOver: false })

        let files
        if (e.dataTransfer) {
            files = e.dataTransfer.files
        } else if (e.target) {
            files = e.target.files
        }

        this.props.onUpload(files)
    }

    clickUpload() {
        this.fileControl.click()
    }

    render() {
        const uploaderClasses = {
            uploader: true,
            active: this.state.dragOver,
        }

        const { accept } = this.props

        const acceptedFiles = accept ? accept.join(',') : ''

        return (
            <div
                style={{ backgroundColor: 'white' }}
                className={cx(uploaderClasses)}
                onDragLeave={this.dragLeave.bind(this)}
                onDragOver={this.dragOver.bind(this)}
                onDrop={this.drop.bind(this)}
            >
                <div className="uploader-prompt">
                    <span className="artwork-file-container">
                        <input
                            type="file"
                            className="artwork-file-control"
                            ref={(c) => {
                                this.fileControl = c
                            }}
                            onChange={this.drop.bind(this)}
                            accept={acceptedFiles}
                        />
                        <button type="button" className="btn btn-link" onClick={this.clickUpload.bind(this)}>
                            choose an image
                        </button>
                    </span>
                    drag and drop
                </div>
                <div className="uploader-progress">Upload image...</div>
                <div className="uploader-success">Upload successful</div>
                <div className="uploader-error">
                    There was a problem with the upload{' '}
                    <button type="button" className="btn btn-link">
                        Retry
                    </button>
                </div>
            </div>
        )
    }
}

Uploader.propTypes = {
    accept: PropTypes.array,
    onUpload: PropTypes.func.isRequired,
}
