import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import EditForm from './components/edit-form'
import { GENERAL_SETTINGS } from '../../utils/couriers'

const Settings = ({ params }) => (
    <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
        <EditForm showSettings={GENERAL_SETTINGS} courierId={params.id} />
    </Col>
)

Settings.propTypes = {
    params: PropTypes.object.isRequired,
}

export default Settings
