import trim from './trim'
import auth from '../auth'
import { findWebapiUrlFromHostname } from './service-url'

class Webapi {
    constructor(parseResponse = true) {
        this.url = findWebapiUrlFromHostname()
        this.parseResponse = parseResponse
        this.headers = {}
        this.queryParams = {}
        this.urlParts = []
    }

    path(part) {
        this.urlParts.push(part)
        return this
    }

    header(header, val) {
        this.headers[header] = val
        return this
    }

    authorization(token) {
        return this.header('Authorization', token)
    }

    accept(value) {
        return this.header('Accept', value)
    }

    contentType(value) {
        return this.header('Content-Type', value)
    }

    queryParam(key, val) {
        if (this.queryParams[key] == null) {
            this.queryParams[key] = []
        }
        this.queryParams[key].push(val)

        return this
    }

    get() {
        return this.request('get')
    }

    post(data, isJson) {
        return this.request('post', data, isJson)
    }

    put(data, isJson) {
        return this.request('put', data, isJson)
    }

    delete(data, isJson) {
        return this.request('delete', data, isJson)
    }

    request(method, data, isJson = true) {
        const url = this.getUrl()
        const body = (function b() {
            if (data != null) {
                if (isJson) {
                    return JSON.stringify(data)
                }

                return data
            }

            return undefined // FF treats `null` as a body
        })()

        return fetch(url, {
            method,
            headers: this.headers,
            body,
        })
            .then((res) => {
                const p = Webapi.status(res)
                if (this.parseResponse) {
                    return p.then(Webapi.parse)
                }

                return p
            })
            .catch((err) => {
                if (auth.hasAuthExpired()) {
                    auth.clear()
                    window.router.push('/login')
                }

                return Promise.reject(err)
            })
    }

    getUrl() {
        const parts = this.urlParts
            .filter((part) => part != null)
            .map((part) => part.toString())
            .map((part) => trim(part, '/'))
            .join('/')

        const query = Object.keys(this.queryParams)
            .reduce((q, key) => {
                const values = this.queryParams[key]
                return [...q, ...values.map((v) => `${encodeURIComponent(key)}=${v ? encodeURIComponent(v) : ''}`)]
            }, [])
            .join('&')

        return trim(`${this.url}/${parts}?${query}`, '/?')
    }

    static status(res) {
        if (res.status >= 200 && res.status < 300) {
            return Promise.resolve(res)
        }
        return Promise.reject(res)
    }

    static parse(res) {
        return res.json().catch(() => Promise.resolve())
    }
}

export default Webapi
