import polyline from '@mapbox/polyline'

export const decodePolylines = (encodedPolylines) => {
    let results = []
    for (const i in encodedPolylines) {
        const encodedLine = encodedPolylines[i]
        const coordinates = polyline.decode(encodedLine).map(([longitude, latitude]) => [latitude, longitude])
        results = results.concat(coordinates)
    }

    return results
}
