import {
    FETCHING_GROUPS,
    FETCHING_GROUP,
    FETCHING_LATEST_GROUPS,
    FETCHING_DIFF_BETWEEN_GROUPS,
    FETCHING_PREVIOUS_GROUP,
    FETCHING_NEXT_GROUP,
    GROUPS_LOADED,
    LATEST_GROUPS_LOADED,
    DIFF_BETWEEN_GROUPS_LOADED,
    GROUP_LOADED,
    PREVIOUS_GROUP_LOADED,
    NEXT_GROUP_LOADED,
    GROUP_CREATED,
    GROUP_DELETED,
    GROUP_POSTAL_CODE_ZONES_LOADED,
    GROUP_UPDATED,
    GROUP_PUBLISHED,
    GROUP_UNPUBLISHED,
    PUBLISHING_GROUP,
    UNPUBLISHING_GROUP,
} from '../constants/actionTypes'
import defaultState from './initialState/zone-management-state'
import { mapSort, sortStrings } from '../utils/sorting'
import { prop } from '../utils/prop'

export default function ZoneManagementReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCHING_GROUPS:
            return {
                ...state,
                loaded: false,
            }

        case FETCHING_GROUP:
            return {
                ...state,
                loaded: false,
            }

        case FETCHING_LATEST_GROUPS:
            return {
                ...state,
                loaded: false,
            }

        case FETCHING_DIFF_BETWEEN_GROUPS:
            return {
                ...state,
                loaded: false,
            }

        case FETCHING_PREVIOUS_GROUP:
            return {
                ...state,
                loaded: false,
            }

        case FETCHING_NEXT_GROUP:
            return {
                ...state,
                loaded: false,
            }

        case PUBLISHING_GROUP:
            return {
                ...state,
                loaded: false,
            }

        case UNPUBLISHING_GROUP:
            return {
                ...state,
                loaded: false,
            }

        case GROUPS_LOADED:
            return {
                ...state,
                groups: action.response.groups,
            }

        case LATEST_GROUPS_LOADED:
            return {
                ...state,
                groups: action.response.groups,
            }

        case DIFF_BETWEEN_GROUPS_LOADED:
            return {
                ...state,
            }

        case GROUP_LOADED:
            return {
                ...state,
                group: action.response,
            }

        case PREVIOUS_GROUP_LOADED:
            return {
                ...state,
            }

        case NEXT_GROUP_LOADED:
            return {
                ...state,
            }

        case GROUP_POSTAL_CODE_ZONES_LOADED:
            return {
                ...state,
                postalCodeZones: action.response.postalCodeZones.sort(mapSort(sortStrings, prop('name'))),
            }

        case GROUP_CREATED:
            return {
                ...state,
            }

        case GROUP_UPDATED:
            return {
                ...state,
            }

        case GROUP_DELETED:
            return {
                ...state,
            }

        case GROUP_PUBLISHED:
            return {
                ...state,
            }

        case GROUP_UNPUBLISHED:
            return {
                ...state,
            }

        default:
            return state
    }
}
