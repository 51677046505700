import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as actions from '../../../actions/couriers'
import { compensationTypes } from '../../../utils/couriers'
import TierType from './tier-type'
import { DATE_FORMAT } from '../../../utils/date'

class TiersList extends Component {
    componentDidMount = () => {
        const today = moment
            .utc()
            .subtract(60, 'days')
            .format(DATE_FORMAT)
        this.props.actions.fetchCompensationTiers(this.props.courierId, today)
    }

    renderTypes = (courierId) =>
        compensationTypes
            .filter((type) => type.group === this.props.group)
            .map((type) => <TierType courierId={courierId} key={type.id} type={type} />)

    render() {
        const { loading, courierId } = this.props

        if (loading) {
            return null
        }

        return (
            <Row>
                <Col sm={12} md={10} mdOffset={1}>
                    {this.renderTypes(courierId)}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    error: state.couriers.error,
    loading: state.couriers.loadingCompensationTiers,
    compensationTiers: state.couriers.compensationTiers,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TiersList)

TiersList.propTypes = {
    actions: PropTypes.object,
    courierId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    group: PropTypes.any.isRequired,
}
