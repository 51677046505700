import React, { Component } from 'react'
import { Button, ControlLabel, Form, FormControl, FormGroup, Glyphicon, Modal } from 'react-bootstrap'
import Loader from 'react-loader'
import PropTypes from 'prop-types'
import DatePicker from 'react-bootstrap-date-picker'
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import CurrencySelect from '../common/currency-select'
import * as actions from '../../actions/regional-settings'
import { hd } from '../../utils/hd'
import memoize from '../../utils/memoize'
import { handleError } from '../../utils/handle-error'

const toOption = ({ id: value, englishExonym: label }) => ({ value, label })
const toCents = (amount) => amount * 100

class CreateModal extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            isSubmitting: false,
            ...this.initialState(),
        }
    }

    initialState = (props = this.props) => {
        const city =
            props.prefill != null && props.prefill.city != null && props.regionalSettings.cities != null
                ? props.regionalSettings.cities.find(({ id }) => id === props.prefill.city)
                : null
        const country = city != null ? props.regionalSettings.countries.find(({ id }) => id === city.countryCode) : null
        const currency =
            country != null ? props.regionalSettings.currencies.find(({ id }) => id === country.currency) : null
        return {
            regionalSettings: props.regionalSettings,
            description: '',
            date:
                props.prefill != null && props.prefill.date != null
                    ? moment(props.prefill.date, 'YYYY-MM-DD')
                    : moment(),
            invoiceAccountType: hd(props.invoiceTypes),
            invoiceArticle: hd(props.invoiceArticles),
            amountInCents: 0,
            copies: 1,
            currency: currency != null ? currency.id : '',
            country: country != null ? country.id : '',
            city: city != null ? city.id : null,
            customInvoiceDescription: '',
            invoiceRecipient: null,
        }
    }

    closeModal = () => this.props.close()

    clear = () => this.setState(this.initialState())

    componentDidMount() {
        this.props.actions.fetchRegionalSettings()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.show && nextProps.show) {
            this.setState(this.initialState(nextProps))
        }
    }

    onSubmit = (e) => {
        this.setState({ isSubmitting: true })
        e.preventDefault()
        for (let i = 0; i < this.state.copies; i += 1) {
            this.props
                .createInvoiceItem(this.props.invoiceRecipientId, {
                    description: this.state.description,
                    date: this.state.date.format('YYYY-MM-DD'),
                    invoiceAccountType: this.state.invoiceAccountType.value,
                    invoiceArticle: this.state.invoiceArticle.value,
                    amountInCents: toCents(this.state.amountInCents),
                    currency: { code: this.state.currency },
                    country: this.state.country,
                    city: this.state.city,
                    customInvoiceDescription: this.state.customInvoiceDescription,
                    invoiceRecipient: this.state.invoiceRecipient != null ? this.state.invoiceRecipient.value : null,
                })
                .then(() => {
                    if (this.props.loadAllInvoiceItems != null) {
                        this.onSubmitComplete()
                        this.props.loadAllInvoiceItems()
                    }
                })
                .catch((err) => {
                    this.onSubmitComplete()
                    handleError(err)
                })
        }
    }

    onSubmitComplete = () => {
        this.setState({ isSubmitting: false })
        this.closeModal()
    }

    onChange = memoize((prop, name) => {
        return (e) => {
            this.setState({ [name]: e.target[prop] })
        }
    })

    validate() {
        return (
            this.state.amountInCents > 0 &&
            this.state.copies <= 10 &&
            this.state.customInvoiceDescription.length < 60 &&
            this.state.country.length > 0 &&
            this.state.currency.length > 0 &&
            (this.props.invoiceRecipientOptions == null || this.state.invoiceRecipient != null)
        )
    }

    render() {
        const { isSubmitting } = this.state

        return (
            <Modal show={this.props.show} animation>
                {isSubmitting && <Loader />}

                <div className="panel-heading">
                    <h3 className="panel-title">
                        <Glyphicon glyph="plus" /> Create Invoice items
                    </h3>
                </div>

                <div className="panel-body" style={{ padding: '20px 35px', boxSizing: 'border-box' }}>
                    <Form method="post" horizontal onSubmit={this.onSubmit}>
                        <FormGroup>
                            <ControlLabel htmlFor="date">Relevant date (shown on invoice) </ControlLabel>
                            <DatePicker
                                className="form-control"
                                selected={this.state.date}
                                value={this.state.date.format('YYYY-MM-DD')}
                                dateFormat="YYYY-MM-DD"
                                onChange={(e) => this.setState({ date: moment(e) })}
                                id="date"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="invoiceArticle">Invoice article (shown on invoice)</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={this.props.invoiceArticles}
                                value={this.state.invoiceArticle}
                                onChange={(item) => this.setState({ invoiceArticle: item })}
                                placeholder="Choose invoice type"
                                noResultsText="None"
                                id="invoiceArticle"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="description">Description for internal reference</ControlLabel>
                            <FormControl
                                type="text"
                                required
                                id="description"
                                value={this.state.description}
                                onChange={this.onChange('value', 'description')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="customInvoiceDescription">
                                Description for external reference
                            </ControlLabel>
                            <FormControl
                                type="text"
                                required
                                id="customInvoiceDescription"
                                value={this.state.customInvoiceDescription}
                                onChange={this.onChange('value', 'customInvoiceDescription')}
                            />
                        </FormGroup>
                        {this.props.invoiceRecipientOptions != null ? (
                            <FormGroup>
                                <ControlLabel htmlFor="invoiceRecipient">Invoice recipient</ControlLabel>
                                <Select
                                    autoBlur
                                    allowCreate={false}
                                    backspaceRemoves={false}
                                    clearable={false}
                                    ignoreCase
                                    searchable={false}
                                    isLoading={false}
                                    options={this.props.invoiceRecipientOptions}
                                    value={this.state.invoiceRecipient}
                                    onChange={(invoiceRecipient) => this.setState({ invoiceRecipient })}
                                    noResultsText="None"
                                    id="invoiceRecipient"
                                />
                            </FormGroup>
                        ) : null}
                        <FormGroup>
                            <ControlLabel htmlFor="currency">Currency</ControlLabel>
                            <CurrencySelect
                                value={this.state.currency}
                                onSelect={(currency) => this.setState({ currency: currency ? currency.value : '' })}
                                id="currency"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="country">Country</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={
                                    this.props.regionalSettings.countries
                                        ? this.props.regionalSettings.countries.map(toOption)
                                        : []
                                }
                                value={this.state.country}
                                onChange={(country) => this.setState({ country: country ? country.value : '' })}
                                placeholder="Select country..."
                                noResultsText="None"
                                id="country"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="city">City</ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={
                                    this.props.regionalSettings.cities
                                        ? this.props.regionalSettings.cities
                                              .filter(({ countryCode }) => countryCode === this.state.country)
                                              .map(toOption)
                                        : []
                                }
                                value={this.state.city}
                                onChange={(city) => this.setState({ city: city ? city.value : null })}
                                placeholder="Optionally select city..."
                                noResultsText="None"
                                id="city"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="amount">Amount in {this.state.currency}</ControlLabel>
                            <FormControl
                                type="number"
                                min="0"
                                required
                                id="amount"
                                value={this.state.amountInCents}
                                onChange={this.onChange('value', 'amountInCents')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="type">Type (Positive or negative) </ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={this.props.invoiceTypes}
                                value={this.state.invoiceAccountType}
                                onChange={(item) => this.setState({ invoiceAccountType: item })}
                                placeholder="Choose invoice type"
                                noResultsText="None"
                                id="type"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel htmlFor="copies"># of invoices to create (max 10 at the time)</ControlLabel>
                            <FormControl
                                type="number"
                                min="1"
                                max="10"
                                required
                                id="copies"
                                value={this.state.copies}
                                onChange={this.onChange('value', 'copies')}
                            />
                        </FormGroup>
                        <div>
                            <Button type="button" bsStyle="danger" onClick={this.closeModal}>
                                Close
                                <Glyphicon glyph="remove" />
                            </Button>

                            <Button type="button" bsStyle="default" onClick={this.clear}>
                                Clear
                            </Button>

                            <Button
                                type="submit"
                                bsStyle="primary"
                                className="pull-right"
                                disabled={!this.validate()}
                                onClick={this.onSubmit}
                            >
                                {this.state.isSubmitting ? 'loading...' : 'Save'}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        )
    }
}

CreateModal.propTypes = {
    show: PropTypes.bool,
    invoiceRecipientId: PropTypes.number,
    createInvoiceItem: PropTypes.func.isRequired,
    loadAllInvoiceItems: PropTypes.func,
    close: PropTypes.func.isRequired,
    invoiceArticles: PropTypes.array.isRequired,
    invoiceTypes: PropTypes.array.isRequired,
    regionalSettings: PropTypes.object.isRequired,
    invoiceRecipientOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
        }),
    ),
    prefill: PropTypes.shape({
        date: PropTypes.string,
        city: PropTypes.string,
    }),
    actions: PropTypes.object.isRequired,
}

const mapStateToProps = ({ regionalSettings }) => ({
    regionalSettings,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateModal)
