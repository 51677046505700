import {
    FETCHING_COURIERS,
    COURIERS_LOADED,
    COURIERS_FAILED_TO_LOAD,
    COURIER_FAILED_TO_LOAD,
    COURIER_SELECTED,
    FETCHING_COURIER,
    COURIER_LOADED,
    COURIER_UPDATED,
    UPDATING_COURIER,
    COURIER_FAILED_TO_UPDATE,
    FETCHING_COMPENSATION_TIERS,
    COMPENSATION_TIERS_LOADED,
    COMPENSATION_TIERS_FAILED_TO_LOAD,
    UPDATING_COMPENSATION_TIER,
    COMPENSATION_TIER_UPDATED,
    COMPENSATION_TIER_FAILED_TO_UPDATE,
} from '../constants/actionTypes'
import couriersState from './initialState/couriers-state'

const toOption = ({ id: value, externalName: label }) => ({ value, label })

export default function CouriersReducer(state = couriersState, action) {
    switch (action.type) {
        case FETCHING_COURIERS:
            return {
                ...state,
                loading: true,
                couriers: [],
            }

        case COURIERS_LOADED:
            return {
                ...state,
                loading: false,
                error: null,
                couriers: action.couriers,
                options: action.couriers.map(toOption),
            }

        case COURIERS_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                couriers: [],
            }

        case FETCHING_COURIER:
            return {
                ...state,
                loading: true,
                courier: null,
            }

        case COURIER_FAILED_TO_LOAD:
            return {
                ...state,
                loading: false,
                error: action.error,
                courier: null,
            }

        case COURIER_LOADED:
            return {
                ...state,
                loading: false,
                error: null,
                courier: {
                    name: action.courier.name,
                    countries: action.courier.countries,
                    courierSettings: {
                        billingPeriod: action.courier.courierSettings.billingPeriod,
                        dueDaysInvoice: action.courier.courierSettings.dueDaysInvoice,
                        vatOnInvoice: action.courier.courierSettings.vatOnInvoice,
                        invoiceEmail: action.courier.courierSettings.invoiceEmail,
                        invoiceEmailCopy: action.courier.courierSettings.invoiceEmailCopy,
                        companyRegistrationNumber: action.courier.courierSettings.companyRegistrationNumber,
                        terminalEndStopAlways: action.courier.courierSettings.terminalEndStopAlways,
                        usesOwnCars: action.courier.courierSettings.usesOwnCars,
                        selfInvoiced: action.courier.courierSettings.selfInvoiced,
                        companyAddress: action.courier.courierSettings.companyAddress,
                        companyCity: action.courier.courierSettings.companyCity,
                        companyCountry: action.courier.courierSettings.companyCountry,
                        companyPostalCode: action.courier.courierSettings.companyPostalCode,
                        vatNumber: action.courier.courierSettings.vatNumber,
                        parentCompany: action.courier.courierSettings.parentCompany,
                        combinedInvoicing: action.courier.courierSettings.combinedInvoicing,
                        selfBillRecipientOverride: action.courier.courierSettings.selfBillRecipientOverride,
                        companyLegalName: action.courier.courierSettings.companyLegalName,
                    },
                },
                courierId: action.courier.id,
            }

        case COURIER_SELECTED:
            return {
                ...state,
                selected: state.data.find((b) => b.id === action.id),
            }

        case UPDATING_COURIER:
            return {
                ...state,
                loading: true,
                error: null,
            }

        case COURIER_UPDATED:
            return {
                ...state,
                loading: false,
                error: null,
                courier: {
                    name: action.courier.name,
                    courierSettings: {
                        billingPeriod: action.courier.courierSettings.billingPeriod,
                        dueDaysInvoice: action.courier.courierSettings.dueDaysInvoice,
                        vatOnInvoice: action.courier.courierSettings.vatOnInvoice,
                        invoiceEmail: action.courier.courierSettings.invoiceEmail,
                        companyRegistrationNumber: action.courier.courierSettings.companyRegistrationNumber,
                        terminalEndStopAlways: action.courier.courierSettings.terminalEndStopAlways,
                        usesOwnCars: action.courier.courierSettings.usesOwnCars,
                        selfInvoiced: action.courier.courierSettings.selfInvoiced,
                        selfBillRecipientOverride: action.courier.courierSettings.selfBillRecipientOverride,
                        companyLegalName: action.courier.courierSettings.companyLegalName,
                    },
                },
                courierId: action.courier.id,
            }

        case COURIER_FAILED_TO_UPDATE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case FETCHING_COMPENSATION_TIERS:
            return {
                ...state,
                loadingCompensationTiers: true,
            }

        case COMPENSATION_TIERS_LOADED:
            return {
                ...state,
                loadingCompensationTiers: false,
                compensationTiers: action.compensationTiers,
            }

        case COMPENSATION_TIERS_FAILED_TO_LOAD:
            return {
                ...state,
                loadingCompensationTiers: false,
                error: action.error,
            }

        case UPDATING_COMPENSATION_TIER:
            return {
                ...state,
                updatingCompensationTier: true,
            }

        case COMPENSATION_TIER_UPDATED:
            return {
                ...state,
                updatingCompensationTier: false,
            }

        case COMPENSATION_TIER_FAILED_TO_UPDATE:
            return {
                ...state,
                updatingCompensationTier: false,
                error: action.error,
            }

        default:
            return state
    }
}
