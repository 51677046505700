import React, { Component } from 'react'
import AsyncSelect from 'react-select/lib/Async'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { findMerchants } from '../../../../../utils/merchant-webapi'
import { handleError } from '../../../../../utils/handle-error'

class MerchantSearchSelect extends Component {
    loadMerchantOptions = (excludeMerchantsInGroups) =>
        debounce((searchTerm, callback) => {
            const convertResponseToSelectOptions = (merchants) => ({
                options: merchants.map(({ id: value, name, externalName }) => ({
                    value,
                    label: `${name} (${externalName})`,
                })),
            })

            findMerchants(searchTerm, excludeMerchantsInGroups)
                .then((response) => callback(null, convertResponseToSelectOptions(response)))
                .catch((error) => {
                    handleError(error)
                    callback(null, convertResponseToSelectOptions([]))
                })
        }, 250)

    render() {
        const { selectedMerchantId, onChange, onInputChange, excludeMerchantsInGroups } = this.props
        return (
            <div>
                <AsyncSelect
                    autoload={false}
                    autosize={false}
                    loadOptions={this.loadMerchantOptions(excludeMerchantsInGroups)}
                    placeholder="Merchant name"
                    noResultsText="Merchant not found..."
                    value={selectedMerchantId}
                    onSelectResetsInput={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onChange={onChange}
                    onInputChange={onInputChange}
                />
            </div>
        )
    }
}

MerchantSearchSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    selectedMerchantId: PropTypes.number,
    onInputChange: PropTypes.func,
    excludeMerchantsInGroups: PropTypes.bool,
}

export default MerchantSearchSelect
