import { Component } from 'react'
import { auth } from '../../utils/auth-webapi'

const logo = 'https://carrier-images.s3.amazonaws.com/buyers/logo/3ca5ddff-a3a3-46f1-a78b-7a11418b4755.png'

const login = ([Auth0Lock, config]) => {
    const options = {
        closable: false,
        auth: {
            audience: config['auht0-login-audience'],
            redirectUrl: `${window.location.origin}/login/session`,
            responseType: 'token id_token',
            scope: 'openid',
        },
        theme: {
            logo,
            primaryColor: '#39c69b',
        },
        languageDictionary: {
            title: '',
        },
    }
    new Auth0Lock(config['auth0-client-id'], config['auth0-web-login-domain'], options).show()
}

export default class Login extends Component {
    componentDidMount() {
        Promise.all([import('auth0-lock').then(({ default: Auth0Lock }) => Auth0Lock), auth()]).then(login)
    }

    render() {
        return null
    }
}
