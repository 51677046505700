import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('zone-management/graphql')

class ZoneManagementClient {
    send = (query, variables) => {
        const graphql = {
            query,
            variables,
        }
        return webapi().post(graphql)
    }
}

export default new ZoneManagementClient()
