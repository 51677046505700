import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap/lib'
import AddDiscount from './components/add'
import Discounts from './components/discounts'
import auth from '../../../auth'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER } from '../../../utils/role'

const DiscountModal = (props) => {
    const canModify = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER)
    return (
        <div className="static-modal">
            <Modal show={props.show} container={this}>
                <Modal.Header>
                    <Modal.Title>Manage Parcel Discounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ padding: 10 }}>
                        <ul>
                            <li>Here you can manage Parcel Discounts for this Buyer.</li>
                            <li>Prices apply up to parcel Quantity and above</li>
                            <li>
                                Example: Quantity 2, Discount 10%. This means there is a 10% discount on 2 or more
                                parcels
                            </li>
                        </ul>
                    </div>
                    {canModify ? <AddDiscount addDiscount={props.addDiscount} /> : null}
                    <Discounts parcelDiscounts={props.parcelDiscounts} deleteDiscount={props.deleteDiscount} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

DiscountModal.propTypes = {
    show: PropTypes.bool,
    addDiscount: PropTypes.func,
    parcelDiscounts: PropTypes.array,
    deleteDiscount: PropTypes.func,
    closeModal: PropTypes.func,
}

export default DiscountModal
