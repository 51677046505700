import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import { Row, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import { handleError } from '../../utils/handle-error'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'
import auth from '../../auth'

class RejectedInvoiceList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invoices: [],
            fetchingInvoices: true,
            courierId: this.props.courierId,
            merchantId: this.props.merchantId,
        }
    }

    transitionToCourierPage = () => {
        this.props.router.push('/admin/couriers')
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
        if (!hasAuth) {
            this.transitionToCourierPage()
            return
        }
        this.loadAllInvoices()
    }

    transitionToManageRejectedInvoiceItems = (rejectedInvoiceId) => () => {
        if (this.props.courierId !== null) {
            this.props.router.push(`/admin/couriers/${this.state.courierId}/rejected-invoices/${rejectedInvoiceId}`)
        } else {
            this.props.router.push(`/admin/merchants/${this.state.merchantId}/rejected-invoices/${rejectedInvoiceId}`)
        }
    }

    loadAllInvoices = () => {
        this.setState({ fetchingInvoices: true })
        this.props
            .loadRejectedInvoices(this.state.courierId)
            .then((resp) => {
                this.setState({
                    invoices: resp,
                    fetchingInvoices: false,
                })
            })
            .catch((err) => {
                this.setState({ fetchingInvoices: false })
                handleError(err)
            })
    }

    render() {
        return (
            <Row>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Reference No</th>
                            <th>Currency</th>
                            <th>Rejected At</th>
                            <th>Manage Invoice</th>
                        </tr>
                    </thead>
                    {this.state.fetchingInvoices ? (
                        <Loader />
                    ) : (
                        <tbody>
                            {this.state.invoices.map((ri) => (
                                <tr key={ri.id}>
                                    <td>{ri.invoiceId}</td>
                                    <td>{ri.invoiceNo}</td>
                                    <td>{ri.currency.code}</td>
                                    <td>{moment(ri.rejectedAt).format('YYYY-MM-DD HH:mm')}</td>
                                    <td>
                                        <Button
                                            variant="link"
                                            onClick={this.transitionToManageRejectedInvoiceItems(ri.id)}
                                        >
                                            Manage
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
            </Row>
        )
    }
}

RejectedInvoiceList.propTypes = {
    courierId: PropTypes.number,
    merchantId: PropTypes.number,
    loadRejectedInvoices: PropTypes.func,
    router: PropTypes.object,
}

const mapStateToProps = ({
    buyers: { loading, error, buyer },
    invoiceSettings: {
        articles: { merchant: invoiceArticles },
    },
}) => ({
    loading,
    error,
    buyer,
    invoiceArticles,
})

export default connect(mapStateToProps)(RejectedInvoiceList)
