export default {
    loading: false,
    error: null,
    couriers: [],
    courier: {
        name: '',
        courierSettings: {
            billingPeriod: 'MONTHLY',
            dueDaysInvoice: 30,
            vatOnInvoice: true,
            invoiceEmail: '',
            companyRegistrationNumber: '',
            terminalEndStopAlways: false,
        },
    },
    compensationTiers: [],
    loadingCompensationTiers: false,
    updatingCompensationTier: false,
}
