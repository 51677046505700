import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'

const options = (zones) => {
    if (zones === null || typeof zones === 'undefined') {
        return []
    }
    return zones.map((z) => ({ value: z.id, label: z.title, countryCode: z.countryCode, city: z.city }))
}

export default class PostalCodeZones extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.defaultValue ? options(props.defaultValue) : [],
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(options(prevProps.newZones), options(this.props.newZones))) {
            const valueZones = [...this.state.value]
            const removeDublicatedValueZones = uniqBy(valueZones.concat(options(this.props.newZones)), 'value')

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                {
                    value: removeDublicatedValueZones,
                },
                () => {
                    this.props.onChange(this.state.value)
                },
            )
        }
    }

    onChange = (value) => {
        this.setState({ value }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.value)
            }
        })
    }

    render() {
        const { multi } = this.props

        return (
            <Select
                disabled={this.props.disabled}
                value={this.state.value}
                options={options(this.props.zones)}
                multi={multi}
                allowCreate={false}
                ignoreCase
                searchable
                onChange={this.onChange}
            />
        )
    }
}

PostalCodeZones.propTypes = {
    disabled: PropTypes.bool,
    zones: PropTypes.array.isRequired,
    defaultValue: PropTypes.array,
    newZones: PropTypes.array,
    onChange: PropTypes.func,
    multi: PropTypes.bool,
}

PostalCodeZones.defaultProps = {
    multi: true,
}
