import React from 'react'
import { Col } from 'react-bootstrap'
import InvoicingSettingsForm from './components/invoicing-settings-form'

const Invoicing = () => (
    <Col md={10} mdOffset={1} lg={7}>
        <h2 className="mb-md">Invoicing</h2>
        <InvoicingSettingsForm />
    </Col>
)

export default Invoicing
