import React, { Component } from 'react'
import { Alert, Col, Grid, Tabs, Tab } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import auth from '../../../auth'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TERMINAL_ADMIN, TERMINAL_WORKER } from '../../../utils/role'
import TerminalSelect from '../../common/terminal-select'
import { dataReset, fetchData, getBookDeliveryValues } from '../../../actions/terminal-rules'
import HtmlTitle from '../../html-title'
import { SettingsTab, ProfilesTab } from './tabs'

const LONGPOLL_REFRESH_RATE_MS = 15000
const IDLE_TIMER_MS = 30000

const TabId = {
    SETTINGS: 1,
    PROFILES: 2,
}

class TerminalSortingView extends Component {
    canEditRules = auth.hasAnyRole(TERMINAL_ADMIN, TERMINAL_WORKER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    constructor(props) {
        super(props)
        this.state = {
            selectedTerminal: null,
            tabId: TabId.SETTINGS,
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer)
        clearTimeout(this.idleTimer)
        document.body.removeEventListener('click', this.active)
        document.body.removeEventListener('touchstart', this.active)
    }

    componentDidMount = () => {
        document.body.addEventListener('click', this.active)
        document.body.addEventListener('touchstart', this.active)
    }

    active = () => {
        clearTimeout(this.idleTimer)
        this.idleTimer = setTimeout(this.idle, IDLE_TIMER_MS)
    }

    idle = () => {
        clearInterval(this.timer)
        this.poll(() => new Promise(this.fetchData), LONGPOLL_REFRESH_RATE_MS)
    }

    sleep = (time) =>
        new Promise((resolve) => {
            this.timer = setTimeout(resolve, time)
        })

    poll = (promiseFn, time) => {
        promiseFn().then(
            this.sleep(time).then(() => {
                this.poll(promiseFn, time)
            }),
        )
    }

    onSelectTerminal = (selectedTerminal) => {
        if (selectedTerminal) {
            const { dispatch } = this.props
            dispatch(dataReset())
            this.setState({ selectedTerminal }, this.fetchData)
        }
    }

    fetchData = () => {
        const { dispatch } = this.props
        const { selectedTerminal } = this.state
        if (selectedTerminal) {
            return Promise.all([
                dispatch(getBookDeliveryValues(selectedTerminal.id)),
                dispatch(fetchData(selectedTerminal.id)),
            ])
        }
        return Promise.resolve()
    }

    onSelectTab = (tabId) => {
        this.setState({ tabId })
    }

    render = () => {
        const { bookDelivery } = this.props
        const { selectedTerminal, tabId } = this.state
        const header = (selectedTerminal && selectedTerminal.city) || 'Select Terminal'
        const hasLoadedSuccessfully = Boolean(bookDelivery)
        const hasLoadedUnsuccessfully = bookDelivery === null
        return (
            <div className="sorting">
                <HtmlTitle title="Terminal Sorting" />
                <Grid>
                    <Col md={10} mdOffset={1}>
                        <h1>{header}</h1>
                        <TerminalSelect onSelect={this.onSelectTerminal} />
                        {selectedTerminal && (
                            <div className="terminal-content">
                                {hasLoadedSuccessfully && (
                                    <Tabs
                                        id="sorting-view-tabs"
                                        activeKey={tabId}
                                        onSelect={this.onSelectTab}
                                        className="tabs"
                                    >
                                        <Tab eventKey={TabId.SETTINGS} title="Settings">
                                            <SettingsTab
                                                selectedTerminalId={selectedTerminal.id}
                                                canEditRules={this.canEditRules}
                                                fetchData={this.fetchData}
                                            />
                                        </Tab>
                                        <Tab eventKey={TabId.PROFILES} title="Profiles">
                                            <ProfilesTab
                                                selectedTerminalId={selectedTerminal.id}
                                                canEditRules={this.canEditRules}
                                            />
                                        </Tab>
                                    </Tabs>
                                )}
                                {hasLoadedUnsuccessfully && (
                                    <Alert bsStyle="danger">No Sorting machine in {selectedTerminal.name}</Alert>
                                )}
                            </div>
                        )}
                    </Col>
                </Grid>
            </div>
        )
    }
}

TerminalSortingView.propTypes = {
    dispatch: PropTypes.func.isRequired,
    bookDelivery: PropTypes.object,
}

const mapStateToProps = ({ terminalRules: { bookDelivery } }) => ({
    bookDelivery,
})

export default connect(mapStateToProps)(TerminalSortingView)
