import React, { Component } from 'react'
import { Form, FormGroup, FormControl, Button, Col, ControlLabel, InputGroup } from 'react-bootstrap/lib'

export default class AddDiscount extends Component {
    constructor(props) {
        super(props)
        this.state = this.getInitState()
    }

    getInitState() {
        return {
            fromQuantity: false,
            discountPercentage: false,
            valid: true,
        }
    }

    resetState() {
        this.setState({
            fromQuantity: false,
            discountPercentage: false,
            valid: true,
        })
    }

    changeThreshold(event) {
        this.setState({ fromQuantity: event.target.value })
    }

    changePercentage(event) {
        this.setState({ discountPercentage: event.target.value })
    }

    validate() {
        if (this.state.fromQuantity && this.state.discountPercentage) {
            return true
        }
        this.setState({ valid: false })
        return false
    }

    addDiscount() {
        if (this.validate()) {
            const send = {
                fromQuantity: this.state.fromQuantity,
                discountPercentage: this.state.discountPercentage,
            }
            this.props.addDiscount(send)
            this.resetState()
        }
    }

    render() {
        return (
            <Form horizontal>
                <FormGroup validationState={!this.state.valid && !this.state.fromQuantity ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        Quantity{' '}
                    </Col>
                    <Col sm={10}>
                        <InputGroup>
                            <InputGroup.Addon>#</InputGroup.Addon>
                            <FormControl
                                type="number"
                                placeholder="Quantity"
                                value={this.state.fromQuantity}
                                onChange={this.changeThreshold.bind(this)}
                            />
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup validationState={!this.state.valid && !this.state.discountPercentage ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        Discount{' '}
                    </Col>
                    <Col sm={10}>
                        <InputGroup>
                            <FormControl
                                type="number"
                                placeholder="Discount"
                                value={this.state.discountPercentage}
                                onChange={this.changePercentage.bind(this)}
                            />
                            <InputGroup.Addon>%</InputGroup.Addon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col smOffset={2} sm={10}>
                        <Button className="btn btn-primary right" onClick={this.addDiscount.bind(this)}>
                            Add
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}
