import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.terminals-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('terminals')

export const getTerminalInformation = (terminalId) =>
    webapi()
        .path(terminalId)
        .path('info')
        .get()

export const getTerminals = () => webapi().get()
