import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import { Creatable } from 'react-select'
import editMerchant from './edit-merchant-hoc'
import ShippingCost from '../shipping-cost'
import MerchantInput from './merchant-input'

class ProductSettingsForm extends Component {
    onShippingCostsChange = (rawShippingCosts) => {
        const shippingCosts = rawShippingCosts.map(
            ({ country, currency, freeThresholdInCents, shippingPriceInCents }) => ({
                country,
                currency,
                freeThresholdInCents,
                shippingPriceInCents,
            }),
        )
        const { onChange } = this.props
        onChange('value', 'shipping')({ target: { value: shippingCosts } })
    }

    onWhitelistedDomainsChange = (rawWhitelistedDomains) => {
        const whitelistedDomains = rawWhitelistedDomains.map((x) => x.value)
        const { onChange } = this.props
        onChange('value', 'whitelistedDomains')({ target: { value: whitelistedDomains } })
    }

    render() {
        const { merchant, merchantSettings, onSettingsChange } = this.props
        return (
            <div>
                <FormGroup controlId="shipping">
                    <Col componentClass={ControlLabel} sm={3}>
                        Shipping costs for Product Search
                    </Col>
                    <Col sm={9}>
                        <ShippingCost value={merchant.shipping} onChange={this.onShippingCostsChange} />
                    </Col>
                </FormGroup>
                <MerchantInput
                    controlId="priceRunnerId"
                    type="number"
                    label="Price runner mapping identifier"
                    value={merchantSettings.priceRunnerId}
                    onChange={onSettingsChange('value', 'priceRunnerId')}
                />
                <FormGroup controlId="whitelistedDomains">
                    <Col componentClass={ControlLabel} sm={3}>
                        Whitelisted domains
                    </Col>
                    <Col sm={9}>
                        <Creatable
                            multi
                            clearable
                            value={merchant.whitelistedDomains.map((domain) => ({ value: domain, label: domain }))}
                            onChange={this.onWhitelistedDomainsChange}
                            backspaceRemoves={false}
                            deleteRemoves={false}
                            noResultsText="Type a domain to add it..."
                            placeholder="Add domains..."
                            promptTextCreator={(label) => `Press enter to add domain "${label}"`}
                            onInputChange={(input) => (input ? input.toLowerCase() : input)}
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }
}

export default editMerchant(ProductSettingsForm)

ProductSettingsForm.propTypes = {
    merchant: PropTypes.object.isRequired,
    merchantSettings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
}
