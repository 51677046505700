import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router'
import { Grid, Row, Col, Table, DropdownButton, MenuItem } from 'react-bootstrap'
import Loader from 'react-loader'
import { getMinimalMerchants, deleteMerchant } from '../../utils/merchant-webapi'
import { PriceTierType } from '../../utils/types'
import auth from '../../auth'
import PriceTierModal from './price-tiers'
import DiscountModal from './parcel-discounts'
import HtmlTitle from '../html-title'
import * as discountActions from '../../actions/discounts'
import * as merchantActions from '../../actions/merchants'
import { mapSort, sortStrings } from '../../utils/sorting'
import { handleError } from '../../utils/handle-error'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

class List extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            minimalMerchants: [],
            loaded: true,
            merchantId: null,
            priceTierModal: false,
            discountModal: false,
        }
    }

    componentDidMount() {
        if (!auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)) {
            this.props.router.push('/admin/users')
            return
        }

        this.setState({ loaded: false })
        getMinimalMerchants()
            .then((minimalMerchants) => this.setState({ minimalMerchants, loaded: true }))
            .catch((e) => {
                handleError(e)
                this.setState({ loaded: true })
            })
    }

    onDelete = (id) => () => {
        // eslint-disable-next-line no-alert
        if (!window.confirm('Are you sure you want to delete this merchant?')) {
            return
        }

        deleteMerchant(id)
            .then(() =>
                this.setState((state) => {
                    state.minimalMerchants.filter((b) => b.id !== id)
                }),
            )
            .catch(handleError)
    }

    hasActions = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    hasPriceActions = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER)

    fetchPriceTiers = ({ id }) => () => {
        this.setState({ priceTierModal: true, merchantId: id })
        this.props.merchantActions.fetchPriceTiersWebapi(PriceTierType.MerchantPallets, id)
        this.props.merchantActions.fetchPriceTiersWebapi(PriceTierType.DistributionVolume, id)
        this.props.merchantActions.fetchPriceTiersWebapi(PriceTierType.MerchantParcels, id)
        this.props.merchantActions.fetchPriceTiersWebapi(PriceTierType.BoxDistribution, id)
        this.props.merchantActions.fetchTierMappings()
        if (this.hasPriceActions) {
            this.props.merchantActions.fetchZonesWebapi(id)
        }
    }

    fetchParcelDiscounts = ({ id }) => () => {
        this.setState({ discountModal: true, merchantId: id })
        this.props.discountActions.fetchParcelDiscountsWebapi(id)
        if (this.hasPriceActions) {
            this.props.merchantActions.fetchZonesWebapi(id)
        }
    }

    addPriceTier = (type, data) => {
        this.props.merchantActions.savePriceTier(type, this.state.merchantId, data)
    }

    modifyPriceTiers = (type, modifications) =>
        this.props.merchantActions.modifyPriceTiers(this.state.merchantId, type, modifications)

    deletePriceTier = (type, tierId) => {
        this.props.merchantActions.deletePriceTier(type, this.state.merchantId, tierId)
    }

    addDiscount = (data) => {
        this.props.discountActions.addDiscount(this.state.merchantId, data)
    }

    deleteDiscount = (dId) => {
        this.props.discountActions.deleteDiscount(this.state.merchantId, dId)
    }

    closeModal = () => {
        this.setState({ priceTierModal: false, discountModal: false })
    }

    transitionToEdit = ({ id }) => () => {
        this.props.router.push(`/admin/merchants/${id}/edit`)
    }

    transitionToEditFixedCosts = ({ id }) => () => {
        this.props.router.push(`/admin/merchants/${id}/manage-invoice-items`)
    }

    transitionToManageCollectionPoints = ({ id }) => () => {
        this.props.router.push(`/admin/merchants/${id}`)
    }

    renderEditLink(merchant) {
        if (!this.hasActions) {
            return null
        }

        return (
            <Link
                name="carriers-edit"
                className="btn btn-default"
                to={`/admin/merchants/${merchant.id}`}
                style={{ marginRight: '5px' }}
            >
                Edit
            </Link>
        )
    }

    renderDeleteButton({ id }) {
        if (!this.hasPriceActions) {
            return null
        }

        return <MenuItem onClick={this.onDelete(id)}>Delete</MenuItem>
    }

    renderPriceTierButton(merchant) {
        if (!this.hasPriceActions) {
            return null
        }

        return (
            <MenuItem eventKey="4" onClick={this.fetchPriceTiers(merchant)}>
                Price Tiers
            </MenuItem>
        )
    }

    renderParcelDiscountButton(merchant) {
        if (!this.hasPriceActions) {
            return null
        }

        return (
            <MenuItem eventKey="5" onClick={this.fetchParcelDiscounts(merchant)}>
                Parcel Discounts
            </MenuItem>
        )
    }

    renderFixedCosts(merchant) {
        if (!this.hasActions) {
            return null
        }

        return (
            <MenuItem eventKey="6" onClick={this.transitionToEditFixedCosts(merchant)}>
                Manage invoice items
            </MenuItem>
        )
    }

    renderTableRow = (merchant) => (
        <tr key={merchant.id}>
            <td>{merchant.id}</td>
            <td>{merchant.name}</td>
            <td>{merchant.externalName}</td>
            {this.hasActions ? (
                <td style={{ whiteSpace: 'nowrap' }}>
                    {this.renderEditLink(merchant)}
                    <DropdownButton pullRight title="Actions" id="buyer-actions-dropdown">
                        {this.renderPriceTierButton(merchant)}
                        {this.renderParcelDiscountButton(merchant)}
                        {this.renderFixedCosts(merchant)}
                        {this.renderDeleteButton(merchant)}
                    </DropdownButton>
                </td>
            ) : null}
        </tr>
    )

    render() {
        return (
            <div>
                <HtmlTitle title="Merchants" />
                <Loader color="#BFBFBF" loaded={this.state.loaded}>
                    {this.hasPriceActions ? (
                        <PriceTierModal
                            show={this.state.priceTierModal}
                            closeModal={this.closeModal}
                            priceTiers={this.props.priceTiers}
                            addPriceTier={this.addPriceTier}
                            modifyPriceTiers={this.modifyPriceTiers}
                            deletePriceTier={this.deletePriceTier}
                            postalcodeZones={this.props.postalcodeZones}
                            merchantId={this.state.merchantId}
                            tierEnums={this.props.tierEnums}
                        />
                    ) : null}
                    {this.hasPriceActions ? (
                        <DiscountModal
                            show={this.state.discountModal}
                            closeModal={this.closeModal}
                            parcelDiscounts={this.props.parcelDiscounts}
                            addDiscount={this.addDiscount}
                            deleteDiscount={this.deleteDiscount}
                        />
                    ) : null}
                    <Grid fluid>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                {this.hasPriceActions ? (
                                    <Link to="/admin/merchants/create" className="btn btn-primary pull-right">
                                        Create
                                    </Link>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Display name</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.minimalMerchants
                                            .sort(mapSort(sortStrings, ({ name }) => name))
                                            .map(this.renderTableRow)}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Grid>
                </Loader>
            </div>
        )
    }
}

List.propTypes = {
    router: PropTypes.object,
    merchantActions: PropTypes.object,
    discountActions: PropTypes.object,
    priceTiers: PropTypes.object,
    postalcodeZones: PropTypes.array,
    parcelDiscounts: PropTypes.array,
    tierEnums: PropTypes.array,
}

const mapStateToProps = (state) => ({
    postalcodeZones: state.buyers.postcodes,
    priceTiers: state.buyers.priceTiers,
    tierEnums: state.buyers.tierEnums,
    parcelDiscounts: state.discounts.parcelDiscounts,
})

const mapDispatchToProps = (dispatch) => ({
    merchantActions: bindActionCreators(merchantActions, dispatch),
    discountActions: bindActionCreators(discountActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(List)
