import Api from './api'
import Webapi from './webapi'
import dev from './dev'
import auth from '../auth'

const baseUrl = '/api/geocoding'
const contentType = dev('application/json', 'application/vnd.budbee.geocoding-v1+json')

export default {
    saveGeocode(newGeocodes) {
        return new Api(baseUrl)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(newGeocodes)
    },

    // deprecate or remove this
    persistGeocode(geocodeResult, id) {
        return new Api(baseUrl)
            .path(`?addressId=${id}`)
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(geocodeResult)
    },

    search(query) {
        return new Api(baseUrl)
            .path('search')
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post(query)
    },

    overwrite(items) {
        return new Api(baseUrl)
            .path('overwrite')
            .header('Accept', contentType)
            .header('Content-Type', contentType)
            .header('Authorization', auth.get())
            .post({ overwrite: items })
    },

    geocode(street, postalCode, city, countryCode) {
        return new Webapi()
            .path('/geocode')
            .authorization(auth.get())
            .header('Content-Type', 'application/json')
            .post({
                street,
                postalCode,
                locality: city,
                country: countryCode,
            })
    },
}
