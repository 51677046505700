import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/regional-settings'

const toOption = ({ id: value, label }) => ({ value, label })

class CurrencySelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
        }
    }

    getCurrency = (value) => this.props.currencies.find((currency) => currency.id === value)

    componentDidMount() {
        this.props.actions.fetchRegionalSettings().then(() => {
            const { value } = this.props
            this.setState({
                selected: this.getCurrency(value),
            })
        })
    }

    componentWillReceiveProps({ value }) {
        if (value != null) {
            this.setState({
                selected: this.getCurrency(value),
            })
        }
    }

    onChange = (selected) => {
        this.setState({ selected }, () => {
            this.props.onSelect(this.state.selected)
        })
    }

    render() {
        const { multi, currencies } = this.props
        const { selected } = this.state

        const placeholder = multi ? 'Select currencies...' : 'Select currency...'

        return (
            <Select
                placeholder={placeholder}
                value={selected}
                options={currencies.map(toOption)}
                onChange={this.onChange}
                allowCreate={false}
                ignoreCase
                searchable
                clearable
                multi={multi}
            />
        )
    }
}

CurrencySelect.propTypes = {
    multi: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string,
    actions: PropTypes.object,
    currencies: PropTypes.array.isRequired,
}

CurrencySelect.defaultProps = {
    multi: false,
}

const mapStateToProps = (state) => ({
    currencies: state.regionalSettings.currencies,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelect)
