import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Alert, FormGroup, ControlLabel, HelpBlock, Panel } from 'react-bootstrap'
import Loader from 'react-loader'
import Select from 'react-select'

import SortingMachineLaneConfigModal from '../sorting-machine/sorting-machine-lane-modal'
import { SORTING_PROFILES } from '../constants'
import SortingMachine from '../sorting-machine/sorting-machine'
import {
    createProfileDateRule,
    createProfileLockerRule,
    createProfileTemporalRule,
    getAllProfileRules,
    getAllLockerStops,
    createProfileLockerLinehaulRule,
    createProfileLockerStopRule,
    createProfileMerchantPriorityRule,
    createProfileRules,
    createProfileTerminalRule,
    createProfileReturnLinehaulRule,
    createProfileReadFailRule,
    createProfileReturnToMerchantRule,
    createProfileLinehaulHubOrRegionRule,
    createProfileOversizeLockerParcelRule,
    deleteProfileRule,
    deleteProfileRules,
} from '../../../../../actions/terminal-rules'
import { getProfileKeyByName } from '../get-profile-key-by-name'
import { createProfileSelectOptions } from './create-profile-select-options'
import ClearRulesButton from '../clear-rules-button'

class ProfilesTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editingLane: null,
            selectedSortingProfile: getProfileKeyByName(SORTING_PROFILES.MORNING),
        }
    }

    componentDidMount = () => {
        const { dispatch, selectedTerminalId } = this.props
        const { selectedSortingProfile } = this.state
        dispatch(getAllProfileRules(selectedTerminalId, selectedSortingProfile))
    }

    componentWillReceiveProps = (nextProps) => {
        const { dispatch, selectedTerminalId } = this.props
        const { selectedTerminalId: nextSelectedTerminalId } = nextProps
        if (selectedTerminalId !== nextSelectedTerminalId) {
            const selectedSortingProfile = getProfileKeyByName(SORTING_PROFILES.MORNING)
            this.setState({ selectedSortingProfile })
            dispatch(getAllProfileRules(nextSelectedTerminalId, selectedSortingProfile))
        }
    }

    onProfileSelect = ({ value: selectedSortingProfile }) => {
        const { dispatch, selectedTerminalId } = this.props
        this.setState({ selectedSortingProfile })
        dispatch(getAllProfileRules(selectedTerminalId, selectedSortingProfile))
    }

    onHideMachineLaneModal = () => {
        this.setState({ editingLane: null })
    }

    onLaneClick = (editingLane) => {
        this.setState({ editingLane })
    }

    onSaveDateRule = (date, recurring, lesser, greater) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileDateRule(
                selectedTerminalId,
                selectedSortingProfile,
                editingLane.id,
                date,
                recurring,
                lesser,
                greater,
            ),
        )
    }

    onSaveTemporalRule = (temporal) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileTemporalRule(selectedTerminalId, selectedSortingProfile, editingLane.id, temporal))
    }

    onSaveLockerRule = (lockerId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileLockerRule(selectedTerminalId, selectedSortingProfile, editingLane.id, lockerId))
    }

    getAllLockerStops = (routeId) => {
        const { dispatch } = this.props
        dispatch(getAllLockerStops(routeId))
    }

    onSaveLockerLinehaulRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileLockerLinehaulRule(selectedTerminalId, selectedSortingProfile, editingLane.id, terminalId),
        )
    }

    onSaveLockerStopRule = (lockerStopId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileLockerStopRule(selectedTerminalId, selectedSortingProfile, editingLane.id, lockerStopId))
    }

    onSaveMerchantPriorityRule = (merchantId, merchantGroupId, exclusivePriority) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileMerchantPriorityRule(
                selectedTerminalId,
                selectedSortingProfile,
                editingLane.id,
                merchantId,
                merchantGroupId,
                exclusivePriority,
            ),
        )
    }

    onSaveMultipleLockerStopRules = (lockerStopIdCollection) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileRules(
                selectedTerminalId,
                selectedSortingProfile,
                editingLane.id,
                lockerStopIdCollection.map((lockerStopId) => ({
                    type: 'LOCKER_STOP',
                    attributes: { lockerStopId },
                })),
            ),
        )
    }

    onSaveTerminalRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileTerminalRule(selectedTerminalId, selectedSortingProfile, editingLane.id, terminalId))
    }

    onSaveReturnLinehaulRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileReturnLinehaulRule(selectedTerminalId, selectedSortingProfile, editingLane.id, terminalId),
        )
    }

    onSaveReadFailRule = () => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileReadFailRule(selectedTerminalId, selectedSortingProfile, editingLane.id))
    }

    onSaveReturnToMerchantRule = (merchantId, merchantGroupId) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileReturnToMerchantRule(
                selectedTerminalId,
                selectedSortingProfile,
                editingLane.id,
                merchantId,
                merchantGroupId,
            ),
        )
    }

    onSaveLinehaulHubOrRegionRule = (groupWarehouseId, groupWarehouseType) => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(
            createProfileLinehaulHubOrRegionRule(
                selectedTerminalId,
                selectedSortingProfile,
                editingLane.id,
                groupWarehouseId,
                groupWarehouseType,
            ),
        )
    }

    onSaveOversizeLockerParcelRule = () => {
        const { dispatch, selectedTerminalId } = this.props
        const { editingLane, selectedSortingProfile } = this.state
        dispatch(createProfileOversizeLockerParcelRule(selectedTerminalId, selectedSortingProfile, editingLane.id))
    }

    onDeleteRule = (type, rule) => {
        const { dispatch, selectedTerminalId } = this.props
        const { selectedSortingProfile } = this.state
        dispatch(deleteProfileRule(selectedTerminalId, selectedSortingProfile, rule.id))
    }

    renderSortingMachine = () => {
        const {
            terminalLanes,
            terminal,
            routeRules,
            routeSortingStatuses,
            terminalProfileRules,
            canEditRules,
        } = this.props
        const { selectedSortingProfile } = this.state
        const isLoadingSortingMachine = terminalProfileRules.isLoading || !terminalLanes
        return isLoadingSortingMachine ? (
            <Loader color="#003085" />
        ) : (
            <div>
                {canEditRules && (
                    <Alert bsStyle="info" className="sorting-alert">
                        Select a lane below to start configuring profile{' '}
                        <b>{SORTING_PROFILES[selectedSortingProfile]}</b>
                    </Alert>
                )}
                <SortingMachineLaneConfigModal
                    onSaveTerminalRule={this.onSaveTerminalRule}
                    onSaveReturnLinehaulRule={this.onSaveReturnLinehaulRule}
                    onSaveDateRule={this.onSaveDateRule}
                    onSaveTemporalRule={this.onSaveTemporalRule}
                    onSaveOversizeLockerParcelRule={this.onSaveOversizeLockerParcelRule}
                    onSaveLockerRule={this.onSaveLockerRule}
                    onSaveLockerLinehaulRule={this.onSaveLockerLinehaulRule}
                    onSaveLockerStopRule={this.onSaveLockerStopRule}
                    onSaveMerchantPriorityRule={this.onSaveMerchantPriorityRule}
                    onSaveMultipleLockerStopRules={this.onSaveMultipleLockerStopRules}
                    onSaveReadFailRule={this.onSaveReadFailRule}
                    onSaveReturnToMerchantRule={this.onSaveReturnToMerchantRule}
                    onSaveLinehaulHubOrRegionRule={this.onSaveLinehaulHubOrRegionRule}
                    onDeleteRule={this.onDeleteRule}
                    onHide={this.onHideMachineLaneModal}
                    getAllLockerStops={this.getAllLockerStops}
                    show={this.state.editingLane != null}
                    routes={this.props.routes}
                    onDemandRoutes={[]}
                    routeRules={this.props.routeRules}
                    onDemandRouteRules={[]}
                    lockers={this.props.lockers}
                    lockerRules={this.props.lockerRules}
                    lockerStops={this.props.lockerStops}
                    lockerRoutes={this.props.lockerRoutes}
                    rules={terminalProfileRules.rules}
                    hubPallets={this.props.hubPallets}
                    terminalId={this.props.selectedTerminalId}
                    editingLane={this.state.editingLane}
                    routesloaded={this.props.routesloaded}
                />
                <SortingMachine
                    terminalLanes={terminalLanes}
                    terminal={terminal}
                    rules={terminalProfileRules.rules}
                    routeRules={routeRules}
                    routeSortingStatuses={routeSortingStatuses}
                    onClick={canEditRules ? this.onLaneClick : undefined}
                />
            </div>
        )
    }

    render = () => {
        const {
            dispatch,
            sortingProfiles,
            terminal,
            canEditRules,
            deletingAllRulesFromProfile,
            deletingAllRulesFromProfileError,
        } = this.props
        const { selectedSortingProfile } = this.state
        return (
            <div>
                <Panel className="sorting-settings-panel profile">
                    <FormGroup>
                        <ControlLabel>Select profile</ControlLabel>
                        <Select
                            autoBlur
                            allowCreate={false}
                            backspaceRemoves={false}
                            clearable={false}
                            searchable={false}
                            options={createProfileSelectOptions(sortingProfiles)}
                            value={selectedSortingProfile}
                            onChange={this.onProfileSelect}
                            placeholder=""
                        />
                        {canEditRules && (
                            <HelpBlock>
                                The profile will be visualized and configurable on the sorting machine below
                            </HelpBlock>
                        )}
                    </FormGroup>
                    {terminal && sortingProfiles.includes(selectedSortingProfile) && (
                        <div style={{ marginTop: '12px' }}>
                            <ClearRulesButton
                                onConfirmation={() => dispatch(deleteProfileRules(terminal.id, selectedSortingProfile))}
                                confirmationMessage={`You are about to remove all rules from all lanes for profile ${SORTING_PROFILES[selectedSortingProfile]}.`}
                                isDeletingRules={deletingAllRulesFromProfile}
                                errorMessage={deletingAllRulesFromProfileError}
                                buttonText="Clear profile rules"
                            />
                        </div>
                    )}
                </Panel>
                {this.renderSortingMachine()}
            </div>
        )
    }
}

ProfilesTab.propTypes = {
    selectedTerminalId: PropTypes.number.isRequired,
    canEditRules: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    terminal: PropTypes.object.isRequired,
    terminalLanes: PropTypes.array,
    rules: PropTypes.array,
    routeRules: PropTypes.array.isRequired,
    lockerRules: PropTypes.array,
    lockerStops: PropTypes.array.isRequired,
    lockerRoutes: PropTypes.array,
    routeSortingStatuses: PropTypes.array.isRequired,
    routes: PropTypes.array,
    routesloaded: PropTypes.bool,
    lockers: PropTypes.array,
    hubPallets: PropTypes.array.isRequired,
    sortingProfiles: PropTypes.array.isRequired,
    terminalProfileRules: PropTypes.object.isRequired,
    deletingAllRulesFromProfile: PropTypes.bool,
    deletingAllRulesFromProfileError: PropTypes.string,
}

const mapStateToProps = (
    {
        terminals: { terminals },
        terminalRules: {
            terminalLanes,
            rules,
            routeRules,
            lockerRules,
            lockerStops,
            lockerRoutes,
            routeSortingStatuses,
            routes,
            routesloaded,
            lockers,
            hubPallets,
            sortingProfiles,
            deletingAllRulesFromProfile,
            deletingAllRulesFromProfileError,
        },
        terminalProfileRules,
    },
    { selectedTerminalId },
) => ({
    terminal: terminals.find(({ id }) => id === selectedTerminalId),
    terminalLanes,
    rules,
    routeRules,
    lockerRules,
    lockerStops,
    lockerRoutes,
    routeSortingStatuses,
    routes,
    routesloaded,
    lockers,
    hubPallets,
    sortingProfiles,
    terminalProfileRules,
    deletingAllRulesFromProfile,
    deletingAllRulesFromProfileError,
})

export default connect(mapStateToProps)(ProfilesTab)
