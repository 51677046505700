import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, HelpBlock, Button } from 'react-bootstrap'
import Select from 'react-select'
import AlertGroup from '../common/alert-group'
import getValue from '../../utils/value'
import auth from '../../auth'
import UserProfilePictureWrapper from './user-profile-picture-wrapper'
import Password from '../login/password'
import { curry } from '../../utils/curry'
import { handleError } from '../../utils/handle-error'
import { getUser, denyUserImage, userExists } from '../../utils/user-webapi'
import {
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    EXTERNAL_TERMINAL_WORKER,
    LEGACY_BUDBEE_TERMINAL_WORKER,
    LEGACY_TERMINAL_WORKER,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

const toOption = (data) => {
    if (data.role) {
        return { value: data.role.id, label: data.role.name }
    }
    return { value: data.key, label: data.value }
}

const toMerchantOption = (data) => {
    return {
        value: data.id,
        label: data.name === data.externalName ? data.externalName : `${data.externalName} (${data.name})`,
    }
}

const toTerminalOption = ({ id: value, name: label }) => ({ value, label })
const fromOption = ({ value }) => value

export default class Form extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            userId: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            password: null,
            validPassword: false,
            language: 'en',
            roles: [],
            connectedOwnerOperatorOptions: [],
            connectedBuyerOptions: [],
            terminal: null,
            userExists: false,
            imageUrl: null,
            showDeleteImgButton: false,
        }
    }

    componentDidMount() {
        if (this.props.initialUser != null) {
            getUser(this.props.initialUser)
                .then((res) => {
                    this.setState({
                        userId: res.id,
                        firstName: res.firstName,
                        lastName: res.lastName,
                        email: res.email,
                        phoneNumber: res.phoneNumber,
                        language: res.userSettings.language,
                        imageUrl: res.userSettings.image ? res.userSettings.image.url : null,
                        roles: res.userRoles.map(toOption),
                        connectedOwnerOperatorOptions: res.connectedOwnerOperators.map(toOption),
                        connectedBuyerOptions: res.connectedMerchants.map(toMerchantOption),
                        terminal: res.userSettings.warehouse ? toTerminalOption(res.userSettings.warehouse) : null,
                        showDeleteImgButton: auth.hasAnyRole(
                            OPERATIONS_ADMIN,
                            OPERATIONS_COORDINATOR,
                            TRAFFIC_CONTROLLER,
                        ),
                    })
                })
                .catch(handleError)
        }
    }

    setOptions = (type) => (def, options) => this.setState({ [type]: options || def })

    setOwnerOperatorOptions = this.setOptions('connectedOwnerOperatorOptions')

    setBuyerOptions = this.setOptions('connectedBuyerOptions')

    setRoleOptions = this.setOptions('roles')

    setTerminalOptions = this.setOptions('terminal')

    getUser() {
        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            language,
            roles,
            connectedOwnerOperatorOptions,
            connectedBuyerOptions,
            terminal,
        } = this.state
        const user = {
            firstName: getValue(firstName),
            lastName: getValue(lastName),
            email: email ? getValue(email).trim() : null,
            phoneNumber: getValue(phoneNumber),
            userSettings: {
                language: getValue(language),
                warehouse:
                    terminal === null
                        ? null
                        : {
                              id: fromOption(terminal),
                          },
            },
            roleIds: roles.map(fromOption),
            connectedOwnerOperatorIds: connectedOwnerOperatorOptions.map(fromOption),
            connectedBuyerIds: connectedBuyerOptions.map(fromOption),
        }
        if (this.props.passwordRequired) {
            user.password = getValue(password)
        }

        return user
    }

    validate = () => {
        if (this.props.passwordRequired && !this.state.validPassword) {
            return false
        }

        if (!this.props.validate(this.getUser()) || this.state.userExists) {
            return false
        }

        return true
    }

    deleteUserImage = () =>
        denyUserImage(this.state.userId)
            .then(() =>
                this.setState({
                    showDeleteImgButton: false,
                    imageUrl: '',
                }),
            )
            .catch(handleError)

    onChange = curry((prop, name, e) => this.setState({ [name]: e.target[prop] }))

    onChangeLanguage = (selectedOption) => {
        this.setState({ language: selectedOption.value })
    }

    onChangeEmail = curry((prop, name, e) => {
        this.onChange(prop, name, e)

        userExists(e.target[prop])
            .then(() => this.setState({ userExists: true }))
            .catch(() => this.setState({ userExists: false }))
    })

    onPasswordChange = ({ password, valid: validPassword }) => this.setState({ password, validPassword })

    renderPasswordField() {
        if (!this.props.passwordRequired) {
            return null
        }

        return (
            <Password
                userInputs={[this.state.email, this.state.firstName, this.state.lastName, this.state.phoneNumber]}
                onChange={this.onPasswordChange}
            />
        )
    }

    renderOwnerOperatorOptionsSelect() {
        if (!this.props.canSetCourier) {
            return null
        }

        return (
            <FormGroup>
                <ControlLabel>Employed by courier(s)</ControlLabel>
                <Select
                    multi
                    value={this.state.connectedOwnerOperatorOptions}
                    options={this.props.ownerOperatorOptions}
                    onChange={this.setOwnerOperatorOptions}
                />
            </FormGroup>
        )
    }

    renderBuyerOptionsSelect() {
        if (!this.props.canSetMerchant) {
            return null
        }

        return (
            <FormGroup>
                <ControlLabel>Employed by Merchant(s)</ControlLabel>
                <Select
                    multi
                    value={this.state.connectedBuyerOptions}
                    options={this.props.buyerOptions}
                    onChange={this.setBuyerOptions}
                />
            </FormGroup>
        )
    }

    renderRolesSelect() {
        return (
            <FormGroup>
                <ControlLabel>Roles</ControlLabel>
                <Select
                    multi
                    value={this.state.roles}
                    options={this.props.roleOptions}
                    onChange={this.setRoleOptions}
                />
            </FormGroup>
        )
    }

    renderTerminalWorkerSelect() {
        const isTerminalWorker = this.state.roles.some((r) =>
            [
                LEGACY_BUDBEE_TERMINAL_WORKER,
                LEGACY_TERMINAL_WORKER,
                TERMINAL_ADMIN,
                TERMINAL_WORKER,
                EXTERNAL_TERMINAL_WORKER,
            ].includes(r.label.toLowerCase()),
        )

        if (!isTerminalWorker) {
            return null
        }

        return (
            <FormGroup>
                <ControlLabel>Works at Terminal</ControlLabel>
                <Select
                    multi={false}
                    value={this.state.terminal}
                    options={this.props.terminalOptions}
                    onChange={this.setTerminalOptions}
                />
            </FormGroup>
        )
    }

    render() {
        const { errors, statusCode } = this.props

        return (
            <Grid fluid>
                <Row>
                    <Col md={4} mdOffset={4}>
                        {this.state.imageUrl != null ? (
                            <UserProfilePictureWrapper
                                imageUrl={this.state.imageUrl}
                                showDeleteImgButton={this.state.showDeleteImgButton}
                                deleteUserImage={this.deleteUserImage}
                            />
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col md={4} mdOffset={4}>
                        <form onSubmit={this.props.onSubmit}>
                            <FormGroup validationState={this.state.userExists ? 'error' : null}>
                                <ControlLabel>Email</ControlLabel>
                                <FormControl
                                    type="email"
                                    value={this.state.email || ''}
                                    placeholder="Email"
                                    onChange={this.onChangeEmail('value', 'email')}
                                />
                                <FormControl.Feedback />
                                <HelpBlock>{this.state.userExists ? 'User exists' : null}</HelpBlock>
                            </FormGroup>
                            {this.renderPasswordField()}
                            <FormGroup>
                                <ControlLabel>First name</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.firstName || ''}
                                    placeholder="First name"
                                    onChange={this.onChange('value', 'firstName')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Lastname</ControlLabel>
                                <FormControl
                                    type="text"
                                    value={this.state.lastName || ''}
                                    placeholder="Last name"
                                    onChange={this.onChange('value', 'lastName')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Phonenumber</ControlLabel>
                                <FormControl
                                    type="tel"
                                    value={this.state.phoneNumber || ''}
                                    placeholder="Phone number"
                                    onChange={this.onChange('value', 'phoneNumber')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>Language</ControlLabel>
                                <Select
                                    onChange={this.onChangeLanguage}
                                    value={this.state.language || ''}
                                    options={[
                                        { value: 'en', label: 'English' },
                                        { value: 'sv', label: 'Swedish' },
                                        { value: 'ru', label: 'Russian' },
                                        { value: 'nl', label: 'Dutch' },
                                    ]}
                                />
                            </FormGroup>
                            {this.renderOwnerOperatorOptionsSelect()}
                            {this.renderBuyerOptionsSelect()}
                            {this.renderRolesSelect()}
                            {this.renderTerminalWorkerSelect()}
                            {errors && <AlertGroup statusCode={statusCode} message={errors} />}
                            <FormGroup>
                                <Button type="submit" bsStyle="primary" disabled={!this.validate()}>
                                    Save
                                </Button>
                                <Button onClick={this.props.onCancel}>Cancel</Button>
                            </FormGroup>
                        </form>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

Form.propTypes = {
    passwordRequired: PropTypes.bool,
    initialUser: PropTypes.string,
    validate: PropTypes.func,
    ownerOperatorOptions: PropTypes.array,
    buyerOptions: PropTypes.array,
    roleOptions: PropTypes.array,
    terminalOptions: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    canSetCourier: PropTypes.bool,
    canSetMerchant: PropTypes.bool,
    errors: PropTypes.array,
    statusCode: PropTypes.number,
}

Form.defaultProps = {
    passwordRequired: false,
}
