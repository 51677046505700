import React from 'react'
import { IndexRoute, Route, Redirect } from 'react-router'
import Couriers from '../components/couriers'
import CourierHandler from '../components/couriers/courier-handler'
import CouriersCreate from '../components/couriers/create'
import CourierSettings from '../components/couriers/settings'
import CourierInvoicing from '../components/couriers/invoicing'
import CourierDangerZone from '../components/couriers/danger-zone'
import CourierPricePoints from '../components/couriers/price-points'
import { UsersList, UsersView, UsersCreate, UsersEdit, UsersImages } from '../components/users'
import MerchantHandler from '../components/buyers/merchant-handler'
import {
    BuyersCreate,
    BuyersList,
    CollectionPoints as MerchantCollectionPoints,
    Settings as MerchantSettings,
    Invoicing as MerchantInvoicing,
    Distribution as MerchantDistribution,
    Products as MerchantProducts,
    Notifications as MerchantNotifications,
    Shopping as ShoppingSettings,
    Coupons as CouponSettings,
    ShoppingIndexingSettings,
    ShoppingCollections,
} from '../components/buyers'
import { RolesList, RolesView } from '../components/roles'
import {
    ZoneManagementListHandler,
    ZoneManagementGroupHandler,
    ZoneManagementCreateHandler,
    ZoneManagementEditHandler,
    ZoneManagementViewHandler,
} from '../components/zoneManagement'
import CreateMerchantCollectionPoint from '../components/buyers/collection-point/create'
import { Geocoding, SaveVariationHelp } from '../components/geocoding'
import { OrdersView } from '../components/orders'
import AppHandler from '../components/app-handler'
import AdminHandler from '../components/admin-handler'
import UnauthorizedHandler from '../components/unauthorized-handler'
import RecurringPickupsListHandler from '../components/recurring-pickups/list-handler'
import RecurringPickupsCreateHandler from '../components/recurring-pickups/create-handler'
import RecurringPickupHandler from '../components/recurring-pickups/view-handler'
import RecurringPickupEditHandler from '../components/recurring-pickups/edit-handler'
import RecurringPickupCopyHandler from '../components/recurring-pickups/copy-handler'
import OneTimePickupCreateHandler from '../components/recurring-pickups/one-time-pickup/create-handler'
import AdminMapHandler from '../components/admin-map-handler'
import { Login, Session } from '../components/login'
import { Auth0 } from '../components/auth0'
import RouteSearchHandler from '../components/route-search-handler'
import SchedulerHandler from '../components/scheduler-handler'
import SMSSenderView from '../components/sms-notifications/sms-sender-view'
import SocialShare from '../components/social-share'
import EditRouteHandler from '../components/edit-route-handler'
import { TerminalSortingView, RouteSortingView } from '../components/terminal-sorting'
import { Holidays } from '../components/holidays'
import { LockerHolidayUnblocks } from '../components/locker-holidays'
import MerchantInvoiceItems from '../components/buyers/manage-invoice-items'
import CourierInvoiceItems from '../components/couriers/manage-invoice-items'
import RejectedInvoiceItemView from '../components/rejected-invoices/rejected-invoice-item-view'
import RejectedInvoiceView from '../components/rejected-invoices/rejected-invoice-view'
import { TerminalLimits } from '../components/terminal-limits'
import LoadingScheduler from '../components/loading-scheduler'
import RouteAnalysis from '../components/routes/analysis/analysis'
import Search from '../components/search/index'
import TrackingBanners from '../components/communication/tracking-banners'
import ConsumerSearchHandler from '../components/consumer-search-handler'
import { ConsumersView } from '../components/consumers'
import PalletConfiguration from '../components/hub-pallet-configuration/hub-pallet-configuration'
import ReturnPalletConfiguration from '../components/return-pallet-configuration/return-pallet-configuration'
import GroundOperationsIndex from '../ground-operations'
import ChronoRoutes from '../components/chrono-routes'
import AutoConsignmentBooking from '../components/auto-consignment-booking'
import IngridDedup from '../components/other/ingrid-dedup'

const routes = (
    <Route name="app" path="/" component={AppHandler}>
        <Route path="/admin/geocoding/save-variation" component={SaveVariationHelp} />
        <Route path="/admin" component={AdminHandler}>
            <Route path="unauthorized" component={UnauthorizedHandler} />

            <Route path="users">
                <Route path="/admin/users/create" component={UsersCreate} />
                <Route path="/admin/users/:id" component={UsersView} />
                <Route path="/admin/users/:id/edit" component={UsersEdit} />
                <Route path="/admin/users/images/recent" component={UsersImages} />
                <IndexRoute component={UsersList} />
            </Route>

            <Route path="social">
                <IndexRoute component={SocialShare} />
            </Route>

            <Route path="/admin/couriers">
                <IndexRoute component={Couriers} />
                <Route path="/admin/couriers/create" component={CouriersCreate} />
                <Route path="/admin/couriers/:id/manage-invoice-items" component={CourierInvoiceItems} />
                <Route path="/admin/couriers/:id/rejected-invoices" component={RejectedInvoiceView} />
                <Route
                    path="/admin/couriers/:id/rejected-invoices/:rejectedInvoiceId"
                    component={RejectedInvoiceItemView}
                />
                <Route path="/admin/couriers/:id" component={CourierHandler}>
                    <IndexRoute component={CourierSettings} />
                    <Route path="/admin/couriers/:id/invoicing" component={CourierInvoicing} />
                    <Route path="/admin/couriers/:id/danger-zone" component={CourierDangerZone} />
                    <Route path="/admin/couriers/:id/price-points" component={CourierPricePoints} />
                </Route>
            </Route>

            <Route path="/admin/carriers">
                <IndexRoute component={Couriers} />
            </Route>

            <Redirect from="buyers" to="merchants" />
            <Route path="merchants">
                <IndexRoute component={BuyersList} />
                <Route path="/admin/merchants/create" component={BuyersCreate} />
                <Route path="/admin/merchants/:id/manage-invoice-items" component={MerchantInvoiceItems} />
                <Route path="/admin/merchants/:id" component={MerchantHandler}>
                    <IndexRoute component={MerchantCollectionPoints} />
                    <Route
                        path="/admin/merchants/:id/create-collection-point"
                        component={CreateMerchantCollectionPoint}
                    />
                    <Route path="/admin/merchants/:id/general" component={MerchantSettings} />
                    <Route path="/admin/merchants/:id/invoicing" component={MerchantInvoicing} />
                    <Route path="/admin/merchants/:id/distribution" component={MerchantDistribution} />
                    <Route path="/admin/merchants/:id/products" component={MerchantProducts} />
                    <Route path="/admin/merchants/:id/notifications" component={MerchantNotifications} />
                    <Route path="/admin/merchants/:id/shopping" component={ShoppingSettings} />
                    <Route path="/admin/merchants/:id/coupons" component={CouponSettings} />
                    <Route path="/admin/merchants/:id/indexing-settings" component={ShoppingIndexingSettings} />
                    <Route path="/admin/merchants/:id/shopping-collections" component={ShoppingCollections} />
                </Route>
            </Route>

            <Route path="roles">
                <Route path="/admin/roles/:id" component={RolesView} />
                <IndexRoute component={RolesList} />
            </Route>

            <Route path="/admin/ground-operations*">
                <IndexRoute component={GroundOperationsIndex} />
            </Route>

            <Route path="/admin/zone-management">
                <IndexRoute component={ZoneManagementListHandler} />
                <Route path=":type/:groupName/:groupId/:action" component={ZoneManagementGroupHandler} />
                <Route path=":type/:groupName/:groupId/view/:terminalCode" component={ZoneManagementViewHandler} />
                <Route path=":type/:groupName/:groupId/edit/create" component={ZoneManagementCreateHandler} />
                <Route path=":type/:groupName/:groupId/edit/:terminalCode" component={ZoneManagementEditHandler} />
            </Route>

            <Route path="orders">
                <Route path="/admin/orders/:token" component={OrdersView} />
                <IndexRoute component={Search} />
            </Route>

            <Route path="consumers">
                <IndexRoute component={ConsumerSearchHandler} />
                <Route path="/admin/consumers/consumer/:phoneNumber" component={ConsumersView} />
            </Route>

            <Redirect from="/admin/merchant-routes" to="/admin/recurring-routes" />
            <Route path="/admin/recurring-routes">
                <IndexRoute component={RecurringPickupsListHandler} />
                <Route path="create" component={RecurringPickupsCreateHandler} />
                <Route path=":id" component={RecurringPickupHandler} />
                <Route path=":id/edit" component={RecurringPickupEditHandler} />
                <Route path=":id/one-time-route" component={OneTimePickupCreateHandler} />
                <Route path=":id/copy" component={RecurringPickupCopyHandler} />
            </Route>

            <Route path="/admin/scheduler" component={SchedulerHandler} />
            <Route path="/admin/geocoding" component={Geocoding} />
            <Route path="/admin/edit-route/:routeId" component={EditRouteHandler} />
            <Route path="/admin/routes/:routeId" component={EditRouteHandler} />
            <Route path="/admin/routes/:routeId/analysis" component={RouteAnalysis} />
            <Route path="/admin/map/:routeId" component={AdminMapHandler} />
            <Route path="/admin/route-search" component={RouteSearchHandler} />
            <Route path="/admin/search" component={Search} />

            <Route path="/admin/terminal-sorting" component={TerminalSortingView} />
            <Route path="/admin/terminal-sorting/:routeId" component={RouteSortingView} />

            <Route path="/admin/sms" component={SMSSenderView} />

            <Route path="/admin/holidays" component={Holidays} />

            <Route path="/admin/locker-holidays" component={LockerHolidayUnblocks} />

            <Route path="/admin/chrono-routes" component={ChronoRoutes} />

            <Route path="/admin/terminal-loading" component={LoadingScheduler} />

            <Route path="/admin/hub-pallet-configuration" component={PalletConfiguration} />

            <Route path="/admin/return-pallet-configuration" component={ReturnPalletConfiguration} />

            <Route path="/admin/auto-consignment-booking" component={AutoConsignmentBooking} />

            <Route path="/admin/terminal-limits" component={TerminalLimits} />
            <Redirect from="/admin/city-limits" to="/admin/terminal-limits" />

            <Route path="/admin/tracking-banners" component={TrackingBanners} />

            <Route path="/admin/ingrid-dedup" component={IngridDedup} />

            <Redirect from="/admin/?" to="carriers" />
        </Route>

        <Route path="login">
            <IndexRoute component={Login} />
            <Route path="/login/session" component={Session} />
        </Route>
        <Route path="/auth0/retool" component={() => <Auth0 vendor="retool" />} />
    </Route>
)

export default routes
