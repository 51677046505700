import { LOADING_SLOTS_LOADING, LOADING_SLOTS_FAILED_TO_LOAD, LOADING_SLOTS_LOADED } from '../constants/actionTypes'
import { getTerminalLoadingSlots } from '../utils/terminal-sorting-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

export const loadingSlotsLoaded = (data) => ({
    type: LOADING_SLOTS_LOADED,
    status: 200,
    response: {
        data,
    },
})

export const loadingSlotsFailedToLoad = ({ status, statusText: error }) => ({
    type: LOADING_SLOTS_FAILED_TO_LOAD,
    status,
    error,
})

export const fetchingLoadingSlots = () => ({
    type: LOADING_SLOTS_LOADING,
})

/**
 * Fetch available loading slots
 * @param {*} city
 * @param {*} date
 */
export const fetchLoadingSlots = (city, date) => (dispatch) => {
    dispatch(fetchingLoadingSlots())
    return getTerminalLoadingSlots(city, date)
        .then(compose(dispatch, loadingSlotsLoaded))
        .catch(compose(dispatch, loadingSlotsFailedToLoad, handleError))
}
