import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class RecurringPaymentTransactionInline extends Component {
    statusOfTransaction = () => {
        const { status } = this.props
        if (status === 'INITIATED') {
            return { text: 'Pending', style: 'default' }
        }
        if (status === 'APPROVED') {
            return { text: 'Approved', style: 'success' }
        }
        if (status === 'DENIED') {
            return { text: 'Denied', style: 'danger' }
        }
        return { text: status, style: 'default' }
    }

    processed = () => {
        const { processed } = this.props
        if (processed) {
            return { text: 'Yes', style: 'success' }
        }
        return { text: 'No', style: 'warning' }
    }

    render() {
        const { amount, currency, initiatedAt, adyenReference } = this.props
        const status = this.statusOfTransaction()
        const processed = this.processed()
        return (
            <tr>
                <td>{adyenReference}</td>
                <td>
                    {amount} {currency}
                </td>
                <td>{moment(initiatedAt).format('YYYY-MM-DD HH:mm UTC')}</td>
                <td>
                    <span className={`label label-${status.style}`}>{status.text}</span>
                </td>
                <td>
                    <span className={`label label-${processed.style}`}>{processed.text}</span>
                </td>
            </tr>
        )
    }
}

RecurringPaymentTransactionInline.propTypes = {
    amount: PropTypes.number,
    adyenReference: PropTypes.string,
    currency: PropTypes.string,
    initiatedAt: PropTypes.string,
    status: PropTypes.string,
    processed: PropTypes.bool,
}

export default RecurringPaymentTransactionInline
