import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, FormControl } from 'react-bootstrap'
import DeliveryPreferencesSelect, { deliveryPreferencesTypes } from '../../inputs/selects/deliveryPreferences'
import ResidenceTypesSelect, { residenceTypes } from '../../inputs/selects/residenceTypes'
import { updateResidence as updateConsumerResidence } from '../../../utils/consumer-webapi'
import { handleError } from '../../../utils/handle-error'

/**
 * Render an End Customer
 */
class ResidenceInline extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editing: false,
            residence: props.residence,
            residenceUpdated: props.residence,
        }
    }

    onInputChange = (stateField, value) =>
        this.setState((prevState) => ({
            ...prevState,
            residenceUpdated: {
                ...prevState.residenceUpdated,
                settings: {
                    ...prevState.residenceUpdated.settings,
                    [stateField]: value,
                },
            },
        }))

    cancelEditing = () => this.setState((prevState) => ({ editing: false, residenceUpdated: prevState.residence }))

    toggleEdit = () => this.setState({ editing: true })

    updateResidence = () => {
        updateConsumerResidence(
            this.props.residence.id,
            this.props.residence.consumerId,
            this.state.residenceUpdated.settings,
        )
            .then(() => this.setState((prevState) => ({ editing: false, residence: prevState.residenceUpdated })))
            .catch(handleError)
    }

    valueOrNALabel = (o) => o || <span className="text-muted">N/A</span>

    valueInput = (o) => o || ''

    renderEditInfo() {
        const { residenceUpdated } = this.state
        return (
            <tr key={residenceUpdated.id}>
                <td>{this.valueOrNALabel(residenceUpdated.street)}</td>
                <td>{this.valueOrNALabel(residenceUpdated.postalCode)}</td>
                <td>{this.valueOrNALabel(residenceUpdated.city)}</td>
                <td>{this.valueOrNALabel(residenceUpdated.country)}</td>
                <td>
                    <ResidenceTypesSelect
                        stateField="estateType"
                        onChange={this.onInputChange}
                        selected={residenceUpdated.settings.estateType}
                    />
                </td>
                <td>
                    <FormControl
                        style={{ maxWidth: 80 }}
                        type="text"
                        value={this.valueInput(residenceUpdated.settings.accessCode)}
                        onChange={(e) => this.onInputChange('accessCode', e.target.value)}
                    />
                </td>
                <td>
                    <FormControl
                        style={{ maxWidth: 50 }}
                        type="text"
                        value={this.valueInput(residenceUpdated.settings.floor)}
                        onChange={(e) => this.onInputChange('floor', e.target.value)}
                    />
                </td>
                <td>
                    <FormControl
                        style={{ maxWidth: 200 }}
                        type="text"
                        componentClass="textarea"
                        placeholder="Add special instructions..."
                        value={this.valueInput(residenceUpdated.settings.specialInstructions)}
                        onChange={(e) => this.onInputChange('specialInstructions', e.target.value)}
                    />
                </td>
                <td>
                    <FormControl
                        style={{ maxWidth: 150 }}
                        type="text"
                        value={this.valueInput(residenceUpdated.settings.careOf)}
                        onChange={(e) => this.onInputChange('careOf', e.target.value)}
                    />
                </td>
                <td style={{ width: 100 }}>
                    <DeliveryPreferencesSelect
                        country={residenceUpdated.country}
                        stateField="deliveryMode"
                        onChange={this.onInputChange}
                        selected={residenceUpdated.settings.deliveryMode}
                        optionsData={deliveryPreferencesTypes}
                    />
                </td>
                <td>{this.renderEditButton()}</td>
            </tr>
        )
    }

    renderInfo = () => {
        const { residence } = this.state
        return (
            <tr>
                <td>{this.valueOrNALabel(residence.street)}</td>
                <td>{this.valueOrNALabel(residence.postalCode)}</td>
                <td>{this.valueOrNALabel(residence.city)}</td>
                <td>{this.valueOrNALabel(residence.country)}</td>
                <td>{residenceTypes[residence.settings.estateType]}</td>
                <td>{this.valueOrNALabel(residence.settings.accessCode)}</td>
                <td>{this.valueOrNALabel(residence.settings.floor)}</td>
                <td>{this.valueOrNALabel(residence.settings.specialInstructions)}</td>
                <td>{this.valueOrNALabel(residence.settings.careOf)}</td>
                <td>{deliveryPreferencesTypes[residence.settings.deliveryMode]}</td>
                <td>{this.renderEditButton()}</td>
            </tr>
        )
    }

    renderInformation = () => (this.state.editing ? this.renderEditInfo() : this.renderInfo())

    renderEditButton() {
        return (
            <Row>
                <Col md={12}>
                    <Button
                        bsSize="small"
                        bsStyle="primary"
                        onClick={this.state.editing ? this.updateResidence : this.toggleEdit}
                    >
                        {this.state.editing ? 'Save' : 'Edit'}
                    </Button>
                    {this.state.editing && (
                        <Button bsSize="small" bsStyle="warning" onClick={this.cancelEditing}>
                            Cancel
                        </Button>
                    )}
                </Col>
            </Row>
        )
    }

    render() {
        return this.renderInformation()
    }
}

ResidenceInline.propTypes = {
    residence: PropTypes.object,
}

export default ResidenceInline
