import React, { Component } from 'react'
import { Form, FormGroup, FormControl, Button, Col, ControlLabel, InputGroup } from 'react-bootstrap/lib'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { SingleDatePicker } from 'react-dates'
import currencies, { SEK } from '../../../../utils/currencies'
import CountrySelect from '../../../common/country-select'

const availableCurrencies = currencies()

const getInitState = () => ({
    newZones: [],
    partitionIds: [],
    quantity: false,
    fixedRate: false,
    incrementalRate: false,
    currency: SEK,
    valid: true,
    quantityEnum: null,
    tierEnums: [],
    startDate: null,
    endDate: null,
    startDateFocused: false,
    endDateFocused: false,
})

class AddTier extends Component {
    constructor(props) {
        super(props)
        this.state = getInitState()
        this.onPartitionChange = this.onPartitionChange.bind(this)
        this.changePrice = this.changePrice.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)
        this.addPriceTier = this.addPriceTier.bind(this)
        this.changePricePerUnit = this.changePricePerUnit.bind(this)
        this.startDate = this.changeValidStartDate.bind(this)
        this.endDate = this.changeValidEndDate.bind(this)
    }

    componentDidMount() {
        this.initState()
    }

    onPartitionChange(value) {
        this.setState({ partitionIds: value })
        if (this.props.emitValueAfterChange) {
            this.props.emitZonesChange(value)
        }
    }

    initState() {
        this.setState(getInitState())
    }

    resetState() {
        this.setState({
            quantity: false,
            fixedRate: false,
            incrementalRate: false,
            valid: true,
            startDate: null,
            endDate: null,
            startDateFocused: false,
            endDateFocused: false,
        })
    }

    changePrice(event) {
        this.setState({ fixedRate: event.target.value })
    }

    changeCurrency(event) {
        this.setState({ currency: event.target.value })
    }

    changePricePerUnit(event) {
        this.setState({ incrementalRate: event.target.value })
    }

    changeQuantity(event) {
        this.setState({ quantity: event.target.value })
    }

    changeValidStartDate = (startDate) => {
        this.setState({ startDate })
    }

    changeValidEndDate = (endDate) => {
        this.setState({ endDate })
    }

    onCountryChange = (countries) => {
        if (!countries.length) {
            return
        }
        const newCountriesValues = countries.map((c) => c.value)
        const newZones = this.props.postalZones.filter((p) => newCountriesValues.includes(p.countryCode))
        this.setState({ newZones })
    }

    validate() {
        if (
            this.state.partitionIds.length > 0 &&
            this.state.currency !== null &&
            this.state.quantity &&
            this.state.fixedRate
        ) {
            return true
        }
        this.setState({ valid: false })
        return false
    }

    addPriceTier() {
        if (this.validate()) {
            const { partitionIds, quantity, fixedRate, incrementalRate, startDate, endDate } = this.state
            const form = {
                partitionIds: partitionIds.map((z) => z.value),
                quantity,
                fixedRate: Math.round(Number(fixedRate).toFixed(2) * 100),
                incrementalRate: Math.round(Number(incrementalRate).toFixed(2) * 100),
                currency: this.state.currency,
                validFrom: startDate,
                validTo: endDate,
            }
            this.props.addPriceTier(this.props.priceTierType, form)
            this.resetState()
        }
    }

    render() {
        const {
            valid,
            fixedRate,
            incrementalRate,
            quantity,
            partitionIds,
            currency,
            newZones,
            startDate,
            endDate,
            startDateFocused,
            endDateFocused,
        } = this.state
        const {
            unit,
            partitionRenderer,
            partitionName,
            enumTier,
            enumTiers,
            enumQuantityMap,
            showSelectCountry,
        } = this.props
        const basePrice = enumTier ? 'Price' : 'Base Price'
        return (
            <Form horizontal>
                {showSelectCountry ? (
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={2} className="mt-xs">
                            Select Country
                        </Col>
                        <Col sm={10}>
                            <p>Choose a country to pre-fill all delivery zones</p>
                            <CountrySelect multi onSelect={(countries) => this.onCountryChange(countries)} />
                        </Col>
                    </FormGroup>
                ) : null}

                <FormGroup validationState={!valid && partitionIds.length === 0 ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        {partitionName}{' '}
                    </Col>
                    <Col sm={10}>{partitionRenderer(this.onPartitionChange, newZones)}</Col>
                </FormGroup>
                <FormGroup validationState={!valid && !currency ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        Currency{' '}
                    </Col>
                    <Col sm={10}>
                        <Select
                            class="form-horizontal"
                            allowCreate={false}
                            backspaceRemoves={false}
                            clearable={false}
                            searchable={false}
                            isLoading={false}
                            options={availableCurrencies}
                            value={this.state.currency}
                            autosize={false}
                            onChange={(item) => this.setState({ currency: item.value })}
                        />
                    </Col>
                </FormGroup>
                <FormGroup validationState={!valid && !fixedRate ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={2}>
                        {` ${basePrice} `}
                    </Col>
                    <Col sm={10}>
                        <InputGroup>
                            <InputGroup.Addon>{this.state.currency}</InputGroup.Addon>
                            <FormControl
                                type="number"
                                step="any"
                                placeholder="Price"
                                value={fixedRate}
                                onChange={this.changePrice}
                            />
                        </InputGroup>
                    </Col>
                </FormGroup>
                {enumTier ? (
                    <FormGroup validationState={!valid && !quantity ? 'error' : null}>
                        <Col componentClass={ControlLabel} sm={2}>
                            {' '}
                            {unit.code}{' '}
                        </Col>
                        <Col sm={10}>
                            <Select
                                class="form-horizontal"
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                searchable={false}
                                isLoading={false}
                                options={enumTiers}
                                value={this.state.quantityEnum}
                                autosize={false}
                                onChange={(item) => {
                                    this.setState({
                                        quantityEnum: item.value,
                                        quantity: enumQuantityMap.get(item.value),
                                    })
                                }}
                            />
                        </Col>
                    </FormGroup>
                ) : (
                    <div>
                        <FormGroup>
                            <Col componentClass={ControlLabel} sm={2}>
                                {' '}
                                Per {unit.code} +
                            </Col>
                            <Col sm={10}>
                                <InputGroup>
                                    <InputGroup.Addon>
                                        {this.state.currency} / {unit.code}
                                    </InputGroup.Addon>
                                    <FormControl
                                        step="any"
                                        type="number"
                                        placeholder={`Price per ${unit.code}`}
                                        value={incrementalRate}
                                        onChange={this.changePricePerUnit}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup validationState={!valid && !quantity ? 'error' : null}>
                            <Col componentClass={ControlLabel} sm={2}>
                                {' '}
                                {unit.name}{' '}
                            </Col>
                            <Col sm={10}>
                                <InputGroup>
                                    <FormControl
                                        type="number"
                                        step="any"
                                        placeholder={unit.name}
                                        value={quantity}
                                        onChange={this.changeQuantity}
                                    />
                                    <InputGroup.Addon>{unit.code}</InputGroup.Addon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </div>
                )}
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        Valid from
                    </Col>
                    <Col sm={10}>
                        <SingleDatePicker
                            date={startDate}
                            onDateChange={this.changeValidStartDate}
                            focused={startDateFocused}
                            onFocusChange={({ focused }) => this.setState({ startDateFocused: focused })}
                            displayFormat="YYYY-MM-DD"
                            id="singledatepicker_start_date"
                            placeholder="Date (Optional)"
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col componentClass={ControlLabel} sm={2}>
                        {' '}
                        Valid to
                    </Col>
                    <Col sm={10}>
                        <SingleDatePicker
                            date={endDate}
                            onDateChange={this.changeValidEndDate}
                            focused={endDateFocused}
                            onFocusChange={({ focused }) => this.setState({ endDateFocused: focused })}
                            displayFormat="YYYY-MM-DD"
                            id="singledatepicker_end_date"
                            placeholder="Date (Optional)"
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col smOffset={2} sm={10}>
                        <Button className="btn btn-primary right" onClick={this.addPriceTier}>
                            Add
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}

AddTier.propTypes = {
    addPriceTier: PropTypes.func,
    priceTierType: PropTypes.string,
    partitionRenderer: PropTypes.func,
    partitionName: PropTypes.string,
    unit: PropTypes.object.isRequired,
    enumTier: PropTypes.bool.isRequired,
    enumTiers: PropTypes.array,
    postalZones: PropTypes.array,
    enumQuantityMap: PropTypes.object,
    emitZonesChange: PropTypes.func,
    emitValueAfterChange: PropTypes.bool,
    showSelectCountry: PropTypes.bool,
}

export default AddTier
