import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Button, Alert, Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../actions/owner'
import Confirm from '../confirm'

class DangerZone extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showDeleteModal: false,
            error: false,
        }
    }

    onError = () => this.setState({ error: true })

    onConfirmDelete = () => this.setState({ showDeleteModal: true })

    deleteCourier = () => {
        this.setState({ error: false })

        this.props.actions
            .deleteCouriers(this.props.params.id)
            .then(() => this.props.router.push('/admin/couriers'))
            .catch(this.onError)
    }

    renderDeleteConfirmation() {
        const { courier } = this.props
        const { showDeleteModal } = this.state

        return (
            <Confirm
                show={showDeleteModal}
                title="Remove courier"
                bsStyle="danger"
                onClose={() => this.setState({ showDeleteModal: false })}
                onConfirm={() => {
                    this.deleteCourier()
                    this.setState({ showDeleteModal: false })
                }}
            >
                {`Completely remove courier '${courier.name}'?`}
            </Confirm>
        )
    }

    render() {
        if (this.props.loading) {
            return null
        }

        return (
            <Col className="mt-sm" md={8} mdOffset={1}>
                <Alert bsStyle="warning">
                    Be careful! This area contains dangerous operations, some of which cannot be undone
                </Alert>
                {this.state.error && (
                    <Alert bsStyle="danger">
                        <Glyphicon glyph="thumbs-down" /> Oops, something went wrong!
                    </Alert>
                )}
                <Button bsStyle="danger" onClick={this.onConfirmDelete}>
                    <Glyphicon glyph="trash" /> Delete this courier
                </Button>
                {this.renderDeleteConfirmation()}
            </Col>
        )
    }
}

const mapStateToProps = (state) => ({
    courier: state.couriers.courier,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(DangerZone)

DangerZone.propTypes = {
    router: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    actions: PropTypes.object,
    courier: PropTypes.object,
    loading: PropTypes.bool,
}
