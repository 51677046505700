import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Glyphicon } from 'react-bootstrap'
import moment from 'moment'

const tableHeadingKeys = ['Product Title', 'Order', 'Merchant Id', 'Current Price', 'Image Url', 'Created', 'Actions']

export default class ProductsList extends Component {
    renderProducts = () => {
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            {tableHeadingKeys.map((heading) => {
                                return <th key={heading}>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.products.map((product) => {
                            return (
                                <tr key={product.id}>
                                    <td>{product.title}</td>
                                    <td>{product.order}</td>
                                    <td>{product.merchantId}</td>
                                    <td>{product.currentPrice}</td>
                                    <td>{product.imageUrl}</td>
                                    <td>{moment(product.created).format('YYYY-MM-DD')}</td>
                                    <td>
                                        <Button
                                            bsStyle="danger"
                                            bsSize="xsmall"
                                            onClick={() => this.props.onRemoveProduct(product.id)}
                                        >
                                            <Glyphicon glyph="trash" />
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return <div>{this.renderProducts()}</div>
    }
}

ProductsList.propTypes = {
    onRemoveProduct: PropTypes.func,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            currentPrice: PropTypes.number,
            imageUrl: PropTypes.string,
            previousPrice: PropTypes.number,
            title: PropTypes.string,
            created: PropTypes.string,
        }),
    ).isRequired,
}
