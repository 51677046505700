import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from 'react-loader'
import PropTypes from 'prop-types'

import SortingMachineLaneConfigModal from '../sorting-machine/sorting-machine-lane-modal'
import MessageModal from '../../../../common/modal-message'
import {
    DATE,
    DISTRIBUTION_ROUTE,
    LOCKER,
    LOCKER_LINEHAUL,
    LOCKER_STOP,
    MERCHANT_PRIORITY,
    OVERSIZE_LOCKER_PARCEL,
    READ_FAIL,
    RETURN_LINEHAUL,
    TEMPORAL,
    LINEHAUL,
    RETURN_TO_MERCHANT,
    LINEHAUL_HUB_OR_REGION,
    ON_DEMAND_ROUTE,
} from '../../../../../utils/terminal-sorting-rule-types'
import {
    applySelectedSortingProfile,
    assignOnDemandRoutesToLanes,
    assignRoutesToLanes,
    createDateRule,
    createTemporalRule,
    createLockerLinehaulRule,
    createLockerRule,
    createLockerStopRule,
    createRules,
    createOversizeLockerParcelRule,
    createReadFailRule,
    createReturnLinehaulRule,
    createOnDemandRouteRule,
    createRouteRule,
    createTerminalRule,
    createReturnToMerchantRule,
    deleteDateRule,
    deleteTemporalRule,
    deleteLockerLinehaulRule,
    deleteLockerStopRule,
    deleteLockerRule,
    createMerchantPriorityRule,
    deleteMerchantPriorityRule,
    deleteOversizeLockerParcelRule,
    deleteReadFailRule,
    deleteReturnLinehaulRule,
    deleteOnDemandRouteRule,
    deleteRouteRule,
    deleteTerminalRule,
    editSortingProfile,
    getAllLockerStops,
    deleteReturnToMerchantRule,
    createLinehaulHubOrRegionRule,
    deleteLinehaulHubOrRegionRule,
} from '../../../../../actions/terminal-rules'
import TerminalSettings from './terminal-settings'
import { SORTING_PROFILES } from '../constants'
import SortingMachine from '../sorting-machine/sorting-machine'
import SortingProgress from './sorting-progress'
import { getProfileKeyByName } from '../get-profile-key-by-name'

class SettingsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editingLane: null,
            selectedSortingProfile: null,
            applyingProfile: false,
            showMessage: false,
            message: '',
            bsStyle: 'info',
            title: '',
        }
    }

    onSaveDateRule = (date, recurring, lesser, greater) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createDateRule(selectedTerminalId, this.state.editingLane.id, date, recurring, lesser, greater))
    }

    onSaveTemporalRule = (temporal) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createTemporalRule(selectedTerminalId, this.state.editingLane.id, temporal))
    }

    onSaveLockerRule = (lockerId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createLockerRule(selectedTerminalId, this.state.editingLane.id, lockerId))
    }

    getAllLockerStops = (routeId) => {
        const { dispatch } = this.props
        dispatch(getAllLockerStops(routeId))
    }

    onSaveLockerLinehaulRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createLockerLinehaulRule(selectedTerminalId, this.state.editingLane.id, terminalId))
    }

    onSaveLockerStopRule = (lockerStopId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createLockerStopRule(selectedTerminalId, this.state.editingLane.id, lockerStopId))
    }

    onSaveMerchantPriorityRule = (merchantId, merchantGroupId, exclusivePriority) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(
            createMerchantPriorityRule(
                selectedTerminalId,
                this.state.editingLane.id,
                merchantId,
                merchantGroupId,
                exclusivePriority,
            ),
        )
    }

    onSaveMultipleLockerStopRules = (lockerStopIdCollection) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(
            createRules(
                selectedTerminalId,
                this.state.editingLane.id,
                lockerStopIdCollection.map((lockerStopId) => ({
                    terminalSortingLaneId: this.state.editingLane.id,
                    type: 'LOCKER_STOP',
                    attributes: { lockerStopId },
                })),
            ),
        )
    }

    onSaveRouteRule = (laneRoute) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createRouteRule(selectedTerminalId, this.state.editingLane.id, laneRoute.value))
    }

    onSaveOnDemandRouteRule = (laneRoute) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createOnDemandRouteRule(selectedTerminalId, this.state.editingLane.id, laneRoute.value))
    }

    onSaveTerminalRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createTerminalRule(selectedTerminalId, this.state.editingLane.id, terminalId))
    }

    onSaveReturnLinehaulRule = (terminalId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createReturnLinehaulRule(selectedTerminalId, this.state.editingLane.id, terminalId))
    }

    onSaveReadFailRule = () => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createReadFailRule(selectedTerminalId, this.state.editingLane.id))
    }

    onSaveReturnToMerchantRule = (merchantId, merchantGroupId) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createReturnToMerchantRule(selectedTerminalId, this.state.editingLane.id, merchantId, merchantGroupId))
    }

    onSaveLinehaulHubOrRegionRule = (groupWarehouseId, groupWarehouseType) => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(
            createLinehaulHubOrRegionRule(
                selectedTerminalId,
                this.state.editingLane.id,
                groupWarehouseId,
                groupWarehouseType,
            ),
        )
    }

    onSaveOversizeLockerParcelRule = () => {
        const { dispatch, selectedTerminalId } = this.props
        dispatch(createOversizeLockerParcelRule(selectedTerminalId, this.state.editingLane.id))
    }

    onHideMachineLaneModal = () => {
        this.setState({ editingLane: null })
        this.props.fetchData()
    }

    onLaneClick = (editingLane) => {
        this.setState({ editingLane })
    }

    handleSortingProfileSelect = (selectedSortingProfile, laneIds, merchantForOnDemnadRoutes) => {
        const { dispatch, terminal } = this.props
        if (selectedSortingProfile === getProfileKeyByName(SORTING_PROFILES.DISTRIBUTION_HOME)) {
            this.setState({ selectedSortingProfile })
            dispatch(assignRoutesToLanes(terminal.id))
        } else if (selectedSortingProfile === getProfileKeyByName(SORTING_PROFILES.ON_DEMAND_ROUTES)) {
            this.setState({ selectedSortingProfile })
            dispatch(assignOnDemandRoutesToLanes(terminal.id, laneIds, merchantForOnDemnadRoutes))
        } else {
            this.setState({ selectedSortingProfile, applyingProfile: true })
            dispatch(applySelectedSortingProfile(terminal.id, selectedSortingProfile)).then((response) => {
                this.props.fetchData().then(() => {
                    this.setState({ applyingProfile: false })
                    const message = response.messages && response.messages[0]
                    if (message) {
                        this.openModal('Attention', message, 'danger')
                    }
                })
            })
        }
    }

    handleSaveRuleChanges = () => {
        const { dispatch, terminal } = this.props
        const { selectedSortingProfile } = this.state
        dispatch(editSortingProfile(terminal.id, selectedSortingProfile))
    }

    onDeleteRule = (type, rule) => {
        const { dispatch, selectedTerminalId } = this.props

        switch (type) {
            case DISTRIBUTION_ROUTE:
                dispatch(deleteRouteRule(selectedTerminalId, rule.id))
                break
            case ON_DEMAND_ROUTE:
                dispatch(deleteOnDemandRouteRule(selectedTerminalId, rule.id))
                break
            case DATE:
                dispatch(deleteDateRule(selectedTerminalId, rule.id))
                break
            case TEMPORAL:
                dispatch(deleteTemporalRule(selectedTerminalId, rule.id))
                break
            case LINEHAUL:
                dispatch(deleteTerminalRule(selectedTerminalId, rule.id))
                break
            case RETURN_LINEHAUL:
                dispatch(deleteReturnLinehaulRule(selectedTerminalId, rule.id))
                break
            case OVERSIZE_LOCKER_PARCEL:
                dispatch(deleteOversizeLockerParcelRule(selectedTerminalId, rule.id))
                break
            case LOCKER:
                dispatch(deleteLockerRule(selectedTerminalId, rule.id))
                break
            case LOCKER_LINEHAUL:
                dispatch(deleteLockerLinehaulRule(selectedTerminalId, rule.id))
                break
            case LOCKER_STOP:
                dispatch(deleteLockerStopRule(selectedTerminalId, rule.id))
                break
            case MERCHANT_PRIORITY:
                dispatch(deleteMerchantPriorityRule(selectedTerminalId, rule.id))
                break
            case READ_FAIL:
                dispatch(deleteReadFailRule(selectedTerminalId, rule.id))
                break
            case RETURN_TO_MERCHANT:
                dispatch(deleteReturnToMerchantRule(selectedTerminalId, rule.id))
                break
            case LINEHAUL_HUB_OR_REGION:
                dispatch(deleteLinehaulHubOrRegionRule(selectedTerminalId, rule.id))
                break
            default:
                throw new Error(`No handler for rule type '${type}'.`)
        }
    }

    openModal = (title, message, bsStyle) => {
        this.setState({ showMessage: true, title, message, bsStyle })
    }

    closeModal = () => {
        this.setState({ showMessage: false })
    }

    renderSortingMachine = () => {
        const { terminalLanes } = this.props
        const { applyingProfile } = this.state
        if (applyingProfile || !terminalLanes) {
            return <Loader color="#003085" />
        }

        return (
            <div>
                <SortingProgress
                    selectedTerminalId={this.props.selectedTerminalId}
                    nonVerifiedParcels={this.props.nonVerifiedParcels}
                />
                <SortingMachineLaneConfigModal
                    onSaveTerminalRule={this.onSaveTerminalRule}
                    onSaveReturnLinehaulRule={this.onSaveReturnLinehaulRule}
                    onSaveOnDemandRouteRule={this.onSaveOnDemandRouteRule}
                    onSaveRouteRule={this.onSaveRouteRule}
                    onSaveDateRule={this.onSaveDateRule}
                    onSaveTemporalRule={this.onSaveTemporalRule}
                    onSaveOversizeLockerParcelRule={this.onSaveOversizeLockerParcelRule}
                    onSaveLockerRule={this.onSaveLockerRule}
                    onSaveLockerLinehaulRule={this.onSaveLockerLinehaulRule}
                    onSaveLockerStopRule={this.onSaveLockerStopRule}
                    onSaveMerchantPriorityRule={this.onSaveMerchantPriorityRule}
                    onSaveMultipleLockerStopRules={this.onSaveMultipleLockerStopRules}
                    onSaveReadFailRule={this.onSaveReadFailRule}
                    onSaveReturnToMerchantRule={this.onSaveReturnToMerchantRule}
                    onSaveLinehaulHubOrRegionRule={this.onSaveLinehaulHubOrRegionRule}
                    onDeleteRule={this.onDeleteRule}
                    onHide={this.onHideMachineLaneModal}
                    getAllLockerStops={this.getAllLockerStops}
                    show={this.state.editingLane != null}
                    routes={this.props.routes}
                    onDemandRoutes={this.props.onDemandRoutes}
                    routeRules={this.props.routeRules}
                    onDemandRouteRules={this.props.onDemandRouteRules}
                    lockers={this.props.lockers}
                    lockerRules={this.props.lockerRules}
                    lockerStops={this.props.lockerStops}
                    lockerRoutes={this.props.lockerRoutes}
                    rules={this.props.rules}
                    hubPallets={this.props.hubPallets}
                    terminalId={this.props.selectedTerminalId}
                    editingLane={this.state.editingLane}
                    routesloaded={this.props.routesloaded}
                    canEditLaneEnablement
                />
                <SortingMachine
                    terminalLanes={this.props.terminalLanes}
                    terminal={this.props.terminal}
                    rules={this.props.rules}
                    routeRules={this.props.routeRules}
                    routeSortingStatuses={this.props.routeSortingStatuses}
                    onClick={this.props.canEditRules ? this.onLaneClick : undefined}
                />
            </div>
        )
    }

    render = () => {
        const { selectedTerminalId } = this.props
        const { selectedSortingProfile, applyingProfile } = this.state
        return (
            <div>
                {this.props.canEditRules && (
                    <TerminalSettings
                        selectedTerminalId={selectedTerminalId}
                        selectedSortingProfile={selectedSortingProfile}
                        handleSortingProfileSelect={this.handleSortingProfileSelect}
                        handleSaveRuleChanges={this.handleSaveRuleChanges}
                        applyingProfile={applyingProfile}
                    />
                )}
                {this.renderSortingMachine()}
                <MessageModal
                    show={this.state.showMessage}
                    closeModal={this.closeModal}
                    message={this.state.message}
                    bsStyle={this.state.bsStyle}
                    title={this.state.title}
                    centered
                />
            </div>
        )
    }
}

SettingsTab.propTypes = {
    fetchData: PropTypes.func.isRequired,
    selectedTerminalId: PropTypes.number.isRequired,
    canEditRules: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    terminal: PropTypes.object.isRequired,
    terminalLanes: PropTypes.array,
    rules: PropTypes.array,
    routeRules: PropTypes.array.isRequired,
    onDemandRouteRules: PropTypes.array.isRequired,
    lockerRules: PropTypes.array,
    lockerStops: PropTypes.array.isRequired,
    lockerRoutes: PropTypes.array,
    routeSortingStatuses: PropTypes.array.isRequired,
    routes: PropTypes.array,
    onDemandRoutes: PropTypes.array,
    routesloaded: PropTypes.bool,
    lockers: PropTypes.array,
    hubPallets: PropTypes.array.isRequired,
    nonVerifiedParcels: PropTypes.array,
}

const mapStateToProps = (
    {
        terminals: { terminals },
        terminalRules: {
            terminalLanes,
            rules,
            routeRules,
            lockerRules,
            lockerStops,
            lockerRoutes,
            routeSortingStatuses,
            routes,
            routesloaded,
            lockers,
            hubPallets,
            nonVerifiedParcels,
            onDemandRouteRules,
            onDemandRoutes,
        },
    },
    { selectedTerminalId },
) => ({
    terminal: terminals.find(({ id }) => id === selectedTerminalId),
    terminalLanes,
    rules,
    routeRules,
    lockerRules,
    lockerStops,
    lockerRoutes,
    routeSortingStatuses,
    routes,
    routesloaded,
    lockers,
    hubPallets,
    nonVerifiedParcels,
    onDemandRouteRules,
    onDemandRoutes,
})

export default connect(mapStateToProps)(SettingsTab)
