import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap'
import Select from 'react-select'
import Loader from 'react-loader'
import AlertGroup from '../../common/alert-group'
import { handleError } from '../../../utils/handle-error'
import { createDriverFeedback } from '../../../utils/drivers-webapi'
import auth from '../../../auth'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../../utils/role'

class CreateDriverFeedback extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryId: '-1',
            issueTypeId: '-1',
            comment: '',
            issueTypeOptions: [],
            isSaving: false,
            error: '',
        }
    }

    submit = () => {
        this.setState({ isSaving: true })
        const { order, deliveryAttempt, deliveryInformation } = this.props
        const driverId = deliveryInformation.find((x) => x.consignmentId === deliveryAttempt.id).driversId

        const payload = {
            orderId: order.id,
            consignmentId: deliveryAttempt != null ? deliveryAttempt.id : null,
            categoryId: this.state.categoryId,
            issueTypeId: this.state.issueTypeId,
            comment: this.state.comment,
        }
        createDriverFeedback(driverId, payload)
            .then((response) => {
                if (response.status === 'FAILED') {
                    this.setState({
                        error: response.messages.length > 0 ? response.messages[0] : 'Unable to create feedback',
                        isSaving: false,
                    })
                    return
                }
                setTimeout(() => {
                    this.props.onSuccess()
                    this.setState({ categoryId: '-1', issueTypeId: '-1', comment: '', isSaving: false })
                }, 1000)
            })
            .catch((e) => {
                this.setState({ isSaving: false })
                handleError(e)
            })
    }

    onCategoryChange = (e) => {
        this.setState({
            categoryId: e.value,
            issueTypeOptions: e.data.map((item) => ({ value: item.id, label: item.issueType })),
        })
    }

    onIssueTypeChange = (e) => {
        this.setState({
            issueTypeId: e.value,
        })
    }

    onCommentChange = (e) => {
        this.setState({
            comment: e.target.value,
        })
    }

    hide = () => {
        this.setState({ categoryId: '-1', issueTypeId: '-1', comment: '' })
        this.props.onClose()
    }

    render() {
        const { show, driverFeedbackOptions } = this.props
        const { categoryId, issueTypeId, comment, isSaving, error } = this.state
        const valid = categoryId && categoryId !== '-1' && issueTypeId && issueTypeId !== '-1'

        if (!auth.hasAnyRole(TRAFFIC_CONTROLLER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)) {
            return null
        }

        return (
            <Modal show={show} onHide={this.hide}>
                <Loader color="#BFBFBF" loaded={!isSaving} />
                <Modal.Header closeButton>
                    <Modal.Title>Create driver Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <ControlLabel>
                                Category<span style={{ color: 'red' }}> *</span>
                            </ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={driverFeedbackOptions.map((item) => ({
                                    value: item.id,
                                    label: item.category,
                                    data: item.issueTypes,
                                }))}
                                value={categoryId}
                                onChange={this.onCategoryChange}
                                placeholder="Choose category"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>
                                Issue Type<span style={{ color: 'red' }}> *</span>
                            </ControlLabel>
                            <Select
                                autoBlur
                                allowCreate={false}
                                backspaceRemoves={false}
                                clearable={false}
                                ignoreCase
                                searchable={false}
                                isLoading={false}
                                options={this.state.issueTypeOptions}
                                value={issueTypeId}
                                onChange={this.onIssueTypeChange}
                                placeholder="Choose issue type"
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Comments</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                rows="3"
                                placeholder="Additional comments..."
                                value={comment}
                                onChange={this.onCommentChange}
                            />
                        </FormGroup>
                        {error ? <AlertGroup statusCode={400} message={error} /> : null}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.hide}>Close</Button>
                    <Button disabled={!valid || isSaving} onClick={this.submit}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

CreateDriverFeedback.propTypes = {
    show: PropTypes.bool,
    deliveryInformation: PropTypes.array.isRequired,
    order: PropTypes.object.isRequired,
    deliveryAttempt: PropTypes.object,
    onClose: PropTypes.func,
    driverFeedbackOptions: PropTypes.array,
    onSuccess: PropTypes.func.isRequired,
}

export default CreateDriverFeedback
