import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('trafiki')

export const getDriverFeedbackOptions = () =>
    webapi()
        .path('driver-feedback-options')
        .get()
