import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Table } from 'react-bootstrap'
import moment from 'moment'

const tableHeadingKeys = ['Publish Time', 'Affiliate Partner', 'Description', 'Country']

const CouponsList = (props) => {
    const { couponsList } = props

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {tableHeadingKeys.map((heading) => {
                            return <th key={heading}>{heading}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {couponsList.map((coupon) => {
                        return (
                            <tr key={coupon.id}>
                                <td>{coupon.publishTime && moment(coupon.publishTime).format('YYYY-MM-DD')}</td>
                                <td>{coupon.affiliatePartner}</td>
                                <td>{coupon.description}</td>
                                <td>{coupon.country}</td>
                                <td>
                                    <Button
                                        bsStyle="primary"
                                        bsSize="xsmall"
                                        onClick={() => {
                                            props.editCoupon(coupon)
                                        }}
                                    >
                                        EDIT
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

CouponsList.propTypes = {
    couponsList: PropTypes.array.isRequired,
    editCoupon: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
})

export default connect(mapStateToProps)(CouponsList)
