import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, FormControl, Form, FormGroup, ControlLabel } from 'react-bootstrap'

/**
 * Render an End Customer
 */
export default class EndCustomer extends Component {
    static propTypes = {
        editable: PropTypes.bool.isRequired,
        endCustomer: PropTypes.object.isRequired,
        onFormChange: PropTypes.func.isRequired,
        onEndCustomerSave: PropTypes.func.isRequired,
    }

    state = {
        editing: false,
    }

    onFormChange = (e) => {
        this.props.onFormChange(e.target.name, e.target.value)
    }

    cancelEditing = () => {
        this.setState({ editing: false })
    }

    toggleEdit = () => {
        if (this.state.editing) {
            this.props.onEndCustomerSave()
        }
        const editing = !this.state.editing
        this.setState({ editing })
    }

    value(o) {
        return o || <span className="text-muted">N/A</span>
    }

    renderEditInfo() {
        const { endCustomer } = this.props
        return (
            <Form onChange={this.onFormChange}>
                <FormGroup controlId="form">
                    <ControlLabel>Name</ControlLabel>
                    <FormControl name="name" defaultValue={endCustomer.name} type="text" placeholder="Name" />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Reference person</ControlLabel>
                    <FormControl
                        name="referencePerson"
                        defaultValue={endCustomer.referencePerson}
                        type="text"
                        placeholder="Reference Person"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Social Security Number</ControlLabel>
                    <FormControl
                        name="socialSecurityNumber"
                        defaultValue={endCustomer.socialSecurityNumber}
                        type="text"
                        placeholder="Social Security Number"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Telephone number</ControlLabel>
                    <FormControl
                        name="phoneNumber"
                        defaultValue={endCustomer.phoneNumber}
                        type="tel"
                        placeholder="Telephone Number"
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Email</ControlLabel>
                    <FormControl name="email" defaultValue={endCustomer.email} type="email" placeholder="Email" />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Additional Info</ControlLabel>
                    <FormControl
                        name="comment"
                        defaultValue={endCustomer.comment}
                        type="text"
                        placeholder="Additional info"
                    />
                </FormGroup>
            </Form>
        )
    }

    renderInfo() {
        const { endCustomer } = this.props
        return (
            <div>
                <div>
                    <label className="strong-label">Name</label>
                    {this.value(endCustomer.name)}
                </div>
                <div>
                    <label className="strong-label">Reference Person</label>
                    {this.value(endCustomer.referencePerson)}
                </div>
                <div>
                    <label className="strong-label">Social Security Number</label>
                    {this.value(endCustomer.socialSecurityNumber)}
                </div>
                <div>
                    <label className="strong-label">Telephone Number</label>
                    <a href={`tel:${endCustomer.phoneNumber}`}>{this.value(endCustomer.phoneNumber)}</a>
                </div>
                <div>
                    <label className="strong-label">Email</label>
                    <a href={`mailto:${endCustomer.email}`}>{this.value(endCustomer.email)}</a>
                </div>
                <div>
                    <label className="strong-label">Additional Info</label>
                    {this.value(endCustomer.comment)}
                </div>
            </div>
        )
    }

    renderInformation() {
        return (
            <Row>
                <Col md={8}>{this.state.editing ? this.renderEditInfo() : this.renderInfo()}</Col>
            </Row>
        )
    }

    renderEditButton() {
        if (!this.props.editable) {
            return null
        }

        return (
            <Row style={{ marginTop: '5px' }}>
                <Col md={6}>
                    <Button bsSize="small" onClick={this.toggleEdit}>
                        {this.state.editing ? 'Done' : 'Edit'}
                    </Button>
                    {this.state.editing && (
                        <Button bsSize="small" onClick={this.cancelEditing}>
                            Cancel
                        </Button>
                    )}
                </Col>
            </Row>
        )
    }

    render() {
        return (
            <div>
                {this.renderInformation()}
                {this.renderEditButton()}
            </div>
        )
    }
}
