import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Alert } from 'react-bootstrap/lib'

const MessageModal = (props) => {
    return (
        <Modal show={props.show} container={this}>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert bsStyle={props.bsStyle}>{props.message}</Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

MessageModal.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func,
    message: PropTypes.string,
    bsStyle: PropTypes.string,
    title: PropTypes.string,
}
export default MessageModal
