const textTranslation = {
    time_of_arrival: 'Leveranstiden',
    driver_service: 'Chauffören',
    support_service: 'Kundtjänsten',
    application: 'Appen',
    product_handling: 'Hanteringen av mina varor',
    other: 'Annat',

    /**
     * @deprecated since version 2.0
     */
    non_delivery: 'Utebliven leverans',
    no_notifications: 'Inga aviseringar',
    product_defects: 'Produktfel',
    service: 'Service',
}

const mapType = {
    toText(type) {
        type = type.toLowerCase()
        if (textTranslation[type] != null) {
            return textTranslation[type]
        }

        return type
    },
}

export default mapType
