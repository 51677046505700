import React from 'react'
import Select from 'react-select'
import { Checkbox, FormControl, Row, Col } from 'react-bootstrap'
import cx from 'classnames'
import { isExcludedOrderSetting, enumSettingsMap } from './order-settings'

export default class Settings {
    constructor(props, settingTranslations) {
        this.props = props
        this.settingTranslations = settingTranslations
    }

    getLabel = (value) => {
        let text = null
        const classes = { label: true }

        switch (typeof value) {
            case 'boolean': {
                text = value ? 'Yes' : 'No'
                classes['label-danger'] = !value
                classes['label-success'] = value
                break
            }
            case 'number': {
                classes['label-default'] = true
                text = value
                break
            }
            case 'string': {
                classes['label-default'] = true
                text = value
                break
            }
            default:
                break
        }

        return <span className={cx(classes)}>{text}</span>
    }

    handleNumericChange = (event, setting) => {
        if (setting !== 'recycling' && setting !== 'swap') {
            this.props.onUpdateSetting(false, 'outsideDoorOk')
        }
        let { value } = event.target

        if (setting === 'recipientMinimumAge' && value > 0) {
            this.props.onUpdateSetting('IN_PERSON', 'deliveryMethod')
        }

        if (value === '' || value < 0 || Number.isNaN(value)) {
            value = 0
        }
        value = Number.parseInt(value, 10)
        this.props.onUpdateSetting(value, setting)
    }

    handleSelectChange = (value, setting) => {
        switch (value) {
            case 'LEAVE_BY_DOOR': {
                this.props.onUpdateSetting(false, 'onlyDeliverInPerson')
                this.props.onUpdateSetting(false, 'identificationCheckRequired')
                this.props.onUpdateSetting(false, 'signatureRequired')
                this.props.onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                this.props.onUpdateSetting(false, 'recipientMustMatchEndCustomer')
                this.props.onUpdateSetting(true, 'outsideDoorOk')
                break
            }
            case 'IN_PERSON': {
                this.props.onUpdateSetting(false, 'outsideDoorOk')
                this.props.onUpdateSetting(false, 'leaveWithNeighbour')
                this.props.onUpdateSetting(false, 'alternativelyLeaveWithNeighbour')
                break
            }
            case 'KNOCK_ON_DOOR': {
                this.props.onUpdateSetting(true, 'knockOnDoor')
                this.props.onUpdateSetting(false, 'ringDoorbell')
                this.props.onUpdateSetting(false, 'callConsumer')
                this.props.onUpdateSetting(false, 'noNotification')
                break
            }
            case 'RING_DOOR_BELL': {
                this.props.onUpdateSetting(false, 'knockOnDoor')
                this.props.onUpdateSetting(true, 'ringDoorbell')
                this.props.onUpdateSetting(false, 'callConsumer')
                this.props.onUpdateSetting(false, 'noNotification')
                break
            }
            case 'CALL_CUSTOMER': {
                this.props.onUpdateSetting(false, 'knockOnDoor')
                this.props.onUpdateSetting(false, 'ringDoorbell')
                this.props.onUpdateSetting(true, 'callConsumer')
                this.props.onUpdateSetting(false, 'noNotification')
                break
            }
            case 'NO_NOTIFICATION': {
                this.props.onUpdateSetting(false, 'knockOnDoor')
                this.props.onUpdateSetting(false, 'ringDoorbell')
                this.props.onUpdateSetting(false, 'callConsumer')
                this.props.onUpdateSetting(true, 'noNotification')
                break
            }
            default:
                break
        }
        this.props.onUpdateSetting(value, setting)
    }

    anyNumericValuesFilledIn = () => {
        const { settings } = this.props
        return Object.keys(settings)
            .filter((key) => key !== 'numberOfMissRetries')
            .some((key) => !Number.isNaN(settings[key]) && typeof settings[key] !== 'boolean' && settings[key] > 0)
    }

    isEnum = (setting) => {
        return setting === 'deliveryMethod' || setting === 'deliveryNotificationMethod'
    }

    disablesToggle = (setting) => {
        const disableWhenOutsideDoorOkAndNumericValuesFilledIn =
            setting === 'outsideDoorOk' && this.anyNumericValuesFilledIn()
        const { identificationCheckRequired, recipientMinimumAge, recipientMustMatchEndCustomer } = this.props.settings

        if (setting === 'alternativelyLeaveWithNeighbour' || setting === 'leaveWithNeighbour') {
            return (
                disableWhenOutsideDoorOkAndNumericValuesFilledIn ||
                (identificationCheckRequired && recipientMinimumAge > 0) ||
                (identificationCheckRequired && recipientMustMatchEndCustomer)
            )
        }

        return disableWhenOutsideDoorOkAndNumericValuesFilledIn
    }

    renderSwitch = (settingvalue, setting, onChangeCallback) => {
        const onChange = onChangeCallback || (() => null)
        if (typeof settingvalue === 'boolean') {
            return (
                <Checkbox
                    style={{ margin: 0 }}
                    checked={settingvalue}
                    disabled={this.disablesToggle(setting)}
                    onClick={() => this.handleToggle(!settingvalue, setting)}
                    onChange={onChange}
                />
            )
        }

        if (this.isEnum(setting)) {
            const optionsObject = enumSettingsMap[setting]

            const options = Object.keys(optionsObject).map((key) => ({
                value: key,
                label: optionsObject[key],
            }))

            return (
                <Select
                    multi={false}
                    searchable={false}
                    allowCreate={false}
                    options={options}
                    value={settingvalue}
                    onChange={(option) => this.handleSelectChange(option.value, setting)}
                />
            )
        }
        return (
            <div style={{ width: 48 }}>
                <FormControl
                    type="text"
                    value={settingvalue}
                    onChange={(event) => this.handleNumericChange(event, setting)}
                />
            </div>
        )
    }

    renderSetting = (setting, key, isEditing, handleToggle, onChangeCallback = null) => {
        const { settings, countryCode } = this.props
        const title = this.settingTranslations[setting]
        const label = this.getLabel(settings[setting])

        this.handleToggle = handleToggle
        if (countryCode && isExcludedOrderSetting(countryCode, setting)) {
            return null
        }

        if (!title) {
            return null
        }

        if (isEditing) {
            if (this.isEnum(setting)) {
                return (
                    <Row style={{ marginBottom: '5px' }} key={key}>
                        <Col md={6}>{this.settingTranslations[setting]}</Col>
                        <Col md={6}>{this.renderSwitch(settings[setting], setting, onChangeCallback)}</Col>
                    </Row>
                )
            }

            return (
                <tr key={key}>
                    <td style={{ border: 'none' }}>{this.settingTranslations[setting]}</td>
                    <td style={{ border: 'none' }}>
                        {this.renderSwitch(settings[setting], setting, onChangeCallback)}
                    </td>
                </tr>
            )
        }
        if (this.isEnum(setting)) {
            return (
                <Row style={{ marginBottom: '5px' }} key={key}>
                    <Col md={6}>{this.settingTranslations[setting]}</Col>
                    <Col md={6}>{label}</Col>
                </Row>
            )
        }
        return (
            <tr key={key}>
                <td style={{ border: 'none' }}>{this.settingTranslations[setting]}</td>
                <td style={{ border: 'none' }}>{label}</td>
            </tr>
        )
    }
}
