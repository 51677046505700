import {
    ASSIGN_ROUTES_TO_LANES_FAILED,
    ASSIGNED_ROUTES_TO_LANES,
    ASSIGNING_ROUTES_TO_LANES,
    ALL_RULES_LOADED,
    DELETE_ALL_RULES_FROM_LANES_FAILED,
    DELETED_ALL_RULES_FROM_LANES,
    DELETING_ALL_RULES_FROM_LANES,
    DELETE_ALL_RULES_FROM_PROFILE_FAILED,
    DELETED_ALL_RULES_FROM_PROFILE,
    DELETING_ALL_RULES_FROM_PROFILE,
    LOCKER_STOPS_LOADED,
    LOCKER_ROUTES_LOADED,
    LOCKER_RULES_LOADED,
    ROUTE_RULES_LOADED,
    RULES_LOADED,
    RULES_LOADING,
    SORTING_PROFILES_LOADED,
    BOOK_DELIVERY_LOADED,
    BOOK_DELIVERY_FAILED,
    RULES_RESET,
    EDITING_PROFILE,
    EDITED_PROFILE,
    ASSIGN_ON_DEMAND_ROUTES_TO_LANES_FAILED,
    ASSIGNED_ON_DEMAND_ROUTES_TO_LANES,
    ASSIGNING_ON_DEMAND_ROUTES_TO_LANES,
} from '../constants/actionTypes'
import dataState from './initialState/dataState'

export default function TerminalRuleReducer(state = dataState, action) {
    switch (action.type) {
        case EDITING_PROFILE:
            return {
                ...state,
                editingProfile: true,
            }
        case EDITED_PROFILE: {
            return {
                ...state,
                editingProfile: false,
            }
        }
        case RULES_LOADED:
            return {
                ...state,
                rules: action.rules,
                terminalLanes: action.terminalLanes,
                routeRules: action.routeRules,
                lockerRules: action.lockerRules,
                lockerRoutes: action.lockerRoutes,
                sortingProfiles: action.sortingProfiles,
                routeSortingStatuses: action.routeSortingStatuses,
                nonVerifiedParcels: action.nonVerifiedParcels,
                routes: action.routes,
                routesloaded: true,
                lockers: action.lockers,
                hubPallets: action.hubPallets,
                onDemandRoutes: action.onDemandRoutes,
            }

        case RULES_RESET:
            return dataState

        case RULES_LOADING:
            return { ...state, routesloaded: false }

        case ALL_RULES_LOADED:
            return { ...state, rules: action.response }

        case ROUTE_RULES_LOADED:
            return {
                ...state,
                routeRules: action.rules,
                routes: action.routes,
                routesloaded: true,
            }

        case LOCKER_STOPS_LOADED:
            return { ...state, lockerStops: action.response }

        case LOCKER_ROUTES_LOADED:
            return { ...state, lockerRoutes: action.response }

        case LOCKER_RULES_LOADED:
            return { ...state, lockerRules: action.response }

        case SORTING_PROFILES_LOADED:
            return { ...state, sortingProfiles: action.response }

        case ASSIGNING_ROUTES_TO_LANES:
            return { ...state, assigningRoutesToLanes: true, assignRoutesToLanesError: null }

        case ASSIGNED_ROUTES_TO_LANES:
            return { ...state, assigningRoutesToLanes: false, assignRoutesToLanesError: null }

        case ASSIGN_ROUTES_TO_LANES_FAILED:
            return {
                ...state,
                assigningRoutesToLanes: false,
                assignRoutesToLanesError: 'Could not assign routes to lanes',
            }
        case DELETING_ALL_RULES_FROM_LANES:
            return {
                ...state,
                deletingAllRulesFromLanes: true,
                deletingAllRulesFromLanesError: null,
            }
        case DELETED_ALL_RULES_FROM_LANES:
            return {
                ...state,
                deletingAllRulesFromLanes: false,
                deletingAllRulesFromLanesError: null,
            }
        case DELETE_ALL_RULES_FROM_LANES_FAILED:
            return {
                ...state,
                deletingAllRulesFromLanes: false,
                deletingAllRulesFromLanesError: 'Could not remove all rules from lanes',
            }
        case DELETING_ALL_RULES_FROM_PROFILE:
            return {
                ...state,
                deletingAllRulesFromProfile: true,
                deletingAllRulesFromProfileError: null,
            }
        case DELETED_ALL_RULES_FROM_PROFILE:
            return {
                ...state,
                deletingAllRulesFromProfile: false,
                deletingAllRulesFromProfileError: null,
            }
        case DELETE_ALL_RULES_FROM_PROFILE_FAILED:
            return {
                ...state,
                deletingAllRulesFromProfile: false,
                deletingAllRulesFromProfileError: 'Could not remove all rules from profile',
            }
        case BOOK_DELIVERY_LOADED:
            return { ...state, bookDelivery: action.response }
        case BOOK_DELIVERY_FAILED:
            return { ...state, bookDelivery: null }

        case ASSIGNING_ON_DEMAND_ROUTES_TO_LANES:
            return { ...state, assigningOnDemandRoutesToLanes: true, assignOnDemandRoutesToLanesError: null }

        case ASSIGNED_ON_DEMAND_ROUTES_TO_LANES:
            return { ...state, assigningOnDemandRoutesToLanes: false, assignOnDemandRoutesToLanesError: null }

        case ASSIGN_ON_DEMAND_ROUTES_TO_LANES_FAILED:
            return {
                ...state,
                assigningOnDemandRoutesToLanes: false,
                assignOnDemandRoutesToLanesError: 'Could not assign on-demand routes to lanes',
            }

        default:
            return state
    }
}
