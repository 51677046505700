import { FETCHING_TAGS, TAGS_LOADED, TAGS_FAILED_TO_LOAD } from '../constants/actionTypes'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'
import { getAllTags } from '../utils/tag-webapi'

const fetchingTags = () => ({
    type: FETCHING_TAGS,
})

const tagsLoaded = (response) => ({
    type: TAGS_LOADED,
    tags: response,
})

const tagsFailedToLoad = (e) => ({
    type: TAGS_FAILED_TO_LOAD,
    status: e.status,
    error: e.statusText,
})

export const fetchTags = () => (dispatch) => {
    dispatch(fetchingTags())
    return getAllTags()
        .then(compose(dispatch, tagsLoaded))
        .catch((error) => {
            compose(dispatch, tagsFailedToLoad, handleError)(error)
            throw error
        })
}
