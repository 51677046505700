import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader'
import {
    Grid,
    Row,
    Col,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Badge,
    Button,
    DropdownButton,
    MenuItem,
    Table,
} from 'react-bootstrap'
import { DateRangePicker } from 'react-dates'
import { connect } from 'react-redux'
import moment from 'moment'
import HtmlTitle from './html-title'
import Alert from './alert'
import RouteModal from './routes/route-modal'
import { fetchMerchants } from '../actions/merchants'
import { fetchOwnersWebapi } from '../actions/owner'
import { getCourierUsers } from '../actions/user'
import { search } from '../actions/route-search'
import auth from '../auth'
import { curry } from '../utils/curry'
import { queryString } from '../utils/query-string'
import { isEmpty } from '../utils/is-empty'
import { displayName } from '../utils/display-name'
import routeTypes, { DISTRIBUTION } from '../utils/route-types'
import { mapSort, sortStrings } from '../utils/sorting'
import { prop } from '../utils/prop'
import {
    canFetchCouriers,
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    COURIER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TERMINAL_WORKER,
    TRAFFIC_CONTROLLER,
} from '../utils/role'
import TerminalSelect from './common/terminal-select'

const routeTypeOptions = routeTypes()
const MILLIS_IN_DAY = 24 * 60 * 60 * 1000
const MAX_SEARCH_RANGE = MILLIS_IN_DAY * 31
const DATE_FORMAT = 'YYYY-MM-DD'

class RouteSearchHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment().subtract(1, 'weeks'),
            endDate: moment(),
            courier: '-1',
            driver: '-1',
            merchant: '-1',
            terminal: '',
            routeType: DISTRIBUTION,
            completed: '-1',
            selectedRouteId: null,
            focusedInput: null,
        }
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
            COURIER,
        )
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        const canFetchMerchants = auth.hasAnyRole(
            OPERATIONS_ADMIN,
            OPERATIONS_COORDINATOR,
            TRAFFIC_CONTROLLER,
            TERMINAL_ADMIN,
            TERMINAL_WORKER,
            COMMERCIAL_MANAGER,
            COMMERCIAL_USER,
        )
        if (canFetchMerchants) {
            this.props.actions.fetchMerchants()
        }
        if (canFetchCouriers) {
            this.props.actions.fetchOwners()
        } else {
            this.props.actions.getCourierUsers()
        }

        const { query } = this.props.location
        if (!isEmpty(query)) {
            const newState = {}
            if (query.start != null && query.end != null) {
                newState.startDate = moment(query.start, DATE_FORMAT)
                newState.endDate = moment(query.end, DATE_FORMAT)
            }
            if (query.courier != null) {
                newState.courier = query.courier
                this.props.actions.getCourierUsers(newState.courier)
            }
            if (query.driver != null) {
                newState.driver = query.driver
            }
            if (query.merchant != null) {
                newState.merchant = query.merchant
            }
            if (query.terminal != null) {
                newState.terminal = query.terminal
            }
            if (query.routeType != null) {
                newState.routeType = query.routeType
            }
            if (query.completed != null) {
                newState.completed = query.completed
            }

            this.setState(newState, this.search)
        }
    }

    onSelectChange = curry((p, e) => this.setState({ [p]: e.target.value }))

    onCourierChange = (e) => {
        this.onSelectChange('courier', e)
        this.setState({ driver: '-1' })

        if (e.target.value !== '-1') {
            this.props.actions.getCourierUsers(e.target.value)
        }
    }

    onDateChange = ({ startDate, endDate }) => {
        if (endDate == null || endDate.valueOf() - startDate.valueOf() < MAX_SEARCH_RANGE) {
            this.setState({
                startDate,
                endDate,
                errorMsg: null,
            })
        } else {
            this.setState({
                startDate,
                endDate,
                errorMsg: `Select a range of ${MAX_SEARCH_RANGE / MILLIS_IN_DAY} days or less`,
            })
        }
    }

    onFocusedInputChange = (focusedInput) => this.setState({ focusedInput })

    syncUrl = () => {
        const { startDate, endDate, courier, driver, merchant, terminal, routeType, completed } = this.state
        const q = queryString([
            ['start', startDate.format(DATE_FORMAT)],
            ['end', endDate != null ? endDate.format(DATE_FORMAT) : startDate.format(DATE_FORMAT)],
            ['courier', courier],
            ['driver', driver],
            ['merchant', merchant],
            ['terminalCode', terminal],
            ['routeType', routeType],
            ['completed', completed],
        ])

        this.props.router.push(`/admin/route-search?${q}`)
    }

    toggleRouteModal = curry((routeId, e) => {
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ selectedRouteId: routeId })
    })

    search = curry((e) => {
        if (e != null) {
            e.preventDefault()
        }

        this.syncUrl()

        const { startDate, endDate, courier, driver, merchant, terminal, routeType, completed } = this.state
        const model = {
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate != null ? endDate.format(DATE_FORMAT) : startDate.format(DATE_FORMAT),
            couriers: courier !== '-1' ? [courier] : null,
            drivers: driver !== '-1' ? [driver] : null,
            merchants: merchant !== '-1' ? [merchant] : null,
            routeType: routeType !== '' ? routeType : null,
            completed: completed !== '-1' ? completed === '1' : null,
            terminalCode: terminal !== '' ? terminal : null,
        }

        this.props.actions.search(model)
    })

    renderSearchForm() {
        return (
            <Form onSubmit={this.search}>
                <fieldset>
                    <Alert type="danger" message={this.state.errorMsg} />
                    <legend>Route search</legend>

                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                <ControlLabel htmlFor="routeDate">Date interval</ControlLabel>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    startDateId="startDate"
                                    endDate={this.state.endDate}
                                    endDateId="endDate"
                                    minimumNights={0}
                                    onDatesChange={this.onDateChange}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={this.onFocusedInputChange}
                                    displayFormat={DATE_FORMAT}
                                    firstDayOfWeek={1}
                                    isOutsideRange={() => false}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup controlId="courier">
                                <ControlLabel>Courier</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={this.state.courier}
                                    onChange={this.onCourierChange}
                                >
                                    <option value="-1">All</option>
                                    {this.props.couriers.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                            {name}
                                        </option>
                                    ))}
                                </FormControl>
                            </FormGroup>

                            <FormGroup controlId="driver">
                                <ControlLabel>Driver</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={this.state.driver}
                                    onChange={this.onSelectChange('driver')}
                                >
                                    <option value="-1">All</option>
                                    {this.props.drivers.map(({ id, firstName, lastName }) => (
                                        <option key={id} value={id}>
                                            {displayName(' ', firstName, lastName)}
                                        </option>
                                    ))}
                                </FormControl>
                            </FormGroup>

                            <FormGroup controlId="merchant">
                                <ControlLabel>Merchant</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={this.state.merchant}
                                    onChange={this.onSelectChange('merchant')}
                                >
                                    <option value="-1">All</option>
                                    {this.props.merchants
                                        .sort(mapSort(sortStrings, prop('externalName')))
                                        .map(({ id, externalName, name }) => (
                                            <option key={id} value={id}>
                                                {externalName === name ? externalName : `${externalName} (${name})`}
                                            </option>
                                        ))}
                                </FormControl>
                            </FormGroup>
                        </Col>

                        <Col sm={4}>
                            <FormGroup controlId="terminal">
                                <ControlLabel>Terminal</ControlLabel>
                                <TerminalSelect
                                    onSelect={(selected) => {
                                        this.setState({ terminal: selected ? selected.code : '' })
                                    }}
                                />
                            </FormGroup>

                            <FormGroup controlId="type">
                                <ControlLabel>Type</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={this.state.routeType}
                                    onChange={this.onSelectChange('routeType')}
                                >
                                    <option value="">All</option>
                                    {routeTypeOptions.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </FormControl>
                            </FormGroup>

                            <FormGroup controlId="completed">
                                <ControlLabel>Completed</ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    value={this.state.completed}
                                    onChange={this.onSelectChange('completed')}
                                >
                                    <option value="-1">All</option>
                                    <option value="1">Complete</option>
                                    <option value="0">Incomplete</option>
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Button type="submit" bsStyle="primary">
                            Search
                        </Button>
                    </FormGroup>
                </fieldset>
            </Form>
        )
    }

    renderRow = (row) => (
        <tr key={row.routeId}>
            <td>{row.routeId}</td>
            <td>{row.routeType}</td>
            <td>{row.routeTitle}</td>
            <td>{row.dueDate}</td>
            <td>{row.courierName}</td>
            <td>{displayName(' ', row.driverFirstName, row.driverLastName)}</td>
            <td>
                <DropdownButton id="route-search-dropdown" title="Actions">
                    <MenuItem onClick={this.toggleRouteModal(row.routeId)}>Show stops</MenuItem>
                    <MenuItem href={`/admin/routes/${row.routeId}/analysis/`} target="_blank">
                        View map <Badge>New</Badge>
                    </MenuItem>
                </DropdownButton>
            </td>
        </tr>
    )

    renderResults = () => {
        if (this.props.results == null) {
            return null
        }

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Route Name</th>
                        <th>Date</th>
                        <th>Courier</th>
                        <th>Driver</th>
                        <th />
                    </tr>
                </thead>
                <tbody>{this.props.results.map(this.renderRow)}</tbody>
            </Table>
        )
    }

    renderRouteModal = () => {
        if (this.state.selectedRouteId == null) {
            return null
        }

        return <RouteModal show onHide={this.toggleRouteModal(null)} routeId={this.state.selectedRouteId} />
    }

    render = () => (
        <Grid fluid componentClass="main">
            <HtmlTitle title="Search routes" />

            {this.renderSearchForm()}

            <Loader color="#bfbfbf" loaded={this.props.loaded}>
                {this.renderResults()}
                {this.renderRouteModal()}
            </Loader>
        </Grid>
    )
}

RouteSearchHandler.propTypes = {
    router: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    loaded: PropTypes.bool.isRequired,
    location: PropTypes.object,
    results: PropTypes.array,
    merchants: PropTypes.array,
    couriers: PropTypes.array,
    drivers: PropTypes.array,
}

const mapStateToProps = ({
    buyers: { loading: merchantsLoading, buyers: merchants },
    owners: { loading: couriersLoading, data: couriers },
    users: { loading: driversLoading, data: drivers },
    routeSearch: { loading: searchLoading, results },
}) => ({
    loaded: !merchantsLoading && !couriersLoading && !driversLoading && !searchLoading,
    merchants,
    couriers,
    drivers,
    results,
})
const mapDispatchToProps = () => (dispatch) => ({
    actions: {
        fetchMerchants: () => fetchMerchants()(dispatch),
        fetchOwners: () => fetchOwnersWebapi()(dispatch),
        getCourierUsers: (courierId) => getCourierUsers(courierId)(dispatch),
        search: (query) => search(query)(dispatch),
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(RouteSearchHandler)
