import React, { Component } from 'react'
import { Table, Button } from 'react-bootstrap/lib'
import DiscountRow from './discount-row'

export default class Discounts extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (!this.props.parcelDiscounts) {
            return null
        }
        return (
            <Table className="price-list-table striped bordered condensed hover">
                <thead>
                    <tr>
                        <th />
                        <th>Parcel Quantity</th>
                        <th>Discount (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.parcelDiscounts.map((discount, i) => (
                        <DiscountRow key={i} i={i + 1} discount={discount} deleteDiscount={this.props.deleteDiscount} />
                    ))}
                </tbody>
            </Table>
        )
    }
}
