import React, { Component } from 'react'
import { Row, Table, Button, FormControl, Navbar, Nav } from 'react-bootstrap/lib'

import CountrySelect from '../common/country-select'
import { geocode } from '../../utils/geocoding-webapi'

const newEntry = (key) => ({
    key,
    saved: false,
    streetName: '',
    houseNumber: '',
    postalCode: '',
    locality: '',
    countryCode: null,
    latitude: '',
    longitude: '',
})

const isValid = (entry, property) => {
    const value = entry[property]
    if (!value) {
        return false
    }
    if (typeof value === 'string') {
        return value.trim().length !== 0
    }
    return true
}

const inputCss = (entry, property) => {
    if (entry.saved) {
        return 'geocode-new-address-saved'
    }
    if (!isValid(entry, property)) {
        return 'geocode-new-address-property-missing'
    }
    return ''
}

const toCoordinate = (entry) => ({
    latitude: parseFloat(entry.latitude),
    longitude: parseFloat(entry.longitude),
})

const toRequestPayload = (entry) => ({
    street: `${entry.streetName} ${entry.houseNumber}`,
    postalCode: entry.postalCode,
    locality: entry.locality,
    country: entry.countryCode.value,
    verifiedStreetAddress: {
        streetName: entry.streetName,
        houseNumber: entry.houseNumber,
        originalAsVariation: true,
    },
    verifiedLocation: {
        coordinate: toCoordinate(entry),
        navigationCoordinate: toCoordinate(entry),
    },
})

const isGeocodable = (entry) =>
    isValid(entry, 'streetName') &&
    isValid(entry, 'houseNumber') &&
    isValid(entry, 'postalCode') &&
    isValid(entry, 'locality') &&
    isValid(entry, 'countryCode') &&
    isValid(entry, 'latitude') &&
    isValid(entry, 'longitude')

export default class GeocodeNewAddress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            entries: [],
        }
        this.renderForm = this.renderForm.bind(this)
        this.renderRows = this.renderRows.bind(this)
        this.onChange = this.onChange.bind(this)
        this.addEntry = this.addEntry.bind(this)
        this.save = this.save.bind(this)
        this.saveEntry = this.saveEntry.bind(this)
        this.markAsSaved = this.markAsSaved.bind(this)
    }

    onChange(key, property, value) {
        const { entries } = this.state
        const original = entries.find((entry) => entry.key === key)
        const updated = { ...original, ...{ [property]: value } }
        this.setState({ entries: [...entries.filter((entry) => entry.key !== key), updated] })
    }

    addEntry() {
        const { entries } = this.state
        this.setState({ entries: [...entries, newEntry(Date.now())] })
    }

    markAsSaved(savedEntry) {
        const { entries } = this.state
        const others = entries.filter((entry) => entry.key !== savedEntry.key)
        this.setState({ entries: [...others, { ...savedEntry, saved: true }] })
    }

    saveEntry(entry) {
        geocode(toRequestPayload(entry)).then(() => this.markAsSaved(entry))
    }

    save() {
        this.state.entries.filter((entry) => !entry.saved && isGeocodable(entry)).forEach(this.saveEntry)
    }

    renderForm() {
        return (
            <div>
                <Navbar fluid className="geocoder-navbar">
                    <Nav className="geocoder-new-address-nav">
                        <Button type="submit" onClick={this.addEntry}>
                            Add
                        </Button>
                    </Nav>
                    <Nav className="geocoder-new-address-nav" pullRight>
                        <Button type="submit" onClick={this.save}>
                            Save
                        </Button>
                    </Nav>
                </Navbar>
            </div>
        )
    }

    renderInput(entry, property) {
        return (
            <FormControl
                disabled={entry.saved}
                className={inputCss(entry, property)}
                type="text"
                onChange={(e) => this.onChange(entry.key, property, e.target.value)}
            />
        )
    }

    renderRows(entry) {
        return (
            <tr className="geocode-new-address-entry" key={entry.key}>
                <td>{this.renderInput(entry, 'streetName')}</td>
                <td>{this.renderInput(entry, 'houseNumber')}</td>
                <td>{this.renderInput(entry, 'postalCode')}</td>
                <td>{this.renderInput(entry, 'locality')}</td>
                <td className={inputCss(entry, 'countryCode')}>
                    <CountrySelect
                        onSelect={(country) => this.onChange(entry.key, 'countryCode', country)}
                        value={entry.countryCode}
                    />
                </td>
                <td>{this.renderInput(entry, 'latitude')}</td>
                <td>{this.renderInput(entry, 'longitude')}</td>
            </tr>
        )
    }

    renderTable() {
        const { entries } = this.state
        return (
            <Row className="geocode-new-address">
                <Table striped bordered className="geocode-new-address-table">
                    <thead>
                        <tr>
                            <th>Street Name</th>
                            <th className="geocode-new-address-house-number">House Number</th>
                            <th className="geocode-new-address-postal-code">PostalCode</th>
                            <th className="geocode-new-address-postal-locality">Locality</th>
                            <th>Country</th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                        </tr>
                    </thead>
                    <tbody>{entries.sort((a, b) => a.key - b.key).map(this.renderRows)}</tbody>
                </Table>
            </Row>
        )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderTable()}
            </div>
        )
    }
}
