import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    ButtonGroup,
    Checkbox,
    Col,
    ControlLabel,
    Form,
    FormGroup,
    Glyphicon,
    Panel,
    Row,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import TerminalSettingsModal from './terminal-settings-modal'
import Confirm from '../../../../confirm'
import { deleteAllRulesFromLanes, toggleBookDeliverySettings } from '../../../../../actions/terminal-rules'
import { handleError } from '../../../../../utils/handle-error'
import { getSubTerminals } from '../../../../../utils/sub-terminal-webapi'
import { SORTING_PROFILES } from '../constants'
import ClearRulesButton from '../clear-rules-button'
import LaneSelect from '../../../util/lane-select'
import MerchantSearchSelect from '../sorting-machine/merchant-search-select'

class TerminalSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            terminalLanesInUse: null,
            terminalGatesInUse: null,
            showTerminalSettings: false,
            renderAssignConfirm: false,
            newProfile: null,
            minorTerminals: [],
            merchantForOnDemnadRoutes: null,
        }
    }

    componentDidMount() {
        const { terminal } = this.props
        this.fetchMinorTerminals(terminal.code)
    }

    componentDidUpdate(prevProps) {
        const { terminalLanes, terminal } = this.props

        if (prevProps.terminal !== terminal) {
            this.fetchMinorTerminals(terminal.code)
        }
        if (prevProps.terminalLanes !== terminalLanes) {
            const isEnabled = []
            const hasTerminalGate = []

            terminalLanes.forEach((lane) => {
                if (lane.enabled === true) {
                    isEnabled.push(lane)

                    if (lane.terminalGate && lane.terminalGate.id !== null) {
                        hasTerminalGate.push(lane)
                    }
                }
            })

            const uniqueGatesInUse = [...new Set(hasTerminalGate.map((lane) => lane.terminalGate.id))]

            // The lint error below is inaccurate/irrelevant if the setState method is wrapped in a condition.
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ terminalLanesInUse: isEnabled.length, terminalGatesInUse: uniqueGatesInUse.length })
        }
    }

    fetchMinorTerminals(terminalCode) {
        getSubTerminals(terminalCode)
            .then((result) => this.setState({ minorTerminals: result.payload.sort() }))
            .catch(handleError)
    }

    onToggleBookDelivery = (enabled) => {
        const { dispatch, terminal, bookDelivery } = this.props
        const payload = { disabled: !enabled, disabledTerminals: bookDelivery.disabledTerminals }
        dispatch(toggleBookDeliverySettings(terminal.id, payload))
    }

    onToggleBookDeliveryMinorTerminal = (terminalCode, enabled) => {
        const { dispatch, terminal, bookDelivery } = this.props

        let data
        if (enabled) {
            const index = bookDelivery.disabledTerminals.indexOf(terminalCode)
            const array = [...bookDelivery.disabledTerminals]
            array.splice(index, 1)
            data = { disabled: bookDelivery.disabled, disabledTerminals: array }
        } else {
            data = {
                disabled: bookDelivery.disabled,
                disabledTerminals: [...bookDelivery.disabledTerminals, terminalCode],
            }
        }
        dispatch(toggleBookDeliverySettings(terminal.id, data))
    }

    renderAssignProfileConfirmation() {
        const { renderAssignConfirm, newProfile } = this.state
        const { handleSortingProfileSelect } = this.props

        if (newProfile !== 'ON_DEMAND_ROUTES') {
            return (
                <Confirm
                    show={renderAssignConfirm}
                    title="Change profile"
                    bsStyle="success"
                    onClose={() => this.setState({ renderAssignConfirm: false, newProfile: null })}
                    onConfirm={() => {
                        handleSortingProfileSelect(newProfile, [])
                        this.setState({ renderAssignConfirm: false })
                    }}
                >
                    Are you sure you want to change profile to <b>{SORTING_PROFILES[newProfile]}</b>?
                </Confirm>
            )
        }
        return this.renderOnDemandProfileConfirmation()
    }

    renderOnDemandProfileConfirmation() {
        const { newProfile, merchantForOnDemnadRoutes } = this.state
        const { handleSortingProfileSelect } = this.props
        let selectedAssignmentLaneIds = []
        return (
            <Confirm
                bsStyle="success"
                show
                title="Select lanes and change profile"
                onClose={() => this.setState({ renderAssignConfirm: false, newProfile: null })}
                onConfirm={() => {
                    handleSortingProfileSelect(newProfile, selectedAssignmentLaneIds, merchantForOnDemnadRoutes)
                    this.setState({ renderAssignConfirm: false, merchantForOnDemnadRoutes: null })
                }}
            >
                Select lanes to use and change to <b>{SORTING_PROFILES[newProfile]}</b>
                <br />
                (or leave lanes empty to get the default assignment behaviour)
                <Form horizontal>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                            Merchant
                        </Col>
                        <Col sm={9}>
                            <MerchantSearchSelect
                                onChange={(merchant) =>
                                    this.setState({ merchantForOnDemnadRoutes: merchant ? merchant.value : null })
                                }
                                selectedMerchantId={merchantForOnDemnadRoutes}
                                excludeMerchantsInGroups={false}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col componentClass={ControlLabel} sm={3}>
                            Lanes
                        </Col>
                        <Col sm={9}>
                            <LaneSelect
                                onChange={(pickedLanes) => {
                                    selectedAssignmentLaneIds = pickedLanes.map((lane) => lane.value)
                                }}
                            />
                        </Col>
                    </FormGroup>
                </Form>
            </Confirm>
        )
    }

    renderMinorTerminal(terminalCode) {
        const { bookDelivery, terminals } = this.props
        const terminal = terminals.find((x) => x.code === terminalCode)
        if (bookDelivery === undefined || !terminal) {
            return null
        }

        const terminalEnabled = !bookDelivery.disabledTerminals.includes(terminalCode) && !bookDelivery.disabled
        return (
            <div key={terminalCode}>
                <Checkbox
                    id={terminalCode}
                    checked={terminalEnabled}
                    disabled={bookDelivery.disabled}
                    onChange={(e) => this.onToggleBookDeliveryMinorTerminal(terminalCode, e.target.checked)}
                >
                    {terminal.name} {terminalEnabled ? 'enabled' : 'disabled'}
                </Checkbox>
            </div>
        )
    }

    render() {
        const {
            dispatch,
            terminal,
            selectedSortingProfile,
            deletingAllRulesFromLanes,
            deletingAllRulesFromLanesError,
            handleSaveRuleChanges,
            applyingProfile,
            bookDelivery,
            editingProfile,
            sortingProfiles,
        } = this.props
        const { terminalLanesInUse, terminalGatesInUse, minorTerminals } = this.state
        const sortingMachineBooksDelivery = Boolean(bookDelivery) && !bookDelivery.disabled
        const isSaveToProfileDisabled =
            !sortingProfiles.some((profile) => profile === selectedSortingProfile) || editingProfile || applyingProfile
        return (
            <Panel className="sorting-settings-panel">
                <TerminalSettingsModal
                    onHide={() => this.setState({ showTerminalSettings: false })}
                    show={this.state.showTerminalSettings !== false}
                    terminal={terminal}
                />
                {this.renderAssignProfileConfirmation()}
                <div className="panel-body">
                    <Row>
                        <Col className="pull-right">
                            <Button
                                bsStyle="primary"
                                type="button"
                                onClick={() => this.setState({ showTerminalSettings: true })}
                            >
                                <Glyphicon glyph="cog" /> Edit Settings
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="panel-body">
                    <Row>
                        <Col xs={12} md={9}>
                            <Col xs={6} md={6}>
                                <Row style={{ marginBottom: '10px', fontSize: '16px' }}>
                                    <b>Sorting details</b>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    Sorting logic: <b>{terminal.sortingVersion}</b>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    Number of lanes in use: <b>{terminalLanesInUse || 'None'}</b>
                                </Row>
                                <Row style={{ marginBottom: '5px' }}>
                                    Numbers of gates in use: <b>{terminalGatesInUse || 'None'}</b>
                                </Row>
                            </Col>
                            <Col xs={6} md={6}>
                                <Row style={{ fontSize: '16px' }}>
                                    <b>Book deliveries</b>
                                </Row>
                                <Row>
                                    <Checkbox
                                        id="bookDelivery"
                                        checked={sortingMachineBooksDelivery}
                                        onChange={(e) => this.onToggleBookDelivery(e.target.checked)}
                                    >
                                        <h4 style={{ display: 'inline' }}>
                                            Delivery Booking is {sortingMachineBooksDelivery ? 'enabled' : 'disabled'}
                                        </h4>
                                    </Checkbox>
                                    <div className="minor-terminals">
                                        {minorTerminals.map((terminalCode) => this.renderMinorTerminal(terminalCode))}
                                    </div>
                                </Row>
                            </Col>
                        </Col>
                        <Col style={{ textAlign: 'center' }} className="pull-right" xs={6} md={3}>
                            <Row style={{ marginBottom: '10px', fontSize: '16px' }}>
                                <b>Select profile</b>
                            </Row>
                            <Row>
                                <ButtonGroup className="pull-right" vertical bsSize="large" id="dropdown-size-large">
                                    {Object.keys(SORTING_PROFILES).map((profile) => {
                                        return (
                                            <Button
                                                key={profile}
                                                bsStyle={profile === selectedSortingProfile ? 'success' : null}
                                                onClick={() =>
                                                    this.setState({
                                                        newProfile: profile,
                                                        renderAssignConfirm: true,
                                                    })
                                                }
                                            >
                                                {SORTING_PROFILES[profile]}
                                            </Button>
                                        )
                                    })}
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="panel-body">
                    {/* Todo: send request to notify that settings has been changed for this terminal & Profile */}
                    <Row>
                        <ClearRulesButton
                            onConfirmation={() => dispatch(deleteAllRulesFromLanes(terminal.id))}
                            confirmationMessage={`You are about to remove all rules from all lanes in ${terminal.name}.`}
                            isDeletingRules={deletingAllRulesFromLanes}
                            errorMessage={deletingAllRulesFromLanesError}
                            buttonText="Clear all active rules"
                        />
                        <Button
                            onClick={handleSaveRuleChanges}
                            disabled={isSaveToProfileDisabled}
                            className="pull-right"
                            bsStyle="primary"
                        >
                            {editingProfile ? (
                                <i className="glyphicon glyphicon-refresh refreshing" />
                            ) : (
                                <span>
                                    <Glyphicon glyph="floppy-disk" /> Save to profile
                                </span>
                            )}
                        </Button>
                    </Row>
                </div>
            </Panel>
        )
    }
}

TerminalSettings.propTypes = {
    handleSortingProfileSelect: PropTypes.func.isRequired,
    selectedSortingProfile: PropTypes.string,
    handleSaveRuleChanges: PropTypes.func.isRequired,
    applyingProfile: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    deletingAllRulesFromLanes: PropTypes.bool,
    deletingAllRulesFromLanesError: PropTypes.string,
    terminalLanes: PropTypes.array,
    bookDelivery: PropTypes.object,
    terminal: PropTypes.object.isRequired,
    terminals: PropTypes.array,
    editingProfile: PropTypes.bool,
    sortingProfiles: PropTypes.array.isRequired,
}

const mapStateToProps = (
    {
        terminalRules: {
            deletingAllRulesFromLanes,
            deletingAllRulesFromLanesError,
            terminalLanes,
            bookDelivery,
            editingProfile,
            sortingProfiles,
        },
        terminals: { terminals },
    },
    { selectedTerminalId },
) => ({
    deletingAllRulesFromLanes,
    deletingAllRulesFromLanesError,
    terminalLanes,
    bookDelivery,
    terminal: terminals.find((terminal) => terminal.id === selectedTerminalId),
    terminals,
    editingProfile,
    sortingProfiles,
})

export default connect(mapStateToProps)(TerminalSettings)
