import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid, Col, Row, Button, Panel, Form, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import auth from '../../auth'

import * as smsActions from '../../actions/sms'
import * as userActions from '../../actions/user'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../utils/role'

class SMSSenderView extends Component {
    static propTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
        smsActions: PropTypes.object.isRequired,
        userActions: PropTypes.object.isRequired,
        user: PropTypes.object,
        sending: PropTypes.bool,
        error: PropTypes.bool,
    }

    state = {
        recipient: { value: '', dirty: false },
        message: { value: '', dirty: false },
        select: 'budbee',
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)
        if (!hasAuth) {
            this.props.router.push('/admin/unauthorized')
            return
        }

        this.props.userActions.fetchCurrentUser()
        const preset = this.props.location.query.recipient
        if (preset) {
            const { recipient } = this.state
            recipient.value = preset
            recipient.dirty = true
            this.setState({ recipient })
        }
    }

    handleUserInput = (e) => {
        const { name } = e.target
        if (name === 'select') {
            this.setState({ select: e.target.value })
        } else {
            const { value } = e.target
            const formgroup = this.state[name]
            formgroup.value = value
            formgroup.dirty = true
            this.setState({ [name]: formgroup })
        }
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        this.props.smsActions.sendSMS(this.state.select, this.state.recipient.value, this.state.message.value)
    }

    formValid = () =>
        this.validationState('recipient') === 'success' &&
        this.validationState('message') === 'success' &&
        this.state.select

    validationState = (name) => {
        const formgroup = this.state[name]
        if (formgroup.dirty) {
            return formgroup.value.length > 0 ? 'success' : 'error'
        }
        return null
    }

    renderError() {
        if (this.props.error == null) {
            return null
        }

        if (this.props.error) {
            return (
                <Panel header="Error" bsStyle="danger">
                    Could not send SMS.
                </Panel>
            )
        }

        return (
            <Panel header="Success" bsStyle="success">
                Sms has been sent.
            </Panel>
        )
    }

    render() {
        const currentUser = this.props.user
        if (currentUser == null) {
            return null
        }

        return (
            <Grid fluid>
                <Row>
                    <Col md={8} mdOffset={1}>
                        <Panel header="SMS Service">
                            <Form horizontal onChange={(event) => this.handleUserInput(event)}>
                                <FormGroup controlId="formControlsSelect">
                                    <Col componentClass={ControlLabel} sm={2}>
                                        Sender
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl
                                            name="select"
                                            defaultValue={this.state.select}
                                            componentClass="select"
                                            placeholder="select"
                                        >
                                            <option value="budbee">Budbee (no reply)</option>
                                            <option value="Instabox">Instabox (no reply)</option>
                                            <option value={currentUser.phoneNumber}>
                                                {currentUser.phoneNumber} (You)
                                            </option>
                                        </FormControl>
                                    </Col>
                                </FormGroup>

                                <FormGroup validationState={this.validationState('recipient')}>
                                    <Col componentClass={ControlLabel} sm={2}>
                                        Recipient
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl
                                            readOnly={this.props.sending}
                                            type="tel"
                                            placeholder="Enter the recipients phone number"
                                            name="recipient"
                                            value={this.state.recipient.value}
                                        />
                                        <FormControl.Feedback />
                                    </Col>
                                </FormGroup>

                                <FormGroup validationState={this.validationState('message')}>
                                    <Col componentClass={ControlLabel} sm={2}>
                                        Message
                                    </Col>
                                    <Col sm={8}>
                                        <FormControl
                                            autoFocus
                                            readOnly={this.props.sending}
                                            componentClass="textarea"
                                            placeholder="Enter a message"
                                            name="message"
                                            rows="10"
                                            value={this.state.message.value}
                                        />
                                        <FormControl.Feedback />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col smOffset={2} sm={8}>
                                        <Button
                                            disabled={this.props.sending || !this.formValid()}
                                            bsStyle="primary"
                                            onClick={this.handleFormSubmit}
                                        >
                                            {this.props.sending ? 'Sending...' : 'Send'}
                                        </Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Panel>
                        {this.renderError()}
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    error: state.sms.error,
    sending: state.sms.sending,
    user: state.users.current,
})

const mapDispatchToProps = (dispatch) => ({
    smsActions: bindActionCreators(smsActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SMSSenderView)
