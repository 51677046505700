import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import moment from 'moment'
import { Button, ControlLabel, Modal } from 'react-bootstrap'
import OrderApi from '../../utils/order-api'
import mapTimeZoneToCountry from '../../utils/timezone'
import { getOrderReturnWindows } from '../../utils/order-webapi'

export default class BookReturnModal extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            loading: true,
            windows: [],
            selected: null,
        }
    }

    componentDidMount() {
        const { token, deliveryAddress } = this.props.order
        getOrderReturnWindows(token, moment().format('YYYY-MM-DD'), 5).then((resp) => {
            const windows = resp.map((window) => {
                const returnDate = moment(window.start).format('YYYY-MM-DD')
                const start = moment(window.start)
                    .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                    .format('HH:mm')
                const stop = moment(window.stop)
                    .tz(mapTimeZoneToCountry(deliveryAddress.countryCode))
                    .format('HH:mm')
                return {
                    label: `${returnDate} ${start} – ${stop}`,
                    value: window,
                    data: window,
                }
            })
            this.setState({ loading: false, windows })
        })
    }

    validate() {
        return this.state.selected != null
    }

    submit = () => {
        // The value nrOfParcels is irrelevant since in this case the backend will overwrite it
        const { order, deliveryAttempt } = this.props
        const payload = {
            timeWindow: this.state.selected.data,
            nrOfParcels: 1,
            consignmentId: deliveryAttempt != null ? deliveryAttempt.id : null,
        }
        this.setState({ loading: true })
        OrderApi.bookReturn(order.token, payload)
            .then(() => {
                this.setState({ loading: false })
                this.props.onSuccess()
            })
            .catch((e) => {
                this.setState({ loading: false })
                console.error(e)
            })
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Book Return</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <ControlLabel>Select Return Date</ControlLabel>
                        <Select
                            autoBlur
                            allowCreate={false}
                            backspaceRemoves={false}
                            clearable={false}
                            ignoreCase
                            searchable={false}
                            isLoading={this.state.loading}
                            options={this.state.windows}
                            value={this.state.selected}
                            onChange={(item) => this.setState({ selected: item })}
                            placeholder="Välj upphämtningstid"
                            noResultsText="Inga tillgängliga tider"
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClose}>Cancel</Button>
                    <Button bsStyle="primary" disabled={!this.validate() || this.state.loading} onClick={this.submit}>
                        Book Return
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

BookReturnModal.propTypes = {
    show: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired,
    deliveryAttempt: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}
