import React from 'react'
import PropTypes from 'prop-types'
import { Col, Tabs, Tab } from 'react-bootstrap'
import EditForm from './components/edit-form'
import { INVOICING_SETTINGS, COMPENSATION_GROUPS } from '../../utils/couriers'
import TiersList from './components/tiers-list'
import DefaultsList from './components/default-compensation-models'

const Invoicing = ({ params }) => (
    <div>
        <Col className="mt-sm" md={10} mdOffset={1} lg={7}>
            <EditForm showSettings={INVOICING_SETTINGS} courierId={params.id} />
        </Col>
        <Col md={10} mdOffset={1} />
        <Col className="mt-sm mb-lg" md={10} mdOffset={1}>
            <Tabs defaultActiveKey={1} id="compensation">
                <Tab eventKey={1} title="Base tiers">
                    <TiersList courierId={params.id} group={COMPENSATION_GROUPS.BASE} />
                </Tab>
                <Tab eventKey={2} title="Bonus Tiers">
                    <TiersList courierId={params.id} group={COMPENSATION_GROUPS.BONUS} />
                </Tab>
                <Tab eventKey={3} title="Fixed Tiers">
                    <TiersList courierId={params.id} group={COMPENSATION_GROUPS.FIXED} />
                </Tab>
                <Tab eventKey={4} title="Multiplier Tiers">
                    <TiersList courierId={params.id} group={COMPENSATION_GROUPS.MULTIPLIER} />
                </Tab>
            </Tabs>
        </Col>
        <Col md={10} mdOffset={1} />
        <Col md={10} mdOffset={1}>
            <DefaultsList courierId={params.id} />
        </Col>
    </div>
)

export default Invoicing

Invoicing.propTypes = {
    params: PropTypes.object.isRequired,
}
