import { PROFILE_RULES_LOADED, PROFILE_RULES_LOADING } from '../constants/actionTypes'
import dataState from './initialState/terminal-profile-rules'

export default function TerminalProfileRulesReducer(state = dataState, action) {
    switch (action.type) {
        case PROFILE_RULES_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case PROFILE_RULES_LOADED:
            return {
                ...state,
                isLoading: false,
                rules: action.response,
            }
        default:
            return state
    }
}
