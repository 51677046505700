import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Col, Button, Row, Table, Modal, Glyphicon, Radio } from 'react-bootstrap'

import {
    addMerchantToReturnPalletGroup as addMerchantToReturnPalletGroupAction,
    setMerchantGroupMasterMerchant as setMerchantGroupMasterMerchantAction,
    removeMerchantFromReturnPalletGroup as removeMerchantFromReturnPalletGroupAction,
} from '../../actions/return-pallet-groups'
import MerchantSelectFormGroup from '../terminal-sorting/terminal-sorting-view/tabs/sorting-machine/merchant-select-form-group'

const ReturnPalletGroupEditModal = ({
    group,
    onHide,
    removeMerchantFromReturnPalletGroup,
    addMerchantToReturnPalletGroup,
    setMerchantGroupMasterMerchant,
    show,
}) => {
    const merchants = group ? group.merchants : []
    const merchantMasterId = group && group.groupMaster ? group.groupMaster.id : null
    const groupId = group ? group.id : null
    const groupName = group ? group.name : null
    return (
        <Modal
            className="sorting-modal"
            show={show}
            bsSize="large"
            aria-labelledby="contained-modal-title-lg"
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">Editing: {groupName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>
                                <strong>ID</strong>
                            </th>
                            <th>
                                <strong>Name</strong>
                            </th>
                            <th>Group master</th>
                        </tr>
                    </thead>
                    <tbody>
                        {merchants.map(({ id, name, externalName }) => (
                            <tr key={id}>
                                <td>{id}</td>
                                <td>
                                    {name} ({externalName})
                                </td>
                                <td>
                                    <Radio
                                        checked={id === merchantMasterId}
                                        title="Master merchant of the group"
                                        onChange={() => setMerchantGroupMasterMerchant(groupId, id)}
                                    />
                                </td>
                                <td>
                                    <Button
                                        type="button"
                                        className=""
                                        bsStyle="default"
                                        bsSize="sm"
                                        disabled={id === merchantMasterId}
                                        title={id === merchantMasterId ? 'Master merchant can not be removed' : ''}
                                        onClick={() => removeMerchantFromReturnPalletGroup(groupId, id)}
                                    >
                                        <Glyphicon glyph="trash" />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Row>
                    <Col md={6}>
                        <MerchantSelectFormGroup
                            selectedMerchantIds={new Set(merchants.map(({ id }) => id))}
                            onSave={(merchantId) => addMerchantToReturnPalletGroup(groupId, merchantId)}
                            buttonText="Add"
                        />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

ReturnPalletGroupEditModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    addMerchantToReturnPalletGroup: PropTypes.func.isRequired,
    setMerchantGroupMasterMerchant: PropTypes.func.isRequired,
    removeMerchantFromReturnPalletGroup: PropTypes.func.isRequired,
    group: PropTypes.object,
    show: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ returnPalletGroups: { groups } }, { groupId }) => ({
    group: groups.find(({ id }) => id === groupId),
})

const mapDispatchToProps = {
    addMerchantToReturnPalletGroup: addMerchantToReturnPalletGroupAction,
    setMerchantGroupMasterMerchant: setMerchantGroupMasterMerchantAction,
    removeMerchantFromReturnPalletGroup: removeMerchantFromReturnPalletGroupAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPalletGroupEditModal)
