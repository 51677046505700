import React, { Component } from 'react'
import { Alert, Button, Checkbox, Col, Form, FormGroup, Grid } from 'react-bootstrap'

import TerminalSelect from '../common/terminal-select'
import { handleError } from '../../utils/handle-error'
import {
    getDefaultState,
    getSelectionDetails,
    getStateUpdatesFromResponse,
    isEnabled,
    updateSettings,
    createPayload,
} from './utils'
import { fetchAllHubPallets, setHubPallets } from '../../utils/hubs-webapi'
import {
    HUB_PALLETS_SAVE_STATUS,
    HUB_PALLETS_LOAD_STATUS,
    HTTP_STATUS_NOT_FOUND,
    SAVE_DISABLED_STATUSES,
} from './constants'

export default class HubPalletConfiguration extends Component {
    constructor() {
        super()
        this.state = getDefaultState()
    }

    loadTerminalData = (selectedTerminalId) => {
        fetchAllHubPallets(selectedTerminalId)
            .then((hubPalletsResponse) => {
                this.setState(getStateUpdatesFromResponse(hubPalletsResponse))
            })
            .catch((error) => {
                this.setState({ hubPalletsLoadStatus: HUB_PALLETS_LOAD_STATUS.ERROR })
                if (error.status !== HTTP_STATUS_NOT_FOUND) {
                    handleError(error)
                }
            })
    }

    onConfigurationChange = (id, enablementType) => () => {
        const { hubPalletsSaveStatus, settings } = this.state
        if (hubPalletsSaveStatus !== HUB_PALLETS_SAVE_STATUS.SAVING) {
            this.setState({
                settings: updateSettings(settings, id, enablementType),
                hubPalletsSaveStatus: HUB_PALLETS_SAVE_STATUS.NOT_SAVED,
            })
        }
    }

    onSave = () => {
        const { selectedTerminal, settings } = this.state
        this.setState({ hubPalletsSaveStatus: HUB_PALLETS_SAVE_STATUS.SAVING })
        setHubPallets(selectedTerminal.id, createPayload(settings))
            .then((response) => {
                this.setState({
                    ...getStateUpdatesFromResponse(response),
                    hubPalletsSaveStatus: HUB_PALLETS_SAVE_STATUS.SAVED,
                })
            })
            .catch((error) => {
                handleError(error)
                this.setState({ hubPalletsSaveStatus: HUB_PALLETS_SAVE_STATUS.ERROR })
            })
    }

    onSelectTerminal = (selectedTerminal) => {
        this.setState({ ...getDefaultState(), selectedTerminal })
        if (selectedTerminal) {
            this.loadTerminalData(selectedTerminal.id)
        }
    }

    renderHeader = () => {
        const headerStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }

        const { hubPalletsSaveStatus, hubPalletsLoadStatus, selectedTerminal } = this.state
        const terminalId = selectedTerminal && selectedTerminal.id
        const hasNotLoaded = hubPalletsLoadStatus !== HUB_PALLETS_LOAD_STATUS.LOADED
        const shouldDisableButton = SAVE_DISABLED_STATUSES.has(hubPalletsSaveStatus) || hasNotLoaded
        return (
            <div>
                <h1 style={headerStyle}>
                    Hub Pallet Configuration
                    <Button onClick={this.onSave} bsStyle="primary" bsSize="small" disabled={shouldDisableButton}>
                        {hubPalletsSaveStatus === HUB_PALLETS_SAVE_STATUS.SAVING
                            ? 'Saving configuration...'
                            : 'Save configuration'}
                    </Button>
                </h1>
                {hubPalletsSaveStatus === HUB_PALLETS_SAVE_STATUS.ERROR && (
                    <Alert bsStyle="danger">An error occurred while saving, please try again..</Alert>
                )}
                {hubPalletsSaveStatus === HUB_PALLETS_SAVE_STATUS.SAVED && (
                    <Alert bsStyle="success">Successfully saved!</Alert>
                )}
                <TerminalSelect value={terminalId} onSelect={this.onSelectTerminal} />
            </div>
        )
    }

    renderHowTo = () => {
        const howToStyle = { marginTop: '20px' }

        const { hubPalletsLoadStatus, selectedTerminal } = this.state
        if (hubPalletsLoadStatus === HUB_PALLETS_LOAD_STATUS.ERROR && selectedTerminal) {
            return (
                <Alert style={howToStyle} bsStyle="danger">
                    No sorting machine in {selectedTerminal.name}
                </Alert>
            )
        }
        if (hubPalletsLoadStatus === HUB_PALLETS_LOAD_STATUS.LOADED) {
            return (
                <Alert style={howToStyle} bsStyle="info">
                    <div>
                        <span>
                            Use the lists below to define which terminals should be covered by newly created pallets
                            destined for Hub destinations.
                        </span>
                    </div>
                </Alert>
            )
        }
        return null
    }

    renderCheckbox = ({ id, name, enablementType, terminals }) => {
        const { settings } = this.state
        const isChecked = isEnabled(settings, id, enablementType)
        const onChange = this.onConfigurationChange(id, enablementType)
        const details = terminals && getSelectionDetails(terminals)
        return (
            <Checkbox key={name} checked={isChecked} onChange={onChange}>
                <span>
                    {name}
                    {details && ': '}
                </span>
                {details && <span style={{ fontStyle: 'italic' }}>{details}</span>}
            </Checkbox>
        )
    }

    renderHubPalletFormGroups = () =>
        this.state.hubPalletsResponse.map(({ name, terminals }) => (
            <FormGroup key={name}>
                <b>{name}</b>
                {terminals.map(this.renderCheckbox)}
            </FormGroup>
        ))

    render = () => (
        <Grid fluid>
            <Col md={8} mdOffset={2}>
                <Form>
                    {this.renderHeader()}
                    {this.renderHowTo()}
                    {this.renderHubPalletFormGroups()}
                </Form>
            </Col>
        </Grid>
    )
}
