import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, ControlLabel, Button, Alert, Row, Glyphicon } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import * as actions from '../../../actions/couriers'
import DeleteTierModal from './delete-tier-modal'
import BaseTierTable from './base-tier-table'
import BonusTierTable from './bonus-tier-table'
import FixedTierTable from './fixed-tier-table'
import TierModal from './tier-modal'
import { COMPENSATION_GROUPS } from '../../../utils/couriers'
import { DATE_FORMAT } from '../../../utils/date'

const setSelected = (selected) => (tier) => ({
    ...tier,
    selected,
})

class TierType extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showModal: false,
            selectedTier: null,
            allSelected: false,
            compensationTiers: this.props.compensationTiers,
            showDeleteModal: false,
            deleting: false,
        }
    }

    // needed to make sure that read and write replicas are in phase.
    fetchCompensationTiersWithDelay = () => {
        const lastRelevantDate = moment
            .utc()
            .subtract(60, 'days')
            .format(DATE_FORMAT)
        setTimeout(() => this.props.actions.fetchCompensationTiers(this.props.courierId, lastRelevantDate), 500)
    }

    toggleModal = (selectedTier) => () =>
        this.setState((state) => ({
            showModal: !state.showModal,
            selectedTier,
        }))

    handleClose = (saved) => {
        if (saved) {
            this.fetchCompensationTiersWithDelay()
        }

        return this.toggleModal(null)
    }

    toggleAllSelected = () => {
        this.setState((state) => ({
            allSelected: !state.allSelected,
            compensationTiers: state.compensationTiers.map(setSelected(!state.allSelected)),
        }))
    }

    toggleDeleteModal = () => {
        this.setState((state) => ({
            showDeleteModal: !state.showDeleteModal,
        }))
    }

    selectedCompensationTiers = () => this.state.compensationTiers.filter((x) => x.selected)

    handleDelete = () => {
        this.setState({ deleting: true })

        const tierIds = this.selectedCompensationTiers().map((x) => x.id)
        this.props.actions.deleteCompensationTiers(this.props.courierId, tierIds).then(() => {
            this.setState({ deleting: false })
            this.fetchCompensationTiersWithDelay()
        })
    }

    toggleSelected = (e) => {
        e.stopPropagation()

        const tierId = parseInt(e.target.value, 10)
        const { compensationTiers } = this.state
        const compensationTiersToUpdate = [...compensationTiers]
        const selectedIndex = compensationTiersToUpdate.findIndex((tier) => tier.id === tierId)
        const selectedTier = compensationTiersToUpdate[selectedIndex]

        selectedTier.selected = !selectedTier.selected

        this.setState({ compensationTiers: compensationTiersToUpdate })
    }

    renderTierTable = (type, compensationTiers) => {
        if (type.group === COMPENSATION_GROUPS.BASE) {
            return (
                <BaseTierTable
                    key={0}
                    type={type}
                    compensationTiers={compensationTiers}
                    onSelect={this.toggleSelected}
                    onSelectAll={this.toggleAllSelected}
                    onEdit={this.toggleModal}
                />
            )
        }
        if (type.group === COMPENSATION_GROUPS.BONUS) {
            return (
                <BonusTierTable
                    key={0}
                    type={type}
                    compensationTiers={compensationTiers}
                    onSelect={this.toggleSelected}
                    onSelectAll={this.toggleAllSelected}
                    onEdit={this.toggleModal}
                />
            )
        }
        if (type.group === COMPENSATION_GROUPS.FIXED) {
            return (
                <FixedTierTable
                    key={0}
                    type={type}
                    compensationTiers={compensationTiers}
                    onSelect={this.toggleSelected}
                    onSelectAll={this.toggleAllSelected}
                    onEdit={this.toggleModal}
                />
            )
        }
        if (type.group === COMPENSATION_GROUPS.MULTIPLIER) {
            return (
                <FixedTierTable
                    key={0}
                    type={type}
                    compensationTiers={compensationTiers}
                    onSelect={this.toggleSelected}
                    onSelectAll={this.toggleAllSelected}
                    onEdit={this.toggleModal}
                />
            )
        }

        return null
    }

    render() {
        const { type, loading, courierId } = this.props
        const { compensationTiers, selectedTier, showModal, showDeleteModal, deleting } = this.state

        if (loading) {
            return null
        }

        return (
            <Row className="mt-sm">
                <Col className="text-capitalize text-right" componentClass={ControlLabel} sm={3}>
                    {type.title}
                </Col>
                <Col sm={9}>
                    <Row className="text text-muted margin-horizontal-xs margin-bottom-sm italic"> {type.example} </Row>
                    {compensationTiers.length ? (
                        [
                            this.renderTierTable(type, compensationTiers),
                            <Button
                                key={1}
                                bsStyle="danger"
                                onClick={this.toggleDeleteModal}
                                disabled={!compensationTiers.some((x) => x.selected)}
                            >
                                <Glyphicon glyph="trash" /> Delete
                            </Button>,
                        ]
                    ) : (
                        <Alert bsStyle="info">No {type.title} rates are specified yet</Alert>
                    )}
                    <Button onClick={this.toggleModal(null)} bsStyle="primary">
                        Add {type.title}
                    </Button>
                    {showModal && (
                        <TierModal
                            courierId={courierId}
                            type={type}
                            onClose={this.handleClose}
                            existingTier={selectedTier}
                        />
                    )}
                    {showDeleteModal && (
                        <DeleteTierModal
                            onCancel={this.toggleDeleteModal}
                            onDelete={this.handleDelete}
                            deleting={deleting}
                            count={this.selectedCompensationTiers().length}
                        />
                    )}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    error: state.couriers.error,
    loading: state.couriers.loadingCompensationTiers,
    compensationTiers: state.couriers.compensationTiers
        .filter((tier) => tier.type === ownProps.type.id)
        .map(setSelected(false)),
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TierType)

TierType.propTypes = {
    actions: PropTypes.object,
    type: PropTypes.object.isRequired,
    compensationTiers: PropTypes.array.isRequired,
    courierId: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}
