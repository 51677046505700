export default {
    login: {
        status: 0,
        loading: false,
        message: '',
    },
    requestReset: {
        status: 0,
        message: '',
    },
    reset: {
        status: 0,
        message: '',
    },
    data: [],
    current: null,
    fetchingCurrentUser: false,
    errors: null,
    statusCode: null,
}
