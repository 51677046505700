import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { displayName } from '../../../utils/display-name'
import { generateTimes } from '../../../utils/times'
import { formatTime } from '../format-time'
import { Alert, Checkbox, Col, ControlLabel, FormControl, FormGroup, Glyphicon, Row } from 'react-bootstrap'
import { mapSort, sortStrings } from '../../../utils/sorting'
import moment from 'moment'

const renderLocation = ({ id, buyerName, collectionPointName, startTime, endTime }) => (
    <option key={id} value={id}>
        {displayName(' - ', buyerName, collectionPointName, startTime.substring(0,5), endTime.substring(0,5))}
    </option>
)

renderLocation.propType = {
    id: PropTypes.number.isRequired,
    buyerName: PropTypes.string,
    collectionPointName: PropTypes.string,
}

const MerchantStop = ({
    isReadOnly,
    sortOrder,
    id,
    multipleLocationsOptions,
    isOneTimeRoute,
    startTime,
    endTime,
    pallets,
    pickup,
    returns,
    collectionPointDeleted,
    collectionPointName,
    onPickupChange,
    onTimeChange,
    onPickupOptionChange,
}) => (
    <div>
        <FormGroup>
            <ControlLabel>
                <Glyphicon glyph="shopping-cart" /> Merchant stop {sortOrder + 1}
            </ControlLabel>
            <FormControl
                componentClass="select"
                name="pickupId[]"
                value={id}
                onChange={(e) => onPickupChange(e.target.value, sortOrder)}
                disabled={isReadOnly || multipleLocationsOptions.length === 0}
            >
                <option value="-1" disabled>
                    Select
                </option>
                {[...multipleLocationsOptions]
                    .sort(mapSort(sortStrings, (l) => displayName(' - ', l.buyerName, l.collectionPointName, l.startTime, l.endTime,)))
                    .map(renderLocation)}
            </FormControl>
        </FormGroup>

        {id !== '-1' ? (
            <Row>
                {!isOneTimeRoute && !collectionPointDeleted && (
                    <Col md={12}>
                        <strong>Interval:</strong> {formatTime(startTime)} - {formatTime(endTime)}
                    </Col>
                )}
                {startTime && isOneTimeRoute && !collectionPointDeleted && (
                    <Col md={4}>
                        <FormGroup>
                            <strong>Arrival:</strong>
                            <Select
                                disabled={isReadOnly}
                                componentClass="select"
                                value={{ value: startTime, label: formatTime(startTime) }}
                                onChange={onTimeChange('startTime')}
                                options={generateTimes(5)}
                                clearable={false}
                                searchable
                            />
                        </FormGroup>
                    </Col>
                )}
                {endTime && isOneTimeRoute && !collectionPointDeleted && (
                    <Col md={4}>
                        <FormGroup>
                            <strong>Departure:</strong>
                            <Select
                                disabled={isReadOnly}
                                componentClass="select"
                                value={{ value: endTime, label: formatTime(endTime) }}
                                onChange={onTimeChange('endTime')}
                                options={generateTimes(5)}
                                clearable={false}
                                searchable
                            />
                        </FormGroup>
                        <FormGroup>
                            {moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm')) && (
                                <Alert bsStyle="warning">
                                    <Glyphicon glyph="time" /> Schedule continues into next day
                                </Alert>
                            )}
                        </FormGroup>
                    </Col>
                )}
                {!collectionPointDeleted ? (
                    <Col md={12}>
                        <p>
                            <strong>Agreed max number of pallets:</strong> {pallets}
                        </p>
                    </Col>
                ) : null}
                {!collectionPointDeleted ? (
                    <Col md={12}>
                        <FormGroup>
                            <Checkbox
                                disabled={isReadOnly || multipleLocationsOptions.length === 0}
                                checked={pickup}
                                onChange={(e) => onPickupOptionChange(sortOrder, 'pickup', e.target.checked)}
                                inline
                            >
                                Pick-up
                            </Checkbox>
                            <Checkbox
                                disabled={isReadOnly || multipleLocationsOptions.length === 0}
                                checked={returns}
                                onChange={(e) => onPickupOptionChange(sortOrder, 'returns', e.target.checked)}
                                inline
                            >
                                Return
                            </Checkbox>
                        </FormGroup>
                    </Col>
                ) : null}
                {collectionPointDeleted ? (
                    <Col md={12}>
                        <FormGroup>
                            <Alert bsStyle="danger">
                                <Glyphicon glyph="exclamation-sign" /> This merchant schedule stop "
                                {collectionPointName}" is associated with a collection point that was de-activated and
                                therefore needs updating or removing
                            </Alert>
                        </FormGroup>
                    </Col>
                ) : null}
            </Row>
        ) : null}
    </div>
)

export default MerchantStop
