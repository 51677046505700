import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createCourier } from '../../utils/courier-webapi'
import auth from '../../auth'
import Form from './form'
import empty from '../../utils/empty'
import { handleError } from '../../utils/handle-error'
import * as regionalSettingsActions from '../../actions/regional-settings'
import { OPERATIONS_ADMIN } from '../../utils/role'

const validate = (state) =>
    !empty(state.name) &&
    state.courierSettings !== null &&
    state.countries.length > 0 &&
    !empty(state.courierSettings.companyRegistrationNumber) &&
    !empty(state.courierSettings.vatNumber) &&
    !empty(state.courierSettings.invoiceEmail) &&
    !empty(state.courierSettings.companyCountry) &&
    !empty(state.courierSettings.companyLegalName)

class CreateCourier extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // eslint-disable-next-line react/no-unused-state
            name: '',
            // eslint-disable-next-line react/no-unused-state
            countries: [],
            courierSettings: {
                billingPeriod: 'MONTHLY',
                invoiceEmail: '',
                companyRegistrationNumber: '',
                vatNumber: '',
                companyCountry: '',
                dueDaysInvoice: 30,
                vatOnInvoice: true,
                terminalEndStopAlways: false,
                selfInvoiced: true,
                combinedInvoicing: false,
            },
        }
    }

    componentDidMount() {
        if (!auth.hasAnyRole(OPERATIONS_ADMIN)) {
            this.redirectToCouriers()
        }
        this.props.actions.fetchRegionalSettings()
    }

    onChange = (courier) => {
        this.setState(courier)
    }

    onSettingsChange = (name, prop) => (e) => {
        const value = e.target[name]
        this.setState((prevState) => ({ courierSettings: { ...prevState.courierSettings, [prop]: value } }))
    }

    onSettingsChangeFromList = (name) => (e) => {
        const { value } = e
        this.setState((prevState) => ({
            courierSettings: {
                ...prevState.courierSettings,
                [name]: value,
            },
        }))
    }

    onCountriesChange = (options) => {
        const selectedCountries = []
        options.forEach((option) => {
            selectedCountries.push(option.value)
        })
        // eslint-disable-next-line react/no-unused-state
        this.setState(() => ({ countries: selectedCountries }))
    }

    redirectToCouriers = () => this.props.router.push('/admin/couriers')

    create = (e) => {
        e.preventDefault()

        const carrier = this.state

        if (!validate(carrier)) {
            return
        }

        createCourier(carrier)
            .then(() => this.props.router.push('/admin/couriers'))
            .catch(handleError)
    }

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col className="mt-sm" md={6} mdOffset={3}>
                        <Form
                            onSubmit={this.create}
                            onCancel={this.redirectToCouriers}
                            validate={validate}
                            courier={this.state}
                            onChange={this.onChange}
                            onSettingsChange={this.onSettingsChange}
                            onSettingsChangeFromList={this.onSettingsChangeFromList}
                            onCountriesChange={this.onCountriesChange}
                            countries={this.props.countries}
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    countries: state.regionalSettings.countries,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...regionalSettingsActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourier)

CreateCourier.propTypes = {
    router: PropTypes.object.isRequired,
    actions: PropTypes.object,
    countries: PropTypes.array,
}
