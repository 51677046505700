/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    Button,
    Form,
    FormGroup,
    Col,
    Row,
    Table,
    Checkbox,
    Glyphicon,
    ControlLabel,
    FormControl,
} from 'react-bootstrap'
import Loader from 'react-loader'
import uniq from 'lodash/uniq'

import CountrySelect from '../../../common/country-select'
import {
    getIndexingSettings,
    updateGlobalIndexingSettings,
    updateMerchantIndexingSettings,
    postScrapeMerchant,
} from '../../../../utils/shopping-indexing'
import { getShoppingSettingsForMerchant } from '../../../../utils/shopping-webapi'
import { handleError } from '../../../../utils/handle-error'
import MerchantInput from '../merchant-input'
import ShoppingGlobalIndexing from './shopping-global-indexing'
import ShoppingList from './shopping-list'
import PreviewList from './preview-list'

const LABELS = [
    { manualBrands: 'Brands' },
    { manualKeywords: 'Keywords' },
    { scrapeBrandStart: 'URL Brand Start' },
    { scrapeKeywordStart: 'URL Keyword Start' },
    { blockBrands: 'Block Brands' },
    { blockKeywords: 'Block Keywords' },
    /* { crawlBrandStart: 'Crawl Brand Start' },
    { crawlKeywordStart: 'Crawl Keyword Start' }, */
]

const initialGlobalSettings = {
    manualBrands: [],
    manualKeywords: [],
    blockBrands: [],
    blockKeywords: [],
}

const initialData = {
    manualBrands: [],
    manualKeywords: [],
    blockBrands: [],
    blockKeywords: [],
    crawlBrandStart: [],
    crawlKeywordStart: [],
    scrapeBrandStart: [],
    scrapeKeywordStart: [],
}

class AddShoppingIndexing extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            country: { value: '', label: '' },
            error: null,
            ...initialData,
            taggedList: {},
            globalSettings: { ...initialGlobalSettings },
            indexingSettings: {},
            scarpingResult: null,
            viewGlobalList: false,
            toggle: false,
            singleCheck: {},
            loaded: {
                indexingSettingsLoaded: false,
                globalSettingsLoaded: false,
                scarpingResultLoaded: false,
            },
            textarea: '',
            previewList: null,
            checkAll: {},
        }
    }

    addCheckState() {
        const { indexingSettings, country } = this.state
        if (indexingSettings) {
            let singleCheck = {}
            let checkAll = {}
            // eslint-disable-next-line array-callback-return
            Object.entries(indexingSettings[country.value]).map(([item, value]) => {
                const newKey = { [item]: new Array(value.length).fill(true) }
                singleCheck = { ...singleCheck, ...newKey }
                checkAll = { ...checkAll, [item]: true }
            })

            this.setState({ singleCheck, checkAll })
        }
    }

    getShoppingSettings = () => {
        this.setState({ loaded: { indexingSettingsLoaded: true } })
        getShoppingSettingsForMerchant(this.props.merchant.id)
            .then((merchantSettingsResponse) => {
                const { value } = this.state.country
                if (merchantSettingsResponse) {
                    this.setState({ loaded: { indexingSettingsLoaded: false } })
                    if (!Object.keys(merchantSettingsResponse.indexingSettings).includes(value)) {
                        this.setState({ indexingSettings: { [value]: { ...initialData } } }, this.addCheckState)
                    } else {
                        this.setState({ indexingSettings: merchantSettingsResponse.indexingSettings })
                    }
                    this.setState({ taggedList: merchantSettingsResponse.indexing }, this.addCheckState)
                }
            })
            .catch((e) => {
                this.setState({ error: e }, () => handleError(e))
            })
    }

    getGlobalSettings = () => {
        this.setState({ loaded: { globalSettingsLoaded: true } })

        getIndexingSettings(this.state.country.value)
            .then(({ manualBrands, manualKeywords, blockBrands, blockKeywords }) => {
                this.setState({ loaded: { globalSettingsLoaded: false } })

                this.setState({
                    globalSettings: {
                        manualBrands,
                        manualKeywords,
                        blockBrands,
                        blockKeywords,
                    },
                    viewGlobalList: true,
                })
            })
            .catch((e) => {
                this.setState({ error: e }, () => handleError(e))
            })
    }

    updateGlobalIndexing = () => {
        const createData = () => {
            const {
                country: { value },
                indexingSettings,
                globalSettings,
                singleCheck,
            } = this.state

            const detect = (a) => {
                const check = indexingSettings[value][a].filter((el, i) => {
                    return singleCheck[a][i]
                })
                const list = uniq([...globalSettings[a], ...check])
                return list
            }

            const data = Object.keys(initialGlobalSettings).map((key) => {
                return { [key]: detect(key) }
            })

            return Object.assign({}, ...data)
        }

        const data = {
            country: this.state.country.value,
            ...createData(),
        }

        updateGlobalIndexingSettings(data)
            .then(() => {
                this.getGlobalSettings()
            })
            .catch((e) => {
                this.setState({ error: e }, () => handleError(e))
            })
    }

    showGlobalList() {
        this.setState(({ viewGlobalList }) => ({
            viewGlobalList: !viewGlobalList,
        }))
    }

    handleInputChange = (evt) => {
        const { name, value } = evt.target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    createData = () => {
        const {
            country: { value },
            indexingSettings,
        } = this.state

        const detect = (a) => {
            const check = indexingSettings[value][a].filter((el, i) => {
                return this.state.singleCheck[a][i]
            })

            const list = typeof this.state[a] === 'string' ? [this.state[a], ...check] : [...this.state[a], ...check]
            return uniq(list).filter((i) => i)
        }

        const data = Object.keys(initialData).map((key) => {
            return { [key]: detect(key) }
        })

        return Object.assign({}, ...data)
    }

    updateMerchantIndexingSettings = () => {
        this.setState({ loaded: { indexingSettingsLoaded: true } })
        const { merchant } = this.props
        const { value } = this.state.country
        updateMerchantIndexingSettings(merchant.id, value, this.createData())
            .then(() => {
                this.getShoppingSettings()
            })
            .catch((e) => {
                this.setState({ error: e }, () => handleError(e))
            })
            .finally(() => {
                this.setState({ ...initialData })
            })
    }

    scrapeMerchant = () => {
        this.setState({ loaded: { scarpingResultLoaded: true } })
        const { merchant } = this.props
        const { value } = this.state.country
        postScrapeMerchant(merchant.id, value, this.createData())
            .then((res) => {
                this.setState({ loaded: { scarpingResultLoaded: false } }, () => {
                    this.setState({ scarpingResult: res })
                })
            })
            .catch((e) => {
                this.setState({ error: e }, () => handleError(e))
            })
    }

    removeGlobalItem = (item, key) => {
        this.setState(
            ({ globalSettings }) => ({
                globalSettings: {
                    ...globalSettings,
                    [key]: globalSettings[key].filter((e) => e !== item),
                },
            }),
            this.updateGlobalIndexing,
        )
    }

    removeIndexItem = (item, key) => {
        this.setState(({ indexingSettings, country }) => {
            return {
                loaded: {
                    indexingSettingsLoaded: true,
                },
                indexingSettings: {
                    ...indexingSettings,
                    [country.value]: {
                        ...indexingSettings[country.value],
                        [key]: indexingSettings[country.value][key].filter((e) => e !== item),
                    },
                },
            }
        }, this.updateMerchantIndexingSettings)
    }

    renderList = (arr, key) => {
        const { singleCheck } = this.state
        return arr.map((item, index) => {
            const check = singleCheck[key] ? singleCheck[key][index] : false
            return (
                <tr key={index}>
                    <td>
                        <Checkbox
                            inline
                            bsClass=""
                            checked={check}
                            onChange={() => {
                                this.setState((prevState) => {
                                    const updatedState = prevState.singleCheck[key].map((i, position) =>
                                        index === position ? !i : i,
                                    )
                                    return {
                                        singleCheck: {
                                            ...prevState.singleCheck,
                                            [key]: updatedState,
                                        },
                                    }
                                })
                            }}
                        />
                        <p style={{ display: 'inline-block', paddingLeft: '8px' }}>{item}</p>
                        <Button
                            style={{ float: 'right' }}
                            bsStyle="danger"
                            bsSize="xsmall"
                            onClick={() => this.removeIndexItem(item, key)}
                        >
                            <Glyphicon glyph="trash" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    renderSettings = () => {
        const {
            country: { value },
            indexingSettings,
        } = this.state
        if (indexingSettings[value]) {
            return (
                <div>
                    <p>Check the box to use as a scraping data</p>
                    {LABELS.map((obj, index) => {
                        const heading = Object.keys(obj)[0]
                        return (
                            <Table striped bordered hover key={index}>
                                <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                {heading}
                                                <Button
                                                    style={{ float: 'right' }}
                                                    bsSize="xsmall"
                                                    onClick={() => {
                                                        this.setState((prevState) => {
                                                            return {
                                                                checkAll: {
                                                                    ...prevState.checkAll,
                                                                    [heading]: !prevState.checkAll[heading],
                                                                },
                                                                singleCheck: {
                                                                    ...prevState.singleCheck,
                                                                    [heading]: prevState.singleCheck[heading].map(
                                                                        () => !prevState.checkAll[heading],
                                                                    ),
                                                                },
                                                            }
                                                        })
                                                    }}
                                                >
                                                    check/uncheck all
                                                </Button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{this.renderList(indexingSettings[value][heading], heading)}</tbody>
                            </Table>
                        )
                    })}
                </div>
            )
        }
        return null
    }

    render() {
        const { country, scarpingResult, error, viewGlobalList, globalSettings, taggedList } = this.state

        return (
            <div>
                <Row>
                    <p>Select a country to view and add indexing settings</p>
                    {error && <p>Something went wrong</p>}
                    <Col className="mt-sm" md={10} lg={2}>
                        <CountrySelect
                            value={country}
                            onSelect={(selectedCountry) => {
                                if (selectedCountry) {
                                    this.setState({ country: selectedCountry }, () => {
                                        this.getShoppingSettings()
                                        this.getGlobalSettings()
                                    })
                                }
                            }}
                        />
                    </Col>
                    <Col className="mt-sm" md={10} lg={5}>
                        <Form horizontal>
                            <FormGroup>
                                {LABELS.map((obj, index) => {
                                    return (
                                        <MerchantInput
                                            key={index}
                                            name={Object.keys(obj)[0]}
                                            type="text"
                                            required
                                            controlId={Object.keys(obj)[0]}
                                            label={Object.values(obj)[0]}
                                            value={this.state[Object.keys(obj)[0]]}
                                            onChange={this.handleInputChange}
                                        />
                                    )
                                })}
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col className="mt-sm" md={10} lg={5}>
                        <FormGroup controlId="formControlsTextarea">
                            <ControlLabel>Paste keywords or brands</ControlLabel>
                            <FormControl
                                componentClass="textarea"
                                placeholder="textarea"
                                rows={14}
                                type="text"
                                value={this.state.textarea}
                                name="textarea"
                                onChange={this.handleInputChange}
                            />
                        </FormGroup>
                        <Button
                            onClick={() => {
                                this.setState(({ textarea }) => ({
                                    previewList: textarea
                                        .replace(/\r\n/g, '\n')
                                        .split('\n')
                                        .filter((i) => i),
                                }))
                            }}
                        >
                            Preview
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ textarea: '' })
                            }}
                        >
                            clear
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState(({ textarea }) => ({
                                    manualKeywords: textarea
                                        .replace(/\r\n/g, '\n')
                                        .split('\n')
                                        .filter((i) => i),
                                }))
                            }}
                        >
                            Add to keywords
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState(({ textarea }) => ({
                                    manualBrands: textarea
                                        .replace(/\r\n/g, '\n')
                                        .split('\n')
                                        .filter((i) => i),
                                }))
                            }}
                        >
                            Add to brands
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Button
                        disabled={!country.value}
                        bsStyle="success"
                        onClick={() => this.updateMerchantIndexingSettings()}
                    >
                        Add
                    </Button>
                    <Button disabled={!country.value} bsStyle="info" onClick={() => this.scrapeMerchant()}>
                        Test
                    </Button>
                    <Button onClick={() => this.showGlobalList()}>
                        {viewGlobalList ? 'hide global list' : 'show global list'}
                    </Button>
                    <Button disabled={!country.value} bsStyle="primary" onClick={this.updateGlobalIndexing}>
                        Add to global List
                    </Button>
                </Row>
                <Row>
                    <Col className="mt-sm" md={10} lg={4}>
                        <Loader color="#bfbfbf" loaded={!this.state.loaded.indexingSettingsLoaded}>
                            {country.value && this.renderSettings()}
                        </Loader>
                    </Col>
                    <Col className="mt-sm" md={10} lg={4}>
                        <Loader color="#bfbfbf" loaded={!this.state.loaded.globalSettingsLoaded}>
                            {viewGlobalList && (
                                <ShoppingGlobalIndexing
                                    globalSettings={globalSettings}
                                    country={country.value}
                                    error={error}
                                    removeItem={this.removeGlobalItem}
                                />
                            )}
                        </Loader>
                    </Col>
                    <Col className="mt-sm" md={10} lg={4}>
                        <Loader color="#bfbfbf" loaded={!this.state.loaded.scarpingResultLoaded}>
                            {scarpingResult && <ShoppingList scarpingResult={scarpingResult} />}
                        </Loader>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-sm" md={10} lg={6}>
                        {taggedList[country.value] && <ShoppingList scarpingResult={taggedList[country.value]} />}
                    </Col>
                    <Col className="mt-sm" md={10} lg={6}>
                        {this.state.previewList && <PreviewList list={this.state.previewList} />}
                    </Col>
                </Row>
            </div>
        )
    }
}

AddShoppingIndexing.propTypes = {
    merchant: PropTypes.shape({
        externalName: PropTypes.string,
        id: PropTypes.number,
    }),
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
})

export default connect(mapStateToProps, null)(AddShoppingIndexing)
