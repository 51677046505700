import * as types from '../constants/actionTypes'
import userState from './initialState/userState'
import { mapSort, sortStrings } from '../utils/sorting'

export default function UserReducer(state = userState, action) {
    switch (action.type) {
        case types.USER_LOGIN:
            return {
                ...state,
                login: { loading: true, status: action.status, message: action.message },
            }

        case types.USER_LOGIN_ERROR:
        case types.USER_LOGIN_SUCCESS:
            return {
                ...state,
                login: { loading: false, status: action.status, message: action.message },
            }

        case types.USER_PASSWORD_CHANGE_SUCCESS:
        case types.USER_PASSWORD_CHANGE_FAIL:
            return {
                ...state,
                reset: { status: action.status, message: action.message },
            }

        case types.LOADING_USERS:
            return {
                ...state,
                loading: true,
            }

        case types.USERS_LOADED:
            return {
                ...state,
                loading: false,
                data: action.response.sort(
                    mapSort(sortStrings, ({ firstName, lastName }) => `${firstName} ${lastName}`),
                ),
            }

        case types.USER_LOADED:
            return {
                ...state,
                user: action.user,
            }

        case types.USER_CREATE_FAILED:
            return {
                ...state,
                error: action.message,
            }

        case types.USER_SELECTED:
            return {
                ...state,
                selected: state.data.find((u) => u.id === action.id),
            }

        case types.USER_DELETED:
            return {
                ...state,
                data: state.data.filter((u) => u.id !== action.id),
            }

        case types.CURRENT_USER_LOADED:
            return {
                ...state,
                current: action.user,
                fetchingCurrentUser: false,
            }

        case types.CURRENT_USER_LOGGED_OUT:
            return { ...state, current: null }

        case types.CURRENT_USER_REQUESTED:
            return { ...state, fetchingCurrentUser: true }

        case types.DRIVER_FEEDBACK_LOADED:
            return {
                ...state,
                driverFeedback: action.driverFeedback.payload,
            }

        case types.UPDATING_USER:
            return {
                ...state,
                loading: true,
            }

        case types.USER_UPDATED:
        case types.UPDATE_USER_CANCELLED:
            return {
                ...state,
                loading: false,
                errors: null,
                statusCode: null,
            }

        case types.UPDATE_USER_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.errors,
                statusCode: action.statusCode,
            }
        default:
            return state
    }
}
