import 'moment-timezone'

export const swedenTimeZone = 'Europe/Stockholm'
export const denmarkTimeZone = 'Europe/Copenhagen'
export const finlandTimeZone = 'Europe/Helsinki'
export const netherlandsTimeZone = 'Europe/Amsterdam'
export const belgiumTimeZone = 'Europe/Brussels'
export const germanyTimeZone = 'Europe/Berlin'
export const norwayTimeZone = 'Europe/Oslo'

export default function mapTimeZoneToCountry(countryCode) {
    let timezone
    switch (countryCode.toUpperCase()) {
        case 'SE':
            timezone = swedenTimeZone
            break
        case 'FI':
            timezone = finlandTimeZone
            break
        case 'DK':
            timezone = denmarkTimeZone
            break
        case 'NL':
            timezone = netherlandsTimeZone
            break
        case 'BE':
            timezone = belgiumTimeZone
            break
        case 'DE':
            timezone = germanyTimeZone
            break
        case 'NO':
            timezone = norwayTimeZone
            break
        default:
            return new Error(`Country '${countryCode}' not supported`)
    }
    return timezone
}
