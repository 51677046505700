import React from 'react'
import { Alert } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {
    defaultIfEmpty,
    getTimeDuration,
    getTime,
    OPERATOR_GROUPS,
    PRICE_TYPES,
    VEHICLE_INSPECTION_TIME_PER_ROUTE,
    MIN_HOURS_PER_ROUTE,
} from '../../../utils/couriers'
import { formatDate } from '../../../utils/date'

const TierPreview = (props) => {
    const cities = defaultIfEmpty(
        props.allCitiesApply ? 'all cities' : props.cities.map((x) => x.label).join(', '),
        '[cities]',
    )

    let days = defaultIfEmpty(props.daysOfWeek == null ? 'all days' : props.daysOfWeek.join(', '), '[days]')

    if (props.specialDay) {
        days = 'special days'

        if (props.validFrom && props.validTo) {
            days += ` ${formatDate(props.validFrom)} to ${formatDate(props.validTo)}`
        } else {
            days += ' days'
        }
    }

    const time = defaultIfEmpty(
        props.allTimesApply ? 'all times,' : getTimeDuration(getTime(props.from), getTime(props.to)),
        '[times]',
    )
    const driverLevels = defaultIfEmpty(
        props.allDriverLevelsApply ? ' all levels' : props.driverLevels.map((x) => x.label).join(', '),
        '[levels]',
    )
    const currency = props.currency ? props.currency : '[currency]'
    const type =
        props.type.price_type === PRICE_TYPES.FIXED ? ' in fixed compensation per ' : ' in incremental price per '

    let rate = ''
    if (props.type.operator === OPERATOR_GROUPS.MULTIPLICATIVE) {
        rate = `${props.rate} % per unit ${currency} `
    } else if (props.type.id === VEHICLE_INSPECTION_TIME_PER_ROUTE) {
        rate = `${props.rate} minutes `
    } else if (props.type.id === MIN_HOURS_PER_ROUTE) {
        rate = `${props.rate} hours `
    } else {
        rate = `${props.rate} ${currency} `
    }

    let bounds = ''

    if (props.lowerBound || props.upperBound) {
        bounds += ' between '
        if (props.lowerBound) {
            bounds += `${props.lowerBound} `
        } else {
            bounds += '0 '
        }
        if (props.upperBound) {
            bounds += `and ${props.upperBound} `
        } else {
            bounds += 'and infinity '
        }
    }

    const preview = `In ${cities} 
        on ${days} 
        at ${time} 
        each driver of ${driverLevels} 
        will receive ${rate} 
        ${bounds}
        ${type} ${props.type.price_variable.toLowerCase()}`
    return (
        <Alert bsStyle="info">
            <strong>Preview</strong> <br />
            <i>{preview}</i>
        </Alert>
    )
}

export default TierPreview

TierPreview.propTypes = {
    allCitiesApply: PropTypes.bool.isRequired,
    cities: PropTypes.array,
    daysOfWeek: PropTypes.array.isRequired,
    allTimesApply: PropTypes.bool.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
    allDriverLevelsApply: PropTypes.bool.isRequired,
    driverLevels: PropTypes.array,
    rate: PropTypes.any,
    currency: PropTypes.string.isRequired,
    type: PropTypes.object.isRequired,
    specialDay: PropTypes.bool.isRequired,
    validFrom: PropTypes.object,
    validTo: PropTypes.object,
    lowerBound: PropTypes.any,
    upperBound: PropTypes.any,
}
