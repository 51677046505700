import React from 'react'
import PropTypes from 'prop-types'
import { STOP_TYPE } from '../../../utils/recurring-pickups'
import MerchantStop from './merchant-stop'
import TerminalStop from './terminal-stop'
import RestStop from './rest-stop'
import { Button, ButtonGroup, ButtonToolbar, Glyphicon, Panel } from 'react-bootstrap'

const noMerchantLocations = (stopType, multipleLocationsOptions) => stopType === STOP_TYPE.MERCHANT && multipleLocationsOptions.length === 0

const RecurringStopType = ({
    isReadOnly,
    type,
    pickup,
    sortOrder,
    isOneTimeRoute,
    onTimeChange,
    onPickupOptionChange,
    onPickupChange,
    onTerminalChange,
    multipleLocationsOptions,
}) => {
    if (type === STOP_TYPE.MERCHANT) {
        return (
            <MerchantStop
                isReadOnly={isReadOnly}
                sortOrder={sortOrder}
                id={pickup.merchantScheduleId}
                onPickupChange={onPickupChange}
                multipleLocationsOptions={multipleLocationsOptions}
                startTime={pickup.startTime}
                endTime={pickup.endTime}
                isOneTimeRoute={isOneTimeRoute}
                pallets={pickup.pallets}
                pickup={pickup.pickup}
                returns={pickup.returns}
                collectionPointDeleted={pickup.collectionPointDeleted}
                collectionPointName={pickup.collectionPointName}
                onTimeChange={onTimeChange}
                onPickupOptionChange={onPickupOptionChange}
            />
        )
    }
    if (type === STOP_TYPE.TERMINAL) {
        return (
            <TerminalStop
                isReadOnly={isReadOnly}
                sortOrder={sortOrder}
                terminalId={pickup.terminalId}
                onTerminalChange={onTerminalChange}
                startTime={pickup.startTime}
                endTime={pickup.endTime}
                onTimeChange={onTimeChange}
            />
        )
    }
    if (type === STOP_TYPE.REST) {
        return (
            <RestStop
                isReadOnly={isReadOnly}
                sortOrder={sortOrder}
                startTime={pickup.startTime}
                endTime={pickup.endTime}
                onTimeChange={onTimeChange}
            />
        )
    }

    console.error(`Unknown stop type '${type}'`)
    return <div>Unknown stop type</div>
}

const RecurringStop = ({
    isReadOnly,
    pickup,
    sortOrder,
    isOneTimeRoute,
    onTimeChange,
    onPickupOptionChange,
    onPickupChange,
    onTerminalChange,
    multipleLocationsOptions,
    remove,
    moveUp,
    moveDown,
    totalStops,
}) => (
    <div>
        <Panel>
            <Button
                disabled={isReadOnly}
                bsStyle="link"
                bsSize="xsmall"
                className="pull-right"
                style={{ marginRight: '-7px', marginTop: '-7px' }}
                onClick={remove}
            >
                <Glyphicon glyph="remove" className="text-danger" />
            </Button>
            <RecurringStopType
                isReadOnly={isReadOnly}
                type={pickup.type}
                pickup={pickup}
                sortOrder={sortOrder}
                isOneTimeRoute={isOneTimeRoute}
                onPickupChange={onPickupChange}
                onPickupOptionChange={onPickupOptionChange}
                onTerminalChange={onTerminalChange}
                onTimeChange={onTimeChange}
                multipleLocationsOptions={multipleLocationsOptions}
            />
            <ButtonToolbar bsClass="pull-right">
                <ButtonGroup>
                    <Button
                        bsStyle="link"
                        onClick={moveUp}
                        disabled={
                            isReadOnly || noMerchantLocations(pickup.type, multipleLocationsOptions.length) || sortOrder === 0 || totalStops === 1
                        }
                    >
                        <Glyphicon glyph="circle-arrow-up" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button
                        bsStyle="link"
                        onClick={moveDown}
                        disabled={
                            isReadOnly ||
                            noMerchantLocations(pickup.type, multipleLocationsOptions.length) ||
                            totalStops === 1 ||
                            sortOrder + 1 === totalStops
                        }
                    >
                        <Glyphicon glyph="circle-arrow-down" />
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
        </Panel>
    </div>
)

export default RecurringStop
