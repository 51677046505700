import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Modal, FormGroup, ControlLabel, Button } from 'react-bootstrap'
import Select from 'react-select'

const REASONS = [
    { value: 'TOO_LARGE_TO_DELIVER', label: 'Too large to deliver' },
    { value: 'MAXIMUM_CAPACITY_REACHED', label: 'Maximum capacity reached' },
    { value: 'DISABLED_LOCKER', label: 'Disabled locker' },
    { value: 'OTHER', label: 'Other' },
]

export default class CovertToHomeModal extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            reason: '',
        }
    }

    submitIsDisabled = () => {
        return this.state.reason === ''
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Convert to home delivery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <ControlLabel>Reason</ControlLabel>
                            <Select
                                placeholder="Select a reason"
                                value={this.state.reason}
                                options={REASONS}
                                onChange={(reason) => this.setState({ reason: reason.value })}
                                clearable={false}
                                ignoreCase
                            />
                        </FormGroup>
                    </form>
                    <p>Are you sure you want to convert this order to home delivery from box delivery?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button
                        bsStyle="danger"
                        disabled={this.submitIsDisabled()}
                        onClick={() => this.props.onSuccess(this.state.reason)}
                    >
                        Yes, convert to home
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
CovertToHomeModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
}
