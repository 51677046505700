import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Glyphicon } from 'react-bootstrap'
import Loader from 'react-loader'
import * as transitActions from '../../../../actions/collection-points'
import CreateModal from './modal'

class ManageTransitPoints extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
        }
    }

    addTransitsData = (zonelist, tPoints) => {
        const route = {
            postalCodeZoneIds: zonelist,
            transits: tPoints.map((point, i) => ({
                warehouseId: point.warehouseId,
                sequence: i + 1,
            })),
        }
        this.props.transitActions.addTransitsData(route, this.props.cid)
    }

    saveTransitsData = () => this.props.transitActions.saveTransitsRoute(this.props.cid)

    storeTransitsData = () => this.props.storeTransitsData()

    transitReset = () => {
        this.props.transitActions.transitReset(this.props.cid)
        this.toggleModal()
    }

    transitResetData = () => {
        this.props.transitActions.transitResetData(this.props.cid)
        this.toggleModal()
    }

    toggleModal = () => {
        this.props.transitActions.transitResetData()
        this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }))
    }

    render() {
        if (this.props.loading || this.props.postalcodes == null) {
            return <Loader color="#BFBFBF" />
        }

        const usedZones = []
        this.props.transitsData
            .filter((col) => col.cid === this.props.cid)
            .forEach((cp) => {
                cp.transits.forEach((z) => {
                    z.postalCodeZoneIds.forEach((c) => {
                        usedZones.push(c)
                    })
                })
            })

        let transits = this.props.transits.filter((col) => col.cid === this.props.cid)
        if (transits.length > 0) {
            ;[{ transits }] = transits
        }

        return (
            <div>
                {this.props.canEdit ? (
                    <div>
                        <button type="button" className="btn btn-default margin-btn right" onClick={this.toggleModal}>
                            <i className="glyphicon glyphicon-plus" /> Create Transit Point
                        </button>
                    </div>
                ) : null}
                <div>
                    <Loader color="#449d44" loaded={!this.props.saving} />
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                {this.props.canEdit ? <th>Remove</th> : null}
                                <th>Postalcode Zone</th>
                                <th>transits</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transits.map((transit, index) => {
                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <tr key={index}>
                                        {this.props.canEdit ? (
                                            <td>
                                                <Button
                                                    bsStyle="danger"
                                                    bsSize="xsmall"
                                                    onClick={() => this.props.removeTransitRoute(index)}
                                                >
                                                    <Glyphicon glyph="trash" />
                                                </Button>
                                            </td>
                                        ) : null}
                                        <td>
                                            {transit.postCodes.map((pc, pcIndex) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <span key={pc.label + pcIndex} className={pc.class}>
                                                    {pc.label}
                                                </span>
                                            ))}
                                        </td>
                                        <td>
                                            {transit.transPoints.map((tp, tpIndex) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <span key={tp.label + tpIndex} className={tp.class}>
                                                    {tp.label}
                                                </span>
                                            ))}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {this.props.canEdit ? (
                    <CreateModal
                        show={this.state.modalOpen}
                        success={this.props.success}
                        added={this.props.added}
                        msg={this.props.msg}
                        transitReset={this.transitReset}
                        transitResetData={this.transitResetData}
                        toggle={this.toggleModal}
                        postalcodes={this.props.postalcodes.filter((pc) => !usedZones.includes(pc.id))}
                        saveTransitsData={this.saveTransitsData}
                        storeTransitsData={this.storeTransitsData}
                        warehouses={this.props.warehouses}
                        addTransitsData={this.addTransitsData}
                    />
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.collection.loading,
    saving: state.collection.saving,
    added: state.collection.added,
    success: state.collection.success,
    msg: state.collection.msg,
})

const mapDispatchToProps = (dispatch) => ({
    transitActions: bindActionCreators(transitActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageTransitPoints)

ManageTransitPoints.propTypes = {
    transitActions: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    storeTransitsData: PropTypes.func,
    cid: PropTypes.number,
    transitsData: PropTypes.array,
    transits: PropTypes.array,
    canEdit: PropTypes.bool,
    saving: PropTypes.bool,
    success: PropTypes.bool,
    added: PropTypes.bool,
    msg: PropTypes.node,
    postalcodes: PropTypes.array,
    warehouses: PropTypes.array,
    removeTransitRoute: PropTypes.func,
}
