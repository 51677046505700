import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Label, Table } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export default class LockerConsignments extends Component {
    renderMissOrCancellation(header, color, data) {
        const { countryCode } = this.props
        return (
            <div>
                <b style={{ color }}>{header}</b>
                <br />
                {moment(data.date)
                    .tz(mapTimeZoneToCountry(countryCode))
                    .format(DATE_TIME_FORMAT)}
                <br />
                {data.cancellationCategory ? data.cancellationCategory : null}
                {data.reason ? data.reason : null}
                <br />
                <span style={{ color: 'grey' }}>{data.comment}</span>
            </div>
        )
    }

    remarks({ cancellation, miss }) {
        if (cancellation == null && miss == null) {
            return ''
        }

        if (cancellation != null) {
            return this.renderMissOrCancellation('Cancelled', 'red', cancellation)
        }

        return this.renderMissOrCancellation('Missed', 'orange', miss)
    }

    renderLockerConsignments() {
        const { lockerConsignments } = this.props

        if (lockerConsignments == null || lockerConsignments.length === 0) {
            return null
        }

        return (
            <Table striped condensed>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Locker</th>
                        <th>Estimated interval</th>
                        <th>Parcel ID</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {lockerConsignments.map((lockerConsignment) => this.renderLockerConsignment(lockerConsignment))}
                </tbody>
            </Table>
        )
    }

    renderLockerConsignment = (consignment) => {
        return (
            <tr key={consignment.id}>
                <td>{consignment.id}</td>
                <td>{consignment.consignmentType}</td>
                <td>
                    {consignment.locker.name} <Label bsStyle="info">{consignment.locker.identifier}</Label>
                </td>
                <td>
                    {`${moment(consignment.estimatedInterval.start.date)
                        .tz(consignment.estimatedInterval.start.timeZone)
                        .format(DATE_TIME_FORMAT)}
                    - ${moment(consignment.estimatedInterval.stop.date)
                        .tz(consignment.estimatedInterval.stop.timeZone)
                        .format(DATE_TIME_FORMAT)}`}
                </td>
                <td>{consignment.parcel != null ? consignment.parcel.id : null}</td>
                <td>{this.remarks(consignment)}</td>
            </tr>
        )
    }

    render() {
        return this.renderLockerConsignments()
    }
}

LockerConsignments.propTypes = {
    lockerConsignments: PropTypes.array.isRequired,
    countryCode: PropTypes.string.isRequired,
}
