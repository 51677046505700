export const HUB_PALLETS_SAVE_STATUS = {
    UNCHANGED: 'UNCHANGED',
    NOT_SAVED: 'NOT_SAVED',
    SAVING: 'SAVING',
    SAVED: 'SAVED',
    ERROR: 'ERROR',
}

export const SAVE_DISABLED_STATUSES = new Set([
    HUB_PALLETS_SAVE_STATUS.UNCHANGED,
    HUB_PALLETS_SAVE_STATUS.SAVING,
    HUB_PALLETS_SAVE_STATUS.SAVED,
])

export const HUB_PALLETS_LOAD_STATUS = {
    NOT_LOADED: 'NOT_LOADED',
    LOADED: 'LOADED',
    ERROR: 'ERROR',
}

export const SETTINGS_TYPE = {
    TERMINAL: 'TERMINAL',
    REGION: 'REGION',
}

export const HTTP_STATUS_NOT_FOUND = 404
