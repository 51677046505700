import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

const DeleteTierModal = ({ onCancel, onDelete, deleting, count }) => (
    <Modal bsSize="sm" show onHide={onCancel}>
        <Modal.Header>
            <Modal.Title className="text-capitalize">Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            You are about to remove {count} compensation tier{count > 1 ? 's' : ''}
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onCancel}>No</Button>
            <Button onClick={onDelete} bsStyle="primary" disabled={deleting}>
                {deleting ? 'Deleting...' : `Yes, delete ${count > 1 ? 'them' : 'it'}!`}
            </Button>
        </Modal.Footer>
    </Modal>
)

export default DeleteTierModal

DeleteTierModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    deleting: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
}
