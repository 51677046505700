import React from 'react'
import ReactDOM from 'react-dom'
import acc from 'accounting'
import moment from 'moment'
import mapType from '../../utils/map-price-point-type'

export default class PricePoint extends React.Component {
    getChecked() {
        return ReactDOM.findDOMNode(this.refs.checkbox).checked
    }

    getValue() {
        return this.props.data.pricePoint.id
    }

    copy() {
        return {
            postalCodeZoneId: this.props.postalcodeZone.id,
            pricePoint: {
                price: {
                    amount: this.props.data.pricePoint.price.amount,
                    currency: this.props.data.pricePoint.price.currency,
                },
                type: this.props.data.pricePoint.type,
            },
        }
    }

    setChecked(value) {
        ReactDOM.findDOMNode(this.refs.checkbox).checked = value
    }

    render() {
        const { data, postalcodeZone } = this.props

        return (
            <tr>
                <td>
                    <input type="checkbox" ref="checkbox" />
                </td>
                <td>{mapType.toLabel(data.pricePoint.type)}</td>
                <td>{postalcodeZone != null ? postalcodeZone.title : '(deleted zone)'}</td>
                <td>
                    {acc.formatMoney(data.pricePoint.price.amount / 100, {
                        symbol: data.pricePoint.price.currency.code,
                        decimal: ',',
                        thousand: ' ',
                        format: '%v %s',
                    })}
                </td>
                <td>
                    {data.pricePoint.validFrom != null ? moment(data.pricePoint.validFrom).format('YYYY-MM-DD') : null}
                </td>
                <td>{data.pricePoint.validTo != null ? moment(data.pricePoint.validTo).format('YYYY-MM-DD') : null}</td>
            </tr>
        )
    }
}
