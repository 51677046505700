import Webapi from './webapi'
import auth from '../auth'

const V1 = 'application/vnd.budbee.routes-v1+json'
const V3 = 'application/vnd.budbee.routes-v3+json'
const V4 = 'application/vnd.budbee.routes-v4+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(V3)
        .contentType(V3)
        .path('routes')

export const searchRoutes = (queryData) =>
    webapi()
        .path('search')
        .post(queryData)

export const getRoute = (id) =>
    webapi()
        .path(id)
        .get()

export const getSchedulableDates = () =>
    webapi()
        .path('incomplete/dates')
        .get()

export const getSchedulableRoutes = (req) =>
    webapi()
        .accept(V4)
        .contentType(V4)
        .path('incomplete')
        .post(req)

export const assignDrivers = (request) =>
    webapi()
        .path('assign')
        .post(request)

export const getStopPositionsForRoute = (routeId) =>
    webapi()
        .path(routeId)
        .path('positions')
        .get()

export const getSingleTraffix = (id) =>
    webapi()
        .path('traffix')
        .path(id)
        .get()

export const routeCompleted = (id) =>
    webapi()
        .path('done')
        .path(id)
        .put()

export const getSplitReasons = () =>
    webapi()
        .path('split-reasons')
        .get()

export const extractStops = (
    routeId,
    originalRouteMeetupSequence,
    when,
    address,
    stopIds,
    splitReason,
    responsibleParty,
) =>
    webapi()
        .path('move-stops')
        .post({
            fromRoute: routeId,
            originalRouteMeetupSequence,
            when,
            address,
            stopIdsToMove: stopIds,
            splitReason,
            responsibleParty,
        })

export const getTodaysIncompleteEcommerceRoutes = (terminalId) =>
    webapi()
        .path('sorting/e-commerce')
        .queryParam('terminal', terminalId)
        .get()

export const getOnDemandRoutes = (terminalId, merchantId) =>
    webapi()
        .path('sorting/on-demand-routes')
        .queryParam('terminal', terminalId)
        .queryParam('merchant', merchantId)
        .get()

export const getLockerStops = (routeId) =>
    webapi()
        .accept(V1)
        .contentType(V1)
        .path(routeId)
        .path('sorting/locker-stops')
        .get()

export const getLockerRoutes = (terminalId) =>
    webapi()
        .accept(V1)
        .contentType(V1)
        .path('sorting/locker')
        .queryParam('terminal', terminalId)
        .get()

export const listCurrentRouteSortingStatus = (routeId) =>
    webapi()
        .path(routeId)
        .path('list-current-route-sorting-status')
        .get()

export const getRouteAnalysis = (routeId) =>
    webapi()
        .path(routeId)
        .path('analysis')
        .get()

export const cancelRoute = (routeId) =>
    webapi()
        .accept(V1)
        .contentType(V1)
        .path(routeId)
        .path(`cancel`)
        .delete()
