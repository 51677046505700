import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, ControlLabel, Form, FormGroup, Glyphicon, InputGroup } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Select from 'react-select'
import * as merchantActions from '../../../actions/merchants'
import * as regionalSettingsActions from '../../../actions/regional-settings'
import empty from '../../../utils/empty'
import AlertGroup from '../../common/alert-group'
import { getMerchantTags, setLogo, uploadThumbnailLogo } from '../../../utils/merchant-webapi'
import { handleError } from '../../../utils/handle-error'
import Confirm from '../../confirm'

export const supportTypes = {
    email: {
        name: 'Email',
        type: 'email',
        controlId: 'supportEmail',
        label: 'Merchant Support email address (that consumers can email for assistance)',
    },
    phone: {
        name: 'Telephone',
        type: 'tel',
        controlId: 'supportPhone',
        label: 'Merchant Support phone number (that consumers can dial for assistance)',
    },
    url: {
        name: 'URL',
        type: 'url',
        controlId: 'supportLink',
        label: 'Merchant Support website (that consumers can visit for assistance)',
    },
}

const mapStateToProps = (state) => ({
    merchant: state.buyers.buyer,
    loading: state.buyers.loading,
    updated: state.buyers.updated,
    error: state.buyers.error,
    allCountries: state.regionalSettings.countries,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...merchantActions, ...regionalSettingsActions }, dispatch),
})

export default (WrappedComponent) => {
    class EditMerchant extends Component {
        constructor(props) {
            super(props)

            const { merchant } = props

            const addedSupportTypesCountrySpecific = {}
            merchant.merchantSettings.forEach((settings) => {
                addedSupportTypesCountrySpecific[settings.countryCode] = this.getExistingSupportTypes(settings)
            })
            this.state = {
                allCountries: [],
                merchant: { ...merchant },
                addedSupportTypes: this.getExistingSupportTypes(merchant.buyerSettings),
                addedSupportTypesCountrySpecific,
                showDeleteCustomSettingsModal: false,
                countrySettingsToBeDeleted: null,
                formChanged: false,
                showAlert: false,
            }
        }

        componentDidMount() {
            this.fetchMerchantTags()
            this.props.actions.fetchRegionalSettings()
        }

        componentWillReceiveProps({ allCountries, updated, error }) {
            this.setState({
                allCountries,
                showAlert: updated || error,
            })

            if (updated) {
                setTimeout(() => {
                    this.setState({
                        showAlert: false,
                    })
                }, 3000)
            }
        }

        getExistingSupportTypes = (settings) => {
            const settingsKeys = Object.keys(settings)
            const existingSupportTypes = []

            Object.keys(supportTypes).forEach((key) => {
                const { controlId } = supportTypes[key]
                if (settingsKeys.includes(controlId) && settings[controlId] != null) {
                    existingSupportTypes.push(key)
                }
            })
            return existingSupportTypes
        }

        fetchMerchantTags = () => {
            const { merchant } = this.props
            getMerchantTags(merchant.id)
                .then((response) => {
                    const buyerTagIds = response.map((x) => x.id)
                    this.setState({ merchant: { ...merchant, buyerTagIds } })
                })
                .catch(handleError)
        }

        addSettingsBasedOnCountry = (countryCode) => {
            const { merchant } = this.state

            // If settings are present for this country, do nothing
            const existingSettingsForCountries = merchant.merchantSettings.map((settings) => settings.countryCode)
            if (existingSettingsForCountries.includes(countryCode)) {
                return
            }

            const { logo, externalName, buyerSettings } = merchant

            const newSettingsBatch = {
                countryCode,
                logo,
                externalName,
                supportEmail: buyerSettings.supportEmail,
                supportLink: buyerSettings.supportLink,
                supportPhone: buyerSettings.supportPhone,
                selectIntervalInCheckout: buyerSettings.selectIntervalInCheckout,
                allowedToCreateDeliveryAttemptsAfterCutoff: buyerSettings.allowedToCreateDeliveryAttemptsAfterCutoff,
                offerReturns: buyerSettings.offerReturns,
                offerReturnFromBuyers: buyerSettings.offerReturnFromBuyers,
                defaultReturnTypeToDeliveryMethod: buyerSettings.defaultReturnTypeToDeliveryMethod,
                defaultReturnTypeToBox: buyerSettings.defaultReturnTypeToBox,
                onDemandPickup: buyerSettings.onDemandPickup,
                allowConsumerToChangeDate: buyerSettings.allowConsumerToChangeDate,
                allowConsumerToEditOutsideDoor: buyerSettings.allowConsumerToEditOutsideDoor,
                signatureRequired: buyerSettings.signatureRequired,
                identificationCheckRequired: buyerSettings.identificationCheckRequired,
                deliveryMode: buyerSettings.deliveryMode,
                useGeneralSettingsLogo: true,
                alternativelyLeaveWithNeighbour:
                    countryCode === 'NL' ? buyerSettings.alternativelyLeaveWithNeighbour : false,
                consolidationSupport: buyerSettings.consolidationSupport,
                hasTimeslots: buyerSettings.hasTimeslots,
            }

            this.setState((prevState) => ({
                merchant: {
                    ...prevState.merchant,
                    merchantSettings: [...prevState.merchant.merchantSettings, newSettingsBatch],
                },
                addedSupportTypesCountrySpecific: {
                    ...prevState.addedSupportTypesCountrySpecific,
                    [countryCode]: this.getExistingSupportTypes(merchant.buyerSettings),
                },
                formChanged: true,
                showAlert: false,
            }))
        }

        changeSettings = (value, name, buyerSettingsLevel = false) => {
            this.setState((prevState) =>
                buyerSettingsLevel
                    ? {
                          merchant: {
                              ...prevState.merchant,
                              buyerSettings: {
                                  ...prevState.merchant.buyerSettings,
                                  [name]: value,
                              },
                          },
                          formChanged: true,
                          showAlert: false,
                      }
                    : {
                          merchant: {
                              ...prevState.merchant,
                              [name]: value,
                          },
                          formChanged: true,
                          showAlert: false,
                      },
            )
        }

        changeSettingsBasedOnCountry = (value, name, countryCode) => {
            this.setState((prevState) => ({
                merchant: {
                    ...prevState.merchant,
                    merchantSettings: prevState.merchant.merchantSettings.map((settings) =>
                        settings.countryCode === countryCode ? { ...settings, [name]: value } : settings,
                    ),
                },
                formChanged: true,
                showAlert: false,
            }))
        }

        deleteSettingsBasedOnCountry = (countryCode) => {
            const { merchant } = this.state
            this.changeSettings(
                merchant.merchantSettings.filter((country) => country.countryCode !== countryCode),
                'merchantSettings',
            )
        }

        onAddSupportType = (option, countryCode) => {
            if (countryCode) {
                const { addedSupportTypesCountrySpecific } = this.state
                if (!addedSupportTypesCountrySpecific[countryCode].includes(option.value)) {
                    this.setState((prevState) => ({
                        addedSupportTypesCountrySpecific: {
                            ...prevState.addedSupportTypesCountrySpecific,
                            [countryCode]: [...prevState.addedSupportTypesCountrySpecific[countryCode], option.value],
                        },
                    }))
                }
            } else {
                const { addedSupportTypes } = this.state
                if (!addedSupportTypes.includes(option.value)) {
                    this.setState((prevState) => ({
                        addedSupportTypes: [...prevState.addedSupportTypes, option.value],
                    }))
                }
            }
        }

        onAddSettingsBasedOnCountryChange = (selected) => {
            const country = selected.value
            this.addSettingsBasedOnCountry(country)
        }

        onChange = (prop, name) => (e) => {
            const value = e.target[prop]
            this.changeSettings(value, name)
        }

        onSettingsChange = (prop, name) => (e) => {
            this.changeSettings(e.target[prop], name, true)
        }

        onChangeSettingsBasedOnCountry = (prop, name, countryCode) => (e) => {
            const value = e.target[prop]
            this.changeSettingsBasedOnCountry(value, name, countryCode)
        }

        onRemoveSupportType = (value, countryCode) => {
            const { addedSupportTypesCountrySpecific } = this.state

            if (countryCode) {
                this.setState((prevState) => ({
                    addedSupportTypesCountrySpecific: {
                        ...prevState.addedSupportTypesCountrySpecific,
                        [countryCode]: addedSupportTypesCountrySpecific[countryCode].filter(
                            (supportType) => supportType !== value,
                        ),
                    },
                    merchant: {
                        ...prevState.merchant,
                        merchantSettings: prevState.merchant.merchantSettings.map((settings) => {
                            return settings.countryCode === countryCode
                                ? { ...settings, [supportTypes[value].controlId]: null }
                                : settings
                        }),
                    },
                    formChanged: true,
                    showAlert: false,
                }))
            } else {
                this.setState(
                    (prevState) => ({
                        addedSupportTypes: prevState.addedSupportTypes.filter((supportType) => supportType !== value),
                        merchant: {
                            ...prevState.merchant,
                            buyerSettings: {
                                ...prevState.merchant.buyerSettings,
                                [supportTypes[value].controlId]: null,
                            },
                        },
                        formChanged: true,
                        showAlert: false,
                    }),
                    () => this.changeSettings(null, supportTypes[value].controlId),
                )
            }
        }

        onDeleteSettingsBasedOnCountry = (countryCode) => {
            this.setState({
                countrySettingsToBeDeleted: countryCode,
                showDeleteCustomSettingsModal: true,
            })
        }

        renderSettingsBasedOnCountriesHeader = (country, settings) => {
            return (
                <FormGroup className="country-based-settings-header">
                    <Col componentClass={ControlLabel} sm={3}>
                        {country.label}
                    </Col>
                    <Col sm={6}>
                        <Glyphicon glyph="glyphicon glyphicon-exclamation-sign" /> These settings override the
                        <b> General Settings</b> above!
                    </Col>
                    <Col sm={3}>
                        <Button
                            bsStyle="danger"
                            style={{ float: 'right' }}
                            onClick={() => this.onDeleteSettingsBasedOnCountry(settings.countryCode)}
                        >
                            <Glyphicon glyph="trash" /> Remove
                        </Button>
                    </Col>
                </FormGroup>
            )
        }

        renderSettingsBasedOnCountriesSelect = () => {
            const { allCountries } = this.props
            const { merchant } = this.state

            const countryCodesOfCustomSettings = merchant.merchantSettings.map((settings) => settings.countryCode)
            const customizableCountries = allCountries.filter(
                (country) =>
                    !countryCodesOfCustomSettings.includes(country.id) &&
                    merchant.availableMarkets.includes(country.id),
            )

            return (
                customizableCountries.length > 0 && (
                    <FormGroup
                        className="country-based-settings-select"
                        controlId="countriesAvailableForCustomSettings"
                    >
                        <Col sm={8} smOffset={3}>
                            <InputGroup>
                                <InputGroup.Addon>
                                    <Glyphicon glyph="plus" />
                                </InputGroup.Addon>
                                <Select
                                    placeholder="Add market specific settings ..."
                                    options={customizableCountries.map((country) => ({
                                        value: country.id,
                                        label: country.label,
                                    }))}
                                    onChange={this.onAddSettingsBasedOnCountryChange}
                                    allowCreate={false}
                                    searchable={false}
                                    multi={false}
                                />
                            </InputGroup>
                        </Col>
                    </FormGroup>
                )
            )
        }

        renderDeleteConfirmCustomSettingsConfirmation() {
            const { showDeleteCustomSettingsModal, countrySettingsToBeDeleted, allCountries } = this.state
            const country = allCountries.find((c) => c.id === countrySettingsToBeDeleted)
            return (
                country && (
                    <Confirm
                        title={`Deleting custom settings for ${country.label}!`}
                        show={showDeleteCustomSettingsModal}
                        onClose={() => {
                            this.setState({
                                countrySettingsToBeDeleted: null,
                                showDeleteCustomSettingsModal: false,
                            })
                        }}
                        onConfirm={() => this.deleteSettingsBasedOnCountry(country.id)}
                    >
                        Are you sure you want to delete custom settings for <b>{country.label}</b>? Doing so would
                        delete both the general and distribution settings for <b>{country.label}</b>!
                    </Confirm>
                )
            )
        }

        uploadImage = (file, countryCode) =>
            setLogo(file)
                .then((resp) => {
                    this.changeSettingsBasedOnCountry(resp, 'logo', countryCode)
                    return { logo: resp, countryCode }
                })
                .catch(handleError)

        /*
          Callback invoked when a User has selected an image file for either
          the global primary logo, or a market specific primary logo.
          This method will upload the file, and update the state with the new
          logo url.
        */
        onUpload = ([file], countryCode = null) => {
            if (countryCode != null) {
                this.uploadMarketPrimaryLogo(file, countryCode)
            } else {
                this.uploadGlobalPrimaryLogo(file)
            }
        }

        uploadGlobalPrimaryLogo = (file) => {
            if (file == null) {
                return
            }

            setLogo(file).then((response) => {
                this.changeSettings(response.url, 'logoPreview')
                this.changeSettings(response, 'logo')
            })
        }

        uploadMarketPrimaryLogo = (file, countryCode) => {
            if (file == null) {
                return
            }

            setLogo(file).then((response) => {
                this.changeSettingsBasedOnCountry(response.url, 'logoPreview', countryCode)
                this.changeSettingsBasedOnCountry(response, 'logo', countryCode)
                this.changeSettingsBasedOnCountry(false, 'useGeneralSettingsLogo', countryCode)
            })
        }

        onUploadThumbnailLogo = ([file], countryCode) => {
            if (file == null) {
                return
            }

            const { merchant } = this.state

            uploadThumbnailLogo(merchant.id, file).then((response) => {
                this.changeSettingsBasedOnCountry(response, 'thumbnailLogo', countryCode)
            })
        }

        onSubmit = (e) => {
            e.preventDefault()
            const { merchant, formChanged } = this.state

            if (!formChanged) {
                return
            }

            const updatedMerchant = {
                name: merchant.name,
                externalName: merchant.externalName,
                buyerTagIds: merchant.buyerTagIds,
                whitelistedDomains: merchant.whitelistedDomains,
                shippingCosts: merchant.shippingCosts,
                settingsAllMarkets: merchant.merchantSettings.length === 0,
                logo: merchant.logo,
                merchantSettings: merchant.merchantSettings.map((settings) => {
                    const usingSameLogo = settings.fileRef == null
                    // eslint-disable-next-line no-unused-vars
                    const { logoPreview, fileRef, ...other } = settings
                    return { ...other, useGeneralSettingsLogo: usingSameLogo }
                }),
                buyerSettings: merchant.buyerSettings,
            }

            this.setState({ formChanged: false }, () => {
                this.props.actions.updateMerchant(merchant.id, updatedMerchant)
            })
        }

        validate = () => {
            const { merchant } = this.state
            const { name, externalName, merchantSettings } = merchant
            const { supportEmail, supportPhone, supportLink } = merchant.buyerSettings
            const generalSettingsAreValid =
                (supportEmail || supportPhone || supportLink) && [name, externalName].every((v) => !empty(v))

            const countryBasedSettingsValidity = merchantSettings.map((settings) => {
                const {
                    externalName: countryBasedExternalName,
                    supportEmail: countryBasedSupportEmail,
                    supportPhone: countryBasedSupportPhone,
                    supportLink: countryBasedSupportLink,
                } = settings
                return (
                    (countryBasedSupportEmail || countryBasedSupportPhone || countryBasedSupportLink) &&
                    !empty(countryBasedExternalName)
                )
            })

            return generalSettingsAreValid && countryBasedSettingsValidity.every(Boolean)
        }

        render() {
            const { updated, error } = this.props
            const {
                merchant,
                addedSupportTypes,
                addedSupportTypesCountrySpecific,
                allCountries,
                showAlert,
            } = this.state
            return (
                <Form horizontal onSubmit={this.onSubmit}>
                    <WrappedComponent
                        merchant={merchant}
                        allCountries={allCountries}
                        merchantSettings={merchant.buyerSettings}
                        onChange={this.onChange}
                        onChangeSettingsBasedOnCountry={this.onChangeSettingsBasedOnCountry}
                        changeSettingsBasedOnCountry={this.changeSettingsBasedOnCountry}
                        changeSettings={this.changeSettings}
                        onSettingsChange={this.onSettingsChange}
                        onRemoveSupportType={this.onRemoveSupportType}
                        onAddSupportType={this.onAddSupportType}
                        onUpload={this.onUpload}
                        onUploadThumbnailLogo={this.onUploadThumbnailLogo}
                        renderSettingsBasedOnCountriesHeader={this.renderSettingsBasedOnCountriesHeader}
                        renderSettingsBasedOnCountriesSelect={this.renderSettingsBasedOnCountriesSelect}
                        addedSupportTypes={addedSupportTypes}
                        addedSupportTypesCountrySpecific={addedSupportTypesCountrySpecific}
                    />
                    {this.renderSettingsBasedOnCountriesSelect()}
                    <FormGroup>
                        <Col sm={9} smOffset={3}>
                            {showAlert && <AlertGroup statusCode={updated ? 200 : error} />}
                            <Button type="submit" bsStyle="primary" disabled={!this.validate()}>
                                Save
                            </Button>
                        </Col>
                    </FormGroup>
                    {this.renderDeleteConfirmCustomSettingsConfirmation()}
                </Form>
            )
        }
    }

    EditMerchant.propTypes = {
        actions: PropTypes.object.isRequired,
        merchant: PropTypes.object.isRequired,
        updated: PropTypes.bool.isRequired,
        error: PropTypes.any,
        allCountries: PropTypes.array,
    }

    return connect(mapStateToProps, mapDispatchToProps)(EditMerchant)
}
