import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import Button from 'react-bootstrap/lib/Button'
import DropdownButton from 'react-bootstrap/lib/DropdownButton'
import MenuItem from 'react-bootstrap/lib/MenuItem'
import Modal from 'react-bootstrap/lib/Modal'
import CommentModal from './comment-modal'
import RouteApi from '../../utils/route-api'
import auth from '../../auth'
import TableRow from './table-row'
import { displayName } from '../../utils/display-name'
import { curry } from '../../utils/curry'
import { hd } from '../../utils/hd'
import { routeCompleted } from '../../utils/route-webapi'
import { handleError } from '../../utils/handle-error'
import { OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../../utils/role'

const Collected = 'Collected'
const Delivered = 'Delivered'
const Miss = 'Miss'
const OnRouteDelivery = 'OnRouteDelivery'
const ReturnedToTerminal = 'ReturnedToTerminal'
const ReturnedToMerchant = 'ReturnedToMerchant'

export default class Table extends React.Component {
    state = {
        showRouteModal: false,
        expanded: true,
        allOrdersVisible: true,
        selectedPhoneNumber: null,
    }

    isAdmin = auth.hasAnyRole(OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)

    _markDone = (e) => {
        e.preventDefault()

        const { routeId } = this.props
        if (window.confirm(`Do you really want to mark route ${routeId} as done?`)) {
            routeCompleted(routeId).catch(handleError)
        }
    }

    toggle(hide, state) {
        this.setState({ [state]: hide != null ? hide : !this.state[state] })
    }

    _toggleVisibility = curry((state, e) => {
        e.preventDefault()
        // If the User clicked an a href tag (Drivers phone number), do not toggle the visibility state
        if (e.target.tagName != null && e.target.tagName.toLowerCase() === 'a') {
            if (e.target.getAttribute('role') !== 'menuitem') {
                return (window.location = e.target.href)
            }
        }

        this.setState({ [state]: !this.state[state] })
    })

    _toggleRouteModal = (e) => {
        if (e != null) {
            e.preventDefault()
        }

        this.setState({ showRouteModal: !this.state.showRouteModal })
    }

    _renderRouteModal(routeId, comments, timeZone) {
        if (!this.state.showRouteModal) {
            return
        }
        return (
            <CommentModal
                timeZone={timeZone}
                show={this.state.showRouteModal}
                className="traffix-modal"
                routeId={routeId}
                dataType="route"
                comments={comments}
                commentPermissions={this.props.commentPermissions}
                onSubmit={this.props.onCommentSubmit}
                onRequestHide={this._toggleRouteModal}
            />
        )
    }

    _renderTableRows(data, i) {
        const { order } = data
        const deliveryEta = moment(data.deliveryEta)
        const deliveredTime = order != null ? moment(order.statusTime) : null
        const delivered = order != null && order.orderStatus === Delivered

        let completed = false
        if (order != null) {
            if (order.consignmentType === 'DELIVERY' && order.orderStatus === Delivered) {
                completed = true
            } else if (
                order.consignmentType === 'RETURN' &&
                [Collected, ReturnedToTerminal, ReturnedToMerchant].includes(order.orderStatus)
            ) {
                completed = true
            }
        }

        const rowClassNames = {
            success: completed,
            danger: order != null && order.orderStatus === Miss,
            info: order != null && order.orderStatus === OnRouteDelivery,
            'text-muted': order != null && order.cancellationId,
        }

        const deliveredColumnClassnames = {
            danger: delivered && deliveredTime.isAfter(deliveryEta),
        }
        const ratingColumnClassnames = {
            danger: order != null && order.rating != null && order.rating.score && order.rating.score <= 3,
        }

        return (
            <TableRow
                onRequestHide={this._toggleRouteModal}
                commentPermissions={this.props.commentPermissions}
                ratingColumnClassnames={ratingColumnClassnames}
                deliveredColumnClassnames={deliveredColumnClassnames}
                rowClassNames={rowClassNames}
                deliveredTime={deliveredTime}
                deliveryEta={deliveryEta}
                data={data}
                key={i}
                onCommentSubmit={this.props.onCommentSubmit}
                rowClassNames={cx(rowClassNames)}
                cancelCategories={this.props.cancelCategories}
            />
        )
    }

    _renderTable(route) {
        const { traffixData } = route
        if (!this.state.expanded) {
            return null
        }

        const routeOrders = []

        for (const route of traffixData) {
            if (
                route.routeType === 'LINEHAUL' ||
                route.stopType === 'TERMINAL' ||
                route.stopType === 'CROSSDOCK' ||
                route.orders.length === 0
            ) {
                const routeOrder = { ...route }
                routeOrders.push(routeOrder)
            } else {
                for (const order of route.orders) {
                    const routeOrder = { ...route }
                    routeOrder.orders = []
                    routeOrder.order = order
                    routeOrders.push(routeOrder)
                }
            }
        }

        let relevantOrders

        if (this.state.allOrdersVisible) {
            relevantOrders = routeOrders
        } else {
            let firstOrder = null
            let lastOrder = null

            for (const routeOrder of routeOrders) {
                if (routeOrder.order.orderStatus !== Delivered) {
                    if (firstOrder === null) {
                        firstOrder = routeOrder
                    }
                } else {
                    lastOrder = routeOrder
                }
            }

            const firstIndex = firstOrder ? routeOrders.indexOf(firstOrder) : 0
            const lastIndex = lastOrder ? routeOrders.indexOf(lastOrder) : 0
            const sliceStart = firstIndex > 0 ? firstIndex - 1 : firstIndex
            const sliceEnd = lastIndex + 3

            // Slice the relevant orders from all orders
            relevantOrders = routeOrders.slice(sliceStart, sliceEnd)
        }

        return (
            <div className="panel-body">
                <table className="table table-striped table-condensed">
                    <thead>
                        <tr>
                            <th># xxx</th>
                            <th>Merchant</th>
                            <th>Order</th>
                            <th>Status</th>
                            <th>Photos</th>
                            <th>Routed ETA</th>
                            <th>Live ETA</th>
                            <th>Completed</th>
                            <th>Diff. from Routed</th>
                            <th>Consumer</th>
                            <th>Cancel</th>
                            <th>Telephone</th>
                            <th>Address</th>
                            <th>Rating</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>{relevantOrders.map(this._renderTableRows, this)}</tbody>
                </table>
            </div>
        )
    }

    onContactDriverSelect = curry((number, e) => {
        e.stopPropagation()
        this.setState({ selectedPhoneNumber: number })
    })

    transitionToSmsPage = () => {
        this.props.router.push(`/admin/sms?recipient=${this.state.selectedPhoneNumber}`)
    }

    phoneOrSmsChoiceModal = () => (
        <Modal
            show={this.state.selectedPhoneNumber != null}
            onHide={() => this.setState({ selectedPhoneNumber: null })}
            container={this}
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header>
                <Modal.Title>{this.state.selectedPhoneNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button onClick={() => (window.location = `tel:${this.state.selectedPhoneNumber}`)}>Call</Button>
                <Button onClick={this.transitionToSmsPage} bsStyle="primary">
                    SMS
                </Button>
            </Modal.Body>
        </Modal>
    )

    getEstimatedFinishTime(orders) {
        const etas = orders.filter((order) => order.eta)
        if (etas.length > 0) {
            return moment(etas[etas.length - 1].eta)
        }
        return null
    }

    getTimeZone(traffixData) {
        const timeZones = traffixData.filter((traffix) => traffix.timeZone)
        return timeZones[timeZones.length - 1].timeZone
    }

    renderEstimatedFinishTime(eta, timeZone) {
        const soon = eta.diff(moment(), 'minutes') <= 20

        return (
            <span>
                ETA finish: {eta.tz(timeZone).format('HH:mm')}
                {soon && ' #soon'}
            </span>
        )
    }

    render() {
        const { routeId, route: routeData } = this.props
        let { routeComments } = this.props
        const { traffixData } = routeData

        const orders = traffixData.flatMap((data) => data.orders)
        const finishTime = this.getEstimatedFinishTime(orders)
        const timeZone = this.getTimeZone(traffixData)
        const { isAdmin } = this

        if (routeData.routeComments != null) {
            routeComments = routeData.routeComments
        }

        const data = hd(traffixData)
        return (
            <div className="panel panel-info">
                <a name={`${routeId}`} />
                <div className="panel-heading">
                    <div className="row">
                        <div
                            className="col-md-9 col-lg-9"
                            style={{ paddingTop: 6 }}
                            onClick={this._toggleVisibility('expanded')}
                        >
                            <span className="panel-title">
                                {data.routeType}&nbsp; • Carrier {data.ownerOperatorName}&nbsp; • Route #{routeId}&nbsp;
                                • Driver {displayName(' ', data.driverFirstName, data.driverLastName)} -{' '}
                                <a href={`tel:${data.driverPhoneNo}`}>{data.driverPhoneNo}</a>&nbsp; •{' '}
                                {finishTime && this.renderEstimatedFinishTime(finishTime, timeZone)}
                            </span>
                        </div>

                        <div className="col-md-3 col-lg-3" style={{ marginTop: 6 }}>
                            <div className="pull-right">
                                {this.isAdmin ? (
                                    <DropdownButton id="traffix-actions-dropdown" title="Actions" pullRight>
                                        <MenuItem href={`/admin/map/${routeId}?track`} target="_blank">
                                            Show on map
                                        </MenuItem>
                                        <MenuItem onClick={this._markDone}>Mark done</MenuItem>
                                        <MenuItem onClick={this._toggleRouteModal}>
                                            Route comments{' '}
                                            {routeComments != null && routeComments > 0 ? `(${routeComments})` : null}
                                        </MenuItem>
                                        <MenuItem onClick={this._toggleVisibility('expanded')}>
                                            {this.state.expanded ? 'Hide' : 'Show'}
                                        </MenuItem>
                                        <MenuItem onClick={this._toggleVisibility('allOrdersVisible')}>
                                            {this.state.allOrdersVisible ? 'Show relevant orders' : 'Show all orders'}
                                        </MenuItem>
                                        {isAdmin ? (
                                            <MenuItem onClick={this.onContactDriverSelect(data.driverPhoneNo)}>
                                                Contact driver
                                            </MenuItem>
                                        ) : null}
                                        <MenuItem href={`/admin/routes/${routeId}`} target="_blank">
                                            Inspect Route
                                        </MenuItem>
                                    </DropdownButton>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {this._renderTable(routeData)}
                {this.phoneOrSmsChoiceModal()}
                {this._renderRouteModal(routeId, routeComments, timeZone)}
            </div>
        )
    }
}
