import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Map from '../../map/map'
import { STOP_TYPE } from '../../../utils/recurring-pickups'

export default class RecurringRouteMap extends Component {
    static propTypes = {
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        terminals: PropTypes.array.isRequired,
        recurringPickup: PropTypes.object.isRequired,
    }

    static defaultProps = {
        height: '100%',
        width: '100%',
    }

    state = {
        displayEstimatedRoute: true,
        markers: [],
        estimatedRouteCoordinates: [],
    }

    componentDidUpdate(previousProps) {
        const { recurringPickup } = this.props

        const currentNumberOfPickupStops = recurringPickup.stops.length
        const previousNumberOfPickupStops = previousProps.recurringPickup.stops.length

        if (this.numberOfPickupStopsChanged(currentNumberOfPickupStops, previousNumberOfPickupStops)) {
            this.updateMarkers(recurringPickup)
        } else if (this.pickupStopsChanged(recurringPickup.stops, previousProps.recurringPickup.stops)) {
            this.updateMarkers(recurringPickup)
        } else if (this.pickupOrderChanged(recurringPickup.stops, previousProps.recurringPickup.stops)) {
            this.updateMarkers(recurringPickup)
        }
    }

    getMarkers = (stops) => {
        return stops
            .map((stop) => {
                const order = stop.sortOrder + 1
                switch (stop.type) {
                    case STOP_TYPE.MERCHANT:
                        return this.merchantStopMarker(stop, '#41CDA5', order)
                    case STOP_TYPE.TERMINAL:
                        return this.terminalStopMarker(stop.terminalId, '#F39C12', order)
                    default:
                        return null
                }
            })
            .filter((x) => x != null)
            .sort((a, b) => a.sortOrder - b.sortOrder)
    }

    numberOfPickupStopsChanged = (previousNumberOfPickups, currentNumberOfPickups) =>
        previousNumberOfPickups !== currentNumberOfPickups

    pickupStopsChanged = (currentStops, previousStops) =>
        currentStops.some((currentStop) =>
            previousStops.every(
                (previousStop) => previousStop.type === currentStop.type && previousStop.id !== currentStop.id,
            ),
        )

    pickupOrderChanged = (currentStops, previousStops) =>
        currentStops
            .map(
                (x, index) =>
                    currentStops[index].id === previousStops[index].id &&
                    currentStops[index].sortOrder === previousStops[index].sortOrder,
            )
            .includes(false)

    updateMarkers = (recurringPickup) => {
        if (recurringPickup && recurringPickup.stops.find((x) => x.id === '-1')) {
            return
        }
        if (recurringPickup && !recurringPickup.stops.length) {
            return
        }

        const markers = this.getMarkers(recurringPickup.stops)
        this.setState({ markers })

        const birdRoute = markers.map(({ coordinate: { latitude, longitude } }) => [longitude, latitude])

        this.setState({ markers, estimatedRouteCoordinates: birdRoute })
    }

    terminalStopMarker = (terminalId, color, order) => {
        const { terminals } = this.props
        const terminal = terminals.find((x) => x.id === terminalId)

        if (terminal != null && terminal.address.coordinate) {
            return {
                coordinate: terminal.address.coordinate,
                color,
                sortOrder: order,
                label: order,
                terminalId: terminal.id,
            }
        }

        return null
    }

    merchantStopMarker = (stop, color, label) => {
        if (stop && stop.coordinate) {
            return {
                sortOrder: stop.sortOrder,
                coordinate: stop.coordinate,
                color,
                label,
            }
        }

        return null
    }

    mapLoaded = () => {
        const { recurringPickup } = this.props

        this.updateMarkers(recurringPickup)
    }

    render() {
        const { markers, displayEstimatedRoute, estimatedRouteCoordinates } = this.state

        return (
            <div style={{ backgroundColor: '#191A1A' }}>
                <div style={{ width: this.props.width, height: this.props.height }}>
                    <Map
                        onLoad={this.mapLoaded}
                        markers={markers}
                        displayPolyline={displayEstimatedRoute}
                        displayHeatmap={false}
                        polylineCoordinates={estimatedRouteCoordinates}
                        heatmapCoordinates={[]}
                        zoomToLocation={null}
                    />
                </div>
            </div>
        )
    }
}
