import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.comments-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('comments')

export const getCommentPermissions = () =>
    webapi()
        .path('permissions')
        .get()

export const addRouteComment = (routeId, data) =>
    webapi()
        .path('routes')
        .path(routeId)
        .post(data)

export const addStopComment = (stopId, data) =>
    webapi()
        .path('end-customer-stops')
        .path(stopId)
        .post(data)
