import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import SubscriptionInline from './admin-consumer-subscription-inline'

const AdminSubscriptions = (props) => {
    const { subscriptions, consumer, onCancelSubscription, supportedCities } = props
    const isSubscriptionEditable = (subscription) => {
        if (subscription.cancelledAt === null) {
            return true
        }
        if (subscription.validTo === null) {
            return subscription.suspended
        }
        return moment(new Date()).isSameOrBefore(subscription.validTo, 'day')
    }
    return (
        <Table striped className="text-center">
            <thead>
                <tr className="consumer-subscriptions-header">
                    <th>City</th>
                    <th>Weekday Time Slot</th>
                    <th>Saturday Time Slot</th>
                    <th>Sunday Time Slot</th>
                    <th>Valid To</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                {subscriptions
                    ? subscriptions.map((subscription) => {
                          return (
                              <SubscriptionInline
                                  key={Math.random()}
                                  editable={isSubscriptionEditable(subscription)}
                                  onCancelSubscription={onCancelSubscription}
                                  supportedCities={supportedCities}
                                  subscription={{
                                      supportedCities: subscription.supportedCities,
                                      cycle: subscription.plan.cycle,
                                      city: subscription.city,
                                      weekday: subscription.weekday,
                                      saturday: subscription.saturday,
                                      sunday: subscription.sunday,
                                      suspended: subscription.suspended,
                                      validTo: subscription.validTo,
                                      cancelledAt: subscription.cancelledAt,
                                  }}
                                  consumer={consumer}
                              />
                          )
                      })
                    : null}
            </tbody>
        </Table>
    )
}

AdminSubscriptions.propTypes = {
    subscriptions: PropTypes.array,
    supportedCities: PropTypes.array,
    consumer: PropTypes.object,
    onCancelSubscription: PropTypes.func,
}

export default AdminSubscriptions
