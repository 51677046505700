import { TERMINALS_LOADED } from '../constants/actionTypes'
import terminalsState from './initialState/terminalsState'

const toOption = ({ id: value, name: label }) => ({ value, label })

export default function TerminalReducer(state = terminalsState, action) {
    switch (action.type) {
        case TERMINALS_LOADED:
            return {
                ...state,
                terminals: action.response,
                options: action.response.map(toOption),
            }

        default:
            return state
    }
}
