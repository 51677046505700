import { notValue } from './not-value'
import uniqWith from 'lodash/uniqWith'

export const STOP_TYPE = {
    MERCHANT: 'MERCHANT',
    TERMINAL: 'TERMINAL',
    REST: 'REST',
}

export function filterPickupsNotFoundInLocations(pickups, selectedDay) {
    return pickups.filter(
        (location) =>
            location.type === STOP_TYPE.MERCHANT &&
            !selectedDay.locations.find(
                (pickup) =>
                    location.merchantName === pickup.buyerName &&
                    location.collectionPointName === pickup.collectionPointName,
            ),
    )
}

export function validCollectionPoint(day, model) {
    if (!model.selectedDays.includes(day.enum)) {
        return true
    }

    const selectedDay = model.collectionPoints.find((d) => d.day === day.enum)
    if (selectedDay == null) {
        return false
    }

    return filterPickupsNotFoundInLocations(model.pickups, selectedDay).length === 0
}

export const validate = (model) => {
    const notBlank = ['name', 'dayOfWeek', 'courierId', 'remuneration', 'currency', 'maxPallets', 'vehicleType']

    const noneBlank = notBlank.every(notValue('', model))
    if (!noneBlank) {
        return false
    }

    const notUnselected = ['dayOfWeek', 'courierId']
    const noneUnselected = notUnselected.every(notValue('-1', model))
    if (!noneUnselected) {
        return false
    }

    if (
        !model.pickups
            .filter((pickup) => pickup.type !== STOP_TYPE.REST)
            .every((pickup) => notValue('-1', pickup, 'id'))
    ) {
        return false
    }

    if (
        model.pickups
            .filter(({ type }) => type === STOP_TYPE.MERCHANT)
            .some(({ pickup, returns }) => !pickup && !returns)
    ) {
        return false
    }

    if (model.collectionPoints.length === 0) {
        return false
    }

    const validPickups = model.days.every((day) => validCollectionPoint(day, model))
    if (!validPickups) {
        return false
    }

    if (model.remuneration - model.linehaulCost < 0) {
        return false
    }

    return model.requiredPallets <= model.maxPallets
}

export function matchPickups(day, model, locations) {
    const selectedDay = locations.find((d) => d.day === day.enum)
    if (selectedDay == null) {
        return false
    }

    return selectedDay.locations.filter((location) =>
        model.pickups.some(
            (pickup) =>
                pickup.merchantName === location.buyerName &&
                pickup.collectionPointName === location.collectionPointName &&
                pickup.startTime === location.startTime &&
                pickup.endTime === location.endTime,
        ),
    )
}

export function checkIfMerchantRoute(pickups) {
    const merchants = pickups.filter((pickup) => pickup.type === STOP_TYPE.MERCHANT && pickup.merchantScheduleId > 0)

    if (merchants.length !== 0) {
        return !merchants.some((pickup) => pickup.merchantName.startsWith('Budbee Box'))
    }
    return false
}

export function concatObjectSortAndRemoveDuplicates(object) {
    let concatenatedMultipleLocations = []

    Object.values(object).forEach(element => {
        concatenatedMultipleLocations.push(element);
    });

    concatenatedMultipleLocations.sort((a, b) => b.length - a.length)

    let objectsInEachArray = concatenatedMultipleLocations[0].filter(o => concatenatedMultipleLocations.every(arr => arr.some(r =>
        r.buyerName == o.buyerName &&
        r.collectionPointName == o.collectionPointName &&
        r.startTime == o.startTime &&
        r.endTime == o.endTime
    )))

    objectsInEachArray = uniqWith(
        objectsInEachArray,
        (A, B) =>
            A.buyerName === B.buyerName &&
            A.collectionPointName === B.collectionPointName &&
            A.startTime === B.startTime &&
            A.endTime === B.endTime
    );

    return objectsInEachArray
}
