import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Panel, FormGroup, ControlLabel, FormControl, Table, Button, Glyphicon } from 'react-bootstrap'
import Select from 'react-select'

import CitySelect from '../common/city-select'

/**
    Renders Panel with a Public Holiday.
    Allows User to view date, zones and unlocked Merchants.
    Allows User to edit information
*/
export default class Holiday extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addingUnlock: false,
            selectedMerchant: null,
            selectedCity: null,
        }

        this.renderMerchantUnlocks = this.renderMerchantUnlocks.bind(this)
        this.renderMerchantUnlock = this.renderMerchantUnlock.bind(this)
        this.handleMerchantChange = this.handleMerchantChange.bind(this)
        this.handleUnlockCityChange = this.handleUnlockCityChange.bind(this)
        this.handleCitiesChange = this.handleCitiesChange.bind(this)
        this.handleAddUnlock = this.handleAddUnlock.bind(this)
    }

    handleMerchantChange(value) {
        this.setState({ selectedMerchant: value })
    }

    handleUnlockCityChange(value) {
        this.setState({ selectedCity: value })
    }

    handleCitiesChange(cities) {
        const { holiday } = this.props
        this.props.onCitiesChange(
            holiday,
            cities.reduce((acc, city) => [...acc, city.value], []),
        )
    }

    handleAddUnlock() {
        const { holiday } = this.props
        const { selectedMerchant, selectedCity } = this.state
        this.props.onAddUnlock(holiday, {
            merchant: selectedMerchant,
            city: selectedCity,
        })
    }

    renderAddUnlockButton() {
        return (
            <tr key={0}>
                <td>
                    <Button onClick={() => this.setState({ addingUnlock: true })}>Add new unlock</Button>
                </td>
                <td />
                <td />
            </tr>
        )
    }

    mapCityToOption = (city) => {
        return {
            label: city.label,
            value: city.id,
        }
    }

    renderAllowLockerSelect() {
        const unblockedCityIds = this.props.allowedLockerCities.map((unblock) => unblock.city)
        const selectedCities = this.props.cities
            .filter((city) => unblockedCityIds.includes(city.id))
            .map(this.mapCityToOption)
        const optionCities = this.props.cities
            .filter((city) => this.props.holiday.cities.includes(city.id))
            .map(this.mapCityToOption) // display only the cities that are currently blocked, for ease of use

        return (
            <Select
                multi
                value={selectedCities}
                onChange={(e) => this.props.onAllowLockerCitiesChange(this.props.holiday.id, e)}
                options={optionCities}
            />
        )
    }

    renderAddMerchantUnlockForm(holiday) {
        const { buyers } = this.props
        const { selectedMerchant, selectedCity } = this.state
        const merchantValue = selectedMerchant && selectedMerchant.value

        return (
            <tr key={0}>
                <td>
                    <Select
                        placeholder="Select merchant"
                        value={merchantValue}
                        onChange={this.handleMerchantChange}
                        options={buyers.map((b) => ({ value: b.id, label: `${b.name} (${b.externalName})` }))}
                    />
                </td>
                <td>
                    <Select
                        placeholder="Select city"
                        value={selectedCity}
                        onChange={this.handleUnlockCityChange}
                        options={holiday.cities.map(this.mapCityToOption)}
                    />
                </td>
                <td className="pull-right">
                    <Button
                        bsStyle="success"
                        disabled={!selectedMerchant || !selectedCity}
                        onClick={() => {
                            this.setState({ addingUnlock: false })
                            this.handleAddUnlock()
                        }}
                    >
                        <Glyphicon glyph="ok" />
                    </Button>
                </td>
            </tr>
        )
    }

    renderMerchantUnlock(unlock) {
        return (
            <tr key={`${unlock.merchantId}.${unlock.city}`}>
                <td>{unlock.merchant ? unlock.merchant.name : unlock.merchantId}</td>
                <td>{unlock.city}</td>
                <td className="pull-right">
                    <Button bsStyle="danger" onClick={() => this.props.onRemoveUnlock(this.props.holiday, unlock)}>
                        <Glyphicon glyph="remove" />
                    </Button>
                </td>
            </tr>
        )
    }

    renderMerchantUnlocks() {
        const { unlocked, holiday, buyers } = this.props
        const { addingUnlock } = this.state

        const unlockedWithMerchant = unlocked.map((unlock) => ({
            merchant: buyers.find((buyer) => buyer.id === unlock.merchantId),
            merchantId: unlock.merchantId,
            city: unlock.city,
            holidayId: unlock.id,
        }))

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Merchant</th>
                        <th>In City</th>
                    </tr>
                </thead>
                <tbody>
                    {unlockedWithMerchant.map(this.renderMerchantUnlock)}
                    {!addingUnlock ? this.renderAddUnlockButton() : this.renderAddMerchantUnlockForm(holiday)}
                </tbody>
            </Table>
        )
    }

    render() {
        const { holiday, cities } = this.props

        const date = moment(holiday.date)

        const header = (
            <div>
                {holiday.name}
                <div className="pull-right">
                    <Button
                        bsStyle="danger"
                        bsSize="xsmall"
                        onClick={() => this.props.onRemoveHoliday(this.props.holiday)}
                    >
                        <Glyphicon glyph="trash" />
                    </Button>
                </div>
            </div>
        )

        const activatedCities = cities.filter((city) => holiday.cities.includes(city.id)).map(this.mapCityToOption)

        return (
            <Panel header={header}>
                <form>
                    <FormGroup>
                        <ControlLabel>Date</ControlLabel>
                        <FormControl.Static>
                            {`${date.format('YYYY-MM-DD')} (${date.format('dddd')})`}
                        </FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Activated in Cities</ControlLabel>
                        <CitySelect cities={cities} value={activatedCities} onSelect={this.handleCitiesChange} />
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Allow locker delivery for cities despite holiday</ControlLabel>
                        {this.renderAllowLockerSelect()}
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Unlocked for</ControlLabel>
                        {this.renderMerchantUnlocks()}
                    </FormGroup>
                </form>
            </Panel>
        )
    }
}

Holiday.propTypes = {
    holiday: PropTypes.object.isRequired,
    allowedLockerCities: PropTypes.array,
    unlocked: PropTypes.array,
    cities: PropTypes.array,
    buyers: PropTypes.array,
    onCitiesChange: PropTypes.func.isRequired,
    onAddUnlock: PropTypes.func.isRequired,
    onAllowLockerCitiesChange: PropTypes.func.isRequired,
    onRemoveUnlock: PropTypes.func.isRequired,
    onRemoveHoliday: PropTypes.func.isRequired,
}
