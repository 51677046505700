import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Glyphicon } from 'react-bootstrap'
import Loader from 'react-loader'
import * as actions from '../../../../actions/collection-points'
import { displayName } from '../../../../utils/display-name'
import CreateModal from './modal'
import DeletePickupModal, { formatTime } from './delete-pickup-modal'
import { composeSort, mapSort, sortDays, sortStrings } from '../../../../utils/sorting'
import { prop } from '../../../../utils/prop'
import { pluck } from '../../../../utils/pluck'
import { dayAbbr } from '../../../../utils/day-abbr'

const getTerminalName = prop('terminal.name')
const sorter = composeSort(mapSort(sortStrings, getTerminalName), mapSort(sortDays, pluck('day')))

const scheduleKeyMaker = (schedule) =>
    [
        getTerminalName(schedule),
        schedule.intervalStart,
        schedule.intervalEnd,
        schedule.arrival,
        schedule.minPallets,
        schedule.maxPallets,
    ].join('|')
const groupSchedules = (acc, schedule) => {
    const key = scheduleKeyMaker(schedule)
    if (acc[key] == null) {
        acc[key] = {
            ids: [schedule.id],
            terminalName: getTerminalName(schedule),
            days: [schedule.day],
            intervalStart: schedule.intervalStart,
            intervalEnd: schedule.intervalEnd,
            arrival: schedule.arrival,
            minPallets: schedule.minPallets,
            maxPallets: schedule.maxPallets,
        }
    } else {
        acc[key] = {
            ...acc[key],
            ids: [...acc[key].ids, schedule.id],
            days: [...acc[key].days, schedule.day],
        }
    }

    return acc
}

const groupPickups = (pickups, showDeleteIds) => {
    const groupedPickups = {}

    for (let i = 0; i < pickups.length; i += 1) {
        const key = scheduleKeyMaker(pickups[i])

        if (showDeleteIds.includes(pickups[i].id)) {
            if (groupedPickups[key] == null) {
                groupedPickups[key] = [pickups[i]]
            } else {
                groupedPickups[key] = [...groupedPickups[key], pickups[i]]
            }
        }
    }

    return groupedPickups
}

class ManagePickup extends Component {
    constructor(...args) {
        super(...args)
        this.state = {
            showDeletePickupModal: false,
            showDeleteIds: [],
            modalOpen: false,
        }
    }

    componentDidMount() {
        this.props.actions.fetchCPSchedules(this.props.buyerId, this.props.cid)
    }

    onShowDeletePickupModal = (ids) => {
        this.setState({ showDeletePickupModal: true, showDeleteIds: ids })
    }

    onHideDeletePickupModal = () => {
        this.setState({ showDeletePickupModal: false, showDeleteIds: [] })
    }

    onConfirmDelete = (ids) => {
        this.props.actions.removeCPSchedules(ids, this.props.buyerId, this.props.cid)

        this.onHideDeletePickupModal()
    }

    onCancelDelete = () => {
        this.onHideDeletePickupModal()
    }

    savePickupSchedule = (pickupData) => {
        this.props.actions.savePickupSchedule(this.props.buyerId, pickupData)
        this.toggleModal()
    }

    toggleModal = () => {
        this.setState((previous) => ({
            modalOpen: !previous.modalOpen,
        }))
    }

    renderSchedule = (schedule) => (
        <tr key={schedule.ids.join('|')}>
            {this.props.canEdit ? (
                <td>
                    <Button bsStyle="danger" bsSize="xsmall" onClick={() => this.onShowDeletePickupModal(schedule.ids)}>
                        <Glyphicon glyph="trash" />
                    </Button>
                </td>
            ) : null}
            <td>{schedule.terminalName || 'No terminal registered!'}</td>
            <td>
                {schedule.days
                    .map(dayAbbr())
                    .join(', ')
                    .toLowerCase()}
            </td>
            <td>{displayName(' - ', formatTime(schedule.intervalStart), formatTime(schedule.intervalEnd))}</td>
            <td>{formatTime(schedule.arrival)}</td>
            <td>{schedule.minPallets}</td>
            <td>{schedule.maxPallets}</td>
        </tr>
    )

    renderSchedules(schedules) {
        return Object.values(schedules.sort(sorter).reduce(groupSchedules, {})).map(this.renderSchedule)
    }

    render() {
        const { showDeleteIds } = this.state

        const p = this.props.pickups.find((pk) => pk.collectionId === this.props.cid)
        const pickups = p != null ? p.data : []

        return (
            <div>
                {this.props.canEdit ? (
                    <div>
                        <button type="button" className="btn btn-default margin-btn right" onClick={this.toggleModal}>
                            <Glyphicon glyph="plus" /> Create Pickup Schedules
                        </button>
                    </div>
                ) : null}
                <div>
                    <Loader color="#449d44" loaded={!this.props.saving}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {this.props.canEdit ? <th>Actions</th> : null}
                                    <th>Dropoff terminal</th>
                                    <th>Day</th>
                                    <th>Agreed interval</th>
                                    <th>Arrival at terminal</th>
                                    <th>Agreed min # of pallets</th>
                                    <th>Agreed max # of pallets</th>
                                </tr>
                            </thead>

                            <tbody>{this.renderSchedules(pickups)}</tbody>
                        </table>
                    </Loader>
                </div>

                {this.props.canEdit ? (
                    <DeletePickupModal
                        show={this.state.showDeletePickupModal}
                        cancel={this.onCancelDelete}
                        confirmDelete={(ids) => this.onConfirmDelete(ids)}
                        groupedPickups={groupPickups(pickups, showDeleteIds)}
                    />
                ) : null}

                {this.props.canEdit ? (
                    <CreateModal
                        show={this.state.modalOpen}
                        cid={this.props.cid}
                        success={this.props.success}
                        added={this.props.added}
                        msg={this.props.msg}
                        toggle={this.toggleModal}
                        savePickupSchedule={this.savePickupSchedule}
                    />
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    saving: state.collection.saving,
    pickups: state.collection.pickups,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

ManagePickup.propTypes = {
    actions: PropTypes.object.isRequired,
    buyerId: PropTypes.number,
    pickups: PropTypes.array,
    saving: PropTypes.bool,
    canEdit: PropTypes.bool,
    cid: PropTypes.number,
    success: PropTypes.bool,
    added: PropTypes.bool,
    msg: PropTypes.node,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePickup)
