import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Modal,
    Panel,
    Glyphicon,
    Form,
    FormGroup,
    ControlLabel,
    Button,
    ButtonToolbar,
    ToggleButtonGroup,
    ToggleButton,
    Table,
} from 'react-bootstrap'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import MerchantSelect from '../common/merchant-select'
import * as actions from '../../actions/recurring-pickups'
import { mapSort, sortStrings } from '../../utils/sorting'
import { displayName } from '../../utils/display-name'
import auth from '../../auth'
import { OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../utils/role'

const initialState = () => ({
    startDate: moment(),
    endDate: moment(),
    focusedInput: null,
    merchants: [],
    appliesTo: 'all',
})
const renderPanelHeader = () => (
    <h3 className="panel-title">
        <Glyphicon glyph="time" /> Manage merchant holidays
    </h3>
)

class HolidayModal extends Component {
    static propTypes = {
        show: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
        merchants: PropTypes.array.isRequired,
        merchantHolidays: PropTypes.array.isRequired,
        actions: PropTypes.object.isRequired,
    }

    state = {}

    canEdit = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    componentDidMount() {
        this.initialize()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.show && nextProps.show) {
            this.initialize()
        }
    }

    initialize(reset = true) {
        this.props.actions.fetchHolidays()
        if (reset) {
            this.setState(initialState())
        }
    }

    onDateChange = ({ startDate, endDate }) => this.setState({ startDate, endDate })

    onFocusedInputChange = (focusedInput) => this.setState({ focusedInput })

    create = (e) => {
        e.preventDefault()
        const form = {
            startDate: this.state.startDate.format('YYYY-MM-DD'),
            endDate: this.state.endDate.format('YYYY-MM-DD'),
            appliesTo: this.state.appliesTo,
            merchants: this.state.merchants,
        }

        this.props.actions.createHoliday(form).then(() => this.initialize())
    }

    removeHoliday = (id) => {
        if (!window.confirm('Are you sure?')) {
            return
        }

        this.props.actions.removeHoliday(id).then(() => this.initialize(true))
    }

    isValid() {
        return (
            this.state.startDate != null &&
            this.state.endDate != null &&
            this.state.appliesTo != null &&
            this.state.merchants != null
        )
    }

    renderForm() {
        if (!this.canEdit) {
            return null
        }

        return (
            <Form onSubmit={this.create}>
                <FormGroup>
                    <ControlLabel>Date</ControlLabel>
                    <div>
                        <DateRangePicker
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            minimumNights={0}
                            onDatesChange={this.onDateChange}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={this.onFocusedInputChange}
                            displayFormat="YYYY-MM-DD"
                            firstDayOfWeek={1}
                            isOutsideRange={(date) =>
                                moment()
                                    .startOf('day')
                                    .isAfter(date)
                            }
                        />
                    </div>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Applies to</ControlLabel>
                    <ButtonToolbar>
                        <ToggleButtonGroup
                            type="radio"
                            name="applies-to"
                            value={this.state.appliesTo}
                            onChange={(appliesTo) => this.setState({ appliesTo })}
                        >
                            <ToggleButton value="all">All</ToggleButton>
                            <ToggleButton value="none">None</ToggleButton>
                        </ToggleButtonGroup>
                    </ButtonToolbar>
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Except</ControlLabel>
                    <MerchantSelect
                        value={this.state.merchants}
                        onSelect={(merchants) =>
                            this.setState({
                                merchants: merchants != null ? merchants.map(({ value: id }) => id) : [],
                            })
                        }
                    />
                </FormGroup>

                <FormGroup>
                    <Button type="submit" bsStyle="success" disabled={!this.isValid()}>
                        Create
                    </Button>
                    <Button onClick={() => this.initialize()}>Clear</Button>
                    <Button className="pull-right" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                </FormGroup>
            </Form>
        )
    }

    renderHolidays() {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Applies to</th>
                        <th>Exceptions</th>
                        {this.canEdit ? <th /> : null}
                    </tr>
                </thead>

                <tbody>
                    {this.props.merchantHolidays
                        .sort(mapSort(sortStrings, ({ startDate, endDate }) => `${startDate}|${endDate}`))
                        .map(this.renderHoliday, this)}
                </tbody>
            </Table>
        )
    }

    renderHoliday(holiday) {
        const { id, startDate, endDate, appliesTo, merchants: merchantIds } = holiday
        return (
            <tr key={id}>
                <td style={{ width: 180 }}>
                    {startDate} &ndash; {endDate}
                </td>
                <td style={{ width: 90 }}>{appliesTo}</td>
                <td className="ellipsis" style={{ maxWidth: 560 }}>
                    {displayName(
                        ', ',
                        ...merchantIds.map((merchantId) => this.props.merchants[merchantId]).sort(sortStrings),
                    )}
                </td>
                {this.canEdit ? (
                    <td style={{ width: 35 }}>
                        <Button bsSize="xsmall" bsStyle="danger" onClick={() => this.removeHoliday(id)}>
                            &times;
                        </Button>
                    </td>
                ) : null}
            </tr>
        )
    }

    render() {
        return (
            <Modal show={this.props.show} animation onHide={this.props.onHide} bsSize="large">
                <Panel header={renderPanelHeader()}>
                    {this.renderForm()}
                    <hr />

                    {this.renderHolidays()}
                </Panel>
            </Modal>
        )
    }
}

const mapStateToProps = ({ buyers: { buyers: merchants }, recurringPickups: { merchantHolidays } }) => ({
    merchants: merchants.reduce(
        (acc, { id, externalName }) => ({
            ...acc,
            [id]: externalName,
        }),
        {},
    ),
    merchantHolidays,
})
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(HolidayModal)
