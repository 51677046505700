import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getMerchantCollectionPoints } from '../../utils/merchant-webapi'
import { handleError } from '../../utils/handle-error'

export default class CollectionPointPicker extends Component {
    static propTypes = {
        buyer: PropTypes.object.isRequired,
        onCollectionPointChange: PropTypes.func,
    }

    state = {
        collectionPoints: [],
    }

    componentDidMount() {
        getMerchantCollectionPoints(this.props.buyer.id)
            .then((collectionPoints) => this.setState({ collectionPoints }))
            .catch(handleError)
    }

    onSelected = (event) => {
        const selectedId = parseInt(event.target.value, 10)
        const selected = this.state.collectionPoints.find(
            ({
                primaryKey: {
                    collectionPoint: { id },
                },
            }) => id === selectedId,
        )
        this.props.onCollectionPointChange(selected.primaryKey.collectionPoint)
    }

    renderCollectionPoint = (cp) => {
        const { collectionPoint } = cp.primaryKey
        const label = [
            `${collectionPoint.name}:`,
            `${collectionPoint.address.street},`,
            collectionPoint.address.postalCode,
            collectionPoint.address.city,
        ].join(' ')

        return (
            <option value={collectionPoint.id} key={collectionPoint.id}>
                {label}
            </option>
        )
    }

    render() {
        return (
            <select onChange={this.onSelected} className="form-control">
                <option value="0">Select</option>
                {this.state.collectionPoints.map(this.renderCollectionPoint)}
            </select>
        )
    }
}
