import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import marker from './stop-marker'
import polyline from './polyline'
import heatmap from './heatmap'
import memoize from '../../utils/memoize'
import { hd } from '../../utils/hd'

const map = memoize(() =>
    import('mapbox-gl').then(({ default: mapboxgl }) => {
        // this token is URL restricted to:
        // https://carriers.budbee.com
        // https://carriers.staging.budbee.com
        // https://carriers.testing.budbee.com
        //
        // if you need to develop locally, there's a seperate token that can be
        // requested from a colleague or found when logging in at mapbox.com.
        //
        // it's labeled "Budbee Web LOCALHOST (do not use in production for any services)"
        //
        // eslint-disable-next-line no-param-reassign
        mapboxgl.accessToken =
            'pk.eyJ1IjoiYnVkYmVlIiwiYSI6ImNrcXRnazFwNDA2YTcycHF1NHRqZnJmZnQifQ.wl2DchELvqGZ1yBAB-VGfA'

        const mbMap = new mapboxgl.Map({
            container: 'map-container',
            style: 'mapbox://styles/budbee/cjpjyxhbl0olp2ro1dgfkpfmy',
            zoom: 14,
            center: [0, 0],
        })

        return [mapboxgl, mbMap]
    }),
)

const flyTo = (location) => {
    map().then(([, m]) => {
        m.flyTo({
            center: [location.longitude, location.latitude],
            zoom: 14,
        })
    })
}

const add3DBuildingLayers = () => {
    map().then(([, m]) => {
        const { layers } = m.getStyle()

        const labelLayerId = hd(
            layers
                .filter((layer) => layer.type === 'symbol' && layer.layout['text-field'] != null)
                .map((layer) => layer.id),
        )

        m.addLayer(
            {
                id: '3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                filter: ['==', 'extrude', 'true'],
                type: 'fill-extrusion',
                minzoom: 15,
                paint: {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
                    'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
                    'fill-extrusion-opacity': 0.6,
                },
            },
            labelLayerId,
        )
    })
}

export default class Map extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        onLoad: PropTypes.func.isRequired,
        markers: PropTypes.arrayOf(PropTypes.object),
        displayPolyline: PropTypes.bool.isRequired,
        displayHeatmap: PropTypes.bool.isRequired,
        polylineCoordinates: PropTypes.array,
        heatmapCoordinates: PropTypes.array,
        zoomToLocation: PropTypes.object,
    }

    componentDidMount() {
        map().then(([, m]) => {
            m.on('load', () => {
                add3DBuildingLayers()
                this.props.onLoad()
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.markers !== this.props.markers) {
            this.setMarkers(this.props.markers)
        }

        if (prevProps.polylineCoordinates !== this.props.polylineCoordinates) {
            this.setPolyline(this.props.polylineCoordinates)
        }

        if (prevProps.heatmapCoordinates !== this.props.heatmapCoordinates) {
            this.setHeatmap(this.props.heatmapCoordinates)
        }

        if (prevProps.zoomToLocation !== this.props.zoomToLocation) {
            flyTo(this.props.zoomToLocation)
        }
    }

    setMarkers(markers) {
        map().then(([{ LngLatBounds, Marker }, m]) => {
            this.mbMarkers.forEach((mark) => mark.remove())
            this.markers = markers

            const bounds = new LngLatBounds()

            this.mbMarkers = this.markers.map((mm) => {
                const mark = marker(Marker, mm.coordinate.latitude, mm.coordinate.longitude, mm.label, mm.color)
                mark.addTo(m)
                // eslint-disable-next-line no-underscore-dangle
                bounds.extend(mark._lngLat)
                return mark
            })

            m.fitBounds(bounds, {
                padding: 100,
                animate: false,
            })
        })
    }

    setPolyline(coordinates) {
        map().then(([, m]) => {
            if (this.polyline) {
                const { id } = this.polyline
                if (m.getLayer(id)) {
                    m.removeLayer(id)
                }
                if (m.getSource(id)) {
                    m.removeSource(id)
                }
            }

            if (coordinates.length > 0 && this.props.displayPolyline) {
                const pl = polyline('polyline-layer', coordinates)
                this.polyline = pl
                m.addLayer(pl)
            }
        })
    }

    setHeatmap(coordinates) {
        map().then(([, m]) => {
            if (this.heatmap) {
                const { id } = this.heatmap
                if (m.getLayer(id)) {
                    m.removeLayer(id)
                }
                if (m.getSource(id)) {
                    m.removeSource(id)
                }
            }

            if (coordinates.length > 0 && this.props.displayHeatmap) {
                const hm = heatmap('heatmap-layer', coordinates)
                this.heatmap = hm
                m.addLayer(hm)
            }
        })
    }

    markers = []

    mbMarkers = []

    polyline = null

    heatmap = null

    mapLoaded = false

    render() {
        const style = { width: '100%', height: '100%' }
        return (
            <div style={style}>
                <div id="map-container" style={style} />
                {this.props.children}
            </div>
        )
    }
}
