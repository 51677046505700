import React, { Component } from 'react'
import { Button, Glyphicon } from 'react-bootstrap'
import PropTypes from 'prop-types'

import Confirm from '../../../confirm'
import Alert from '../../../alert'

export default class ClearRulesButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            renderRemoveRulesConfirm: false,
        }
    }

    renderRemoveRulesConfirmation = () => {
        const { renderRemoveRulesConfirm } = this.state
        const { onConfirmation, confirmationMessage } = this.props
        return (
            <Confirm
                show={renderRemoveRulesConfirm}
                title="Clear rules"
                bsStyle="danger"
                onClose={() => this.setState({ renderRemoveRulesConfirm: false })}
                onConfirm={() => {
                    onConfirmation()
                    this.setState({ renderRemoveRulesConfirm: false })
                }}
            >
                {confirmationMessage}
            </Confirm>
        )
    }

    render = () => {
        const { isDeletingRules, errorMessage, buttonText } = this.props
        return (
            <div style={{ display: 'inline-block' }}>
                <Button
                    bsStyle="danger"
                    type="button"
                    disabled={isDeletingRules}
                    onClick={() => this.setState({ renderRemoveRulesConfirm: true })}
                >
                    {isDeletingRules ? (
                        <i className="glyphicon glyphicon-refresh refreshing" />
                    ) : (
                        <span>
                            <Glyphicon glyph="trash" /> {buttonText}
                        </span>
                    )}
                </Button>
                <Alert type="danger" message={errorMessage} />
                {this.renderRemoveRulesConfirmation()}
            </div>
        )
    }
}

ClearRulesButton.propTypes = {
    onConfirmation: PropTypes.func.isRequired,
    confirmationMessage: PropTypes.string.isRequired,
    isDeletingRules: PropTypes.bool,
    errorMessage: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
}
