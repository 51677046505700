import * as types from '../constants/actionTypes'
import { compose } from '../utils/compose'
import { constant } from '../utils/constant'
import { handleError } from '../utils/handle-error'
import * as returnPalletGroupsApi from '../utils/return-pallet-groups-webapi'

const loadedReturnPalletGroups = (response) => ({
    type: types.LOADED_RETURN_PALLET_GROUPS,
    response,
})

const createdReturnPalletGroup = (group) => ({
    type: types.CREATED_RETURN_PALLET_GROUP,
    group,
})

const removedReturnPalletGroup = (id) => ({
    type: types.REMOVED_RETURN_PALLET_GROUP,
    id,
})

const addedMerchantToReturnPalletGroup = (group) => ({
    type: types.ADDED_MERCHANT_TO_RETURN_PALLET_GROUP,
    group,
})

const removedMerchantToReturnPalletGroup = (groupId, merchantId) => ({
    type: types.REMOVED_MERCHANT_FROM_RETURN_PALLET_GROUP,
    groupId,
    merchantId,
})

const updatedMerchantGroupMasterMerchant = (groupId, merchantId) => ({
    type: types.UPDATED_MERCHANT_GROUP_MASTER_MERCHANT,
    groupId,
    merchantId,
})

export const getReturnPalletGroups = (dispatch) =>
    returnPalletGroupsApi
        .getReturnPalletGroups()
        .then(compose(dispatch, loadedReturnPalletGroups))
        .catch(handleError)

export const createReturnPalletGroup = (name) => (dispatch) =>
    returnPalletGroupsApi
        .createReturnPalletGroup(name)
        .then(compose(dispatch, createdReturnPalletGroup))
        .catch(handleError)

export const removeReturnPalletGroup = (id) => (dispatch) =>
    returnPalletGroupsApi
        .removeReturnPalletGroup(id)
        .then(compose(dispatch, removedReturnPalletGroup, constant(id)))
        .catch(handleError)

export const addMerchantToReturnPalletGroup = (groupId, merchantId) => (dispatch) =>
    returnPalletGroupsApi
        .addMerchantToReturnPalletGroup(groupId, merchantId)
        .then(compose(dispatch, addedMerchantToReturnPalletGroup))
        .catch(handleError)

export const removeMerchantFromReturnPalletGroup = (groupId, merchantId) => (dispatch) =>
    returnPalletGroupsApi
        .removeMerchantFromReturnPalletGroup(groupId, merchantId)
        .then(() => dispatch(removedMerchantToReturnPalletGroup(groupId, merchantId)))
        .catch(handleError)

export const setMerchantGroupMasterMerchant = (groupId, merchantId) => (dispatch) =>
    returnPalletGroupsApi
        .setMerchantGroupMasterMerchant(groupId, merchantId)
        .then(() => dispatch(updatedMerchantGroupMasterMerchant(groupId, merchantId)))
        .catch(handleError)
