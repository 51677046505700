import React from 'react'
import { chronoGraph } from '../utils/chrono-webapi'

class ChronoRoutes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
        }
    }

    componentDidMount() {
        chronoGraph().then((resp) =>
            resp.text().then((graph) => {
                this.setState({ data: graph })
            }),
        )
    }

    render() {
        return (
            <div style={{ paddingLeft: '150px' }}>
                <pre>{this.state.data}</pre>
            </div>
        )
    }
}

export default ChronoRoutes
