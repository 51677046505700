import { Component } from 'react'
import PropTypes from 'prop-types'
import { auth } from '../../utils/auth-webapi'

const logo = 'https://carrier-images.s3.amazonaws.com/buyers/logo/3ca5ddff-a3a3-46f1-a78b-7a11418b4755.png'

const redirectUrls = {
    retool: () => {
        const searchParams = new URLSearchParams(window.location.search)
        return `https://budbee.retool.com/oauth/user/redirectCallback?nonce=${searchParams.get('nonce')}`
    },
}

const showAuth0Lock = ([Auth0Lock, config], vendor) => {
    const getRedirectUrl = redirectUrls[vendor]
    if (getRedirectUrl === undefined) {
        throw new Error('invalid vendor')
    }

    const options = {
        closable: false,
        auth: {
            audience: config['auht0-login-audience'],
            redirectUrl: getRedirectUrl(),
            responseType: 'token id_token',
            scope: 'openid',
        },
        theme: {
            logo,
            primaryColor: '#39c69b',
        },
        languageDictionary: {
            title: '',
        },
    }
    new Auth0Lock(config['auth0-client-id'], config['auth0-web-login-domain'], options).show()
}

export default class Auth0 extends Component {
    componentDidMount() {
        Promise.all([import('auth0-lock').then(({ default: Auth0Lock }) => Auth0Lock), auth()]).then((config) =>
            showAuth0Lock(config, this.props.vendor),
        )
    }

    render() {
        return null
    }
}

Auth0.propTypes = {
    vendor: PropTypes.string.isRequired,
}
