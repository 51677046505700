import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

const renderClaimedFreeTimeslot = (timeslot) => (
    <tr key={timeslot.id}>
        <td>{timeslot.issuedReason}</td>
        <td>
            <span className="label label-success">Yes</span>
        </td>
        <td>
            {timeslot.discountedFrom.costInCents / 100} {timeslot.discountedFrom.currency}
        </td>
    </tr>
)

const renderUnclaimedFreeTimeslot = (timeslot) => (
    <tr key={timeslot.id}>
        <td>{timeslot.issuedReason}</td>
        <td>
            <span className="label label-default">No</span>
        </td>
        <td />
    </tr>
)

const renderFreeTimeslot = (timeslot) =>
    timeslot.claimedConsignmentId ? renderClaimedFreeTimeslot(timeslot) : renderUnclaimedFreeTimeslot(timeslot)

const FreeTimeslots = (props) => {
    const { timeslots } = props

    if (timeslots == null || timeslots.length === 0) {
        return null
    }

    return (
        <Table striped responsive condensed>
            <thead>
                <tr>
                    <th>Issued Reason</th>
                    <th>Claimed</th>
                    <th>Discounted From</th>
                </tr>
            </thead>
            <tbody>{timeslots ? timeslots.map(renderFreeTimeslot) : null}</tbody>
        </Table>
    )
}

FreeTimeslots.propTypes = {
    timeslots: PropTypes.array,
}

export default FreeTimeslots
