import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, ControlLabel, Checkbox } from 'react-bootstrap'
import Select from 'react-select'
import editMerchant from './edit-merchant-hoc'
import MerchantInput from './merchant-input'
import BillingPeriodSelect from '../../common/billing-period-select'
import CurrencySelect from '../../common/currency-select'
import {
    MIN_CURRENCY_CONVERSION_FEE_IN_PERCENT,
    MAX_CURRENCY_CONVERSION_FEE_IN_PERCENT,
    MIN_INVOICE_DUE_DAYS,
    MAX_INVOICE_DUE_DAYS,
} from '../../../utils/merchant'
import isEmpty from '../../../utils/isEmptyValue'
import InvoiceDeliveryTypes from '../../../utils/invoiceDeliveryTypes'
import Language from '../../../utils/language'
import MerchantSelect from '../../common/merchant-select'
import CountryCodes from '../../../utils/country-codes-enum'

const invoiceDeliveryTypes = InvoiceDeliveryTypes()
const invoiceLanguages = Language()
const countryCodes = CountryCodes()

class InvoicingSettingsForm extends Component {
    onBillinPeriodChange = (billingPeriod) => {
        const { onSettingsChange } = this.props
        onSettingsChange('value', 'billingPeriod')({ target: { value: billingPeriod.value } })
    }

    onCustomInvoiceCurrencyChange = (currency) => {
        const { onSettingsChange } = this.props
        onSettingsChange('value', 'customInvoiceCurrency')({ target: { value: currency ? currency.value : null } })
    }

    onSettingsChangeFromList = (select, key) => {
        const { onSettingsChange } = this.props
        if (select) {
            onSettingsChange('value', key)({ target: { value: select.value } })
        } else {
            onSettingsChange('value', key)({ target: { value: null } })
        }
    }

    render() {
        const { merchantSettings, onSettingsChange } = this.props
        const isEInvoiceIdMandatory = merchantSettings.invoiceDeliveryType === 'E-INVOICE'
        return (
            <div>
                <MerchantInput
                    controlId="parentCompanyId"
                    label="Parent Company for Netsuite"
                    value={merchantSettings.parentCompanyId}
                    onChange={onSettingsChange('value', 'parentCompanyId')}
                    placeholder="Merchant Id"
                />
                <FormGroup controlId="invoiceRecipientOverride">
                    <Col componentClass={ControlLabel} sm={3}>
                        Override invoice recipient to (Netsuite)
                    </Col>
                    <Col sm={9}>
                        <MerchantSelect
                            value={merchantSettings.invoiceRecipientOverride || ''}
                            onSelect={(select) => this.onSettingsChangeFromList(select, 'invoiceRecipientOverride')}
                            multi={false}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="billingPeriod"
                    validationState={!isEmpty(merchantSettings.billingPeriod) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Billing cycle
                    </Col>
                    <Col sm={9}>
                        <BillingPeriodSelect
                            value={merchantSettings.billingPeriod}
                            onChange={this.onBillinPeriodChange}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    required
                    controlId="companyRegistrationNumber"
                    label="Business ID"
                    value={merchantSettings.companyRegistrationNumber}
                    onChange={onSettingsChange('value', 'companyRegistrationNumber')}
                    placeholder="Org nr -  this is not the same as the VAT number"
                />
                <MerchantInput
                    required
                    type="text"
                    controlId="taxRegistrationNumber"
                    label="Tax Registration Number"
                    value={merchantSettings.taxRegistrationNumber}
                    onChange={onSettingsChange('value', 'taxRegistrationNumber')}
                />
                <MerchantInput
                    required
                    controlId="dueDaysInvoice"
                    type="number"
                    min={MIN_INVOICE_DUE_DAYS}
                    max={MAX_INVOICE_DUE_DAYS}
                    label="Payment Terms"
                    value={merchantSettings.dueDaysInvoice}
                    onChange={onSettingsChange('value', 'dueDaysInvoice')}
                    placeholder="Days"
                />
                <FormGroup controlId="customInvoiceCurrency">
                    <Col componentClass={ControlLabel} sm={3}>
                        Custom invoice currency
                    </Col>
                    <Col sm={9}>
                        <CurrencySelect
                            value={merchantSettings.customInvoiceCurrency}
                            onSelect={this.onCustomInvoiceCurrencyChange}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    controlId="currencyConversionFeeInPercent"
                    type="number"
                    min={MIN_CURRENCY_CONVERSION_FEE_IN_PERCENT}
                    max={MAX_CURRENCY_CONVERSION_FEE_IN_PERCENT}
                    label="Currency conversion fee %"
                    value={merchantSettings.currencyConversionFeeInPercent}
                    onChange={onSettingsChange('value', 'currencyConversionFeeInPercent')}
                    placeholder="%"
                />
                <MerchantInput
                    required
                    type="email"
                    controlId="invoiceEmail"
                    label="Invoice email"
                    value={merchantSettings.invoiceEmail}
                    onChange={onSettingsChange('value', 'invoiceEmail')}
                    placeholder="Invoice email"
                />
                <MerchantInput
                    type="email"
                    controlId="invoiceEmail2"
                    label="Invoice email 2"
                    value={merchantSettings.invoiceEmail2}
                    onChange={onSettingsChange('value', 'invoiceEmail2')}
                    placeholder="Invoice email 2"
                />
                <MerchantInput
                    type="email"
                    controlId="invoiceEmailCopy"
                    label="Invoice email CC"
                    value={merchantSettings.invoiceEmailCopy}
                    onChange={onSettingsChange('value', 'invoiceEmailCopy')}
                />
                <MerchantInput
                    type="email"
                    controlId="invoiceEmailSecondCopy"
                    label="Invoice email CC2"
                    value={merchantSettings.invoiceEmailSecondCopy}
                    onChange={onSettingsChange('value', 'invoiceEmailSecondCopy')}
                />
                <MerchantInput
                    type="email"
                    controlId="invoiceEmailReminder"
                    label="Invoice email reminder"
                    value={merchantSettings.invoiceEmailReminder}
                    onChange={onSettingsChange('value', 'invoiceEmailReminder')}
                />
                <MerchantInput
                    type="email"
                    controlId="invoiceContactEmail"
                    label="Invoice contact email"
                    value={merchantSettings.invoiceContactEmail}
                    onChange={onSettingsChange('value', 'invoiceContactEmail')}
                    placeholder="Contact email"
                />
                <FormGroup controlId="invoiceLanguage">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice language
                    </Col>
                    <Col sm={9}>
                        <Select
                            placeholder="Select language..."
                            value={merchantSettings.invoiceLanguage || ''}
                            options={invoiceLanguages}
                            onChange={(select) => this.onSettingsChangeFromList(select, 'invoiceLanguage')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={merchantSettings.invoicesPreapproved}
                            onChange={onSettingsChange('checked', 'invoicesPreapproved')}
                        >
                            Preapprove invoices in Netsuite
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.invoiceSplitByCountry}
                            onChange={onSettingsChange('checked', 'invoiceSplitByCountry')}
                        >
                            Split invoice by country
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.invoiceGroupSubmerchant}
                            onChange={onSettingsChange('checked', 'invoiceGroupSubmerchant')}
                        >
                            Group submerchants invoices (all merchants invoices with Override invoice recipient to this
                            merchant will be merged)
                        </Checkbox>
                        <Checkbox
                            checked={merchantSettings.requiresTerminalEndAlways}
                            onChange={onSettingsChange('checked', 'requiresTerminalEndAlways')}
                        >
                            Goods always require a final terminal stop
                        </Checkbox>
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceDeliveryType"
                    validationState={!isEmpty(merchantSettings.invoiceDeliveryType) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice delivery type
                    </Col>
                    <Col sm={9}>
                        <Select
                            placeholder="Select invoice delivery type..."
                            value={merchantSettings.invoiceDeliveryType || ''}
                            options={invoiceDeliveryTypes}
                            onChange={(select) => this.onSettingsChangeFromList(select, 'invoiceDeliveryType')}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    required={isEInvoiceIdMandatory}
                    type="text"
                    controlId="eInvoiceId"
                    label="GLN number/Peppol ID/e-invoice ID"
                    value={merchantSettings.eInvoiceId}
                    onChange={onSettingsChange('value', 'eInvoiceId')}
                />
                <MerchantInput
                    type="text"
                    controlId="customInvoiceReference"
                    label="Custom Invoice Reference"
                    value={merchantSettings.customInvoiceReference}
                    onChange={onSettingsChange('value', 'customInvoiceReference')}
                />
                <MerchantInput
                    type="text"
                    controlId="budbeeAccountManager"
                    label="Budbee Account Manager"
                    value={merchantSettings.budbeeAccountManager}
                    onChange={onSettingsChange('value', 'budbeeAccountManager')}
                />
                <h4>Invoice address</h4>
                <hr />
                <FormGroup
                    controlId="invoiceCountry"
                    validationState={!isEmpty(merchantSettings.invoiceCountry) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Country
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={merchantSettings.invoiceCountry || ''}
                            options={countryCodes}
                            placeholder="Select country..."
                            onChange={(select) => this.onSettingsChangeFromList(select, 'invoiceCountry')}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    required
                    type="text"
                    controlId="invoicePostalCode"
                    label="Postal Code"
                    value={merchantSettings.invoicePostalCode}
                    onChange={onSettingsChange('value', 'invoicePostalCode')}
                />
                <MerchantInput
                    type="text"
                    controlId="invoiceState"
                    label="State"
                    value={merchantSettings.invoiceState}
                    onChange={onSettingsChange('value', 'invoiceState')}
                />
                <MerchantInput
                    required
                    type="text"
                    controlId="invoiceCity"
                    label="City"
                    value={merchantSettings.invoiceCity}
                    onChange={onSettingsChange('value', 'invoiceCity')}
                />

                <MerchantInput
                    required
                    type="text"
                    controlId="invoiceAddr1"
                    label="Address1"
                    value={merchantSettings.invoiceAddr1}
                    onChange={onSettingsChange('value', 'invoiceAddr1')}
                />
                <MerchantInput
                    type="text"
                    controlId="invoiceAddr2"
                    label="Address2"
                    value={merchantSettings.invoiceAddr2}
                    onChange={onSettingsChange('value', 'invoiceAddr2')}
                />
                <MerchantInput
                    type="text"
                    controlId="invoiceAddr3"
                    label="Address3"
                    value={merchantSettings.invoiceAddr3}
                    onChange={onSettingsChange('value', 'invoiceAddr3')}
                />
                <MerchantInput
                    type="tel"
                    controlId="invoicePhone"
                    label="Phone number"
                    value={merchantSettings.invoicePhone}
                    onChange={onSettingsChange('value', 'invoicePhone')}
                />
                <MerchantInput
                    type="tel"
                    controlId="invoicePhone2"
                    label="Phone number 2"
                    value={merchantSettings.invoicePhone2}
                    onChange={onSettingsChange('value', 'invoicePhone2')}
                />
            </div>
        )
    }
}

export default editMerchant(InvoicingSettingsForm)

InvoicingSettingsForm.propTypes = {
    merchantSettings: PropTypes.object.isRequired,
    onSettingsChange: PropTypes.func.isRequired,
}
