export const formatTime = (time) => {
    if (time == null) {
        return ''
    }

    const [hours, minutes] = time.split(':')
    return `${hours}:${minutes}`
}

export const getDayOfWeekFromDate = (date) => date.format('dddd').toUpperCase()
