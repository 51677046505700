import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup } from 'react-bootstrap'

import MerchantInput from '../merchant-input'
import { createCollectionGroup, updateCollectionGroup } from '../../../../utils/shopping-collection-api'
import { handleError } from '../../../../utils/handle-error'

export default class CollectionGroupForm extends Component {
    constructor(...props) {
        super(...props)
        this.state = {
            title: this.props.createGroup.title || '',
            order: this.props.createGroup.order || '',
        }
    }

    componentDidUpdate(prevProps) {
        const { createGroup } = this.props

        if (prevProps.createGroup.id !== createGroup.id) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                title: createGroup.title,
                order: createGroup.order,
            })
        }
    }

    handleInputChange = (evt) => {
        const { name, value } = evt.target
        this.setState((state) => ({
            ...state,
            [name]: value,
        }))
    }

    onSubmit = (e) => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({ isSubmitting: true })
        const { title, order } = this.state
        const {
            country,
            createGroup: { edit, create },
            getCollectionGroup,
        } = this.props

        const data = {
            country,
            title,
            order: Number(order),
        }

        if (create) {
            createCollectionGroup(data)
                .then(getCollectionGroup)
                .catch((err) => {
                    handleError(err)
                })
        }

        if (edit) {
            const updateGroup = {
                ...data,
            }
            updateCollectionGroup(this.props.createGroup.id, updateGroup)
                .then(getCollectionGroup)
                .catch((err) => {
                    handleError(err)
                })
        }
        this.setState({
            title: '',
            order: '',
        })
    }

    render() {
        return (
            <Form horizontal>
                <FormGroup>
                    <MerchantInput
                        name="title"
                        type="text"
                        required
                        controlId="title"
                        label="Collection Group Title"
                        value={this.state.title}
                        onChange={this.handleInputChange}
                    />
                    <MerchantInput
                        name="order"
                        type="number"
                        required
                        controlId="order"
                        label="Order"
                        value={this.state.order}
                        onChange={this.handleInputChange}
                    />
                    <Button type="submit" bsStyle="primary" className="pull-right" onClick={this.onSubmit}>
                        Submit
                    </Button>
                </FormGroup>
            </Form>
        )
    }
}

CollectionGroupForm.propTypes = {
    country: PropTypes.string.isRequired,
    createGroup: PropTypes.shape({
        edit: PropTypes.bool,
        create: PropTypes.bool,
        id: PropTypes.string,
        title: PropTypes.string,
        order: PropTypes.number,
    }).isRequired,
    getCollectionGroup: PropTypes.func.isRequired,
}
