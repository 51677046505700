import {
    OWNERS_LOADED,
    OWNERS_LOADING,
    OWNER_LOADED,
    OWNER_PRICE_POINTS_LOADED,
    LOADING_PRICE_POINTS,
} from '../constants/actionTypes'
import dataState from './initialState/dataState'
import { mapSort, sortStrings } from '../utils/sorting'
import { prop } from '../utils/prop'

const toOption = ({ id: value, name: label }) => ({ value, label })

export default function OwnerReducer(state = dataState, action) {
    switch (action.type) {
        case OWNERS_LOADED: {
            const options = action.response.map(toOption)
            const data = action.response.sort(mapSort(sortStrings, prop('name')))
            return {
                ...state,
                loading: false,
                data,
                options,
            }
        }
        case OWNER_LOADED:
            return {
                ...state,
                loading: false,
                single: action.response,
            }

        case OWNERS_LOADING:
            return {
                ...state,
                loading: true,
                data: [],
            }

        case LOADING_PRICE_POINTS:
            return {
                ...state,
                loading: true,
            }

        case OWNER_PRICE_POINTS_LOADED:
            return {
                ...state,
                priceList: action.response,
                loading: false,
            }

        default:
            return state
    }
}
