import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { getRegionalSettings } from '../utils/settings-api'
import Webapi from '../utils/webapi'
import auth from '../auth'

const webapi = () => new Webapi().authorization(auth.get())

class AutoConsignmentBooking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            terminals: [],
            settings: [],
        }

        this.isTerminalDisabled = this.isTerminalDisabled.bind(this)
        this.renderTerminalRow = this.renderTerminalRow.bind(this)
        this.updateTerminal = this.updateTerminal.bind(this)
        this.fetchSettings = this.fetchSettings.bind(this)
    }

    fetchSettings() {
        webapi()
            .path('terminals')
            .path('auto-consignment-booking-settings')
            .get()
            .then((resp) => this.setState({ settings: resp.terminals }))
    }

    componentDidMount() {
        getRegionalSettings().then((resp) => this.setState({ terminals: resp.terminals }))
        this.fetchSettings()
    }

    updateTerminal(terminalCode, disabled) {
        webapi()
            .path('terminals')
            .path(terminalCode)
            .path('auto-consignment-booking')
            .contentType('application/json')
            .put({ disabled })
            .then(() => this.fetchSettings())
    }

    isTerminalDisabled(terminalCode) {
        return (
            this.state.settings
                .filter((terminal) => terminal.terminalCode === terminalCode)
                .map((terminal) => terminal.disabled)[0] || false
        )
    }

    renderTerminalRow(terminal) {
        const disabled = this.isTerminalDisabled(terminal.code)
        const bsStyle = disabled ? 'danger' : 'success'
        return (
            <tr key={terminal.code}>
                <td>{terminal.label}</td>
                <td>
                    <Button bsStyle={bsStyle} onClick={() => this.updateTerminal(terminal.code, !disabled)}>
                        {disabled ? 'Disabled' : 'Enabled'}
                    </Button>
                </td>
            </tr>
        )
    }

    render() {
        const { terminals } = this.state
        return (
            <div style={{ padding: '20px' }}>
                <Table>
                    <thead>
                        <tr>
                            <th>Terminal</th>
                            <th>Status (Click to Toggle)</th>
                        </tr>
                    </thead>
                    <tbody>{terminals.map(this.renderTerminalRow)}</tbody>
                </Table>
            </div>
        )
    }
}

export default AutoConsignmentBooking
