import React from 'react'
import { getDisplayName } from './display-name'

export default function pure(WrappedComponent) {
    class Pure extends React.PureComponent {
        render() {
            return <WrappedComponent {...this.state} {...this.props} />
        }
    }
    Pure.displayName = `Pure(${getDisplayName(WrappedComponent)})`
    return Pure
}
