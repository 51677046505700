import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.invoices-v1+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .contentType(contentType)
        .accept(contentType)
        .path('invoices')

export const getAllInvoicesForMerchant = (id) =>
    webapi()
        .path('merchants')
        .path(id)
        .path('invoice-items')
        .get()

export const getAllInvoicesForCourier = (id) =>
    webapi()
        .path('couriers')
        .path(id)
        .path('invoice-items')
        .get()

export const getAllInvoicesForRoute = (id) =>
    webapi()
        .path('routes')
        .path(id)
        .path('invoice-items')
        .get()

export const getAllRecurringInvoicesForMerchant = (id) =>
    webapi()
        .path('merchants')
        .path(id)
        .path('recurring-invoice-items')
        .get()

export const getAllRecurringInvoicesForCourier = (id) =>
    webapi()
        .path('couriers')
        .path(id)
        .path('recurring-invoice-items')
        .get()

export const createInvoiceForMerchant = (id, invoice) =>
    webapi()
        .path('merchants')
        .path(id)
        .path('invoice-items')
        .post(invoice)

export const createInvoiceForRoute = (id, invoice) =>
    webapi()
        .path('routes')
        .path(id)
        .path('invoice-items')
        .post(invoice)

export const createInvoiceForCourier = (id, invoice) =>
    webapi()
        .path('couriers')
        .path(id)
        .path('invoice-items')
        .post(invoice)

export const createRecurringInvoiceForMerchant = (id, recurringInvoice) =>
    webapi()
        .path('merchants')
        .path(id)
        .path('recurring-invoice-items')
        .post(recurringInvoice)

export const createRecurringInvoiceForCourier = (id, recurringInvoice) =>
    webapi()
        .path('couriers')
        .path(id)
        .path('recurring-invoice-items')
        .post(recurringInvoice)

export const deleteInvoice = (id) =>
    webapi()
        .path('invoice-items')
        .path(id)
        .delete()

export const deleteRecurringInvoice = (id) =>
    webapi()
        .path('recurring-invoice-items')
        .path(id)
        .delete()

export const uploadCourierInvoicesFromFile = (file, id) =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .path('invoices/couriers')
        .path(id)
        .path('invoice-items-file/v2')
        .put(file, false)
