import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from 'react-select'
import * as actions from '../../actions/terminals'

const toOptions = (terminals) => {
    if (terminals == null) {
        return []
    }
    return terminals.map((t) => ({
        value: t.id,
        label: `${t.name} - ${t.address.street}`,
        data: t,
    }))
}

/**
    Renders selectable List of all Terminals.
    onSelect returns selected Terminal as full object
*/
class TerminalSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            loading: true,
        }
    }

    componentDidMount() {
        this.props.actions
            .fetchTerminalsWebapi()
            .then(() => {
                const { terminals, value, multi } = this.props
                const { selected } = this.state

                if (selected == null && value != null && (!multi || value.length > 0)) {
                    this.preSelect(terminals, value)
                }
            })
            .finally(() => this.setState({ loading: false }))
    }

    getSelectedTerminal = (terminals, value) =>
        terminals.find(
            (terminal) =>
                terminal.data.id === value ||
                terminal.data.id === value.id ||
                terminal.data.code === value ||
                terminal.data.code === value.code,
        )

    getSelectedTerminals = (terminals, values) =>
        terminals.filter(
            (terminal) =>
                values.find(
                    (value) =>
                        terminal.data.id === value ||
                        terminal.data.id === value.id ||
                        terminal.data.code === value ||
                        terminal.data.code === value.code,
                ) != null,
        )

    preSelect = (terminals, value) => {
        const { multi } = this.props
        const valuesOrValue =
            multi && Array.isArray(value)
                ? this.getSelectedTerminals(terminals, value)
                : this.getSelectedTerminal(terminals, value)

        this.onChange(valuesOrValue)
    }

    onChange = (selected) => {
        const { multi, onSelect } = this.props
        this.setState({ selected }, () => {
            if (multi) {
                onSelect(selected ? selected.map((x) => x.data) : [])
            } else {
                onSelect(selected ? selected.data : null)
            }
        })
    }

    getOptions = (applicableTerminals) => {
        const { displayLabel } = this.props
        if (displayLabel == null) {
            return applicableTerminals
        }

        return applicableTerminals.map((x) => ({ ...x, label: displayLabel(x) }))
    }

    render() {
        const { multi, terminals, disabled, countryFilter } = this.props
        const { selected: selectedOption, loading } = this.state
        const applicableTerminals = countryFilter
            ? terminals.filter((terminal) => terminal.data.address.countryCode === countryFilter)
            : terminals

        const placeholder = multi ? 'Select terminals...' : 'Select terminal...'

        return (
            <div style={{ width: '500px' }}>
                <Select
                    disabled={loading || disabled}
                    placeholder={placeholder}
                    value={selectedOption}
                    options={this.getOptions(applicableTerminals)}
                    allowCreate={false}
                    ignoreCase
                    searchable
                    clearable
                    backspaceRemoves={false}
                    onChange={this.onChange}
                    multi={multi}
                />
            </div>
        )
    }
}

const mapStateToProps = ({ terminals: { terminals } }) => ({ terminals: toOptions(terminals) })

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TerminalSelect)

TerminalSelect.propTypes = {
    actions: PropTypes.object,
    terminals: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.number]),
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
    displayLabel: PropTypes.func,
    countryFilter: PropTypes.string,
}

TerminalSelect.defaultProps = {
    multi: false,
    disabled: false,
    countryFilter: null,
}
