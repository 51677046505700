import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import Form from './form'
import empty from '../../../utils/empty'
import { handleError } from '../../../utils/handle-error'
import { createMerchantCollectionPoint } from '../../../utils/merchant-webapi'
import { saveInjectionTerminal, saveOrderFulfillmentTime } from '../../../utils/collection-point-webapi'

const validate = ({
    name,
    phoneNumber,
    address: {
        street,
        postalCode,
        city,
        countryCode,
        coordinate: { latitude, longitude },
    },
    injectionTerminal,
    orderFulfillmentTime,
}) => {
    const nonEmptyFields = [name, phoneNumber, street, postalCode, city, countryCode, injectionTerminal]

    return (
        nonEmptyFields.every((v) => !empty(v)) &&
        !Number.isNaN(latitude) &&
        !Number.isNaN(longitude) &&
        !Number.isNaN(orderFulfillmentTime)
    )
}

export default class CreateMerchantCollectionPoint extends Component {
    createBuyerCollectionPoint = (collectionPoint) =>
        createMerchantCollectionPoint(this.props.params.id, { collectionPoint, postalcodeZones: [] })
            .then(({ id }) =>
                saveInjectionTerminal(id, collectionPoint.injectionTerminal).then(() =>
                    saveOrderFulfillmentTime(id, collectionPoint.orderFulfillmentTime),
                ),
            )
            .then(this.transition)
            .catch(handleError)

    transition = () => this.props.router.push(`/admin/merchants/${this.props.params.id}`)

    render() {
        return (
            <Grid fluid>
                <Row>
                    <Col md={6} mdOffset={1}>
                        <Form
                            onSubmit={this.createBuyerCollectionPoint}
                            onCancel={this.transition}
                            validate={validate}
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

CreateMerchantCollectionPoint.propTypes = {
    params: PropTypes.object,
    router: PropTypes.object,
}
