import React from 'react'
import PropTypes from 'prop-types'

/**
 * Render General information about an Order (Ids, timestamps etc)
 */
const General = ({ order }) => {
    if (order === null || typeof order === 'undefined') {
        return null
    }

    return (
        <div>
            <dl className="dl-horizontal">
                <dt>Merchant</dt>
                <dd>{order.buyer.name}</dd>
                <dt>Order ID</dt>
                <dd>{order.id}</dd>
                <dt>API ID</dt>
                <dd>{order.oldId}</dd>
                <dt>Merchant Order Reference</dt>
                <dd>{order.cartId}</dd>
            </dl>
        </div>
    )
}

General.propTypes = {
    order: PropTypes.object.isRequired,
}

export default General
