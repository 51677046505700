import React from 'react'
import Loader from 'react-loader'
import { Grid, Row, Col, Table } from 'react-bootstrap'
import Select from 'react-select'
import moment from 'moment'
import Notifier from '../utils/notifier'
import TimeoutComponent from '../utils/timeout-component'
import HtmlTitle from './html-title'
import { getCouriers, getDrivers } from '../utils/courier-webapi'
import { getSchedulableDates, getSchedulableRoutes, assignDrivers } from '../utils/route-webapi'
import auth from '../auth'
import { curry } from '../utils/curry'
import { displayName } from '../utils/display-name'
import { sortNumbers, reverseSorter, composeSort, mapSort, sortStrings } from '../utils/sorting'
import TerminalSelect from './common/terminal-select'
import { pluck } from '../utils/pluck'
import { hd } from '../utils/hd'
import { handleError } from '../utils/handle-error'
import VehicleTypes from '../utils/vehicle-types'
import RouteTypes from '../utils/route-types'
import { COURIER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER } from '../utils/role'

const vehicleTypes = VehicleTypes()
const routeTypes = RouteTypes()
const TIME_FORMAT = 'HH:mm'
const DATE_FORMAT = 'YYYY-MM-DD'

const sorter = composeSort(
    mapSort(sortStrings, pluck('routeType')),
    mapSort(sortStrings, pluck('terminal')),
    mapSort(sortNumbers, pluck('collectionStartDate')),
    mapSort(sortNumbers, pluck('collectionEndDate')),
)

const renderCollectionInterval = (route) => {
    const { collectionStartDate: startDate, collectionEndDate: endDate, timeZone } = route
    const start =
        startDate != null
            ? moment(startDate)
                  .tz(timeZone)
                  .format(TIME_FORMAT)
            : null
    const stop =
        endDate != null
            ? moment(endDate)
                  .tz(timeZone)
                  .format(TIME_FORMAT)
            : null

    return (
        <span>
            {start} - {stop}
        </span>
    )
}

const renderDateOptions = (dueDates) =>
    dueDates.map((m) => (
        <option key={m} value={m}>
            {m}
        </option>
    ))

export default class SchedulerHandler extends TimeoutComponent {
    scheduleAll = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER)

    state = {
        alerts: [],
        couriers: [],
        drivers: {},
        dueDates: [],

        routes: [],
        routesToSave: {},

        selectedCouriers: {},
        selectedGarages: {},
        selectedDrivers: {},
        selectedVehicles: {},

        selectedDate: '',
        isRefreshing: false,
        isSaving: false,

        filter: {
            terminals: [],
            routeType: null,
            courierId: null,
        },
    }

    componentDidMount() {
        const hasAuth = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER, COURIER)
        if (!hasAuth) {
            this.props.router.push('/admin')
            return
        }

        this.fetchData()
    }

    notificationDismiss() {
        if (this.notificationTimeout != null) {
            clearTimeout(this.notificationTimeout)
        }
    }

    fetchData = () => {
        Promise.all([getCouriers(), getSchedulableDates()])
            .then(([couriers, dueDatesEpoch]) => {
                const now = moment()
                    .startOf('day')
                    .format(DATE_FORMAT)
                const dueDates = dueDatesEpoch
                    .sort(reverseSorter(sortNumbers))
                    .map((d) => moment(d).format(DATE_FORMAT))
                const selectedDate = dueDates.includes(now) ? now : moment(hd(dueDates)).format(DATE_FORMAT)
                this.setState({ couriers, dueDates, selectedDate })
            })
            .catch(handleError)
    }

    fetchRoutes = () => {
        this.setState({ isRefreshing: true })
        const { selectedDate, filter } = this.state
        const req = {
            date: selectedDate,
            terminals: filter.terminals.map(({ code }) => code),
            routeType: filter.routeType,
            courierId: filter.courierId,
        }
        getSchedulableRoutes(req)
            .then(this.routesFetched)
            .catch(handleError)
    }

    routesFetched = (routes) => {
        const state = { ...this.state, routes, isRefreshing: false }
        const ongoingFetch = {
            drivers: {},
        }
        routes
            .filter((route) => route.courierId != null)
            .forEach((route) => {
                const { routeId, courierId } = route
                state.routesToSave[routeId] = false
                state.selectedCouriers[routeId] = courierId

                if (state.drivers[courierId] == null && ongoingFetch.drivers[courierId] == null) {
                    ongoingFetch.drivers[courierId] = true
                    this.fetchDrivers(courierId)
                }

                if (route.garageId != null) {
                    state.selectedGarages[routeId] = route.garageId
                }
                if (route.vehicleType != null) {
                    state.selectedVehicles[routeId] = route.vehicleType
                }
                if (route.driverId != null) {
                    state.selectedDrivers[routeId] = route.driverId
                }
            })

        this.setState(state)
    }

    fetchDrivers(courierId) {
        getDrivers(courierId)
            .then((res) => {
                this.setState((state) => ({
                    drivers: {
                        ...state.drivers,
                        [courierId]: res,
                    },
                }))
            })
            .catch(handleError)
    }

    onSubmit = (e) => {
        e.preventDefault()

        const { selectedCouriers, selectedDrivers, selectedVehicles, routesToSave } = this.state

        this.setState({ isSaving: true })
        const routes = this.state.routes
            .filter((route) => this.state.routesToSave[route.routeId])
            .map((route) => ({
                routeId: route.routeId,
                ownerOperatorId: selectedCouriers[route.routeId] !== 0 ? selectedCouriers[route.routeId] : null,
                userId: selectedDrivers[route.routeId] !== 0 ? selectedDrivers[route.routeId] : null,
                vehicleType: selectedVehicles[route.routeId] !== '' ? selectedVehicles[route.routeId] : null,
            }))

        // Reset checkboxes
        Object.keys(routesToSave).forEach((routeId) => {
            routesToSave[routeId] = false
        })

        assignDrivers(routes)
            .then(this.savedRoute)
            .then(() => this.setState({ routesToSave }))
            .catch(this.saveRouteFailed)
    }

    onCourierChange = curry((routeId, option) => {
        const courierId = option != null ? parseInt(option.value, 10) : NaN
        const { drivers, routesToSave, selectedCouriers, selectedDrivers } = this.state

        if (!Number.isNaN(courierId)) {
            if (drivers[courierId] == null) {
                this.fetchDrivers(courierId)
            }
        }

        routesToSave[routeId] = true
        selectedCouriers[routeId] = courierId
        selectedDrivers[routeId] = 0
        this.setState((state) => ({
            ...state,
            drivers,
            routesToSave,
            selectedCouriers,
            selectedDrivers,
        }))
    })

    onVehicleChange = curry((routeId, option) => {
        const vehicle = option != null ? option.value : null
        const { routesToSave, selectedVehicles } = this.state

        routesToSave[routeId] = true
        selectedVehicles[routeId] = vehicle

        this.setState((state) => ({
            ...state,
            routesToSave,
            selectedVehicles,
        }))
    })

    onDateChange = (e) => {
        this.setState({ selectedDate: e.target.value }, this.fetchRoutes)
    }

    onNumberChange = curry((type, routeId, option) => {
        const { routesToSave, [type]: typ } = this.state
        routesToSave[routeId] = true
        typ[routeId] = option != null ? parseInt(option.value, 10) : NaN
        this.setState((state) => ({ ...state, routesToSave, [type]: typ }))
    })

    filterChange = (tag, value) =>
        this.setState((state) => ({ filter: { ...state.filter, [tag]: value } }), this.fetchRoutes)

    toggleChanged = curry((routeId, e) => {
        this.setState((state) => ({
            routesToSave: {
                ...state.routesToSave,
                [routeId]: e.target.checked,
            },
        }))
    })

    getCourierOption = ({ id: value, name: label }) => ({ value, label })

    getVehicleOption = ({ id: value, name: label }) => ({ value, label })

    getDriverOption = ({ id: value, firstName, lastName, phoneNumber, email }) => ({
        value,
        label: displayName(' • ', displayName(' ', firstName, lastName), phoneNumber, email),
    })

    renderCourierSelect(route, selectedCourier) {
        if (!this.scheduleAll) {
            return null
        }

        const courierSelectOptions = this.state.couriers.map(this.getCourierOption)
        return (
            <td>
                <Select
                    className="selectwidth"
                    value={courierSelectOptions.find(({ value }) => value === selectedCourier)}
                    placeholder="None"
                    options={courierSelectOptions}
                    onChange={this.onCourierChange(route.routeId)}
                />
            </td>
        )
    }

    renderVehicleSelect(route, selectedVehicle) {
        if (!this.scheduleAll) {
            return null
        }

        return (
            <td>
                <Select
                    className="selectwidth"
                    value={vehicleTypes.find(({ value }) => value === selectedVehicle)}
                    placeholder="None"
                    options={vehicleTypes}
                    onChange={this.onVehicleChange(route.routeId)}
                />
            </td>
        )
    }

    renderTableRows = (route) => {
        const { [route.routeId]: selectedCourier = 0 } = this.state.selectedCouriers
        const { [route.routeId]: selectedUserId = 0 } = this.state.selectedDrivers
        const { [route.routeId]: selectedVehicle = '' } = this.state.selectedVehicles
        const { [selectedCourier]: drivers = [] } = this.state.drivers
        const driversSelectOptions = drivers.map(this.getDriverOption)
        const initialDriverSelection = driversSelectOptions.find((user) => user.value === selectedUserId)

        return (
            <tr key={route.routeId}>
                <td>
                    <input
                        type="checkbox"
                        onChange={this.toggleChanged(route.routeId)}
                        checked={this.state.routesToSave[route.routeId]}
                    />
                </td>
                <td>{route.routeId}</td>
                <td>{route.routeType}</td>
                <td>{route.terminal || route.city}</td>
                <td>{route.name}</td>
                <td>{renderCollectionInterval(route)}</td>
                <td>{route.stopCount}</td>
                {this.renderCourierSelect(route, selectedCourier)}
                {this.renderVehicleSelect(route, selectedVehicle)}
                <td>
                    <Select
                        className="selectwidth"
                        value={initialDriverSelection}
                        placeholder="None"
                        options={driversSelectOptions}
                        onChange={this.onNumberChange('selectedDrivers', route.routeId)}
                    />
                </td>
                <td>
                    <a href={`/admin/map/${route.routeId}`} target="_blank" rel="noopener noreferrer">
                        View
                    </a>
                </td>
            </tr>
        )
    }

    renderTable(routes) {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th> </th>
                        <th>#</th>
                        <th>Type</th>
                        <th>Terminal</th>
                        <th>Name</th>
                        <th>Collection</th>
                        <th>Stops</th>
                        {this.scheduleAll ? <th>Courier</th> : null}
                        {this.scheduleAll ? <th>Vehicle</th> : null}
                        <th>Driver</th>
                        <th>Map</th>
                    </tr>
                </thead>

                <tbody>{routes.sort(sorter).map(this.renderTableRows)}</tbody>
            </Table>
        )
    }

    render() {
        const { filter } = this.state

        return (
            <Grid fluid className="scheduler">
                <HtmlTitle title="Scheduler" />
                <Notifier alerts={this.state.alerts} onDismiss={this.notificationDismiss} />

                <Row>
                    <Col md={2}>
                        <select className="form-control" value={this.state.selectedDate} onChange={this.onDateChange}>
                            {renderDateOptions(this.state.dueDates)}
                        </select>
                    </Col>
                    {this.scheduleAll ? (
                        <Col md={2}>
                            <TerminalSelect
                                multi
                                value={filter.terminals}
                                onSelect={(selectedTerminals) => this.filterChange('terminals', selectedTerminals)}
                                displayLabel={(terminal) => terminal.data.name}
                            />
                        </Col>
                    ) : null}
                    {this.scheduleAll ? (
                        <Col md={2}>
                            <Select
                                value={this.state.filter.routeType}
                                onChange={(e) => this.filterChange('routeType', e ? e.value : null)}
                                options={routeTypes}
                                placeholder="Select type..."
                                clearable
                            />
                        </Col>
                    ) : null}
                    {this.scheduleAll ? (
                        <Col md={2}>
                            <Select
                                value={this.state.filter.courierId}
                                onChange={(e) => this.filterChange('courierId', e ? e.value : null)}
                                options={this.state.couriers
                                    .sort(mapSort(sortStrings, ({ name }) => name))
                                    .map((courier) => ({
                                        value: courier.id,
                                        label: courier.name,
                                    }))}
                                placeholder="Select courier..."
                                clearable
                            />
                        </Col>
                    ) : null}
                    <Col md={1}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={this.state.isRefreshing}
                            onClick={this.fetchRoutes}
                            title="Search"
                        >
                            <i className="glyphicon glyphicon-search" />
                        </button>
                    </Col>
                    <Col md={1} className="pull-right">
                        <form method="post" className="form-inline" onSubmit={this.onSubmit}>
                            <button type="submit" className="btn btn-primary" disabled={this.state.isSaving}>
                                Save
                            </button>
                        </form>
                    </Col>
                </Row>

                <Loader color="#bfbfbf" loaded={!this.state.isRefreshing}>
                    {this.renderTable(this.state.routes)}
                </Loader>
            </Grid>
        )
    }

    savedRoute = () => {
        this.doneSaving('success', 'Save successful. Drivers has been notified by sms.')
        this.fetchRoutes()
    }

    saveRouteFailed = (res) => {
        console.error(res)
        this.doneSaving('danger', 'Save failed')
    }

    doneSaving = (type, message) => {
        this.setState(
            {
                isSaving: false,
                alerts: [{ type, message }],
            },
            this.clearAlerts,
        )
    }

    clearAlerts() {
        this.notificationTimeout = this.setTimeout(() => this.setState({ alerts: [] }), 3000)
    }
}
