import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Loader from 'react-loader'
import moment from 'moment'
import Table from '../traffix/table'
import { getCommentPermissions, addStopComment, addRouteComment } from '../../utils/comment-webapi'
import { getSingleTraffix } from '../../utils/route-webapi'
import { handleError } from '../../utils/handle-error'

export default class RouteModal extends React.Component {
    static propTypes = {
        routeId: PropTypes.number.isRequired,
        onHide: PropTypes.func,
        show: PropTypes.bool,
    }

    state = {
        traffixData: [],
        routeComments: [],
        commentPermissions: [],
        loaded: false,
    }

    componentDidMount() {
        if (this.props.routeId != null) {
            this.fetchData()
            this.fetchRoute()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.routeId != null) {
                this.fetchRoute()
            }
        }
    }

    fetchData = () => {
        getCommentPermissions().then((commentPermissions) => this.setState({ commentPermissions }))
    }

    fetchRoute = () => {
        getSingleTraffix(this.props.routeId)
            .then(({ traffixData, routeComments }) =>
                this.setState({
                    traffixData,
                    routeComments,
                    loaded: true,
                }),
            )
            .catch(handleError)
    }

    filterRoutes = (route) => route.traffixData != null && route.traffixData.length > 0

    submitComment = (routeId, dataId, commentType, comment, permissionIds) => {
        const data = {
            message: comment,
            permissionIds,
            date: moment(),
        }

        if (commentType === 'stop') {
            addStopComment(dataId, data)
                .then(() => {
                    const stops = this.state.traffixData
                    const stop = stops.find((s) => s.stopId === dataId)

                    // Push the comment onto `stopComments` so it is visible immediately
                    data.admin = true
                    data.userName = ''
                    stop.stopComments.push(data)

                    this.setState({ traffixData: stops })
                })
                .catch(handleError)
        } else if (commentType === 'route') {
            addRouteComment(routeId, data)
                .then(() => {
                    const { routeComments } = this.state
                    routeComments.push(data)

                    this.setState({ routeComments })
                })
                .catch(handleError)
        }
    }

    renderRoute(data) {
        return Object.keys(data)
            .filter((r) => this.filterRoutes(data[r]))
            .map((r) => {
                const routeData = data[r]
                if (routeData == null) {
                    return null
                }
                return (
                    <Table
                        key={routeData}
                        ref={`table-${r}`}
                        routeId={r}
                        isSearch="ignored"
                        route={routeData}
                        routeComments={this.state.routeComments}
                        commentPermissions={this.state.commentPermissions}
                        latestUpdate={moment()}
                        onCommentSubmit={this.submitComment}
                    />
                )
            })
    }

    render() {
        // Skit grej!
        const obj = {
            [this.props.routeId]: {
                traffixData: this.state.traffixData,
            },
        }

        return (
            <Modal
                show={this.props.show}
                onHide={() => this.props.onHide(null)}
                title={`Route ${this.props.routeId}`}
                className="modal-route"
                animation={false}
            >
                <div className="modal-body padd-down padd-up">
                    <Loader color="#BFBFBF" loaded={this.state.loaded}>
                        {this.renderRoute(obj)}
                    </Loader>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" onClick={this.props.onHide}>
                        Close
                    </button>
                </div>
            </Modal>
        )
    }
}
