import { PARCEL_DISCOUNTS_LOADED } from '../constants/actionTypes'
import { mapSort, sortNumbers } from '../utils/sorting'
import { prop } from '../utils/prop'

export default function BuyersReducer(state = {}, action) {
    switch (action.type) {
        case PARCEL_DISCOUNTS_LOADED:
            return {
                ...state,
                /* Sort Tiers by Weight */
                parcelDiscounts: action.response.sort(mapSort(sortNumbers, prop('fromQuantity'))),
            }

        default:
            return state
    }
}
