import * as types from '../constants/actionTypes'
import { getRoles } from '../utils/role-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

export const rolesLoaded = (response) => ({
    type: types.ROLES_LOADED,
    status: 200,
    response,
})

const toOption = ({ id: value, name: label }) => ({ value, label })
const toOptions = (things) => things.map(toOption)

export const fetchRoles = () => (dispatch) =>
    getRoles()
        .then(compose(dispatch, rolesLoaded, toOptions))
        .catch(handleError)
