import Webapi from './webapi'
import auth from '../auth'

const contentTypeV1 = 'application/vnd.budbee.couriers-v1+json'
const contentTypeV4 = 'application/vnd.budbee.couriers-v4+json'

const webapi = (accept, contentType = accept) => {
    let w = new Webapi().authorization(auth.get()).path('couriers')

    if (accept != null) {
        w.accept(accept)
    }
    if (contentType != null) {
        w = w.contentType(contentType)
    }

    return w
}

export const createCourier = (courier) => webapi(contentTypeV4).post(courier)

export const getCouriers = () => webapi(contentTypeV1).get()

export const getCourier = (id) =>
    webapi(contentTypeV4)
        .path(id)
        .get()

export const editCourier = (id, courier) =>
    webapi(contentTypeV4)
        .path(id)
        .put(courier)

export const deleteCourier = (id) =>
    webapi(contentTypeV1)
        .path(id)
        .delete()

export const getCourierPricePoints = (courierId) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('price-points')
        .get()

export const createCourierPricePoints = (courierId, request) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('price-points')
        .post(request)

export const removeCourierPricePoints = (courierId, pricePointIds) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('price-points')
        .delete(pricePointIds)

export const getDrivers = (courierId) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('drivers')
        .get()

export const getCompensationTiers = (courierId, validTo) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('compensation-tiers')
        .queryParam('validTo', validTo)
        .get()

export const postCompensationTier = (courierId, compensationTier) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('compensation-tiers')
        .post(compensationTier)

export const postCompensationTiers = (courierId, compensationTiers) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('compensation-tiers-list')
        .post(compensationTiers)

export const putCompensationTier = (courierId, compensationTier) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('compensation-tiers')
        .put(compensationTier)

export const dropCompensationTiers = (courierId, compensationTiers) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('compensation-tiers')
        .delete(compensationTiers)

export const putEndCourierCompensationTiers = (courierId, validTo, routeTypeToEnd) =>
    webapi(contentTypeV1)
        .path(courierId)
        .path('end-compensation-tiers')
        .put({ validTo, routeType: routeTypeToEnd })
