import React from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap/lib'
import PropTypes from 'prop-types'
import PostalCodeZones from '../../postal-code-zones/postal-code-zones'
import AddTier from './components/add'
import Tiers from './components/tiers'
import DistributionTiers from './components/tiers/distribution-tiers'

import CollectionPointPicker from '../collection-point-picker'
import { PriceTierType } from '../../../utils/types'
import auth from '../../../auth'
import { COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR } from '../../../utils/role'

export const priceTierData = (priceTiers, type) => {
    if (!priceTiers) {
        return []
    }
    return priceTiers[type]
}

export const enumTierOptions = (enumTiers, type) => {
    if (!enumTiers) {
        return []
    }
    const uniqueTiers = new Set()
    enumTiers.filter((o) => o.type === type && !o.deprecated).forEach((o) => uniqueTiers.add(o.enumString))
    return [...uniqueTiers.values()].map((o) => ({ label: o, value: o }))
}

export const enumTierQuantityMap = (enumTiers, type) => {
    if (!enumTiers) {
        return new Map()
    }
    return new Map(enumTiers.filter((o) => o.type === type && !o.deprecated).map((o) => [o.enumString, o.quantity]))
}
export const quantityEnumTierMap = (enumTiers, type) => {
    if (!enumTiers) {
        return new Map()
    }
    return new Map(enumTiers.filter((o) => o.type === type).map((o) => [o.quantity, o.enumString]))
}

const collectionPointSelector = (merchantId) => (onChange) => (
    <CollectionPointPicker buyer={{ id: merchantId }} onCollectionPointChange={(cp) => onChange([{ value: cp.id }])} />
)
const postalCodeZoneSelector = (postalCodes) => (onChange, newZones) => (
    <PostalCodeZones disable newZones={newZones} zones={postalCodes.map((zones) => zones)} onChange={onChange} />
)
const renderMerchantParcelTiers = (merchantId, priceTiers, addPriceTier, modifyPriceTiers, deletePriceTier) => {
    const data = priceTierData(priceTiers, PriceTierType.MerchantParcels)
    const unit = { name: 'Parcels', code: 'parcel' }
    const canEdit = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
    return (
        <div>
            <div style={{ padding: 10 }}>
                <ul>
                    <li>This prices only applies for on demand pickups.</li>
                    <li>
                        Here you can manage Price Tiers associated with a specific merchant&apos;s collection point.
                    </li>
                    <li>Each collection point can have multiple Price Tiers</li>
                    <li>Prices apply up to and including the top parcel amount</li>
                </ul>
            </div>
            {canEdit ? (
                <AddTier
                    unit={unit}
                    partitionRenderer={collectionPointSelector(merchantId)}
                    partitionName="Collection points"
                    priceTiers={data}
                    priceTierType={PriceTierType.MerchantParcels}
                    addPriceTier={addPriceTier}
                    enumTier={false}
                />
            ) : null}
            <Tiers
                partitionName="Collection points"
                unit={unit}
                priceTiers={data}
                deletePriceTier={canEdit ? deletePriceTier : null}
                modifyPriceTiers={(form) => modifyPriceTiers(PriceTierType.MerchantParcels, form)}
                enumTier={false}
            />
        </div>
    )
}

const renderMerchantPalletTiers = (merchantId, priceTiers, addPriceTier, modifyPriceTiers, deletePriceTier) => {
    const data = priceTierData(priceTiers, PriceTierType.MerchantPallets)
    const unit = { name: 'Pallets', code: 'pallet' }
    const canEdit = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
    return (
        <div>
            <div style={{ padding: 10 }}>
                <ul>
                    <li>This prices only applies for recurring pickup routes.</li>
                    <li>
                        Here you can manage Price Tiers associated with a specific merchant&apos;s collection point.
                    </li>
                    <li>Each collection point can have multiple Price Tiers</li>
                    <li>Prices apply up to and including the top pallet amount</li>
                </ul>
            </div>
            {canEdit ? (
                <AddTier
                    unit={unit}
                    partitionRenderer={collectionPointSelector(merchantId)}
                    partitionName="Collection points"
                    priceTiers={data}
                    priceTierType={PriceTierType.MerchantPallets}
                    addPriceTier={addPriceTier}
                    enumTier={false}
                />
            ) : null}
            <Tiers
                partitionName="Collection points"
                unit={unit}
                priceTiers={data}
                deletePriceTier={canEdit ? deletePriceTier : null}
                modifyPriceTiers={(form) => modifyPriceTiers(PriceTierType.MerchantPallets, form)}
                enumTier={false}
            />
        </div>
    )
}

const renderDistributionTiers = (postalZones, priceTiers, addPriceTier, modifyPriceTiers, deletePriceTier) => {
    return (
        <DistributionTiers
            postalZones={postalZones}
            priceTiers={priceTiers}
            addPriceTier={addPriceTier}
            modifyPriceTiers={modifyPriceTiers}
            deletePriceTier={deletePriceTier}
        />
    )
}

const renderBoxTiers = (postalZones, priceTiers, addPriceTier, modifyPriceTiers, deletePriceTier, tierEnums) => {
    const data = priceTierData(priceTiers, PriceTierType.BoxDistribution)
    const unit = { name: 'Box Size', code: 'Size' }
    const canEdit = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)
    const enumQuantityMap = enumTierQuantityMap(tierEnums, PriceTierType.BoxDistribution)
    const quantityEnumMap = quantityEnumTierMap(tierEnums, PriceTierType.BoxDistribution)
    const boxTiers = enumTierOptions(tierEnums, PriceTierType.BoxDistribution)
    return (
        <div>
            <div style={{ padding: 10 }}>
                <ul>
                    <li>Here you can manage Price Tiers associated with a group of Zones for this Merchant.</li>
                    <li>These Zones can have multipe price Tiers</li>
                    <li>Prices apply to the specified Box Size only</li>
                    <li>A Zone can only appear once in a group per Merchant</li>
                </ul>
            </div>
            {canEdit && postalZones ? (
                <AddTier
                    unit={unit}
                    partitionRenderer={postalCodeZoneSelector(postalZones)}
                    partitionName="Zones"
                    priceTiers={data}
                    priceTierType={PriceTierType.BoxDistribution}
                    addPriceTier={addPriceTier}
                    enumTier
                    enumQuantityMap={enumQuantityMap}
                    enumTiers={boxTiers}
                    postalZones={postalZones}
                    showSelectCountry
                />
            ) : null}
            <Tiers
                partitionName="Zones"
                unit={unit}
                priceTiers={data}
                deletePriceTier={canEdit ? deletePriceTier : null}
                modifyPriceTiers={(form) => modifyPriceTiers(PriceTierType.BoxDistribution, form)}
                quantityEnumMap={quantityEnumMap}
                enumTier
            />
        </div>
    )
}

const PriceTierModal = ({
    show,
    postalcodeZones,
    priceTiers,
    addPriceTier,
    modifyPriceTiers,
    deletePriceTier,
    closeModal,
    merchantId,
    tierEnums,
}) => (
    <div className="static-modal">
        <Modal show={show} onHide={closeModal} container={this} className="price-tiers">
            <Modal.Header closeButton>
                <Modal.Title>Manage Price Tiers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    <Tab eventKey={1} title="Distribution">
                        {renderDistributionTiers(
                            postalcodeZones,
                            priceTiers,
                            addPriceTier,
                            modifyPriceTiers,
                            deletePriceTier,
                        )}
                    </Tab>
                    <Tab eventKey={2} title="Merchant">
                        {renderMerchantPalletTiers(
                            merchantId,
                            priceTiers,
                            addPriceTier,
                            modifyPriceTiers,
                            deletePriceTier,
                        )}
                    </Tab>
                    <Tab eventKey={3} title="On Demand Pickups">
                        {renderMerchantParcelTiers(
                            merchantId,
                            priceTiers,
                            addPriceTier,
                            modifyPriceTiers,
                            deletePriceTier,
                        )}
                    </Tab>
                    <Tab eventKey={4} title="Budbee Box">
                        {renderBoxTiers(
                            postalcodeZones,
                            priceTiers,
                            addPriceTier,
                            modifyPriceTiers,
                            deletePriceTier,
                            tierEnums,
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    </div>
)

PriceTierModal.propTypes = {
    show: PropTypes.bool,
    postalcodeZones: PropTypes.array,
    priceTiers: PropTypes.object,
    addPriceTier: PropTypes.func,
    modifyPriceTiers: PropTypes.func.isRequired,
    deletePriceTier: PropTypes.func,
    closeModal: PropTypes.func,
    merchantId: PropTypes.number,
    tierEnums: PropTypes.array,
}

export default PriceTierModal
