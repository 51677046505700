import Webapi from './webapi'
import auth from '../auth'

const v2 = 'application/vnd.budbee.recurring-pickups-v2+json'
const v3 = 'application/vnd.budbee.recurring-pickups-v3+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(v2)
        .contentType(v2)
        .path('recurring-pickups')

export const getRecurringPickups = (routeType) =>
    webapi()
        .contentType(v3)
        .accept(v3)
        .queryParam('routeType', routeType)
        .get()

export const getRecurringPickup = (id) =>
    webapi()
        .contentType(v3)
        .accept(v3)
        .path(id)
        .get()

export const getRecurringPickupLocations = (dayOfWeek) =>
    webapi()
        .path('/collection-points')
        .queryParam('dayOfWeek', dayOfWeek)
        .get()

export const getMultipleRecurringPickupLocations = (daysOfWeek) =>
    webapi()
        .path('/collection-points')
        .path('multiple')
        .queryParam('daysOfWeek', daysOfWeek)
        .get()

export const createRecurringPickup = (request) =>
    webapi()
        .contentType(v3)
        .accept(v3)
        .post(request)

export const createOneTimePickup = (id, request) =>
    webapi()
        .contentType(v3)
        .accept(v3)
        .path(id)
        .path('single')
        .post(request)

export const editRecurringPickup = (id, request) =>
    webapi()
        .contentType(v3)
        .accept(v3)
        .path(id)
        .put(request)

export const deleteRecurringPickup = (id) =>
    webapi()
        .path(id)
        .delete()

export const createMerchantHoliday = (data) =>
    webapi()
        .path('merchant-holidays')
        .post(data)

export const fetchMerchantHolidays = () =>
    webapi()
        .path('merchant-holidays')
        .get()

export const removeMerchantHoliday = (id) =>
    webapi()
        .path('merchant-holidays')
        .path(id)
        .delete()

export const fetchHistory = (id) =>
    webapi()
        .path('history')
        .path(id)
        .get()
