import React from 'react'
import Creatable from 'react-select/lib/Creatable'
import PropTypes from 'prop-types'
import { Row, Col, FormControl } from 'react-bootstrap'

import pure from '../../utils/pure'

const Editor = ({
    textLabel,
    uriLabel,
    hashTagsLabel,
    value,
    uri,
    onTextChange,
    onUriChange,
    onHashtagChange,
    selectedTags,
    hashtagOptions,
}) => (
    <div style={{ marginBottom: 30 }}>
        <Row>
            <Col md={8}>
                <label>{textLabel}</label>
                <FormControl
                    rows={5}
                    componentClass="textarea"
                    value={value}
                    onChange={onTextChange}
                    style={{ maxWidth: '100%', resize: 'none' }}
                />
            </Col>
            <Col md={8} style={{ marginTop: 10 }}>
                <label>{uriLabel}</label>
                <FormControl type="url" value={uri} onChange={onUriChange} />
            </Col>
            <Col md={8} style={{ marginTop: 10 }}>
                <label>{hashTagsLabel}</label>
                <Creatable multi value={selectedTags} options={hashtagOptions} onChange={onHashtagChange} />
            </Col>
        </Row>
    </div>
)

Editor.propTypes = {
    value: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    textLabel: PropTypes.string.isRequired,
    uriLabel: PropTypes.string.isRequired,
    hashTagsLabel: PropTypes.string.isRequired,
    onTextChange: PropTypes.func,
    onUriChange: PropTypes.func,
    onHashtagChange: PropTypes.func,
    selectedTags: PropTypes.array,
    hashtagOptions: PropTypes.array,
}

export default pure(Editor)
