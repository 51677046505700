import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { login } from '../../actions/user'
import auth from '../../auth'

const parseAuth0 = (auth0) => {
    const decoded = decodeURI(auth0.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
    return JSON.parse(`{"${decoded}"}`)
}

const Session = (props) => {
    const { id_token: token } = parseAuth0(props.location.hash)
    const requestedPage = auth.popRequestedPage()
    const redirectTo = !requestedPage || requestedPage === 'null' ? '/admin' : requestedPage
    props.login(token, () => props.router.push(redirectTo))

    return <div />
}

Session.propTypes = {
    location: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
    login: (token, callback) => dispatch(login(token, callback)),
})

export default connect(() => ({}), mapDispatchToProps)(Session)
