import React from 'react'
import CSSTransitionGroup from 'react-addons-css-transition-group'
import { curry } from './curry'

export default class Notifier extends React.Component {
    state = {
        dismissedAlerts: [],
    }

    dismiss = curry((item, e) => {
        this.setState({ dismissedAlerts: [...this.state.dismissedAlerts, item] })

        if (this.props.onDismiss != null) {
            this.props.onDismiss(item)
        }
    })

    renderAlert = (alert, i) => {
        const headline = alert.headline != null ? <strong>{alert.headline}&nbsp;</strong> : null

        return (
            <div className={`alert alert-dismissable alert-${alert.type}`} key={i}>
                <button type="button" className="close" title="Dismiss" onClick={this.dismiss(alert)}>
                    &times;
                </button>
                {headline}
                {alert.message}
            </div>
        )
    }

    render() {
        const alerts = this.props.alerts.filter((alert) => !this.state.dismissedAlerts.some((a) => a === alert))

        return (
            <div className="alert-notifier-container">
                <CSSTransitionGroup transitionName="alert" transitionEnterTimeout={0} transitionLeaveTimeout={0}>
                    {alerts.map(this.renderAlert)}
                </CSSTransitionGroup>
            </div>
        )
    }
}

Notifier.propTypes = {
    alerts: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            type: React.PropTypes.oneOf(['success', 'info', 'warning', 'danger']).isRequired,
            headline: React.PropTypes.string,
            message: React.PropTypes.string,
        }),
    ).isRequired,
    onDismiss: React.PropTypes.func,
}
