import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, FormControl, ControlLabel, Form as BsForm, Button, Checkbox } from 'react-bootstrap'
import Select from 'react-select'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as courierActions from '../../../actions/couriers'
import * as regionalSettingsActions from '../../../actions/regional-settings'
import empty from '../../../utils/empty'
import BillingPeriods from '../../../utils/billing-periods'
import DueDaysInvoices from '../../../utils/due-days-invoices'
import { INVOICING_SETTINGS, GENERAL_SETTINGS } from '../../../utils/couriers'
import AlertGroup from '../../common/alert-group'
import isEmptyValue from '../../../utils/isEmptyValue'

const billingPeriods = BillingPeriods()
const dueDaysInvoices = DueDaysInvoices()

class EditForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dueDaysInvoice: {
                value: this.props.courierSettings.dueDaysInvoice,
                label: this.props.courierSettings.dueDaysInvoice,
            },
            error: null,
            updated: false,
            courier: {
                ...this.props.courier,
            },
            courierSettings: {
                ...this.props.courierSettings,
            },
        }
    }

    componentDidMount() {
        this.props.actions.fetchRegionalSettings()
    }

    onNameChange = (name, prop) => (e) => {
        const value = e.target[name]
        this.setState((prevState) => ({
            courier: {
                ...prevState.courier,
                [prop]: value,
            },
        }))
    }

    onCountriesChange = (options) => {
        const selectedCountries = []
        options.forEach((option) => {
            selectedCountries.push(option.value)
        })
        this.setState((prevState) => ({
            courier: {
                ...prevState.courier,
                countries: selectedCountries,
            },
        }))
    }

    onSettingsChange = (name, prop) => (e) => {
        const value = e.target[name]
        this.setState((prevState) => ({
            courierSettings: {
                ...prevState.courierSettings,
                [prop]: value,
            },
        }))
    }

    /**
     * DueDays in DB can hold values outside DueDayInvoice options scope.
     * Values outside selector.options scope can't be displayed by picker,
     * but can display {value: String, label: String} objects.
     */
    onDueDayInvoiceChange = (option) => {
        const { value } = option
        this.setState({ dueDaysInvoice: { value, label: value } })
        this.setState((prevState) => ({
            courierSettings: {
                ...prevState.courierSettings,
                dueDaysInvoice: value,
            },
        }))
    }

    onSettingsChangeFromList = (prop) => (e) => {
        const { value } = e
        this.setState((prevState) => ({
            courierSettings: {
                ...prevState.courierSettings,
                [prop]: value,
            },
        }))
    }

    onError = (error) => {
        this.setState({ error: error.status })
    }

    onUpdate = () => {
        this.setState({ updated: true })
    }

    onSubmit = (e) => {
        e.preventDefault()

        this.setState({ error: false, updated: false })

        const updatedCourier = {
            name: this.state.courier.name,
            countries: this.state.courier.countries,
            courierSettings: this.state.courierSettings,
        }

        if (!this.onValidate(updatedCourier)) {
            return
        }

        this.props.actions
            .updateCourier(this.props.courierId, updatedCourier)
            .then(this.onUpdate)
            .catch(this.onError)
    }

    onValidate = (courier) => !empty(courier.name) && courier.countries && courier.countries.length > 0

    shouldShowSettings = (setting) => this.props.showSettings == null || this.props.showSettings === setting

    renderGeneralSettings = () => {
        if (!this.shouldShowSettings(GENERAL_SETTINGS)) {
            return null
        }
        return (
            <div>
                <FormGroup controlId="name" validationState={!isEmptyValue(this.state.courier.name) ? null : 'error'}>
                    <Col sm={3} componentClass={ControlLabel}>
                        Name
                    </Col>
                    <Col sm={8}>
                        <FormControl
                            type="text"
                            required
                            value={this.state.courier.name || ''}
                            onChange={this.onNameChange('value', 'name')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup validationState={!isEmptyValue(this.state.courier.countries) ? null : 'error'}>
                    <Col sm={3} componentClass={ControlLabel}>
                        Operating in
                    </Col>
                    <Col sm={8}>
                        <Select
                            placeholder="Select countries..."
                            value={this.state.courier.countries}
                            options={this.props.countries.map((country) => ({
                                value: country.id,
                                label: country.label,
                            }))}
                            onChange={(options) => this.onCountriesChange(options)}
                            allowCreate={false}
                            ignoreCase
                            multi
                        />
                    </Col>
                </FormGroup>
            </div>
        )
    }

    renderInvoicingSettings = () => {
        if (!this.shouldShowSettings(INVOICING_SETTINGS)) {
            return null
        }

        return (
            <div>
                <FormGroup controlId="companyLegalName">
                    <Col sm={3} componentClass={ControlLabel}>
                        Company legal name
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={this.state.courierSettings.companyLegalName || ''}
                            onChange={this.onSettingsChange('value', 'companyLegalName')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="selfBillRecipientOverride">
                    <Col sm={3} componentClass={ControlLabel}>
                        Override self-bill recipient to (Netsuite)
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="number"
                            placeholder="Carriers ID for courier to receive self-bills"
                            value={this.state.courierSettings.selfBillRecipientOverride}
                            onChange={this.onSettingsChange('value', 'selfBillRecipientOverride')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="companyRegistrationNumber"
                    validationState={
                        !isEmptyValue(this.state.courierSettings.companyRegistrationNumber) ? null : 'error'
                    }
                >
                    <Col sm={3} componentClass={ControlLabel}>
                        Company Registration number
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            required
                            type="text"
                            placeholder="Org nr"
                            value={this.state.courierSettings.companyRegistrationNumber || ''}
                            onChange={this.onSettingsChange('value', 'companyRegistrationNumber')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="vatNumber"
                    validationState={!isEmptyValue(this.state.courierSettings.vatNumber) ? null : 'error'}
                >
                    <Col sm={3} componentClass={ControlLabel}>
                        VAT Number
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            required
                            type="text"
                            value={this.state.courierSettings.vatNumber || ''}
                            onChange={this.onSettingsChange('value', 'vatNumber')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="billingPeriod">
                    <Col componentClass={ControlLabel} sm={3}>
                        Billing period
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={this.state.courierSettings.billingPeriod}
                            options={billingPeriods}
                            onChange={this.onSettingsChangeFromList('billingPeriod')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="dueDaysInvoice">
                    <Col componentClass={ControlLabel} sm={3}>
                        Due days invoice
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={this.state.dueDaysInvoice}
                            options={dueDaysInvoices}
                            onChange={this.onDueDayInvoiceChange}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceEmail"
                    validationState={!isEmptyValue(this.state.courierSettings.invoiceEmail) ? null : 'error'}
                >
                    <Col sm={3} componentClass={ControlLabel}>
                        Invoice email
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            required
                            type="email"
                            placeholder="invoice email"
                            value={this.state.courierSettings.invoiceEmail || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmail')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceEmailCopy">
                    <Col sm={3} componentClass={ControlLabel}>
                        Invoice email CC
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            placeholder="invoice email"
                            value={this.state.courierSettings.invoiceEmailCopy || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmailCopy')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="checkboxes">
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={this.state.courierSettings.terminalEndStopAlways}
                            onChange={this.onSettingsChange('checked', 'terminalEndStopAlways')}
                        >
                            Requires a final terminal stop in all routes
                        </Checkbox>
                        <Checkbox
                            checked={this.state.courierSettings.vatOnInvoice}
                            onChange={this.onSettingsChange('checked', 'vatOnInvoice')}
                        >
                            VAT on invoice
                        </Checkbox>
                        <Checkbox
                            checked={this.state.courierSettings.selfInvoiced}
                            onChange={this.onSettingsChange('checked', 'selfInvoiced')}
                        >
                            Self Invoiced
                        </Checkbox>
                        <Checkbox
                            checked={this.state.courierSettings.combinedInvoicing}
                            onChange={this.onSettingsChange('checked', 'combinedInvoicing')}
                        >
                            Combined invoicing with parent
                        </Checkbox>
                    </Col>
                </FormGroup>
                <h4>Company address</h4>
                <hr />
                <FormGroup controlId="companyCountry">
                    <Col sm={3} componentClass={ControlLabel}>
                        Country
                    </Col>
                    <Col sm={9}>
                        <Select
                            placeholder="Select country..."
                            value={this.state.courierSettings.companyCountry || ''}
                            options={this.props.countries.map((country) => ({
                                value: country.id,
                                label: country.label,
                            }))}
                            onChange={this.onSettingsChangeFromList('companyCountry')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="companyCity">
                    <Col sm={3} componentClass={ControlLabel}>
                        City
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={this.state.courierSettings.companyCity || ''}
                            onChange={this.onSettingsChange('value', 'companyCity')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="companyPostalCode">
                    <Col sm={3} componentClass={ControlLabel}>
                        Postal Code
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={this.state.courierSettings.companyPostalCode || ''}
                            onChange={this.onSettingsChange('value', 'companyPostalCode')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="companyAddress">
                    <Col sm={3} componentClass={ControlLabel}>
                        Company Address
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={this.state.courierSettings.companyAddress || ''}
                            onChange={this.onSettingsChange('value', 'companyAddress')}
                        />
                    </Col>
                </FormGroup>
                <hr />
            </div>
        )
    }

    renderActions = () => (
        <FormGroup>
            <Col sm={9} smOffset={3}>
                <AlertGroup statusCode={this.state.updated ? 200 : this.state.error} />
                <Button type="submit" bsStyle="primary" disabled={!this.onValidate(this.state.courier)}>
                    Save
                </Button>
            </Col>
        </FormGroup>
    )

    render() {
        if (this.props.loading) {
            return null
        }

        return (
            <BsForm horizontal onSubmit={this.onSubmit}>
                {this.renderGeneralSettings()}
                {this.renderInvoicingSettings()}
                {this.renderActions()}
            </BsForm>
        )
    }
}

const mapStateToProps = (state) => ({
    courierSettings: state.couriers.courier.courierSettings,
    courier: state.couriers.courier,
    loading: state.couriers.loading,
    countries: state.regionalSettings.countries,
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ...courierActions, ...regionalSettingsActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditForm)

EditForm.propTypes = {
    courierId: PropTypes.any.isRequired,
    actions: PropTypes.object,
    courierSettings: PropTypes.object,
    courier: PropTypes.object,
    loading: PropTypes.bool,
    countries: PropTypes.array,
    showSettings: PropTypes.oneOf([GENERAL_SETTINGS, INVOICING_SETTINGS]),
}
