import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PricePointItem from './price-point-item'
import Confirm from '../../../confirm'

const mapPricePoints = (pricePoints, selected) =>
    pricePoints.map((item) => ({
        ...item,
        selected,
    }))

class PricePointList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allSelected: false,
            list: mapPricePoints(this.props.list, false),
            showDeleteModal: false,
        }
    }

    onDelete = () => {
        const pricePointIds = this.state.list.filter((item) => item.selected).map((item) => item.id)

        this.props.deletePricePoints(pricePointIds)
    }

    onConfirmDelete = () => {
        this.setState({ showDeleteModal: true })
    }

    onAllSelected = () => {
        const { list, allSelected } = this.state
        this.setState({
            allSelected: !allSelected,
            list: mapPricePoints(list, !allSelected),
        })
    }

    onSelected = (item) => () => {
        const { list } = this.state
        const index = list.findIndex((x) => x.id === item.id)
        list[index].selected = !list[index].selected
        this.setState({ list })
    }

    renderDeleteConfirmation() {
        const { courier } = this.props
        const { showDeleteModal } = this.state

        return (
            <Confirm
                show={showDeleteModal}
                title="Remove price points"
                bsStyle="danger"
                onClose={() => this.setState({ showDeleteModal: false })}
                onConfirm={() => {
                    this.onDelete()
                    this.setState({ showDeleteModal: false })
                }}
            >
                {`Remove price points for courier '${courier.name}'?`}
            </Confirm>
        )
    }

    render() {
        if (!this.state.list) {
            return null
        }

        return (
            <div className="mt-sm">
                <button
                    type="button"
                    className="btn btn-danger btn-sm pull-left margin-btn"
                    onClick={this.onConfirmDelete}
                    disabled={!this.state.list.some((item) => item.selected)}
                >
                    <i className="glyphicon glyphicon-trash remove inline " />
                </button>
                {this.renderDeleteConfirmation()}
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    name="selectAll"
                                    defaultChecked={this.state.allSelected}
                                    onChange={this.onAllSelected}
                                />
                            </th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Valid from</th>
                            <th>Valid to</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list.map((item) => (
                            <PricePointItem key={item.id} onSelected={this.onSelected} item={item} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default PricePointList

PricePointList.propTypes = {
    list: PropTypes.array.isRequired,
    deletePricePoints: PropTypes.func.isRequired,
    courier: PropTypes.object.isRequired,
}
