import * as types from '../constants/actionTypes'
import OwnerOperatorApi from '../utils/owner-operator-api'
import {
    getCouriers,
    getCourier,
    deleteCourier,
    getCourierPricePoints,
    createCourierPricePoints,
    removeCourierPricePoints,
} from '../utils/courier-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

const delayDuration = 750

const ownersLoaded = (response) => ({
    type: types.OWNERS_LOADED,
    status: 200,
    response,
})

const ownerLoaded = (response) => ({
    type: types.OWNER_LOADED,
    status: 200,
    response,
})

const ownerLoading = () => ({
    type: types.OWNERS_LOADING,
})

const fetchingPricePoints = () => ({
    type: types.LOADING_PRICE_POINTS,
})

const pricePointsLoaded = (response) => ({
    type: types.OWNER_PRICE_POINTS_LOADED,
    status: 200,
    response,
})

const pricePointsUpdated = (response) => ({
    type: types.OWNER_PRICE_POINTS_UPDATE,
    status: 200,
    response,
})

export const fetchOwners = () => (dispatch) => {
    dispatch(ownerLoading())
    return OwnerOperatorApi.get()
        .then((res) => dispatch(ownersLoaded(res)))
        .catch(handleError)
}

export const fetchOwnersWebapi = () => (dispatch) => {
    dispatch(ownerLoading())
    return getCouriers()
        .then((res) => dispatch(ownersLoaded(res)))
        .catch(handleError)
}

export const fetchOwner = (id) => (dispatch) => {
    dispatch(ownerLoading())
    return OwnerOperatorApi.get(id)
        .then((res) => dispatch(ownerLoaded(res)))
        .catch(handleError)
}

export const fetchOwnerWebapi = (id) => (dispatch) => {
    dispatch(ownerLoading())
    return getCourier(id)
        .then(compose(dispatch, ownerLoaded))
        .catch(handleError)
}

export const getPricePointList = (carrierId) => (dispatch) =>
    OwnerOperatorApi.getPricePointList(carrierId)
        .then((res) => dispatch(pricePointsLoaded(res)))
        .catch(handleError)

export const getPricePointListWebapi = (courierId) => (dispatch) => {
    dispatch(fetchingPricePoints())
    getCourierPricePoints(courierId)
        .then(compose(dispatch, pricePointsLoaded))
        .catch(handleError)
}

export const getPricePointUpdate = (carrierId) => (dispatch) =>
    OwnerOperatorApi.getPricePointList(carrierId)
        .then(() => dispatch(pricePointsUpdated(carrierId)))
        .catch(handleError)

export const createPricePoints = (carrierId, pricePoints) => (dispatch) =>
    OwnerOperatorApi.createPricePoints(carrierId, pricePoints)
        .then(() => dispatch(getPricePointList(carrierId)))
        .catch(handleError)

export const createPricePointsWebapi = (courierId, pricePoints) => (dispatch) =>
    createCourierPricePoints(courierId, pricePoints)
        .then(() => setTimeout(() => dispatch(getPricePointListWebapi(courierId)), delayDuration))
        .catch((error) => {
            handleError(error)
            throw error
        })

export const removePricePoints = (carrierId, pricePoints) => (dispatch) =>
    OwnerOperatorApi.removePricePoints(carrierId, pricePoints)
        .then(() => dispatch(getPricePointList(carrierId)))
        .catch(handleError)

export const removePricePointsWebapi = (courierId, pricePoints) => (dispatch) =>
    removeCourierPricePoints(courierId, pricePoints)
        .then(() => setTimeout(() => dispatch(getPricePointListWebapi(courierId)), delayDuration))
        .catch((error) => {
            handleError(error)
            throw error
        })

export const deleteCouriers = (courierId) => (dispatch) =>
    deleteCourier(courierId)
        .then(compose(dispatch, fetchOwnersWebapi))
        .catch((error) => {
            handleError(error)
            throw error
        })
