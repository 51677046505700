import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Grid, Row } from 'react-bootstrap'
import Select from 'react-select'
import PropTypes from 'prop-types'
import HtmlTitle from '../html-title'
import { changeLockerHolidayUnblockCities, fetchHolidays } from '../../actions/holidays'
import CountrySelect from '../common/country-select'
import CountryLockerHolidayCitiesSelect from './country-locker-holiday-cities'

class LockerHolidayUnblocks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedHoliday: null,
            countryCode: '',
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(fetchHolidays())
    }

    mapHolidaysToSelectOptions = (holidays) => {
        return holidays.map((entry) => {
            const { holiday } = entry
            return {
                label: `${holiday.name} (${holiday.date})`,
                value: holiday.id,
            }
        })
    }

    onHolidayChange(e) {
        this.setState({ selectedHoliday: e })
    }

    onCountryChange = (countryCode) => {
        this.setState({ countryCode })
    }

    getCitiesForSelectedCountry() {
        return this.props.cities.filter((city) => city.countryCode === this.state.countryCode)
    }

    getHolidayForSelection = (selectedHolidayId) => {
        return this.props.holidays.find((holiday) => holiday.holiday.id === selectedHolidayId)
    }

    handleCitiesUpdateForHoliday = (holidayId, addedCities, removedCities) => {
        const { dispatch } = this.props
        dispatch(changeLockerHolidayUnblockCities(holidayId, addedCities, removedCities))
    }

    renderHolidaySelect() {
        return (
            <div>
                <h2>Holiday</h2>
                <Select
                    disabled={false}
                    placeholder="Select holiday to exclude"
                    value={this.state.selectedHoliday}
                    options={this.mapHolidaysToSelectOptions(this.props.holidays)}
                    searchable
                    clearable
                    backspaceRemoves={false}
                    onChange={(e) => this.onHolidayChange(e)}
                    multi={false}
                />
                <p>Select holiday & country to view unblocked cities.</p>
            </div>
        )
    }

    render() {
        return (
            <div>
                <HtmlTitle title="Locker Holiday exclusions" />
                <Grid fluid>
                    <Row>
                        <Col md={4} mdOffset={2}>
                            <h2>Country</h2>
                            <CountrySelect onSelect={(country) => this.onCountryChange(country.value)} />
                        </Col>
                        <Col md={4} mdOffset={0}>
                            {this.renderHolidaySelect()}
                        </Col>
                    </Row>
                    <Row className="bump-down">
                        <Col md={8} mdOffset={2}>
                            {this.state.countryCode && this.state.selectedHoliday && (
                                <div key={`${this.state.selectedHoliday.value}-${this.state.countryCode}`}>
                                    <h2>Holiday unblocked for locker in cities</h2>
                                    <CountryLockerHolidayCitiesSelect
                                        countryCode={this.state.countryCode}
                                        holiday={this.getHolidayForSelection(this.state.selectedHoliday.value)}
                                        countryCities={this.getCitiesForSelectedCountry()}
                                        onCitiesUpdate={this.handleCitiesUpdateForHoliday}
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = ({ holidays: { loading, error, holidays }, regionalSettings }) => ({
    loading,
    error,
    holidays,
    cities: regionalSettings.cities,
})

export default connect(mapStateToProps)(LockerHolidayUnblocks)

LockerHolidayUnblocks.propTypes = {
    dispatch: PropTypes.func.isRequired,
    holidays: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
}
