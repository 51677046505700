import moment from 'moment'
import { groupBy } from 'lodash'
import mapTimeZoneToCountry from './timezone'

const HOUR_FORMAT = 'HH:mm'

// Remove duplicates from 2d array
export const multiDimensionalUnique = (arr) => {
    const uniques = []
    const itemsFound = {}
    for (let i = 0, l = arr.length; i < l; i += 1) {
        const stringified = JSON.stringify(arr[i])
        if (itemsFound[stringified]) {
            continue
        }
        uniques.push(arr[i])
        itemsFound[stringified] = true
    }
    return uniques
}

// groups slots by their start or stop time
export const groupSlots = (slots, groupByPropertyGetter) => {
    if (!groupByPropertyGetter || !slots || slots.length === 0) {
        return {}
    }

    return groupBy(slots, groupByPropertyGetter)
}

// Checks if a slot matches the current time
export const isBetweenTimes = (currentTime, startTime, stopTime) => currentTime >= startTime && currentTime <= stopTime

// Checks if a driver is late with loading
export const isLate = (currentTime, stop) => currentTime > stop

export const isLoaded = ({ totalParcels, parcelsLoaded }) => totalParcels === parcelsLoaded

export const loadingSlotsTimes = (slots) => multiDimensionalUnique(slots.map(({ start, stop }) => [start, stop]))

// Provides time in seconds (epoch) matching the format provided by the API
export const now = () => moment.utc().valueOf()

export const formattedTime = (date, countryCode) =>
    moment
        .utc(date)
        .tz(mapTimeZoneToCountry(countryCode))
        .format(HOUR_FORMAT)
