import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

export const residenceTypes = {
    HOUSE: 'House',
    APARTMENT: 'Apartment',
    APARTMENT_ACROSS_COURTYARD: 'Apartment across Courtyard',
}

const ResidenceTypes = (props) => {
    const { selected, stateField } = props
    const options = Object.keys(residenceTypes).map((key) => {
        return { label: residenceTypes[key], value: key }
    })
    return (
        <Select
            onChange={(option) => props.onChange(stateField, option.value)}
            options={options}
            value={selected}
            placeholder={residenceTypes[selected]}
            clearable={false}
        />
    )
}

ResidenceTypes.propTypes = {
    selected: PropTypes.string,
    stateField: PropTypes.string,
    onChange: PropTypes.func,
}

export default ResidenceTypes
