import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Form, FormGroup, ControlLabel, FormControl, Checkbox, Button, HelpBlock } from 'react-bootstrap'
import Select from 'react-select'
import BillingPeriods from '../../utils/billing-periods'
import Currencies from '../../utils/currencies'
import MerchantSelect from '../common/merchant-select'

import {
    MIN_INVOICE_DUE_DAYS,
    MAX_INVOICE_DUE_DAYS,
    MIN_CURRENCY_CONVERSION_FEE_IN_PERCENT,
    MAX_CURRENCY_CONVERSION_FEE_IN_PERCENT,
    MIN_DEFAULT_MISS_RETRIES,
} from '../../utils/merchant'
import isEmpty from '../../utils/isEmptyValue'
import InvoiceDeliveryTypes from '../../utils/invoiceDeliveryTypes'
import Language from '../../utils/language'
import CountryCodes from '../../utils/country-codes-enum'
import MerchantInput from './components/merchant-input'

const billingPeriods = BillingPeriods()
const currencies = Currencies()
const invoiceDeliveryTypes = InvoiceDeliveryTypes()
const invoiceLanguages = Language()
const countryCodes = CountryCodes()

export default class BuyerForm extends Component {
    onSubmit = (e) => {
        e.preventDefault()
        this.props.onSubmit()
    }

    onChange = (prop, name) => (e) =>
        this.props.onChange({
            ...this.props.buyer,
            [name]: e.target[prop],
        })

    onChangeSetValueAndKey = (value, key) => {
        const buyerSettings = {
            ...this.props.buyer.buyerSettings,
            [key]: value,
        }
        this.props.onChange({
            ...this.props.buyer,
            buyerSettings,
        })
    }

    onSettingsChange = (prop, name) => (e) => {
        const buyerSettings = {
            ...this.props.buyer.buyerSettings,
            [name]: e.target[prop],
        }
        this.props.onChange({
            ...this.props.buyer,
            buyerSettings,
        })
    }

    onSettingsChangeFromList = (name) => (e) => {
        let item = null
        if (e != null) {
            item = e.value
        }
        const buyerSettings = {
            ...this.props.buyer.buyerSettings,
            [name]: item,
        }
        this.props.onChange({
            ...this.props.buyer,
            buyerSettings,
        })
    }

    onEmailLinkChange = (prop, name) => (e) => {
        const emailLinks = {
            ...(this.props.buyer.buyerSettings.emailLinks != null ? this.props.buyer.buyerSettings.emailLinks : {}),
            [name]: e.target[prop],
        }
        const buyerSettings = {
            ...this.props.buyer.buyerSettings,
            emailLinks,
        }
        this.props.onChange({
            ...this.props.buyer,
            buyerSettings,
        })
    }

    onSelectIntervalInCheckoutChange = (e) => {
        const { buyerSettings } = this.props.buyer
        buyerSettings.selectIntervalInCheckout = e.target.checked
        if (!buyerSettings.selectIntervalInCheckout) {
            buyerSettings.allowedToCreateDeliveryAttemptsAfterCutoff = false
        }
        this.props.onChange(this.props.buyer, buyerSettings)
    }

    setBuyerTags = (tags) => {
        this.props.onUpdateTags(tags)
    }

    setBuyerDomains = (domains) => {
        this.props.onUpdateDomains(domains)
    }

    setShippingCosts = (shippingCosts) => {
        this.props.onUpdateShippingCosts(shippingCosts)
    }

    render() {
        const { buyer } = this.props
        if (buyer == null) {
            return <div />
        }

        const { buyerSettings } = buyer

        const isSupportEmailMandatory = !buyerSettings.supportPhone
        const isSupportPhoneMandatory = !buyerSettings.supportEmail
        const isEInvoiceIdMandatory = buyerSettings.invoiceDeliveryType === 'E-INVOICE'

        return (
            <Form horizontal onSubmit={this.onSubmit}>
                <h2>General</h2>
                <hr />
                <FormGroup controlId="name" validationState={isEmpty(buyer.name) ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={3}>
                        Company name
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            required
                            value={buyer.name || ''}
                            onChange={this.onChange('value', 'name')}
                            placeholder="Customer or brand name"
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="externalName" validationState={isEmpty(buyer.externalName) ? 'error' : null}>
                    <Col componentClass={ControlLabel} sm={3}>
                        Display name (SMS, Tracking)
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            required
                            value={buyer.externalName || ''}
                            onChange={this.onChange('value', 'externalName')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="supportEmail"
                    validationState={isEmpty(buyerSettings.supportEmail) && isSupportEmailMandatory ? 'error' : null}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Support Email (for Tracking) {isSupportEmailMandatory}
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            required={isSupportEmailMandatory}
                            value={buyerSettings.supportEmail || ''}
                            onChange={this.onSettingsChange('value', 'supportEmail')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="supportPhone"
                    validationState={isEmpty(buyerSettings.supportPhone) && isSupportPhoneMandatory ? 'error' : null}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Support Phone (for Tracking) {isSupportPhoneMandatory}
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="tel"
                            required={isSupportPhoneMandatory}
                            value={buyerSettings.supportPhone || ''}
                            onChange={this.onSettingsChange('value', 'supportPhone')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="tags">
                    <Col componentClass={ControlLabel} sm={3}>
                        Tags
                    </Col>
                    <Col sm={9}>
                        <Select
                            multi
                            value={this.props.selectedTags}
                            options={this.props.availableTags}
                            onChange={this.setBuyerTags}
                        />
                    </Col>
                </FormGroup>
                <h2>Invoice</h2>
                <hr />
                <FormGroup controlId="invoiceRecipientOverride">
                    <Col componentClass={ControlLabel} sm={3}>
                        Override invoice recipient to (Netsuite)
                    </Col>
                    <Col sm={9}>
                        <MerchantSelect
                            onSelect={(invoiceRecipientOverride) =>
                                this.onChangeSetValueAndKey(
                                    invoiceRecipientOverride ? invoiceRecipientOverride.value : null,
                                    'invoiceRecipientOverride',
                                )
                            }
                            multi={false}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="billingPeriod"
                    validationState={!isEmpty(buyerSettings.billingPeriod) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Billing cycle
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={buyerSettings.billingPeriod}
                            options={billingPeriods}
                            onChange={this.onSettingsChangeFromList('billingPeriod')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="companyRegistrationNumber"
                    validationState={!isEmpty(buyerSettings.companyRegistrationNumber) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Business ID
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            placeholder="Org nr -  this is not the same as the VAT number"
                            value={buyerSettings.companyRegistrationNumber || ''}
                            onChange={this.onSettingsChange('value', 'companyRegistrationNumber')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="taxRegistrationNumber"
                    validationState={!isEmpty(buyerSettings.taxRegistrationNumber) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Tax Registration Number
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            placeholder="This is the same as VAT Number"
                            type="text"
                            value={buyerSettings.taxRegistrationNumber || ''}
                            onChange={this.onSettingsChange('value', 'taxRegistrationNumber')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="dueDaysInvoice"
                    validationState={buyerSettings.dueDaysInvoice !== null ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Payment Terms
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="number"
                            min={MIN_INVOICE_DUE_DAYS}
                            max={MAX_INVOICE_DUE_DAYS}
                            placeholder="Days"
                            value={buyerSettings.dueDaysInvoice}
                            onChange={this.onSettingsChange('value', 'dueDaysInvoice')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="customInvoiceCurrency">
                    <Col componentClass={ControlLabel} sm={3}>
                        Custom invoice currency{' '}
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={
                                buyerSettings.customInvoiceCurrency != null ? buyerSettings.customInvoiceCurrency : ''
                            }
                            options={currencies}
                            placeholder="None"
                            onChange={this.onSettingsChangeFromList('customInvoiceCurrency')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="currencyConversionFeeInPercent">
                    <Col componentClass={ControlLabel} sm={3}>
                        Currency conversion fee %{' '}
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="number"
                            min={MIN_CURRENCY_CONVERSION_FEE_IN_PERCENT}
                            max={MAX_CURRENCY_CONVERSION_FEE_IN_PERCENT}
                            placeholder="%"
                            value={buyerSettings.currencyConversionFeeInPercent}
                            onChange={this.onSettingsChange('value', 'currencyConversionFeeInPercent')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceEmail"
                    validationState={!isEmpty(buyerSettings.invoiceEmail) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice email
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            placeholder="invoice email"
                            value={buyerSettings.invoiceEmail || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmail')}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    type="email"
                    controlId="invoiceEmail2"
                    label="Invoice email 2"
                    value={buyerSettings.invoiceEmail2}
                    onChange={this.onSettingsChange('value', 'invoiceEmail2')}
                    placeholder="Invoice email 2"
                />
                <FormGroup controlId="invoiceEmailCopy">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice email CC
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            value={buyerSettings.invoiceEmailCopy || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmailCopy')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceEmailSecondCopy">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice email CC2
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            value={buyerSettings.invoiceEmailSecondCopy || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmailSecondCopy')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceEmailReminder">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice email reminder
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            value={buyerSettings.invoiceEmailReminder || ''}
                            onChange={this.onSettingsChange('value', 'invoiceEmailReminder')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceContactEmail">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice contact email
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="email"
                            placeholder="Contact email"
                            value={buyerSettings.invoiceContactEmail || ''}
                            onChange={this.onSettingsChange('value', 'invoiceContactEmail')}
                        />
                    </Col>
                </FormGroup>
                <MerchantInput
                    type="tel"
                    controlId="invoicePhone"
                    label="Invoice phone"
                    value={buyerSettings.invoicePhone}
                    onChange={this.onSettingsChange('value', 'invoicePhone')}
                    placeholder="Invoice phone number"
                />
                <MerchantInput
                    type="tel"
                    controlId="invoicePhone2"
                    label="Invoice phone 2"
                    value={buyerSettings.invoicePhone2}
                    onChange={this.onSettingsChange('value', 'invoicePhone2')}
                    placeholder="Secondary invoice phone number"
                />
                <FormGroup controlId="invoiceLanguage">
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice language
                    </Col>
                    <Col sm={9}>
                        <Select
                            placeholder="Select language..."
                            value={buyerSettings.invoiceLanguage}
                            options={invoiceLanguages}
                            onChange={this.onSettingsChangeFromList('invoiceLanguage')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={buyerSettings.invoicesPreapproved}
                            onChange={this.onSettingsChange('checked', 'invoicesPreapproved')}
                        >
                            Preapprove invoices in Netsuite
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.invoiceSplitByCountry}
                            onChange={this.onSettingsChange('checked', 'invoiceSplitByCountry')}
                        >
                            Split invoice by country
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.invoiceGroupSubmerchant}
                            onChange={this.onSettingsChange('checked', 'invoiceGroupSubmerchant')}
                        >
                            Group submerchants invoices (all merchants invoices with Override invoice recipient to this
                            merchant will be merged)
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.requiresTerminalEndAlways}
                            onChange={this.onSettingsChange('checked', 'requiresTerminalEndAlways')}
                        >
                            Goods always require a final terminal stop
                        </Checkbox>
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceDeliveryType"
                    validationState={!isEmpty(buyerSettings.invoiceDeliveryType) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Invoice delivery type
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={buyerSettings.invoiceDeliveryType != null ? buyerSettings.invoiceDeliveryType : ''}
                            options={invoiceDeliveryTypes}
                            placeholder="Select invoice delivery type..."
                            onChange={this.onSettingsChangeFromList('invoiceDeliveryType')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="eInvoiceId"
                    validationState={isEmpty(buyerSettings.eInvoiceId) && isEInvoiceIdMandatory ? 'error' : null}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        GLN number/Peppol ID/e-invoice ID
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.eInvoiceId || ''}
                            onChange={this.onSettingsChange('value', 'eInvoiceId')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="customInvoiceReference">
                    <Col componentClass={ControlLabel} sm={3}>
                        Custom Invoice Reference
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            placeholder="Merchant specific reference on invoice."
                            type="text"
                            value={buyerSettings.customInvoiceReference || ''}
                            onChange={this.onSettingsChange('value', 'customInvoiceReference')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="budbeeAccountManager">
                    <Col componentClass={ControlLabel} sm={3}>
                        Budbee Contact Person
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.budbeeAccountManager || ''}
                            onChange={this.onSettingsChange('value', 'budbeeAccountManager')}
                        />
                    </Col>
                </FormGroup>
                <h4>Invoice address</h4>
                <hr />
                <FormGroup
                    controlId="invoiceCountry"
                    validationState={!isEmpty(buyerSettings.invoiceCountry) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Country
                    </Col>
                    <Col sm={9}>
                        <Select
                            value={buyerSettings.invoiceCountry != null ? buyerSettings.invoiceCountry : ''}
                            options={countryCodes}
                            placeholder="Select country..."
                            onChange={this.onSettingsChangeFromList('invoiceCountry')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceState">
                    <Col componentClass={ControlLabel} sm={3}>
                        State
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoiceState || ''}
                            onChange={this.onSettingsChange('value', 'invoiceState')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceCity"
                    validationState={!isEmpty(buyerSettings.invoiceCity) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        City
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoiceCity || ''}
                            onChange={this.onSettingsChange('value', 'invoiceCity')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoicePostalCode"
                    validationState={!isEmpty(buyerSettings.invoicePostalCode) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Postal Code
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoicePostalCode || ''}
                            onChange={this.onSettingsChange('value', 'invoicePostalCode')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup
                    controlId="invoiceAddr1"
                    validationState={!isEmpty(buyerSettings.invoiceAddr1) ? null : 'error'}
                >
                    <Col componentClass={ControlLabel} sm={3}>
                        Address1
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoiceAddr1 || ''}
                            onChange={this.onSettingsChange('value', 'invoiceAddr1')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceAddr2">
                    <Col componentClass={ControlLabel} sm={3}>
                        Address2
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoiceAddr2 || ''}
                            onChange={this.onSettingsChange('value', 'invoiceAddr2')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="invoiceAddr3">
                    <Col componentClass={ControlLabel} sm={3}>
                        Address3
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={buyerSettings.invoiceAddr3 || ''}
                            onChange={this.onSettingsChange('value', 'invoiceAddr3')}
                        />
                    </Col>
                </FormGroup>
                <h2>Distribution</h2>
                <hr />
                <FormGroup controlId="defaultMissRetries">
                    <Col componentClass={ControlLabel} sm={3}>
                        Override number of miss retries
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="number"
                            min={MIN_DEFAULT_MISS_RETRIES}
                            step="1"
                            value={buyerSettings.defaultMissRetries || ''}
                            onChange={this.onSettingsChange('value', 'defaultMissRetries')}
                        />
                        <HelpBlock>Overrides the number of miss retries order setting</HelpBlock>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={buyerSettings.selectIntervalInCheckout}
                            onChange={this.onSelectIntervalInCheckoutChange}
                        >
                            Should Select Delivery Date in E-commerce Checkout
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.allowedToCreateDeliveryAttemptsAfterCutoff}
                            disabled={!buyerSettings.selectIntervalInCheckout}
                            onChange={this.onSettingsChange('checked', 'allowedToCreateDeliveryAttemptsAfterCutoff')}
                        >
                            Allowed to Create Delivery Attempts after cutoff (will be immediately Cancelled because of
                            delay)
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.onDemandPickup}
                            onChange={this.onSettingsChange('checked', 'onDemandPickup')}
                        >
                            On demand pickup service
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.allowConsumerToChangeDate}
                            onChange={this.onSettingsChange('checked', 'allowConsumerToChangeDate')}
                        >
                            Allow consumer to change date for delivery
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.allowConsumerToEditOutsideDoor}
                            onChange={this.onSettingsChange('checked', 'allowConsumerToEditOutsideDoor')}
                        >
                            Allow consumer to edit outside door
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.signatureRequired}
                            onChange={this.onSettingsChange('checked', 'signatureRequired')}
                        >
                            Signature required by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.identificationCheckRequired}
                            onChange={this.onSettingsChange('checked', 'identificationCheckRequired')}
                        >
                            Identification check by default, unless specified
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.offerReturns}
                            onChange={this.onSettingsChange('checked', 'offerReturns')}
                        >
                            Offer Return Service
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.offerReturnFromBuyers}
                            onChange={this.onSettingsChange('checked', 'offerReturnFromBuyers')}
                        >
                            Offer Return Service from Merchant Portal
                        </Checkbox>
                    </Col>
                </FormGroup>
                <h2>Notification</h2>
                <hr />
                <FormGroup controlId="orderCreatedSECampaign">
                    <Col componentClass={ControlLabel} sm={3}>
                        Order created email link SE
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="url"
                            placeholder="URL"
                            value={
                                buyerSettings.emailLinks != null ? buyerSettings.emailLinks.orderCreatedSECampaign : ''
                            }
                            onChange={this.onEmailLinkChange('value', 'orderCreatedSECampaign')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="orderCreatedFICampaign">
                    <Col componentClass={ControlLabel} sm={3}>
                        Order created email link FI
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="url"
                            placeholder="URL"
                            value={
                                buyerSettings.emailLinks != null ? buyerSettings.emailLinks.orderCreatedFICampaign : ''
                            }
                            onChange={this.onEmailLinkChange('value', 'orderCreatedFICampaign')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="orderCreatedDKCampaign">
                    <Col componentClass={ControlLabel} sm={3}>
                        Order created email link DK
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="url"
                            placeholder="URL"
                            value={
                                buyerSettings.emailLinks != null ? buyerSettings.emailLinks.orderCreatedDKCampaign : ''
                            }
                            onChange={this.onEmailLinkChange('value', 'orderCreatedDKCampaign')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="orderCreatedNLCampaign">
                    <Col componentClass={ControlLabel} sm={3}>
                        Order created email link NL
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="url"
                            placeholder="URL"
                            value={
                                buyerSettings.emailLinks != null ? buyerSettings.emailLinks.orderCreatedNLCampaign : ''
                            }
                            onChange={this.onEmailLinkChange('value', 'orderCreatedNLCampaign')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup controlId="orderCreatedBECampaign">
                    <Col componentClass={ControlLabel} sm={3}>
                        Order created email link BE
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="url"
                            placeholder="URL"
                            value={
                                buyerSettings.emailLinks != null ? buyerSettings.emailLinks.orderCreatedBECampaign : ''
                            }
                            onChange={this.onEmailLinkChange('value', 'orderCreatedBECampaign')}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Checkbox
                            checked={buyerSettings.defaultReturnTypeToDeliveryMethod}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={this.onSettingsChange('checked', 'defaultReturnTypeToDeliveryMethod')}
                        >
                            {true === false ? 'Allow consumer only to return parcel same way it was delivered' : ''}
                            {/* TODO: Show this text when backend functionality is done */}
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.defaultReturnTypeToBox}
                            hidden // TODO: Enable when backend functionality is done
                            disabled // TODO: Enable when backend functionality is done
                            onChange={this.onSettingsChange('checked', 'defaultReturnTypeToBox')}
                        >
                            {true === false ? 'Allow consumer only to return parcel with Budbee Box' : ''}
                            {/* TODO: Show this text when backend functionality is done */}
                        </Checkbox>

                        <Checkbox
                            checked={buyerSettings.smsSendCollected}
                            onChange={this.onSettingsChange('checked', 'smsSendCollected')}
                        >
                            Notify consumer when driver has collected parcel for delivery
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.smsSendEta}
                            onChange={this.onSettingsChange('checked', 'smsSendEta')}
                        >
                            Notify consumer when driver is arriving shortly
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.smsSendDelivered}
                            onChange={this.onSettingsChange('checked', 'smsSendDelivered')}
                        >
                            Notify consumer when delivery succeeded
                        </Checkbox>
                        <Checkbox
                            checked={buyerSettings.smsSendMiss}
                            onChange={this.onSettingsChange('checked', 'smsSendMiss')}
                        >
                            Notify consumer when delivery failed (Miss)
                        </Checkbox>
                    </Col>
                </FormGroup>
                <hr />
                <FormGroup>
                    <Col sm={9} smOffset={3}>
                        <Button type="submit" bsStyle="primary" disabled={!this.props.validate(buyer)}>
                            Save
                        </Button>
                        <Button type="button" bsStyle="default" onClick={this.props.onCancel}>
                            Cancel
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}

BuyerForm.propTypes = {
    availableTags: PropTypes.array,
    buyer: PropTypes.object,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onUpdateTags: PropTypes.func,
    selectedTags: PropTypes.array,
    validate: PropTypes.func,
    onUpdateDomains: PropTypes.func,
    onUpdateShippingCosts: PropTypes.func,
}
