import auth from '../auth'

// User roles defined in budbee-carrers

export const COMMERCIAL_MANAGER = '* commercial manager'
export const COMMERCIAL_USER = '* commercial user'

export const COURIER = '* courier'

export const OPERATIONS_ADMIN = '* operations administrator'
export const OPERATIONS_COORDINATOR = '* operations coordinator'

export const TERMINAL_ADMIN = '* Terminal administrator'
export const TERMINAL_WORKER = '* terminal worker'
export const EXTERNAL_TERMINAL_WORKER = '* external terminal worker'
export const LEGACY_BUDBEE_TERMINAL_WORKER = 'budbee terminal worker'
export const LEGACY_TERMINAL_WORKER = 'terminal worker'

export const TRAFFIC_CONTROLLER = '* traffic controller'

export const MERCHANT_ADMIN = '* merchant admin'

export const USER_MANAGER = '* user manager'

export const canFetchCouriers = () =>
    auth.hasAnyRole(
        OPERATIONS_ADMIN,
        OPERATIONS_COORDINATOR,
        TRAFFIC_CONTROLLER,
        TERMINAL_ADMIN,
        TERMINAL_WORKER,
        COMMERCIAL_MANAGER,
        COMMERCIAL_USER,
        USER_MANAGER,
    )
