import React from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { isBetweenTimes, isLate } from '../../utils/slots'

const spanStyle = {
    display: 'block',
    fontSize: 25,
}

export default function SlotItem({ slot, currentTime }) {
    if (isEmpty(slot)) {
        return null
    }

    const slotCellClassNames = cx({
        'slot-cell': true,
        'slot-cell-late': isLate(currentTime, slot.stop, slot.totalParcels, slot.parcelsLoaded),
        'slot-cell-highlighted': isBetweenTimes(currentTime, slot.start, slot.stop),
    })

    const online = slot.driverAtTerminal ? '#42B73E' : 'red'

    return (
        <div className="slot-item-container">
            <div className={slotCellClassNames}>
                <div className="parcel-container">
                    <i
                        style={{ marginRight: 10 }}
                        className={slot.parcelsLoaded === 0 ? 'fas fa-box' : 'fas fa-box-open'}
                    />
                    <span
                        style={{
                            color: slot.parcelsLoaded === 0 ? 'red' : 'inherit',
                        }}
                    >
                        {`${slot.parcelsLoaded}/${slot.totalParcels}`}
                    </span>
                    <div
                        style={{
                            marginLeft: 15,
                            width: 16,
                            height: 16,
                            borderRadius: 8,
                            border: '2px solid white',
                            backgroundColor: online,
                        }}
                    />
                </div>
                <div>
                    {slot.driverName && <span style={spanStyle}>{slot.driverName}</span>}
                    {slot.cage ? (
                        <span style={spanStyle}>{slot.cage}</span>
                    ) : (
                        <Link to={`/admin/terminal-sorting/${slot.routeId}`}>
                            <span style={spanStyle}>#{slot.routeId}</span>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

SlotItem.propTypes = {
    slot: PropTypes.object.isRequired,
    currentTime: PropTypes.number.isRequired,
}
