import * as types from '../constants/actionTypes'
import {
    getAllPostalCodeZoneGroups,
    getLatestPostalCodeZoneGroups,
    getDiffBetweenGroups,
    getPostalCodeZoneGroupByID,
    getPostalCodeZonesByGroupID,
    removePostalCodeZoneGroup,
    updatePostalCodeZoneGroup,
    createPostalCodeZoneGroup,
    getPreviousPostalCodeZoneGroupByID,
    getNextPostalCodeZoneGroupByID,
    publishPostalCodeZoneGroup,
    unpublishPostalCodeZoneGroup,
} from '../utils/market-access-webapi'
import { compose } from '../utils/compose'
import { handleError } from '../utils/handle-error'

const fetchingGroups = () => ({
    type: types.FETCHING_GROUPS,
})

const fetchingLatestGroups = () => ({
    type: types.FETCHING_LATEST_GROUPS,
})

const fetchingDiffBetweenGroups = () => ({
    type: types.FETCHING_DIFF_BETWEEN_GROUPS,
})

const fetchingGroup = () => ({
    type: types.FETCHING_GROUP,
})

const fetchingPreviousGroup = () => ({
    type: types.FETCHING_PREVIOUS_GROUP,
})

const fetchingNextGroup = () => ({
    type: types.FETCHING_NEXT_GROUP,
})

const publishingGroup = () => ({
    type: types.PUBLISHING_GROUP,
})

const unpublishingGroup = () => ({
    type: types.UNPUBLISHING_GROUP,
})

const groupsLoaded = (response) => ({
    type: types.GROUPS_LOADED,
    status: 200,
    response,
})

const latestGroupsLoaded = (response) => ({
    type: types.LATEST_GROUPS_LOADED,
    status: 200,
    response,
})

const diffBetweenGroupsLoaded = (response) => ({
    type: types.DIFF_BETWEEN_GROUPS_LOADED,
    status: 200,
    response,
})

const groupLoaded = (response) => ({
    type: types.GROUP_LOADED,
    status: 200,
    response,
})

const previousGroupLoaded = (response) => ({
    type: types.PREVIOUS_GROUP_LOADED,
    status: 200,
    response,
})

const nextGroupLoaded = (response) => ({
    type: types.NEXT_GROUP_LOADED,
    status: 200,
    response,
})

const postalCodeZonesLoaded = (response) => ({
    type: types.GROUP_POSTAL_CODE_ZONES_LOADED,
    status: 200,
    response,
})

const groupCreated = (response) => ({
    type: types.GROUP_CREATED,
    status: 200,
    response,
})

const groupUpdated = (response) => ({
    type: types.GROUP_UPDATED,
    status: 200,
    response,
})

const groupDeleted = (response) => ({
    type: types.GROUP_DELETED,
    status: 200,
    response,
})

const groupPublished = (response) => ({
    type: types.GROUP_PUBLISHED,
    status: 200,
    response,
})

const groupUnpublished = (response) => ({
    type: types.GROUP_UNPUBLISHED,
    status: 200,
    response,
})

export const fetchGroups = () => (dispatch) => {
    dispatch(fetchingGroups())
    return getAllPostalCodeZoneGroups()
        .then(compose(dispatch, groupsLoaded))
        .catch(handleError)
}

export const fetchLatestGroups = () => (dispatch) => {
    dispatch(fetchingLatestGroups())
    return getLatestPostalCodeZoneGroups()
        .then(compose(dispatch, latestGroupsLoaded))
        .catch(handleError)
}

export const fetchGroup = (id) => (dispatch) => {
    dispatch(fetchingGroup())
    return getPostalCodeZoneGroupByID(id)
        .then(compose(dispatch, groupLoaded))
        .catch(handleError)
}

export const fetchPreviousGroup = (id) => (dispatch) => {
    dispatch(fetchingPreviousGroup())
    return getPreviousPostalCodeZoneGroupByID(id)
        .then(compose(dispatch, previousGroupLoaded))
        .catch(handleError)
}

export const fetchNextGroup = (id) => (dispatch) => {
    dispatch(fetchingNextGroup())
    return getNextPostalCodeZoneGroupByID(id)
        .then(compose(dispatch, nextGroupLoaded))
        .catch(handleError)
}

export const fetchPostalCodeZones = (id) => (dispatch) =>
    getPostalCodeZonesByGroupID(id)
        .then(compose(dispatch, postalCodeZonesLoaded))
        .catch(handleError)

export const deleteGroup = (id) => (dispatch) =>
    removePostalCodeZoneGroup(id)
        .then(compose(dispatch, groupDeleted))
        .catch(handleError)

export const updateGroup = (id, data) => (dispatch) =>
    updatePostalCodeZoneGroup(id, data)
        .then(compose(dispatch, groupUpdated))
        .catch(handleError)

export const createGroup = (data) => (dispatch) =>
    createPostalCodeZoneGroup(data)
        .then(compose(dispatch, groupCreated))
        .catch(handleError)

export const fetchDiffBetweenGroups = (liveId, draftId) => (dispatch) => {
    dispatch(fetchingDiffBetweenGroups())
    return getDiffBetweenGroups(liveId, draftId)
        .then(compose(dispatch, diffBetweenGroupsLoaded))
        .catch(handleError)
}

export const publishGroup = (id, requestBody) => (dispatch) => {
    dispatch(publishingGroup())
    return publishPostalCodeZoneGroup(id, requestBody)
        .then(compose(dispatch, groupPublished))
        .catch(handleError)
}

export const unpublishGroup = (id) => (dispatch) => {
    dispatch(unpublishingGroup())
    return unpublishPostalCodeZoneGroup(id)
        .then(compose(dispatch, groupUnpublished))
        .catch(handleError)
}
