import React from 'react'
import { Link } from 'react-router'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

const renderTableRow = (order) => {
    const date = order.date ? moment(order.date).format('YYYY-MM-DD') : 'N/A'

    return (
        <tr key={order.token}>
            <td>{date}</td>
            <td>
                <Link to={`/admin/orders/${order.token}`}>{order.token}</Link>
            </td>
            <td>{order.buyerName}</td>
            <td>{order.name}</td>
            <td>
                {order.street}, {order.postalCode} {order.city}
            </td>
        </tr>
    )
}

const renderTable = (props) => {
    const { orders } = props
    return (
        <Table striped responsive>
            <thead>
                <tr>
                    <th>
                        <strong>Date</strong>
                    </th>
                    <th>
                        <strong>Token</strong>
                    </th>
                    <th>
                        <strong>Merchant</strong>
                    </th>
                    <th>
                        <strong>Consumer</strong>
                    </th>
                    <th>
                        <strong>Delivery Address</strong>
                    </th>
                </tr>
            </thead>

            <tbody>{orders ? orders.map(renderTableRow) : null}</tbody>
        </Table>
    )
}

renderTable.propTypes = {
    orders: PropTypes.array.isRequired,
}

export default renderTable
