import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getOrderSignatures } from '../../../utils/order-webapi'
import { handleError } from '../../../utils/handle-error'

const renderSignature = (signature) => {
    const {
        id,
        printedName,
        image: { url },
    } = signature

    if (url == null) {
        return null
    }

    return (
        <div key={id}>
            <h3>{printedName}</h3>
            <img className="img-responsive center-block" src={url || null} alt={printedName} />
        </div>
    )
}

export default class Signature extends Component {
    static propTypes = {
        order: PropTypes.object,
    }

    state = {
        signatures: [],
    }

    componentDidMount() {
        if (this.props.order) {
            this.updateSignature()
        }
    }

    updateSignature = () => {
        getOrderSignatures(this.props.order.token)
            .then((signatures) => this.setState({ signatures }))
            .catch((err) => {
                if (err.status !== 404) {
                    handleError(err)
                }
            })
    }

    render() {
        const { signatures } = this.state

        if (signatures == null) {
            return null
        }

        return <div>{signatures.map(renderSignature)}</div>
    }
}
