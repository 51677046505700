import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import CitySelect from '../common/city-select'

export default class CountryLockerHolidayCitiesSelect extends Component {
    onAddAllCities = () => {
        const selectedCities = this.props.countryCities.map(this.mapCityToOption)
        this.onCitiesChange(selectedCities)
    }

    onCitiesChange = (cities) => {
        if (cities === null) {
            return
        }
        const unblockedBefore = this.getUnblockedLockerCities()

        const updatedCityIds = cities.map((city) => city.value)
        const previousUnblockedCityIds = unblockedBefore.map((city) => city.id)

        const addedCityIds = updatedCityIds.filter((cityId) => !previousUnblockedCityIds.includes(cityId))
        const removedCityIds = previousUnblockedCityIds.filter((cityId) => !updatedCityIds.includes(cityId))

        this.props.onCitiesUpdate(this.props.holiday.holiday.id, addedCityIds, removedCityIds)
    }

    getUnblockedLockerCities = () => {
        const { holiday, countryCities } = this.props
        const unblockedCityIds = holiday.unblockedLockerCities.map((unblock) => unblock.city)
        return countryCities.filter((city) => unblockedCityIds.includes(city.id))
    }

    mapCityToOption = (city) => {
        return {
            label: city.label,
            value: city.id,
        }
    }

    getUnblockedLockerCitiesAsOptions = () => {
        return this.getUnblockedLockerCities().map(this.mapCityToOption)
    }

    render() {
        return (
            <div>
                <CitySelect
                    multi
                    countryFilter={this.props.countryCode}
                    value={this.getUnblockedLockerCitiesAsOptions()}
                    onSelect={(e) => this.onCitiesChange(e)}
                />
                <br />
                <Button type="submit" bsStyle="primary" onClick={this.onAddAllCities}>
                    Add all cities
                </Button>
            </div>
        )
    }
}

CountryLockerHolidayCitiesSelect.propTypes = {
    holiday: PropTypes.object.isRequired,
    countryCode: PropTypes.string.isRequired,
    countryCities: PropTypes.array.isRequired,
    onCitiesUpdate: PropTypes.func.isRequired,
}
