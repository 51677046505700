import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/vnd.budbee.geocoding-v2+json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)

export const saveGeocode = (newGeocodes) =>
    webapi()
        .path('geocoding')
        .post(newGeocodes)

export const searchGeocode = (query) =>
    webapi()
        .path('geocoding')
        .path('search')
        .post(query)

export const geocode = (request) =>
    webapi()
        .path('geocode')
        .post(request)

export const overwriteGeocode = (items) =>
    webapi()
        .path('geocoding')
        .path('overwrite')
        .post({ overwrite: items })
