import React, { PureComponent } from 'react'
import Loader from 'react-loader'
import { Alert, Row, Col, Grid, FormGroup } from 'react-bootstrap'
import Helmet from '../html-title'
import { getSocialMediaText, saveSocialMediaText } from '../../utils/social-webapi'
import Editor from './editor'

const msgStyle = { width: '50%', margin: 'auto', textAlign: 'center' }

const failLoadMsg = (
    <Alert bsStyle="danger" style={msgStyle}>
        <strong>Some Resources failed to load!</strong>
    </Alert>
)

const isValid = (currentValue) => currentValue != null

export default class SocialShare extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            platforms: [],
            error: '',
            isLoading: false,
        }
        this.renderTextAreas = this.renderTextAreas.bind(this)
        this.onTextChange = this.onTextChange.bind(this)
        this.onHashtagChange = this.onHashtagChange.bind(this)
        this.saveEdits = this.saveEdits.bind(this)
        this.validate = this.validate.bind(this)
        this.setLoading = this.setLoading.bind(this)
    }

    componentDidMount() {
        this.setLoading()
        getSocialMediaText()
            .then((response) => this.setState({ isLoading: false, platforms: response }))
            .catch((e) => this.setState({ isLoading: false, error: `Something went wrong ${e}` }))
    }

    onTextChange(key, e) {
        const newState = {
            ...this.state,
        }

        newState.platforms = newState.platforms.slice()
        newState.platforms[key] = {
            ...newState.platforms[key],
        }

        newState.platforms[key].text = e.target.value

        this.setState(newState)
    }

    onUriChange(key, e) {
        const newState = {
            ...this.state,
        }

        newState.platforms = newState.platforms.slice()
        newState.platforms[key] = {
            ...newState.platforms[key],
        }

        newState.platforms[key].link = e.target.value

        this.setState(newState)
    }

    onHashtagChange(key, tags) {
        const newTags = tags.map((tag) => tag.value)
        const newState = {
            ...this.state,
        }

        newState.platforms = newState.platforms.slice()

        newState.platforms[key] = {
            ...newState.platforms[key],
        }

        newState.platforms[key].tags = [...newTags]

        this.setState(newState)
    }

    setLoading() {
        this.setState((prevState) => ({
            isLoading: !prevState.isLoading,
        }))
    }

    validate() {
        const { platforms } = this.state

        return platforms.every((p) => isValid(p.text))
    }

    saveEdits(event) {
        event.preventDefault()
        const { platforms } = this.state

        saveSocialMediaText(platforms).catch((e) => console.error(e))
    }

    renderTextAreas() {
        const { platforms } = this.state

        return platforms.map((p, i) => {
            const tags = p.tags.map((k) => ({
                label: `#${k}`,
                value: k,
            }))

            return (
                <Editor
                    textLabel={p.platform}
                    uriLabel={`Link to share for ${p.platform}`}
                    hashTagsLabel={`Select hashtags for ${p.platform}`}
                    key={i.toString()}
                    value={p.text}
                    uri={p.link}
                    selectedTags={p.tags}
                    hashtagOptions={tags}
                    onTextChange={(e) => this.onTextChange(i, e)}
                    onUriChange={(e) => this.onUriChange(i, e)}
                    onHashtagChange={(e) => this.onHashtagChange(i, e)}
                />
            )
        })
    }

    render() {
        const { error, isLoading } = this.state

        if (isLoading) {
            return <Loader />
        }

        const content = error ? (
            failLoadMsg
        ) : (
            <div>
                <Grid fluid>
                    <Row>
                        <form onSubmit={this.saveEdits}>
                            <Col md={6} mdOffset={1}>
                                {this.renderTextAreas()}
                                <FormGroup>
                                    <button disabled={!this.validate()} type="submit" className="btn btn-primary">
                                        Save
                                    </button>
                                </FormGroup>
                            </Col>
                        </form>
                    </Row>
                </Grid>
            </div>
        )

        return (
            <div>
                <Helmet title="Social share" />
                {content}
            </div>
        )
    }
}

SocialShare.displayName = 'SocialShare'
