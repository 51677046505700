export default (id, coordinates) => ({
    id,
    type: 'line',
    source: {
        type: 'geojson',
        data: {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates,
            },
        },
    },
    layout: {
        'line-join': 'round',
        'line-cap': 'round',
    },
    paint: {
        'line-color': '#448AFF',
        'line-width': 4,
    },
})
