import React, { Component } from 'react'
import Loader from 'react-loader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import auth from '../../auth'
import Form from './form'
import * as roleActions from '../../actions/roles'
import * as merchantActions from '../../actions/merchants'
import * as courierActions from '../../actions/owner'
import * as userActions from '../../actions/user'
import * as terminalActions from '../../actions/terminals'
import empty from '../../utils/empty'
import {
    COMMERCIAL_MANAGER,
    COMMERCIAL_USER,
    OPERATIONS_ADMIN,
    OPERATIONS_COORDINATOR,
    TERMINAL_ADMIN,
    TRAFFIC_CONTROLLER,
} from '../../utils/role'

class Edit extends Component {
    constructor(props) {
        super(props)

        this.formRef = null

        this.setFormRef = (el) => {
            this.formRef = el
        }
    }

    canSetCourier = auth.hasAnyRole(OPERATIONS_ADMIN, OPERATIONS_COORDINATOR, TRAFFIC_CONTROLLER, TERMINAL_ADMIN)

    canSetMerchant = auth.hasAnyRole(COMMERCIAL_MANAGER, COMMERCIAL_USER, OPERATIONS_ADMIN, OPERATIONS_COORDINATOR)

    componentDidMount() {
        this.props.roleActions.fetchRoles()

        if (this.canSetCourier) {
            this.props.courierActions.fetchOwnersWebapi()
        }
        if (this.canSetMerchant) {
            this.props.merchantActions.fetchMerchants()
        }

        if (this.canSetCourier || this.canSetMerchant) {
            this.props.terminalActions.fetchTerminalsWebapi()
        }
    }

    validate = ({
        firstName,
        lastName,
        email,
        phoneNumber,
        connectedOwnerOperatorIds: couriers,
        connectedBuyerIds: merchants,
    }) => {
        const hasDetails = [firstName, lastName, email, phoneNumber].every((v) => !empty(v))
        const hasEmployer = !this.canSetCourier || couriers.length > 0 || merchants.length > 0

        return hasDetails && hasEmployer
    }

    edit = (e) => {
        e.preventDefault()

        const user = this.formRef.getUser()

        if (!this.validate(user)) {
            return
        }
        this.props.userActions.updatingUser()
        this.props.userActions.editUser(this.context.router.params.id, user, this.props.router)
    }

    cancel = () => {
        this.props.userActions.updateUserCancelled()
        this.props.router.push(`/admin/users/${this.context.router.params.id}`)
    }

    render() {
        const { loading } = this.props

        return loading ? (
            <Loader color="#BFBFBF" />
        ) : (
            <Form
                ref={this.setFormRef}
                onSubmit={this.edit}
                onCancel={this.cancel}
                validate={this.validate}
                canSetCourier={this.canSetCourier}
                canSetMerchant={this.canSetMerchant}
                canSetTerminal={this.canSetTerminal}
                ownerOperatorOptions={this.props.couriers}
                buyerOptions={this.props.merchants}
                roleOptions={this.props.roles}
                terminalOptions={this.props.terminals}
                initialUser={this.context.router.params.id}
                errors={this.props.errors}
                statusCode={this.props.statusCode}
            />
        )
    }
}

Edit.contextTypes = {
    router() {},
}

Edit.propTypes = {
    courierActions: PropTypes.object,
    merchantActions: PropTypes.object,
    roleActions: PropTypes.object,
    terminalActions: PropTypes.object,
    userActions: PropTypes.object,
    router: PropTypes.object,
    couriers: PropTypes.array,
    merchants: PropTypes.array,
    roles: PropTypes.array,
    terminals: PropTypes.array,
    errors: PropTypes.array,
    statusCode: PropTypes.number,
    loading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
    errors: state.users.errors,
    statusCode: state.users.statusCode,
    roles: state.roles.data,
    couriers: state.owners.options,
    merchants: state.buyers.options,
    terminals: state.terminals.options,
    loading: state.users.loading,
})

const mapDispatchToProps = (dispatch) => ({
    roleActions: bindActionCreators(roleActions, dispatch),
    merchantActions: bindActionCreators(merchantActions, dispatch),
    courierActions: bindActionCreators(courierActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    terminalActions: bindActionCreators(terminalActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
