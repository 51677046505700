import Webapi from './webapi'
import auth from '../auth'

const contentType = 'application/json'

const webapi = () =>
    new Webapi()
        .authorization(auth.get())
        .accept(contentType)
        .contentType(contentType)
        .path('hubs')

export const fetchHubPallets = (terminalId) =>
    webapi()
        .path('pallets')
        .path(terminalId)
        .get()

export const fetchAllHubPallets = (terminalId) =>
    webapi()
        .path('pallets')
        .path(terminalId)
        .path('all')
        .get()

export const setHubPallets = (terminalId, payload) =>
    webapi()
        .path('pallets')
        .path(terminalId)
        .put(payload)
