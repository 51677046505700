import React, { Component } from 'react'
import {
    Row,
    Col,
    Panel,
    Button,
    Table,
    FormGroup,
    InputGroup,
    FormControl,
    DropdownButton,
    MenuItem,
    ControlLabel,
} from 'react-bootstrap'
import Loader from 'react-loader'
import { Link } from 'react-router'
import Alert from './alert'
import { consumerSearch } from '../utils/consumer-webapi'

const RequestStatus = {
    NA: 'NA',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
}

const searchOptions = [{ value: 'phoneNumber', label: 'Recipient Phone number' }]

export default class ConsumerSearchHandler extends Component {
    constructor(props) {
        super(props)
        this.defaultSearchPlaceholder = 'Search phone number with country code, ex: +xxyyyyyyyyyy'
        this.state = {
            requestStatus: RequestStatus.NA,
            isSearching: false,
            searchOption: searchOptions[0],
            query: '',
            results: [],
            error: null,
            searchFieldPlaceholder: this.defaultSearchPlaceholder,
        }
    }

    isValidForm = () => {
        const { searchOption, query } = this.state

        return searchOption !== '' && query && query.trim() !== ''
    }

    search = (event) => {
        event.preventDefault()
        const { searchOption, query } = this.state

        this.setState(
            {
                isSearching: true,
                requestStatus: RequestStatus.NA,
            },
            () => {
                consumerSearch(searchOption.value, query)
                    .then((res) => {
                        this.setState({
                            results: [res],
                            requestStatus: RequestStatus.SUCCESS,
                            error: null,
                        })
                    })
                    .catch((e) => {
                        this.setState({
                            results: [],
                            requestStatus: e.status === 404 ? RequestStatus.SUCCESS : RequestStatus.FAILURE,
                            error: e.status !== 404 && e,
                        })
                    })
                    .finally(() => this.setState({ isSearching: false }))
            },
        )
    }

    renderForm() {
        const { searchOption, isSearching } = this.state

        return (
            <Panel>
                <form>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <ControlLabel>Search for Consumers *</ControlLabel>
                                <InputGroup>
                                    <DropdownButton
                                        componentClass={InputGroup.Button}
                                        id="search-option-dropdown"
                                        title={searchOption.label}
                                    >
                                        {searchOptions.map((s) => (
                                            <MenuItem
                                                key={s.value}
                                                onClick={() =>
                                                    this.setState({
                                                        searchOption: s,
                                                        searchFieldPlaceholder:
                                                            s.value !== 'phoneNumber'
                                                                ? 'Search...'
                                                                : this.defaultSearchPlaceholder,
                                                    })
                                                }
                                            >
                                                {s.label}
                                            </MenuItem>
                                        ))}
                                    </DropdownButton>
                                    <InputGroup.Addon>=</InputGroup.Addon>
                                    <FormControl
                                        type="text"
                                        placeholder={this.state.searchFieldPlaceholder}
                                        onChange={(e) => this.setState({ query: e.target.value })}
                                    />
                                    <InputGroup.Button>
                                        <Button
                                            bsStyle="primary"
                                            type="submit"
                                            disabled={!this.isValidForm() || isSearching}
                                            onClick={this.search}
                                        >
                                            Search
                                        </Button>
                                    </InputGroup.Button>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
            </Panel>
        )
    }

    renderNoResults() {
        return (
            <div>
                <span className="text-muted">No results</span>
            </div>
        )
    }

    renderRow(row) {
        return (
            <tr key={row.consumerId}>
                <td>
                    <Link to={`/admin/consumers/consumer/${row.phoneNumber}`}>{row.name}</Link>
                </td>
                <td>
                    <Link to={`/admin/consumers/consumer/${row.phoneNumber}`}>{row.phoneNumber}</Link>
                </td>
            </tr>
        )
    }

    renderResults() {
        const { results, isSearching, requestStatus, error } = this.state

        if (isSearching) {
            return <Loader />
        }

        if (error) {
            return <Alert type="danger" message={`Could not search: ${error.status} ${error.statusText}`} />
        }

        if (results.length === 0 && requestStatus === RequestStatus.SUCCESS) {
            return <Alert type="info" message="No results found for this this search criteria!" />
        }

        return (
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Consumer</th>
                        <th>Phone Number</th>
                    </tr>
                </thead>
                <tbody>{results.map(this.renderRow)}</tbody>
            </Table>
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderForm()}
                    </Col>
                </Row>
                <Row>
                    <Col md={8} mdOffset={2}>
                        {this.renderResults()}
                        <span className="text-muted">Max 1 result</span>
                    </Col>
                </Row>
            </div>
        )
    }
}
