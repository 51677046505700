import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import moment from 'moment'

import mapType from '../../../utils/map-rating-type'
import mapTimeZoneToCountry from '../../../utils/timezone'

/**
 * Render a Rating along with Category, comment
 */
export default class Rating extends Component {
    constructor(...args) {
        super(...args)
    }

    value(o) {
        return o || <span className="text-muted">N/A</span>
    }

    renderCategory(ratingCategory) {
        if (ratingCategory == null) {
            return null
        }

        return (
            <div>
                <label className="strong-label">Category</label>
                {mapType.toText(ratingCategory)}
            </div>
        )
    }

    renderCreatedDate(rating, countryCode) {
        if (rating.createdAt != null) {
            return (
                <div>
                    <label className="strong-label">Date</label>
                    {moment(rating.createdAt)
                        .tz(mapTimeZoneToCountry(countryCode))
                        .format('YYYY-MM-DD HH:mm:ss')}
                </div>
            )
        }

        return null
    }

    render() {
        const { rating, deliveryAddress } = this.props
        if (rating === null || typeof rating === 'undefined') {
            return null
        }

        return (
            <div>
                <Col md={6}>
                    {this.renderCreatedDate(rating, deliveryAddress.countryCode)}
                    <label className="strong-label">Comment</label>
                    {this.value(rating.comment)}
                    {this.renderCategory(rating.ratingCategory)}
                </Col>
                <Col md={6}>
                    <h1 className="text-center">{rating.score}/5</h1>
                </Col>
            </div>
        )
    }
}

Rating.propTypes = {
    rating: PropTypes.object,
}
