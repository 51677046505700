import Api from './api'
import auth from '../auth'

const baseUrl = '/api/sms'
const contentType = 'application/vnd.budbee.sms-v1+json'

export default {
    getVariables() {
        return new Api(baseUrl)
            .path('variables')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .get()
    },

    sendText(from, to, message) {
        let realFrom = from
        let realTo = to
        let realMessage = message
        if (message == null) {
            realMessage = to
            realTo = from
            realFrom = 'budbee'
        }

        return new Api(baseUrl)
            .path('send')
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .post({
                sender: realFrom,
                recipient: realTo,
                message: realMessage,
            })
    },

    listSmsHistoryFrom(token) {
        return new Api(baseUrl)
            .path('orders')
            .path(token)
            .header('Accept', contentType)
            .header('Authorization', auth.get())
            .header('Content-Type', contentType)
            .get()
    },
}
