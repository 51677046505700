import { FETCHING_LINEHAULS, LINEHAUL_LOADED } from '../constants/actionTypes'
import defaultState from './initialState/linehauls'

export function linehaulReducer(state = defaultState, action) {
    switch (action.type) {
        case FETCHING_LINEHAULS:
            return {
                ...state,
                loaded: false,
                data: {
                    routes: [],
                },
            }

        case LINEHAUL_LOADED:
            return {
                ...state,
                loaded: true,
                data: action.data,
            }

        default:
            return state
    }
}
