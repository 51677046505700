import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import mapTimeZoneToCountry from '../../../utils/timezone'
import { getOrderCallHistory } from '../../../utils/order-webapi'
import { handleError } from '../../../utils/handle-error'

const renderRecording = ({ recordingUrl: recording }) => {
    if (recording == null) {
        return null
    }

    return <audio className="pull-right" type="audio/mpeg" src={`${recording}.mp3`} controls />
}
renderRecording.propTypes = {
    recordingUrl: PropTypes.string,
}

/**
 * Render Call Log
 */
export default class CallLog extends Component {
    static propTypes = {
        order: PropTypes.object,
    }

    state = {
        calls: [],
    }

    componentDidMount() {
        if (this.props.order) {
            this.updateCallLog()
        }
    }

    updateCallLog = () => {
        // Get call history for order
        getOrderCallHistory(this.props.order.token)
            .then((calls) => this.setState({ calls }))
            .catch(handleError)
    }

    renderRow(call) {
        const { order } = this.props

        const date = moment(call.date)
            .tz(mapTimeZoneToCountry(order.deliveryAddress.countryCode))
            .format('YYYY-MM-DD HH:mm:ss')

        return (
            <tr key={call.id}>
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{date}</td>
                <td>
                    {call.user.firstName} {call.user.lastName}
                </td>
                <td>
                    <a href={`tel:${call.fromPhoneNumber}`}>{call.fromPhoneNumber}</a>
                </td>
                <td>
                    <a href={`tel:${call.toPhoneNumber}`}>{call.toPhoneNumber}</a>
                </td>
                <td>{renderRecording(call)}</td>
            </tr>
        )
    }

    render() {
        const { calls } = this.state

        if (calls == null || calls.length === 0) {
            return null
        }

        return (
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th />
                    </tr>
                </thead>
                <tbody>{calls.map(this.renderRow, this)}</tbody>
            </Table>
        )
    }
}
