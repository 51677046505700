/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Checkbox, Glyphicon } from 'react-bootstrap'

export default class ShoppingGlobalIndexing extends Component {
    renderList = (arr, key) => {
        return arr.map((item, index) => {
            return (
                <tr key={index}>
                    <td>
                        <Checkbox inline bsClass="" />
                        <p style={{ display: 'inline-block', paddingLeft: '8px' }}>{item}</p>
                        <Button
                            style={{ float: 'right' }}
                            bsStyle="danger"
                            bsSize="xsmall"
                            onClick={() => this.props.removeItem(item, key)}
                        >
                            <Glyphicon glyph="trash" />
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    renderBrandList = (manualBrands) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Global brands</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(manualBrands, 'manualBrands')}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    renderKeywordsList = (manualKeywords) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Global keywords</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(manualKeywords, 'manualKeywords')}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    renderBlockedBrandsList = (blockBrands) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover condensed>
                        <thead>
                            <tr>
                                <th>Global blocked brands</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(blockBrands, 'blockBrands')}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    renderBlockedKeywordsList = (blockKeywords) => {
        return (
            <div>
                <div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Global blocked keywords</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList(blockKeywords, 'blockKeywords')}</tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render() {
        const {
            globalSettings: { manualBrands, manualKeywords, blockBrands, blockKeywords },
            error,
        } = this.props

        if (error) {
            return <p>No global Indexing found</p>
        }

        return (
            <div>
                <h4>Global List</h4>
                {this.renderBrandList(manualBrands)}
                {this.renderKeywordsList(manualKeywords)}
                {this.renderBlockedBrandsList(blockBrands)}
                {this.renderBlockedKeywordsList(blockKeywords)}
            </div>
        )
    }
}

ShoppingGlobalIndexing.propTypes = {
    globalSettings: PropTypes.shape({
        manualBrands: PropTypes.arrayOf(PropTypes.string),
        manualKeywords: PropTypes.arrayOf(PropTypes.string),
        blockBrands: PropTypes.arrayOf(PropTypes.string),
        blockKeywords: PropTypes.arrayOf(PropTypes.string),
    }),
    error: PropTypes.shape({}),
    removeItem: PropTypes.func,
}
