import React from 'react'
import PropTypes from 'prop-types'
import { formattedTime } from '../../utils/slots'

export default function Header({ selectedTerminal, currentTime }) {
    return (
        <h2 style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
            {`Current time ${formattedTime(currentTime, selectedTerminal.address.countryCode)} `}(
            {selectedTerminal.name})
        </h2>
    )
}

Header.propTypes = {
    selectedTerminal: PropTypes.object.isRequired,
    currentTime: PropTypes.number.isRequired,
}
