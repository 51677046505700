import React, { Component } from 'react'
import Grid from 'react-bootstrap/lib/Grid'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'

export default class UnauthorizedHandler extends React.Component {
    render() {
        return (
            <Grid fluid>
                <Row className="text-center">
                    <Col md={12}>
                        <h3>You do not have access to this page</h3>
                    </Col>
                </Row>
            </Grid>
        )
    }
}
