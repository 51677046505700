import React, { Component } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { displayName } from '../../../../utils/display-name'
import { generateKey } from '../../../../utils/generate-key'

export const formatTime = (time) => {
    if (time == null) {
        return null
    }
    const [hour, minute] = time
    const pad = (x) => (x < 10 ? `0${x}` : `${x}`)

    return `${pad(hour)}:${pad(minute)}`
}

export default class DeletePickupModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidUpdate(prevProps) {
        const { groupedPickups } = this.props
        if (groupedPickups !== prevProps.groupedPickups) {
            this.handlePickups(groupedPickups)
        }
    }

    handlePickups = (groupedPickups) => {
        if (!groupedPickups) return

        const handledPickups = Object.values(groupedPickups).map((group) => {
            return group.map((pickup) => {
                return {
                    ...pickup,
                    selected: false,
                }
            })
        })

        this.setState({ handledPickups })
    }

    selectDay = (id) => () => {
        this.setState((state) => ({
            handledPickups: state.handledPickups.map((group) =>
                group.map((d) => ({
                    ...d,
                    selected: d.id === id ? !d.selected : d.selected,
                })),
            ),
        }))
    }

    onCancel = () => {
        const { cancel } = this.props

        this.setState((state) => ({
            handledPickups: state.handledPickups.map((group) =>
                group.map((d) => ({
                    ...d,
                    selected: false,
                })),
            ),
        }))

        cancel()
    }

    onConfirm = () => {
        const { confirmDelete } = this.props
        const { handledPickups } = this.state

        const shouldDelete = handledPickups.map((group) =>
            group.filter((pickup) => pickup.selected).map((pickup) => pickup.id),
        )

        const flattenedShouldDelete = [].concat(...shouldDelete)
        confirmDelete(flattenedShouldDelete)
    }

    renderIdentifiers = (pickup) => {
        if (!pickup) return null

        return (
            <div className="group-identifiers">
                <div className="group-identifier">Terminal: {pickup.terminal.code || 'N/A'}</div>
                <div className="group-identifier">Arrival: {formatTime(pickup.arrival) || 'N/A'}</div>
                <div className="group-identifier">
                    Pallets: {pickup.minPallets || 'N/A'} - {pickup.maxPallets || 'N/A'}
                </div>
                <div className="group-identifier">
                    Interval:{' '}
                    {displayName(' - ', formatTime(pickup.intervalStart), formatTime(pickup.intervalEnd)) || 'N/A'}
                </div>
            </div>
        )
    }

    render() {
        const { handledPickups } = this.state

        if (!handledPickups) return null

        return (
            <Modal show={this.props.show} animation className="pickup-schedules">
                <div className="panel panel-default" style={{ margin: 0 }}>
                    <div className="panel-heading">
                        <h3 className="panel-title">
                            <i className="glyphicon glyphicon-time" /> Manage Pickup Days
                        </h3>
                    </div>
                    <div className="panel-body">
                        <div className="col-md-12 delete-modal">
                            <div className="label">Select days to remove:</div>
                            <div className="schedule-groups">
                                {handledPickups.map((group) => (
                                    <div className="schedule-container" key={generateKey(group[0].id)}>
                                        {this.renderIdentifiers(group[0])}
                                        <div className={cn('btn-group dow')} role="group" aria-label="Days">
                                            {group.map((item) => (
                                                <button
                                                    key={item.id}
                                                    type="button"
                                                    className={cn('btn', {
                                                        'btn-primary': item.selected,
                                                        'btn-default': !item.selected,
                                                    })}
                                                    onClick={this.selectDay(item.id)}
                                                >
                                                    {item.day}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="panel-footer">
                        <button type="button" className="btn" onClick={this.onCancel}>
                            <i className="glyphicon glyphicon-remove" /> Cancel
                        </button>
                        <div className="right">
                            <button type="button" className="btn btn-danger" onClick={this.onConfirm}>
                                <i className="glyphicon glyphicon-trash" /> CONFIRM
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

DeletePickupModal.propTypes = {
    show: PropTypes.bool.isRequired,
    cancel: PropTypes.func,
    confirmDelete: PropTypes.func,
    groupedPickups: PropTypes.object,
}
