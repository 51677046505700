import moment from 'moment'

export const isAfter = (time1, time2) => {
    const timeA = moment(time1, 'HH:mm')
    const timeB = moment(time2, 'HH:mm')

    return moment(timeA).isAfter(moment(timeB))
}

export const humanTimeDiff = (timestamp) => {
    const timeDiff = (time) => {
        return moment(time).diff(moment())
    }

    return moment.duration(timeDiff(timestamp)).humanize(true)
}

// Returns all times in a day with minuteStep number of minutes between each times
// Example output with a minuteStep of 5: [00:00, 00:05, 00:10, 00:15...]

export const generateTimes = (minuteStep) => {
    const hours = []
    for (let hour = 0; hour < 24; hour += 1) {
        const minuteIterations = Math.floor(60 / minuteStep)
        for (let i = 0; i < minuteIterations; i += 1) {
            const time = moment({ hour, minute: minuteStep * i })
            hours.push({ value: time.format('HH:mm:SS'), label: time.format('HH:mm') })
        }
    }
    return hours
}
