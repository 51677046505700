import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Glyphicon } from 'react-bootstrap'

const AlertGroup = ({ statusCode, message }) => {
    const isStatusCode = (...statusCodes) => statusCodes.includes(statusCode)
    const isSuccess = isStatusCode(200, 201, 202, 204)
    const className = isSuccess ? 'fadeOut' : ''

    const renderMessage = (msg, glyph, type) => {
        if (Array.isArray(msg)) {
            return msg.map((item, idx) => {
                const key = `key${idx}`
                return (
                    <Alert bsStyle={type} className={className} key={key}>
                        <Glyphicon glyph={glyph} /> {item}
                    </Alert>
                )
            })
        }

        return (
            <Alert bsStyle={type} className={className}>
                <Glyphicon glyph={glyph} /> {msg}
            </Alert>
        )
    }

    return (
        <div>
            {isSuccess && renderMessage('Your changes were saved', 'thumbs-up', 'success')}
            {isStatusCode(400, 422) &&
                renderMessage(message || 'Check your data and try again', 'thumbs-down', 'warning')}
            {isStatusCode(401, 403) && renderMessage(message, 'lock', 'warning')}
            {isStatusCode(500, 503) && renderMessage('Oops, something went wrong!', 'thumbs-down', 'danger')}
        </div>
    )
}

AlertGroup.propTypes = {
    statusCode: PropTypes.number,
    message: PropTypes.any,
}

export default AlertGroup
