const LEAVE_WITH_NEIHGBOUR = 'leaveWithNeighbour'
const ALTERNATIVELY_LEAVE_WITH_NEIHGBOUR = 'alternativelyLeaveWithNeighbour'
// the setting to leave with neighbour is sometimes represented as 'leaveWithNeighbour' and sometimes
// 'LEAVE_WITH_NEIGHBOUR' so include all forms in EXCLUDED_SETTINGS
const EXCLUDED_SETTINGS = [LEAVE_WITH_NEIHGBOUR, ALTERNATIVELY_LEAVE_WITH_NEIHGBOUR, 'LEAVE_WITH_NEIGHBOUR']

export const isExcludedOrderSetting = (countryCode, setting) => {
    // all delivery settings other than leave with neighbour are permitted in all countries
    if (!EXCLUDED_SETTINGS.includes(setting)) {
        return false
    }
    // Leave with neighbour only available in NL and BE
    return countryCode !== 'NL' && countryCode !== 'BE'
}

export const deliveryMethod = {
    IN_PERSON: 'IN PERSON',
    LEAVE_BY_DOOR: 'LEAVE BY DOOR',
}

export const deliveryNotificationMethod = {
    RING_DOOR_BELL: 'RING DOOR BELL',
    KNOCK_ON_DOOR: 'KNOCK ON DOOR',
    CALL_CUSTOMER: 'CALL CUSTOMER',
    NO_NOTIFICATION: 'NO NOTIFICATION',
}

export const enumSettingsMap = {
    deliveryMethod,
    deliveryNotificationMethod,
}
