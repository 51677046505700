import React, { Component } from 'react'
import { Link } from 'react-router'

import RoleApi from '../../utils/role-api'

export default class RoleView extends React.Component {
    constructor(...args) {
        super(...args)
        this.state = {
            role: null,
        }
    }

    componentWillMount() {
        RoleApi.find(this.props.params.id)
            .then((role) => this.setState({ role }))
            .catch((e) => console.error(e))
    }

    renderPermission(permission) {
        return <li key={permission.id}>{permission.name}</li>
    }

    renderPermissions(rolePermissions) {
        return rolePermissions.map((p) => p.permission).map(this.renderPermission, this)
    }

    render() {
        const { role } = this.state
        if (role == null) {
            return null
        }

        return (
            <div className="container">
                <h4>{role.name}</h4>
                <ul>{this.renderPermissions(role.rolePermissions)}</ul>
            </div>
        )
    }
}
