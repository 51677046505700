import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import Select from 'react-select'
import PricePoint from './item'
import * as ownerActions from '../../actions/owner'

export default class PricePointList extends Component {
    constructor(...args) {
        super(...args)

        this.state = {
            showPopover: false,
        }
    }

    _checkAll = (e) => {
        Object.keys(this.refs)
            .filter((ref) => ref.startsWith('pricePoint-'))
            .map((ref) => this.refs[ref])
            .forEach((node) => node.setChecked(e.target.checked))
    }

    _onDelete = () => {
        const pricePointIds = Object.keys(this.refs)
            .filter((ref) => ref.startsWith('pricePoint-'))
            .map((ref) => this.refs[ref])
            .filter((ref) => ref.getChecked())
            .map((ref) => ref.getValue())

        this.props.onDelete(pricePointIds, this.props.collectionId)
    }

    _onCarrierSelect = (val) => {
        const id = parseInt(val.value)
        if (Number.isNaN(id)) {
            return
        }
        const pricePoints = Object.keys(this.refs)
            .filter((ref) => ref.startsWith('pricePoint-'))
            .map((ref) => this.refs[ref])
            .filter((ref) => ref.getChecked())
            .map((ref) => ref.copy())
        if (!confirm(`Do you really want to copy ${pricePoints.length} price points to Owner operator ${id}`)) {
            return
        }

        this.props.onCopy(id, pricePoints)
    }

    sortCarriers(c1, c2) {
        const n1 = c1.name.toLowerCase()
        const n2 = c2.name.toLowerCase()
        if (n1 < n2) return -1
        if (n2 < n1) return 1
        return 0
    }

    _getCarrierOptions(carriers) {
        return carriers.sort(this.sortCarriers).map((carrier) => ({
            label: carrier.name,
            value: carrier.id,
        }))
    }

    _onTogglePopover = () => {
        this.setState({ showPopover: !showPopover })
    }

    _renderCopy = () => {
        if (this.props.onCopy == null) {
            return null
        }

        const popover = (
            <Popover title="Select carrier" id="CopyToCarrier">
                <Select options={this._getCarrierOptions(this.props.carriers)} onChange={this._onCarrierSelect} />
            </Popover>
        )

        return (
            <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
                <button type="button" className="btn btn-primary btn-sm pull-right" style={{ marginRight: 5 }}>
                    Copy selected to
                </button>
            </OverlayTrigger>
        )
    }

    _renderItem = (data) => {
        const key = `${data.pricePoint.id}:${data.postalcodeZoneId}`
        return (
            <PricePoint
                key={key}
                ref={`pricePoint-${data.pricePoint.id}`}
                data={data}
                postalcodeZone={this.props.postalcodeZones.find((pcz) => pcz.id === data.postalcodeZoneId)}
            />
        )
    }

    render() {
        if (this.props.priceList == null) {
            return null
        }
        return (
            <div>
                {this.props.onDelete != null ? (
                    <button
                        type="button"
                        className="btn btn-danger btn-sm pull-left margin-btn"
                        onClick={this._onDelete}
                    >
                        <i className="glyphicon glyphicon-trash remove inline " />
                    </button>
                ) : null}
                {this._renderCopy()}
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" onClick={this._checkAll} />
                            </th>
                            <th>Type</th>
                            <th>Postalcode Zone</th>
                            <th>Price</th>
                            <th>Valid from</th>
                            <th>Valid to</th>
                        </tr>
                    </thead>
                    <tbody>{this.props.priceList.map(this._renderItem)}</tbody>
                </table>
            </div>
        )
    }
}
