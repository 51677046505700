export const superfluousEventsToExcludeFirstOf = [
    'MAXIMUM_NUMBER_OF_ATTEMPTS_UPDATED',
    'LEAVE_OUTSIDE_DOOR_UPDATED',
    'SIGNATURE_REQUIREMENT_UPDATED',
    'MINIMUM_AGE_UPDATED',
    'MUST_MATCH_CONSUMER_UPDATED',
    'CONSUMER_KNOCK_ON_DOOR_UPDATED',
    'IDENTIFICATION_CHECK_REQUIREMENT_UPDATED',
    'CONSUMER_NAME_UPDATED',
    'CONSUMER_EMAIL_UPDATED',
    'CONSUMER_PHONE_NUMBER_UPDATED',
    'ADDRESS_STREET_UPDATED',
    'ADDRESS_LOCALITY_UPDATED',
    'ADDRESS_POSTAL_CODE_UPDATED',
    'ADDRESS_COUNTRY_UPDATED',
    'DELIVERY_PARCEL_ADDED',
]

export const eventsToExclude = ['PARCEL_ADDED_TO_CONSIGNMENT']

export const eventsToShowInList = [
    'ORDER_REGISTERED',
    'ARRIVED_AT_DISTRIBUTION_TERMINAL',
    'BANK_ID_AUTHENTICATION_FAILED',
    'BANK_ID_AUTHENTICATION_COMPLETED',
    'CHANGED_TO_LOCKER_DELIVERY',
    'CHANGED_TO_HOME_DELIVERY',
    'CHANGED_TO_LOCKER_RETURN',
    'CHANGED_TO_HOME_RETURN',
    'DELIVERY_TYPE_UPDATED',
    'DELIVERY_CONSIGNMENT_BOOKED',
    'DELIVERY_CONSIGNMENT_COMPLETED',
    'LOCKER_CHANGED',
    'RETURN_CONSIGNMENT_BOOKED',
    'RETURN_CONSIGNMENT_COMPLETED',
    'LOCKER_RETURN_PARCEL_INJECTED_BY_CONSUMER',
    'LOCKER_RETURN_PARCEL_PICKED_UP_BY_DRIVER',
]

const excludeFirstEventOfType = (events) => {
    const excludeEvents = [...superfluousEventsToExcludeFirstOf]
    const exclusionFilter = (dataEvent) => {
        const index = excludeEvents.indexOf(dataEvent.event.event)
        if (index !== -1) {
            excludeEvents.splice(index, 1)
            return false
        }

        return true
    }

    return events.filter(exclusionFilter)
}

const excludeEvents = (events) => events.filter((dataEvent) => !eventsToExclude.includes(dataEvent.event.event))

export const filteredEvents = (events) => {
    return excludeEvents(excludeFirstEventOfType(events))
}
