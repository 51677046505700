import React, { Component } from 'react'
import { Link } from 'react-router'
import Loader from 'react-loader'
import { Button, Table } from 'react-bootstrap'
import HtmlTitle from '../html-title'

import auth from '../../auth'
import RoleApi from '../../utils/role-api'

export default class RoleList extends React.Component {
    constructor(...args) {
        super(...args)
        this.state = {
            isLoading: false,
            roles: [],
        }
    }

    componentWillMount() {
        if (!auth.hasPermission('view_roles')) {
            this.props.router.push('/admin/users')
        }
        RoleApi.find()
            .then((roles) => this.setState({ roles }))
            .catch((e) => console.error(e))
    }

    sort(r1, r2) {
        const n1 = r1.name.toLowerCase()
        const n2 = r2.name.toLowerCase()
        if (n1 < n2) return -1
        if (n2 < n1) return 1
        return 0
    }

    renderRow(role) {
        return (
            <tr key={role.id}>
                <td>{role.id}</td>
                <td>
                    <Link to={`/admin/roles/${role.id}`}>{role.name}</Link>
                </td>
                <td>{role.rolePermissions.length}</td>
            </tr>
        )
    }

    renderRows(roles) {
        return roles.sort(this.sort).map(this.renderRow, this)
    }

    render() {
        return (
            <div>
                <HtmlTitle title="Roles" />
                <Loader color="#bfbfbf" loaded={!this.state.isLoading}>
                    <div className="container">
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Permissions</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderRows(this.state.roles)}</tbody>
                        </Table>
                    </div>
                </Loader>
            </div>
        )
    }
}
